import React from "react";
import style from "./EditPlan.module.css";
import { Dialog } from "primereact/dialog";
// import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { RxCross2 } from "react-icons/rx";
import { addPlan } from "../../../../Api/admin";
import AddFeatures from "../addPlan/AddFeatures/AddFeatures";
import { getFeature } from "../../../../Api/admin";
import Loader from "../../../common/Loader";
import { updatePlan } from "../../../../Api/admin";
import { getPlanByIdData } from "../../../../Api/admin";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import Toaster from "../../../common/Toaster";

const EditPlan = () => {
  const [open, setopen] = useState(false);
  const [featuredata, setFeaturedata] = useState([]);

  const [featureinc, setfeatureinc] = useState([]);
  const [featurenotinc, setfeaturenotinc] = useState([]);

  const [includedFeature, setIncludedFeature] = useState("");
  const [excludedFeature, setExcludedFeature] = useState("");
  const [toaster, setToaster] = useState({
    show: false,
    message: "",
  });

  const [loader, setLoader] = useState(false);
  const [getDataBYId, setGetDataById] = useState([]);
  const [wordCount, setWordCount] = useState(0);
  const limit = 50;

  const handleTextChange = (event) => {
    const words = event.target.value.split(/\s+/).filter(Boolean);
    if (words.length <= limit) {
      setWordCount(words.length);
      const { name, value } = event.target;
      setUserData({ ...userData, [name]: value });
    } else {
      setToaster({
        show: true,
        message: `you cant write more then ${limit} words in description`,
      });
    }
  };

  let params = useParams();

  const navigate = useNavigate();

  var fetchfeature = async () => {
    var result = await getFeature();
    setFeaturedata(result.data.data);
    // setFilteredData(result.data.data);
    console.log("planskkkkk", result.data.data);
  };

  const getPlanDataById = async () => {
    // console.log(params.id,"ASDAADASDASD")
    setLoader(true);
    await getPlanByIdData(params.id)
      .then((res) => {
        console.log(res.data.data, "adasdsdasdsadasdasdasdasdasdasd");
        setGetDataById(res.data.data);

        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  useEffect(() => {
    getPlanDataById();
  }, []);

  useEffect(() => {
    if (getDataBYId?.plan_description) {
      setWordCount(
        getDataBYId?.plan_description?.split(/\s+/).filter(Boolean).length
      );
    }
  }, [getDataBYId]);

  const [userData, setUserData] = useState({
    plan_name: "",
    user_type: "",
    validity: "",
    amount: "",
    plan_description: "",
    included_features: [],
    not_included_features: [],
  });

  const handleDropdownChange = (event, index) => {
    const { name, value } = event.target;
    setUserData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDropdownChangeFeature = (event) => {
    const { name, value } = event.target;

    // Add the selected feature to the correct list
    if (name === "included_features") {
      setIncludedFeature("");
      setfeatureinc((prev) => [...prev, value]);
    } else {
      setExcludedFeature("");
      setfeaturenotinc((prev) => [...prev, value]);
    }

    // Remove the selected feature from the main list
    // setFeaturedata(prev => prev.filter(item => item.feature_name !== value));
  };

  const removeFeature = (featureName, isIncluded) => {
    const newFeature = { feature_name: featureName };

    // Add the feature back to the main list
    // setFeaturedata(prev => [...prev, newFeature]);

    // Remove the feature from the specific list
    if (isIncluded) {
      setfeatureinc((prev) => prev.filter((name) => name !== featureName));
    } else {
      setfeaturenotinc((prev) => prev.filter((name) => name !== featureName));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      userData.plan_description = userData.plan_description.trim();
      let updateData = {
        ...userData,
        not_included_features: [...featurenotinc],
        included_features: [...featureinc],
      };

      console.log("Updating with data:", updateData);

      let response = await updatePlan(params.id, updateData);
      console.log("Update response:", response);

      // Optionally reset the form or redirect the user
      setLoader(false);
      // You might want to navigate back to the plan list or show a success message

      navigate("/superadmin/subscriptionplan");
    } catch (error) {
      console.error("Failed to update the plan:", error);
      setLoader(false);
      // Optionally handle errors, e.g., show an error message
    }
  };

  const handleChnage = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  useEffect(() => {
    if (getDataBYId) {
      setUserData({
        plan_name: getDataBYId.plan_name || "",
        user_type: getDataBYId.user_type || "",
        validity: getDataBYId.validity || "",
        amount: getDataBYId.amount || "",
        plan_description: getDataBYId.plan_description || "",
        included_features: getDataBYId.included_features
          ? getDataBYId.included_features.map((f) => f.feature_name)
          : [],
        not_included_features: getDataBYId.not_included_features
          ? getDataBYId.not_included_features.map((f) => f.feature_name)
          : [],
      });
      setfeatureinc(getDataBYId.included_features || []);
      setfeaturenotinc(getDataBYId.not_included_features || []);
    }
  }, [getDataBYId]);

  return (
    <div>
      <AddFeatures
        resumeone={open}
        setResumeone={setopen}
        func={fetchfeature}
      />

      {toaster && (
        <>
          <Toaster
            // event={"Please InActive Current Post then you Add new Post"}
            event={toaster.message}
            show={toaster.show}
            severity={"warn"}
            setToaster={setToaster}
          />
        </>
      )}

      <form onSubmit={handleSubmit}>
        <div>
          <div
            className={`d-flex mb-4 justify-content-between mt-4 align-items-center`}
          >
            <div>
              {" "}
              <i
                className="bi bi-chevron-left pointer fs-3"
                onClick={() => navigate("/superadmin/subscriptionplan")}
              ></i>
            </div>
            <div className={`lufga_32px fw_600`}>Edit Subscription Plan</div>
            <button
              type="button"
              className={`theme_button lufga_16px`}
              onClick={() => setopen(true)}
            >
              Add feature
            </button>
          </div>
          <div className={style.formContainer}>
            <div className={`row mt-3`}>
              <div className={`col-6`}>
                <div className={`lufga_18px `}>Plan Name</div>
                <div>
                  <input
                    required
                    placeholder="Plan Name"
                    onChange={handleChnage}
                    className={`lufga_16px form-control`}
                    name="plan_name"
                    value={userData.plan_name}
                    // defaultValue={getDataBYId?.plan_name}
                    type="text"
                  />
                </div>
              </div>
              <div className={`col-6`}>
                <div className={`lufga_18px`}>User Type</div>
                <div>
                  <select
                    name="user_type"
                    className={`lufga_16px ${style.input_text_dropdown}`}
                    id="dropdown"
                    value={userData.user_type}
                    onChange={handleDropdownChange}
                  >
                    <option selected disabled>
                      select user type
                    </option>
                    <option value="Surrogate Mother">Surrogate Mother</option>
                    <option value="Intended Parent">Intended Parent</option>
                    <option value="Embryo Donor">Embryo Donor</option>
                    <option value="Adopting Parent">Adopting Parent</option>
                  </select>
                </div>
              </div>
            </div>

            <div className={`row mt-3`}>
              <div className={`col-6`}>
                <div>
                  <div className={`lufga_18px`}>Validity</div>
                  <div>
                    <select
                      name="validity"
                      className={`lufga_16px ${style.input_text_dropdown}`}
                      id="dropdown"
                      value={userData.validity}
                      onChange={handleDropdownChange}
                    >
                      <option value="" selected disabled>
                        Select Validity
                      </option>
                      <option value="1 Month">1 Month</option>
                      <option value="3 Months">3 Months</option>
                      <option value="6 Months">6 Months</option>
                      <option value="12 Months">12 Months</option>
                      <option value="24 Months">24 Months</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className={`col-6`}>
                <div className={`lufga_18px`}>Amount per user</div>
                <div>
                  <input
                    required
                    placeholder="Enter amount"
                    onChange={handleChnage}
                    className={`lufga_16px form-control`}
                    name="amount"
                    value={userData.amount}
                    type="number"
                  />
                </div>
              </div>
            </div>

            <div className={`row mt-3`}>
              <div className={`col-md-12`}>
                <label
                  className={`form-check-label lufga_18px d-flex align-items-center ${style.LabelText}`}
                  htmlFor="flexCheckDefault"
                >
                  Plan Description
                  <span className={`${style.words_left}`}>
                    (Remaining: {limit - wordCount} / {limit})
                  </span>
                </label>
                <textarea
                  required
                  rows={2}
                  id="plan_description"
                  type="text"
                  className="form-control lufga_16px"
                  placeholder="Enter Plan Description"
                  name="plan_description"
                  value={userData.plan_description}
                  onChange={handleTextChange}
                />
              </div>
            </div>

            <div className={`row mt-3`}>
              <div className="col-md-12">
                <label
                  className={`form-check-label mb-1 lufga_18px ${style.LabelText}`}
                  htmlFor="flexCheckDefault"
                >
                  Features
                </label>
                <div>
                  {/* <label htmlFor="dropdown"></label> */}
                  {/* <select
                  name="included_features"
                  className={`lufga_16px  ${style.input_text_dropdown}`}
                  onChange={handleDropdownChangeFeature}
                  value={includedFeature}
                >
                  <option value="" disabled selected>
                    Select a feature
                  </option>
                  
                  {featuredata.filter(d => ![...featureinc, ...featurenotinc].includes(d.feature_name)).map((res) => (
                    <option key={res.feature_name} value={res.feature_name}>
                      {res.feature_name}
                    </option>
                  ))}

                </select> */}

                  <select
                    name="included_features"
                    onChange={handleDropdownChangeFeature}
                    value={includedFeature}
                    className={`lufga_16px ${style.input_text_dropdown}`}
                  >
                    <option value="" disabled selected>
                      Select a feature
                    </option>
                    {featuredata
                      .filter(
                        (d) =>
                          !featureinc.includes(d.feature_name) &&
                          !featurenotinc.includes(d.feature_name)
                      )
                      .map((res) => (
                        <option key={res.feature_name} value={res.feature_name}>
                          {res.feature_name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>

            <div className={style.arr}>
              {featureinc.map((feature, index) => (
                <div key={index}>
                  <div className={`${style.arr_list} lufga_14px`}>
                    {feature}{" "}
                    <RxCross2
                      style={{ color: "#358C99" }}
                      onClick={() => removeFeature(feature, true)}
                    />
                  </div>
                </div>
              ))}
            </div>

            <div className={`row mt-3`}>
              <div className="col-md-12">
                <label
                  className={`form-check-label mb-1 lufga_18px ${style.LabelText}`}
                  htmlFor="flexCheckDefault"
                >
                  Features Not Included
                </label>
                <div>
                  {/* <label htmlFor="dropdown"></label> */}

                  <select
                    name="not_included_features"
                    className={`lufga_16px ${style.input_text_dropdown}`}
                    onChange={handleDropdownChangeFeature}
                    value={excludedFeature}
                  >
                    <option value="" disabled selected>
                      Select a feature to Not Include
                    </option>
                    {featuredata
                      .filter(
                        (d) =>
                          !featureinc.includes(d.feature_name) &&
                          !featurenotinc.includes(d.feature_name)
                      )
                      .map((res) => (
                        <option key={res.feature_name} value={res.feature_name}>
                          {res.feature_name}
                        </option>
                      ))}
                  </select>

                  {/* <select
                  name="not_included_features"
                  className={`lufga_16px  ${style.input_text_dropdown}`}
                  onChange={handleDropdownChangeFeature}
                  value={excludedFeature}
                >
                  <option value="" disabled selected>
                    Select a feature to Not Include
                  </option>

                  {featuredata.filter(d => ![...featureinc, ...featurenotinc].includes(d.feature_name)).map((res) => (
                    <option key={res.feature_name} value={res.feature_name}>
                      {res.feature_name}
                    </option>
                  ))}
                </select> */}
                </div>
              </div>
            </div>

            <div className={style.arr}>
              {featurenotinc.map((feature, index) => (
                <div key={index}>
                  <div className={`${style.not_arr_list} lufga_14px`}>
                    {feature}{" "}
                    <RxCross2
                      style={{ color: "#DC1919" }}
                      onClick={() => removeFeature(feature, false)}
                    />
                  </div>
                </div>
              ))}
            </div>

            <div className="d-flex mt-5 justify-content-end gap-4">
              <button
                type="button"
                className={style.cancelBtn}
                onClick={() => navigate("/superadmin/subscriptionplan")}
              >
                Cancel
              </button>

              <button type="submit" className={`theme_button lufga_16px`}>
                Update
              </button>
            </div>
          </div>
        </div>
      </form>
      <Loader open={loader} />
    </div>
  );
};

export default EditPlan;
