import React from 'react'
import style from './SuperAdminLayout.module.css'
import Signin from './signin/signin'
import { useState } from 'react'
import ForgotPassword from './forgotpassword/forgotpassword'

const SuperAdminLayout = () => {

  const [resume, setResume] = useState(true)
  

  return (
    <>
    <div>
      <Signin resumeone={resume} setResumeone={setResume} />
    
      <div className={`${style.main_container}`}>
        <div className={`${style.image_container}`}>
          <img src='images/superadmin_sign_in_image.png' className={`${style.img_main}`} />
          <img src='images/logo_super_admin.png' className={`${style.logo}`} />
        </div>
        <div className={`${style.footer}`}>
          <div className='d-flex align-items-center'><span className='lufga_14px' style={{color:"#A0AEC0"}}>@ 2024, Made with ❤️ by</span><span className='theme_color lufga_18px fw_700'>&nbsp; New Hope Points</span></div>
        </div>
      </div>


    </div>
    </>
  )
}

export default SuperAdminLayout