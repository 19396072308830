import React, { useEffect, useState } from 'react';
import style from './LawyerEditAppoint.module.css';
import { Dialog } from 'primereact/dialog';
import { editAppointment, getlawyerAppointmentById, deleteAppointmentById } from '../../../../Api/Lawyer';
import Loader from '../../../common/Loader';
import moment from 'moment';
import { ConfirmDelete } from '../../../common/ConfirmDelete';
import PhoneInputComponent from '../../../commonComponents/PhoneInput/PhoneInput';

const LawyerEditAppoint = ({ apoint, setApoint, appointId, reload }) => {


    const [editable, setEditable] = useState(true)

    const [data, setData] = useState({
        name: "",
        appointment_title: "",
        contact_number: "",
        email_address: "",
        appointment_purpose: "",
        client_type: "",
        service_requested: "",
        date: "",
        location: "",
        important_note: "",
        start_time: "",
        end_time: ""
    });

    const [loader, setLoader] = useState(false);

    const deleteAppointmentData = async (id) => {
        setLoader(true);
        try {
            await deleteAppointmentById(id);
            await reload();
            setApoint(false);
        } catch (error) {
            console.log(error);
            setApoint(false);
        }
        setLoader(false);
    };

    const addAppointmentdata = async (e) => {
        e.preventDefault();
        try {
            setLoader(true);
            await editAppointment(appointId, data);
            await reload();
            setEditable(true)
            setApoint(false);

            setLoader(false);
        } catch (err) {
            console.log(err);

            setEditable(true)
            setApoint(false);
            setLoader(false);
        }
    };

    const getAppointmentData = async () => {
        setLoader(true);
        try {
            let res = await getlawyerAppointmentById(appointId);
            const appointmentData = res.data.data[0];
            appointmentData.date = moment(appointmentData.date).format('YYYY-MM-DD');
            setData(appointmentData);
            setLoader(false);
        } catch (err) {
            console.log(err);
            setLoader(false);
        }
    };

    const onInputChange = (e) => {
        const { name, value } = e.target;

        setData(prev => ({ ...prev, [name]: value }));
    };

    useEffect(() => {
        if (appointId) {
            getAppointmentData();
        }
    }, [appointId]);

    return (
        <div>
            <Loader open={loader} />
            <Dialog
                closable={false}
                visible={apoint}
                className='p-0 m-0'
                onHide={() => setApoint(false)}
                style={{ width: "65vw", padding: "0px", margin: "0px" }}
                breakpoints={{ "1400px": "90vw", "641px": "100vw" }}
            >
                <div className='Verfied_con'>
                    <div className='d-flex justify-content-between align-items-center'>
                        {/* Dont remove this first span its here to maintain the layout */}
                        <span></span>
                        <span className={style.verfideText}>{data.appointment_title}</span>
                        <span onClick={() => { setApoint(false); setEditable(true) }} style={{ cursor: "pointer" }}><i className="pi pi-times" style={{ fontSize: '1.5rem' }}></i></span>
                    </div>
                    <form onSubmit={addAppointmentdata}>
                        <div className="row mt-4">
                            <div className="col-md-3">
                                <label className={`form-check-label mb-1 ${style.LabelText}`} htmlFor="flexCheckDefault">
                                    Appointment Title<span className='text-danger'>*</span>
                                </label>
                                <input
                                    disabled={editable}
                                    required
                                    type="text"
                                    className="form-control"
                                    placeholder="Appointment Title"
                                    name="appointment_title"
                                    value={data.appointment_title}
                                    onChange={onInputChange}
                                />
                            </div>
                            <div className="col-md-3">
                                <label className={`form-check-label mb-1 ${style.LabelText}`} htmlFor="flexCheckDefault">
                                    Name<span className='text-danger'>*</span>
                                </label>
                                <input
                                    disabled={editable}
                                    required
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Your Name"
                                    name="name"
                                    value={data.name}
                                    onChange={onInputChange}
                                />
                            </div>
                            <div className="col-md-3">
                                <label className={`form-check-label mb-1 ${style.LabelText}`} htmlFor="flexCheckDefault">
                                    Email Address<span className='text-danger'>*</span>
                                </label>
                                <input
                                    disabled={editable}
                                    required
                                    type="email"
                                    className="form-control"
                                    placeholder="Enter Your Email Address"
                                    name="email_address"
                                    value={data.email_address}
                                    onChange={onInputChange}
                                />
                            </div>
                            <div className="col-md-3">
                                <label className={`form-check-label mb-1 ${style.LabelText}`} htmlFor="flexCheckDefault">
                                    Contact Number<span className='text-danger'>*</span>
                                </label>
                                <PhoneInputComponent
                                    value={data.contact_number}
                                    onChange={onInputChange}
                                    name="contact_number"
                                    required={true}
                                    disabled={editable}
                                    placeholder="Enter Your Contact Number"
                                />
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-md-4">
                                <label className={`form-check-label mb-1 ${style.LabelText}`} htmlFor="flexCheckDefault">
                                    Client Type<span className='text-danger'>*</span>
                                </label>
                                <input
                                    disabled={editable}
                                    required
                                    type="text"
                                    className="form-control"
                                    placeholder="Client Type"
                                    name="client_type"
                                    value={data.client_type}
                                    onChange={onInputChange}
                                />
                            </div>
                            <div className="col-md-4">
                                <label className={`form-check-label mb-1 ${style.LabelText}`} htmlFor="flexCheckDefault">
                                    Service Requested<span className='text-danger'>*</span>
                                </label>
                                <input
                                    disabled={editable}
                                    required
                                    type="text"
                                    className="form-control"
                                    placeholder="Service Requested"
                                    name="service_requested"
                                    value={data.service_requested}
                                    onChange={onInputChange}
                                />
                            </div>
                            <div className="col-md-4">
                                <label className={`form-check-label mb-1 ${style.LabelText}`} htmlFor="flexCheckDefault">
                                    Date<span className='text-danger'>*</span>
                                </label>
                                <input
                                    disabled={editable}
                                    required
                                    type="date"
                                    className="form-control"
                                    placeholder="Date"
                                    name="date"
                                    value={data.date}
                                    onChange={onInputChange}
                                />
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-md-3">
                                <label className={`form-check-label mb-1 ${style.LabelText}`} htmlFor="flexCheckDefault">
                                    Start Time<span className='text-danger'>*</span>
                                </label>
                                <input
                                    disabled={editable}
                                    required
                                    type="time"
                                    className="form-control"
                                    placeholder="Appointment Purpose"
                                    name="start_time"
                                    value={data.start_time}
                                    onChange={onInputChange}
                                />
                            </div>
                            <div className="col-md-3">
                                <label className={`form-check-label mb-1 ${style.LabelText}`} htmlFor="flexCheckDefault">
                                    End time<span className='text-danger'>*</span>
                                </label>
                                <input
                                    disabled={editable}
                                    required
                                    type="time"
                                    className="form-control"
                                    placeholder="Appointment Purpose"
                                    name="end_time"
                                    value={data.end_time}
                                    onChange={onInputChange}
                                />
                            </div>
                            <div className="col-md-6">
                                <label className={`form-check-label mb-1 ${style.LabelText}`} htmlFor="flexCheckDefault">
                                    Appointment Purpose<span className='text-danger'>*</span>
                                </label>
                                <input
                                    disabled={editable}
                                    required
                                    type="text"
                                    className="form-control"
                                    placeholder="Appointment Purpose"
                                    name='appointment_purpose'
                                    value={data.appointment_purpose}
                                    onChange={onInputChange}
                                />
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-md-12">
                                <label className={`form-check-label mb-1 ${style.LabelText}`} htmlFor="flexCheckDefault">
                                    Location
                                </label>
                                <input
                                    disabled={editable}
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Your Location"
                                    name='location'
                                    value={data.location}
                                    onChange={onInputChange}
                                />
                            </div>
                        </div>
                        <div className="row mt-3 mb-4">
                            <div className="col-md-12">
                                <label className={`form-check-label mb-1 ${style.LabelText}`} htmlFor="flexCheckDefault">
                                    Important Note
                                </label>
                                <input
                                    disabled={editable}
                                    type="text"
                                    className="form-control"
                                    placeholder="Important Note"
                                    name="important_note"
                                    value={data.important_note}
                                    onChange={onInputChange}
                                />
                            </div>
                        </div>

                        {editable &&
                            <div className='d-flex justify-content-end align-items-center gap-4'>
                                <ConfirmDelete onDelete={deleteAppointmentData} data={appointId} header={"Cancel Appointment"} deleteMsg={"Are you sure you want to cancel this appointment"} cancelButtonText={"No"} deleteButtonText={"Yes"}>
                                    <button className={style.delete_button} type="button">Cancel Appointment</button>
                                </ConfirmDelete>

                                <button onClick={() => { setEditable(!editable) }} className={style.Edit_button} type="button">
                                    <span className='d-flex align-items-center justify-content-center gap-1'>
                                        <i className="pi pi-user-edit" style={{ color: '#fff', fontSize: '1.3rem' }}></i>
                                        Reschedule Appointment
                                    </span>
                                </button>
                            </div>

                        }

                        {!editable &&
                            <div div className='d-flex justify-content-end gap-3'>
                                <button className={style.CencelButton} type="button" onClick={() => { setEditable(!editable); getAppointmentData() }}>Cancel</button>
                                <button className={style.SubmitButton}
                                    //  onClick={(e) => addAppointmentdata(e)} 
                                    type="submit">Submit</button>
                            </div>

                        }


                    </form>
                </div>
            </Dialog >
        </div >
    );
};

export default LawyerEditAppoint;
