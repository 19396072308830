import React, { useEffect, useState } from "react";
import ClientTable from "../MyClientTable/ClientTable";
import style from "./MyClient.module.css";
import { InputText } from "primereact/inputtext";
import { getActiveSubscriber } from "../../../Api/admin";
import Loader from "../../common/Loader";

const Myclient = () => {
 
  const [data,setData] = useState([])
  const [loader, setloader] = useState(false)
  const [filterData,setFilterData]=useState([])

  const fetchActiveSubscriber = async()=>{
    setloader(true)
    const result = await getActiveSubscriber()
    console.log(result.data)
    setData(result.data.data)
    setFilterData(result.data.data);
    setloader(false)
  }
  
const handleSearchClick = (e) => {
  let val = e.trim().toLowerCase();
  let searchName = filterData.filter((res) => {
    const fullName =
      (res?.fullName?.trim().toLowerCase().replace(/\s+/g, "") || "") +
      (res?.memberId?.trim().toLowerCase().replace(/\s+/g, "") || "");
    return fullName.includes(val.replace(/\s+/g, ""));
  });
  setData(searchName);
};

  useEffect(function(){
    fetchActiveSubscriber()
  },[])

  return (
    <div className={style.ClientTable}>
      <Loader open={loader} />
      <div className="d-flex justify-content-between mb-4 mt-3 ">
        <div>
          {" "}
          <span className="lufga_24px fw_600">Active Subscribers</span>
        </div>
        <div className="d-flex gap-2 align-items-center">
          <div>
            <span className={`p-input-icon-left d-flex align-items-center `}>
              {/* <i className={`pi pi-search ${style.search_icon}`} /> */}
              <div className="searchInput w-100">
                <input
                  className="form-control ps-5"
                  placeholder="Search"
                  onChange={(e) => handleSearchClick(e.target.value)}
                ></input>
                <i className="pi pi-search searchIcon"></i>
              </div>
            </span>
          </div>
        </div>
      </div>

      <ClientTable data={data} role="all_clients" />
    </div>
  );
};

export default Myclient;
