import React, { useState, useEffect } from "react";
import ClientTable from "./LawyerAppointmentListTable";
import style from ".././LawyerMyClient.module.css";
import { InputText } from "primereact/inputtext";
import { getAppointmentByClientId } from "../../../../Api/Lawyer";
import Loader from "../../../common/Loader";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import BackButton from "../../../commonComponents/BackButton/BackButton";

const LawyerAppointmentsList = () => {
    const selector = useSelector(e => e.header)
    const [data, setData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [filterData, setFilterData] = useState([]);
    const params = useParams();

    const clientId = params.clientid;

    const location = useLocation();
    const { appointmentId,ClinetName } = location.state;
    
    const handleSearchClick = (e) => {
        let val = e.trim().toLowerCase();
        let searchName = filterData.filter((res) => {
            const fullName = (res?.appointment_purpose?.trim().toLowerCase().replace(/\s+/g, '') || '') +
                (res?.date?.trim().toLowerCase().replace(/\s+/g, '') || '');
            return fullName.includes(val.replace(/\s+/g, ''));
        });
        setData(searchName);
    };


    const getAppointmentsFunction = async () => {
        try {
            setLoader(true);
            const response = await getAppointmentByClientId({ "clientId": clientId });
            console.log(response, "clients response");
            setData(response.data.data);
            setFilterData(response.data.data)
        } catch (error) {
            console.log("error", error);
        } finally {
            setLoader(false);
        }
    };

    useEffect(() => {
        getAppointmentsFunction();
    }, []);

    return (
        <>
            <Loader open={loader} />
            <BackButton text="Back" path={-1} />
            <div className={`${style.ClientTable}`}>
                <div className="d-flex justify-content-between mb-4 mt-3">
                    <div className="d-flex flex-column gap-2 align-items-start lufga_24px fw_600">

                        <div>Appointments</div>
                        <span className="lufga_22px">
                            <span className="">{clientId}: </span>
                            {ClinetName}
                        </span>
                    </div>

                    <div className="d-flex align-items-center gap-2">
                        <div className="searchInput w-100">
                            <input
                                className="form-control ps-5"
                                placeholder="Search"
                                onChange={(e) => handleSearchClick(e.target.value)}
                            ></input>
                            <i className="pi pi-search searchIcon"></i>
                        </div>
                    </div>

                </div>
                <ClientTable data={data} role="all_clients" />
            </div>
        </>
    );
};

export default LawyerAppointmentsList;
