import React from 'react'
import style from './doctorview.module.css'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { useTranslation } from 'react-i18next'

const DoctorView = ({ resumeone, setResumeone }) => {
    let { t } = useTranslation()
    return (
        <div className={style.AuctionForm}>

            <Dialog
                closable={false}

                visible={resumeone}
                className='p-0 m-0'
                onHide={() => setResumeone(false)}
                style={{ width: "65vw", padding: "0px", margin: "0px" }}
                breakpoints={{ "960px": "75vw", "641px": "100vw" }}
            >
                <div>
                    <div className="d-flex justify-content-end">

                        <Button icon="pi pi-times" className={`p-button-text ${style.crossButton}`} onClick={() => setResumeone(false)} />
                    </div>


                    <div className={style.HeaderDialog}>
                        <div className="d-flex">
                            <div className={style.ProfilePic}>
                                <img src="/images/doctorslist_one.png" alt="" />
                            </div>
                            <div className={style.PrifileHeding}>
                                <div className='d-flex gap-3'>
                                    <div> <span className={style.Textname}>Rebbeca Smith</span></div>
                                    {/* <div> <button className={style.VerfiedButton1}>
                                                <img src="/images/green tick.png" className='w-50' alt="" />
                                                Verified</button></div> */}
                                </div>
                                <br />
                                <div><span className={style.ProfileText}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                                    Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                </span></div>
                            </div>
                        </div>

                        <div className='d-flex justify-content-between px-1 pt-2'>
                            <div className='d-flex gap-3 '>
                                <img src="/images/doctor_view_phone_image.png" alt="" />
                                <span className={style.LoctionText}>393.339.232.222</span>
                            </div>
                            <div className='d-flex gap-3 '>
                                <img src="/images/image 141.png" alt="" />
                                <span className={`me-3 ${style.LoctionText}`}>Rebecca.smith22464@gmail.com</span>
                            </div>
                        </div>
                    </div>


                    <form action="">
                        <div className="row ">

                        </div>
                        
                        <div className="row ">
                            <div className="col-md-12">
                                <label className={`form-check-label mb-1 ${style.LabelText}`} htmlFor="flexCheckDefault">Current Address</label>
                                <input type="text" className="form-control" placeholder="" />
                            </div>
                        </div>


                        <div className="row ">

                            <div className="col-md-6">
                                <label className={`form-check-label mb-1 ${style.LabelText}`} htmlFor="flexCheckDefault">Age</label>
                                <input type="text" className="form-control" placeholder="Enter Your Name" />
                            </div>
                            <div className="col-md-6">
                                <label className={`form-check-label mb-1 ${style.LabelText}`} htmlFor="flexCheckDefault">Date of Birth</label>
                                <input type="date" className="form-control" placeholder="Enter your Contact Id" />
                            </div>
                        </div>

                        

                        <div className="row ">
                            <div className="col-md-6">
                                <label className={`form-check-label mb-1 ${style.LabelText}`} htmlFor="flexCheckDefault">city</label>
                                <input type="text" className="form-control" placeholder="" />
                            </div>

                            <div className="col-md-6">
                                <label className={`form-check-label mb-1 ${style.LabelText}`} htmlFor="flexCheckDefault">State of Residence</label>
                                <input type="text" className="form-control" placeholder="Enter your Contact Number " />
                            </div>

                        </div>

                        <div className="row ">
                            <div className="col-md-6">
                                <label className={`form-check-label mb-1 ${style.LabelText}`} htmlFor="flexCheckDefault">Country</label>
                                <input type="text" className="form-control" placeholder="" />
                            </div>

                            <div className="col-md-6">
                                <label className={`form-check-label mb-1 ${style.LabelText}`} htmlFor="flexCheckDefault">zip code</label>
                                <input type="number" className="form-control" placeholder="Enter your Contact Number " />
                            </div>

                        </div>

                        <div className="row ">
                            <div className="col-md-6">
                                <label className={`form-check-label mb-1 ${style.LabelText}`} htmlFor="flexCheckDefault">Joining date</label>
                                <input type="date" className="form-control" placeholder="" />
                            </div>

                            <div className="col-md-6">
                                <label className={`form-check-label mb-1 ${style.LabelText}`} htmlFor="flexCheckDefault">Years of Experience</label>
                                <input type="text" className="form-control" placeholder="Enter your Contact Number " />
                            </div>

                        </div>


                       

                    </form>


                </div>
            </Dialog>
        </div>
    )
}

export default DoctorView