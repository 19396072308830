import React from 'react'
import style from './UnderConstruction.module.css'

const UnderConstruction = () => {
  return (
    <div>
        <div className={`${style.image_section}`}>
            <img src='/images/undercons.png' alt='Under Construction Image' />
        </div>

        <div className={`${style.content_section}`}>
            <div className={`${style.lufga_20px}`}>Under Construction</div>
            <div className={`${style.lufga_20px} ${style.theme_color}`}>Coming Soon</div>
            <div className={`${style.lufga_20px}`}>Check Back Later</div>
        </div>
    </div>
  )
}

export default UnderConstruction;