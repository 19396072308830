import React, { useEffect, useRef, useState } from "react";
import style from "./MyProfile.module.css";
import { useTranslation } from "react-i18next";
import { CiImageOn } from "react-icons/ci";
import Loader from "../../common/Loader";
import {
  getDashboardUser,
  updateDashboardUser,
  uploadDocument,
} from "../../../Api/CommonApis";
import { Country, State, City } from "country-state-city";
import {
  UpdateSurrogateProfile,
  UpdateSurrogateprofile,
  getSurrogateMotherData,
} from "../../../Api/SurrogateMotherApis";
import moment from "moment";
import ProfileUploadDialog from "./ProfileUploadDialog";
import { Snackbar } from "@mui/material";
import { toast } from "../../../utils/common";
import { changeHeaderData } from "../../../Store/Slices/headerDataSlice";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { FaArrowLeft } from "react-icons/fa";
const MyProfile = () => {
  let { t } = useTranslation();
  const countries = Country.getAllCountries();
  const [country, setCountry] = useState(null);
  const [state, setState] = useState(null);
  const [city, setCity] = useState(null);
  const [statesListing, setStatesListing] = useState([]);
  const [citiesListing, setCitiesListing] = useState([]);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [editable, setEditable] = useState(true);
  const [upload, setUpload] = useState(false);


  let userData = JSON.parse(sessionStorage.getItem("userData"));

  
  let params = useParams();
  const paramId = params.id;
  const userId = paramId
    ? paramId
    : userData.userId;

  

  const dispatch = useDispatch();
  let name = useRef();
  const [loader, setLoader] = useState(true);
  const [img, setImg] = useState(null);
  const [defaultImg, setDefaultimg] = useState("/images/blankProfile.png");
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    middleName: "",
    height: {
      feet: 0,
      inches: 0,
    },
    weight: {
      LBs: 0,
      oz: 0,
    },
    email: "",
    homePhone: "",
    workPhone: "",
    cellPhone: "",
    imageUrl: "",
    imageFileName: "",
    short_description: "",
    age: 0,
    dob: "",
    current_address: "",
    country: "",
    state: "",
    city: "",
    zipCode: "",
    emailVerify: true,
    photos: [],
  });

  async function getUserData() {
    try {
      let res = await getSurrogateMotherData(userId);
      let resultData = res.data.data;
      dispatch(
        changeHeaderData({
          firstName: resultData?.firstName,
          lastName: resultData?.lastName,
          photos: resultData?.photos,
          activeIndex: resultData?.photo_profile_index,
        })
      );
      name.current = resultData?.firstName + " " + resultData?.lastName;
      setPhotoIndex(
        resultData.photo_profile_index !== undefined
          ? resultData?.photo_profile_index
          : resultData?.photos?.length - 1
      );
      setData((pre) => {
        return { ...pre, ...resultData };
      });

      setState(resultData.state);
      setCity(resultData.city);

      if (res.data.data.country !== undefined) {
        let cRes = countries?.find(
          (res2) => res2?.name == res.data.data.country
        );
        setCountry(`${cRes.name}&&${cRes.isoCode}`);
        getStates(`${cRes.name}&&${cRes.isoCode}`, resultData.city);
      }
      if (res.data.data.city !== undefined) {
        setCity(res.data.data.city);
      }
      setLoader(false);
    } catch (err) {
      console.log(err);
      setLoader(false);
    }
  }
  useEffect(() => {
    if (statesListing.length !== 0) {
      let sRes = statesListing?.find((res2) => res2?.name == data.state);
      if (sRes !== undefined) {
        setState(`${sRes.name}&&${sRes.isoCode}&&${sRes.countryCode}`);
        getCities(`${sRes.name}&&${sRes.isoCode}&&${sRes.countryCode}`);
      }
    }
  }, [statesListing]);

  function getImgPreview(file) {
    if (file) {
      let url = URL.createObjectURL(file);
      return url;
    }
  }

  async function editProfileData(e) {
    e.preventDefault();
    let payload = {
      ...data,
      city: city.split("&&")[0],
      state: state.split("&&")[0],
      country: country.split("&&")[0],
    };
    // return
    let formdata = new FormData();
    if (img !== null) {
      formdata.append("uploadDoc", img);
    }

    setLoader(true);
    try {
      if (img !== null) {
        let imageData = await uploadDocument(formdata);
        payload.imageFileName = imageData.data.fileName;
        payload.imageUrl = imageData.data.fileLocation;
      }
      let res = await UpdateSurrogateprofile(userId, payload);
      // return
      setImg(null);
      await getUserData();
      setEditable(true);
      setLoader(false);
    } catch (err) {
      setLoader(false);
      console.log(err);
    }
  }

  function getStates(res) {
    let isoCode = res.split("&&")[1];
    setCountry(res);
    let states = State.getStatesOfCountry(isoCode);
    setStatesListing(states);
    // if (city) {
    //   let cRes = states?.find((res2) => res2?.name == city);
    //   getCities(`${cRes.name}&&${cRes.isoCode}&&${cRes.countryCode}`);
    // }
  }

  function getCities(res) {
    let isoCode = res.split("&&")[1];
    let cCode = res.split("&&")[2];
    setState(res);
    let cities = City.getCitiesOfState(cCode, isoCode);
    setCitiesListing(cities);
  }

  function onInputChange(e) {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }
  const handleProfileImageClick = () => {
    setUpload(true);
  };

  useEffect(() => {
    getUserData();
  }, []);

  function ageCalculate(res) {
    const dob = moment(res);
    const now = moment();
    const age = now.diff(dob, "years");
    setData((pre) => {
      return { ...pre, age: age };
    });
  }
  let [snackbar, setSnackbar] = useState(false);
  let [toastMessage, setToastMessage] = useState("");
  let navigate=useNavigate();

  return (
    <>
     {!params?.id ? null : (
          <div className="mt-4">
            <div
              className="d-flex align-items-center gap-2 btn-Secondary p-0 pointer"
              onClick={() => navigate(-1)}
            >
              <FaArrowLeft /> Back
            </div>
          </div>
        )}
        
      <ProfileUploadDialog
        setUpload={setUpload}
        upload={upload}
        getUserData={getUserData}
      />

      <Snackbar
        open={snackbar}
        autoHideDuration={6000}
        onClose={() => setSnackbar(false)}
        message={toastMessage}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      />

      <Loader open={loader} />
      <div className={style.MyProfile}>
        <form onSubmit={editProfileData}>

          <div className={` ${style.bannersize}`}>
            <div className="d-flex align-items-center gap-3 px-3">
              {editable ? (
                <>
                  <div className={`${style.ProfileImg1}`}>
                    <img
                      src={
                        data?.photos?.length !== 0
                          ? data.photos[photoIndex]?.fileUrl
                          : "/images/blankProfile.png"
                      }
                      className="w-100 h-100"
                      alt=""
                    />
                  </div>
                </>
              ) : (
                <>
                  <label
                    className={`${style.ProfileImg1} pointer border`}
                    onClick={handleProfileImageClick}
                  >
                    <div className={`${style.inputIcon}`}>
                      <CiImageOn />
                    </div>
                    <img
                      src={
                        data?.photos?.length !== 0
                          ? data?.photos[photoIndex]?.fileUrl
                          : "/images/blankProfile.png"
                      }
                      htmlFor="imgInp"
                      className="w-100 h-100"
                      alt=""
                    />
                    {/* <input type='file' hidden id='imgInp' onChange={(e) => {
                                    setDefaultimg(e.target.files[0])
                                    setImg(e.target.files[0])
                                }} 
                                />  */}
                  </label>
                </>
              )}

              <div className="d-flex flex-column align-item-center justify-content-center gap-3">
                <div className={style.BnnerFirstText}>
                  {data.memberId} : Surrogate Mother
                </div>
                <div className={style.BnnersecondtText}>{name.current}</div>
                {/* <div className={`${style.notification_on_off_btn} ${style.notification_on_btn}`}>Notification On</div> */}
              </div>
            </div>
            <div className={`p-3 p-relative ${style.ProfileImg}`}>
              <img
                src="/images/edit_doctor_right_pic.png"
                className={`w-100`}
                alt=""
              />
              {editable ? (
                <div
                  className={`d-flex justify-content-end ${style.ChlidButton}`}
                >
                  {params?.id ? null : (
                    <button
                      className={`text-center d-flex align-items-center ${style.VerfiedButton}`}
                      onClick={() => setEditable(false)}
                    >
                      <i class="bi bi-pencil-square me-1 d-flex align-items-center"></i>
                      <span className={style.verfiedText}>Edit Details</span>
                    </button>
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 mt-3">
              <label
                htmlFor="exampleInputEmail1"
                className={`form-label  ${style.LabelHeading}`}
              >
                {t("First Name")}
                <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className={`form-control`}
                placeholder=""
                disabled={editable}
                value={data?.firstName}
                required
                name="firstName"
                onChange={onInputChange}
              />
            </div>

            <div className="col-md-4 mt-3">
              <label
                htmlFor="exampleInputEmail1"
                className={`form-label  ${style.LabelHeading}`}
              >
                {t("Middle Initial")}
              </label>
              <input
                type="text"
                className={`form-control`}
                placeholder=""
                disabled={editable}
                value={data?.middleName}
                name="middleName"
                onChange={onInputChange}
              />
            </div>
            <div className="col-md-4 mt-3">
              <label
                htmlFor="exampleInputEmail1"
                className={`form-label ${style.LabelHeading}`}
              >
                {t("Last Name")}
                <span className="text-danger">*</span>
              </label>

              <input
                type="text"
                className={`form-control`}
                placeholder=""
                disabled={editable}
                value={data?.lastName}
                required
                name="lastName"
                onChange={onInputChange}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 mt-3">
              <label
                htmlFor="exampleInputEmail1"
                className={`form-label  ${style.LabelHeading}`}
              >
                {t("Age")}
                <span className="text-danger">*</span>
              </label>
              <input
                type="number"
                className={`form-control`}
                placeholder=""
                disabled={true}
                value={data?.age}
                required
                name="age"
                onChange={(e) => {
                  onInputChange(e);
                }}
              />
            </div>

            <div className="col-md-6  mt-3">
              <label
                htmlFor="exampleInputEmail1"
                className={`form-label  ${style.LabelHeading}`}
              >
                {t("Date of Birth")}
                <span className="text-danger">*</span>
              </label>
              <input
                type="date"
                className={`form-control`}
                placeholder=""
                disabled={editable}
                value={data?.dob && moment(data.dob).format("YYYY-MM-DD")}
                required
                name="dob"
                onChange={(e) => {
                  onInputChange(e);
                  ageCalculate(e.target.value);
                }}
              />
            </div>
          </div>

          <div className="col">
            <div className="row">
              <div className="col-md-6  mt-3">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  LabelHeading`}
                >
                  {t("Height")} <span className="text-danger">*</span>
                </label>

                <div className="d-flex justify-content-between gap-2">
                  <input
                    type="number"
                    className="form-control"
                    required
                    disabled={editable}
                    onChange={(e) => {
                      setData((pre) => {
                        pre.height.feet = e.target.value;
                        return { ...pre };
                      });
                    }}
                    value={data.height.feet}
                  />
                  {/* <select
                    className="form-select"
                    aria-label="Default select example"
                    name="feet"
                    disabled={editable}
                   
                   
                  >
                    <option selected>{t("Select")}</option>
                    <option value={2}>{t("2")}</option>
                    <option value={3}>{t("3")}</option>
                    <option value={4}>{t("4")}</option>
                    <option value={5}>{t("5")}</option>
                    <option value={6}>{t("6")}</option>
                    <option value={7}>{t("7")}</option>
                    <option value={8}>{t("8")}</option>
                    <option value={10}>{t("10")}</option>
                  </select> */}

                  <div className="d-flex align-items-center">Ft</div>

                  <input
                    type="number"
                    className="form-control"
                    disabled={editable}
                    value={data.height.inches}
                    required
                    onChange={(e) => {
                      setData((pre) => {
                        pre.height.inches = e.target.value;
                        return { ...pre };
                      });
                    }}
                  />

                  {/* <select
                    className="form-select"
                    aria-label="Default select example"
                    name="inches"

                  >
                    <option selected>{t("Select")}</option>
                    {[...Array(12)].map((e, key) => (
                      <option value={key}>{key + 1}</option>
                    ))}
                  </select> */}
                  <div className="d-flex align-items-center">Inches</div>
                </div>
              </div>

              <div className="col-md-6  mt-3">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label LabelHeading`}
                >
                  {t("Weight")} <span className="text-danger">*</span>
                </label>

                <div className="d-flex justify-content-between gap-2">
                  <input
                    type="number"
                    disabled={editable}
                    value={data.weight.LBs}
                    required
                    onChange={(e) => {
                      setData((pre) => {
                        pre.weight.LBs = e.target.value;
                        return { ...pre };
                      });
                    }}
                    className="form-control"
                  />
                  {/* <select
                    className="form-select"
                    aria-label="Default select example"
                    name="LBs"
                  >
                    <option selected>{t("Select")}</option>
                    <option value={1}>{t("07")}</option>
                    <option value={2}>{t("08")}</option>
                    <option value={3}>{t("09")}</option>
                  </select> */}
                  <div className="d-flex align-items-center">Lbs</div>
                  <input
                    type="number"
                    disabled={editable}
                    value={data.weight.oz}
                    required
                    onChange={(e) => {
                      setData((pre) => {
                        pre.weight.oz = e.target.value;
                        return { ...pre };
                      });
                    }}
                    className="form-control"
                  />
                  {/* <select
                    className="form-select"
                    aria-label="Default select example"
                    name="oz"
                   
                  >
                    <option selected>{t("Select")}</option>
                    <option value={1}>{t("07")}</option>
                    <option value={2}>{t("08")}</option>
                    <option value={3}>{t("09")}</option>
                  </select> */}
                  <div className="d-flex align-items-center">Oz</div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12  mt-3">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("Email Address")}
                </label>
                <input
                  type="text"
                  className={`form-control`}
                  placeholder=""
                  disabled={true}
                  required
                  name="email"
                  value={data?.email}
                  onChange={onInputChange}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-4  mt-3">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("Home Phone")}
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className={`form-control`}
                  placeholder=""
                  disabled={true}
                  required
                  value={data?.homePhone}
                  name="homePhone"
                  onChange={onInputChange}
                />
              </div>

              <div className="col-md-4  mt-3">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("Work Phone")}
                </label>
                <input
                  type="text"
                  className={`form-control`}
                  placeholder=""
                  disabled={true}
                  required
                  value={data?.workPhone}
                  name="workPhone"
                  onChange={onInputChange}
                />
              </div>

              <div className="col-md-4  mt-3">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("Cell Phone")}
                </label>
                <input
                  type="text"
                  className={`form-control`}
                  placeholder=""
                  disabled={true}
                  required
                  value={data?.cellPhone}
                  name="cellPhone"
                  onChange={onInputChange}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-12  mt-3">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("Current Address")}
                  <span className="text-danger">*</span>
                </label>

                <input
                  type="text"
                  className={`form-control`}
                  placeholder=""
                  disabled={editable}
                  required
                  value={data?.current_address}
                  name="current_address"
                  onChange={onInputChange}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6  mt-3">
              <label
                htmlFor="exampleInputEmail1"
                className={`form-label  ${style.LabelHeading}`}
              >
                {t("Country")}
                <span className="text-danger">*</span>{" "}
              </label>
              <select
                className={`form-select`}
                value={country}
                required
                disabled={editable}
                onChange={(e) => {
                  getStates(e.target.value);
                  setState("");
                  setCity("");
                }}
              >
                <option selected disabled value={""}>
                  {t("Select")}
                </option>
                {countries.map((res, index) => {
                  return (
                    <option key={index} value={`${res.name}&&${res.isoCode}`}>
                      {res.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="col-md-6  mt-3">
              <label
                htmlFor="exampleInputEmail1"
                className={`form-label  ${style.LabelHeading}`}
              >
                {t("State of Residence")}
              
              </label>
              <select
                className={`form-select`}
                value={state}
                disabled={editable}
                onChange={(e) => {
                  getCities(e.target.value);
                  setCity("");
                }}
              >
                <option selected disabled value={""}>
                  {t("Select")}
                </option>
                {statesListing?.map((res, index) => {
                  return (
                    <option
                      key={index}
                      value={`${res.name}&&${res.isoCode}&&${res.countryCode}`}
                    >
                      {res.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="col-md-6  mt-3">
              <label
                htmlFor="exampleInputEmail1"
                className={`form-label  ${style.LabelHeading}`}
              >
                {t("City")}
              
              </label>
              <select
                className={`form-select`}
                value={city}
               
                disabled={editable}
                onChange={(e) => setCity(e.target.value)}
              >
                <option selected disabled value={""}>
                  {t("Select")}
                </option>
                {citiesListing?.map((res, index) => {
                  return (
                    <option key={index} value={`${res.name}`}>
                      {res.name}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="col-md-6  mt-3">
              <label
                htmlFor="exampleInputEmail1"
                className={`form-label ${style.LabelHeading}`}
              >
                {t("Zip Code")}
                <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className={`form-control`}
                required
                placeholder=""
                disabled={editable}
                value={data?.zipCode}
                name="zipCode"
                onChange={onInputChange}
              />
            </div>
          </div>
          <div className="col-md-12">
            {editable ? (
              ""
            ) : (
              <div className="d-flex justify-content-end gap-4 mt-5">
                <button
                  className={style.CancelButton}
                  type="button"
                  onClick={() => setEditable(true)}
                >
                  Cancel
                </button>
                <button className={style.SaveButton} type="submit">
                  Save Changes
                </button>
              </div>
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default MyProfile;
