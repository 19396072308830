import React, { useState, useEffect } from "react"
import { Dialog } from 'primereact/dialog';
import LawyerAddPocDialog from "./LawyerAddPocDialog";
import LawyerEditPoc from "./LawyerEditPoc";
import { addAndUpdatePoc, getClientDataById } from "../../../../Api/Lawyer";
import Loader from "../../../common/Loader";


const LawyerAddPoc = ({ clientId, pocInfo }) => {

    console.log("pocInfo for assPoc component", pocInfo)
    const [addocument, setAddDocument] = useState(false);
    const [editdocument, setEditDocument] = useState(false);
    const [data, setData] = useState({});
    const [loader, setLoader] = useState(false);

    const getClientDataByIdFunction = async () => {
        try {
            setLoader(true);
            const response = await getClientDataById(clientId);
            console.log(response, "poc Data")
            var clientData = response.data.data;
            setData(clientData);
            console.log(clientData.clientId, "rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr")
        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false);
        }
    };


    useEffect(() => {
        getClientDataByIdFunction()
    }, [])

    return (
        <>
            <Loader open={loader} />
            {console.log(data.clientId, "this is tidhreihwijjhrtjhdjsk")}
            <LawyerAddPocDialog addocument={addocument} setAddDocument={setAddDocument}
                reload={getClientDataByIdFunction}
                appointmentId={data.clientId}
                username={data.name}
            />

            <LawyerEditPoc apoint={editdocument} setApoint={setEditDocument}
                reload={getClientDataByIdFunction}
                appointId={clientId} pocInfo={pocInfo} clientId={data.clientId}
            />

            <div className="d-flex align-items-center">
                <div className="lufga_20px">Assigned POC : </div>
                {data?.poc ?
                    <div className="pointer theme_color lufga_20px" onClick={() => setEditDocument(true)}>&nbsp; {data?.poc.name}</div>
                    :
                    <div className="pointer theme_color lufga_20px" onClick={() => setAddDocument(true)}>&nbsp; +Add POC</div>
                }
            </div>
        </>
    );
}

export default LawyerAddPoc;