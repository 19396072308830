import React, { useState } from 'react'
import style from './Resumes.module.css'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
const EditResume = () => {
  let { t } = useTranslation()

  const [resumeData, setResumeData] = useState(
    {
      service_offer: '',
      short_description: '',
      age: 78,
      height: {
        feet: 0,
        inches: 8
      },
      weight: {
        LBs: 0,
        oz: 90
      },
      dob: '',
      current_address: '',
      country: '',
      state: '',
      city: '',
      zipCode: '',
      life_style: '',
      spending_free_time_hobby: '',
      race: '',
      medical_compatibility: '',
      child_details: [
        {
          gender: '',
          dob: '',
          conceive: 0,
          weeks_at_delivery: 0,
          body_weight: {
            lbs: 6,
            oz: 8
          },
          ob_name: '',
          delivery_hospital_state: '',
          this_baby_was: ''
        },
        {
          gender: '',
          dob: '',
          conceive: 2312,
          weeks_at_delivery: 31231,
          body_weight: {
            lbs: 6,
            oz: 8
          },
          ob_name: '',
          delivery_hospital_state: '',
          this_baby_was: ''
        }
      ]
    }

  )
  function ResumeUpdate(e) {
    e.preventDefault()
    console.log(resumeData)
  }

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    console.log(value)
    console.log(checked)
    console.log(name)

    if (type === 'checkbox') {
      setResumeData((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
    } else {
      setResumeData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  return (
    <div>
      <div className={style.CreditReport_con}>

        <div className='d-flex justify-content-between mb-4'>

        </div>
        <div className={`mb-5 ${style.bannersize}`}>
          <div className='d-flex'>
            <div className='p-4'>
              <img src="/images/child5 (2).png" alt="" />
            </div>

            <div className='d-flex flex-column align-item-center justify-content-center gap-3'>
              <div className={style.BnnerFirstText}>NPHIP001 : Surrogate Mother</div>
              <div className={style.BnnersecondtText}>Alexa Smith</div>
              <div className='d-flex gap-4 align-items-center'>
                <button className={`text-center ${style.VerfiedButton_white}`} ><i class="bi bi-patch-check text-center me-2" ></i>Verified</button>
                <div className='lufga_16px theme_color' style={{ fontSize: '500' }}>Update/View Resume</div>
              </div>
            </div>
          </div>
          <div className=''>
            <img src="/images/child5 (1).png" alt="" />
          </div>
        </div>
        <form onSubmit={ResumeUpdate}>
          <div className={style.child1}>
            <div>
              <div className='theme_color lufga_24px'>Profile info for match-making</div>
              <div className='CheckBoxParent row'>
                <div className="col-md-6">
                  <label className={`form-label  ${style.LabelHeading}`} htmlFor="flexRadioDefault1">
                    {t("Service offered")}<span className='text-danger'>*</span>
                  </label>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-check">

                        <input className={`form-check-input ${style.InputCustom}`} type="checkbox" defaultValue id="flexCheckDefault" />
                        <label className={`form-check-label ${style.SubLAbelHedaing}`} htmlFor="flexCheckDefault">

                          {t("Egg/Sperm Donor")}
                        </label>
                      </div>
                    </div>

                    <div className="col-md-6">  <div className="form-check">
                      <input className={`form-check-input ${style.InputCustom}`} type="checkbox" defaultValue id="flexCheckDefault" />
                      <label className={`form-check-label ${style.SubLAbelHedaing}`} htmlFor="flexCheckDefault">
                        {t("Surrogate Mother")}
                      </label>
                    </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="form-label LabelHeading" htmlFor="flexRadioDefault1">

                  </label>
                  {/* <div className='d-flex gap-3 justify-content-end mt-2'>
          <button className={style.SaveProfileButton}>Save Profile</button>
          <button className={style.ViewResumeButton}>View Resume</button>
        </div> */}

                </div>
              </div>

            </div>
            <div className="row">
              <div className="col">

                <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`}>{t("Short description")}<span className='text-danger'>*</span></label>

                <input
                  required
                  type='text'
                  className='form-control'
                  placeholder=''
                  name='short_description'
                  value={resumeData.short_description}
                  onChange={handleInputChange}
                />

              </div>

            </div>

            <div className='row'>
              <div className="col-md-6">
                <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`} >{t("Age")}<span className='text-danger'>*</span></label>

                <input
                  required
                  type='text'
                  className='form-control'
                  placeholder=''
                  name='short_description'
                  value={resumeData.short_description}
                  onChange={handleInputChange}
                />
              </div>

              <div className="col-md-6">
                <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`} >{t("Date of birth")}<span className='text-danger'>*</span></label>

                <input
                  required
                  type='text'
                  className='form-control'
                  placeholder=''
                  name='short_description'
                  value={resumeData.short_description}
                  onChange={handleInputChange}
                />
              </div>

            </div>

            <div className="row">


              <div className='col-md-6'>
                <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`}>{t("Height")} <span className='text-danger'>*</span></label>

                <div className='d-flex justify-content-between gap-2'>
                  <select className={`form-select $`} aria-label="Default select example">
                    <option selected>{t("select")}</option>
                    <option value={1}>{t("07")}</option>
                    <option value={2}>{t("08")}</option>
                    <option value={3}>{t("09")}</option>

                  </select>

                  <div className="d-flex align-items-center">ft</div>

                  <select className={`form-select $`} aria-label="Default select example">
                    <option selected>{t("select")}</option>
                    <option value={1}>{t("07")}</option>
                    <option value={2}>{t("08")}</option>
                    <option value={3}>{t("09")}</option>

                  </select>
                  <div className="d-flex align-items-center">inches</div>
                </div>


              </div>

              <div className='col-md-6'>
                <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`}>{t("Weight")} <span className='text-danger'>*</span></label>

                <div className='d-flex justify-content-between gap-2'>
                  <select className={`form-select $`} aria-label="Default select example">
                    <option selected>{t("select")}</option>
                    <option value={1}>{t("07")}</option>
                    <option value={2}>{t("08")}</option>
                    <option value={3}>{t("09")}</option>

                  </select>
                  <div className="d-flex align-items-center">lbs</div>
                  <select className={`form-select $`} aria-label="Default select example">
                    <option selected>{t("select")}</option>
                    <option value={1}>{t("07")}</option>
                    <option value={2}>{t("08")}</option>
                    <option value={3}>{t("09")}</option>

                  </select>
                  <div className="d-flex align-items-center">oz</div>
                </div>


              </div>

            </div>

            <div className="row">
              <div className="col-md-12">
                <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`}>{t("Current Address")}<span className='text-danger'>*</span></label>

                <input
                  required
                  type='text'
                  className='form-control'
                  placeholder=''
                  name='short_description'
                  value={resumeData.short_description}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`}>{t("City")} </label>
                <select className={`form-select $`} aria-label="Default select example">
                  <option selected>{t("select")}</option>
                  <option value={1}>{t("07")}</option>
                  <option value={2}>{t("08")}</option>
                  <option value={3}>{t("09")}</option>

                </select>
              </div>
              <div className="col-md-4">
                <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`} >{t("State of Residence")} </label>
                <select className={`form-select $`} aria-label="Default select example">
                  <option selected>{t("select")}</option>
                  <option value={1}>{t("07")}</option>
                  <option value={2}>{t("08")}</option>
                  <option value={3}>{t("09")}</option>

                </select>
              </div>
              <div className="col-md-4">
                <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`} >{t("Zip Code")} </label>
                <input
                  required
                  type='text'
                  className='form-control'
                  placeholder=''
                  name='short_description'
                  value={resumeData.short_description}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`} >{t("Write about your lifestyle")} </label>
                <input
                  required
                  type='text'
                  className='form-control'
                  placeholder=''
                  name='short_description'
                  value={resumeData.short_description}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`} >{t("Write about your Hobbies")} </label>
                <input
                  required
                  type='text'
                  className='form-control'
                  placeholder=''
                  name='short_description'
                  value={resumeData.short_description}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="row">


              <div className="col-md-6">
                <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`} >{t("Skin Colour")} </label>
                <select className={`form-select $`} aria-label="Default select example">
                  <option selected>{t("select")}</option>
                  <option value={1}>{t("07")}</option>
                  <option value={2}>{t("08")}</option>
                  <option value={3}>{t("09")}</option>

                </select>
              </div>
              <div className="col-md-6">
                <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`} >{t("Medical Compatibility")} </label>
                <select className={`form-select $`} aria-label="Default select example">
                  <option selected>{t("select")}</option>
                  <option value={1}>{t("07")}</option>
                  <option value={2}>{t("08")}</option>
                  <option value={3}>{t("09")}</option>

                </select>
              </div>
            </div>

            <div className="section2">

              {/* <div className={`row style.HistoryText`}>
      <div className='col'>Reproductive History</div>
    </div> */}



              <div className="child1">
                <div >
                  <div className='row my-3'><span className={`lufga_18px ${style.MiddleSubHeading}`} style={{ fontSize: "900" }}>{t("Child #1")}</span></div>

                  <div className='CheckBoxParent row'>
                    <div className="col-md-6">
                      <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`} >{t("Gender")} </label>

                      <div className="row align-items-center d-flex">
                        <div className="col-md-6 p-0 ps-3">
                          <div className="form-check">
                            <input className={`form-check-input ${style.InputCustom}`} type="radio" defaultValue id="flexRadioDefault1" />
                            <label className={`form-check-label ${style.SubLAbelHedaing}`} htmlFor="flexRadioDefault1">

                              {t("Egg/Sperm Donor")}
                            </label>
                          </div>
                        </div>

                        <div className="col-md-6 p-0">

                          <div className="form-check">
                            <input className={`form-check-input ${style.InputCustom}`} type="radio" defaultValue id="flexRadioDefault1" />
                            <label className={`form-check-label ${style.SubLAbelHedaing}`} htmlFor="flexRadioDefault1">
                              {t("Surrogate Mother")}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`} >{t("Date of Birth")} </label>
                      <input
                  required
                  type='Date'
                  className='form-control'
                  placeholder=''
                  name='short_description'
                  value={resumeData.short_description}
                  onChange={handleInputChange}
                />

                    </div>
                  </div>
                  <div className="row">

                    <div className="col-md-6">
                      <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`} >{t("Months to conceive")} </label>
                      <select className={`form-select $`} aria-label="Default select example">
                        <option selected>{t("select")}</option>
                        <option value={1}>{t("07")}</option>
                        <option value={2}>{t("08")}</option>
                        <option value={3}>{t("09")}</option>

                      </select>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`} >{t("Weeks at Delivery")} </label>
                      <input
                  required
                  type='text'
                  className='form-control'
                  placeholder=''
                  name='short_description'
                  value={resumeData.short_description}
                  onChange={handleInputChange}
                />

                    </div>
                  </div>


                  <div className="row">
                    <div className='col-md-6'>
                      <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`} >{t("Baby’s Weight")} </label>
                      <div className='d-flex justify-content-between gap-2'>
                        <select className={`form-select $`} aria-label="Default select example">
                          <option selected>{t("select")}</option>
                          <option value={1}>{t("07")}</option>
                          <option value={2}>{t("08")}</option>
                          <option value={3}>{t("09")}</option>

                        </select>
                        <div className="d-flex align-items-center">lbs</div>
                        <select className={`form-select $`} aria-label="Default select example">
                          <option selected>{t("select")}</option>
                          <option value={1}>{t("07")}</option>
                          <option value={2}>{t("08")}</option>
                          <option value={3}>{t("09")}</option>

                        </select>
                        <div className="d-flex align-items-center">oz</div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`} >{t("Name of OB")} </label>
                      <input
                  required
                  type='text'
                  className='form-control'
                  placeholder=''
                  name='short_description'
                  value={resumeData.short_description}
                  onChange={handleInputChange}
                />

                    </div>
                  </div>
                  <div className='row'>
                    <div className="col-md-12">
                      <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`} >{t("Delivery Hospital and State")} </label>
                      <input
                  required
                  type='text'
                  className='form-control'
                  placeholder=''
                  name='short_description'
                  value={resumeData.short_description}
                  onChange={handleInputChange}
                />

                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`} >{t("This baby was")} </label>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-check">
                            <input className={`form-check-input ${style.InputCustom}`} type="radio" defaultValue id="flexRadioDefault1" />
                            <label className={`form-check-label ${style.SubLAbelHedaing}`} htmlFor="flexRadioDefault1">

                              {t("My Own Child")}
                            </label>
                          </div>
                        </div>

                        <div className="col-md-6">

                          <div className="form-check">
                            <input className={`form-check-input ${style.InputCustom}`} type="radio" defaultValue id="flexRadioDefault1" />
                            <label className={`form-check-label ${style.SubLAbelHedaing}`} htmlFor="flexRadioDefault1">
                              {t("Surrogate Birth")}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
         
          </div>
        </form>


      </div>
    </div>
  )
}

export default EditResume