import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import style from "./style.module.css";
import { useNavigate, useOutletContext, useParams } from "react-router";
import moment from "moment/moment";
import { buySubscriptionPlan } from "../../../Api/CommonApis";
import Loader from "../../common/Loader";
import Toaster from "../../common/Toaster";
import SubscribePaymentModule from "./SubscribePaymentModule";

export default function PaymentDialog({ role }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, fetchData } = useOutletContext();
  const [selectPaymentMethod, setSelectPaymentMethod] = useState("paypal");
  const [planData, setPlanData] = useState({});
  const [openSucees, setOpenSuccess] = useState(false)

  const userData =
    role === "ip" || "embryo_donor"
      ? {
        userId: sessionStorage.getItem("userId"),
      }
      : JSON.parse(sessionStorage.getItem("userData"));

  const [loader, setLoader] = useState(false);
  const [toaster, setToaster] = useState({
    show: false,
    message: "",
  });

  function getEndDate(duration) {
    return moment().add(duration, "months")._d;
  }

  async function pay() {
    if (selectPaymentMethod == "cashApp") {
      setToaster({
        show: true,
        message: "Cash App is currently unavailable.",
      });
      return;
    }
    setLoader(true);
    try {
      let payload = {
        plan_name: planData?.plan_name,
        price: planData?.amount.toString(),
        status: true,
        startDate: new Date(),
        endDate: getEndDate(+planData?.validity?.split(" ")[0]),
        plan_id: planData._id,
        transaction_id: "f65g465sg4654s65g4sd5g4",
      };
      // setOpenSuccess(true)

      if (selectPaymentMethod == "paypal") {
        let res = await buySubscriptionPlan(userData?.userId, payload);


      } else {
        setToaster({
          show: true,
          message: "Cash App is currently unavailable.",
        });
        setLoader(false);
        return;
      }
    } catch (err) {
      console.log(err);
    } finally {
      fetchData();
      setLoader(false);

      const path =
        role === "surrogate"
          ? "/surrogate/subplan/success"
          : role === "intendedParent"
            ? "/intendedParent/subscription/success"
            : role === "embryo_donor"
              ? "/embryo_donor/subscription/success"
              : "/intendedParent/subscription/success"; // Add a default path if necessary

      navigate(path);
    }
  }

  useEffect(() => {
    let plan = data?.find((res) => res._id == id);
    console.log(plan)
    setPlanData(plan);
  }, [data]);

  return (
    <>
      <Toaster
        event={toaster.message}
        show={toaster.show}
        severity={"warn"}
        setToaster={setToaster}
      />
      <Loader open={loader} />
      <Dialog
        closable={false}
        visible={true}
        onHide={() => navigate("/surrogate/subplan")}
        contentClassName="p-lg-5 p-md-4 p-sm-4 p-3 "
        style={{ width: "750px" }}
        breakpoints={{ "960px": "700", "790px": "90vw" }}
      >
        <div className='d-flex justify-content-center'>
          <span className={`${style.header}`}>Choose How To Pay</span>
        </div>
        <div className='d-flex justify-content-center'>
          <span className={`${style.discription}`}>Payment Method</span>
        </div>
        <div className="d-flex align-items-center mt-4 mb-5">
          <input
            className="form-check-input me-3"
            type="radio"
            value="paypal"
            checked={selectPaymentMethod === "paypal"}
            id="flexRadioDefault1"
            onChange={(e) => setSelectPaymentMethod(e.target.value)}
          />
          <label className="fs-5 label" htmlFor="flexRadioDefault1">
            Paypal
          </label>
        </div>
        <hr />
        <div className="d-flex align-items-center my-5">
          <input
            className="form-check-input me-3"
            type="radio"
            value="cashApp"
            checked={selectPaymentMethod === "cashApp"}
            id="flexRadioDefault"
            onChange={(e) => setSelectPaymentMethod(e.target.value)}
          />
          <label className="fs-5 label" htmlFor="flexRadioDefault">
            Cash App
          </label>
        </div>
        <div className="d-flex justify-content-end gap-2">
          <button
            className="btn-Secondary"
            onClick={() =>
              navigate(
                -1
              )
            }
          >
            Cancel
          </button>
          <button className="btn-Primary" onClick={() => pay(planData)}>
            Pay $ {planData?.amount}
          </button>
        </div>
      </Dialog>
      {/* <SubscribePaymentModule openSucees={openSucees}/> */}
    </>
  );
}
