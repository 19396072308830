import React, { useState, useEffect } from "react";
import style from "./CreatePost.module.css";
import Toaster from "../../../common/Toaster";

const ImageUpload = ({ image, setImage, allowedFileTypes = ["image/jpeg", "image/png"], ...props }) => {
  const [fileSrc, setFileSrc] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [toaster, setToaster] = useState({
    show: false,
    message: "",
  });

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file && !allowedFileTypes.includes(file.type)) {
      const allowedTypesString = allowedFileTypes.map(type => {
        switch(type) {
          case "image/jpeg": return "JPEG";
          case "image/png": return "PNG";
          case "application/pdf": return "PDF";
          default: return type;
        }
      }).join(", ");
      
      setToaster({
        show: true,
        message: `Please upload a valid file (${allowedTypesString} only)`,
      });
      return;
    }

    const reader = new FileReader();

    reader.onloadend = () => {
      setFileSrc(reader.result);
      setFileType(file.type);
    };

    if (file) {
      setImage(file);
      reader.readAsDataURL(file);
    }
  };

  const handleButtonClick = () => {
    const fileInput = document.getElementById("file");
    fileInput.click();
  };

  useEffect(() => {
    if (image && image?.name) {
      setFileSrc(URL.createObjectURL(image));
      setFileType(image.type);
    }
    else{
      setFileSrc(image)
    }
  }, [image]);

  return (
    <>
      <Toaster
        event={toaster.message}
        show={toaster.show}
        severity={"warn"}
        setToaster={setToaster}
      />
      <div {...props} className="d-flex flex-column justify-content-center align-items-center">
        <label
          className="d-flex justify-content-center align-items-center flex-column w-50"
          htmlFor="file"
        >
          {fileSrc ? (
            fileType === "application/pdf" ? (
              <embed
              src={`${fileSrc}#toolbar=0&navpanes=0`}
                type="application/pdf"
                height={320}
                width={"100%"}
                style={{ borderRadius: "16px" }}
              />
            ) : (
              <img
                src={fileSrc}
                alt="Uploaded"
                height={320}
                width={320}
                style={{ borderRadius: "16px" }}
              />
            )
          ) : (
            <div>
              <img
                src="/images/uploadeLogoImage.png"
                height={120}
                width={120}
              />
            </div>
          )}
          <div className="mt-2 d-flex">
            {fileSrc ? (
              <button
                className={`py-2 px-5 f-13 ${style.SaveButton}`}
                type="button"
                size="sm"
                color="primary"
                onClick={handleButtonClick}
              >
                Change File
              </button>
            ) : (
              <button
                className={`py-2 px-5 f-13 ${style.SaveButton}`}
                type="button"
                size="lg"
                color="primary"
                onClick={handleButtonClick}
              >
                Upload
              </button>
            )}
          </div>
        </label>
        <input type="file" hidden id="file" onChange={handleFileChange} />
      </div>
    </>
  );
};

export default ImageUpload;
