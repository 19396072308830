import React from 'react'
import "./popovericon.css"

export const PopoverItem = ({text,icon,...props}) => {
    return (
        <div className='popover_item gap-3' {...props}>
            <div>
                {icon}
            </div>
            <div>
                {text}
            </div>
        </div>
    )
}