import React, { useEffect, useState } from "react";
import style from "../../../IvfClinicComponents/MyProfile/MyProfile.module.css";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import ActiveDelete from "../../../commonComponents/ActiveDelete/ActiveDelete";
import styles from "./IvfClinicActiveClients.module.css";
import { getDashboardUser } from "../../../../Api/CommonApis";
import Loader from "../../../common/Loader";
import { FaArrowLeft } from "react-icons/fa";

const IvfuserProfile = () => {
  let { t } = useTranslation();
  let userId = useParams().id;
  const navigate = useNavigate();

  function EditProfile() {
    navigate("editprofileivf");
  }

  const [userData, setUserData] = useState({});
  const [loader, setLoader] = useState(true);
  const [defaultImg, setDefaultimg] = useState('/images/ivfclinichospitalimage.png')

  async function getUserData() {
    try {

      let res = await getDashboardUser(userId);
      setUserData(res.data.data);
      console.log(res);


      setLoader(false);
    } catch (err) {
      console.log(err);
      setLoader(false);
    }
  }

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <>

      <Loader open={loader} />

      <div className="mt-4">
        <div
          className="d-flex align-items-center gap-2 btn-Secondary p-0 pointer"
          onClick={() => navigate(-1)}
        >
          <FaArrowLeft /> Back
        </div>
      </div>


      <div className={style.MyProfile}>
        <div className={`mb-5 ${style.bannersize}`}>
          <div className="d-flex">
           
            <div className="p-4">
              <img src={userData?.imageUrl || `/images/ivfclinichospitalimage.png`} alt="" className={styles.ProfileImages} />
            </div>

            <div className="d-flex flex-column align-item-center justify-content-center gap-3">
              <div className={style.BnnerFirstText}>{userData.memberId} : Ivf Clinic</div>
              <div className={style.BnnersecondtText}>
                {userData.fullName}
              </div>
              <div>
                <ActiveDelete id={userId} user_blocked={userData?.user_blocked} target={"ivfclinics"} />
              </div>
            </div>
          </div>
          <div className={`p-2 ${style.ParentButton}`}>
            <img src="/images/ivf_clinic_my_profile_header_right.png" alt="" />
            {/* <div className={`d-flex justify-content-end ${style.ChlidButton}`}>
                        <button className={`text-center d-flex align-items-center ${style.VerfiedButton}`}><i class="bi bi-pencil-square me-1 "></i>
                            <span className={style.verfiedText} onClick={EditProfile}>Edit Details</span>
                        </button>
                    </div> */}
          </div>
        </div>

        <form action="">
          <div className="row mb-4">
            <div className="col">
              <label
                htmlFor="exampleInputEmail1"
                className={`form-label  ${style.LabelHeading}`}
              >
                {t("Clinic Name")}
                <span className="text-danger">*</span>
              </label>

              <input
                disabled
                type="text"
                className={`form-control ${style.InputCustom}`}
                placeholder=""
                value={userData.fullName}
              />
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-md-6 mb-4">
              <label
                htmlFor="exampleInputEmail1"
                className={`form-label  ${style.LabelHeading}`}
              >
                {t("Communication Email Address")}
                <span className="text-danger">*</span>
              </label>

              <input
                disabled
                type="email"
                className={`form-control ${style.InputCustom}`}
                placeholder=""
                value={userData.email}
              />
            </div>
            <div className="col-md-6 mb-4">
              <label
                htmlFor="exampleInputEmail1"
                className={`form-label  ${style.LabelHeading}`}
              >
                {t("Password")}
                <span className="text-danger">*</span>
              </label>

              <input
                disabled
                type="password"
                className={`form-control ${style.InputCustom}`}
                placeholder=""
                value={userData.password}
              />
            </div>

            <div className="col">
              <div className="row mb-4">
                <div className="col-md-6">
                  <label
                    htmlFor="exampleInputEmail1"
                    className={`form-label  ${style.LabelHeading}`}
                  >
                    {t("Contact Number")}
                    <span className="text-danger">*</span>
                  </label>

                  <input
                    disabled
                    type="number"
                    className={`form-control ${style.InputCustom}`}
                    placeholder=""
                    value={userData.mobile_No}
                  />
                </div>
                <div className="col-md-6">
                  <label
                    htmlFor="exampleInputEmail1"
                    className={`form-label  ${style.LabelHeading}`}
                  >
                    {t("Alternative Contact Number")}
                  </label>

                  <input
                    disabled
                    type="number"
                    className={`form-control ${style.InputCustom}`}
                    placeholder=""
                    value={userData.mobile_No_alter}
                  />
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-md-12">
                  <label
                    htmlFor="exampleInputEmail1"
                    className={`form-label  ${style.LabelHeading}`}
                  >
                    {t("Current Address")}
                    <span className="text-danger">*</span>
                  </label>

                  <input
                    disabled
                    type="text"
                    className={`form-control ${style.InputCustom}`}
                    placeholder=""
                    value={userData.address}
                  />
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-md-6">
                  <label
                    htmlFor="exampleInputEmail1"
                    className={`form-label  ${style.LabelHeading}`}
                  >
                    {t("City")} <span className="text-danger">*</span>
                  </label>
                  <select
                    disabled
                    className={`form-select ${style.InputCustom}`}
                    aria-label="Default select example"
                  >
                    <option selected>{t(userData.city)}</option>
                    {/* <option value={1}>{t("Noida")}</option>
                    <option value={2}>{t("Aligarh")}</option>
                    <option value={3}>{t("Rampur")}</option> */}
                  </select>
                </div>
                <div className="col-md-6">
                  <label
                    htmlFor="exampleInputEmail1"
                    className={`form-label  ${style.LabelHeading}`}
                  >
                    {t("State of Residence")}
                    <span className="text-danger">*</span>{" "}
                  </label>
                  <select
                    disabled
                    className={`form-select ${style.InputCustom}`}
                    aria-label="Default select example"
                  >
                    <option selected>{t(userData.state)}</option>
                    {/* <option value={1}>{t("07")}</option>
                    <option value={2}>{t("08")}</option>
                    <option value={3}>{t("09")}</option> */}
                  </select>
                </div>

              </div>
              <div className="row mb-4">
                <div className="col-md-6">
                  <label
                    htmlFor="exampleInputEmail1"
                    className={`form-label  ${style.LabelHeading}`}
                  >
                    {t("Country")} <span className="text-danger">*</span>
                  </label>
                  <select
                    disabled
                    className={`form-select ${style.InputCustom}`}
                    aria-label="Default select example"
                  >
                    <option selected>{t(userData.country)}</option>
                    {/* <option value={1}>{t("Noida")}</option>
                    <option value={2}>{t("Aligarh")}</option>
                    <option value={3}>{t("Rampur")}</option> */}
                  </select>
                </div>

                <div className="col-md-6">
                  <label
                    htmlFor="exampleInputEmail1"
                    className={`form-label  ${style.LabelHeading}`}
                  >
                    {t("Zip Code")}
                    <span className="text-danger">*</span>{" "}
                  </label>
                  <input
                    disabled
                    type="text"
                    className={`form-control ${style.InputCustom}`}
                    placeholder=""
                    value={userData.zip_code}
                  />
                </div>
              </div>
            </div>
            {/* <div className='d-flex justify-content-end gap-4'>
                        <button className={style.CancelButton}>cancel</button>
                        <button className={style.SaveButton}>Save Changes</button>
                    </div> */}
          </div>
        </form>
      </div>
    </>
  );
};

export default IvfuserProfile;
