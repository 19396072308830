import { callSnackBar } from "../Store/Slices/ToastSlice";
import { store } from "../Store/store";




export const toast = {
  success: (e) => store.dispatch(callSnackBar({type:"success",msg:e,show:true})),
  error: (e) => store.dispatch(callSnackBar({type:"error",msg:e,show:true})),
};

export const userID = () => {
  let userId = null;
  let userData = sessionStorage.getItem("userData")

  if (userData) {
      userId = JSON.parse(userData).userId

  }
  return userId

}