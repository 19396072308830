import React from 'react'
import style from "./BidNowDialog.module.css"
import { Dialog } from 'primereact/dialog'
import { useTranslation } from 'react-i18next'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useState } from 'react'
import { InputNumber } from 'primereact/inputnumber';
import { useEffect } from 'react'
import { bidOnAuction, getAuctionDataById } from '../../../../Api/IntendedParent'
import Loader from '../../../common/Loader';

function BidNowDialog({show,reload,setShow,id}) {
  let { t } = useTranslation()
  const [loader, setLoader] = useState(true)
  // const { id } = useParams()
  const navigate = useNavigate()
  const [auctionData, setAuctionData] = useState({})
  const [bidValue, setBidValue] = useState(0)
  const userId = sessionStorage.getItem('userId')
  const [showDialog, setShowDialog] = useState(false)

// let {getAllAuction}=useOutletContext()

  useEffect(() => {
    setShowDialog(show)


  }, [show])

  useEffect(() => {
    if (bidValue < auctionData?.highest_Bid?.bid) {
      setBidValue(5000)
    }
  }, [bidValue])
  async function getAuctionData() {

    try {

      let res = await getAuctionDataById(id)
      setAuctionData(res.data.data)
     
      
      if(res.data.data.highest_Bid.biderId !== undefined){
        setBidValue(res.data.data.highest_Bid.bid)
      }else{
        setBidValue(res.data.data.starting_bid_amount)
      }
      setLoader(false)
    } catch (err) {
      setLoader(false)
      console.log(err)
    }

  }


  useEffect(() => {
    getAuctionData()
  }, [show])

  async function submitBid() {
    setLoader(true)
    let payload = {
      biderId: userId,
      bid: +(bidValue)
    }
    try {
      let res = await bidOnAuction(id, payload)
      await getAuctionData()
      await reload()
      setLoader(false)
      setShow(false)
      // navigate('/intendedParent/auctions')
    } catch (err) {
      setLoader(false)
      console.log(err)
    }

  }


  return (
    <div>
      <Loader open={loader} />
      <Dialog
        closable={false}
        visible={showDialog}
        className='p-0 m-0'
        onHide={() => { }}
        style={{ width: "30vw", padding: "0px", margin: "0px" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <div className='bidnow'>
          <div className='py-4'>
            <span className={style.BidingText}>{`Bidding on SM Id "${auctionData?.memberId}"`}</span>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex  align-items-center">
              <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`} >{t("Bidding Amount in ($)")}<span className='text-danger fs-5'>*</span> </label>
            </div>
            <div className="">
              <div className='d-flex justify-content-center align-items-center gap-2 '>
                <button className={style.PlusIcon} disabled={bidValue == auctionData?.highest_Bid?.bid || bidValue===auctionData?.starting_bid_amount} onClick={() => setBidValue(pre => pre - 1000)}>-</button>
                {/* <span className={style.NumberText}>5500 </span>  */}
                <InputNumber inputId="withoutgrouping" inputClassName={style.bidInput} value={bidValue} onValueChange={(e) => setBidValue(e.value)} useGrouping={false} />
                <button className={style.PlusIcon} onClick={() => setBidValue(pre => pre + 1000)}>+</button>
              </div>
            </div>
          </div>
          <div className='d-flex justify-content-end w-100 gap-4 mt-5'>
            <button className={style.cancelButton} onClick={() => 
           setShow(false)

            }>Cancel</button>
            <button className={style.BidButton} onClick={() => submitBid()}>Bid</button>
          </div>
        </div>
      </Dialog>
    </div>
  )
}

export default BidNowDialog