import { Dialog } from 'primereact/dialog';
import React from 'react'
import style from "./ViewDetails.module.css"
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';

const ViewDetails = ({ visibale, setVisibale }) => {

    let { t } = useTranslation()

    return (
        <div className={style.ViewALL}>

            <Dialog
                closable={false}

                visible={visibale}
                className='p-0 m-0'
                onHide={() => setVisibale(false)}
                style={{ width: "65vw", padding: "0px", margin: "0px" }}
                breakpoints={{ "960px": "75vw", "641px": "100vw" }}
            >
                <div>
                    <div className="d-flex justify-content-end">

                        <Button icon="pi pi-times" className="p-button-text" onClick={() => setVisibale(false)} />
                    </div>
                    <form action="">
                        <div className={style.HeaderDialog}>
                            <div className="d-flex">
                                <div className={style.ProfilePic}>
                                    <img src="/images/Ellipse 24.png" alt="" />
                                </div>
                                <div className={style.PrifileHeding}>
                                    <div className='d-flex gap-3'>
                                        <div> <span className={style.Textname}>Rebbeca Smith</span></div>
                                        <div> <button className={style.VerfiedButton1}>
                                            {/* <img src="/images/green tick.png" className='w-50' alt="" /> */}
                                            Verified</button></div>
                                    </div>
                                    <br />
                                    <div><span className={style.ProfileText}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                                        Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                    </span></div>
                                </div>
                            </div>
                            <div className='d-flex justify-content-between px-1 pt-2'>
                                <div className='d-flex gap-3 '>
                                    <img src="/images/image 140.png" alt="" />
                                    <span className={style.LoctionText}>Indigo, CA</span>
                                </div>
                                <div className='d-flex gap-3 '>
                                    <img src="/images/image 141.png" alt="" />
                                    <span className={`me-3 ${style.LoctionText}`}>Rebecca.smith22464@gmail.com</span>
                                </div>
                            </div>
                        </div>

                        <div className={style.child1}>
                            <div >


                                <div className='CheckBoxParent row mb-3'>
                                    <div className="col-md-6">
                                        <label className={`form-label  ${style.LabelHeading}`} htmlFor="flexRadioDefault1">
                                            {t("Gender")}<span className='text-danger'>*</span>
                                        </label>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-check">

                                                    <input className={`form-check-input ${style.InputCustom}`} type="checkbox" defaultValue id="flexCheckDefault" />
                                                    <label className={`form-check-label ${style.SubLAbelHedaing}`} htmlFor="flexCheckDefault">

                                                        {t("Egg/Sperm Donor")}
                                                    </label>
                                                </div>
                                            </div>

                                            <div className="col-md-6">  <div className="form-check">
                                                <input className={`form-check-input ${style.InputCustom}`} type="checkbox" defaultValue id="flexCheckDefault" />
                                                <label className={`form-check-label ${style.SubLAbelHedaing}`} htmlFor="flexCheckDefault">
                                                    {t("Surrogate Mother")}
                                                </label>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-label LabelHeading" htmlFor="flexRadioDefault1">

                                        </label>
                                        <div className='d-flex gap-3 justify-content-end mt-2'>
                                            <button className={style.SaveProfileButton}>Save Profile</button>
                                            <button className={style.ViewResumeButton}>View Resume</button>
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-md-6">

                                    <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`}>{t("Weeks at Delivery")}<span className='text-danger'>*</span></label>

                                    <input type="text" className={`form-control ${style.InputCustom}`} placeholder="" />

                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`} >{t("Weeks at Delivery")}<span className='text-danger'>*</span></label>

                                    <input type="date" className={`form-control ${style.InputCustom}`} placeholder="" />
                                </div>
                            </div>


                            <div className="row">
                                <div className='col-md-6'>
                                    <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`}>{t("Weight")} <span className='text-danger'>*</span></label>

                                    <div className='d-flex justify-content-between gap-2'>
                                        <select className={`form-select $`} aria-label="Default select example">
                                            <option selected>{t("select")}</option>
                                            <option value={1}>{t("07")}</option>
                                            <option value={2}>{t("08")}</option>
                                            <option value={3}>{t("09")}</option>

                                        </select>

                                        <div className="d-flex align-items-center">ft</div>

                                        <select className={`form-select $`} aria-label="Default select example">
                                            <option selected>{t("select")}</option>
                                            <option value={1}>{t("07")}</option>
                                            <option value={2}>{t("08")}</option>
                                            <option value={3}>{t("09")}</option>

                                        </select>
                                        <div className="d-flex align-items-center">inches</div>
                                    </div>


                                </div>

                                <div className='col-md-6'>
                                    <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`}>{t("Height")} <span className='text-danger'>*</span></label>

                                    <div className='d-flex justify-content-between gap-2'>
                                        <select className={`form-select $`} aria-label="Default select example">
                                            <option selected>{t("select")}</option>
                                            <option value={1}>{t("07")}</option>
                                            <option value={2}>{t("08")}</option>
                                            <option value={3}>{t("09")}</option>

                                        </select>
                                        <div className="d-flex align-items-center">lbs</div>
                                        <select className={`form-select $`} aria-label="Default select example">
                                            <option selected>{t("select")}</option>
                                            <option value={1}>{t("07")}</option>
                                            <option value={2}>{t("08")}</option>
                                            <option value={3}>{t("09")}</option>

                                        </select>
                                        <div className="d-flex align-items-center">oz</div>
                                    </div>


                                </div>

                            </div>

                            <div className="row mb-4">
                                <div className="col-md-12">
                                    <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`}>{t("Current Address")}<span className='text-danger'>*</span></label>

                                    <input type="text" className={`form-control ${style.InputCustom}`} placeholder="" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`}>{t("City")} </label>
                                    <select className={`form-select $`} aria-label="Default select example">
                                        <option selected>{t("select")}</option>
                                        <option value={1}>{t("07")}</option>
                                        <option value={2}>{t("08")}</option>
                                        <option value={3}>{t("09")}</option>

                                    </select>
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`} >{t("State of Residence")} </label>
                                    <select className={`form-select $`} aria-label="Default select example">
                                        <option selected>{t("select")}</option>
                                        <option value={1}>{t("07")}</option>
                                        <option value={2}>{t("08")}</option>
                                        <option value={3}>{t("09")}</option>

                                    </select>
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`} >{t("Zip Code")} </label>
                                    <input type="text" className={`form-control ${style.InputCustom}`} placeholder="" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`} >{t("lifestyle")} </label>
                                    <input type="text" className={`form-control ${style.InputCustom}`} placeholder="" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`} >{t("Hobbies")} </label>
                                    <input type="text" className={`form-control ${style.InputCustom}`} placeholder="" />
                                </div>
                            </div>

                            <div className="row mb-3">


                                <div className="col-md-6">
                                    <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`} >{t("Skin Colour")} </label>
                                    <select className={`form-select $`} aria-label="Default select example">
                                        <option selected>{t("select")}</option>
                                        <option value={1}>{t("07")}</option>
                                        <option value={2}>{t("08")}</option>
                                        <option value={3}>{t("09")}</option>

                                    </select>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`} >{t("Medical Compatibility")} </label>
                                    <select className={`form-select $`} aria-label="Default select example">
                                        <option selected>{t("select")}</option>
                                        <option value={1}>{t("07")}</option>
                                        <option value={2}>{t("08")}</option>
                                        <option value={3}>{t("09")}</option>

                                    </select>
                                </div>
                            </div>

                            <div className="section2">
                                <div className={style.HistoryText}>Reproductive History</div>



                                <div className="child1">
                                    <div >
                                        <span className={style.MiddleSubHeading}>{t("Child #1")}</span>

                                        <div className='CheckBoxParent row mb-3'>
                                            <div className="col-md-6">
                                                <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`} >{t("Gender")} </label>

                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-check">
                                                            <input className={`form-check-input ${style.InputCustom}`} type="radio" defaultValue id="flexRadioDefault1" />
                                                            <label className={`form-check-label ${style.SubLAbelHedaing}`} htmlFor="flexRadioDefault1">

                                                                {t("Egg/Sperm Donor")}
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">

                                                        <div className="form-check">
                                                            <input className={`form-check-input ${style.InputCustom}`} type="radio" defaultValue id="flexRadioDefault1" />
                                                            <label className={`form-check-label ${style.SubLAbelHedaing}`} htmlFor="flexRadioDefault1">
                                                                {t("Surrogate Mother")}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`} >{t("Date of Birth")} </label>
                                                <input type="date" className={`form-control ${style.InputCustom}`} placeholder="" />

                                            </div>
                                        </div>
                                        <div className="row">

                                            <div className="col-md-6">
                                                <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`} >{t("Months to conceive")} </label>
                                                <select className={`form-select $`} aria-label="Default select example">
                                                    <option selected>{t("select")}</option>
                                                    <option value={1}>{t("07")}</option>
                                                    <option value={2}>{t("08")}</option>
                                                    <option value={3}>{t("09")}</option>

                                                </select>
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`} >{t("Weeks at Delivery")} </label>
                                                <input type="text" className={`form-control ${style.InputCustom}`} placeholder="" />

                                            </div>
                                        </div>


                                        <div className="row ">

                                            <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`} >{t("Baby’s Weight")} </label>
                                            <div className="col-md-6">
                                                <select className={`form-select $`} aria-label="Default select example">
                                                    <option selected>{t("select")}</option>
                                                    <option value={1}>{t("07")}</option>
                                                    <option value={2}>{t("08")}</option>
                                                    <option value={3}>{t("09")}</option>

                                                </select>


                                            </div>

                                            <div className="col-md-6">

                                                <select className={`form-select $`} aria-label="Default select example">
                                                    <option selected>{t("select")}</option>
                                                    <option value={1}>{t("07")}</option>
                                                    <option value={2}>{t("08")}</option>
                                                    <option value={3}>{t("09")}</option>

                                                </select>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-12">
                                                <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`} >{t("Name of OB")} </label>
                                                <input type="text" className={`form-control ${style.InputCustom}`} placeholder="" />

                                            </div>
                                            <div className="col-md-12">
                                                <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`} >{t("Delivery Hospital and State")} </label>
                                                <input type="text" className={`form-control ${style.InputCustom}`} placeholder="" />

                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`} >{t("Gender")} </label>

                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-check">
                                                            <input className={`form-check-input ${style.InputCustom}`} type="radio" defaultValue id="flexRadioDefault1" />
                                                            <label className={`form-check-label ${style.SubLAbelHedaing}`} htmlFor="flexRadioDefault1">

                                                                {t("My Own Child")}
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">

                                                        <div className="form-check">
                                                            <input className={`form-check-input ${style.InputCustom}`} type="radio" defaultValue id="flexRadioDefault1" />
                                                            <label className={`form-check-label ${style.SubLAbelHedaing}`} htmlFor="flexRadioDefault1">
                                                                {t("Surrogate Birth")}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </form>
                </div>
            </Dialog>
        </div>
    )
}

export default ViewDetails