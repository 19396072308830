import React, { useEffect, useState } from 'react'
import style from './Setting.module.css'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { getPoliciesData, updatePolicies } from '../../../Api/admin';
import Loader from '../../common/Loader';
import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate } from 'react-router';

export default function PrivacyPolicy() {

    const [editorData, setEditorData] = useState('');
    const [loader, setLoader] = useState(true)
    const navigate = useNavigate()


    async function updateData() {
        setLoader(true)
        try {
            await updatePolicies({ privacyPolicy: editorData })
        } catch (err) {
            console.log(err)
        } finally {
            setLoader(false)
        }
    }


    async function getPolicyData() {
        try {
            let res = await getPoliciesData()
            setEditorData(res?.data?.data?.privacyPolicy)
            console.log(res?.data?.data)
        } catch (err) {
            console.log(err)
        } finally {
            await getPoliciesData()
            setLoader(false)
        }
    }

    useEffect(() => {
        getPolicyData()
    }, [])

    return (
        <>
            <Loader open={loader} />
            <div className='p-md-4 p-sm-3 p-2'>
                <div className='mb-4'>
                    <div className='d-flex align-items-center gap-2 btn-Secondary p-0 pointer' onClick={() => navigate('/superadmin/setting')}><FaArrowLeft /> Back</div>
                </div>
                <div className={`${style.t_heading}`}>Privacy Policy</div>
                <div className='mt-3'>
                    <CKEditor
                        editor={ClassicEditor}
                        data={editorData}
                        onInit={editor => {
                            console.log('Editor is ready to use!', editor);
                        }}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            setEditorData(data);
                        }}
                    />
                </div>
                <div className='text-end mt-4'>
                    <button className='btn-Primary' onClick={updateData}>Update</button>
                </div>
            </div>
        </>
    )
}
