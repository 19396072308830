import React, { useEffect, useState } from "react";
import Style from "./Userlisting.module.css"
import { TableContainer } from "../common/TableContainer";
import { InputText } from 'primereact/inputtext'
import {
  Backdrop,
  Box,
  CircularProgress,
  FormGroup,
  TablePagination,
} from "@mui/material";
import classnames from "classnames";

import { db } from "../../Firebase/firebase";
import {
  getCollectionData,
  getDocumentData,
} from "../../Firebase/cloudFirestore/getData";
import { updateDocument } from "../../Firebase/cloudFirestore/updateData";
import { deleteDocument } from "../../Firebase/cloudFirestore/deleteData";
import { Button, Modal, ModalHeader, Spinner } from "reactstrap";
import { pink } from "@mui/material/colors";
import { Close, Delete } from "@mui/icons-material";
import ReactPaginate from "react-paginate";
import { RiDeleteBin5Line } from "react-icons/ri";

const Userslisting = () => {
  // const [activeTab, setActivetab] = useState("1");
  const [modalviewmore, setModalviewmore] = useState(false);
  const [modalviewmatches, setModalviewmatches] = useState(false);
  const [modalidproofview, setModalidproofview] = useState(false);
  const [currentImage, setCurrentImage] = useState(""); // New state for current image URL
  const [storeData, setStoreData] = useState([]);
  const [storeData1, setStoreData1] = useState([]);
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [activeIconTab, setActiveIconTab] = useState("1");
  const [data, setData] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [loader, setLoader] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(10); // Number of items per page
  const [allData, setAllData] = useState([]);
  const [allDatas, setAllDatas] = useState([]);
  const [paginationData, setPaginationData] = useState([]);
  const [imageLoading, setImageLoading] = useState(true);
  const [count, setCount] = useState(0);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const toggleIconTab = (icontab) => {
    if (activeIconTab !== icontab) setActiveIconTab(icontab);
  };

  const viewmore = (res) => {
    if (res) {
      setStoreData([res]);
      console.log(res);
    }
    setModalviewmore(!modalviewmore);
    setActiveIconTab("1");
    // setModalviewmatches(false); // Close viewmatches modal
  };

  const viewmatches = async (res) => {
    // Reset storeData1 to an empty array initially
    setStoreData1([]);

    if (res && res.matches && res.matches.length > 0) {
      // Fetch details of each user based on their ID
      const matchDetails = await Promise.all(
        res.matches.map(async (matchId) => {
          const matchDetails = await getDocumentData("users", matchId);
          return matchDetails;
        })
      );

      // Set the detailed match information in the state
      setStoreData1({ user: res, matches: matchDetails });
    } else {
      setStoreData1({ user: res });
    }

    // Toggle the modal visibility
    setModalviewmatches(!modalviewmatches);
  };

  const idproofview = (url) => {
    setCurrentImage(url); // Set the current image URL
    setModalidproofview(!modalidproofview);
  };

  const getEvent = async () => {
    let eventData = await getCollectionData("users");
    { console.log(eventData, "eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee") }
    // Sort the eventData based on the created_at property in descending order

    // eventData.sort((a, b) => b?.created_at.toMillis() - a?.created_at.toMillis());
    // let event_data = eventData.map((e) => {
    //   e.selected = false;
    //   return e;
    // });

    // when created at will be created , then we will use this sorting 


    let event_data = eventData.map((e) => {
      e.selected = false;
      return e;
    });

    setAllData(event_data);
    setAllDatas(event_data);

    paginate(event_data, page);
  };

  function filterData(e) {
    const filteredData = allData.filter((res) =>
      res.name.toLowerCase().startsWith(e.toLowerCase())
    );
    if (e.length !== 0) {
      setData(filteredData);
    } else {
      paginate(allDatas, page);
    }
  }

  function paginate(eventData, cpage) {
    console.log(cpage);
    const filteredData = eventData.filter((res) =>
      res.name && typeof res.name === 'string' && res.name.toLowerCase().includes(searchInput.toLowerCase())
    );

    const startIndex = cpage * rowsPerPage;
    const slicedData = filteredData.slice(startIndex, startIndex + rowsPerPage);
    setData(slicedData);
  }

  useEffect(() => {
    getEvent();
  }, [rowsPerPage]); // Include currentPage as a dependency

  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    setSelectedRows(selectAll ? [] : data.map((res, index) => index));
  };

  const toggleRowSelection = (index, checked) => {
    const selectedIndex = selectedRows.indexOf(index);
    data[index].selected = checked;
    let changeData = allData.map((e) => {
      if (e.id === data[index].id) {
        e.selected = checked;
      }
      return e;
    });
    setAllData(changeData);

    let newSelectedRows = [];

    if (selectedIndex === -1) {
      newSelectedRows = [...selectedRows, index];
    } else {
      newSelectedRows = [
        ...selectedRows.slice(0, selectedIndex),
        ...selectedRows.slice(selectedIndex + 1),
      ];
    }

    setSelectedRows(newSelectedRows);
    setSelectAll(newSelectedRows.length === data.length);
  };

  const deleteSelectedRows = async () => {
    if (selectedRows.length > 0) {
      setDeleteConfirmationModal(true);
    } else {
      // Add any other logic or feedback message here when no checkboxes are selected
      window.alert("No rows selected for deletion");
    }
  };

  const confirmDelete = async () => {
    const deletedRows = [];
    setLoader(true);
    for (const obj of allData) {
      if (obj.selected) {
        const documentId = obj.id;
        await deleteDocument("users", documentId);

        // Remove the deleted user's ID from matches of other users
        for (const otherUser of allData) {
          if (otherUser.id !== documentId && otherUser.matches) {
            // Remove the ID from matches array
            otherUser.matches = otherUser.matches.filter(
              (matchId) => matchId !== documentId
            );

            // Update the matches in Firestore
            await updateMatchesInFirestore(otherUser.id, otherUser.matches);
          }
        }
      }
    }

    setSelectAll(false);
    setSelectedRows([]);
    setDeleteConfirmationModal(false);
    getEvent();
    setLoader(false);
  };

  const updateMatchesInFirestore = async (userId, matches) => {
    // Update the matches field in Firestore
    await updateDocument("users", userId, { matches: matches });
  };

  const cancelDelete = () => {
    setDeleteConfirmationModal(false);
  };

  const handlePageChange = ({ selected }) => {
    setCount(count + 1);
    setCurrentPage(selected);
    console.log(selected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    console.log(newPage);
    paginate(allData, newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));

    setPage(0);
  };

  return (
    <>
      <Box>

        <div className={`d-flex ${Style.heading_section}`}>
          <h2>User Management</h2>
          <div className={`d-flex gap-4`}>
            <div className="">
              <input
                type="search"
                placeholder="Search"
                className={`px-3 ${Style.searchbar}`}
                // value={searchInput}
                onChange={(e) => filterData(e.target.value)}
              />
            </div>
            <div>
              <button type="button" class="btn btn-outline-light"
                className={`${Style.delete_button} ${Style.no_style} 
                  `}
                onClick={deleteSelectedRows}
              >

                <RiDeleteBin5Line style={{color:'black'}}/>
              </button>
            </div>
          </div>
        </div>
      </Box>

      <Box sx={{ m: 2 }} className={`${Style.border - 1}`}>
        
      <TableContainer className={"table_container"}>
        
        <table hover responsive size="lg">
          <thead style={{borderBottom:'1px solid lightgray'}}>
            <tr>
              <th></th>
              <th>Name</th>
              <th>Email</th>
              <th>Created At</th>
              <th>contact No.</th>
              {/* <th>City</th> */}
              <th>Details</th>
              {/* <th>Matches</th> */}
            </tr>
          </thead>

          <tbody>
            {data?.map((res, index) => (
              <tr key={index}>
                <td>
                  <div className="form-check">
                    <input
                      style={{ border: "none" , boxShadow:'inset 1px 1px 8px #e1e1e1'
                      }}
                      className="form-check-input"
                      type="checkbox"
                      checked={res?.selected}
                      onChange={(e) =>
                        toggleRowSelection(index, e.target.checked)
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`checkbox-${index}`}
                    ></label>
                  </div>
                </td>

                <th className="text_cap">{res?.name}</th>

                <td>{res?.email}</td>
                <td>
                  {res?.created_at &&
                    new Date(res.created_at).toLocaleDateString()}
                </td>
                <td>{res?.mobile}</td>
                {/* <td>{res.address ? res?.address : "null"}</td> */}

                <td>
                  {
                    <button
                      disabled
                      onClick={() => {
                        viewmore(res);
                      }}
                      className={`${Style.view_more} ${Style.no_style}`}
                    >
                      view
                    </button>
                  }
                </td>
                {/* <td>
                  {
                    <button
                      onClick={() => {
                        viewmatches(res);
                      }}
                      className={`${Style.view_more} ${Style.no_style}`}
                    >
                      Matches
                    </button>
                  }
                </td> */}
              </tr>
            ))}
          </tbody>
        </table>

      </TableContainer>
      </Box>

      <Modal
        size="lg"
        isOpen={modalviewmore}
        toggle={() => viewmore()}
        className={`${Style.modal_main} px-3`}
      >
        {/* ... (your existing modal code) */}
        <div className={`p-5`}>
          <div className={`${Style.header_of_modal} `}>
            <div className={`${Style.header_data}`}>
              <div className={`${Style.header_text}`}>Personal Details</div>
              <div
                className={`${Style.cross_button}`}
                onClick={() => viewmore()}
              >
                <Close />
              </div>
            </div>

            <div
              tabs
              className={`${Style.nav_main} d-flex justify-content-between`}
            >
              <button className={`${Style.navitem}`}>
                <div
                  tag="a"
                  href="#tab"
                  className={`${Style.headerbutton} ${activeIconTab === "1" ? "focused" : ""
                    } pr-0`}
                  onClick={(ev) => {
                    ev.preventDefault();
                    toggleIconTab("1");
                  }}
                >
                  <span
                    style={{
                      color: activeIconTab === "1" ? "#E03368" : "black",
                      fontWeight: 600,
                      borderBottom:
                        activeIconTab === "1" ? "2px solid #E03368" : "none",
                      paddingBottom: "19.2px",
                    }}
                  >
                    Basic Details{" "}
                  </span>
                </div>
              </button>
              <button className={`${Style.navitem}`}>
                <div
                  tag="a"
                  href="#tab"
                  className={`${Style.headerbutton} classnames({ active: activeIconTab === "2" })`}
                  onClick={(ev) => {
                    ev.preventDefault();
                    toggleIconTab("2");
                  }}
                >
                  <span
                    style={{
                      color: activeIconTab === "2" ? "#E03368" : "black",
                      fontWeight: 600,
                      borderBottom:
                        activeIconTab === "2" ? "2px solid #E03368" : "none",
                      paddingBottom: "19.2px",
                    }}
                  >
                    About Me
                  </span>
                </div>
              </button>
              <button className={`${Style.navitem}`}>
                <div
                  tag="a"
                  href="#tab"
                  className={`${Style.headerbutton} classnames({ active: activeIconTab === "3" })`}
                  onClick={(ev) => {
                    ev.preventDefault();
                    toggleIconTab("3");
                  }}
                >
                  <span
                    style={{
                      color: activeIconTab === "3" ? "#E03368" : "black",
                      borderBottom:
                        activeIconTab === "3" ? "2px solid #E03368" : "none",
                      fontWeight: 600,
                      paddingBottom: "19.2px",
                    }}
                  >
                    interest
                  </span>
                </div>
              </button>
              <button className={`${Style.navitem}`}>
                <div
                  tag="a"
                  href="#tab"
                  className={`${Style.headerbutton} classnames({ active:activeIconTab === "4" })`}
                  onClick={(ev) => {
                    ev.preventDefault();
                    toggleIconTab("4");
                  }}
                >
                  <span
                    style={{
                      color: activeIconTab === "4" ? "#E03368" : "black",
                      fontWeight: 600,
                      borderBottom:
                        activeIconTab === "4" ? "2px solid #E03368" : "none",
                      paddingBottom: "19.2px",
                    }}
                  >
                    Looking For
                  </span>
                </div>
              </button>

              <button className={`${Style.navitem}`}>
                <div
                  tag="a"
                  href="#tab"
                  className={`${Style.headerbutton} classnames({ active: activeIconTab === "5" })`}
                  onClick={(ev) => {
                    ev.preventDefault();
                    toggleIconTab("5");
                  }}
                >
                  <span
                    style={{
                      color: activeIconTab === "5" ? "#E03368" : "black",
                      fontWeight: 600,
                      borderBottom:
                        activeIconTab === "5" ? "2px solid #E03368" : "none",
                      paddingBottom: "19.2px",
                    }}
                  >
                    Images
                  </span>
                </div>
              </button>

              <button className={`${Style.navitem}`}>
                <div
                  tag="a"
                  href="#tab"
                  className={`${Style.headerbutton} classnames({ active: activeIconTab === "5" })`}
                  onClick={(ev) => {
                    ev.preventDefault();
                    toggleIconTab("6");
                  }}
                >
                  <span
                    style={{
                      color: activeIconTab === "6" ? "#E03368" : "black",
                      fontWeight: 600,
                      borderBottom:
                        activeIconTab === "6" ? "2px solid #E03368" : "none",
                      paddingBottom: "19.2px",
                    }}
                  >
                    ID proof
                  </span>
                </div>
              </button>
            </div>
          </div>
          <hr />
          <FormGroup className="">
            <div
              className={`d-flex flex-column overflow-auto custom_scrollbar ${Style.userlisting}`}
            >
              {activeIconTab === "1" && (
                <>
                  {storeData &&
                    storeData.map((res, index) => {
                      return (
                        <div>
                          <div className={`${Style.data_section}`}>
                            <div className="d-flex justify-content-between">
                              <div>DOB</div>
                              <div className={`${Style.details_field}`}>
                                {res?.dOB ? res.dOB : "Null"}
                              </div>
                            </div>

                            <div className="d-flex justify-content-between">
                              <div>Gender</div>
                              <div className={`${Style.details_field}`}>
                                {res?.gender ? res.gender : "Null"}
                              </div>
                            </div>

                            <div className="d-flex justify-content-between">
                              <div>Education</div>
                              <div className={`${Style.details_field}`}>
                                {res.education ? res.education : "Null"}
                              </div>
                            </div>

                            <div className="d-flex justify-content-between">
                              <div>Height</div>
                              <div className={`${Style.details_field}`}>
                                {res.height ? res.height : "Null"}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </>
              )}

              {activeIconTab === "2" && (
                <>
                  {storeData &&
                    storeData.map((res, index) => {
                      return (
                        <div>
                          <div className={`${Style.data_section}`}>
                            <div className="d-flex justify-content-between">
                              <div className={``}>Favourite Cuisine</div>
                              <div className={`${Style.details_field}`}>
                                {res?.cushine && res?.cushine.length > 0
                                  ? res.cushine.join(", ")
                                  : "Null"}{" "}
                              </div>
                            </div>

                            <div className="d-flex justify-content-between">
                              <div className={``}>Music</div>
                              <div className={`${Style.details_field}`}>
                                {res?.music && res?.music.length > 0
                                  ? res?.music.join(", ")
                                  : "Null"}
                              </div>
                            </div>
                            <div className="d-flex justify-content-between">
                              <div>Divorced</div>
                              <div className={`${Style.details_field}`}>
                                {res?.divorced ? res?.divorced : "Null"}
                              </div>
                            </div>
                            <div className="d-flex justify-content-between">
                              <div>longest relationship</div>
                              <div className={`${Style.details_field}`}>
                                {res?.longestRelationship
                                  ? res?.longestRelationship
                                  : "Null"}
                              </div>
                            </div>
                            <div className="d-flex justify-content-between">
                              <div>Current status</div>
                              <div className={`${Style.details_field}`}>
                                {res?.currentStatus}
                              </div>
                            </div>
                            <div className="d-flex justify-content-between">
                              <div>Hobbies/Freetime</div>
                              <div className={`${Style.details_field}`}>
                                {res?.free_time && res?.free_time.length > 0
                                  ? res.free_time.join(", ")
                                  : "No hobbies/freetime"}
                              </div>
                            </div>
                            <div className="d-flex justify-content-between">
                              <div>Zodiac</div>
                              <div className={`${Style.details_field}`}>
                                {res?.zodiac && res?.zodiac.length > 0
                                  ? res?.zodiac.join(", ")
                                  : "Null"}
                              </div>
                            </div>
                            <div className="d-flex justify-content-between">
                              <div>Political Learning</div>
                              <div className={`${Style.details_field}`}>
                                {res?.political && res?.political.length > 0
                                  ? res?.political.join(", ")
                                  : "Null"}
                              </div>
                            </div>
                            <div className="d-flex justify-content-between">
                              <div>Religion</div>
                              <div className={`${Style.details_field}`}>
                                {res?.religion ? res?.religion : "Null"}
                              </div>
                            </div>
                            <div className="d-flex justify-content-between">
                              <div>No. Of Relationship</div>
                              <div className={`${Style.details_field}`}>
                                {res?.totalRelationship
                                  ? res?.totalRelationship
                                  : "Null"}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </>
              )}

              {activeIconTab === "3" && (
                <>
                  {storeData &&
                    storeData.map((res, index) => {
                      return (
                        <div>
                          <div className={`${Style.data_section}`}>
                            <div className="d-flex justify-content-between">
                              <div className={``}>Interests</div>
                              <div className={`${Style.details_field}`}>
                                {res?.interests && res?.interests.length > 0
                                  ? res.interests.join(", ")
                                  : "Null"}{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </>
              )}

              {activeIconTab === "4" && (
                <>
                  {storeData &&
                    storeData.map((res, index) => {
                      return (
                        <div>
                          <div className={`${Style.data_section}`}>
                            <div className="d-flex justify-content-between">
                              <div>Habits</div>
                              <div className={`${Style.details_field}`}>
                                {res?.habbits && res?.habbits.length > 0
                                  ? res?.habbits.join(", ")
                                  : "Null"}
                              </div>
                            </div>
                            <div className="d-flex justify-content-between">
                              <div>Seeking For</div>
                              <div className={`${Style.details_field}`}>
                                {res?.seeking && res?.seeking.length > 0
                                  ? res?.seeking.join(", ")
                                  : "Null"}
                              </div>
                            </div>
                            <div className="d-flex justify-content-between">
                              <div>Hoping</div>
                              <div className={`${Style.details_field}`}>
                                {res?.relationshipType &&
                                  res?.relationshipType.length > 0
                                  ? res?.relationshipType.join(", ")
                                  : "Null"}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </>
              )}

              {activeIconTab === "5" && (
                <>
                  {storeData &&
                    storeData.map((res, index) => {
                      return (
                        <div key={index} className={`${Style.data_section}`}>
                          <h3>Profile Image</h3>
                          <div className="d-flex justify-content-between">
                            {res.profileImg !== null ? (
                              <>
                                {imageLoading && (
                                  <div className={`${Style.imageplaceholder}`}>
                                    Loading...
                                  </div>
                                )}
                                <img
                                  className={`${Style.image_size} ${imageLoading ? "hidden" : ""
                                    }`}
                                  src={res.profileImg}
                                  alt={`User ${res.name}'s image`}
                                  onLoad={() => setImageLoading(false)}
                                />
                              </>
                            ) : (
                              <div>No Image</div>
                            )}
                          </div>
                          <h3>Gallery</h3>
                          <div className="d-flex justify-content-between flex-wrap gap-1">
                            {res.mulImgUrl && res.mulImgUrl.length > 0 ? (
                              res.mulImgUrl.map((url, index) => (
                                <div key={index}>
                                  {imageLoading && (
                                    <div
                                      className={`${Style.imageplaceholder}`}
                                    >
                                      Loading...
                                    </div>
                                  )}
                                  <img
                                    className={`${Style.image_size} ${imageLoading ? "hidden" : ""
                                      }`}
                                    src={url}
                                    alt={`User ${res.name}'s image ${index + 1
                                      }`}
                                    onLoad={() => setImageLoading(false)}
                                  />
                                </div>
                              ))
                            ) : (
                              <div>No other Images</div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                </>
              )}
              {activeIconTab === "6" && (
                <>
                  {storeData &&
                    storeData.map((res, index) => {
                      return (
                        <div key={index} className={`${Style.data_section}`}>
                          <div className="d-flex justify-content-between">
                            {res.profileImg !== null ? (
                              <>
                                {imageLoading && (
                                  <div className={`${Style.imageplaceholder}`}>
                                    Loading...
                                  </div>
                                )}

                                <div
                                  onClick={() =>
                                    idproofview(res.frontSideIdProof)
                                  }
                                >
                                  <h3 className="mb-2">Front side Id</h3>
                                  {/* Image element */}
                                  <img
                                    className={`${Style.image_size} ${imageLoading ? "hidden" : ""
                                      }`}
                                    src={res.frontSideIdProof}
                                    alt={`User ${res.name}'s image`}
                                    onLoad={() => setImageLoading(false)}
                                  />
                                </div>
                                <div
                                  onClick={() =>
                                    idproofview(res.backSideIdProof)
                                  }
                                >
                                  <h3 className="mb-2">Back side Id</h3>
                                  {/* Image element */}
                                  <img
                                    className={`${Style.image_size} ${imageLoading ? "hidden" : ""
                                      }`}
                                    src={res.backSideIdProof}
                                    alt={`User ${res.name}'s image`}
                                    onLoad={() => setImageLoading(false)}
                                  />
                                </div>
                              </>
                            ) : (
                              <div>No Image</div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                </>
              )}
            </div>
          </FormGroup>
        </div>
      </Modal>

      <Modal
        className={``}
        isOpen={deleteConfirmationModal}
        toggle={cancelDelete}
      >
        <Backdrop
          sx={{ color: "pink", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className={`p-4 ${Style.modal_content_1}`}>
          <div>
            <div className={`${Style.delete_modal_text}`}>
              Are you sure you want to delete the selected User ?
            </div>
            <div className={`${Style.delete_modal_text_1}`}>
              This will delete this User permanently, you cannot undo this
              action
            </div>
          </div>

          <div className={`${Style.delete_buttons_section}`}>
            <div
              className={`${Style.cancel_button_of_delete_section}`}
              onClick={cancelDelete}
            >
              Cancel
            </div>
            <div
              className={`${Style.delete_button_of_delete_section}`}
              onClick={confirmDelete}
            >
              Delete
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        className={`modalcontent2 p-5`}
        size="lg"
        isOpen={modalviewmatches}
        toggle={() => viewmatches()}
      >
        <div className={`p-5`}>
          <div className={`${Style.header_of_modal}`}>
            {storeData1?.user && (
              <div className={`${Style.header_data}`}>
                <div className={`${Style.header_text}`}>
                  {storeData1.user.name}'s Matches
                </div>
                <div
                  className={`${Style.cross_button}`}
                  onClick={() => viewmatches()}
                >
                  <Close />
                </div>
              </div>
            )}
          </div>

          <hr />

          <FormGroup className="">
            <div
              className={`d-flex flex-column overflow-auto custom_scrollbar userlisting`}
            >
              {storeData1?.matches && storeData1?.matches.length > 0 ? (
                <table borderless responsive size="lg" className={`W-100`}>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Location</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {storeData1?.matches?.map((res, index) => (
                      <tr key={index}>
                        <th className="text_cap">{res?.name}</th>
                        <td>{res?.email}</td>
                        <td>{res?.address}</td>
                        <td>
                          <button
                            onClick={() => {
                              viewmore(res);
                            }}
                            className={`${Style.view_more} ${Style.no_style}`}
                          >
                            view
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div>No matches for this user.</div>
              )}
            </div>
          </FormGroup>
        </div>
      </Modal>

      <Modal
        className={`modalcontent2 p-5`}
        size="md"
        isOpen={modalidproofview}
        toggle={() => setModalidproofview(!modalidproofview)} // Simplified toggle function
      >
        <div className={``}>
          <FormGroup className="">
            <div
              className={`d-flex flex-column overflow-auto custom_scrollbar userlisting`}
            >
              {/* Display the current image using the currentImage state */}
              <div>
                <div
                  className={`${Style.cross_button_for_id_proof}`}
                  onClick={() => setModalidproofview(!modalidproofview)} // Simplified toggle function
                >
                  <Close />
                </div>
                <img
                  className={`w-100 h-100`}
                  src={currentImage} // Use currentImage state for the src
                  alt={`ID Proof`}
                  onLoad={() => setImageLoading(false)}
                />
              </div>
            </div>
          </FormGroup>
        </div>
      </Modal>

      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <TablePagination
          component="div"
          count={allData.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
      </>
  );
};

export default Userslisting;




















// import { Pagination } from 'reactstrap'
// import { TableContainer } from '../../common/TableContainer'
// import { InputText } from 'primereact/inputtext'
// import { useNavigate } from 'react-router'
// const ClientTable = ({data}) => {
//     const navigate =useNavigate()

//     return (

//         <div className={style.ClientTable}>
          
       
//             <TableContainer className={"table_container"}>
//                 <table>
//                     <thead>
//                         <tr>

//                             <th>

//                                 <input className="form-check-input m-0 me-3" type="checkbox" defaultValue id="flexCheckDefault" />
//                                 <label className="form-check-label" htmlFor="flexCheckDefault">
//                                 </label>

//                                 Document Name
//                             </th>
//                             <th>Upload Date</th>
//                             <th>Document Number</th>
//                             <th>Contract Id</th>
//                             <th>Document</th>

//                         </tr>
//                     </thead>
//                     <tbody>
//                         {data.map((res,index)=>{
//                             return(
//                         <tr key={index}>
//                             <td className='d-flex align-items-center'>
//                                   <input className="form-check-input m-0 me-3" type="checkbox" defaultValue id="flexCheckDefault" />
//                                 <label className="form-check-label" htmlFor="flexCheckDefault">
//                                 </label>
//                                 <img src={res.img} alt="image" className="ProfileImages me-1"/>

//                                 {res.document_name}
//                                 </td>
//                             <td>{res.upload_date}</td>
//                             <td>{res.document_number}</td>
//                             <td>{res.contract_Id}</td>
//                             <td>
//                                 <button className={style.ViewButton} onClick={()=>navigate('clientView')}><i class="bi bi-eye d-flex align-items-center me-1"></i> View</button>
//                             </td>
//                         </tr>

//                             )
//                         })}
//                     </tbody>


//                 </table>
//             </TableContainer>

//         </div>

//     )
// }

// export default ClientTable