import axios from "axios"
import { defaultAuction, baseUrl } from "./EndPoint"

let token;
let userData = localStorage.getItem("userData")
if (userData) {
    token = JSON.parse(userData).token
}

let config = {
    headers: {
        Authorization: `Bearer ${token}`,
    }
  }

export const getDefaultAuction = async () => {
    return await axios.get(`${baseUrl}${defaultAuction.getDefaultAuction}`,config)
}

export const updateDefaultAuction = async (data) => {
    return await axios.put(`${baseUrl}${defaultAuction.updateDefaultAuction}`,data,config)
}
