import React from 'react'
import "./common.css"

export const Logo = () => {
    return (
        <div className='logo_con flex align_center'>
            <div>
               <img src='/images/logo.png'/>
            </div>
           
        </div>
    )
}
