import React, { useState, useEffect } from "react";
import ClientTable from "../LawyerClientTable";
import style from "../../MyClientListing/LawyerMyClient.module.css";
import { InputText } from "primereact/inputtext";
import { getLawyerDocumentByClientId } from "../../../../Api/Lawyer";
import AddDocument from "../AddDocumentDialog/LawyerAddDocument";

import Loader from "../../../common/Loader";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import BackButton from "../../../commonComponents/BackButton/BackButton";
import LawyerAddPoc from "../LawyerAddPoc/LawyerAddPoc";

const LawyerViewReport = () => {

  // const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);
  // const appointmentId = queryParams.get('appointmentId');

  const location = useLocation();
  const { appointmentId,ClinetName,pocInfo } = location.state;

console.log("poc info on listing page",pocInfo)
  const params = useParams()
  const clientId = params.clientid;
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [filterData, setFilterData] = useState([]);

  const [addocument, setAddDocument] = useState(false);

  const handleSearchClick = (e) => {
    let val = e.trim().toLowerCase();
    let searchName = filterData.filter((res) => {
      const fullName = (res?.createdAt?.trim().toLowerCase().replace(/\s+/g, '') || '') +
        (res?.legal_document_name?.trim().toLowerCase().replace(/\s+/g, '') || '');
      return fullName.includes(val.replace(/\s+/g, ''));
    });
    setData(searchName);
  };


  const getReportsFunction = async () => {
    try {
      setLoader(true);
      const response = await getLawyerDocumentByClientId({ "clientId": clientId });
      console.log(response, "Reports response");
      setData(response.data.data);
      setFilterData(response.data.data)
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getReportsFunction();
  }, []);

  return (
    <>
      <Loader open={loader} />
      <AddDocument addocument={addocument} setAddDocument={setAddDocument} appointmentId={appointmentId} reload={getReportsFunction} />

      <BackButton text="Back" path={-1}/>

      <div className={`${style.ClientTable}`}>
        <div className="d-flex justify-content-between mb-2">
          <div className="d-flex flex-column gap-2 align-items-start lufga_24px fw_600">
            <span className="lufga_22px">
              <span className="">{clientId}: </span>
              
               {ClinetName}
              
            </span>
          </div>

          <div className="d-flex align-items-center gap-3">
            <div className="searchInput w-100">
              <input
                className="form-control ps-5"
                placeholder="Search"
                onChange={(e) => handleSearchClick(e.target.value)}
              ></input>
              <i className="pi pi-search searchIcon"></i>
            </div>

            <div>
              <button className="saveButton wsnb" onClick={() => { setAddDocument(!addocument) }}>
                + Add Document
              </button>
            </div>
          </div>

        </div>

        <div className="mb-3">
          <LawyerAddPoc clientId={appointmentId} pocInfo={pocInfo}/>  
        </div>

        <ClientTable data={data} role="reports" update={getReportsFunction} />
      </div>
    </>
  );
};

export default LawyerViewReport;
