import axios from "axios";
import { auctionService, baseUrl, dashboardService, surrogateMother } from "./EndPoint";
let userData = JSON.parse(sessionStorage.getItem("userData"));

let config = {
  headers: {
    Authorization: `Bearer ${userData?.token}`,
  },
};
export async function getSurrogateMotherData(id) {
  return await axios.get(
    `${baseUrl}${surrogateMother.getAllData}${id}`,
    config
  );
}
export async function getDashBoardUseSurrogate(id) {
  return await axios.get(
    `${baseUrl}${dashboardService.getUserById}${id}`,
    config
  );
}
export async function getResumaData(id){
    return await axios.get(`${baseUrl}${surrogateMother.getResumeData}${id}`,config)
    }
    export async function updateResumeData(id,data){
        return await axios.put(`${baseUrl}${surrogateMother.updateResumeData}${id}`,data,config)
        }
export async function UpdateSurrogateprofile(id, data) {
  return await axios.put(
    `${baseUrl}${surrogateMother.updateProfile}${id}`,
    data,
    config
  );
}
export async function createAuction(body) {
  return await axios.post(
    `${baseUrl}${surrogateMother.addAuction}`,
    body,
    config
  );
}
export async function uploadImage(fileData) {
  const formData = new FormData();
  formData.append("uploadDoc", fileData);
  // formData.append("name", "firstFile");
  return await axios.post(`${baseUrl}${surrogateMother.imgaeUpload}`, formData);
}

export async function getAllSurrogateAuction(id) {
  return await axios.get(
    `${baseUrl}${surrogateMother.getAllActionByUserId}${id}`,
    config
  );
}

export async function getAuctionById(id) {
  return await axios.get(
    `${baseUrl}${surrogateMother.getAuctionById}${id}`,
    config
  );
}

export async function auctionStatus(id,body) {
  return await axios.put(
    `${baseUrl}${auctionService.auctionStatus}${id}`,body,
    config
  );
}
export async function auctionBidDetails(id) {
  return await axios.get(
    `${baseUrl}${auctionService.auctionBidDetails}${id}`,
    config
  );
}

export async function auctionUpdateById(id, data) {
  return await axios.put(
    `${baseUrl}${surrogateMother.updateAuctionById}${id}`,
    data,
    config
  );
}

export async function deleteAuctionById(id) {
  return await axios.delete(
    `${baseUrl}${surrogateMother.deleteAuctionById}${id}`,
    config
  );
}
export async function pushNotificationSurrogate(id, data) {
  return await axios.put(
    `${baseUrl}${surrogateMother.pushNotification}${id}`,
    data,
    config
  );
}

export async function emailNotificationSurrogate(id, data) {
  return await axios.put(
    `${baseUrl}${surrogateMother.emailNotification}${id}`,
    data,
    config
  );
}

export async function selectProfilePhoto(id, body) {
  return await axios.put(
    `${baseUrl}${surrogateMother.selectProfilePhoto}${id}`,
    body,
    config
  );
}
export async function selectResumePhoto(id, body) {
    return await axios.put(
      `${baseUrl}${surrogateMother.selectResumePhoto}${id}`,
      body,
      config
    );
  }
export async function deletePhoto(id, payload) {
  return await axios.post(
    `${baseUrl}${surrogateMother.deletePhoto}${id}`,
    payload,
    config
  );
}
export async function getAllSurrogateDonors() {
  return await axios.get(`${baseUrl}${surrogateMother.getAllSurrogateDonors}`);
}

export async function addSurrogateSuperAdmin(data){
    return await axios.post(`${baseUrl}${surrogateMother.addSuperAdminSurrogate}`,data,config)
}


export async function updateConciseFormOne(data,id){
    return await axios.post(`${baseUrl}${surrogateMother.conciseFormOne}${id}`,data,config)
}
export async function updateConciseFormTwo(data,id){
    return await axios.post(`${baseUrl}${surrogateMother.conciseFormtwo}${id}`,data,config)
}
export async function verifySurrogateResume(data,id){
    return await axios.post(`${baseUrl}${surrogateMother.verifyResume}${id}`,data,config)
}

export async function pushNotificationData(id,data){
  return await axios.put(`${baseUrl}${surrogateMother.pushNotificationOfAll}${id}`,data,config)

}