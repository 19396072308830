import React, { useEffect, useState } from "react";
import Loader from "../../common/Loader";
import style from "./Setting.module.css";
import { useNavigate } from "react-router";
import { FaArrowLeft } from "react-icons/fa6";
import { getAboutData } from "../../../Api/admin";
import BackButton from "../../commonComponents/BackButton/BackButton";

export default function AboutUsSurrogate() {
  const [loader, setLoader] = useState(true);
  const [getData, setGetData] = useState("");
  let navigator = useNavigate()


  const getDataAbout = async()=>{
    await getAboutData().then((res)=>{
      console.log(res)
      setGetData(res?.data?.data[0]?.about_data)
      setLoader(false)
    }).catch((err)=>{
      console.log(err)
    })
  }

  useEffect(()=>{
    getDataAbout()
  },[])

  return (
    <div>
      <Loader open={loader} />
      <div className="mainContainer">
      <BackButton path={-1} text="Back" />
        <div className={`${style.t_heading}`}>About Us</div>
        <div className="CardStyle p-md-4 p-sm-3 p-2 mt-2">
        <div  dangerouslySetInnerHTML = {{ __html:getData}}></div>
        </div>
      </div>
    </div>
  );
}
