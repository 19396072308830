import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import style from "./style.module.css";
import { TabView, TabPanel } from "primereact/tabview";
import moment from "moment";
import Loader from "../../common/Loader";
import { revenueFromSubscription } from "../../../Api/admin";
import Toaster from "../../common/Toaster";

export default function RevenueSurrogateReport() {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [series, setSeries] = useState([]);
  const [loader, setLoader] = useState(false);
  const [roles, setRoles] = useState([]); // Dynamically set roles
  const [toaster, setToaster] = useState({
    show: false,
    message: "",
  });

  const fetchData = async () => {
    setLoader(true);

    const payload = {
      start: startDate,
      end: endDate,
    };

    try {
      const response = await revenueFromSubscription(payload);
      console.log(response, "revenue response");

      if (response.status !== 201) {
        console.error('Error fetching data:', response.statusText);
        return;
      }

      const data = response.data;
      if (data.error) {
        console.error('Error fetching data:', data.errormessage);
      } else {
        const rolesFromData = Object.keys(data.data[0]).filter(key => key);
        processData(data.data[0], rolesFromData);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoader(false);
    }
  };

  const processData = (data, rolesFromData) => {
    const parsedSeries = rolesFromData.map(role => {
      if (!data[role]) {
        console.warn(`No data found for role: ${role}`);
        return { name: role, data: [], totalRevenue: 0 };
      }

      return {
        name: role,
        data: data[role].data.map(item => ({
          x: item.day,
          y: item.revenue,
        })),
        totalRevenue: data[role].total_revenue,
      };
    });

    setSeries(parsedSeries);
    setRoles(parsedSeries.map(seriesItem => ({
      name: seriesItem.name,
      totalRevenue: seriesItem.totalRevenue,
    })));
  };

  const options = {
    chart: {
      type: "line",
      zoom: {
        enabled: true,
        type: "xy",
        autoScaleYaxis: true,
      },
      toolbar: {
        autoSelected: "zoom",
        tools: {
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
      },
    },
    xaxis: {
      type: "datetime",
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val.toFixed(2);
        },
      },
    },
  };

  const handleDateChange = (setter, date) => {
    const today = moment().format("YYYY-MM-DD");
    if (moment(date).isAfter(today)) {
      setToaster({
        show: true,
        message: "Dates cannot be in the future.",
      });
      return;
    }

    if (setter === setStartDate && endDate && moment(date).isSameOrAfter(endDate)) {
      setToaster({
        show: true,
        message: "Start date must be earlier than the end date.",
      });
      return;
    }

    if (setter === setEndDate && startDate && moment(date).isSameOrBefore(startDate)) {
      setToaster({
        show: true,
        message: "End date must be later than the start date.",
      });
      return;
    }

    setter(date);
  };


  useEffect(() => {
    const savedDates = JSON.parse(localStorage.getItem("dateRange"));
    console.log(savedDates, "this is the console for saved dates")
    if (savedDates) {
      setStartDate(savedDates.startDate);
      setEndDate(savedDates.endDate);
      fetchData();
    } else {
      const today = moment().format("YYYY-MM-DD");
      const oneWeekAgo = moment().subtract(7, "days").format("YYYY-MM-DD");
      setStartDate(oneWeekAgo);
      setEndDate(today);
      fetchData();
    }
  }, []);

  useEffect(() => {
    if (startDate && endDate) {
      localStorage.setItem("dateRange", JSON.stringify({ startDate, endDate }));
      fetchData();
    }
  }, [startDate, endDate]);

  return (
    <div>
      <Toaster
        // event={"Please InActive Current Post then you Add new Post"}
        event={toaster.message}
        show={toaster.show}
        severity={"warn"}
        setToaster={setToaster}
      />
      {loader && <Loader open={loader} />}
      <div className={`lufga_24px fw_600 ${style.subheading_color}`}>
        Revenue from Subscription Report
      </div>

      <div className="table_container mt-3">

        <div className="TabviewContainer">
          <div className="d-flex justify-content-end">
            <div className="d-flex gap-3 align-items-center justify-content-end">
              <label htmlFor="startdate" style={{ whiteSpace: 'nowrap' }}>Start Date</label>
              <input
                id="startdate"
                className={`form-control`}
                type="date"
                value={startDate || ""}
                onChange={(e) => handleDateChange(setStartDate, e.target.value)}
                max={moment().format("YYYY-MM-DD")}
              />

              <label htmlFor="enddate" style={{ whiteSpace: 'nowrap' }}>End Date</label>
              <input
                id="enddate"
                className={`form-control`}
                type="date"
                value={endDate || ""}
                onChange={(e) => handleDateChange(setEndDate, e.target.value)}
                max={moment().format("YYYY-MM-DD")}
              />
            </div>
          </div>

          <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
            {roles.map((role, index) => (
              <TabPanel key={index} header={<div className="d-flex flex-column align-items-center gap-2"><span>{role.name}</span> <span>${role.totalRevenue.toFixed(2)}</span></div>}>
                <Chart options={options} series={series.filter(ser => ser.name === role.name)} type="line" height={350} />
              </TabPanel>
            ))}
          </TabView>
        </div>
      </div>
    </div>
  );
}
