import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { FaArrowLeft } from "react-icons/fa6";
import style from './Setting.module.css'
import { getPoliciesData } from "../../../Api/admin";
import Loader from "../../common/Loader";
import BackButton from "../../commonComponents/BackButton/BackButton";


export default function TermAndConditionSurrogate() {
let navigator = useNavigate()
const [getTermandCondition,setTermAndCondition] = useState("")
const [loader, setLoader] = useState(true)


const getTermConditionData = async ()=>{
  await getPoliciesData().then((res)=>{
    // console.log(res)
    setLoader(false)
    setTermAndCondition(res?.data?.data?.termsNconditions)
  }).catch((err)=>{
    console.log(err)
    setLoader(false)
  })
}

useEffect(()=>{
  getTermConditionData()
},[])

  return (
    <>
            <Loader open={loader} />

      <div className="mainContainer">
      <BackButton path={-1} text="Back" />
                <div className={`${style.t_heading}`}>Terms & Conditions</div>
        <div className="CardStyle p-md-4 p-sm-3 p-2 mt-2">
          <div  dangerouslySetInnerHTML = {{ __html:getTermandCondition}}></div>
       
        </div>
      </div>
    </>
  );
}
