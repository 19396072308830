import React from 'react'
import style from './NewsSection.module.css'
import LatestNews from './LatestNews/LatestNews'
import { InputText } from 'primereact/inputtext'
import { useNavigate } from "react-router-dom";


const NewsSection = () => {
    const navigate = useNavigate();
  return (
    <div className='mt-4'>
        <div className="d-flex align-items-center justify-content-between mb-4 mt-3">
        {/* <BackButton text="NHP Auctions" path="/superadmin/AuctionView"/> */}
        <div className='lufga_42px fw_700'>News</div>
        <div className="d-flex gap-4 align-items-center">
        <div>
            <button
              className="theme_button lufga_22px"
              onClick={() => navigate('/superadmin/latestnews/CreateNews')}
            >
              + Create News
            </button>
          </div>
          <div>
            <span className={`p-input-icon-left d-flex align-items-center `}>
              {/* <i className={`pi pi-search ${style.search_icon}`} /> */}
              <InputText placeholder="Search" className={`${style.searchbar}`} />
            </span>
          </div>

        </div>
      </div>
        <LatestNews role="true"/>
    </div>
  )
}

export default NewsSection