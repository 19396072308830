import { Dialog } from 'primereact/dialog'
import React, { useEffect, useState } from 'react'
import style from './Auction.module.css'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { buyNowSurrogate, getAuctionDataById } from '../../../Api/IntendedParent';
import Loader from '../../common/Loader';
import CommonPaymentDialogBox from '../../common/CommonPaymentDialogBox';
export default function BuyNowModal({show,reload,setShow,id}) {
    const [loader, setLoader] = useState(true)
   
 
    const navigate = useNavigate()
    const userId = sessionStorage.getItem('userId')
    const [auctionData, setAuctionData] = useState({})
    const [paymentDialog, setPaymentDialog] = useState(false)
    


    async function getAuctionData() {
    

        try {
    
          let res = await getAuctionDataById(id)
          setAuctionData(res.data.data)
       
          setLoader(false)
        } catch (err) {
          setLoader(false)
          console.log(err)
        }
    
      }

      function getThreePercent(total){
        let price = (+(total) * (auctionData.deposit_fee_per?auctionData.deposit_fee_per:3))/100
           return price
      }


      async function buySurrogate(amount){
        setLoader(true)
      try{
        let payload = {
          buyer_id: userId,
          price: amount,
          status: true
        }
        let res = buyNowSurrogate(id,payload)
        setShow(false)
        // navigate('/intendedParent/auctions')
        reload()
        setLoader(false)
      }catch(err){
        console.log(err)
        setLoader(false)
      }
      }
    
    
      useEffect(() => {
        getAuctionData()
      }, [show])

    return (
        <>
         <Loader open={loader} />
            <Dialog
                closable={false}
                visible={show}
                className='p-0 m-0'
                onHide={() => { }}
                style={{ width: "40vw", padding: "0px", margin: "0px" }}
                breakpoints={{ "960px": "75vw", "641px": "100vw" }}
            >
                <div className='py-4 px-3'>
                    <div className={`${style.header}`}>Auction Commission</div>
                    <div className={`${style.discription} px-5 mt-3`}>{`You need to pay ${auctionData.deposit_fee_per?auctionData.deposit_fee_per:3}% of Buy Now Price in order to bid on SM Id "${auctionData.memberId}"`}</div>
                    {/* <div className='mt-5 w-100 d-flex'>
                        <div className='w-50'><span className={`${style.amountLable}`}>Buy Now Price :</span><span className='ms-3 fs-5 text-black'>${auctionData?.buy_now_price}</span></div>
                    </div> */}
                    {/* <div className='mt-5 w-100 d-flex'>
                       
                        <div className='w-50'><span className={`${style.amountLable}`}>Auction Commission :</span><span className='ms-3 fs-5 text-black'>${getThreePercent(auctionData?.buy_now_price)}</span></div>
                    </div> */}
                    <table className={`${style.mainTable} w-100 mt-3 `}>
                    <tbody className='w-100 gap-3 pt-5'>

                        <tr className='w-100'>
                          <td className=''><span className={`${style.amountLable}`}>Buy Now Price </span></td>
                          <td className=''><span className='ms-3 fs-5 text-black'>:${auctionData?.buy_now_price}</span></td>

                        </tr>
                        <tr className='w-100  '>
                          
                          <td className='d-flex flex-column'><span className={`${style.amountLable}`}>Auction Commission </span>
                          <span>({auctionData.deposit_fee_per?auctionData.deposit_fee_per:3}% of Buy Now Price)</span></td>
                          <td className=''><span className='ms-3 fs-5 text-black'>:${getThreePercent(auctionData?.buy_now_price)}</span></td>

                        </tr>
                        <tr className='w-100  '>
                          
                          <td className='d-flex flex-column'><span className={`${style.amountLable}`}>Total Price </span>
                         </td>
                          <td className=''><span className='ms-3 fs-5 text-black'>:${auctionData?.buy_now_price + getThreePercent(auctionData?.buy_now_price) }</span></td>

                        </tr>
                    </tbody>
                    </table>
                    <div className='d-flex justify-content-end gap-4 mt-5'>
                        <button className={`btn ${style.cancelbtn}`} onClick={() => { setShow(false)}}>Cancel</button>
                        <button className={` btn ${style.paybtn}`} onClick={()=>{setPaymentDialog(true);setShow(false)}}>Pay ${auctionData?.buy_now_price + getThreePercent(auctionData?.buy_now_price) }</button>
                    </div>
                </div>
            </Dialog>
            <CommonPaymentDialogBox visible={paymentDialog} payment={auctionData?.buy_now_price + getThreePercent(auctionData?.buy_now_price)} setOutDialog={setShow} setVisible={setPaymentDialog} otherFunction={buySurrogate} functionData={auctionData?.buy_now_price + getThreePercent(auctionData?.buy_now_price)} reloadData={reload}/>

        </>
    )
}
