import React from "react";
import UnderConstruction from "../../UnderConstruction/UnderConstruction";
import style from "./Dashboard.module.css";
import { useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";
import { useState } from "react";
import LatestNews from "./LatestNews/LatestNews";
import AuctionPost from "./AuctionPost/auctionPost";
import Latestclasses from "./LatestClasees/Latestclasses";
import ScanCards from "./ScanCards/ScanCards";
import { useEffect } from "react";
import { useNavigate } from "react-router/dist";
import {
  auctionBidDetails,
  getAllSurrogateAuction,
  getSurrogateMotherData,
} from "../../../Api/SurrogateMotherApis";
import NoAuctionPost from "./NoAuctionPost/NoAuctionPost";
import AuctionNew from "../AuctionNew/AuctionNew";
import Loader from "../../common/Loader";

const Dashboard = () => {
  let token = new URLSearchParams(window.location.search).get("token");
  let email = new URLSearchParams(window.location.search).get("email");
  let userId = new URLSearchParams(window.location.search).get("id");
  let navigate = useNavigate();
  const [allData, setAllData] = useState({});
  const [activeData, setActiveData] = useState([]);
  const [loader,setLoader] = useState(true)

  const [uppercards, setUpperCards] = useState([]);
  const [showData,setShowData] = useState(false)

  let { t } = useTranslation();

  let userData = JSON.parse(sessionStorage.getItem("userData"));
  const getUserDatas = async (id) => {
    setShowData(true)
    if (sessionStorage.getItem("userData")) {
      let userData = JSON.parse(sessionStorage.getItem("userData"));
      console.log(userData.userId);

      const auction = await auctionBidDetails(userData.userId);
      console.log(auction);
      const data = await getSurrogateMotherData(userData.userId);
      let response = await getAllSurrogateAuction(userData.userId).catch(
        (err) => {
          console.log("Error : ", err);
        }
      );

      let dataReverse = [...response.data.data.active_Auction];
      let reverData = [];
      if (dataReverse?.length !== 0) {
        reverData = dataReverse.reverse();
      }
      setActiveData(reverData);
      setShowData(true)

      console.log(reverData, "reverdata,reverdata");
      console.log(auction);

      let obj = [
        {
          title: "Current Auction",
          main_content:
            auction?.data?.data?.length !== 0
              ? `$${auction.data.data[0].highest_Bid}`
              : 0,
          graph: "true",
          icon: "hammer_for_dashboard.png",
          percent:
            auction?.data?.data?.length !== 0
              ? auction.data.data[0].percentage_increment
              : 0,
          information_type: "from last bid",
        },
        {
          title: "Your Membership Id ",
          main_content: data.data.data.memberId,
          icon: "memberImage.png",
          information_type: "Recommend to Others",
        },
      ];
      setAllData(data.data.data);
      setUpperCards(obj);
    }
    setShowData(false)
  };

  useEffect(() => {
    getUserDatas();
  }, []);

  return (
    <div>
        <Loader open={showData} />
     {/* {showData && ( */}
      {/* <> */}
         <div className={`${style.main_container}`}>
        <div
          className={`${style.surrogate_name} fw_600 mb-4 lufga_26px container-fluid`}
        >
          Hii {allData?.firstName}{allData?.lastName},
          {console.log(allData.firstName)}
        </div>

        <div className={`${style.upper_card_section}`}>
          <ScanCards cardObject={uppercards} />
        </div>
      </div>

      <div>
        <div
          className={`d-flex mt-3 mb-2 align-items-center justify-content-between container-fluid`}
        >
          <div className={`lufga_24px fw_600`}>My Auction Post</div>
          <div
            className={`theme_color lufga_20px pointer `}
            onClick={() => navigate("/surrogate/auction")}
          >
            View All Posts
          </div>
        </div>
      
         {activeData.length !== 0 ? (
          <>
            <AuctionPost
              getData={activeData}
              showhead={false}
              getALLData={getUserDatas}
            />
          </>
        ) : (
          <>
            <NoAuctionPost />
          </>
        )}
       
      </div>

      <div>
        <LatestNews  />
      </div>

      <div>{/* <Latestclasses /> */}</div>
      {/* </>
     )}
      */}
    </div>
  );
};
export default Dashboard;
