import React, { useEffect, useState } from "react";
// import style from "./IntendedDashboard.module.css"
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FaArrowTrendDown, FaArrowTrendUp } from "react-icons/fa6";
import LatestNews from "../../SurrogateComponents/Dashboard/LatestNews/LatestNews";

import {
  getDashboardUser,
  updateDashboardUser,
  uploadDocument,
} from "../../../Api/CommonApis";
import { Dialog } from "primereact/dialog";
import { Button } from "reactstrap";
import style from "./EmbryoDashboard.module.css";
import MyAuctionSpace from "../Auction/MyAuctionSpace";
import Auction from "../Auction/Auction";
import BottomNavigation from '@mui/material/BottomNavigation'

function EmbryoDashboard() {
  const [userData, setUserData] = useState({});
  const [loader, setLoader] = useState(true);
  let userId = sessionStorage.getItem("userId");
  const [memberid, setMemberid] = useState();

  async function getUserData() {
    try {
      let res = await getDashboardUser(userId);
      console.log(res, "itsres itsres itsres");
      setUserData(res.data.data);
      setLoader(false);
    } catch (err) {
      console.error(err);
      setLoader(false);
    }
  }

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    console.log(userData, "userdata userdata updated");
    if (userData.memberId) {
      setMemberid(userData.memberId);
    }
  }, [userData]);

  const [visible, setVisible] = useState(false);
  const [position, setPosition] = useState("center");

  const footerContent = (
    <div>
      <Button
        label="No"
        icon="pi pi-times"
        onClick={() => setVisible(false)}
        className="p-button-text"
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        onClick={() => setVisible(false)}
        autoFocus
      />
    </div>
  );

  const show = (position) => {
    setPosition(position);
    setVisible(true);
  };
  let { t } = useTranslation();

  const [uppercards, setUpperCards] = useState([]);

  useEffect(() => {
    setUpperCards([
      // {
      //   title: "Credit Score",
      //   main_content: "689",
      //   graph: "true",
      //   icon: "hammer_for_dashboard.png",
      //   percent: "8.5%",
      //   information_type: " Up from last month",
      // },
      {
        
        title: "Your Membership Id",
        main_content: memberid , // Provide a default or loading state
        icon: "memberImage.png",
      },
    ]);
  }, [memberid]);

// Dummy data
  const cardDummy = {
    fullName : "Rebecca",
    member_id : "AJ45298",
  }

  return (

  
    <div>
      <div className={`${style.main_container1}`}>
        <div
          className={`${style.surrogate_name} lufga_24px fw_600`}
        >
          Hi  <span className={`ml-1`}>{userData?.fullName}</span> , 
        </div>
        <div className={`${style.upper_card_section} `}>
          <div className="mt-2 justify-content-left d-flex gap-3">
            {uppercards?.map((card, index) => (
              <div key={index}>
                <div className={`${style.card_wrapper} h-100`}>
                  <Link
                    style={{ height: "100%" }}
                    
                    className="d-flex"
                  >
                    <div className={`${style.card_body}`}>
                      <div className={`${style.card_upper}`}>
                        <div className={`${style.upper_left}`}>
                          <div className={`${style.left_upper} lufga_16px`}>
                            {t(card.title)}
                          </div>
                          <div className={`${style.left_lower}`}>
                            {t(userData?.memberId)}
                          </div>
                        </div>
                        <div className={`${style.upper_right}`}>
                          <img
                            className={`${style.card_img_top} mt-4`}
                            src={`/images/${card.icon}`}
                            alt={t("Card image cap")}
                          />
                        </div>
                      </div>

                      
                    </div>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
            

        {/* Mid Section Starts
          Top Matches for you  */}
      

        <div>
          <div className="">
            <div>
              <div className={``}>
                {/* <MyAuctionSpace outsidecall={true} /> */}
                <Auction limit={3}/>
              </div>
            </div>
          </div>
        </div>

        {/* Mid Section Ends 
          Top Matches for you */}

        
      </div>
      
      {/* Last Section Starts
      Latest News Section */}

      <div className="py-3">
        <LatestNews outsidecall={true}/>
      </div>

      {/* Last Section Ends
      Latest News Section */}
    </div>
  )  
}

export default EmbryoDashboard;
