import React from 'react'
import { ProgressSpinner } from 'primereact/progressspinner';

export default function Loader({open}) {
    return (
        <>
        {open? <div className='d-flex justify-content-center align-items-center' style={{ width: '100%', height: '100vh',background:'rgba(0,0,0,0.4)',zIndex:'9999',position:'absolute',top:'0',left:'0' }}>
                <ProgressSpinner strokeWidth="3" />
            </div>:''}
           
        </>
    )
}

