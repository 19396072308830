export const USER_POSITION = ["VOLUNTEER", "ANALYTICS_HEAD"]


export const HEAR_ABOUT_US = ["Search Engine", "Referral from NHP Surrogate or Egg Donor", "Internet Message Board", "Banner Advertisement", "Friend or Family", "Postcard", "Take-One Flyer", "News Story", "Newspaper Advertisement", "Social Media", "Yelp", "Other"];

export const SURROGATE_MOTHER_PREFERRED_METHOD = ["Phone", "Email", "Text Message", "Facebook Messenger", "WhatsApp"];

export const SURROGATE_MOTHER_RACE = ["White", "African american or Black", "Hispanic/Latino/Spanish Origin", "Asian", "American Indian or Alaskan Native", "Native Hawaiian or Other Pacific Islander", "Other/Unknown"];

export const SURROGATE_PREGNANCY_COMPLICATIONS = ["Pre-term labor", "Placenta previa", "High blood pressure", "Preeclampsia", "Gestational diabetes", "No complications", "Other"];

export const METHOD_OF_BIRTH_CONTROL = ["Birth control pills", "Condoms", "Contraceptive gel", "Depo Provera", "Diafragma", "IUD", "Norplant", "Patch", "Tubal ligation", "vasectomy", "Not sexually active", "None", "Other"];

export const SURROGATE_MARITAL_STATUS = ["Married", "Single, No Partner", "Not Married, but a commited relationship"];
export const CONTSDESCRIBE_SUFFERED_DEPRESSION = ["Mild", "Moderate", "Severe", "Postpartum"];
export const DEPRESSION_TREATMENT = ["Medication", "Counseling", "Exercise", "Lifestyle modification","Other"]

export const SURROGATE_INSURANCE_THROUGH = ["Your employer", "Your spouse/partner's employer", "The open market/Affordable Care Act plan", "Government assistance plan", "Other"]

