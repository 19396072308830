import React, { useEffect, useState } from "react";
import style from "./Auction.module.css";
import { useNavigate, Outlet } from "react-router-dom";
import { getDataAuctionById } from "../../../Api/IntendedParent";
import moment from "moment";
import Loader from "../../common/Loader";
import { t } from "i18next";
import { useParams } from "react-router";
import { BsThreeDotsVertical } from "react-icons/bs";
import NoAuctionPost from "../../SurrogateComponents/Dashboard/NoAuctionPost/NoAuctionPost";
import BidNowDialog from "./BidNowDialog/BidNowDialog";
import BuyNowModal from "./BuyNowModal";

export default function MyAuctionSpace(outsidecall) {
  const userId = sessionStorage.getItem("userId");
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [allData, setAllData] = useState([]);
  const [bidNowOpen, setBidNowOpen] = useState(false);
  const [buyNowOPen, setBuyNowOpen] = useState(false);
  const [cardId,setCardId] = useState()
  let params = useParams();

  async function getAllData() {
    try {
      let res = await getDataAuctionById(userId);
      setAllData(res.data.data);
      setLoader(false);
    } catch (err) {
      console.log(err);
      setLoader(false);
    }
  }

  useEffect(() => {
    getAllData();
  }, []);

  const calculateDateDifference = (startDate, endDate) => {
    const start = moment(startDate);
    const end = moment(endDate);
    const duration = moment.duration(end.diff(start));

    const days = duration.days();
    const hours = duration.hours();

    if (days > 0) {
      return `${days} days ${hours} hours left`;
    } else {
      return `${hours} hours left`;
    }
  };

  function checkMyBid(data) {
    let allBids = data?.bids;
    let myhighBid = allBids?.filter((res) => res?.biderId == userId);
    let sortedBids = myhighBid?.sort((a, b) => a?.bid - b?.bid).reverse();
    return sortedBids?.length !== 0
      ? `My highest bid $${sortedBids[0]?.bid}`
      : "";
  }
  return (
    <>
      <div
        className={
          outsidecall
            ? `mt-3 d-flex align-items-center justify-content-between mb-3`
            : `mt-3 d-flex align-items-center justify-content-between mb-3`
        }
      >
        <div
          className={outsidecall ? `lufga_24px fw_600` : `lufga_34px fw-bold`}
        >
          {" "}
          My Auction Space
        </div>
        <button
          className={`${style.AuctionSpaceButton}`}
          onClick={() => {
            navigate("/intendedParent/auctions");
          }}
        >
          NHP Auction
        </button>
      </div>

      {allData  .length === 0 ? (
        <NoAuctionPost source={"ip"}/>
      ) : (
        <>
          <div
            className={
              outsidecall
                ? `${style.cardContainer}`
                : `${style.cardContainer} mt-5`
            }
          >
            {allData &&
              Array.isArray(allData) &&
              allData?.map((card, index) => (
                <div key={index} className={`w-100 ${style.maincardContainer}`}>
                  <div
                    className={`${style.card_upper} pointer`}
                    onClick={() =>
                      calculateDateDifference(card.startDate, card.endDate) ==
                      "0 hours left"
                        ? {}
                        : navigate(
                            `/intendedParent/auctions/auctionResume/${card._id}`
                          )
                    }
                    // style={{
                    //   backgroundImage: `url('${card?.photoUrl}')`,
                    // }}
                  >
                     <div
                   className={`${style.card_upper_mask}`}
                   style={{
                    backgroundImage: `url('${card?.photoUrl}')`,
                  }}
                  >

                  </div>
                  <img
                    className={`${style.card_upper_image}`}
                    src={ card?.photoUrl}
                  />
                    <div className={`${style.upper_v_1}`}>
                      {/* <div className={`${style.upper_v_1_1} pointer`}>
                                            <BsThreeDotsVertical />
                                        </div> */}
                    </div>
                    <div className={`${style.upper_v_2}`}>
                      <div className={`${style.upper_v_2_1}`}>
                        {/* {calculateDateDifference(card.startDate, card.endDate)} */}
                        End Date-{moment(card.endDate).format("DD/MM/YY")}
                      </div>
                    </div>
                  </div>
                  <div className={`${style.card_lower} d-flex flex-column`}>
                    <div className="d-flex justify-content-between align-items-center py-2">
                      <div
                        className={`${style.lower_v_1} lufga_18px pointer`}
                        onClick={() =>
                          calculateDateDifference(
                            card.startDate,
                            card.endDate
                          ) == "0 hours left"
                            ? {}
                            : navigate(
                                `/intendedParent/auctions/auctionResume/${card._id}`
                              )
                        }
                      >
                       SM Id: {t(card.memberId)}
                      </div>
                      {checkMyBid(card) && (<>
                    
                    <div className={`${style.sBtn}`}>{checkMyBid(card)}</div>
                    </>)}
                    </div>
                    <div
                      className={`${style.card_lower_v_1} d-flex flex-column mt-auto`}
                    >
                      <div className={`my-4 ${style.sBtn}`}>
                        Surrogate Mother
                      </div>
                      <div className={`${style.lower_v_2}`}>
                        {t(card?.short_description)}
                      </div>
                      <div className="d-flex justify-content-between px-3 mb-3 mt-3 gap-4">
                        <div className={`${style.Currenttext}`}>
                          Current Bid :
                        </div>
                        <div className={`${style.Dollertext}`}>
                          ${card?.highest_Bid?.bid}
                        </div>
                      </div>
                      {card?.buyer?<div className="d-flex justify-content-between px-3 mb-3 mt- gap-4">
                        <div className={`${style.Currenttext}`}>
                          Buy Amount :
                        </div>
                        <div className={`${style.Dollertext}`}>
                          ${card?.buy_now_price}
                        </div>
                      </div>:""}
                      
                      <div className="d-flex justify-content-between gap-4 mt-auto">
                        {card.buyer?"":<>
                        <button
                          className={`btn ${style.lower_v_3}`}
                          onClick={() =>{
                            // navigate(
                            //     `/intendedParent/auctions/biding/${card._id}`
                            // )
                            setBidNowOpen(true);
                            setCardId(card._id)

                          }}
                          disabled={
                            calculateDateDifference(
                              card.startDate,
                              card.endDate
                            ) == "0 hours left"
                          }
                        >
                          Bid Now
                        </button>
                        <button
                          className={`btn ${style.lower_v_Buy}`}
                          onClick={() =>{
                            // navigate(
                            //     `/intendedParent/auctions/buyNow/${card._id}`
                            // )
                            setBuyNowOpen(true);
                            setCardId(card._id)
                          }
                          }
                          disabled={
                            calculateDateDifference(
                              card.startDate,
                              card.endDate
                            ) == "0 hours left"
                          }
                        >
                          Buy Now
                        </button>
                        </>}
                       
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>

          <BuyNowModal show={buyNowOPen} reload={getAllData} setShow={setBuyNowOpen} id={cardId}/>

          <BidNowDialog show={bidNowOpen} reload={getAllData} setShow={setBidNowOpen} id={cardId}/>
        </>
      )}
    </>
  );
}
