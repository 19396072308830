import React, { useEffect, useState } from "react";
import style from "./EggDonor.module.css";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import ViewDetails from "../ViewDetailsDialog/ViewDetails";
import {
    connectNotification,
    ConnectAddDataSurrogate
} from "../../../../Api/IntendedParent";

import { addSearchEmbryos } from "../../../../Api/embryo";

import { FaArrowLeft } from "react-icons/fa6";
import Loader from "../../../common/Loader";
import {
    gethabitsdata,
    getlifestyledata,
    getUserDataById,
} from "../../../../Api/admin";
import Toaster from "../../../common/Toaster";


const FindEmbryoDonor = () => {
    let { t } = useTranslation();
    const navigate = useNavigate();
    let params = useParams();
    const [visibale, setVisibale] = useState(false);
    const [loader, setLoader] = useState(false);
    const [getAllHabits, setGetAllHabits] = useState([]);
    const [getAllLifeStyle, setGetAllLifeStyle] = useState([]);
    const [userData, setUserData] = useState();
    const [toaster, setToaster] = useState({
        show: false,
        message: "",
    });

    const [connectedData, setConnectedData] = useState(false);

    const [connectData, setConnectData] = useState()


    // console.log(params)

    const userId = sessionStorage.getItem("userId");

    const getUserDataIP = async () => {
        await getUserDataById(userId)
            .then((res) => {
                console.log(res)
                let responseData = res.data.data;
                // let savedData = responseData.saved_surrogate.includes(surrogate_id)
                setUserData(res.data.data);
                setConnectedData(res.data.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const [formateData, setFormatData] = useState(
        {
            "bioMotherRace": "",
            "bioMotherMaxHeight": {
                "maxFt": "",
                "maxIn": ""
            },
            "bioMotherMinHeight": {
                "minFt": "",
                "minIn": ""
            },
            "bioMotherMaxWeight": {
                "maxLbs": "",
                "maxOz": ""
            },
            "bioMotherMinWeight": {
                "minLbs": "",
                "minOz": ""
            },
            "bioMotherAge": {
                "max": "",
                "min": ""
            },
            "bioFatherRace": "",
            "bioFatherMaxHeight": {
                "maxFt": "",
                "maxIn": ""
            },
            "bioFatherMinHeight": {
                "minFt": "",
                "minIn": ""
            },
            "bioFatherMaxWeight": {
                "maxLbs": "",
                "maxOz": ""
            },
            "bioFatherMinWeight": {
                "minLbs": "",
                "minOz": ""
            },
            "bioFatherAge": {
                "max": "",
                "min": ""
            }
        }
    );

    const handleInputChange = (e) => {

        const { name, value } = e.target;

        if (e.target.value === "") {

            console.log("if inside");
            setFormatData((prevState) => ({
                ...prevState,
                [name]: null,
            }));

        }
        else {

            console.log("else inside");
            setFormatData((prevState) => ({
                ...prevState,
                [name]: value,
            }));

        }

    };

    const connectSuperAdmin = async (cardData) => {
        setLoader(true);
        let payLoadData = {
            role: ["admin"],
            super_admin_message: {
                heading: "New Connection Request",
                msg: `${userData.fullName} has requested to connect with ${cardData?.firstName} (SM)`,
            },
            surrogate_id: cardData.userId,
        };
        await connectNotification(userId, payLoadData)
            .then((res) => {
                // console.log(res)
                setToaster({
                    show: true,
                    message: "Your connection request has been submitted",
                });
                setLoader(false);
                getUserDataIP();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const ConnectLeads = async (cardData) => {

        setLoader(true);

        let payloadLeads = {
            connected_by: {
                id: userId,
                role: userData.role

            },
            connected_to: {
                id: cardData.userId,
                role: "eggDonor",
            }
        }
        await ConnectAddDataSurrogate(payloadLeads).then((res) => {
            console.log(res)
            setLoader(false)
            setToaster({
                show: true,
                message: "Your connection request has been submitted",
            });
            addSearchData()
        }).catch((err) => {
            console.log(err)
            setLoader(false)
        })

    }


    // const addSearchData = async () => {
    //     let payloadData = { ...formateData };

    //     let payload = {
    //         connected_by_id: userId,
    //         service_offer: "eggDonor",

    //         ...payloadData
    //     }






    //     // console.log(payloadData)



    //     // if (payloadData?.maxHeightFeet) {
    //     //     let dataInch = +(payloadData?.maxHeightFeet) * 12
    //     //     payload.maxHeight = +(payload.maxHeight) + dataInch
    //     // }

    //     // if (payloadData?.maxHeightInches) {
    //     //     let dataInch = +(payloadData?.maxHeightInches)
    //     //     payload.maxHeight = +(payload.maxHeight) + dataInch
    //     // }

    //     // if (payloadData?.minHeightFeet) {
    //     //     let dataInch = +(payloadData?.minHeightFeet) * 12
    //     //     payload.minHeight = +(payload.minHeight) + dataInch
    //     // }

    //     // if (payloadData?.minHeightInches) {
    //     //     let dataInch = +(payloadData?.minHeightInches)
    //     //     payload.minHeight = +(payload.minHeight) + dataInch
    //     // }

    //     // if (payloadData?.maxWeightLBs) {
    //     //     let dataInch = +(payloadData?.maxWeightLBs) * 16
    //     //     payload.maxWeight = +(payload.maxWeight) + dataInch
    //     // }

    //     // if (payloadData?.maxWeightOz) {
    //     //     let dataInch = +(payloadData?.maxWeightOz)
    //     //     payload.maxWeight = +(payload.maxWeight) + dataInch
    //     // }

    //     // if (payloadData?.minWeightLBs) {
    //     //     let dataInch = +(payloadData?.minWeightLBs) * 16
    //     //     payload.minWeight = +(payload.minWeight) + dataInch
    //     // }

    //     // if (payloadData?.minWeightOz) {
    //     //     let dataInch = +(payloadData?.minWeightOz)
    //     //     payload.minWeight = +(payload.minWeight) + dataInch
    //     // }



    //     // delete payload?.bioMotherAge?.min
    //     // delete payload?.bioMotherAge?.max
    //     // delete payload?.bioMotherRace
    //     // delete payload?.bioMotherMinHeight?.minFt
    //     // delete payload?.bioMotherMinHeight?.minIn
    //     // delete payload?.bioMotherMaxHeight?.maxFt
    //     // delete payload?.bioMotherMaxHeight?.maxIn
    //     // delete payload?.bioMotherMinWeight?.minLbs
    //     // delete payload?.bioMotherMinWeight?.minOz
    //     // delete payload?.bioMotherMaxWeight?.maxLbs
    //     // delete payload?.bioMotherMaxWeight?.maxOz

    //     // delete payload?.bioFatherAge?.min
    //     // delete payload?.bioFatherAge?.max
    //     // delete payload?.bioFatherRace
    //     // delete payload?.bioFatherMinHeight?.minFt
    //     // delete payload?.bioFatherMinHeight?.minIn
    //     // delete payload?.bioFatherMaxHeight?.maxFt
    //     // delete payload?.bioFatherMaxHeight?.maxIn
    //     // delete payload?.bioFatherMinWeight?.minLbs
    //     // delete payload?.bioFatherMinWeight?.minOz
    //     // delete payload?.bioFatherMaxWeight?.maxLbs
    //     // delete payload?.bioFatherMaxWeight?.maxOz

    //     setLoader(true);
    //     await addSearchEmbryos(payloadData)
    //         .then((res) => {
    //             setCards(res.data.data);
    //             setLoader(false);
    //             console.log(res)
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //             setLoader(false);
    //         });
    // };


    const addSearchData = async () => {
        // Filter out the keys in formateData that have values
        let payloadData = {};
        Object.keys(formateData).forEach(key => {
            if (typeof formateData[key] === 'object' && formateData[key] !== null) {
                // If the value is an object, we need to check if it has any values inside
                let innerObject = {};
                Object.keys(formateData[key]).forEach(innerKey => {
                    if (formateData[key][innerKey] !== "") {
                        innerObject[innerKey] = formateData[key][innerKey];
                    }
                });
                if (Object.keys(innerObject).length > 0) {
                    payloadData[key] = innerObject;
                }
            } else if (formateData[key] !== "" && formateData[key] !== null) {
                // If the value is not an object, check if it's not empty or null
                payloadData[key] = formateData[key];
            }
        });
    
        let payload = {
            connected_by_id: userId,
            service_offer: "eggDonor",
            ...payloadData
        };
    
        setLoader(true);
        await addSearchEmbryos(payload)
            .then((res) => {
                setCards(res.data.data);
                setLoader(false);
                console.log(res);
            })
            .catch((err) => {
                console.log(err);
                setLoader(false);
            });
    };


    const clearFilter = async () => {
        console.log(params.name)
        setFormatData({
            service_offer: "embryo_donor",
        });
        await addSearchEmbryos({
            service_offer: "embryo_donor", setFormatData
        })
            .then((res) => {
                setCards(res.data.data);
                setLoader(false);
                // console.log(res)
            })
            .catch((err) => {
                console.log(err);
                setLoader(false);
            });
    };
    useEffect(() => {
        addSearchData();

        getUserDataIP();
    }, []);
    const [cards, setCards] = useState([]);

    return (
        <div>
            <Toaster
                event={toaster.message}
                show={toaster.show}
                severity={"success"}
                setToaster={setToaster}
            />
            <Loader open={loader} />

            <ViewDetails visibale={visibale} setVisibale={setVisibale} />
            <div className="mb-4 mt-4 ms-4 w-25">
                <div
                    className="d-flex align-items-center gap-2 btn-Secondary p-0 pointer w-50"
                    onClick={() => navigate("/intendedParent/search")}
                >
                    <FaArrowLeft /> Back
                </div>
            </div>
            <div className={style.Search_con}>
                <div className="d-flex justify-content-between mb-2">
                    <div className={style.CreditText}>Find a Embryo Donor</div>
                    <div>
                        <button
                            className={style.ExportButton}
                            onClick={() => navigate("savepost")}
                        >
                            Saved Contacts
                        </button>
                    </div>
                </div>
                <div className={style.FormCustom}>
                    <div className={`${style.styleIngrid}`}>

                        <div className="">
                            <label
                                className={`form-check-label mb-2 ${style.LabelText}`}
                                htmlFor="flexCheckDefault"
                            >
                                {t("Bio Mother Age Range")}
                            </label>
                            <input
                                type="number"
                                className="form-control"
                                placeholder="Min age"
                                name="bioMotherAge.min"
                                onChange={(e) => {
                                    setFormatData(pre => {
                                        pre.bioMotherAge.min = e.target.value;
                                        return ({ ...pre })
                                    })
                                }}
                                value={formateData?.bioMotherAge?.min ? formateData?.bioMotherAge?.min : ""}
                            />
                            {/* {console.log(formateData?.minAge)} */}
                        </div>
                        <div className="">
                            <label
                                className={`form-check-label mb-2 ${style.LabelText}`}
                                htmlFor="flexCheckDefault"
                            >
                                {t("")}  <span className="text-danger"></span>
                            </label>
                            <input
                                type="number"
                                className="form-control"
                                placeholder="Max Age"
                                name="bioMotherAge.max"
                                onChange={(e) => {
                                    setFormatData(pre => {
                                        pre.bioMotherAge.max = e.target.value;
                                        return ({ ...pre })
                                    })
                                }}
                                value={formateData?.bioMotherAge?.max ? formateData?.bioMotherAge?.max : ""}
                            />
                        </div>



                        <div className="">
                            <label
                                className={`form-check-label mb-2 ${style.LabelText}`}
                                htmlFor="flexCheckDefault"
                            >
                                {t("Bio Mother Race")}
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Bio Mother Race"
                                name="bioMotherRace"
                                onChange={(e) => {
                                    setFormatData(pre => {
                                        pre.bioMotherRace = e.target.value;
                                        return ({ ...pre })
                                    })
                                }}
                                value={formateData?.bioMotherRace ? formateData?.bioMotherRace : ""}
                            />
                        </div>

                    </div>

                    <div>

                        <div className="row mt-2">
                            <div className='col-md-6'>
                                <div className="row">
                                    <div className="col-6">
                                        <label htmlFor="exampleInputEmail1" className={`form-check-label mb-2 ${style.LabelText}`}>{t("Bio Mother (Min Height)")}</label>

                                        <div className='d-flex justify-content-between gap-2'>
                                            <input type='number'
                                                className='form-control'
                                                placeholder='Min Height'
                                                value={formateData?.bioMotherMinHeight?.minFt ? formateData?.bioMotherMinHeight?.minFt : ""}
                                                name="bioMotherMinHeight.minFt"
                                                onChange={(e) => {
                                                    setFormatData(pre => {
                                                        pre.bioMotherMinHeight.minFt = e.target.value;
                                                        return ({ ...pre })
                                                    })
                                                }}
                                            />

                                            <div className="d-flex align-items-center">{t("Ft")}</div>
                                            <input type='number'

                                                className='form-control'
                                                placeholder='Min Height'
                                                value={formateData?.bioMotherMinHeight?.minIn ? formateData?.bioMotherMinHeight?.minIn : ""}
                                                name="bioMotherMinHeight.minIn"
                                                onChange={(e) => {
                                                    setFormatData(pre => {
                                                        pre.bioMotherMinHeight.minIn = e.target.value;
                                                        return ({ ...pre })
                                                    })
                                                }}
                                            />
                                            <div className="d-flex align-items-center">{t("In")}</div>
                                        </div>
                                    </div>

                                    <div className="col-6">
                                        <label htmlFor="exampleInputEmail1" className={`form-check-label mb-2 ${style.LabelText}`}>{t("Bio Mother (Max Height)")}</label>
                                        <div className='d-flex justify-content-between gap-2'>
                                            <input type='number'
                                                className='form-control'
                                                placeholder='Max Height'
                                                value={formateData?.bioMotherMaxHeight?.maxFt ? formateData?.bioMotherMaxHeight?.maxFt : ""}
                                                name="bioMotherMaxHeight.maxFt"
                                                onChange={(e) => {
                                                    setFormatData(pre => {
                                                        pre.bioMotherMaxHeight.maxFt = e.target.value;
                                                        return ({ ...pre })
                                                    })
                                                }}
                                            />

                                            <div className="d-flex align-items-center">{t("Ft")}</div>
                                            <input type='number'

                                                className='form-control'
                                                placeholder='Max Height'
                                                value={formateData?.bioMotherMaxHeight?.maxIn ? formateData?.bioMotherMaxHeight?.maxIn : ""}
                                                name="bioMotherMaxHeight.maxIn"
                                                onChange={(e) => {
                                                    setFormatData(pre => {
                                                        pre.bioMotherMaxHeight.maxIn = e.target.value;
                                                        return ({ ...pre })
                                                    })
                                                }}
                                            />
                                            <div className="d-flex align-items-center">{t("In")}</div>
                                        </div>
                                    </div>

                                </div>
                            </div>


                            <div className='col-md-6'>
                                <div className="row">
                                    <div className="col-6">
                                        <label htmlFor="exampleInputEmail1" className={`form-check-label mb-2 ${style.LabelText}`}>{t("Bio Mother (Min Weight)")}</label>

                                        <div className='d-flex justify-content-between gap-2'>
                                            <input type='number'

                                                className='form-control'
                                                placeholder='Min weight'
                                                name="bioMotherMinWeight.minLbs"
                                                value={formateData?.bioMotherMinWeight?.minLbs ? formateData?.bioMotherMinWeight?.minLbs : ""}
                                                onChange={(e) => {
                                                    setFormatData(pre => {
                                                        pre.bioMotherMinWeight.minLbs = e.target.value;
                                                        return ({ ...pre })
                                                    })
                                                }}
                                            />
                                            <div className="d-flex align-items-center">{t("Lbs")}</div>
                                            <input type='number'

                                                className='form-control'
                                                placeholder='Min weight'
                                                name="bioMotherMinWeight?.minOz"
                                                value={formateData?.bioMotherMinWeight?.minOz ? formateData?.bioMotherMinWeight?.minOz : ""}
                                                onChange={(e) => {
                                                    setFormatData(pre => {
                                                        pre.bioMotherMinWeight.minOz = e.target.value;
                                                        return ({ ...pre })
                                                    })
                                                }}
                                            />
                                            <div className="d-flex align-items-center">{t("Oz")}</div>
                                        </div>
                                    </div>

                                    <div className="col-6">

                                        <label htmlFor="exampleInputEmail1" className={`form-check-label mb-2 ${style.LabelText}`}>{t("Bio Mother (Max Weight)")}</label>

                                        <div className='d-flex justify-content-between gap-2'>
                                            <input type='number'

                                                className='form-control'
                                                placeholder='Max weight'
                                                name="bioMotherMaxWeight.maxLbs"
                                                value={formateData?.bioMotherMaxWeight?.maxLbs ? formateData?.bioMotherMaxWeight?.maxLbs : ""}
                                                onChange={(e) => {
                                                    setFormatData(pre => {
                                                        pre.bioMotherMaxWeight.maxLbs = e.target.value;
                                                        return ({ ...pre })
                                                    })
                                                }}
                                            />
                                            <div className="d-flex align-items-center">{t("Lbs")}</div>
                                            <input type='number'

                                                className='form-control'
                                                placeholder='Max weight'
                                                name="bioMotherMaxWeight.maxOz"
                                                value={formateData?.bioMotherMaxWeight?.maxOz ? formateData?.bioMotherMaxWeight?.maxOz : ""}
                                                onChange={(e) => {
                                                    setFormatData(pre => {
                                                        pre.bioMotherMaxWeight.maxOz = e.target.value;
                                                        return ({ ...pre })
                                                    })
                                                }}
                                            />
                                            <div className="d-flex align-items-center">{t("Oz")}</div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>















                    <div className={`${style.styleIngrid} mt-3`}>

                        <div className="">
                            <label
                                className={`form-check-label mb-2 ${style.LabelText}`}
                                htmlFor="flexCheckDefault"
                            >
                                {t("Bio Father Age Range")}
                            </label>
                            <input
                                type="number"
                                className="form-control"
                                placeholder="Min age"
                                name="bioFatherAge.min"
                                onChange={(e) => {
                                    setFormatData(pre => {
                                        pre.bioFatherAge.min = e.target.value;
                                        return ({ ...pre })
                                    })
                                }}
                                value={formateData?.bioFatherAge?.min ? formateData?.bioFatherAge?.min : ""}
                            />
                            {/* {console.log(formateData?.minAge)} */}
                        </div>
                        <div className="">
                            <label
                                className={`form-check-label mb-2 ${style.LabelText}`}
                                htmlFor="flexCheckDefault"
                            >
                                {t("")}  <span className="text-danger"></span>
                            </label>
                            <input
                                type="number"
                                className="form-control"
                                placeholder="Max Age"
                                name="bioFatherAge.max"
                                onChange={(e) => {
                                    setFormatData(pre => {
                                        pre.bioFatherAge.max = e.target.value;
                                        return ({ ...pre })
                                    })
                                }}
                                value={formateData?.bioFatherAge?.max ? formateData?.bioFatherAge?.max : ""}
                            />
                        </div>



                        <div className="">
                            <label
                                className={`form-check-label mb-2 ${style.LabelText}`}
                                htmlFor="flexCheckDefault"
                            >
                                {t("Bio father Race")}
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Bio Father Race"
                                name="bioFatherRace"
                                onChange={(e) => {
                                    setFormatData(pre => {
                                        pre.bioFatherRace = e.target.value;
                                        return ({ ...pre })
                                    })
                                }}
                                value={formateData?.bioFatherRace ? formateData?.bioFatherRace : ""}
                            />
                        </div>

                    </div>

                    <div>

                        <div className="row mt-2">
                            <div className="col-md-6">
                                <div className="row">
                                    <div className='col-md-6'>
                                        <label htmlFor="exampleInputEmail1" className={`form-check-label mb-2 ${style.LabelText}`}>{t("Bio Father (Min Height)")}</label>

                                        <div className='d-flex justify-content-between gap-2'>
                                            <input type='number'
                                                className='form-control'
                                                placeholder='Min Height'
                                                value={formateData?.bioFatherMinHeight?.minFt ? formateData?.bioFatherMinHeight?.minFt : ""}
                                                name="bioFatherMinHeight.minFt"
                                                onChange={(e) => {
                                                    setFormatData(pre => {
                                                        pre.bioFatherMinHeight.minFt = e.target.value;
                                                        return ({ ...pre })
                                                    })
                                                }}
                                            />

                                            <div className="d-flex align-items-center">{t("Ft")}</div>
                                            <input type='number'

                                                className='form-control'
                                                placeholder='Min Height'
                                                value={formateData?.bioFatherMinHeight?.minIn ? formateData?.bioFatherMinHeight?.minIn : ""}
                                                name="bioFatherMinHeight?.minIn"
                                                onChange={(e) => {
                                                    setFormatData(pre => {
                                                        pre.bioFatherMinHeight.minIn = e.target.value;
                                                        return ({ ...pre })
                                                    })
                                                }}
                                            />
                                            <div className="d-flex align-items-center">{t("In")}</div>
                                        </div>
                                    </div>

                                    <div className="col-6">
                                        <label htmlFor="exampleInputEmail1" className={`form-check-label mb-2 ${style.LabelText}`}>{t("Bio Father (Max Height)")}</label>
                                        <div className='d-flex justify-content-between gap-2'>
                                            <input type='number'
                                                className='form-control'
                                                placeholder='Max Height'
                                                value={formateData?.bioFatherMaxHeight?.maxFt ? formateData?.bioFatherMaxHeight?.maxFt : ""}
                                                name="bioFatherMaxHeight.maxFt"
                                                onChange={(e) => {
                                                    setFormatData(pre => {
                                                        pre.bioFatherMaxHeight.maxFt = e.target.value;
                                                        return ({ ...pre })
                                                    })
                                                }}
                                            />

                                            <div className="d-flex align-items-center">{t("Ft")}</div>
                                            <input type='number'

                                                className='form-control'
                                                placeholder='Max Height'
                                                value={formateData?.bioFatherMaxHeight?.maxIn ? formateData?.bioFatherMaxHeight?.maxIn : ""}
                                                name="bioFatherMaxHeight.maxIn"
                                                onChange={(e) => {
                                                    setFormatData(pre => {
                                                        pre.bioFatherMaxHeight.maxIn = e.target.value;
                                                        return ({ ...pre })
                                                    })
                                                }}
                                            />
                                            <div className="d-flex align-items-center">{t("In")}</div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className='col-md-6'>
                                <div className="row">
                                    <div className="col-6">
                                        <label htmlFor="exampleInputEmail1" className={`form-check-label mb-2 ${style.LabelText}`}>{t("Bio Father (Min Weigh)")}</label>

                                        <div className='d-flex justify-content-between gap-2'>
                                            <input type='number'

                                                className='form-control'
                                                placeholder='Min weight'
                                                name="bioFatherMinWeight.minLbs"
                                                value={formateData?.bioFatherMinWeight?.minLbs ? formateData?.bioFatherMinWeight?.minLbs : ""}
                                                onChange={(e) => {
                                                    setFormatData(pre => {
                                                        pre.bioFatherMinWeight.minLbs = e.target.value;
                                                        return ({ ...pre })
                                                    })
                                                }}
                                            />
                                            <div className="d-flex align-items-center">{t("Lbs")}</div>
                                            <input type='number'

                                                className='form-control'
                                                placeholder='Min weight'
                                                name="bioFatherMinWeight.minOz"
                                                value={formateData?.bioFatherMinWeight?.minOz ? formateData?.bioFatherMinWeight?.minOz : ""}
                                                onChange={(e) => {
                                                    setFormatData(pre => {
                                                        pre.bioFatherMinWeight.minOz = e.target.value;
                                                        return ({ ...pre })
                                                    })
                                                }}
                                            />
                                            <div className="d-flex align-items-center">{t("Oz")}</div>
                                        </div>
                                    </div>

                                    <div className="col-6">
                                        <label htmlFor="exampleInputEmail1" className={`form-check-label mb-2 ${style.LabelText}`}>{t("Bio Father (Max Weight)")}</label>

                                        <div className='d-flex justify-content-between gap-2'>
                                            <input type='number'

                                                className='form-control'
                                                placeholder='Max weight'
                                                name="bioFatherMaxWeight.maxLbs"
                                                value={formateData?.bioFatherMaxWeight?.maxLbs ? formateData?.bioFatherMaxWeight?.maxLbs : ""}
                                                onChange={(e) => {
                                                    setFormatData(pre => {
                                                        pre.bioFatherMaxWeight.maxLbs = e.target.value;
                                                        return ({ ...pre })
                                                    })
                                                }}
                                            />
                                            <div className="d-flex align-items-center">{t("Lbs")}</div>
                                            <input type='number'

                                                className='form-control'
                                                placeholder='Max weight'
                                                name="bioFatherMaxWeight.maxOz"
                                                value={formateData?.bioFatherMaxWeight?.maxOz ? formateData?.bioFatherMaxWeight?.maxOz : ""}
                                                onChange={(e) => {
                                                    setFormatData(pre => {
                                                        pre.bioFatherMaxWeight.maxOz = e.target.value;
                                                        return ({ ...pre })
                                                    })
                                                }}
                                            />
                                            <div className="d-flex align-items-center">{t("Oz")}</div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>






                    <div className="d-flex justify-content-center mt-4">
                        <div className="d-flex gap-2">
                            <button
                                className={style.SearchButton}
                                onClick={() => {
                                    addSearchData();
                                }}
                            >
                                Search Embryo Donor
                            </button>
                            <button
                                className={` ${style.clear_profile} me-3`}
                                onClick={() => {
                                    clearFilter();
                                }}
                            >
                                Clear Search
                            </button>
                        </div>
                    </div>
                </div>

                <div className={`${style.cardContainer} mt-5`}>
                    {cards?.map((card, index) => (
                        <div key={index} className={`w-100  ${style.maincardContainer}`}>
                            <div
                                className={`${style.card_upper} pointer`}

                            >
                                <div
                                    className={`${style.card_upper_mask}`}
                                    style={{
                                        backgroundImage: `url(${card?.bio_mother?.mother_photos?.[0]?.fileUrl
                                            })`,
                                    }}
                                >

                                </div>
                                <img
                                    className={`${style.card_upper_image}`}
                                    src={card?.bio_mother?.mother_photos?.[0]?.fileUrl}
                                />
                                <div className={`${style.upper_v_1}`}></div>
                            </div>
                            <div className={`${style.card_lower} d-flex flex-column`}>
                                <div className={`${style.cardAgeText} `}>
                                    Embryo Id: {t(card.memberId)}
                                    {/* , Age:{t(card.age)} */}
                                </div>
                                <div className="d-flex justify-content-between align-items-center py-2">
                                    {/* <div className={`${style.lower_v_2}  `}>
                                        {card?.height?.feet || card?.height?.inches ? `Height:` : null}
                                        {card?.height?.feet || card?.height?.inches
                                            ? `${card?.height?.feet}.${card?.height?.inches} Ft,`
                                            : ""}{" "}
                                        {card?.skin_color}
                                    </div> */}
                                </div>
                                <div
                                    className={`${style.card_lower_v_1} d-flex flex-column mt-auto`}
                                >
                                    <div className="d-flex justify-content-between gap-4 mt-auto">
                                        {card?.is_connected === true ? (
                                            <div className={`${style.lower_v_3}`}>Connected</div>
                                        ) : (
                                            <button
                                                className={`btn ${style.lower_v_3}`}
                                                onClick={() => {
                                                    ConnectLeads(card);
                                                }}
                                            >
                                                Connect
                                            </button>
                                        )}

                                        <button
                                            className={`btn ${style.lower_v_Buy}`}
                                            onClick={() => {
                                                navigate(`/embryo_donor/viewDetail/${card.userId}`);
                                            }}
                                        >
                                            View Details
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default FindEmbryoDonor;
