import axios from "axios"
import { auctionService, baseUrl, intendedParent,adoptingParent } from "./EndPoint"


let token = sessionStorage.getItem("authToken")

let config = {
    headers: {
        Authorization: `Bearer ${token}`,
    }
  }

export async function pushNotificationData(id,data){
    return await axios.put(`${baseUrl}${intendedParent.pushNotification}${id}`,data,config)
}

export async function emailNotificationData(id,data){
    return await axios.put(`${baseUrl}${intendedParent.emailNotification}${id}`,data,config)
}

export async function updatePassword(id,data){
    return await axios.put(`${baseUrl}${intendedParent.changepassword}${id}`,data,config)
}



export async function getAllAuctionData(){
    return await axios.get(`${baseUrl}${auctionService.getAllAuctions}`,config)
}


export async function bidOnAuction(id,data){
    return await axios.put(`${baseUrl}${auctionService.bidOnAuction}${id}`,data,config)
}



export async function getAuctionDataById(id){
    return await axios.get(`${baseUrl}${auctionService.getAuctionById}${id}`,config)
}


export async function getResumeData(id){
    return await axios.get(`${baseUrl}${intendedParent.getResumeData}${id}`,config)
}


export async function buyNowSurrogate(id,data){
    return await axios.put(`${baseUrl}${auctionService.buyNow}${id}`,data,config)
}

export async function getDataAuctionById(id){
    return await axios.get(`${baseUrl}${intendedParent.myAuctiongetData}${id}`,config)
}

export async function addSearchIntendent(data){
    return await axios.post(`${baseUrl}${intendedParent.searchSurrogate}`,data,config)
}

export async function saveSurrogate(id,data){
    return await axios.put(`${baseUrl}${intendedParent.userDashBoard}${id}`,data,config)
}

export async function getsaveSurrogate(id){
    return await axios.get(`${baseUrl}${intendedParent.getsaveUsersurrogat}${id}`,config)
}

export async function connectNotification(id,data){
    return await axios.post(`${baseUrl}${intendedParent.connectsurrogate}${id}`,data,config)
}

export async function ConnectAddDataSurrogate(data){
    return await axios.post(`${baseUrl}${intendedParent.connectDataSurrogate}`,data,config)
}




export const addAdoptingParent = async (userId, body) => {
    return await axios.post(
      `${baseUrl}${adoptingParent.addAdoptingParent}${userId}`,
      body
    );
  }; 
  
  export const getAdoptingParentFormData = async (id) => {
    return await axios.get(`${baseUrl}${adoptingParent.getAdoptingParentById}${id}`)
  }
  
  export const addIntendedParentFormData = async (userId, body) => {
    return await axios.post(
      `${baseUrl}${intendedParent.addIntendedParent}${userId}`,
      body
    );
  }; 
  
  export const getIntendedParentsFormData = async (id) => {
    return await axios.get(`${baseUrl}${intendedParent.getIntendedParentById}${id}`)
  }

  export const verifyIntendedParentResume=async(data,id)=>{
    return await axios.put(`${baseUrl}${intendedParent.verifyIntendedResumeById}${id}`,data,config)
  }
  

  export const verifyAdoptingParentResume=async(data,id)=>{
    return await axios.put(`${baseUrl}${adoptingParent.verifyIntendedResumeById}${id}`,data,config)
  }
