import React, { useState } from "react";
import "primeicons/primeicons.css";
import style from "./Roles.module.css";
import { RxDashboard } from "react-icons/rx";
import { NavLink, Navigate, useLocation, useNavigate } from "react-router-dom";
import { GoQuestion, GoChecklist, GoPerson } from "react-icons/go";
import {
  MdKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
  MdOutlineManageAccounts,
} from "react-icons/md";
import {
  RiArrowDropDownLine,
  RiAuctionLine,
  RiExchangeDollarLine,
} from "react-icons/ri";
import {
  PiListChecks,
  PiPersonArmsSpreadThin,
  PiUserListLight,
} from "react-icons/pi";
import { IoSettingsOutline } from "react-icons/io5";
import { LuBarChart4 } from "react-icons/lu";
import { TbNews } from "react-icons/tb";

const SuperAdmin = () => {
  const [open, SetOpen] = useState(false);
  const [manage, SetManage] = useState(false);
  const [masterList, setMasterList] = useState(false);
  const navigate = useNavigate("");

  return (
    <div>
      <NavLink
        style={{ textDecoration: "none" }}
        to={`/superadmin/manageaccounts`}
        onClick={(e) => {
          SetManage(!manage);
          e.preventDefault();
        }}
      >
        <div className="navlink ">
          <div className="fs-4 d-flex align-items-center">
            <MdOutlineManageAccounts />
          </div>
          <div className="mt-1">Manage Accounts</div>
  
            {manage ? <MdOutlineKeyboardArrowUp className="fs-5" /> : <MdKeyboardArrowDown className="fs-5" />}
          
        </div>
      </NavLink>
      <div className={`${style.sub_navlinks}`}>
        <div
          className={`${style.toggle_of_sub_navlinks} toggle_of_sub_navlinks`}
          style={{ display: manage ? "flex" : "none" }}
        >
          <NavLink
            className={({ isActive }) =>
              isActive ? "superadmin_dashboard_sub_navlink" : ""
            }
            style={{ textDecoration: "none" }}
            to={`/superadmin/manageaccounts/surrogate`}
          >
            <div className="navlink">
              <div>Surrogate Mother</div>
            </div>
          </NavLink>

          <NavLink
            className={({ isActive }) =>
              isActive ? "superadmin_dashboard_sub_navlink" : ""
            }
            style={{ textDecoration: "none" }}
            to={`/superadmin/manageaccounts/intendedparents`}
          >
            <div className="navlink">
              <div>Intended Parents</div>
            </div>
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              isActive ? "superadmin_dashboard_sub_navlink" : ""
            }
            style={{ textDecoration: "none" }}
            to={`/superadmin/manageaccounts/ivfclinics`}
          >
            <div className="navlink">
              <div>IVF Clinics</div>
            </div>
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              isActive ? "superadmin_dashboard_sub_navlink" : ""
            }
            style={{ textDecoration: "none" }}
            to={`/superadmin/manageaccounts/lawyer`}
          >
            <div className="navlink">
              <div>Lawyers</div>
            </div>
          </NavLink>
        </div>
      </div>
      <NavLink
        style={{ textDecoration: "none" }}
        to={`/superadmin/subscriptionplan`}
      >
        <div className="navlink">
          <div className="fs-4 d-flex align-items-center">
            <RiExchangeDollarLine />
          </div>
          <div className="mt-1">Subscription Plans</div>
        </div>
      </NavLink>
      <NavLink
        style={{ textDecoration: "none" }}
        to={`/superadmin/activeusers`}
      >
        <div className="navlink">
          <div className="fs-4 d-flex align-items-center">
            <PiUserListLight />
          </div>
          <div className="mt-1">Active Subscribers</div>
        </div>
      </NavLink>
      <NavLink
        style={{ textDecoration: "none" }}
        to={`/superadmin/dashboard`}
        onClick={(e) => {
          SetOpen(!open);
          e.preventDefault();
        }}
      >
        <div className="navlink">
          <div className="fs-4 d-flex align-items-center">
            <IoSettingsOutline />
          </div>
          <div className="mt-1">Global Settings</div>
          {open ? <MdOutlineKeyboardArrowUp className="fs-5" /> : <MdKeyboardArrowDown className="fs-5" />}

        </div>
      </NavLink>

      <div className={`${style.sub_navlinks}`}>
        <div
          className={`${style.toggle_of_sub_navlinks} toggle_of_sub_navlinks`}
          style={{ display: open ? "flex" : "none" }}
        >
          <NavLink
            className={({ isActive }) =>
              isActive ? "superadmin_dashboard_sub_navlink" : ""
            }
            style={{ textDecoration: "none" }}
            to={`/superadmin/dashboard/socialPages`}
          >
            <div className="navlink">
              <div>Social Pages</div>
            </div>
          </NavLink>

          <NavLink
            className={({ isActive }) =>
              isActive ? "superadmin_dashboard_sub_navlink" : ""
            }
            style={{ textDecoration: "none" }}
            to={`/superadmin/dashboard/apiKey`}
          >
            <div className="navlink">
              <div>API keys</div>
            </div>
          </NavLink>

          <NavLink
            className={({ isActive }) =>
              isActive ? "superadmin_dashboard_sub_navlink" : ""
            }
            style={{ textDecoration: "none" }}
            to={`/superadmin/dashboard/auctions`}
          >
            <div className="navlink">
              <div>Auctions</div>
            </div>
          </NavLink>
        </div>
      </div>

      <NavLink
        style={{ textDecoration: "none" }}
        to={`/superadmin/masterlist`}
        onClick={(e) => {
          setMasterList(!masterList);
          e.preventDefault();
        }}
      >
        <div className="navlink">
          <div className="fs-4 d-flex align-items-center">
            <PiListChecks />
          </div>
          <div className="mt-1">Master List</div>
          {masterList ? <MdOutlineKeyboardArrowUp className="fs-5" /> : <MdKeyboardArrowDown className="fs-5" />}

        </div>
      </NavLink>

      <div className={`${style.sub_navlinks}`}>
        <div
          className={`${style.toggle_of_sub_navlinks1} toggle_of_sub_navlinks1`}
          style={{ display: masterList ? "flex" : "none" }}
        >
          <NavLink
            className={({ isActive }) =>
              isActive ? "superadmin_dashboard_sub_navlink" : ""
            }
            style={{ textDecoration: "none" }}
            to={`/superadmin/masterlist/habbits`}
          >
            <div className="navlink">
              <div>Habits</div>
            </div>
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              isActive ? "superadmin_dashboard_sub_navlink" : ""
            }
            style={{ textDecoration: "none" }}
            to={`/superadmin/masterlist/lifeStyle`}
          >
            <div className="navlink">
              <div>Lifestyle</div>
            </div>
          </NavLink>
        </div>
      </div>

      <NavLink
        style={{ textDecoration: "none" }}
        to={`/superadmin/manageleads`}
      >
        <div className="navlink">
          <div className="fs-4 d-flex align-items-center">
            <PiPersonArmsSpreadThin />
          </div>
          <div className="mt-1">Manage Leads</div>
        </div>
      </NavLink>

      <NavLink
        style={{ textDecoration: "none" }}
        to={`/superadmin/manage_auction`}
      >
        <div className="navlink">
          <div className="fs-4 d-flex align-items-center">
            <RiAuctionLine />
          </div>
          <div className="mt-1">Manage Auction</div>
        </div>
      </NavLink>
      {/* <NavLink style={{ textDecoration: "none" }} to={`myprofile`}>
        <div className="navlink">
          <div className="fs-4">
            <i className="pi pi-user" style={{ fontSize: "1.2rem" }}></i>
          </div>
          <div>My Profile</div>
        </div>
      </NavLink> */}
      <NavLink style={{ textDecoration: "none" }} to={`/superadmin/reports`}>
        <div className="navlink">
          <div className="fs-4 d-flex align-items-center">
            <LuBarChart4 />
          </div>
          <div className="mt-1">Reports</div>
        </div>
      </NavLink>

      <NavLink style={{ textDecoration: "none" }} to={`/superadmin/latestnews`}>
        <div className="navlink">
          <div className="fs-4 d-flex align-items-center">
            <TbNews />
          </div>
          <div className="mt-1">News</div>
        </div>
      </NavLink>

      <hr></hr>

      <NavLink style={{ textDecoration: "none" }} to={`setting`}>
        <div className="navlink">
          <div
            className="fs-4 d-flex align-items-center"
            style={{ width: "20px" }}
          ></div>
          <div>Settings</div>
        </div>
      </NavLink>

      <div
        className="pointer"
        style={{ textDecoration: "none" }}
        to={``}
        onClick={() => {
          localStorage.clear();
          navigate("/superadmin");
        }}
      >
        <div className="navlink">
          <div
            className="fs-4 d-flex align-items-center"
            style={{ width: "20px" }}
          ></div>
          <div>Logout</div>
        </div>
      </div>
    </div>
  );
};
export default SuperAdmin;
