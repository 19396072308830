
import {React,useState} from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useNavigate } from "react-router-dom";
import EditAppoint from "../EditAppointmentDialog/EditAppoint";
import Loader from "../../../common/Loader";


export default function ScheduleCalender({
  view,
  selectedMonthYear,
  selectedDate,
  appointments,
  height,
  weekStart,
  weekEnd,
  onDateClick,
  reload,
  showTimesOnly = false,
}) {

  const [apoint, setApoint] = useState(false);
  const [appId,setAppId]=useState()
  const navigate = useNavigate();
  const localizer = momentLocalizer(moment);

  const eventStyleGetter = (event, start, end, isSelected) => {
    const style = {
      backgroundColor: "#fff",
      borderRadius: "5px",
      opacity: 0.8,
      color: "white",
      border: "none",
      display: "block",
      padding: "2px",
    };
    return { style };
  };

  const filteredEvents = appointments
    .filter((item) => {
      const eventDate = moment(item.date);
   
      if (view === "week") {
        return eventDate.isBetween(weekStart, weekEnd, null, "[]");
      } else if (view === "day") {
        return eventDate.isSame(selectedDate, "day");
      } else {
        return (
          eventDate.month() + 1 === selectedMonthYear.month &&
          eventDate.year() === selectedMonthYear.year
        );
      }
    })
    .map((item) => ({
      id: item._id,
      title: !showTimesOnly && item.appointment_title,
      start: moment(item.date)
        .set({
          hour: parseInt(item.start_time.split(":")[0], 10),
          minute: parseInt(item.start_time.split(":")[1], 10),
        })
        .toDate(),
      end: moment(item.date)
        .set({
          hour: parseInt(item.end_time.split(":")[0], 10),
          minute: parseInt(item.end_time.split(":")[1], 10),
        })
        .toDate(),
    }));

 
  const handleSelectEvent = (event) => {
    setAppId(event.id)
    setApoint(true);
  };

  const getDate = () => {
    if (view === "day") {
      return selectedDate;
    }
    return view === "week"
      ? weekStart
      : new Date(selectedMonthYear.year, selectedMonthYear.month - 1, 1);
  };

  const dayFormat = (date, culture, localizer) => {
    const day = localizer.format(date, "ddd"); // Day abbreviation (Sun, Mon, ...)
    const monthDate = localizer.format(date, "DD MMM"); // Date and month abbreviation (25 Jun)
    return (
      <div>
        <div>{monthDate}</div>
        <div>{day}</div>
      </div>
    );
  };

  const handleDrillDown = (date) => {
    onDateClick(date);
  };

  return (
    <div className="mt-4">
     
      <EditAppoint apoint={apoint} setApoint={setApoint} appointId={appId} reload={reload}/>
      <Calendar
        localizer={localizer}
        events={filteredEvents}
        startAccessor="start"
        endAccessor="end"
        style={{ height: height || 800 ,background:'#fff'}}
        eventStyleGetter={eventStyleGetter}
        onSelectEvent={handleSelectEvent}
        view={view}
        toolbar={false}
        date={getDate()}
        onDrillDown={handleDrillDown}
        formats={{
          dayHeaderFormat: (date, culture, localizer) =>
            localizer.format(date, "ddd DD MMM"),
        }}
        dayFormat={dayFormat}
      />
    </div>
  );
}



