// src/BarChart.js
import React, { useEffect, useState, useCallback } from "react";
import Chart from "react-apexcharts";
import style from "./style.module.css";
import { getAuctionPostData } from "../../../Api/admin";
import debounce from "lodash.debounce";
import Loader from "../../common/Loader";

const AuctionPostReport = () => {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [total,setTotal]=useState([])
  const [timeInfo, setTimeInfo] = useState(() => {
    const storedTimeInfo = JSON.parse(localStorage.getItem("timeInfoForAuctionPost"));
    if (storedTimeInfo) {
      return storedTimeInfo;
    } else {
      return {
        month: new Date().getMonth() + 1, // Current month (1-12)
        year: new Date().getFullYear(), // Current year
      };
    }
  });

  const fetchReportData = async (info) => {
    setLoader(true);
    try {
      const res = await getAuctionPostData(info);
      setData(res.data.data.data);
      setTotal(res.data.data.total_auction)
      console.log(res, "this is the response from the api");
    } catch (err) {
      console.log(err, "this is the error while getting surrogate matched data");
    } finally {
      setLoader(false);
    }
  };

  const debouncedFetchReportData = useCallback(
    debounce((info) => fetchReportData(info), 300),
    []
  );

  const handleTimeChange = (e) => {
    const { name, value } = e.target;
    const newTimeInfo = { ...timeInfo, [name]: value };
    setTimeInfo(newTimeInfo);
    localStorage.setItem("timeInfoForAuctionPost", JSON.stringify(newTimeInfo));
    debouncedFetchReportData(newTimeInfo);
  };

  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];



  useEffect(() => {
    fetchReportData(timeInfo);
  }, [timeInfo]);

  const howManyWeeks = (data) => {
    let dataLength = data.length;
    let weeks = [];
    for (let i = 0; i < dataLength; i++) {
      weeks.push(`week ${i + 1}`);
    }
    return weeks;
  };

  const xAxisData = (data) => {
    let dataLength = data.length;
    let weeks = [];
    for (let i = 0; i < dataLength; i++) {
      weeks.push(data[i].count);
    }
    return weeks;
  };

  const options = {
    chart: {
      type: "bar",
      background: "transparent",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        distributed: true,
      },
    },
    xaxis: {
      categories: howManyWeeks(data),
    },
    yaxis: {
      tickAmount: Math.max(...xAxisData(data)),
      labels: {
        formatter: (value) => Math.round(value),
      },
    },
    fill: {
      opacity: 0.6,
    },
    colors: [
      "rgba(255, 99, 132, 0.6)",
      "rgba(54, 162, 235, 0.6)",
      "rgba(255, 206, 86, 0.6)",
      "rgba(75, 192, 192, 0.6)",
      "rgba(96, 112, 89 , 0.6)",
    ],
    dataLabels: {
      enabled: false,
    },
  };

  const series = [
    {
      name: "Auction Posts",
      data: xAxisData(data),
    },
  ];

  return (

    <div>
      <Loader open={loader} />




      <div className={`d-flex flex-column align-items-start ${style.subheading_color}`}>

        <span className={`lufga_24px fw_600`}>Auction Post</span>

        <div className="d-flex justify-content-between align-items-center gap-2 w-100">
          <div><span className="theme_color">Total:</span> {total}</div>

          <div className="header my-2 d-flex align-items-center gap-3 justify-content-end">
            <label className="d-flex align-items-center gap-2">
              <span className="">Month:</span>
              <select
                name="month"
                value={timeInfo.month}
                onChange={handleTimeChange}
                className="form-select"
              >
                {Array.from({ length: 12 }, (_, i) => (
                  <option key={i + 1} value={i + 1}>
                    {months[i]}
                  </option>
                ))}
              </select>
            </label>
            <label className="d-flex align-items-center gap-2">
              <span className="">Year:</span>
              <input
                type="number"
                name="year"
                value={timeInfo.year}
                onChange={handleTimeChange}
                min="2000"
                max="2100"
                className="form-control"
              />
            </label>
          </div>
        </div>
      </div>



      <div className="table_container mt-3">
        {loader ? (
          <>

            <Chart options={options} series={series} type="bar" height={350} />
          </>
        ) : (
          <Chart options={options} series={series} type="bar" height={350} />
        )}
      </div>
    </div>
  );
};

export default AuctionPostReport;
