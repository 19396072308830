import React, { useState } from "react";
import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import style from './Setting.module.css'



export default function FaqMainTab() {
  const [cards, setCards] = useState([
    {
      title: "FAQ for Surrogate Mother",
      image: "surrogate.png",
      navlink: "surrogate_donor",
    },
    {
      title: "FAQ for Intended Parent",
      image: "itparent.png",
      navlink: "intended_parents",
    },
    {
        title: "FAQ for Lawyers",
        image: "lawyer.png",
        navlink: "lawyer",
      },
      {
        title: "FAQ for IVF Clinics",
        image: "clinic.png",
        navlink: "ivf_clinic",
      },
      {
        title: "FAQ for Embryo Donors",
        image: "clinic.png",
        navlink: "embryo_donor",
      },
      // {
      //   title: "FAQ for Adopting Parent",
      //   image:"",
      //   navlink: "adopting_parent"
      // }

  ]);
  let arr2 = [0, 1,2,3,4];

  const navigate = useNavigate()

  return (
    <div>
      <div className="mainContainer">
        <div className="mb-4">
          <div
            className="d-flex align-items-center gap-2 btn-Secondary p-0 pointer"
            onClick={() => navigate("/superadmin/setting")}
          >
            <FaArrowLeft /> Back
          </div>
        </div>
        <div className="CardStyle p-md-4 p-sm-3 p-2 mt-2">
        <div className="d-flex jutify-content-center flex-wrap">
        {arr2?.map((res, index) => (
              <div
                key={index}
                className="col-12 col-sm-4 p-lg-2 p-md-2 p-sm-2 p-2"
              >
                <Link to={cards[res].navlink} className={"navLink"}>
                  <div className={`${style.card}`}>
                    <img
                      className={`${style.card_img_top}`}
                      src={`/images/${cards[res].image}`}
                      alt="Card image cap"
                    />
                    <div className={`${style.card_body}`}>
                      <div className={`${style.lufga_18px}`}>{cards[res].title}</div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
        </div>
        </div>
      </div>
    </div>
  );
}
