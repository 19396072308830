import jsPDF from "jspdf";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import Loader from "../../common/Loader";
import { MdOutlineFileDownload } from "react-icons/md";
import { useTranslation } from "react-i18next";
import * as htmlToImage from "html-to-image";
import {

    getAdoptingParentFormData,
    getIntendedParentsFormData,

} from "../../../Api/IntendedParent";


import moment from "moment";
import { MultiSelect } from "primereact/multiselect";

const IntendedParentResume = ({ children }) => {





    const id = sessionStorage.getItem('userId')

    const role = sessionStorage.getItem('role')


    const races = [
        { name: "White", code: "W" },
        { name: "Black or African American", code: "B" },
        { name: "American Indian or Alaska Native", code: "AI" },
        { name: "Asian", code: "A" },
        { name: "Native Hawaiian", code: "NH" },
        { name: "Other", code: "O" },
    ];

    useEffect(() => {
        const idToGetFormData = id;
        if (idToGetFormData) {
            getFormdata(idToGetFormData);
        }
    }, []);

    async function getFormdata(id) {
        try {
            let res = await (role === "parents"
                ? getIntendedParentsFormData(id)
                : getAdoptingParentFormData(id));
            setFormData(res.data.data);
            setmemberId(res.data.data.memberId);
        } catch (error) {
            console.log(error);
        }
    }

    const navigate = useNavigate();
    const [Congratulation, setCongratulation] = useState(true);
    const [formData, setFormData] = useState({
        parent_photos: [
            {
                fileName: "",
                fileUrl: "",
            },
        ],
        photo_resume_index: 0,
        full_name: "",
        not_show_on_public_profile_full_name: false,
        date_of_birth: "",
        gender: "",
        race: [],
        spouse_name: "",
        not_show_on_public_profile_spouse_name: false,
        spouse_date_of_birth: "",
        spouse_gender: "",
        spouse_race: [],
        street_address: "",
        not_show_on_public_profile_street_address: false,
        city: "",
        state_of_residence: "",
        country: "",
        zip_code: "",
        date_of_legal_marriage: "",
        your_highest_degree_of_education: "",
        your_spouse_highest_degree_of_education: "",
        religion: "",
        your_occupation: "",
        spouse_occupation: "",
        total_income: "",
        briefly_describe_your_reason_for_wanting_to_adopt: "",
        tell_us_about_any_children_you_currently_have: "",
        kid_birth_status: "",
        tell_us_about_your_self: "",
        tell_us_about_your_spouse: "",
        tell_us_about_your_relationship: "",
        tell_us_about_your_extended_family: "",
        tell_us_about_adventure_you_do_in_your_free_time: "",
        tell_us_about_tradition: "",
        tell_us_about_home: "",
        tell_us_about_your_and_your_spouse_hobbies: "",
        your_favorites: {
            hobby: "",
            movie: "",
            sport: "",
            holiday: "",
            book: "",
            subject: "",
            tradition: "",
            destination: "",
            job: "",
            food: "",
            music_type: "",
            song: "",
            celebrity: "",
            moment: "",
            author: "",
        },
        your_spouse_favorites: {
            hobby: "",
            movie: "",
            sport: "",
            holiday: "",
            book: "",
            subject: "",
            tradition: "",
            destination: "",
            job: "",
            food: "",
            music_type: "",
            song: "",
            celebrity: "",
            moment: "",
            author: "",
        },
        child_preferences: {
            age_range: {
                min: "",
                max: "",
            },
            gender: "",
            special_need: "",
            race_of_child: "",
        },
    });

    let { t } = useTranslation();
    const [loader, setLoader] = useState(false);


    const [memberId, setmemberId] = useState("");

    const resumeRef1 = useRef(null);
    const resumeRef2 = useRef(null);
    const resumeRef3 = useRef(null)

    async function convertToImg() {
        setLoader(true);
        let arr = [resumeRef1.current, resumeRef2.current, resumeRef3.current];
        let photoArr = [];
        const pdf = new jsPDF();
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();

        console.log(pdfWidth,"this is pdfWidth")
        console.log(pdfHeight,"this is pdfHeight");

        for (let index = 0; index < arr.length; index++) {
            const res = arr[index];
            await htmlToImage
                .toPng(res, { quality: 0.5 }) // Reduced quality to 0.5
                .then(function (dataUrl) {
                    photoArr.push(dataUrl);
                    console.log(dataUrl, "this is dataUrl");
                    const imgProps = pdf.getImageProperties(dataUrl);
                    console.log(imgProps,"this is imgProps")
                    const imgWidth = pdfWidth;
                    const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;

                    // Scale image to fit within PDF dimensions
                    const scaleFactor = Math.min(
                        pdfWidth / imgProps.width,
                        pdfHeight / imgProps.height
                    );
                    console.log(scaleFactor,"this is scaleFactor");
                    const scaledWidth = imgProps.width * scaleFactor;
                    const scaledHeight = imgProps.height * scaleFactor;
                    console.log(scaledWidth,"this is scaledWidth")
                    console.log(scaledHeight,"this is scaledHeight")

                    pdf.addImage(
                        dataUrl,
                        "PNG",
                        0,
                        0,
                        scaledWidth,
                        scaledHeight,
                        undefined,
                        "FAST"
                    ); // Added compression option
                    if (index !== arr.length - 1) {
                        pdf.addPage();
                    }
                })
                .catch(function (error) {
                    console.error("oops, something went wrong!", error);
                })
                .finally(() => {
                    if (index === arr.length - 1) {
                        setLoader(false);
                    }
                });
        }

        pdf.save("Resume.pdf");
    }

    

    return (
        <>
            <Loader open={loader} />

            <div className='d-flex flex-column'>

                <div
                    className='d-flex justify-content-end'
                >
                    <div
                        onClick={() => { convertToImg() }}
                    >
                        {children}
                    </div>
                </div>

                <div className="height_for_scroll">
                    <div className="w-100 d-flex justify-content-end pt-4 pe-4 pb-2">
                        <button
                            className="btn btn_non_fill text-dark bg-light d-flex align-items-center fs-6  download_button_for_pdf"
                            onClick={convertToImg}
                        >
                            <MdOutlineFileDownload className="fs-5 me-2" /> Download Profile
                        </button>
                    </div>
                    <div
                        className="scroll_clild_wrapper"
                    // style={{width:"1300px"}}
                    >
                        <div className="scroll_child p-2">
                            <div
                                // className='p-2'
                                style={{ width: "1000px" }}
                                className="v_resume_scalling"
                            >
                                <div className="row m-5 align-center mx-0">
                                    <div
                                        className="col-12 mb-4 mt-4 maincontainer"
                                        ref={resumeRef1}
                                    >
                                        <div className="topBox" />
                                        <div className="leftBox" />
                                        <div className="flex gap-1">
                                            <div className="col-3">
                                                <img
                                                    crossOrigin="anonymous"
                                                    src={`${formData?.parent_photos?.[
                                                        formData?.photo_resume_index
                                                    ]?.fileUrl
                                                        }?${moment().format("mm:ss")}`}
                                                    style={{ width: "90%" }}
                                                    alt=""
                                                />
                                            </div>
                                            <div className="col-9">
                                                <div className="d-flex justify-content-between topRight">
                                                    <div className="">
                                                        <div>
                                                            <span className="topId"> {memberId}</span>
                                                        </div>
                                                        <div>
                                                            <span className="topText">
                                                                {role === "parents" ? (
                                                                    <>Intended Parent</>
                                                                ) : (
                                                                    <>Adopting Parent</>
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <img
                                                            src="/images/bigLogo.png"
                                                            alt=""
                                                            style={{ width: "140px" }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="d-flex justify-content-between mt-2">
                                                    <div className="topRightList">
                                                        <div>
                                                            <span className="listHeading">Date Of Birth: </span>
                                                            <span className="listText">
                                                                {" "}
                                                                {formData?.date_of_birth}{" "}
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <span className="listHeading">Gender: </span>
                                                            <span className="listText">
                                                                {formData?.gender}{" "}
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <span className="listHeading">Race: </span>
                                                            <span className="listText">
                                                                {" "}
                                                                {formData?.race?.map((r) => r.name).join(", ")}
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div className="topRightList">
                                                        <div>
                                                            <span className="listHeading">
                                                                Date of Birth (Spouse):{" "}
                                                            </span>
                                                            <span className="listText">
                                                                {" "}
                                                                {formData?.spouse_date_of_birth}
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <span className="listHeading">
                                                                Gender (Spouse):{" "}
                                                            </span>
                                                            <span className="listText">
                                                                {" "}
                                                                {formData?.spouse_gender}
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <span className="listHeading">Race (Spouse): </span>
                                                            <span className="listText">
                                                                {" "}
                                                                {formData?.spouse_race
                                                                    ?.map((r) => r.name)
                                                                    .join(", ")}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="topRightList">
                                                    <div>
                                                        <span className="listHeading">
                                                            Location(City, State & Country):{" "}
                                                        </span>
                                                        <span className="listText">
                                                            {" "}
                                                            {formData?.city ? `${formData.city}, ` : ""}
                                                            {formData?.state_of_residence
                                                                ? `${formData.state_of_residence}, `
                                                                : ""}
                                                            {formData?.country}
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span className="listHeading">
                                                            Date of Legal Marriage:{" "}
                                                        </span>
                                                        <span className="listText">
                                                            {" "}
                                                            {moment(formData?.date_of_legal_marriage).format(
                                                                "YYYY-MM-DD"
                                                            )}
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span className="listHeading">
                                                            Highest Degree of Education:{" "}
                                                        </span>
                                                        <span className="listText">
                                                            {" "}
                                                            {formData?.your_highest_degree_of_education}
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span className="listHeading">
                                                            Spouse's Highest Degree of Education:{" "}
                                                        </span>
                                                        <span className="listText">
                                                            {" "}
                                                            {formData?.your_spouse_highest_degree_of_education}
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span className="listHeading">Religion: </span>
                                                        <span className="listText">
                                                            {" "}
                                                            {formData?.religion}
                                                        </span>
                                                    </div>

                                                    <div>
                                                        <span className="listHeading">Your Occupation: </span>
                                                        <span className="listText">
                                                            {" "}
                                                            {formData?.your_occupation}
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span className="listHeading">
                                                            Spouse's Occupation:{" "}
                                                        </span>
                                                        <span className="listText">
                                                            {" "}
                                                            {formData?.spouse_occupation}
                                                        </span>
                                                    </div>

                                                    <div>
                                                        <span className="listHeading">Total Income($): </span>
                                                        <span className="listText">
                                                            {" "}
                                                            {formData?.total_income}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row mt-2 p-2 mx-0">
                                            <table class="table table-bordered border-dark">
                                                <tbody className="styleBody">
                                                    <tr>
                                                        <td className="styleLeft fw-bold">
                                                            Briefly describe your reasons for wanting to adopt
                                                        </td>
                                                        <td valign="middle" className="styleRight">
                                                            {" "}
                                                            {
                                                                formData?.briefly_describe_your_reason_for_wanting_to_adopt
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="fw-bold">
                                                            Tell us about any children you currently have,
                                                            including their age(s)
                                                        </td>
                                                        <td valign="middle" className="styleRight">
                                                            {" "}
                                                            {
                                                                formData?.tell_us_about_any_children_you_currently_have
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="fw-bold">Kids Birth Status</td>
                                                        <td valign="middle" className="styleRight">
                                                            {formData?.kid_birth_status}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="fw-bold">
                                                            Tell us about your yourself
                                                        </td>
                                                        <td valign="middle" className="styleRight">
                                                            {formData?.tell_us_about_your_self}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="fw-bold">
                                                            Tell us about your your spouse
                                                        </td>
                                                        <td valign="middle" className="styleRight">
                                                            {formData?.tell_us_about_your_spouse}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="styleLeft fw-bold">
                                                            Tell us about your relationship
                                                        </td>
                                                        <td valign="middle" className="styleRight">
                                                            {formData?.tell_us_about_your_relationship}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="fw-bold">
                                                            Tell us about your extended family
                                                        </td>
                                                        <td valign="middle" className="styleRight">
                                                            {formData?.tell_us_about_your_extended_family}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="fw-bold">
                                                            Tell us about adventures you do in your free time
                                                        </td>
                                                        <td valign="middle" className="styleRight">
                                                            {
                                                                formData?.tell_us_about_adventure_you_do_in_your_free_time
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="fw-bold">
                                                            Tell us about tradition, holidays & celebrations
                                                        </td>
                                                        <td valign="middle" className="styleRight">
                                                            {formData?.tell_us_about_tradition}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="fw-bold">Tell us about your home</td>
                                                        <td valign="middle" className="styleRight">
                                                            {formData?.tell_us_about_home}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="fw-bold">
                                                            Tell us about your and your spouse hobbies &
                                                            Lifestyle
                                                        </td>
                                                        <td valign="middle" className="styleRight">
                                                            {
                                                                formData?.tell_us_about_your_and_your_spouse_hobbies
                                                            }
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <div className="rightBox" />
                                        <div className="bottomBox" />
                                    </div>

                                    <div
                                        className="col-12 mb-4 mt-4 maincontainer"
                                        ref={resumeRef2}
                                    >
                                        <div className="topBox" />
                                        <div className="leftBox" />
                                        <div className="d-flex justify-content-end mb-4">
                                            <img
                                                src="/images/bigLogo.png"
                                                alt=""
                                                style={{ width: "140px" }}
                                            />
                                        </div>
                                        <div class="row mt-2 p-2 mx-0">
                                            <table class="table table-bordered border-dark">
                                                <thead>
                                                    <tr style={{ fontWeight: "600", fontFamily: "Lufga" }}>
                                                        <td style={{ width: "40%" }}>Favorites</td>
                                                        <td style={{ width: "30%" }}>Your Favorites</td>
                                                        <td>Your Spouse's Favorites</td>
                                                    </tr>
                                                </thead>
                                                <tbody className="styleBody">
                                                    <tr>
                                                        <td className="fw-bold">Hobby</td>
                                                        <td valign="middle" className="styleRight">
                                                            {formData?.your_favorites?.hobby}
                                                        </td>
                                                        <td valign="middle" className="styleRight">
                                                            {formData?.your_spouse_favorites?.hobby}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="fw-bold">Movie</td>
                                                        <td valign="middle" className="styleRight">
                                                            {" "}
                                                            {formData?.your_favorites?.movie}
                                                        </td>
                                                        <td valign="middle" className="styleRight">
                                                            {formData?.your_spouse_favorites?.movie}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="fw-bold">Sport</td>
                                                        <td valign="middle" className="styleRight">
                                                            {formData?.your_favorites?.sport}
                                                        </td>
                                                        <td valign="middle" className="styleRight">
                                                            {formData?.your_spouse_favorites?.sport}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="fw-bold">Holiday</td>
                                                        <td valign="middle" className="styleRight">
                                                            {" "}
                                                            {formData?.your_favorites?.holiday}
                                                        </td>
                                                        <td valign="middle" className="styleRight">
                                                            {" "}
                                                            {formData?.your_spouse_favorites?.holiday}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="fw-bold">Book</td>
                                                        <td valign="middle" className="styleRight">
                                                            {" "}
                                                            {formData?.your_favorites?.book}
                                                        </td>
                                                        <td valign="middle" className="styleRight">
                                                            {" "}
                                                            {formData?.your_spouse_favorites?.book}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="fw-bold">Subject</td>
                                                        <td valign="middle" className="styleRight">
                                                            {" "}
                                                            {formData?.your_favorites?.subject}
                                                        </td>
                                                        <td valign="middle" className="styleRight">
                                                            {" "}
                                                            {formData?.your_spouse_favorites?.subject}{" "}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="fw-bold">Tradition</td>
                                                        <td valign="middle" className="styleRight">
                                                            {" "}
                                                            {formData?.your_spouse_favorites?.tradition}{" "}
                                                        </td>
                                                        <td valign="middle" className="styleRight">
                                                            {" "}
                                                            {formData?.your_spouse_favorites?.tradition}{" "}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="fw-bold">Destination</td>
                                                        <td valign="middle" className="styleRight">
                                                            {" "}
                                                            {formData?.your_favorites?.destination}
                                                        </td>
                                                        <td valign="middle" className="styleRight">
                                                            {" "}
                                                            {formData?.your_spouse_favorites?.destination}{" "}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="fw-bold">Job</td>
                                                        <td valign="middle" className="styleRight">
                                                            {" "}
                                                            {formData?.your_favorites?.job}{" "}
                                                        </td>
                                                        <td valign="middle" className="styleRight">
                                                            {formData?.your_spouse_favorites?.job}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="fw-bold">Food</td>
                                                        <td valign="middle" className="styleRight">
                                                            {" "}
                                                            {formData?.your_favorites?.food}{" "}
                                                        </td>
                                                        <td valign="middle" className="styleRight">
                                                            {" "}
                                                            {formData?.your_spouse_favorites?.food}{" "}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="fw-bold">Music</td>
                                                        <td valign="middle" className="styleRight">
                                                            {" "}
                                                            {formData?.your_favorites?.music_type}{" "}
                                                        </td>
                                                        <td valign="middle" className="styleRight">
                                                            {" "}
                                                            {formData?.your_spouse_favorites?.music_type}{" "}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="fw-bold">Song</td>
                                                        <td valign="middle" className="styleRight">
                                                            {" "}
                                                            {formData?.your_favorites?.song}
                                                        </td>
                                                        <td valign="middle" className="styleRight">
                                                            {" "}
                                                            {formData?.your_spouse_favorites?.song}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="fw-bold">Celebrity</td>
                                                        <td valign="middle" className="styleRight">
                                                            {" "}
                                                            {formData?.your_favorites?.celebrity}{" "}
                                                        </td>
                                                        <td valign="middle" className="styleRight">
                                                            {formData?.your_spouse_favorites?.celebrity}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="fw-bold">Moment</td>
                                                        <td valign="middle" className="styleRight">
                                                            {" "}
                                                            {formData?.your_favorites?.moment}{" "}
                                                        </td>
                                                        <td valign="middle" className="styleRight">
                                                            {formData?.your_spouse_favorites?.moment}{" "}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="fw-bold">Author</td>
                                                        <td valign="middle" className="styleRight">
                                                            {" "}
                                                            {formData?.your_favorites?.author}
                                                        </td>
                                                        <td valign="middle" className="styleRight">
                                                            {formData?.your_spouse_favorites?.author}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <div className="mt-4">
                                            <label className="containerHeading fs-5">
                                                Child Preferences
                                            </label>
                                        </div>

                                        <div className="row border rounded-5 my-3 p-3 d-flex flex-column gap-3 mx-0">
                                            <table className="tableStyle">
                                                <thead className="styleBody">
                                                    <tr>
                                                        <td style={{ width: "50%" }} cope="col">
                                                            Age Range
                                                        </td>
                                                        <td style={{ width: "50%" }} scope="col">
                                                            Gender
                                                        </td>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    <tr>
                                                        <td className="d-flex gap-3 mt-2">
                                                            <input
                                                                type="text"
                                                                style={{ width: "45%", borderRadius: "0.3rem" }}
                                                                className="form-control"
                                                                disabled
                                                                value={
                                                                    formData?.child_preferences?.age_range?.min
                                                                }
                                                            />
                                                            <input
                                                                type="text"
                                                                style={{ width: "45%", borderRadius: "0.3rem" }}
                                                                className="form-control"
                                                                disabled
                                                                value={
                                                                    formData?.child_preferences?.age_range?.max
                                                                }
                                                            />
                                                        </td>
                                                        <td className="">
                                                            <input
                                                                type="text"
                                                                style={{ width: "100%", borderRadius: "0.3rem" }}
                                                                className=" mt-2 form-control"
                                                                disabled
                                                                value={
                                                                    formData?.child_preferences?.gender
                                                                }
                                                            />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <table className="tableStyle">
                                                <thead className="styleBody">
                                                    <tr>
                                                        <td style={{ width: "100%" }} scope="col">
                                                            Special Needs
                                                        </td>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    <tr>
                                                        <td className="mt-2">
                                                            <input
                                                                disabled
                                                                type="text"
                                                                style={{ width: "100%", borderRadius: "0.3rem" }}
                                                                className="form-control mt-2 "
                                                                value={formData?.child_preferences?.special_need}
                                                            />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <table className="tableStyle">
                                                <thead className="styleBody">
                                                    <tr>
                                                        <td style={{ width: "100%" }} scope="col">
                                                            Race of child
                                                        </td>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    <tr>
                                                        <td className="mt-2">
                                                            <MultiSelect
                                                                style={{
                                                                    width: "100%",
                                                                    borderRadius: "0.3rem",
                                                                    background: "#e9ecef",
                                                                    opacity: "1",
                                                                    border: "unset !important",
                                                                }}
                                                                value={formData?.child_preferences?.race_of_child}
                                                                disabled={true}
                                                                display="chip"
                                                                showSelectAll={false}
                                                                closeIcon={false}
                                                                placeholder="Select Race"
                                                                maxSelectedLabels={5}
                                                                className="multiSelectDropDown  ps-2"
                                                                name="race"
                                                                options={races}
                                                                optionLabel="name"
                                                            />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <div className="rightBox" />
                                        <div className="bottomBox" />
                                    </div>

                                    <div
                                        className="col-12 mb-4 mt-4 maincontainer"
                                        ref={resumeRef3}
                                    >
                                        <div className="topBox" />
                                        <div className="leftBox" />
                                        <div className="d-flex justify-content-end mb-4">
                                            <img
                                                src="/images/bigLogo.png"
                                                alt=""
                                                style={{ width: "140px" }}
                                            />
                                        </div>
                                        <div className="row">
                                            <div className="d-flex flex-wrap p-2">
                                                {formData?.parent_photos?.map((item, index) => (
                                                    <div className="m-3 imgWidth">
                                                        <img
                                                            crossOrigin="anonymous"
                                                            src={`${item?.fileUrl}?${moment().format("mm:ss")}`}
                                                            style={{ width: "100%", height: "100%" }}
                                                            alt=""
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="rightBox" />
                                        <div className="bottomBox" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-center flex-md-row flex-column gap-md-5 gap-3 m-3 mx-0">
                        <button
                            className="btn_non_fill mw_400px text-dark bg-light d-flex align-items-center w-100 w-md-25 download_button_for_pdf"
                            onClick={() =>
                                navigate(
                                    role === "parents"
                                        ? `/intendedparents/form/${id}`
                                        : `/adoptingparent/form/${id}`
                                )
                            }
                        >
                            <span className="mx-auto">Make Changes</span>
                        </button>
                        <button
                            className="btn_fill mw_400px w-100 w-md-25"
                        // onClick={navigateTodashboard}
                        >
                            Go to Dashboard
                        </button>
                    </div>
                </div>
            </div >
        </>
    );
};

export default IntendedParentResume;
