import React, { useState, useEffect } from "react";
import style from "./LawyerDashboard.module.css";
import { useNavigate } from 'react-router-dom';
import ScheduleCalender from "../CalenderComponent/Calender/LawyerScheduleCalender";
import moment from "moment";
import ScanCards from "../../SurrogateComponents/Dashboard/ScanCards/ScanCards";
import NewClients from "./NewClients/LawyerNewClients";
import LatestNews from "../../SurrogateComponents/Dashboard/LatestNews/LatestNews";
import { getAppointments, getClientsOflawyer } from "../../../Api/Lawyer";
import Loader from "../../common/Loader";
import { getDashboardUser } from "../../../Api/CommonApis";
import { useDispatch } from "react-redux";
import { changeHeaderData } from "../../../Store/Slices/headerDataSlice";
var memberId;

const LawyerDashboard = () => {
  const navigate = useNavigate();
  const [appointments, setAppointments] = useState([]);
  const [data, setData] = useState([]);
  const [userData, setUserData] = useState({})
  const [loader, setLoader] = useState(false);
  const [todayAppointments, setTodayAppointments] = useState(0);
  const [appointmentChange, setAppointmentChange] = useState("");
  const [graphValue, setGraphValue] = useState(null);

  let userId = sessionStorage.getItem("userId");
  const dispatch = useDispatch();


  
  async function getUserData() {
    try {
      console.log(userId);
      let res = await getDashboardUser(userId);
      console.log("data on dashboard",res.data.data)
      setUserData(res.data.data)
      memberId = res.data.data.memberId;
      let lawyerid=res.data.data._id;
      sessionStorage.setItem("LawyerId",memberId)

      dispatch(
        changeHeaderData({
          lawyerName: res?.data?.data?.fullName,
          lawyerPhoto: res?.data?.data?.imageUrl,
          LawyerId: memberId,
          LawyerProfileId:lawyerid
        })
      );


      setLoader(false);
    } catch (err) {
      console.log(err);
      setLoader(false);
    }
  }

  

  const fetchAppointments = async () => {
    console.log(memberId,"pppppppppppppppp")
    try {
      let res = await getAppointments({ "lawyerId": memberId });
      let allAppointments = res.data.data;
      setAppointments(allAppointments);

      const today = moment().startOf('day');
      const yesterday = moment().subtract(1, 'day').startOf('day');

      const todayCount = allAppointments.filter(app => moment(app.date).isSame(today, 'day')).length;
      const yesterdayCount = allAppointments.filter(app => moment(app.date).isSame(yesterday, 'day')).length;

      setTodayAppointments(todayCount);

      if (yesterdayCount > 0) {
        const percentageChange = ((todayCount - yesterdayCount) / yesterdayCount) * 100;
        setAppointmentChange(`${percentageChange.toFixed(1)}% ${percentageChange > 0 ? 'Up' : 'Down'} from yesterday`);

        if (percentageChange > 0) {
          setGraphValue(true);
        } else if (percentageChange < 0) {
          setGraphValue(false);
        } else {
          setGraphValue(null);
        }
      } else {
        setAppointmentChange("No data for yesterday");
        setGraphValue(null);
      }

    } catch (error) {
      console.log(error);
    }
  };



  const getClientsFunction = async () => {
    try {
      setLoader(true);
      const response = await getClientsOflawyer({ "lawyerId": memberId });
      console.log(response, "clients response");
      setData(response.data.data);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    const ok=async()=>{
      await getUserData();
      fetchAppointments();
      getClientsFunction();
    }
    ok()
  }, []);

  const currentWeekStart = moment().startOf('week').toDate();
  const currentWeekEnd = moment().endOf('week').toDate();

  return (
    <div>
      <Loader open={loader} />
      <div className={`${style.main_container} my-3`}>
        <div className={`${style.surrogate_name} lufga_26px fw_600`}>
          Hi {userData.fullName},
        </div>
        <div className={`${style.upper_card_section}`}>
          <ScanCards cardObject={[
            {
              title: "Appointments Today",
              main_content: todayAppointments,
              graph: graphValue,
              icon: "clinic_dashboard_appointment_card_photo.png",
              percent: appointmentChange,
            },
            {
              title: "Your membership id",
              main_content: memberId,
              icon: "",
              information_type: "Recommend to Others",
            },
          ]} />
        </div>
      </div>

      <div className="mb-3">
        <NewClients data={data} />
      </div>

      <div className="mb-3">
        <div className="d-flex justify-content-between align-items-center">
          <div className="lufga_26px fw_600">Upcoming Appointments</div>
          <div className='lufga_18px fw_400 theme_color' style={{ cursor: "pointer" }} onClick={() => navigate("/lawyer/mycalender")}>View all Appointments</div>
        </div>
        <ScheduleCalender
          view="week"
          selectedMonthYear={{ month: moment().month() + 1, year: moment().year() }}
          appointments={appointments}
          reload={fetchAppointments}
          height={"350px"}
          background={"white"}
          weekStart={currentWeekStart}
          weekEnd={currentWeekEnd}
          showTimesOnly={true}
        />
      </div>

      <div className="mb-3">
        <LatestNews outsidecall={true} />
      </div>
    </div>
  );
};

export default LawyerDashboard;
