import React from "react";
import style from "./Platform.module.css";
import { Dialog } from "primereact/dialog";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { addSocialPage } from "../../../../../Api/admin";
import { uploadDocument } from "../../../../../Api/CommonApis";
import Loader from "../../../../common/Loader";

const AddSocialMedia = ({ resumeone, setResumeone, refresh, setRefresh }) => {
  let { t } = useTranslation();

  const [selectedOption, setSelectedOption] = useState("");
  const [platformLink, setPlatformLink] = useState("");
  const [img, setImg] = useState(null)
  const [defaultImage, setDefaultImage] = useState(null)
  // const [imageName, setImageName] = useState("");
  // const [imageUrl, setImageUrl] = useState("");
  // const [imageSrc, setImageSrc] = useState(null);
  const [loader, setLoader] = useState(false)

  // function handleImageUpload(){
  //   console.log('sssssssssss')
  //   alert('jadooooo')
  // }
  const handleDropdownChange = (event) => {
    setSelectedOption(event.target.value);
  };

  // console.log(img);
    const handleSubmit = async() => {
      setLoader(true)
      console.log(img)
      let formdata= new FormData()
      if(img !== null){
        formdata.append('uploadDoc', img)
      }
      let imageData;
      console.log(formdata, 'jadoooo')
      try {
        if (img !== null) {
            imageData = await uploadDocument(formdata)
            console.log("imageData",imageData)
           // setImageName(imageData?.data?.fileName)
           // setImageUrl(imageData?.data?.fileLocation)
        }
      } catch (err) {
          console.log("error",err)
      }

      const body = {
        "platform": selectedOption,
        "platformLink": platformLink,
        "platform_logo_name": imageData?.data?.fileName,
        "platform_logo_url": imageData?.data?.fileLocation,
      }
      console.log(body)
      const result = await addSocialPage(body)
      console.log(result)
      setRefresh(!refresh)
      setResumeone(false)
      setLoader(false)
      setDefaultImage(null)
      
      setSelectedOption("")
      setPlatformLink("")
      setImg(null)
  };

  return (
    <div className={style.AuctionForm}>
      <Loader open={loader} />
      <Dialog
        closable={false}
        visible={resumeone}
        className="p-0 m-0"
        onHide={() => setResumeone(false)}
        style={{ width: "50vw", padding: "0px", margin: "0px" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <div>
          <div className={`d-flex mb-3 justify-content-center`}>
            <div className={`lufga_32px fw_600`}>Add Platform</div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <label
                className={`form-check-label mb-1 ${style.LabelText} fw_600`}
                htmlFor="flexCheckDefault"
              >
                Select Platform
              </label>
              <div>
                {/* <label htmlFor="dropdown"></label> */}
                <select
                  className={`${style.input_text}
                `}
                  id="dropdown"
                  value={selectedOption}
                  onChange={handleDropdownChange}
                >
                  <option value="">Select Platform</option>
                  <option value="Facebook">Facebook</option>
                  <option value="Instagram">Instagram</option>
                  <option value="Tiktok">Tiktok</option>
                  <option value="Twitter">Twitter</option>
                  <option value="LinkedIn">LinkedIn</option>
                  <option value="Pinterest">Pinterest</option>
                  <option value="Reddit">Reddit</option>
                  <option value="Discord">Discord</option>
                  <option value="Youtube">Youtube</option>
                  <option value="Bilibili">Bilibili</option>
                  <option value="Telegram">Telegram</option>
                </select>
              </div>
              {/* <input
                type="email"
                className="form-control"
                placeholder="Lorem Ipsum"
              /> */}
            </div>


            <div className={`form-check-label mb-1 ${style.LabelText} fw_600`}>Logo</div>
            <div className={` ${style.Images_Border}`}>
              <label htmlFor="fileInput" className={style.center_content}>
                <i
                  className={`bi bi-card-image  mb-3 ${style.imagesCustom}`}
                ></i>
                <div>
                  <div className={style.UploadName}>
                    Upload or Drop your image
                  </div>
                </div>
              </label>
              <input
                type="file"
                id="fileInput"
                required
                onChange={(e)=>{setImg(e.target.files[0])
                    setDefaultImage(URL.createObjectURL(e.target.files[0]))
                  }
                }                  
                accept="image/*"
                style={{ display: "none" }}
              />
            
              {/* {imageSrc && ( */}
              <div   className="  d-flex align-items-center justify-content-center">  
                <img
                  src={defaultImage}
                  alt=""
                  style={{ width: "20%", height: "20%" }}
                />
                    </div>
              {/* )} */}
            </div>

            <div className="col-md-12 mt-3">
              <label
                className={`form-check-label mb-1 fw_600 ${style.LabelText}`}
                htmlFor="flexCheckDefault"
              >
                Platform Link
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Platform Link"
                onChange={(e)=>setPlatformLink(e.target.value)}
              />
            </div>

            <div className="d-flex mt-4 justify-content-end gap-4">
              <button
                className={style.cancelBtn}
                onClick={() =>{ setResumeone(false)
                                setDefaultImage(null)
                                setSelectedOption("")
                                setPlatformLink("")
                                setImg(null)}
                }
              >
                Cancel
              </button>

              <button className={style.SaveButton} onClick={handleSubmit}>Submit</button>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default AddSocialMedia;
