import React, { useState } from "react";
import style from "./Resumes.module.css";
import { useTranslation } from "react-i18next";
import VerifyResume from "./VerifyResume/VerifyResume";
import { useNavigate } from "react-router";
import {
  getResumaData,
  getSurrogateMotherData,
  updateResumeData,
  verifySurrogateResume,
} from "../../../Api/SurrogateMotherApis";
import { useEffect } from "react";
import { CiImageOn } from "react-icons/ci";
import Loader from "../../common/Loader";
import { Country, State, City } from "country-state-city";
import moment from "moment";
import {
  getDashboardUser2,
  uploadDocument,
} from "../../../Api/CommonApis";
import ResumePhoto from "./ResumePhoto";
import { MultiSelect } from "primereact/multiselect";
import { useRef } from "react";
import jsPDF from 'jspdf';
import * as htmlToImage from 'html-to-image';
import "./style.css";
import { SURROGATE_MOTHER_RACE } from "../../../constants";
import { toast } from "../../../utils/common";
import { BasicProfile } from "./BasicProfile";
import { MdOutlineFileDownload } from "react-icons/md";
import { changeHeaderData } from "../../../Store/Slices/headerDataSlice";
import { useDispatch } from "react-redux";
import { GoVerified } from "react-icons/go";
import { gethabitsdata, getlifestyledata } from "../../../Api/admin";
import { AiFillDatabase } from "react-icons/ai";

const Resume = () => {

  const [visibale, setVisibale] = useState(false);
  const resumeRef1 = useRef(null);
  const resumeRef2 = useRef(null);
  let { t } = useTranslation();
  const dispatch = useDispatch()
  let [activeTab, setActiveTab] = useState(0);
  const navigate = useNavigate();
  const [editable, setEditable] = useState(true);
  const [all, setAll] = useState({});
  const countries = Country.getAllCountries();
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [loader, setLoader] = useState(true);
  const [city, setCity] = useState("");
  const [statesListing, setStatesListing] = useState([]);
  const [citiesListing, setCitiesListing] = useState([]);
  const [img, setImg] = useState(null);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [defaultImg, setDefaultimg] = useState("/images/blankProfile.png");
  const [upload, setUpload] = useState(false);
  const [users, setUsers] = useState(null);
  const userRef = useRef(null);
  const [selectedLifeStyle, setSelectedLifeStyle] = useState([]);
  const [selectedHabits, setSelectedHabits] = useState([]);
  const [activeEditPage, setActiveEditPage] = useState(0);
  const [allData, setAlldata] = useState(null)
  const cities = [
    { name: "Fashion $ Style" },
    { name: "Outdoor activities" },
    { name: "Music" },
    { name: "Gaming" },
    { name: "Work" },
  ];
  const cities2 = [
    { name: "Eat healthy" },
    { name: "Drink more water" },
    { name: "Be grateful" },
    { name: "Follow daily routine" },
    { name: "Learning" },
  ];
  let userData = JSON.parse(sessionStorage.getItem("userData"));

  let styleSheet = {
    maincontainer: {
      width: '100%',
      height: 'auto',
      position: 'relative',
      padding: '25px',
      background: 'linear-gradient(251.07deg, #FFFFFF 35.06%, #E8F9FA 95.96%)'
      // margin:'0 auto'
    },
    maincontainer2: {
      width: '100%',
      height: 'auto',
      position: 'relative',
      padding: '35px',
      background: 'linear-gradient(251.07deg, #FFFFFF 35.06%, #E8F9FA 95.96%)',
      minHeight: '1500px'
      // margin:'0 auto'
    },
    topContainer: {
      width: '100%',
      display: 'flex',
      // border: '1px solid red',
    },
    topLeft: {
      width: '40%',

    },
    topRight: {
      width: '60%',
      padding: '20px',
    },
    topRightInnerTop: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    codeHeading: {
      fontWeight: '600',
      fontSize: '24px',
      color: 'black',
      fontFamily: 'Lufga'
    },
    codeSub: {
      fontWeight: '600',
      fontSize: '14px',
      color: 'rgba(24, 124, 139, 1)',
      fontFamily: 'Lufga'
    },
    heading: {
      marginTop: '30px',
      fontWeight: '600',
      fontSize: '18px',
      color: 'rgba(24, 124, 139, 1)',
      fontFamily: 'Lufga',
      borderBottom: '2px solid rgba(24, 124, 139, 1)',
      width: '220px',
      paddingBottom: '3px'
    },
    heading2: {
      marginTop: '30px',
      fontWeight: '600',
      fontSize: '18px',
      color: 'rgba(24, 124, 139, 1)',
      fontFamily: 'Lufga',
      borderBottom: '2px solid rgba(24, 124, 139, 1)',
      width: '310px',
      paddingBottom: '3px'
    },
    listContent: {
      marginTop: '25px',
      display: 'flex',
      flexDirection: 'column',
      gap: '5px'
    },
    listHeading: {
      fontWeight: '600',
      fontSize: '14px',
      fontFamily: 'Lufga',
    },
    listText: {
      fontWeight: '400',
      fontSize: '14px',
      fontFamily: 'Lufga',
    },
    childContainer: {
      display: 'grid',
      width: '100%',
      gridTemplateColumns: '33.33% 33.33% 33.33%'
    },
    childHeading: {
      fontSize: '16px',
      fontWeight: '600',
      color: 'rgba(24, 124, 139, 1)',
      fontFamily: 'Lufga',
    },
    topBox: {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100px',
      height: "17px",
      background: 'rgba(24, 124, 139, 1)'
    },
    leftBox: {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '17px',
      height: "350px",
      background: 'rgba(24, 124, 139, 1)'
    },
    rightBox: {
      position: 'absolute',
      bottom: '0',
      right: '0',
      width: '20px',
      height: "100px",
      background: 'rgba(24, 124, 139, 1)'
    },
    bottomBox: {
      position: 'absolute',
      bottom: '0',
      right: '0',
      width: '350px',
      height: "20px",
      background: 'rgba(24, 124, 139, 1)'
    },


  }

  const [lifestyle, setLifestyle] = useState([]);
  const [habits, setHabits] = useState([]);
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    middleName: "",
    userId: "",
    service_offer: "",
    short_description: "",
    age: "",
    dob: "",
    height: {
      feet: "",
      inches: "",
    },
    weight: {
      LBs: "",
      oz: "",
    },
    current_address: "",
    country: "",
    state: "",
    city: "",
    zipCode: "",
    life_style: [],
    habits: [],
    race: [""],
    medical_comarraypatibility: [""],
    child_details: [],
    photos: [],
    memberId: "",
    skin_color: "",
    relationship_status: "",
  });

  function radioButtonChange(e, val) {
    let { name } = e.target;
    setData(pre => {
      pre[name] = val;
      return ({ ...pre })
    })
  };

  async function getUserdataById() {
    try {
      let res = await getDashboardUser2(userData.userId);
      userRef.current = res.data.data;
      setUsers(res.data.data);
      // console.log(res.data.data);
    } catch (err) {
      console.log(err);
    }
  }

  async function getAlldata() {
    try {
      let res = await getSurrogateMotherData(userData.userId);
      setLoader(false)
      setAll(res.data.data);
      setAlldata(res.data.data);
      console.log(res.data.data, "pppppppppppppppppppppppppppp")

      await gethobiesandlifestyle(res.data.data.habits, res.data.data.life_style)




    } catch (err) {
      setLoader(false)
      console.log(err);
    }
  }

  async function gethobiesandlifestyle(habits, lifestyle) {
    await getLifestyle(lifestyle);
    await getHabits(habits);
  }



  const getLifestyle = async (lifestyle) => {
    var result = await getlifestyledata();

    setSelectedLifeStyle(result?.data?.data?.filter(dd => lifestyle?.includes(dd._id)))
    // console.log(result?.data?.data?.filter(dd=>lifestyle?.includes(dd._id)))

    setLifestyle(result.data.data);

  };

  const getHabits = async (habits) => {
    var result = await gethabitsdata();

    setSelectedHabits(result?.data?.data?.filter(dd => habits?.includes(dd._id)))
    // console.log(result?.data?.data?.filter(dd=>habits?.includes(dd._id)))

    setHabits(result.data.data);
  };

  // useEffect(() => {
  //   gethobiesandlifestyle()
  // }, [])






  async function getUserData() {
    try {
      let res = await getResumaData(userData.userId);
      let resultData = res.data.data;
      // console.log(resultData)
      dispatch(changeHeaderData({ firstName: resultData?.firstName, lastName: resultData?.lastName, photos: resultData?.photos, activeIndex: resultData?.photo_resume_index }))
      setPhotoIndex(
        resultData.photo_resume_index !== undefined
          ? resultData?.photo_resume_index
          : resultData?.photos?.length - 1
      );
      setData((pre) => {
        return { ...pre, ...res.data.data };
      });

      setState(resultData.state);
      setCity(resultData.city);

      if (
        resultData.life_style !== undefined &&
        resultData.life_style.length !== 0
      ) {
        let arr = resultData?.life_style?.map((res) => {
          return { name: res };
        });
        setSelectedLifeStyle(arr);
      }
      if (resultData.habits !== undefined && resultData.habits.length !== 0) {
        let arr = resultData?.habits?.map((res) => {
          return { name: res };
        });
        setSelectedHabits(arr);
      }

      if (res.data.data.country !== undefined) {
        let cRes = countries?.find(
          (res2) => res2?.name == res.data.data.country
        );

        setCountry(`${cRes?.name}&&${cRes?.isoCode}`);
        getStates(`${cRes?.name}&&${cRes?.isoCode}`);
      }
      if (res.data.data.city !== undefined) {
        setCity(res.data.data.city);
      }

    } catch (err) {
      console.log(err);
      // setLoader(false);
    } finally {
      await getAlldata()
      setLoader(false);
    }
  }
  useEffect(() => {
    if (statesListing.length !== 0) {
      let sRes = statesListing?.find((res2) => res2?.name == data.state);
      if (sRes !== undefined) {
        setState(`${sRes.name}&&${sRes.isoCode}&&${sRes.countryCode}`);
        getCities(`${sRes.name}&&${sRes.isoCode}&&${sRes.countryCode}`);
      }
    }
  }, [statesListing]);

  function getImgPreview(file) {
    // let url = URL.createObjectURL(file);
    // return url;
  }

  async function editProfileData(e) {
    e.preventDefault();

    let lifiStyle = selectedLifeStyle?.map((res) => res._id);
    let habits = selectedHabits?.map((res) => res._id);

    if (!lifiStyle.some(e => e) || !habits.some(d => d)) {
      toast.error("Please Fill All Fields");
      return
    }

    let payload = {
      ...data,
      city: city.split("&&")[0],
      state: state.split("&&")[0],
      country: country.split("&&")[0],
      life_style: lifiStyle,
      habits: habits,
    };
    let formdata = new FormData();
    if (img !== null) {
      formdata.append("uploadDoc", img);
    }

    setLoader(true);
    try {
      if (img !== null) {
        let imageData = await uploadDocument(formdata);
        payload.imageFileName = imageData.data.fileName;
        payload.imageUrl = imageData.data.fileLocation;
      }
      let res = await updateResumeData(userData.userId, payload);
      // return
      setImg(null);
      await getUserData();
      setEditable(true);
      setLoader(false);
    } catch (err) {
      setLoader(false);
      console.log(err);
    }
  }

  function getStates(res) {
    let isoCode = res.split("&&")[1];
    setCountry(res);
    let states = State.getStatesOfCountry(isoCode);
    setStatesListing(states);
    // if (city) {
    //   let cRes = states?.find((res2) => res2?.name == city);
    //   getCities(`${cRes.name}&&${cRes.isoCode}&&${cRes.countryCode}`);
    // }
  }

  function getCities(res) {
    let isoCode = res.split("&&")[1];
    let cCode = res.split("&&")[2];
    setState(res);
    let cities = City.getCitiesOfState(cCode, isoCode);
    setCitiesListing(cities);
  }

  function onInputChange(e) {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }
  const handleProfileImageClick = () => {
    setUpload(true);
  };

  useEffect(() => {
    getAlldata();
    getUserData();
    getUserdataById();
  }, []);

  function navigateToResumePage() {

    let userData = sessionStorage.getItem("userData");
    if (userData) {
      let ss = JSON.parse(userData);
      if (
        userRef.current?.resuma_complete == true &&
        userRef.current?.resuma_complete !== undefined
      ) {
        window.location.href = `https://www.newhopepoints.org/surrogateMother/resume/step-1?token=${encodeURIComponent(
          ss.token
        )}&email=${encodeURIComponent(ss.email)}&id=${encodeURIComponent(
          ss.userId
        )}&step=1`;
      } else {
        window.location.href = `https://www.newhopepoints.org/surrogateMother/resume/step-1?token=${encodeURIComponent(ss.token)}&email=${encodeURIComponent(
          ss.email
        )}&id=${encodeURIComponent(ss.userId)}&step=1`;
      }
    };

  }

  function navigatet(res) {
    let userData = sessionStorage.getItem("userData");
    let ss = JSON.parse(userData);
    // window.location.href = `http://localhost:3000/surrogateMother/resume/step-${res}?token=${encodeURIComponent(ss.token)}&email=${encodeURIComponent(
    //   ss.email
    // )}&id=${encodeURIComponent(ss.userId)}?step=${encodeURIComponent(res)}`;
    window.location.href = `https://www.newhopepoints.org/surrogateMother/resume/step-${res}?token=${encodeURIComponent(ss.token)}&email=${encodeURIComponent(
      ss.email
    )}&id=${encodeURIComponent(ss.userId)}?step=${encodeURIComponent(res)}`;
  }

  //   function ageCalculate(res) {
  //     return moment().diff(moment(res), 'years');
  //     // const dob = moment(res);
  //     //   const now = moment();
  //     //   const age = now.diff(dob, 'years');
  //     //   onInputChange({target:{value:age,name:"age"}})
  //   }

  function ageCalculate(res) {
    const dob = moment(res);
    const now = moment();
    const age = now.diff(dob, 'years');
    setData(pre => {
      return { ...pre, age: age }
    })
  }
  // async function convertToImg() {
  //   setLoader(true)
  //   let arr = [resumeRef1.current, resumeRef2.current];
  //   let photoArr = [];
  //   const pdf = new jsPDF();

  //   for (let index = 0; index < arr.length; index++) {
  //     const res = arr[index];
  //     await htmlToImage.toPng(res, { quality: 0.5 })
  //       .then(function (dataUrl) {
  //         photoArr.push(dataUrl);
  //         const imgProps = pdf.getImageProperties(dataUrl);
  //         const pdfWidth = pdf.internal.pageSize.getWidth();
  //         const pdfHeight = ((imgProps.height * pdfWidth) / imgProps.width) - 10;

  //         pdf.addImage(dataUrl, 'PNG', 0, 0, pdfWidth, pdfHeight);
  //         if (index !== arr.length - 1) {
  //           pdf.addPage();
  //         }
  //       })
  //       .catch(function (error) {
  //         console.error('oops, something went wrong!', error);
  //       }).finally(() => {
  //         setLoader(false)
  //       })
  //   }

  //   pdf.save('Resume');
  // }
  async function convertToImg() {
    setLoader(true);
    let arr = [resumeRef1.current, resumeRef2.current];
    let photoArr = [];
    const pdf = new jsPDF();
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    for (let index = 0; index < arr.length; index++) {
      const res = arr[index];
      await htmlToImage.toPng(res, { quality: 0.5 }) // Reduced quality to 0.5
        .then(function (dataUrl) {
          photoArr.push(dataUrl);
          const imgProps = pdf.getImageProperties(dataUrl);
          const imgWidth = pdfWidth;
          const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;

          // Scale image to fit within PDF dimensions
          const scaleFactor = Math.min(pdfWidth / imgProps.width, pdfHeight / imgProps.height);
          const scaledWidth = imgProps.width * scaleFactor;
          const scaledHeight = imgProps.height * scaleFactor;

          pdf.addImage(dataUrl, 'PNG', 0, 0, scaledWidth, scaledHeight, undefined, 'FAST'); // Added compression option
          if (index !== arr.length - 1) {
            pdf.addPage();
          }
        })
        .catch(function (error) {
          console.error('oops, something went wrong!', error);
        })
        .finally(() => {
          if (index === arr.length - 1) {
            setLoader(false);
          }
        });
    }

    pdf.save('Resume.pdf');
  }




  function EditData(page) {
    if (activeTab == 0) {
      setActiveEditPage(0);
      setEditable(false);
    }
    else {
      setActiveEditPage(1);
      setEditable(true);
    }
  }


  async function verifyResume(e, userId) {
    e.preventDefault()
    let payload = {
      price: '99',
      status: true
    }
    setLoader(true)

    try {
      await verifySurrogateResume(payload, userId)

    } catch (err) {
      console.log(err)
    } finally {
      setVisibale(false)
      await getUserdataById()
      setLoader(false)
    }

  }







  return (
    <>
      <ResumePhoto
        setUpload={setUpload}
        upload={upload}
        getUserData={getUserData}
      />
      <Loader open={loader} />


      {allData?.firstName
        ?
        <div
          style={{ width: '905px' }}
        >
          <div
            style={{
              height: "0px",
              overflow: "hidden",
            }}
          >
            <div style={styleSheet.maincontainer} ref={resumeRef1} id='print'>
              <div style={styleSheet.topBox} />
              <div style={styleSheet.leftBox} />
              <div style={styleSheet.topContainer}>
                <div style={styleSheet.topLeft}>
                  <img crossOrigin='anonymous' src={allData?.photos?.length >= 1 ? `${allData?.photos[photoIndex]?.fileUrl}?${moment().format('mm:ss')}` : "/images/blankProfile.png"} alt='' style={{ width: '100%', height: '100%' }} />
                </div>
                <div style={styleSheet.topRight}>
                  <div style={styleSheet.topRightInnerTop}>
                    <div>
                      {/* <div style={styleSheet.codeHeading}>{allData?.memberId}</div> */}
                      <div style={styleSheet.codeSub}>Surrogate Mother</div>
                    </div>
                    <div><img src='/images/bigLogo.png' alt='' style={{ width: '140px' }} /></div>
                  </div>
                  <div style={styleSheet.heading}>
                    General Information
                  </div>
                  <div style={styleSheet.listContent}>
                    <div><span className='v_listHeading'>SM Id:</span>&nbsp;<span className='v_listText'>{allData?.memberId}</span></div>
                    {/* {allData?.not_show_fullName ? '' : <div><span className='v_listHeading'>Name:</span>&nbsp;<span className='v_listText'>{`${allData?.firstName} ${allData?.middleName} ${allData?.lastName}`}</span></div>} */}
                    <div><span className='v_listHeading'>Age:</span>&nbsp;<span className='v_listText'>{allData?.age}</span></div>
                    <div><span className='v_listHeading'>Location:</span>&nbsp;<span className='v_listText'>{`${allData?.state}, ${allData?.country !== undefined ? allData?.country : ''}`}</span></div>
                    <div><span style={styleSheet.listHeading}>Kids:</span>&nbsp;<span style={styleSheet.listText}>{allData?.no_of_kids}</span></div>
                    <div><span style={styleSheet.listHeading}>Vaginal Deliveries:</span>&nbsp;<span style={styleSheet.listText}>{allData?.no_of_vaginal_deliveries}</span></div>
                    <div><span style={styleSheet.listHeading}>C-Section Deliveries:</span>&nbsp;<span style={styleSheet.listText}>{allData?.no_of_c_section_deliveries}</span></div>
                    <div><span style={styleSheet.listHeading}>Insurance:</span>&nbsp;<span style={styleSheet.listText}>{allData?.medical_insurance ? 'Yes' : 'No'}</span></div>
                    <div><span style={styleSheet.listHeading}>Will you Terminate if needed:</span>&nbsp;<span style={styleSheet.listText}>{allData?.terminate_when_need ? 'Yes' : 'No'}</span></div>
                    <div><span style={styleSheet.listHeading}>Are you Willing to work with HIV positive intended parent:</span>&nbsp;<span style={styleSheet.listText}>{allData?.willing_to_work_with_hiv_positive_ip ? 'Yes' : 'No'}</span></div>
                    <div><span style={styleSheet.listHeading}>Are you Willing to work with HEP B positive intended parent:</span>&nbsp;<span style={styleSheet.listText}>{allData?.willing_to_work_with_hep_b_positive_ip ? 'Yes' : 'No'}</span></div>
                    <div><span style={styleSheet.listHeading}>Are you Employed:</span>&nbsp;<span style={styleSheet.listText}>{allData?.currently_employed ? 'Yes' : 'No'}</span></div>
                    {allData?.currently_employed ? <div><span className='v_listHeading'>Job Role:</span>&nbsp;<span className='v_listText'>{allData?.occupation}</span></div> : ''}

                  </div>
                </div>
              </div>
              {allData?.child_details?.length < 1 ? <>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
              </> : <>
                <div style={styleSheet.heading}>
                  Pregnancy History
                </div>
                <div style={styleSheet.childContainer}>

                  {allData?.child_details?.map((res, index) => {
                    return (

                      <div style={styleSheet.listContent} key={index}>
                        <div style={styleSheet.childHeading}>Child #{index + 1} -:</div>
                        <div><span style={styleSheet.listHeading}>Date of Delivery:</span>&nbsp;<span style={styleSheet.listText}>{moment(res?.dob).format('DD/MM/YYYY')}</span></div>
                        <div><span style={styleSheet.listHeading}>Weeks at Delivery:</span>&nbsp;<span style={styleSheet.listText}>{res?.weeks_at_delivery}</span></div>
                        <div><span style={styleSheet.listHeading}>Baby’s weight:</span>&nbsp;<span style={styleSheet.listText}>{`${res?.body_weight.lbs} Lbs ${res?.body_weight.oz} Oz`}</span></div>
                        <div><span style={styleSheet.listHeading}>Delivery Type:</span>&nbsp;<span style={styleSheet.listText}>{res?.delivery_type}</span></div>
                        <div><span style={styleSheet.listHeading}>Surrogacy:</span>&nbsp;<span style={styleSheet.listText}>{res?.surrogacy ? 'Yes' : 'No'}</span></div>
                        <div><span style={styleSheet.listHeading}>Singleton or Multiple:</span>&nbsp;<span style={styleSheet.listText}>{res?.singleton_or_multiple}</span></div>
                      </div>
                    )
                  })}
                </div>
              </>}
              {allData?.child_details?.length > 3 ? "" : <>
                <div style={styleSheet.heading2}>
                  Compensation and Ideology
                </div>
                <div style={styleSheet.listContent}>
                  <div><span style={styleSheet.listHeading}>Desired Compensation:</span>&nbsp;<span style={styleSheet.listText}>${allData?.desired_compensaton}</span></div>
                  <div><span style={styleSheet.listHeading}>Embryos willing to transfer:</span>&nbsp;<span style={styleSheet.listText}>{allData?.embryo_willing_to_transfer}</span></div>
                  <div><span style={styleSheet.listHeading}>Number of Embryo Transfer Attempts:</span>&nbsp;<span style={styleSheet.listText}>{allData?.no_of_embryo_transfer_attempts}</span></div>
                  <div><span style={styleSheet.listHeading}>Why do you want to become a surrogate? What first interested you in surrogacy?</span>&nbsp;<span style={styleSheet.listText}>{allData?.want_to_become_surrogate}</span></div>
                  <div><span style={styleSheet.listHeading}>What does being a surrogate mean to you?</span>&nbsp;<span style={styleSheet.listText}>{allData?.what_surrogate_means_to_you}</span></div>
                  <div><span style={styleSheet.listHeading}>Do you have any concerns about surrogacy?</span>&nbsp;<span style={styleSheet.listText}>{allData?.any_concern_about_surrogacy}</span></div>
                  <div><span style={styleSheet.listHeading}>Are you willing to work with Intended Parent(s) that does not speak English (translation provided)?</span>&nbsp;<span style={styleSheet.listText}>{allData?.willing_to_work_with_non_english_speaker_ip}</span></div>
                  <div><span style={styleSheet.listHeading}>Are you willing to reduce caffeine during pregnancy?</span>&nbsp;<span style={styleSheet.listText}>{allData?.willing_to_reduce_caffeine ? 'Yes' : 'No'}</span></div>
                  <div><span style={styleSheet.listHeading}>Are you willing to eat organic foods, if requested (and paid for) by the Intended Parent(s)?</span>&nbsp;<span style={styleSheet.listText}>{allData?.willing_to_eat_organic_food ? 'Yes' : 'No'}</span></div>
                  <div><span style={styleSheet.listHeading}>What type of family would you like to work with?</span>&nbsp;<span style={styleSheet.listText}>{allData?.type_of_family_want_to_work_with}</span></div>
                  <div><span style={styleSheet.listHeading}>Is there anyone you are not comfortable working with?</span>&nbsp;<span style={styleSheet.listText}>{allData?.NOT_want_work_with_intended_parents}</span></div>
                </div>
              </>}
              <br />
              <br />
              <br />
              <br />
            </div>
            <div style={styleSheet.maincontainer2} ref={resumeRef2} >
              <div style={styleSheet.topBox} />
              <div style={styleSheet.leftBox} />
              <div style={styleSheet.topRightInnerTop}>
                <div>&nbsp;</div>
                <div><img src='/images/bigLogo.png' alt='' style={{ width: '140px' }} /></div>
              </div>
              {allData?.child_details?.length > 3 ? <>
                <br />
                <div style={styleSheet.heading2}>
                  Compensation and Ideology
                </div>
                <div style={styleSheet.listContent}>
                  <div><span style={styleSheet.listHeading}>Desired Compensation:</span>&nbsp;<span style={styleSheet.listText}>${allData?.desired_compensaton}</span></div>
                  <div><span style={styleSheet.listHeading}>Embryos willing to transfer:</span>&nbsp;<span style={styleSheet.listText}>{allData?.embryo_willing_to_transfer}</span></div>
                  <div><span style={styleSheet.listHeading}>Number of Embryo Transfer Attempts:</span>&nbsp;<span style={styleSheet.listText}>{allData?.no_of_embryo_transfer_attempts}</span></div>
                  <div><span style={styleSheet.listHeading}>Why do you want to become a surrogate? What first interested you in surrogacy?</span>&nbsp;<span style={styleSheet.listText}>{allData?.want_to_become_surrogate}</span></div>
                  <div><span style={styleSheet.listHeading}>What does being a surrogate mean to you?</span>&nbsp;<span style={styleSheet.listText}>{allData?.what_surrogate_means_to_you}</span></div>
                  <div><span style={styleSheet.listHeading}>Do you have any concerns about surrogacy?</span>&nbsp;<span style={styleSheet.listText}>{allData?.any_concern_about_surrogacy}</span></div>
                  <div><span style={styleSheet.listHeading}>Are you willing to work with Intended Parent(s) that does not speak English (translation provided)?</span>&nbsp;<span style={styleSheet.listText}>{allData?.willing_to_work_with_non_english_speaker_ip}</span></div>
                  <div><span style={styleSheet.listHeading}>Are you willing to reduce caffeine during pregnancy?</span>&nbsp;<span style={styleSheet.listText}>{allData?.willing_to_reduce_caffeine ? 'Yes' : 'No'}</span></div>
                  <div><span style={styleSheet.listHeading}>Are you willing to eat organic foods, if requested (and paid for) by the Intended Parent(s)?</span>&nbsp;<span style={styleSheet.listText}>{allData?.willing_to_eat_organic_food ? 'Yes' : 'No'}</span></div>
                  <div><span style={styleSheet.listHeading}>What type of family would you like to work with?</span>&nbsp;<span style={styleSheet.listText}>{allData?.type_of_family_want_to_work_with}</span></div>
                  <div><span style={styleSheet.listHeading}>Is there anyone you are not comfortable working with?</span>&nbsp;<span style={styleSheet.listText}>{allData?.NOT_want_work_with_intended_parents}</span></div>
                </div>
                <br />
              </> : ''}
              <div style={styleSheet.heading}>
                Interest
              </div>
              <div style={styleSheet.listContent}>
                <div><span style={styleSheet.listHeading}>Describe your ideal Intended Parent(s):</span>&nbsp;<span style={styleSheet.listText}>{allData?.describe_the_ideal_intended_Parent}</span></div>
                <div><span style={styleSheet.listHeading}>What would you like your future Intended Parent(s) to know about you?</span>&nbsp;<span style={styleSheet.listText}>{allData?.note_future_intended_Parent}</span></div>
                <div><span style={styleSheet.listHeading}>Are you willing to have the Intended Parent(s) present during doctors appointments and the delivery?</span>&nbsp;<span style={styleSheet.listText}>{allData?.presence_of_ip_during_appointment_and_delivery ? 'Yes' : 'No'}</span></div>
                <div><span style={styleSheet.listHeading}>There anyone you would like present during the delivery (example: Spouse/Partner, Mother, Sister or Friend)?</span>&nbsp;<span style={styleSheet.listText}>{allData?.anyone_you_want_during_delivery}</span></div>
                <div><span style={styleSheet.listHeading}>How often would you like to be in contact with the Intended Parent(s) during the pregnancy?</span>&nbsp;<span style={styleSheet.listText}>{allData?.contact_with_ip_during_pregnancy}</span></div>
                <div><span style={styleSheet.listHeading}>How often would you like to be in contact with the Intended Parent(s) after the delivery?</span>&nbsp;<span style={styleSheet.listText}>{allData?.fequency_of_contact_with_ip_after_pregnancy}</span></div>
                <div><span style={styleSheet.listHeading}>How would you like to be in contact with the Intended Parent(s)?</span>&nbsp;<span style={styleSheet.listText}>{allData?.mode_of_communication_with_ip}</span></div>
                <div><span style={styleSheet.listHeading}>Are you willing to pump breast milk (compensation available)?</span>&nbsp;<span style={styleSheet.listText}>{allData?.willing_to_pump_breast_milk ? 'Yes' : 'No'}</span></div>
                <div><span style={styleSheet.listHeading}>Are you willing to leave termination choices up to the Intended Parent(s) in the case of a serious birth defect?</span>&nbsp;<span style={styleSheet.listText}>{allData?.terminate_when_need ? 'Yes' : 'No'}</span></div>
                <div><span style={styleSheet.listHeading}>Are you willing to leave termination choices up to the Intended Parent(s) in the case of a non-life threatening condition?</span>&nbsp;<span style={styleSheet.listText}>{allData?.leave_termnation_choice_on_ip_for_threatening_condition ? 'Yes' : 'No'}</span></div>
                <div><span style={styleSheet.listHeading}>If recommended by a doctor are you willing to be placed on bedrest?</span>&nbsp;<span style={styleSheet.listText}>{allData?.willing_to_be_placed_on_bedrest ? 'Yes' : 'No'}</span></div>
                <div><span style={styleSheet.listHeading}>What reassurance can you give your Intended Parent(s) that you will not change your mind?</span>&nbsp;<span style={styleSheet.listText}>{allData?.reassurances_for_ip}</span></div>
                <div><span style={styleSheet.listHeading}>Please describe your support system and who your support system is:</span>&nbsp;<span style={styleSheet.listText}>{allData?.describe_support_system}</span></div>
                <div><span style={styleSheet.listHeading}>Do you have child care available?</span>&nbsp;<span style={styleSheet.listText}>{allData?.child_care_available ? 'Yes' : 'No'}</span></div>
                <div><span style={styleSheet.listHeading}>When are you ready to begin:</span>&nbsp;<span style={styleSheet.listText}>{moment(allData?.ready_to_begin).format('DD/MM/YYYY')}</span></div>
              </div>
            </div>
          </div>
        </div>
        :
        ''}

      {all.firstName && (<>



        <div className={`bg-white p-4 rounded-4 mt-3 ${style.CreditReport_con}`}>
          {/* <form onSubmit={editProfileData}> */}
          <div className={` ${style.bannersize} p-md-4 p-sm-3 p-2`}>
            <div className="d-flex align-items-center gap-3">
              {editable ? (
                <>
                  <div className={`${style.ProfileImg1}`}>
                    <img
                      src={
                        data?.photos?.length !== 0 &&
                          data.photos[photoIndex]?.fileUrl
                          ? data.photos[photoIndex]?.fileUrl
                          : "/images/blankProfile.png"
                      }
                      className="w-100 h-100"
                      alt=""
                    />
                  </div>
                </>
              ) : (
                <>
                  <label
                    className={`${style.ProfileImg1} pointer border`}
                    onClick={handleProfileImageClick}
                  >
                    <div className={`${style.inputIcon}`}>
                      <CiImageOn />
                    </div>
                    <img
                      src={
                        data?.photos?.length !== 0
                          ? data.photos[photoIndex]?.fileUrl
                          : getImgPreview(defaultImg)
                      }
                      htmlFor="imgInp"
                      className="w-100 h-100"
                      alt=""
                    />
                    {/* <input type='file' hidden id='imgInp' onChange={(e) => {
                                    setDefaultimg(e.target.files[0])
                                    setImg(e.target.files[0])
                                }} 
                                />  */}
                  </label>
                </>
              )}

              <div className="d-flex flex-column align-item-center justify-content-center gap-3">
                <div className={style.BnnerFirstText}>
                  {data.memberId} : Surrogate Mother
                </div>
                <div
                  className={style.BnnersecondtText}
                >{`${data?.firstName} ${data?.lastName}`}</div>

                {users?.resuma_complete ? <div className="
                d-flex gap-3">
                  <button
                    className={`${style.VerifyResumeButton} ${users?.verify_resume !== undefined ? users?.verify_resume?.status ? style.verified : '' : ''}`}
                    onClick={() => users?.verify_resume !== undefined ? users?.verify_resume?.status ? {} : setVisibale(true) : setVisibale(true)}
                  >
                    {users?.verify_resume !== undefined ? users?.verify_resume?.status ? <><GoVerified className="fs-5" /> Verified</> : 'Verify Resume' : 'Verify Resume'}

                  </button>
                  <button
                    className={style.UpadateResumeButton}
                    onClick={() => navigateToResumePage()}
                  >
                    Update/View Resume
                  </button>
                </div> : <><button className={style.ctcBtn} onClick={() => navigatet(users?.resuma_step)}>Make Advance Resume</button></>}
                {/* <button className={style.ctcBtn} onClick={() => navigatet(users?.resuma_step)}>Make Advance Resume</button></>*/}
              </div>
            </div>

            <div className={`p-3 p-relative ${style.ProfileImg}`}>
              <img src="/images/edit_doctor_right_pic.png" className={`w-100`} alt="" />


            </div>
          </div>




          <div className={style.tabs_container}>

            <div onClick={() => {
              setActiveTab(0)
              setActiveEditPage(0);
              setEditable(true);
            }} className={`${style.navLink} ${activeTab === 0 ? style.active : ""}`}>
              Matchmaking Profile
            </div>

            <div onClick={() => {
              setActiveTab(1)
              setActiveEditPage(0);
              setEditable(true);
            }} className={`${style.navLink} ${activeTab === 1 ? style.active : ""}`}>
              Basic Profile
            </div>


            <div className={`p-2 ms-auto me-3`}>

              {editable && activeEditPage == 0 ? (
                <div
                  className={`d-flex justify-content-end ${style.ChlidButton}`}
                >
                  <button
                    className={`text-center d-flex align-items-center ${style.VerfiedButton}`}
                    onClick={EditData}
                  >
                    <i class="bi bi-pencil-square me-1 d-flex align-items-center"></i>
                    <span className={style.verfiedText}>Edit Details</span>
                  </button>
                </div>
              ) : (

                ""
              )}
            </div>



          </div>




          {activeTab === 0 ?
            <form onSubmit={editProfileData} className="mt-3">
              {/* <div className={` ${style.match_making_heading} mb-4`}>
                Profile info for match-making
              </div> */}
              <div className={`${style.child1} mt-4 px-md-4 px-sm-3 px-2`}>
                <div className="row mb-4">
                  <label
                    htmlFor="exampleInputEmail1"
                    className={`form-label ${style.LabelHeading}`}
                  >
                    {t("Service Offered")}
                    <span className="text-danger">*</span>
                  </label>
                  <div className="col-md-3 mb-lg-0 mb-md-0 mb-sm-4 mb-4 ms-3">
                    <div className="form-check">
                      <input
                        className={`form-check-input ${style.InputCustom}`}
                        type="radio"
                        name="service_offer"
                        value="surrogate"
                        checked={data.service_offer == "surrogate"}
                        id="flexRadioDefault1"
                      // onChange={(e) => {
                      //   setData(prevData => ({ ...prevData, name: 'service_offer', label: 'Surrogate Mother' }));
                      // }}
                      />
                      <label
                        className={`form-check-label ${style.SubLAbelHedaing}`}
                        htmlFor="flexRadioDefault1"
                      >
                        {t("Surrogate Mother")}
                      </label>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="form-check">
                      <input
                        className={`form-check-input ${style.InputCustom}`}
                        type="radio"
                        name="service_offer"
                        value="Egg/Sperm Donor"
                        id="flexRadioDefault2"
                        checked={data.service_offer == "eggDonor"}
                      // onChange={(e) => {
                      //   setData(prevData => ({ ...prevData, name: 'service_offer', label: 'Egg/Sperm Donor' }));
                      // }}
                      />
                      <label
                        className={`form-check-label ${style.SubLAbelHedaing}`}
                        htmlFor="flexRadioDefault1"
                      >
                        {t("Egg/Sperm Donor")}
                      </label>
                    </div>
                  </div>
                </div>

                <div className="row mb-4 mt-3">
                  <div className="col-md-12">
                    <label
                      htmlFor="exampleInputEmail1"
                      className={`form-label  ${style.LabelHeading}`}
                    >
                      {t("Short description")}
                      <span className="text-danger">*</span>
                    </label>

                    <textarea
                      type="text"
                      rows={1}
                      className={`form-control`}
                      placeholder=""
                      disabled={editable}
                      value={data?.short_description}
                      required
                      name="short_description"
                      onChange={onInputChange}
                    />
                  </div>
                </div>

                <div className="row mb-4">
                  <div className="col-6 col-lg-6 mb-md-4 mb-sm-4 mb-4">
                    <label
                      htmlFor="exampleInputEmail1"
                      className={`form-label  ${style.LabelHeading}`}
                    >
                      {t("Age")}
                      <span className="text-danger">*</span>
                    </label>

                    <input
                      type="number"
                      className={`form-control`}
                      placeholder=""
                      disabled={true}
                      value={data?.age}
                      required
                      name="age"
                      onChange={onInputChange}
                    />
                  </div>
                  <div className="col-6 col-lg-6 mb-md-4 mb-sm-4 mb-4">
                    <label
                      htmlFor="exampleInputEmail1"
                      className={`form-label  ${style.LabelHeading}`}
                    >
                      {t("Date of birth")}
                      <span className="text-danger">*</span>
                    </label>

                    <input
                      type="date"
                      className={`form-control`}
                      placeholder=""
                      disabled={editable}
                      value={data?.dob && moment(data.dob).format("YYYY-MM-DD")}
                      required
                      name="dob"
                      onChange={(e) => {
                        onInputChange(e);
                        ageCalculate(e.target.value);

                      }}
                    />
                  </div>
                  <div className="col-6 col-lg-6">
                    <label
                      htmlFor="exampleInputEmail1"
                      className={`form-label ${style.LabelHeading}`}
                    >
                      {t("Height")} <span className="text-danger">*</span>
                    </label>
                    <div className="row">
                      <div className="col-sm-6 mb-xs-4 d-flex">
                        <input
                          required
                          type="number"
                          disabled={editable}
                          value={data.height.feet}
                          onChange={(e) => {
                            setData((pre) => {
                              pre.height.feet = e.target.value;
                              return { ...pre };
                            });
                          }}
                          className="form-control me-2"
                        />
                        <div className="d-flex align-items-center">ft</div>
                      </div>
                      <div className="col-sm-6 d-flex">
                        <input
                          type="number"
                          required
                          disabled={editable}
                          value={data.height.inches}
                          onChange={(e) => {
                            setData((pre) => {
                              pre.height.inches = e.target.value;
                              return { ...pre };
                            });
                          }}
                          className="form-control me-2"
                        />
                        <div className="d-flex align-items-center">inches</div>
                      </div>

                    </div>


                  </div>

                  <div className="col-6 col-lg-6">
                    <label
                      htmlFor="exampleInputEmail1"
                      className={`form-label ${style.LabelHeading}`}
                    >
                      {t("Weight")} <span className="text-danger">*</span>
                    </label>
                    <div className="row">
                      <div className="col-sm-6 mb-xs-4 d-flex">
                        <input
                          required
                          type="number"
                          disabled={editable}
                          value={data.weight.LBs}
                          onChange={(e) => {
                            setData((pre) => {
                              pre.weight.LBs = e.target.value;
                              return { ...pre };
                            });
                          }}
                          className="form-control me-2"
                        />
                        <div className="d-flex align-items-center">lbs</div>
                      </div>
                      <div className="col-sm-6 d-flex">
                        <input
                          required
                          type="number"
                          disabled={editable}
                          value={data.weight.oz}
                          onChange={(e) => {
                            setData((pre) => {
                              pre.weight.oz = e.target.value;
                              return { ...pre };
                            });
                          }}
                          className="form-control me-2"
                        />
                        <div className="d-flex align-items-center">oz</div>
                      </div>
                      <div></div>
                    </div>

                  </div>

                </div>

                <div className="row mb-4">
                  <div className="col-md-12">
                    <label
                      htmlFor="exampleInputEmail1"
                      className={`form-label  ${style.LabelHeading}`}
                    >
                      {t("Current Address")}
                      <span className="text-danger">*</span>
                    </label>

                    <input
                      type="text"
                      className={`form-control`}
                      placeholder=""
                      disabled={editable}
                      value={data?.current_address}
                      required
                      name="current_address"
                      onChange={onInputChange}
                    />
                  </div>
                </div>


                <div className="row mb-4">
                  <div className="col-6 col-xl-3 mb-xl-0 mb-md-4 mb-sm-4 mb-4">
                    <label
                      htmlFor="exampleInputEmail1"
                      className={`form-label  ${style.LabelHeading}`}
                    >
                      {t("Country")}
                      <span className="text-danger">*</span>{" "}
                    </label>
                    <select
                      className={`form-select`}
                      value={country}
                      required
                      disabled={editable}
                      onChange={(e) => {
                        getStates(e.target.value);
                        setState("");
                        setCity("");
                      }}
                    >
                      <option selected disabled value={""}>
                        {t("select")}
                      </option>
                      {countries.map((res, index) => {
                        return (
                          <option key={index} value={`${res.name}&&${res.isoCode}`}>
                            {res.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-6 col-xl-3 mb-xl-0 mb-md-4 mb-sm-4 mb-4">
                    <label
                      htmlFor="exampleInputEmail1"
                      className={`form-label  ${style.LabelHeading}`}
                    >
                      {t("State of Residence")}

                    </label>
                    <select
                      className={`form-select`}
                      value={state}

                      disabled={editable}
                      onChange={(e) => {
                        getCities(e.target.value);
                        setCity("");
                      }}
                    >
                      <option selected disabled value={""}>
                        {t("select")}
                      </option>
                      {statesListing?.map((res, index) => {
                        return (
                          <option
                            key={index}
                            value={`${res.name}&&${res.isoCode}&&${res.countryCode}`}
                          >
                            {res.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="col-6 col-xl-3">
                    <label
                      htmlFor="exampleInputEmail1"
                      className={`form-label  ${style.LabelHeading}`}
                    >
                      {t("City")}

                    </label>
                    <select
                      className={`form-select`}
                      value={city}

                      disabled={editable}
                      onChange={(e) => setCity(e.target.value)}
                    >
                      <option selected disabled value={""}>
                        {t("select")}
                      </option>
                      {citiesListing?.map((res, index) => {
                        return (
                          <option key={index} value={`${res.name}`}>
                            {res.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="col-6 col-xl-3">
                    <label
                      htmlFor="exampleInputEmail1"
                      className={`form-label ${style.LabelHeading}`}
                    >
                      {t("Zip Code")}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control`}
                      required
                      placeholder=""
                      disabled={editable}
                      value={data?.zipCode}
                      name="zipCode"
                      onChange={onInputChange}
                    />
                  </div>
                </div>


                <div className="row mb-4">
                  <div className="col-md-6 mb-4 mb-md-0">
                    <label
                      htmlFor="exampleInputEmail1"
                      className={`form-label  ${style.LabelHeading}`}
                    >
                      {t("Select your lifestyle")}{" "}
                    </label>
                    <MultiSelect
                      value={selectedLifeStyle}
                      disabled={editable}
                      onChange={(e) => setSelectedLifeStyle(e.value)}
                      // options={cities}
                      options={lifestyle}
                      optionLabel="name"
                      display="chip"
                      showSelectAll={false}
                      closeIcon={false}
                      placeholder=" "
                      maxSelectedLabels={5}
                      className="multiSelectDropDown ps-2"
                    />
                  </div>

                  <div className="col-md-6">
                    <label
                      htmlFor="exampleInputEmail1"
                      className={`form-label  ${style.LabelHeading}`}
                    >
                      {t("Select your habits")}{" "}
                    </label>
                    <MultiSelect
                      value={selectedHabits}
                      disabled={editable}
                      onChange={(e) => setSelectedHabits(e.value)}
                      // options={cities2}
                      options={habits}
                      optionLabel="name"
                      display="chip"
                      placeholder=" "
                      showSelectAll={false}
                      closeIcon={false}
                      maxSelectedLabels={5}
                      className="multiSelectDropDown ps-2"
                    />

                  </div>
                </div>




                {/* <div className="row mb-4">
                <div className="col-md-12 mt-4">
                  <label className="form-label fs-5">
                    {t("Current marital/relationship status")}?
                    <span className="text-danger">*</span>
                  </label>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-check">
                        <input
                          required
                          disabled={editable}
                          className="form-check-input"
                          id="relationship_status1"
                          type="radio"
                          onChange={(e) => radioButtonChange(e, e.target.value)}
                          checked={data.relationship_status === "Married"}
                          name="relationship_status"
                          value="Married"
                        />

                        <label
                          htmlFor="relationship_status1"
                          className="form-check-label LabeSubHeading "
                        >
                          {t("Married")}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-check">
                        <input
                          required
                          disabled={editable}
                          className="form-check-input"
                          id="relationship_status1"
                          type="radio"
                          onChange={(e) => radioButtonChange(e, e.target.value)}
                          checked={data.relationship_status === 'Single, No Partner'}
                          name="relationship_status"
                          value="Single, No Partner"
                        />
                        <label
                          htmlFor="relationship_status1"
                          className="form-check-label LabeSubHeading "
                        >
                          {t("Single, No Partner")}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-check">
                        <input
                          required
                          disabled={editable}
                          className="form-check-input"
                          id="relationship_status1"
                          type="radio"
                          onChange={(e) => radioButtonChange(e, e.target.value)}
                          checked={data.relationship_status === "Not married, but in a committed relationship"}
                          name="relationship_status"
                          value="Not married, but in a committed relationship"
                        />
                        <label
                          htmlFor="relationship_status1"
                          className="form-check-label LabeSubHeading "
                        >
                          {t("Not married, but in a committed relationship")}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

                <div className="row mb-4">
                  <div className="col-md-6 col-xl-3 mb-lg-0 mb-md-0 mb-sm-4 mb-4">
                    <label
                      htmlFor="exampleInputEmail1"
                      className={`form-label  ${style.LabelHeading}`}
                    >
                      {t("Race")}
                    </label>
                    <select
                      className={`form-select ${style.InputCustom}`}
                      onChange={onInputChange}
                      disabled={editable}
                      value={data.race}
                      name="race"
                      required
                    >
                      {SURROGATE_MOTHER_RACE.map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-6 col-xl-3 mb-lg-0 mb-md-0 mb-sm-4 mb-4">
                    <label
                      htmlFor="exampleInputEmail1"
                      className={`form-label  ${style.LabelHeading}`}
                    >
                      {t("Skin Color")}
                    </label>
                    <select
                      className={`form-select ${style.InputCustom}`}
                      onChange={onInputChange}
                      disabled={editable}
                      value={data.skin_color}
                      name="skin_color"
                      required
                    >
                      <option value={"noValue"} selected disabled>
                        Select Skin color
                      </option>
                      <option value="Light, pale white">Light, pale white</option>
                      <option value="white, fair">white, fair</option>
                      <option value="Medium white to olive">
                        Medium white to olive
                      </option>
                      <option value="Olive, mid brown">Olive, mid brown</option>
                      <option value="Brown, dark brown">Brown, dark brown</option>
                      <option value="Very dark brown, black">
                        Very dark brown, black
                      </option>
                    </select>
                  </div>

                </div>

                <div className="section2">


                  <div className="child1 mt-4">
                    {data?.child_details.map((res, key) => {
                      return (
                        <>
                          <div className="child1" key={key}>
                            <div>
                              <div className="d-flex align-items-center justify-content-between">
                                <span className="MiddleSubHeading pb-3 lufga_18px">{`Child #${key + 1
                                  }`}</span>
                                <div>
                                </div>
                              </div>

                              <div className="CheckBoxParent row pb-4">
                                <div className="col-6">
                                  <label
                                    className={`form-label ${style.LabelHeading}`}
                                    htmlFor="flexRadioDefault1"
                                  >
                                    {t("Gender")}
                                    <span className="text-danger">*</span>
                                  </label>

                                  <div className="row">
                                    <div className="col-xl-8">
                                      <div className="row">
                                        <div className="col-6">
                                          <div className="form-check">
                                            <input
                                              checked={res.gender == "Male"}
                                              className={`me-2 ${!editable && "form-check-input"}`}
                                              type="radio"
                                              name={`childGender${key}`}
                                              id={`childGender${key}1`}
                                              disabled={editable}
                                              onChange={(e) => {
                                                if (e.target.checked) {
                                                  setData((pre) => {
                                                    pre.child_details[key].gender =
                                                      "Male";
                                                    return { ...pre };
                                                  });
                                                }
                                              }}
                                            />
                                            <label
                                              className="form-check-label LabelSubHeading"
                                              htmlFor={`childGender${key}1`}
                                            >
                                              {t("Male")}
                                            </label>
                                          </div>
                                        </div>

                                        <div className="col-6">
                                          <div className="form-check">
                                            <input
                                              disabled={editable}
                                              checked={res.gender == "Female"}
                                              className={`me-2 ${!editable && "form-check-input"}`}
                                              type="radio"
                                              onChange={(e) => {
                                                if (e.target.checked) {
                                                  setData((pre) => {
                                                    pre.child_details[key].gender =
                                                      "Female";
                                                    return { ...pre };
                                                  });
                                                }
                                              }}
                                              name={`childGender${key}`}
                                              id={`childGender${key}2`}
                                            />
                                            <label
                                              className="form-check-label LabelSubHeading"
                                              htmlFor={`childGender${key}2`}
                                            >
                                              {t("Female")}
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>


                                <div className="col-6">

                                  <label
                                    className={`form-label ${style.LabelHeading}`}
                                    htmlFor="flexRadioDefault1"
                                  >
                                    {t("This baby was")}

                                  </label>

                                  <div className="row">
                                    <div className="col-xl-8">
                                      <div className="row">
                                        <div className="col-6">
                                          <div className="form-check">
                                            <input
                                              onChange={(e) => {
                                                setData((pre) => {
                                                  pre.child_details[key].this_baby_was =
                                                    e.target.value;
                                                  return { ...pre };
                                                });
                                              }}
                                              disabled={editable}
                                              className={`${!editable && "form-check-input"} me-2 ${style.InputCustom}`}

                                              type="radio"
                                              name={`this_baby_was${key}`}
                                              id={`this_baby_was${key}1`}
                                              value="My Own Child"
                                              checked={
                                                data.child_details[key].this_baby_was ==
                                                "My Own Child"
                                              }
                                            />
                                            <label
                                              className="form-check-label LabelSubHeading"
                                              htmlFor={`this_baby_was${key}1`}
                                            >
                                              {t("My Own Child")}
                                            </label>
                                          </div>
                                        </div>



                                        <div className="col-6">

                                          <div className="form-check">

                                            <input
                                              className={`${!editable && "form-check-input"} me-2 ${style.InputCustom}`}
                                              type="radio"
                                              value="Surrogate Birth"
                                              name={`this_baby_was${key}`}
                                              checked={
                                                data.child_details[key].this_baby_was ==
                                                "Surrogate Birth"
                                              }
                                              onChange={(e) => {
                                                setData((pre) => {
                                                  pre.child_details[key].this_baby_was =
                                                    e.target.value;
                                                  return { ...pre };
                                                });
                                              }}
                                              id={`this_baby_was${key}2`}
                                              disabled={editable}
                                            />
                                            <label
                                              className="form-check-label LabelSubHeading"
                                              htmlFor={`this_baby_was${key}2`}
                                            >
                                              {t("Surrogate Birth")}
                                            </label>
                                          </div>

                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                              </div>
                            </div>


                            <div className="row pb-4">


                              <div className="col-6 col-xl-3 mb-xl-0 mb-md-4 mb-sm-4 mb-4">
                                <label
                                  htmlFor="exampleInputEmail1"
                                  className={`form-label ${style.LabelHeading}`}
                                >
                                  {t("Date of Birth")}
                                  <span className="text-danger">*</span>
                                </label>

                                <input
                                  disabled={editable}
                                  value={
                                    res.dob &&
                                    moment(res.dob).format("YYYY-MM-DD")
                                  }
                                  type="date"
                                  className={`form-control ${style.InputCustom}`}
                                  placeholder=""
                                  onChange={(e) => {
                                    setData((pre) => {
                                      pre.child_details[key].dob = e.target.value;
                                      return { ...pre };
                                    });
                                  }}
                                />
                              </div>

                              <div className="col-6 col-xl-3 mb-xl-0 mb-md-4 mb-sm-4 mb-4">
                                <label
                                  htmlFor="exampleInputEmail1"
                                  className={`form-label ${style.LabelHeading}`}
                                >
                                  {t("Months to conceive")}

                                </label>

                                <input type="number"
                                  value={res.conceive}
                                  onChange={(e) => {
                                    setData((pre) => {
                                      pre.child_details[key].conceive =
                                        e.target.value;
                                      return { ...pre };
                                    });
                                  }}
                                  className="form-control"
                                  disabled={editable} />

                              </div>


                              <div className="col-6 col-xl-3">
                                <label
                                  htmlFor="exampleInputEmail1"
                                  className={`form-label ${style.LabelHeading}`}
                                >
                                  {t("Weeks at Delivery")}
                                  <span className="text-danger">*</span>
                                </label>

                                <input
                                  onChange={(e) => {
                                    setData((pre) => {
                                      pre.child_details[key].weeks_at_delivery =
                                        e.target.value;
                                      return { ...pre };
                                    });
                                  }}
                                  value={res.weeks_at_delivery}
                                  disabled={editable}
                                  type="text"
                                  className={`form-control ${style.InputCustom}`}
                                  placeholder=""
                                />
                              </div>

                              <div className="col-6 col-xl-3">
                                <label
                                  htmlFor="exampleInputEmail1"
                                  className={`form-label ${style.LabelHeading}`}
                                >
                                  {t("Baby's Weight")}{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="row">
                                  <div className="col-6 d-flex">
                                    <input
                                      type="number"
                                      disabled={editable}
                                      onChange={(e) => {
                                        setData((pre) => {
                                          pre.child_details[key].body_weight.lbs =
                                            e.target.value;
                                          return { ...pre };
                                        });
                                      }}
                                      value={res?.body_weight?.lbs}
                                      className="form-control me-2"
                                    />
                                    <div className="d-flex align-items-center">
                                      lbs
                                    </div>
                                  </div>
                                  <div className="col-6 d-flex">
                                    <input
                                      type="number"
                                      disabled={editable}
                                      value={res?.body_weight?.oz}
                                      onChange={(e) => {
                                        setData((pre) => {
                                          pre.child_details[key].body_weight.oz =
                                            e.target.value;
                                          return { ...pre };
                                        });
                                      }}
                                      className="form-control me-2"
                                    />
                                    <div className="d-flex align-items-center">
                                      oz
                                    </div>
                                  </div>

                                </div>

                              </div>

                            </div>



                            <div className="row pb-4">

                              <div className="col-md-6 mb-4 mb-md-0">
                                <label
                                  htmlFor="exampleInputEmail1"
                                  className={`form-label ${style.LabelHeading}`}
                                >
                                  {t("Name of OB")}

                                </label>

                                <input
                                  type="text"
                                  value={res.ob_name}
                                  onChange={(e) => {
                                    setData((pre) => {
                                      pre.child_details[key].ob_name =
                                        e.target.value;
                                      return { ...pre };
                                    });
                                  }}
                                  disabled={editable}
                                  className={`form-control ${style.InputCustom}`}
                                  placeholder=""
                                />
                              </div>



                              <div className="col-md-6">
                                <label
                                  htmlFor="exampleInputEmail1"
                                  className={`form-label ${style.LabelHeading}`}
                                >
                                  {t("Delivery Hospital and State")}

                                </label>

                                <input
                                  value={res.delivery_hospital_state}
                                  onChange={(e) => {
                                    setData((pre) => {
                                      pre.child_details[
                                        key
                                      ].delivery_hospital_state = e.target.value;
                                      return { ...pre };
                                    });
                                  }}
                                  disabled={editable}
                                  type="text"
                                  className={`form-control ${style.InputCustom}`}
                                  placeholder=""
                                />
                              </div>

                            </div>


                          </div>
                        </>
                      );
                    })}
                  </div>
                  <div className="col-md-12">
                    {editable ? (
                      ""
                    ) : (
                      <div className="d-flex justify-content-end gap-4 mt-5">
                        <button
                          type="button"
                          className="btn_non_fill"
                          onClick={() => setEditable(true)}
                        >
                          Cancel
                        </button>
                        <button className="btn_fill" type="submit">
                          Save
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </form>

            : <BasicProfile
              getAlldata={getAlldata}
              data={all}
              activeEditPage={activeEditPage}
              cti={convertToImg}
              setEditable={setEditable}
              setActiveEditPage={setActiveEditPage}
            />}
          <VerifyResume visibale={visibale} setVisibale={setVisibale} verifyResume={verifyResume} userData={users} />
        </div>

      </>)
      }
    </>
  );
};

export default Resume;
