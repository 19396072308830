import React, { useEffect } from "react";
import style from "./AddFeatures.module.css";
import { Dialog } from "primereact/dialog";
// import { useTranslation } from "react-i18next";
import { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { addPlan } from "../../../../../Api/admin";
import { addFeature } from "../../../../../Api/admin";
import Loader from "../../../../common/Loader";

const AddFeatures = ({ resumeone, setResumeone, func }) => {
  //   let { t } = useTranslation();

  const [userData, setUserData] = useState({
    feature_name: "",
  });
  const [loader, setLoader] = useState(false);


  const handleChnage = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true)
    try {
      // Assume addPlan is an async function that sends userData to your backend
      await addFeature(userData);
      func()
      setResumeone(false)

      setLoader(false)
      // Handle success, e.g., clearing the form or providing user feedback
    } catch (error) {
      console.log(error)
      setLoader(false)
      // Handle error, e.g., providing user feedback
    }
      finally{
        setLoader(false)
      setUserData({feature_name: ""})
    }
    
  };

  useEffect(function () {
    func();
  }, []);

  return (
    <div className={style.AuctionForm}>
      <Loader open={loader} /> 
      <Dialog
        closable={false}
        visible={resumeone}
        className="p-0 m-0"
        onHide={() => setResumeone(false)}
        style={{ width: "50vw", padding: "0px", margin: "0px" , border:"3px solid #187C8B"}}
        // border: 3px solid #187C8B
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <form onSubmit={handleSubmit}>
          <div>
            <div className={`d-flex mb-4 justify-content-center`}>
              <div className={`lufga_20px`}>Add Feature</div>
            </div>

            <div className={`row`}>
              <div className={`col-12`}>
                <div className={`lufga_18px `}>Feature Name</div>
                <div>
                  <input
                    required
                    onChange={handleChnage}
                    className={`lufga_16px form-control`}
                    name="feature_name"
                    value={userData.feature_name}
                    type="text"
                    placeholder=""
                  />
                </div>
              </div>
            </div>

            <div className="d-flex mt-5 justify-content-end gap-4">
              <button
                type="button"
                className={style.cancelBtn}
                onClick={() => setResumeone(false)}
              >
                Cancel
              </button>

              <button type="submit" className={`theme_button lufga_16px`}>Add Feature</button>
            </div>
          </div>
        </form>
      </Dialog>
    </div>
  );
};

export default AddFeatures;
