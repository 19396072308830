import React, { useEffect, useState } from 'react'
import style from './Appoint.module.css'
import { Dialog } from 'primereact/dialog';
import { addAppointment } from '../../../../Api/IvfClinic';
import Loader from '../../../common/Loader';
import { useDispatch, useSelector } from "react-redux";
import PhoneInputComponent from '../../../commonComponents/PhoneInput/PhoneInput';

const Appoint = ({ apoint, setApoint, reload }) => {

    const MemberId=sessionStorage.getItem("ClinicId")
    const selector = useSelector(e => e.header)
 

    const [data, setData] =
    useState(
    {
        clinicId:MemberId,
        name: "",
        appointment_title: "",
        contact_number: "",
        email_address: "",
        client_type: "",
        service_requested: "",
        date: "",
        location: "",
        important_note: "",
        start_time: "",
        end_time: ""

    })

    const [loader, setLoader] = useState(false)

    const addAppointmentdata = async (e) => {
        e.preventDefault();
        try {
            setLoader(true);
            console.log(data,"this is data")
            await addAppointment(data);
            await reload();
            setApoint(false);
            setData({
                name: "",
                appointment_title: "",
                contact_number: "",
                email_address: "",
                appointment_purpose: "",
                client_type: "",
                service_requested: "",
                date: "",
                location: "",
                important_note: "",
                start_time: "",
                end_time: ""
            });
            setLoader(false);
        } catch (err) {
            console.log(err);
            setData({
                name: "",
                appointment_title: "",
                contact_number: "",
                email_address: "",
                appointment_purpose: "",
                client_type: "",
                service_requested: "",
                date: "",
                location: "",
                important_note: "",
                start_time: "",
                end_time: ""
            });
            setApoint(false);
            setLoader(false);
        }
    };

    useEffect(() => {
        // Update the clinicId in the data state whenever the apoint state changes
        if (apoint) {
            setData(prevData => ({
                ...prevData,
                clinicId: MemberId
            }));
        }
    }, [apoint, MemberId]);


    return (
        <div>
            <Loader open={loader}/>
            <Dialog
                closable={false}
                visible={apoint}
                className='p-0 m-0'
                onHide={() => setApoint(false)}
                style={{ width: "65vw", padding: "0px", margin: "0px" }}
                breakpoints={{ "1400px": "90vw", "641px": "100vw" }}
            >
                <div className='Verfied_con'>

                    <div className='d-flex justify-content-center '>
                        <span className={style.verfideText}>New Appointment</span>

                    </div>

                    <form onSubmit={addAppointmentdata}>
                        <div className="row mt-4">
                            <div className="col-md-3">

                                <label className={`form-check-label mb-1 ${style.LabelText}`} htmlFor="flexCheckDefault">Appointment Title<span className='text-danger'>*</span></label>
                                <input
                                    required
                                    type="text"
                                    className="form-control"
                                    placeholder="Appointment Title"
                                    name="appointment_title"
                                    onChange={e => setData({ ...data, appointment_title: e.target.value })}
                                />
                            </div>

                            <div className="col-md-3">

                                <label className={`form-check-label mb-1 ${style.LabelText}`} htmlFor="flexCheckDefault">Name<span className='text-danger'>*</span></label>
                                <input
                                    required
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Your Name"
                                    name="name"
                                    onChange={e => setData({ ...data, name: e.target.value })}
                                />
                            </div>


                            <div className="col-md-3">
                                <label className={`form-check-label mb-1 ${style.LabelText}`} htmlFor="flexCheckDefault">Email Address<span className='text-danger'>*</span></label>
                                <input
                                    required
                                    type="email"
                                    className="form-control"
                                    placeholder="Enter Your Email Address"
                                    name="email_address"
                                    onChange={e => setData({ ...data, email_address: e.target.value })}
                                />
                            </div>

                            <div className="col-md-3">
                                <label className={`form-check-label mb-1 ${style.LabelText}`} htmlFor="flexCheckDefault">Contact Number<span className='text-danger'>*</span></label>

                                
                                <PhoneInputComponent
                                    value={data.contact_number}
                                    onChange={e => {
                                        const { name, value } = e.target;
                                        setData({ ...data, [name]: value })
                                    }}
                                    name="contact_number"
                                    required={true}
                                    disabled={false}
                                    placeholder="Enter Your Contact Number"
                                />

                              
                            </div>
                        </div>


                        <div className="row mt-3">

                            <div className="col-md-4">
                                <label className={`form-check-label mb-1 ${style.LabelText}`} htmlFor="flexCheckDefault">Client Type<span className='text-danger'>*</span></label>
                                <input
                                    required
                                    type="text"
                                    className="form-control"
                                    placeholder="Client Type"
                                    name="client_type"
                                    onChange={e => setData({ ...data, client_type: e.target.value })}
                                />
                            </div>


                            <div className="col-md-4">
                                <label className={`form-check-label mb-1 ${style.LabelText}`} htmlFor="flexCheckDefault">Service Requested<span className='text-danger'>*</span></label>
                                <input
                                    required
                                    type="text"
                                    className="form-control"
                                    placeholder="Service Requested"
                                    name="service_requested"
                                    onChange={e => setData({ ...data, service_requested: e.target.value })}
                                />
                            </div>

                            <div className="col-md-4">
                                <label className={`form-check-label mb-1 ${style.LabelText}`} htmlFor="flexCheckDefault">Date<span className='text-danger'>*</span></label>
                                <input
                                    required
                                    type="date"
                                    className="form-control"
                                    placeholder="Date"
                                    name="date"
                                    onChange={e => setData({ ...data, date: e.target.value })}
                                />

                            </div>
                        </div>

                        <div className="row mt-3">

                            <div className="col-md-3">

                                <label className={`form-check-label mb-1 ${style.LabelText}`} htmlFor="flexCheckDefault">Start Time<span className='text-danger'>*</span></label>
                                <input
                                    required
                                    type="time"
                                    className="form-control"
                                    placeholder=""
                                    name="start_time"
                                    onChange={e => setData({ ...data, start_time: e.target.value })}
                                />
                            </div>

                            <div className="col-md-3">

                                <label className={`form-check-label mb-1 ${style.LabelText}`} htmlFor="flexCheckDefault">End time<span className='text-danger'>*</span></label>
                                <input
                                    required
                                    type="time"
                                    className="form-control"
                                    placeholder=""
                                    name="end_time"
                                    onChange={e => setData({ ...data, end_time: e.target.value })}
                                />
                            </div>

                            <div className="col-md-6">

                                <label className={`form-check-label mb-1 ${style.LabelText}`} htmlFor="flexCheckDefault">Appointment Purpose<span className='text-danger'>*</span></label>
                                <input
                                    required
                                    type="text"
                                    className="form-control"
                                    placeholder="Appointment Purpose" 
                                    name='appointment_purpose'
                                    onChange={e=>setData({...data,appointment_purpose:e.target.value})}
                                    />
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-md-12">
                                <label className={`form-check-label mb-1 ${style.LabelText}`} htmlFor="flexCheckDefault">Location</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Your Location"
                                    name='location'
                                    onChange={e => setData({ ...data, location: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className="row mt-3 mb-4">
                            <div className="col-md-12">
                                <label className={`form-check-label mb-1 ${style.LabelText}`} htmlFor="flexCheckDefault">Important Note</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Important Note"
                                    name='important_note'
                                    onChange={e => setData({ ...data, important_note: e.target.value })}
                                />
                            </div>
                        </div>

                        <div className='d-flex justify-content-end gap-3'>
                            <button className={style.CencelButton} type="button" onClick={() => setApoint(false)}>Cancel</button>
                            <button className={style.SubmitButton} type="submit">Submit</button>
                        </div>
                    </form>
                </div>
            </Dialog>
        </div>
    )
}

export default Appoint