import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import "./common.css";
import { Alert, Snackbar } from '@mui/material';
import { callSnackBar } from '../../Store/Slices/ToastSlice';

export const CustomToast = () => {


  let show = useSelector(e => e.toast)
  let pos = {
    vertical: "top",
    horizontal: "right",
  }
  let dispatch = useDispatch();

  function closeSnackbar() {

    dispatch(callSnackBar({ type: "", msg: "", show: false }))

  }
  let {vertical,horizontal}=pos

  return (
    <Snackbar
      sx={{ zIndex: "999999999999999999999999" }}
      anchorOrigin={{ vertical, horizontal }}
      autoHideDuration={3000}
      open={show.show}
      key={"vertical" + "horizontal"}
      onClose={closeSnackbar}
    >
      <Alert
        severity={show.type}
        sx={{ width: "100%" }}
        onClose={closeSnackbar}
      >
        {show.msg}
      </Alert>
    </Snackbar>
  );
};
