// import { getAuth } from "firebase/auth";
// import { initializeApp } from "firebase/app";
// import { getStorage } from "firebase/storage";
// import { getFirestore } from "firebase/firestore";

// const firebaseConfig = {
//   apiKey: "AIzaSyCAk4x3pgH1whZr6ESiwPaVMvLOIBNFIP4",
//   authDomain: "dating-app-f2931.firebaseapp.com",
//   projectId: "dating-app-f2931",
//   storageBucket: "dating-app-f2931.appspot.com",
//   messagingSenderId: "306418358106",
//   appId: "1:306418358106:web:98a9d51433ea29de908563",
//   measurementId: "G-S081Y1RPTF"
// };

// const app = initializeApp(firebaseConfig);

// export const storage = getStorage(app);
// export const db = getFirestore(app);
// export const auth = getAuth(app);



// firebase.jsx
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";


  // your firebase configuration
  const firebaseConfig = {
    apiKey: "AIzaSyB7AlJeiLg5akHciOzD47431WtXQcElBR4",
    authDomain: "ivf-project-65157.firebaseapp.com",
    projectId: "ivf-project-65157",
    storageBucket: "ivf-project-65157.appspot.com",
    messagingSenderId: "514863985904",
    appId: "1:514863985904:web:f0017d4d1e7bd3245dbe35"
  };

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);









