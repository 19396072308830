import { Block } from "@mui/icons-material";
import React, { useEffect } from "react";
import style from './ActiveDelete.module.css'
import { useState } from "react";
import { activeInactive, deleteUser } from "../../../Api/admin";
import { useNavigate } from "react-router";

const ActiveDelete = ({id, user_blocked, target}) => {
    const navigate = useNavigate()
    const [IsShown, setIsShown] = useState(false);
    const [IsActive, setIsActive] = useState(true);
    console.log(user_blocked)
    const handleShow = () => {
        setIsShown(!IsShown);
    };


    useEffect(()=>{
        setIsActive(!user_blocked)
    },[user_blocked])

    const handleActive = () => {
        setIsActive(!IsActive);
        setIsShown(!IsShown);
    };

    const handleActiveInactive = async(data)=>{
        const result = await activeInactive(id,{user_blocked:data})
        console.log(result)
    }

    const handleDelete = async()=>{

        const result = await deleteUser(id,{isDelete:true})
        console.log(result)
        navigate(`/superadmin/manageaccounts/${target}`)
    }

    return (
        <div>
            <div className={`d-flex lufga_16px ${style.main_contianer}`}>
                <div className={`d-flex flex-column gap-1`}>
                    <div onClick={handleShow}>

                        <div className={`lufga_16px ${IsActive ? style.active : style.block}`}>
                            {IsActive ?
                                <div>Active</div>
                                :
                                <div>Blocked</div>
                            }

                            {IsShown ?
                                <div><i className={`pi pi-angle-up ${style.icons_margin}`}></i></div>
                                :
                                <div>
                                    <i className={`pi pi-angle-down ${style.icons_margin}`}></i>
                                </div>
                            }
                        </div>

                    </div>

                    <div style={{ display: IsShown ? "block" : "none" }}>

                        <div className={`lufga_16px ${!IsActive ? style.active : style.block}`}
                            onClick={handleActive}>
                            {!IsActive
                                ? <div onClick={()=>handleActiveInactive(false)}>Activate Account</div>
                                : <div onClick={()=>handleActiveInactive(true)}>Block Account</div>
                            }
                        </div>

                    </div>
                </div>

                {/* <div>
                    <button className={`no_style_button lufga_16px ${style.delete_button} ` } onClick={handleDelete} style={{ color: "red" }}>
                        Delete Account
                    </button>
                </div> */}
            </div>
        </div>
    );
};

export default ActiveDelete;
