import React, { useState } from "react";
import style from "./AuctionList.module.css";
import { Pagination } from "reactstrap";
import { TableContainer } from "../../../common/TableContainer";
import { InputText } from "primereact/inputtext";
import { useNavigate } from "react-router";

const ClientTable = ({ data, role }) => {
  const navigate = useNavigate();

  const roleToRoute = {
    Surrogacy: "userprofile/surrogate",
    IntendedParent: "/intendedParent/myprofile",
    IvfClinic: "/ivfclinic/myprofile",
    Lawyer: "/lawyer/myprofile",
  };

  // Function to handle the click event
  const handleViewClick = (userRole) => {
    const route = roleToRoute[userRole];
    if (route) {
      navigate(route);
    } else {
      console.error("No route defined for this role");
    }
  };

  

  return (
    <div className={style.ClientTable}>
      <TableContainer className={"table_container"}>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>User Role</th>
              <th>User ID</th>
              <th>Winning Bid</th>
              <th>Winning Bidder</th>
              <th>Date Range</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td className="d-flex align-items-center">
                  <img
                    src={item?.photoUrl || `/images/blankProfile.png`}
                    alt="user img"
                    className={` me-1 ${style.ProfileImages}`}
                    width="50px"
                  />
                  <span className="ms-2">{item?.name}</span>
                </td>
                <td>
                  {item?.role && item?.role == "surrogate_donor" ? (
                    <div>Surrogate Mother</div>
                  ) : (
                    item?.role || <div>Surrogate Mother</div>
                  )}
                </td>
                <td>{item?.memberId}</td>
                <td>
                  {/* {item?.buyer?.name ? (
                    <>item?.buyer?.buyer_name</>
                  ) : (
                    <>
                      {item?.highest_Bid?.bid !== 0 ?
                        item?.highest_Bid?.bid
                        :
                        item.starting_bid_amount}
                    </>
                  )} */}

                  {item?.buyer?.price ? (
                    <>{item.buyer.price}</>
                  ) : item?.highest_Bid?.bid !== 0 ? (
                    item?.highest_Bid?.bid
                  ) : (
                    item.starting_bid_amount
                  )}
                </td>
                <td>
                  {/* {item?.buyer?.price ? (
                    <>item?.buyer?.price</>
                  ) : (
                    <>
                      {item?.highest_Bid?.bid !== 0 ? item?.highest_Bid?.bid : item.starting_bid_amount}
                    </>
                  )} */}

                  {item?.buyer?.buyer_name ? (
                    <>{item.buyer.buyer_name}</>
                  ) : item?.highest_Bid?.bid !== 0 ? (
                    item?.highest_Bid?.biderId[0]?.fullName
                  ) : (
                    <div>No winning Bidder</div>
                  )}

                </td>
                <td>
                  {new Date(item?.startDate).toLocaleDateString("en-GB") +
                    " - " +
                    new Date(item?.endDate).toLocaleDateString("en-GB")}
                </td>
                <td>
                  <button
                    className={style.ViewButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(
                        `/superadmin/manage_auction/auctionResume/${item._id}`,
                        { state: { data: item.highest_Bid } }
                      );
                    }}
                  >
                    <i className="bi bi-eye d-flex align-items-center me-1"></i>{" "}
                    View
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </TableContainer>
    </div>
  );
};

export default ClientTable;
