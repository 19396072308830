import React from 'react'
import style from './ScanCards.module.css'

import { useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";
import { useState } from "react";
import { FaArrowTrendDown } from "react-icons/fa6";
import { FaArrowTrendUp } from "react-icons/fa6";



const ScanCards = ({ cardObject }) => {


  let { t } = useTranslation();

  return (
    <div>

      <div className='contianer-fluid'>
        <div className={`${style.upper_card_section}`}>




          <div className="mt-2 justify-content-left d-flex gap-4">
            {cardObject?.map((card, index) => (
              <div
                key={index}
              >
                <div className={`${style.card_wrapper} h-100`}>
                  <Link
                    style={{ height: '100%' }}
                    // to={card.isThirdPartyUrl ? card.source : "internalnews"}
                    // target={card.isThirdPartyUrl ? '_blank' : '_self'}
                    className="d-flex"
                  >

                    <div className={`${style.card_body} `}>
                      <div className={`${style.card_upper}`}>
                        <div className={`${style.upper_left}`}>
                          <div className={`${style.left_upper} lufga_16px`}>
                            {t(card.title)}
                          </div>
                          <div className={`${style.left_lower}`}>
                            {t(card.main_content)}
                          </div>
                        </div>
                        {card.icon !== "" ?
                          <div className={`${style.upper_right}`}>
                            <img
                              className={`${style.card_img_top}`}
                              src={`/images/${card.icon}`}
                              alt={t("Card image cap")}
                            />
                          </div>
                          : null
                        }
                      </div>


                      <div className={`${style.card_lower} lufga_16px`}>
                        <div className={`${style.lower_left}`}>
                          {card.graph === true ?
                            <div style={{ color: "green", display: "flex", alignItems: "center" }}>
                              <FaArrowTrendUp />
                            </div>
                            : card.graph === false ?
                              <div style={{ color: "red", display: "flex", alignItems: "center" }}>
                                <FaArrowTrendDown />
                              </div>
                              : null // Or any other fallback you want for cases where graph is neither "true" nor "false"
                          }
                        </div>
                        <div className={`${style.lower_middle}`}>
                          {card.graph === true ?
                            <div style={{ color: "green", display: "flex", alignItems: "center" }}>
                              {t(card.percent)}
                            </div>
                            : card.graph === false ?
                              <div style={{ color: "red", display: "flex", alignItems: "center" }}>
                                {t(card.percent)}
                              </div>
                              : <div style={{ color: "#187C8B", display: "flex", alignItems: "center" }}>
                                {t(card.percent)}
                              </div>
                          }

                        </div>
                        <div className={`${style.lower_right}`}>

                          {card.graph === true ?
                            <div>
                              {t(card.information_type)}
                            </div>
                            : card.graph === false ?
                              <div>
                                {t(card.information_type)}
                              </div>
                              : <div style={{ color: "#187C8B", display: "flex", alignItems: "center" }}>
                                {/* {t(card.information_type)} */}
                              </div>
                          }

                        </div>
                      </div>
                    </div>


                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>

      </div>


    </div >
  )
}
export default ScanCards;