import React, { useState } from 'react'
import style from './Client.module.css'
import { Pagination } from 'reactstrap'
import { TableContainer } from '../../../../../common/TableContainer'
import { InputText } from 'primereact/inputtext'
import { useNavigate } from 'react-router'
import { deleteUser } from '../../../../../../Api/admin'
import { ConfirmDelete } from '../../../../../common/ConfirmDelete'
import { RiDeleteBinLine } from 'react-icons/ri'
import Loader from '../../../../../common/Loader'
import moment from 'moment'


const ClientTable = ({ data, update, role }) => {

    const navigate = useNavigate()
    const [loader, setLoader] = useState(false)


    const roleToRoute = {
        'Surrogacy': '/surrogate/myprofile',
        'IntendedParent': '/intendedParent/myprofile',
        'IvfClinic': '/ivfclinic/myprofile',
        'Lawyer': '/lawyer/myprofile',
    };

    const deleteuser = async (obj) => {
        setLoader(true);
        await deleteUser(obj, { isDelete: true })
            .then((res) => {
                update();
                setLoader(false);

            })
            .catch((res) => {
                console.log(res);
            })
            .finally(() => {
                setLoader(false)
            })
    };


    // Function to handle the click event
    const handleViewClick = (id) => {
        // const route = roleToRoute[userRole];
        // if (route) {
        //     navigate(route);
        // } else {
        //     console.error('No route defined for this role');
        // }

        navigate(`ivfuserprofile/${id}`)
    };

    return (
        <><Loader open={loader} />
            <div className={style.ClientTable}>


                <TableContainer className={"table_container"}>
                    <table>



                        <thead>
                            <tr>

                                <th>Clinic Name</th>
                                <th>User ID</th>
                                <th>Location</th>
                                <th>Account Created</th>
                                <th></th>
                            </tr>


                        </thead>


                        <tbody>
                            {data.length > 0 ? data.map((res, index) => (


                                <tr key={index}>
                                    <td style={res.user_blocked === true ? { "opacity": '0.53' } : {}} className='d-flex align-items-center'>
                                        <span className={` me-3  ${style.ProfileImages} ${res.user_blocked === true ? style.block_style : ''}
                    `}>
                                           <img src={res?.imageUrl || '/images/ivfclinichospitalimage.png'} alt="jjmage"
                                            className={` me-3  ${style.ProfileImages}`} />
                                        </span>
                                        {res.fullName}

                                    </td>
                                    <td style={res.user_blocked === true ? { "opacity": '0.53' } : {}}>{res.memberId}</td>
                                    <td style={res.user_blocked === true ? { "opacity": '0.53' } : {}}>{`${res.city}, ${res.state}, ${res.country}`}</td>
                                    <td style={res.user_blocked === true ? { "opacity": '0.53' } : {}}>{res.createdAt ? moment(res.createdAt).format("DD/MM/YYYY") : "-N/A-"}</td>

                                    <td className="d-flex align-items-center gap-3">
                                        <button
                                            className={style.ViewButton}
                                            onClick={() => handleViewClick(res._id)}
                                        >
                                            <i className="bi bi-eye d-flex align-items-center "></i>{" "}
                                            View
                                        </button>
                                        <ConfirmDelete onDelete={deleteuser} data={res._id} header="Delete Clinic Account" deleteMsg="Are you sure you want to delete Ivf clinic account">
                                            <button
                                                className={`d-flex align-items-center gap-1 ${style.ViewButton}`}

                                            >
                                                <RiDeleteBinLine className="text-danger" />
                                                Delete
                                            </button>
                                        </ConfirmDelete>

                                    </td>


                                </tr>

                            )) : (
                                <tr style={{height:"60vh"}}>
                                  <td colSpan="8" className="text-center">
                                    <h1>No data available</h1>
                                  </td>
                                </tr>
                              ) }
                        </tbody>

                    </table>
                </TableContainer>

            </div>
        </>
    )
}

export default ClientTable