import React, { useEffect, useRef, useState } from 'react'
import style from './EditDoctor.module.css'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router'
import { getDoctorByIdApi, updateDoctorByIdApi } from '../../../../Api/IvfClinic'
import { uploadDocument } from "../../../../Api/CommonApis"
import { CiImageOn } from 'react-icons/ci'
import Loader from '../../../common/Loader'
import { Country, State, City } from "country-state-city";
import { changeHeaderData } from '../../../../Store/Slices/headerDataSlice'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import BackButton from '../../../commonComponents/BackButton/BackButton'
import PhoneInputComponent from '../../../commonComponents/PhoneInput/PhoneInput'



const EditDoctor = () => {
    let { t } = useTranslation()
    const navigate = useNavigate()
    const [loader, setLoader] = useState(true)
    const [editable, setEditable] = useState(true)
    const countries = Country.getAllCountries()
    const [country, setCountry] = useState('')
    const [state, setState] = useState('')
    const [city, setCity] = useState('')
    const [statesListing, setStatesListing] = useState([])
    const [citiesListing, setCitiesListing] = useState([])
    const [img, setImg] = useState(null)
    const [defaultImg, setDefaultimg] = useState('/images/ivfclinichospitalimage.png')
    const dispatch = useDispatch()

    const [photoIndex, setPhotoIndex] = useState(0);

    const [upload, setUpload] = useState(false);

    const params = useParams();
    const userId = params.id;


    const location = useLocation();
    const callState = location.state?.call || '';

    console.log("location", location)

    const [userData, setUserData] = useState({
        "_id": "",
        "clinic_id": "",
        "firstName": "",
        "total_experience": "",
        "age": 0,
        "date_of_birth": "",
        "short_descrip": "",
        "email_address": "",
        "mobile_number": "",
        "current_address": "",
        "state_of_residence": "",
        "city": "",
        "country": "",
        "zip_code": "",
        "image_file": {
            "file_name": "",
            "file_url": ""
        },
    })


    async function getUserData() {
        try {
            console.log("userid", userId)
            let res = await getDoctorByIdApi(userId)
            res.data.data.date_of_birth = moment(res.data.data.date_of_birth).format("YYYY-MM-DD")
            setUserData(pre => { return { ...pre, ...res.data.data } })
            console.log("res", res)

            if (res.data.data.country !== undefined) {
                let cRes = countries?.find(res2 => res2?.name == res.data.data.country)
                setCountry(`${cRes.name}&&${cRes.isoCode}`)
                getStates(`${cRes.name}&&${cRes.isoCode}`)
            }
            if (res.data.data.city !== undefined) {
                setCity(res.data.data.city)
            }
            setLoader(false)
            return res;
        } catch (err) {
            console.log(err)
            setLoader(false)
        }
    }



    useEffect(() => {
        if (statesListing.length !== 0) {
            let sRes = statesListing?.find(res2 => res2?.name == userData.state_of_residence)
            if (sRes !== undefined) {
                setState(`${sRes.name}&&${sRes.isoCode}&&${sRes.countryCode}`)
                getCities(`${sRes.name}&&${sRes.isoCode}&&${sRes.countryCode}`)
            }
        }

    }, [statesListing])


    function getImgPreview(file) {
        let url = URL.createObjectURL(file)
        return url
    }

    async function editProfileData(e) {

        console.log("this is the console while submit ")
        e.preventDefault()

        if (userData.image_file.imageUrl == '' && img == null) {
            alert('Please select profile image')
            return
        }

        let payload = {
            ...userData,
            // textSMS_mobile: JSON.parse(userData.textSMS_mobile),
            // textSMS_mobile_alter: JSON.parse(userData.textSMS_mobile_alter),
            city: city.split('&&')[0],
            state_of_residence: state.split('&&')[0],
            country: country.split('&&')[0],
        }

        let formdata = new FormData()
        if (img !== null) {
            formdata.append('uploadDoc', img)
        }

        setLoader(true)
        try {
            if (img !== null) {
                let imageData = await uploadDocument(formdata)
                payload.image_file.file_name = imageData.data.fileName
                payload.image_file.file_url = imageData.data.fileLocation
            }

            console.log(payload, "this is payload")

            let res = await updateDoctorByIdApi(userId, payload)

            setImg(null)

            let responseForData = await getUserData()
            console.log("response forn data", responseForData)

            setLoader(false)


        } catch (err) {
            setLoader(false)
            console.log(err)
        }
        finally {
            navigate(-1)
        }

    }


    function getStates(res) {
        let isoCode = res.split('&&')[1]
        setCountry(res)
        let states = State.getStatesOfCountry(isoCode)
        setStatesListing(states)
    }

    function getCities(res) {
        let isoCode = res.split('&&')[1]
        let cCode = res.split('&&')[2]
        setState(res)
        let cities = City.getCitiesOfState(cCode, isoCode)
        setCitiesListing(cities)
    }

    function onInputChange(e) {
        setUserData(pre => {
            return { ...pre, [e.target.name]: e.target.value }
        })
    }


    const todayDate = moment().format("YYYY-MM-DD");

    function ageCalculate(res) {
        const dob = moment(res);
        const now = moment();
        const age = now.diff(dob, "years");
        userData((pre) => {
            return { ...pre, age: age };
        });
    }

    useEffect(() => {
        getUserData();
    }, []);

    useEffect(() => {
        if (callState === "edit") {
            setEditable(false);
        } else {
            setEditable(true);
        }
    }, [callState]);

    return (

        <>  <Loader open={loader} />
            <BackButton path={-1} text="Back" />
            <div className={style.MyProfile}>

                <form onSubmit={editProfileData}>


                    <div className={`mb-5 ${style.bannersize} px-3`}>

                        <div className="d-flex gap-3 align-items-center px-3">
                            {editable ? (
                                <>
                                    <div className={`${style.ProfileImg1}`}>

                                        <img
                                            src=
                                            {userData?.image_file?.file_url !== ''
                                                ? userData?.image_file?.file_url
                                                :
                                                defaultImg
                                            }
                                            className='w-100 h-100'
                                            alt="ivf Clinic"
                                        />
                                    </div>

                                </>
                            ) : (
                                <>

                                    <label className={`${style.ProfileImg1} pointer border`} >

                                        <div className={`${style.inputIcon}`}>
                                            <CiImageOn />
                                        </div>

                                        <img
                                            src={userData?.image_file?.file_url !== '' && img == null
                                                ? userData?.image_file?.file_url
                                                :
                                                defaultImg === '/images/ivfclinichospitalimage.png'
                                                    ? defaultImg
                                                    :
                                                    getImgPreview(defaultImg)}
                                            htmlFor='imgInp'
                                            className='w-100 h-100'
                                            alt=""
                                        />

                                        <input
                                            type='file'
                                            hidden
                                            id='imgInp'
                                            onChange={(e) => {
                                                setDefaultimg(e.target.files[0])
                                                setImg(e.target.files[0])
                                            }} />

                                    </label>

                                </>
                            )}


                            <div className="d-flex flex-column align-item-center justify-content-center gap-3">
                                <div className={style.BnnerFirstText}>{userData.memberId} : Clinic Doctor</div>
                                <div className={style.BnnersecondtText}>{userData.firstName}</div>

                            </div>

                        </div>

                        <div className={`p-relative ${style.ProfileImg} d-flex align-items-center`}>
                            <img src="/images/ivf_clinic_doctor_view_right_pic.png" className={`w-100`} alt="" />
                            {/* {editable ? <div className={`d-flex justify-content-end ${style.ChlidButton}`}>
                                <button className={`text-center d-flex align-items-center ${style.VerfiedButton}`} onClick={() => setEditable(false)}><i class="bi bi-pencil-square me-1 d-flex align-items-center"></i>
                                    <span className={style.verfiedText} >Edit Details</span>
                                </button>
                            </div> : ''} */}
                        </div>

                    </div>

                    <div className="row">
                        <div className="col mt-3">

                            <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`}>
                                {t("First Name")}
                                <span className='text-danger'>
                                    *
                                </span>
                            </label>
                            <input
                                type="text"
                                disabled={true}
                                className={`form-control`}
                                placeholder="Enter Your First Name"
                                value={userData?.firstName}
                                name='firstName' onChange={onInputChange}
                            />

                        </div>



                        <div className="col-md-6 mt-3">

                            <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`}>
                                {t("Total Experience")}
                                <span className='text-danger'>*</span>
                            </label>
                            <input
                                type="text"
                                className={`form-control`}
                                disabled={editable}
                                name='total_experience'
                                value={userData?.total_experience}
                                placeholder="Enter Your Total Experience"
                                onChange={onInputChange}
                            />
                        </div>



                        <div className="col-md-6 mt-3">

                            <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`}>
                                {t("Age")}
                                <span className='text-danger'>*</span>
                            </label>
                            <input
                                type="number"
                                className={`form-control`}
                                disabled={true}
                                name='age'
                                value={userData?.age}
                                placeholder="" />

                        </div>

                        <div className="col-md-6 mt-3">

                            <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`}>
                                {t("Date Of Birth")}
                                <span className='text-danger'>*</span>
                            </label>
                            <input
                                type="date"
                                className={`form-control`}
                                disabled={true}
                                value={userData?.date_of_birth}
                                placeholder=""
                                max={todayDate}
                                onChange={(e) => {
                                    onInputChange(e);
                                    ageCalculate(e.target.value);
                                }}
                            />

                        </div>
                        <div className="col-md-6 mt-3">

                            <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`}>
                                {t("Email Address")}
                                <span className='text-danger'>*</span>
                            </label>
                            <input
                                type="email"
                                className={`form-control`}
                                disabled={true}
                                value={userData?.email_address}
                                placeholder="" />

                        </div>



                        <div className="col-md-6 mt-3">

                            <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`}>
                                {t("Mobile Number")}
                                <span className='text-danger'>*</span>
                            </label>

                          

                            <PhoneInputComponent
                                value={userData.mobile_number}
                                onChange={e => {
                                    const { name, value } = e.target;
                                    setUserData({ ...userData, [name]: value })
                                }}
                                placeholder="Enter Mobile Number"
                                required
                                disabled={true}
                                name="mobile_number"
                            />


                        </div>


                        <div className="col-md-12 mt-3">

                            <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`}>
                                {t("Current Address")}
                                <span className='text-danger'>*</span>
                            </label>
                            <input
                                type="text"
                                className={`form-control`}
                                placeholder="" required
                                disabled={editable}
                                value={userData?.current_address}
                                name='current_address'
                                onChange={onInputChange} />

                        </div>

                        <div className="col-md-12 mt-3">

                            <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`}>
                                {t("Description")}
                                <span className='text-danger'>*</span>
                            </label>
                            <input
                                type="text"
                                className={`form-control`}
                                placeholder=""
                                required
                                disabled={editable}
                                value={userData?.short_descrip}
                                name='short_descrip'
                                onChange={onInputChange} />

                        </div>

                    </div>

                    <div className="row mt-3">
                        <div className="col-md-3">

                            <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`}>
                                {t("Country")}
                                <span className='text-danger'>*</span>
                            </label>

                            <select
                                className={`form-select`}
                                value={country}
                                required
                                disabled={editable}
                                onChange={(e) => {
                                    getStates(e.target.value)
                                    setState('')
                                    setCity('')
                                }}>
                                <option selected disabled value={''}>{t("select")}</option>

                                {countries.map((res, index) => {
                                    return <option key={index} value={`${res.name}&&${res.isoCode}`}>{res.name}</option>
                                })}

                            </select>

                        </div>
                        <div className="col-md-3">

                            <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`}>
                                {t("State of Residence")}
                            </label>
                            <select
                                className={`form-select`}
                                value={state}
                                disabled={editable}
                                onChange={(e) => {
                                    getCities(e.target.value)
                                    setCity('')
                                }} >
                                <option selected disabled value={''}>{t("select")}</option>

                                {statesListing?.map((res, index) => {
                                    return <option key={index} value={`${res.name}&&${res.isoCode}&&${res.countryCode}`}>{res.name}</option>
                                })}

                            </select>



                        </div>
                        <div className="col-md-3">

                            <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`}>
                                {t("City")}
                            </label>
                            <select
                                className={`form-select`}
                                value={city}
                                disabled={editable}
                                onChange={(e) => setCity(e.target.value)}
                            >
                                <option selected disabled value={''}>{t("select")}</option>

                                {citiesListing?.map((res, index) => {
                                    return <option key={index} value={`${res.name}`}>{res.name}</option>
                                })}

                            </select>


                        </div>

                        <div className="col-md-3">

                            <label htmlFor="exampleInputEmail1" className={`form-label ${style.LabelHeading}`} >
                                {t("Zip Code")}<span className='text-danger'>*</span>
                            </label>
                            <input
                                type="number"
                                className={`form-control`}
                                required placeholder=""
                                disabled={editable}
                                value={userData?.zip_code}
                                name='zip_code'
                                onChange={onInputChange}
                            />

                        </div>
                    </div>

                    <div className='col-md-12'>

                        {editable ? '' : <div className='d-flex justify-content-end gap-4 mt-5'>
                            <button type='button' onClick={() => {
                                setEditable(true);
                                navigate(-1)
                                getUserData();
                            }
                            } className={style.CancelButton}>Cancel</button>
                            <button type='submit' className={style.SaveButton}>Save Changes</button>
                        </div>}
                    </div>


                </form>
            </div>
        </>
    )
}

export default EditDoctor
