import React, { useState, useEffect } from "react";
import ClientTable from "../MyClientTable/LawyerClientTable";
import style from "./LawyerMyClient.module.css";
import { InputText } from "primereact/inputtext";
import { getClientsOflawyer } from "../../../Api/Lawyer";
import Loader from "../../common/Loader";
import { useDispatch, useSelector } from "react-redux";

const LawyerMyClient = () => {
  const MemberId=sessionStorage.getItem("LawyerId")
  const selector = useSelector(e => e.header)
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [filterData,setFilterData]= useState([]);


  const handleSearchClick = (e) => {
    let val = e.trim().toLowerCase();
    let searchName = filterData.filter((res) => {
      const fullName = (res?.clientId?.trim().toLowerCase().replace(/\s+/g, '') || '') +
        (res?.name?.trim().toLowerCase().replace(/\s+/g, '') || '');
      return fullName.includes(val.replace(/\s+/g, ''));
    });
    setData(searchName);
  };


  const getClientsFunction = async () => {
    try {
      setLoader(true);
      const response = await getClientsOflawyer({ "lawyerId":MemberId });
      console.log(response, "clients response");
      setData(response.data.data);
      setFilterData(response.data.data)
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getClientsFunction();
  }, []);

  return (
    <>
      <Loader open={loader} />
      <div className={`${style.ClientTable}`}>
        <div className="d-flex justify-content-between mb-4 mt-3">
          <div>
            <span className="lufga_24px fw_600">My Clients</span>
          </div>

          <div className="d-flex align-items-center gap-2">
            <div className="searchInput w-100">
              <input
                className="form-control ps-5"
                placeholder="Search"
                onChange={(e) => handleSearchClick(e.target.value)}
              ></input>
              <i className="pi pi-search searchIcon"></i>
            </div>
          </div>

        </div>
        <ClientTable data={data} role="all_clients" update={getClientsFunction} />
      </div>
    </>
  );
};

export default LawyerMyClient;
