import axios from "axios"
import { baseUrl, dashboardService, services, subscription } from "./EndPoint"



let token = sessionStorage.getItem("authToken")

let config = {
    headers: {
        Authorization: `Bearer ${token}`,
    }
}
let userData= JSON.parse(sessionStorage.getItem("userData"))
let config2={};

if(sessionStorage.getItem("userData")){

     config2 = {
      headers: {
          Authorization: `Bearer ${userData?.token}`,
      }
    }
}



// dashbard services start 

export async function getDashboardUser(id){
    return await axios.get(`${baseUrl}${dashboardService.getUserById}${id}`,config)
}


export async function getDashboardUser2(id){
    return await axios.get(`${baseUrl}${dashboardService.getUserById}${id}`,config2)
}


export async function updateDashboardUser(id,data){
    return await axios.put(`${baseUrl}${dashboardService.updateUser}${id}`,data, config)
}


export async function changeUserPassword(id,data){
    return await axios.put(`${baseUrl}${services.changePassword}${id}`,data, config)
}

// dashbard services end


// services start 
export async function uploadDocument(data){
    return await axios.post(`${baseUrl}${services.uploadDoc}`,data)
}

export async function deleteDocsApi(data){
    return await axios.post(`${baseUrl}${services.deleteDoc}`,data)
}
// services end



//subscriptionplan apis start


export async function buySubscriptionPlan(id,data){
    return await axios.put(`${baseUrl}${subscription.buySubscriptionPlan}${id}`,data)
}

export const uploadMultipleFiles = async (files) => {
    return await axios.post(
      `${baseUrl}${services.uploadMultiDocs}`,
      files
    );
  };


//subscriptionplan apis end