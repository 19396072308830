import React from "react";
import { IoArrowBackOutline } from "react-icons/io5";
import style from "./BackButton.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

const BackButton = ({ text, path ,my }) => {
  const navigate = useNavigate();

  return (

    <div
      className={`d-flex align-items-center gap-2 btn-Secondary p-0 pointer w-50 ${my !== undefined ? `my-${my}` : 'my-3'} `}
      onClick={() => navigate(path)}
    >
      <FaArrowLeft /> {text}
    </div>
  );
};


export default BackButton;
