import React, { useEffect, useState } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import style from "./Setting.module.css";
import { InputSwitch } from "primereact/inputswitch";
import Loader from "../../common/Loader";
import {
  emailNotificationSurrogate,
  getDashBoardUseSurrogate,
  getSurrogateMotherData,
  pushNotificationSurrogate,
} from "../../../Api/SurrogateMotherApis";
import { useTranslation } from "react-i18next";
import { changeUserPassword } from "../../../Api/CommonApis";
import { IoIosArrowForward } from "react-icons/io";
import { pushNotificationData } from "../../../Api/SurrogateMotherApis";
import { useNavigate } from "react-router";
import { getFaqDatabyrole } from "../../../Api/admin";
import Toaster from "../../common/Toaster";
const Setting = () => {
  const [loader, setLoader] = useState(false);
  let userId = sessionStorage.getItem("userId");
  let { t } = useTranslation();
  const [passwordsMatch, setPasswordMatch] = useState(true);
  const [error, setError] = useState("");
  const [error2, setError2] = useState("");
  const [error3, setError3] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });

  const [toaster, setToaster] = useState({
    show: false,
    message: "",
  });
  const [faqData, setfaqData] = useState([]);
  // const [pustDataNotification,setPushDataNotification] = useState({
  //   auctionUpdates: false,
  //   latestNews: false,
  //   importantUpdate: false,
  //   promotionalNotifications: false
  // })
  const [pushNotification, setPushNotification] = useState({
    auctionUpdates: false,
    latestNews: false,
    importantUpdate: false,
    promotionalNotifications: false,
  });
  const [emailNotification, setEmailNotification] = useState({
    auctionUpdates: false,
    latestNews: false,
    importantUpdate: false,
    promotionalNotifications: false,
  });

  let navigator = useNavigate();

  // const pushData = async()=>{
  //   let payloadData ={
  //     auctionUpdates:pustDataNotification.auctionUpdates,
  //     latestNews:pustDataNotification.latestNews,
  //     importantUpdate:pustDataNotification.importantUpdate,
  //     promotionalNotifications:pustDataNotification.promotionalNotifications
  //   }
  //   await pushNotificationData(payloadData).then((res)=>{
  //     console.log(res)
  //   }).catch((err)=>{
  //     console.log(err)
  //   })
  // }
  const getDataFaqByRole = async () => {
    setLoader(true);
    await getFaqDatabyrole("ivf_clinic")
      .then((res) => {
        console.log(res);
        setLoader(false);
        setfaqData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };


  async function getUserData() {
    try {
      let res = await getDashBoardUseSurrogate(userId);
      if (res.data.data.pushNotification !== undefined) {
        setPushNotification((pre) => {
          return { ...pre, ...res.data.data.pushNotification };
        });
      }

      if (res.data.data.emailNotification !== undefined) {
        setEmailNotification((pre) => {
          return { ...pre, ...res.data.data.emailNotification };
        });
      }
      setLoader(false);
    } catch (err) {
      console.log(err);
      setLoader(false);
    }
  }

  async function inputChange(e, type) {
    if (type == "pushNotification") {
      let obj = { ...pushNotification, [e.target.name]: e.target.value };
      setPushNotification(obj);
      await pushNotificationSurrogate(userId, obj);
    } else if (type == "emailNotification") {
      let obj = { ...emailNotification, [e.target.name]: e.target.value };
      setEmailNotification(obj);
      await emailNotificationSurrogate(userId, obj);
    }
  }

  async function changePassword(e) {
    e.preventDefault();

    if (error !== "" || error2 !== "" || error3 !== "") {
      return;
    }

    if (passwordsMatch === false) {
      return;
    }

    setLoader(true);

    try {
      await changeUserPassword(userId, {
        newPassword: formData.password,
      });
    } catch (err) {
      console.log(err);
    } finally {
      setFormData({
        password: "",
        confirmPassword: "",
      });
      setToaster({
        show: true,
        message: "Your password has been changed successfully",
      });
      setLoader(false);
    }
  }

  function inpChange(e) {
    let { name, value } = e.target;

    if (name === "password") {
      if (value.trim() !== "") {
        if (
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+}{":;'?/><,.\[\]\\]).{8,}$/.test(
            value.trim()
          )
        ) {
          setError2("");
          setError("");
          setError3("");
        } else {
          if (value.length < 8) {
            setError2(t("Password must have at least 8 characters"));
          } else {
            setError2("");
          }

          if (!/[a-z]/.test(value) || !/[A-Z]/.test(value)) {
            setError(
              t("A mix of both uppercase and lowercase letters is required")
            );
          } else {
            setError("");
          }

          if (!/[!@#$%^&*()_+}{":;'?/><,.\[\]\\]/.test(value)) {
            setError3(
              t(
                "Inclusion of at least one special character (e.g., @ # ?) is required"
              )
            );
          } else {
            setError3("");
          }
        }
      } else {
        setError("");
        setError2("");
        setError3("");
      }
      if (formData?.confirmPassword !== "") {
        if (formData.confirmPassword === value) {
          setPasswordMatch(true);
        } else {
          setPasswordMatch(false);
        }
      }
    } else if (name === "confirmPassword") {
      if (formData.password === value) {
        setPasswordMatch(true);
      } else {
        setPasswordMatch(false);
      }
    }

    setFormData((prevState) => ({ ...prevState, [name]: value.trim() }));
  }

  useEffect(() => {
    getUserData();
    getDataFaqByRole();
  }, []);

  return (
    <div>
       <Toaster
            // event={"Please InActive Current Post then you Add new Post"}
            event={toaster.message}
            show={toaster.show}
            severity={"success"}
            setToaster={setToaster}
          />
      <Loader open={loader} />
      <div className="mt-3">
        <Accordion
          multiple
          activeIndex={[0]}
          className={style.AccordionTabheader}
        >
          <AccordionTab
            header="Push Notification"
            className={style.AccordionBAck}
          >
            

            <div className={style.AccordingTab}>
              <div className={style.TabHeading}>Latest News</div>
              <div className="d-flex align-items-center">
                {" "}
                <InputSwitch
                  checked={pushNotification.latestNews}
                  name="latestNews"
                  onChange={(e) => inputChange(e, "pushNotification")}
                />
              </div>
            </div>

            <div className={style.AccordingTab}>
              <div className={style.TabHeading}>Important Updates </div>
              <div className="d-flex align-items-center">
                {" "}
                <InputSwitch
                  checked={pushNotification.importantUpdate}
                  name="importantUpdate"
                  onChange={(e) => inputChange(e, "pushNotification")}
                />
              </div>
            </div>

            <div className={style.AccordingTab}>
              <div className={style.TabHeading}>Promotional Notifications</div>
              <div className="d-flex align-items-center">
                {" "}
                <InputSwitch
                  checked={pushNotification.promotionalNotifications}
                  name="promotionalNotifications"
                  onChange={(e) => inputChange(e, "pushNotification")}
                />
              </div>
            </div>
          </AccordionTab>

          <AccordionTab
            header="Email Notifications"
            className={style.AccordionBAck}
          >
            <div className={style.AccordingTab}>
              <div className={style.TabHeading}>Auction Updates</div>
              <div className="d-flex align-items-center">
                {" "}
                <InputSwitch
                  checked={emailNotification.auctionUpdates}
                  name="auctionUpdates"
                  onChange={(e) => inputChange(e, "emailNotification")}
                />
              </div>
            </div>

            <div className={style.AccordingTab}>
              <div className={style.TabHeading}>Latest News</div>
              <div className="d-flex align-items-center">
                {" "}
                <InputSwitch
                  checked={emailNotification.latestNews}
                  name="latestNews"
                  onChange={(e) => inputChange(e, "emailNotification")}
                />
              </div>
            </div>

            <div className={style.AccordingTab}>
              <div className={style.TabHeading}>Important Updates </div>
              <div className="d-flex align-items-center">
                {" "}
                <InputSwitch
                  checked={emailNotification.importantUpdate}
                  name="importantUpdate"
                  onChange={(e) => inputChange(e, "emailNotification")}
                />
              </div>
            </div>

            <div className={style.AccordingTab}>
              <div className={style.TabHeading}>Promotional Notifications</div>
              <div className="d-flex align-items-center">
                {" "}
                <InputSwitch
                  checked={emailNotification.promotionalNotifications}
                  name="promotionalNotifications"
                  onChange={(e) => inputChange(e, "emailNotification")}
                />
              </div>
            </div>
          </AccordionTab>
          <AccordionTab
            header="Change Password"
            className={style.AccordionBAck}
          >
            <div className={style.PasswordBack}>
              <form onSubmit={changePassword}>
                <div className="row mt-3">
                  <div className="col-md-6">
                    <label
                      className={`form-check-label mb-2 ${style.LabelText}`}
                      htmlFor="flexCheckDefault"
                    >
                      New Password<span className="text-danger">*</span>
                    </label>
                    <div className="p-relative">
                      <input
                        type={showPassword ? "text" : "password"}
                        required
                        className="form-control"
                        name="password"
                        value={formData.password}
                        placeholder="Enter New Password"
                        onChange={inpChange}
                      />
                      {!showPassword ? (
                        <i
                          className="bi bi-eye-slash viewPasswordIcon pointer"
                          onClick={() => setShowPassword(!showPassword)}
                        ></i>
                      ) : (
                        <i
                          className="bi bi-eye viewPasswordIcon pointer"
                          onClick={() => setShowPassword(!showPassword)}
                        ></i>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label
                      className={`form-check-label mb-2 ${style.LabelText}`}
                      htmlFor="flexCheckDefault"
                    >
                      Confirm Password<span className="text-danger">*</span>
                    </label>
                    <div className="p-relative">
                      <input
                        type={showConfirmPassword ? "text" : "password"}
                        required
                        className="form-control"
                        name="confirmPassword"
                        placeholder="Confirm New Password"
                        value={formData.confirmPassword}
                        onChange={inpChange}
                      />
                      {!showConfirmPassword ? (
                        <i
                          className="bi bi-eye-slash viewPasswordIcon pointer"
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                        ></i>
                      ) : (
                        <i
                          className="bi bi-eye viewPasswordIcon pointer"
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                        ></i>
                      )}
                    </div>
                    <div className="text-danger mt-1">
                      {passwordsMatch ? "" : t("Password doesn't match")}
                    </div>
                  </div>
                </div>
                {error && <li className="text-danger mt-1">{error}</li>}
                {error2 && <li className="text-danger mt-1">{error2}</li>}
                {error3 && <li className="text-danger mt-1">{error3}</li>}
                <div className="d-flex justify-content-end gap-3 my-4">
                  {/* <button type="button" className='btn-Secondary'>Cancel</button> */}
                  <button type="submit" className="btn-Primary">
                    Save Changes
                  </button>
                </div>
              </form>
            </div>
          </AccordionTab>

          <AccordionTab
            header="Informative Content Access"
            className={style.AccordionBAck}
          >
            <div
              className={style.AccordingTab}
              onClick={() => {
                navigator("/ivfclinic/termAndCondition");
              }}
            >
              <div className={style.TabHeading}>Terms & Conditions</div>
              <div className={style.round}>
                <IoIosArrowForward />
              </div>
            </div>

            <div
              className={style.AccordingTab}
              onClick={() => {
                navigator("/ivfclinic/privacyPolicy");
              }}
            >
              <div className={style.TabHeading}>Privacy Policy</div>
              <div className={style.round}>
                <IoIosArrowForward />
              </div>
            </div>

            <div
              className={style.AccordingTab}
              onClick={() => {
                navigator("/ivfclinic/aboutUs");
              }}
            >
              <div className={style.TabHeading}>About Us</div>
              <div className={style.round}>
                <IoIosArrowForward />
              </div>
            </div>
          </AccordionTab>
          <AccordionTab
            header="Frequently Asked Questions"
            className={style.AccordionBAck}
          >
            {faqData.map((res) => (
              <>
                <Accordion>
                  <AccordionTab
                    header={res.question}
                    className={style.AccordionBAck}
                  >
                    <div className={style.AccordingTab}>
                      <div className={style.TabHeading}>{res.answer}</div>
                    </div>
                  </AccordionTab>
                </Accordion>
              </>
            ))}
          </AccordionTab>
        </Accordion>
      </div>
    </div>
  );
};

export default Setting;
