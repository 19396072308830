import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ViewDetails from "../ViewDetailsDialog/ViewDetails";
import { FaArrowLeft } from "react-icons/fa";
import style from "../FindEggDonor/EggDonor.module.css";
import {
  getsaveSurrogate,
  connectNotification,
  ConnectAddDataSurrogate,
} from "../../../../Api/IntendedParent";
import Toaster from "../../../common/Toaster";
import Loader from "../../../common/Loader";
import { getUserDataById } from "../../../../Api/admin";
import NoSaveUI from "./NoSaveUI";
const SavedPost = () => {
  let params = useParams();

  let { t } = useTranslation();
  const [visibale, setVisibale] = useState(false);
  const [loader, setLoader] = useState(true);

  const [toaster, setToaster] = useState({
    show: false,
    message: "",
  });
  const userId = sessionStorage.getItem("userId");
  const [userData, setUserData] = useState();

  // console.log(params);

  const getUserDataIP = async () => {
    await getUserDataById(userId)
      .then((res) => {
        setUserData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const connectSuperAdmin = async (cardData) => {
    setLoader(true);
    let payLoadData = {
      role: ["admin"],
      super_admin_message: {
        heading: "New Connection Request",
        msg: `${userData.fullName} has requested to connect with ${cardData?.firstName} (SM)`,
      },
      surrogate_id: cardData.userId,
    };
    await connectNotification(userId, payLoadData)
      .then((res) => {
        // console.log(res);
        setToaster({
          show: true,
          message: "Your connection request has been submitted",
        });
        setLoader(false);
        getUserDataIP()
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const ConnectLeads = async(cardData)=>{
  
    setLoader(true);
  
      let payloadLeads = {
        connected_by:{
          id:userId,
          role:userData.role
  
        },
        connected_to:{
        id:cardData.userId,
        role:params.name === " Surrogate Mother" ? "surrogate_donor" : "eggDonor",
        }
      }
      await ConnectAddDataSurrogate(payloadLeads).then((res)=>{
        console.log(res)
        setLoader(false)
        setToaster({
          show: true,
          message: "Your connection request has been submitted",
        });
        getSavedDataById()
      }).catch((err)=>{
        console.log(err)
        setLoader(false)
      })
  
    }
  const [cards, setCards] = useState([]);

  const getSavedDataById = async () => {
    setLoader(true);
    await getsaveSurrogate(userId)
      .then((res) => {
        // console.log(res);
        let dtaa = res.data.data[0].surrogateData;

        setCards(
          dtaa.filter(
            (s) =>
              s.service_offer ===
              (params.name === " Surrogate Mother" ? "surrogate" : "eggDonor")
          )
        );
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  useEffect(() => {
    getSavedDataById();
    getUserDataIP();
  }, []);

  const navigate = useNavigate();

  return (
    <div>
      <Toaster
        event={toaster.message}
        show={toaster.show}
        severity={"success"}
        setToaster={setToaster}
      />
      <Loader open={loader} />
      <div className="mb-4 mt-4 ms-4">
        <div
          className="d-flex align-items-center gap-2 btn-Secondary p-0 pointer"
          onClick={() => navigate(-1)}
        >
          <FaArrowLeft /> Back
        </div>
      </div>
      <div className={`${style.Savedpost_icon} mt-4`}>
        <div className={style.CreditText}>Saved Contact : {params.name}</div>
        {cards.length === 0 ?
        <>
       <div>
        <div className="d-flex justify-content-center  w-100">
        <div className="w-25">
          <img className="w-100" src="/images/undercons.png" />
        </div>
      </div>
      
          <div className={`d-flex justify-content-center lufga_noaucton mt-4`}>No Saved Contacts</div>
          <div className={`d-flex justify-content-center lufga_24px mt-2`}>
          Save Your Favourite {params.name} Profiles for Later
          </div>
        
    </div>
        </>:
        <>
         <div className={`${style.cardContainer} mt-5`}>
          {cards.map((card, index) => (
            <div key={index} className={`w-100  ${style.maincardContainer}`}>
              <div
                className={`${style.card_upper} pointer`}
                // style={{
                //   backgroundImage: `url(${
                //     card?.photos[card.photo_profile_index]?.fileUrl
                //   })`,
                // }}
              >
                 <div
                   className={`${style.card_upper_mask}`}
                     style={{
                  backgroundImage: `url(${
                    card?.photos[
                      card.photo_profile_index ? card?.photo_profile_index : 0
                    ]?.fileUrl
                  })`,
                }}
                  >

                  </div>
                  <img
                    className={`${style.card_upper_image}`}
                    src={ card?.photos[
                      card.photo_profile_index ? card?.photo_profile_index : 0
                    ]?.fileUrl}
                  />
                <div className={`${style.upper_v_1}`}></div>
              </div>
              <div className={`${style.card_lower} d-flex flex-column`}>
                <div className={`${style.cardAgeText} `}>
                    SM Id: {t(card.memberId)}, Age:{t(card.age)}
                </div>
                <div className="d-flex justify-content-between align-items-center py-2">
                  <div className={`${style.lower_v_2}  `}>
                    {t(card.short_description)}
                  </div>
                </div>
                <div
                  className={`${style.card_lower_v_1} d-flex flex-column mt-auto`}
                >
                  <div className="d-flex justify-content-between gap-4 mt-auto">
                    {card?.is_connected === true ? (
                      <div className={`${style.lower_v_3}`}>Connected</div>
                    ) : (
                      <button
                        className={`btn ${style.lower_v_3}`}
                        onClick={() => {
                          ConnectLeads(card);
                        }}
                      >
                        Connect
                      </button>
                    )}
                    <button
                      className={`btn ${style.lower_v_Buy}`}
                      onClick={() => {
                        navigate(`/intendedParent/viewDetail/${card.userId}`);
                      }}
                    >
                      View Details
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        </>}
       
      </div>
    </div>
  );
};

export default SavedPost;
