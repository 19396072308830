import axios from "axios";
import { baseUrl, dashboardService, ivfClinic } from "./EndPoint"

let userData = JSON.parse(sessionStorage.getItem("userData"))

let config = {
    headers: {
        Authorization: `Bearer ${userData?.token}`,
    }
  }

export async function getuserDataById (id) {
    return await axios.get(`${baseUrl}${ivfClinic.getUserById}${id}`)
}

export async function getAppointments (payload){
    return await axios.post(`${baseUrl}${ivfClinic.getAppointments}`,payload, config)
}

export async function addAppointment (payload){
    return await axios.post(`${baseUrl}${ivfClinic.addAppointment}`, payload,config)
}

export async function editAppointment (id,payload){
    return await axios.put(`${baseUrl}${ivfClinic.editAppointment}${id}`,payload,config)
}

export async function getClinicAppointmentById (id){
    return await axios.get(`${baseUrl}${ivfClinic.getClinicAppointmentById}${id}`,config)
}

export async function deleteAppointmentById (id){
    return await axios.delete(`${baseUrl}${ivfClinic.deleteAppointment}${id}`,config)
}

export async function deleteClientById (id){
    return await axios.delete(`${baseUrl}${ivfClinic.deleteClient}${id}`,config)
}

export async function deleteReportById (id){
    return await axios.delete(`${baseUrl}${ivfClinic.deleteReport}${id}`,config)
}

export async function getClientsOfClinic(payload){
    return await axios.post(`${baseUrl}${ivfClinic.getClientsOfClinic}`,payload,config)
}

export async function getClientDataById(id){
    return await axios.get(`${baseUrl}${ivfClinic.getClientDataById}${id}`,config)
}

export async function addAppointmentByClientId(payload){
    return await axios.post(`${baseUrl}${ivfClinic.addAppointmentByClientId}`,payload,config)
}

export async function getAppointmentByClientId(payload){
    return await axios.post(`${baseUrl}${ivfClinic.getAppointmentByClientId}`,payload,config)
}

export async function getReportByClientId(payload){
    return await axios.post(`${baseUrl}${ivfClinic.getReportByClientId}`,payload,config)
}

export async function addReportivfCLinic(payload){
    return await axios.post(`${baseUrl}${ivfClinic.addReportivfCLinic}`,payload,config)
}

export async function addDoctorInClinic(payload){
    return await axios.post(`${baseUrl}${ivfClinic.addDoctorInClinic}`,payload,config)
}

export async function getDoctorsListApi (id){
    return await axios.get(`${baseUrl}${ivfClinic.getDoctorsListApi}${id}`,config)
}

export async function deleteDoctorByIdApi (id){
    return await axios.delete(`${baseUrl}${ivfClinic.deleteDoctorByIdApi}${id}`,config)
}

export async function getDoctorByIdApi (id){
    return await axios.get(`${baseUrl}${ivfClinic.getDoctorByIdApi}${id}`,config)
}

export async function updateDoctorByIdApi (id,payload){
    return await axios.put(`${baseUrl}${ivfClinic.updateDoctorByIdApi}${id}`,payload,config)
}


