import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import style from './LawyerAddPoc.module.css';

import moment from 'moment';
import ImageUpload from '../../../SurrogateComponents/Auction/CreatePostAuction/ImageUpload';
import { uploadImage } from '../../../../Api/SurrogateMotherApis';
import Loader from '../../../common/Loader';
import { addAndUpdatePoc } from "../../../../Api/Lawyer";
import PhoneInputComponent from '../../../commonComponents/PhoneInput/PhoneInput';

const LawyerAddPocDialog = ({ addocument, setAddDocument, appointmentId, reload , username }) => {
    const [loader, setLoader] = useState(false);
    const [image, setImage] = useState(null);
    const [fieldValidation, setFieldValidation] = useState(false);


    console.log('appointmentId', appointmentId)
    console.log('firstName', username)

    let [data, setData] = useState({
        "clientId": `${appointmentId}`,
        "name": "",
        "contact_number": "",
        "email_address": "",
        "relation_with_client": ""
    });



    const addPocFunction = async (e) => {
        e.preventDefault();
        setLoader(true);
        console.log('Data before submission:', data); // Debugging line
        data={...data,clientId:appointmentId}
        try {
            await addAndUpdatePoc(data);
            reload();
            resetForm();
            setAddDocument(false);
        } catch (err) {
            console.log(err);
            setLoader(false);
        } finally {
            setLoader(false);
            setAddDocument(false);
        }
    };
    

    const resetForm = () => {
        setData({
            "clientId": `${appointmentId}`,
            "name": "",
            "contact_number": "",
            "email_address": "",
            "relation_with_client": ""
        });
        setImage(null);
        setFieldValidation(false);
    };

    const onInputChange = (e) => {
        const { name, value } = e.target;
        console.log('Input Change:', name, value); // Debugging line
        setData(prevData => ({ ...prevData, [name]: value }));
        setFieldValidation(false);
    };
    
    useEffect(() => {
        if (addocument) {
            
        }
    }, [addocument]);

    return (
        <div>
            <Loader open={loader} />
            <Dialog
                closable={false}
                visible={addocument}
                className='p-0 m-0'
                onHide={() => setAddDocument(false)}
                style={{ width: '70vw', padding: '0px', margin: '0px' }}
                breakpoints={{ '1400px': '80vw', '641px': '100vw' }}
            >
                <div className='Verfied_con'>
                    <div className='d-flex justify-content-center '>
                        <span className={`fW_600 ${style.verfideText}`}>{username} : Add POC</span>
                    </div>

                    <form onSubmit={addPocFunction}>
                        <div className='row'>
                            <div className='col-12 mt-3'>
                                <label className={`form-check-label mb-1 ${style.LabelText}`} htmlFor='report_name'>Name</label>
                                <input
                                    name='name'
                                    required
                                    type='text'
                                    className='form-control'
                                    placeholder='Enter Name'
                                    onChange={onInputChange}
                                    value={data.name}
                                />
                            </div>
                        </div>

                        <div className='row mb-4'>

                            <div className='col-md-6 mt-3'>
                                <label className={`form-check-label mb-1 ${style.LabelText}`} htmlFor='contact_number'>Contact Number</label>
                                <PhoneInputComponent
                                disabled={false}
                                name={"contact_number"}
                                value={data.contact_number}
                                onChange={onInputChange}
                                />
                            </div>


                            <div className='col-md-6 mt-3'>
                                <label className={`form-check-label mb-1 ${style.LabelText}`} htmlFor='email_address'>Email Address</label>
                              
                                
                                
                                <input
                                    required
                                    type='email'
                                    className='form-control'
                                    placeholder='Enter Email Id'
                                    name='email_address'
                                    value={data.email_address}
                                    onChange={onInputChange}
                                />
                            </div>

                

                            <div className='col-12 mt-3'>
                                <label className={`form-check-label mb-1 ${style.LabelText}`} htmlFor='client_type'>Relation With Client</label>
                                <input
                                    required
                                    type='text'
                                    className='form-control'
                                    placeholder='Relation With Client'
                                    name='relation_with_client'
                                    value={data.relation_with_client}
                                    onChange={onInputChange}
                                />
                            </div>


                        </div>

                       
                        <div className='d-flex justify-content-end gap-3'>
                            <button className={style.CencelButton} type='button' onClick={() => { setAddDocument(false); resetForm() }}>Cancel</button>
                            <button className={style.SubmitButton} type='submit' disabled={loader}>Save</button>
                        </div>
                    </form>
                </div>
            </Dialog>
        </div>
    );
};

export default LawyerAddPocDialog;
