import React, { useState } from 'react'
import style from './LawyerNewClients.module.css'
import { InputText } from 'primereact/inputtext'
import { TableContainer } from '../../../common/TableContainer'
import { useNavigate } from 'react-router';
import Loader from '../../../common/Loader';

const LawyerNewClients = ({ data }) => {

    const navigate = useNavigate();

    let firstFiveClients;
    if (data.length <= 5) {
        firstFiveClients = data;
    }
    else {
        firstFiveClients = data.slice(0, 5)
    }

    return (
        <div>

            <div className='d-flex justify-content-between mb-4 mt-3 container-fluid'>

                <div className='lufga_26px fw_600'>New Clients</div>
                <div style={{ cursor: 'pointer' }} className='lufga_18px fw_400 theme_color' onClick={() => navigate("/lawyer/myclient")}>View all Clients</div>

            </div>

            <TableContainer className={"table_container"}>
                <table>
                    <thead>
                        <tr>

                            <th>
                                Name
                            </th>
                            <th>Client Type</th>
                            <th>Service Requested</th>
                            <th>Contract Id</th>
                            <th></th>

                        </tr>
                    </thead>
                    <tbody>
                        {firstFiveClients.length === 0 ? (
                            <tr style={{ height: '200px' }}>
                                <td style={{ height: '200px', width: '100%' }} colSpan="5">
                                    <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
                                        <div className='lufga_24px'>No Data Available for Clients</div>
                                    </div>
                                </td>
                            </tr>
                        )
                            :
                            firstFiveClients.map((res, index) => {
                                return (
                                    <tr key={index}>
                                        <td className=''>
                                            {res.name}
                                        </td>
                                        <td>{res.client_type}</td>
                                        <td>{res.service_requested}</td>
                                        <td>{res.clientId}</td>
                                        <td>
                                            <button className={style.ViewButton} onClick={() => navigate(`clientView/${res._id}`)}><i class="bi bi-eye d-flex align-items-center me-1"></i> View</button>
                                        </td>
                                    </tr>

                                )
                            })}
                    </tbody>


                </table>
            </TableContainer>

        </div>
    )
};

export default LawyerNewClients