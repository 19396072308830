import React, { useEffect, useState } from "react";
import style from "./Auction.module.css";
import SecurityDepositDialog from "./SecurityDepositDialog";
import { useNavigate, Outlet } from "react-router-dom";
import { BsThreeDotsVertical } from "react-icons/bs";
import { t } from "i18next";
import { getAllAuctionData } from "../../../Api/IntendedParent";
import Loader from "../../common/Loader";
import moment from "moment";
import BidNowDialog from "./BidNowDialog/BidNowDialog";
import BuyNowModal from "./BuyNowModal";
import NoNhpPost from "../../SurrogateComponents/Dashboard/NoAuctionPost/NoNhpPost";

const Auction = ({ limit }) => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [allData, setAllData] = useState([]);
  const userId = sessionStorage.getItem("userId");
  const [bidNowOpen, setBidNowOpen] = useState(false);
  const [buyNowOPen, setBuyNowOpen] = useState(false);
  const [cardId, setCardId] = useState();

  async function getAllAuctions() {
    try {
      let res = await getAllAuctionData();
      if (limit) {
        setAllData(res.data.data.active_Auction.splice(0, limit));
      } else {
        setAllData(res.data.data.active_Auction);
      }
      setLoader(false);
    } catch (err) {
      console.log(err);
      setLoader(false);
    }
  }

  const calculateDateDifference = (startDate, endDate) => {
    const start = moment(startDate);
    const end = moment(endDate);
    const duration = moment.duration(end.diff(start));

    const days = duration.days();
    const hours = duration.hours();

    if (days > 0) {
      return `${days} days ${hours} hours left`;
    } else {
      return `${hours} hours left`;
    }
  };

  useEffect(() => {
    getAllAuctions();
  }, []);

  // function checkMyBid(data) {
  //   let allBids = data?.bids;
  //   let myhighBid = allBids?.filter((res) => res?.biderId == userId);
  //   let sortedBids = myhighBid?.sort((a, b) => a?.bid - b?.bid).reverse();
  //   return sortedBids?.length !== 0
  //     ? `My highest bid $${sortedBids[0]?.bid}`
  //     : null;
  // }
 const allDummyData = [
  {
    "member_name" : "Isela & Michael, New York",
    "photoUrl" : "https://media.istockphoto.com/id/1365865879/photo/happy-face-at-the-beach.jpg?s=612x612&w=0&k=20&c=NOOEN-pgXsY2xYsGDUNV0xRdH11uxkIlk61oPodgvwY=",
    "short_description" : "Successfull Executives, Both work from home. Excited to adopt our first baby",

  },{
    "member_name" : "Isela & Michael, New York",
    "photoUrl" : "https://media.istockphoto.com/id/1365865879/photo/happy-face-at-the-beach.jpg?s=612x612&w=0&k=20&c=NOOEN-pgXsY2xYsGDUNV0xRdH11uxkIlk61oPodgvwY=",
    "short_description" : "Successfull Executives, Both work from home. Excited to adopt our first baby"
  },{
    "member_name" : "Isela & Michael, New York",
    "photoUrl" : "https://media.istockphoto.com/id/1365865879/photo/happy-face-at-the-beach.jpg?s=612x612&w=0&k=20&c=NOOEN-pgXsY2xYsGDUNV0xRdH11uxkIlk61oPodgvwY=",
    "short_description" : "Successfull Executives, Both work from home. Excited to adopt our first baby",
  }
 ]
  
  return (
    <div>
      <Loader open={loader} />
      <div
        className={`mt-4 d-flex align-items-center justify-content-between mb-3`}
      >
        <div className={`lufga_24px fw_600`}>Top Matches For You!</div>
        <span
        className={`lufga_16px  theme_color ${style.ViewAllPosts}`} 
          // className={`{style.AuctionSpaceButton}`}
          onClick={() => {
            navigate(`/embryo_donor/search`);
          }}
        >
          View All Posts
        </span>
      </div>

      {allData.length === 0 ? (
        <>
          <NoNhpPost />
        </>
      ) : (
        <>
          <div className={`${style.cardContainer} mt-4`}>
            {allDummyData?.map((card, index) => (
              <div key={index} className={`w-100  ${style.maincardContainer}`}>
                <div
                  className={`${style.card_upper} pointer`}
                  onClick={() =>
                    calculateDateDifference(card.startDate, card.endDate) ==
                    "0 hours left"
                      ? {}
                      : navigate(
                          `/intendedParent/auctions/auctionResume/${card._id}`
                        )
                  }
                  // style={{
                  //   backgroundImage: `url('${card?.photoUrl}')`,
                  // }}
                >
                   <div
                   className={`${style.card_upper_mask}`}
                  //  style={{
                  //   backgroundImage: `url('${card?.photoUrl}')`,
                  // }}
                  >

                  </div>
                  <img
                    className={`${style.card_upper_image}`}
                    src={ card?.photoUrl}
                  />
                  {/* <div className={`${style.upper_v_1}`}></div> */}
                  {/* <div className={`${style.upper_v_2}`}>
                    <div className={`${style.upper_v_2_1}`}>
                      End Date- {moment(card.endDate).format("DD/MM/YYYY")}
                    </div>
                  </div> */}
                </div>
                <div className={`${style.card_lower} d-flex flex-column`}>
                  <div className="d-flex justify-content-between align-items-center py-2">
                    <div
                      className={`${style.lower_v_1} lufga_18px pointer`}
                      // onClick={() =>
                      //   calculateDateDifference(card.startDate, card.endDate) ==
                      //   "0 hours left"
                      //     ? {}
                      //     : navigate(
                      //         `/intendedParent/auctions/auctionResume/${card._id}`
                      //       )
                      // }
                    >
                     {t(card.member_name)}
                    </div>
                    {/* {checkMyBid(card) && (<>
                    
                    <div className={`${style.sBtn}`}>{checkMyBid(card)}</div>
                    </>)} */}
                  </div>
                  <div
                    className={`${style.card_lower_v_1} d-flex flex-column mt-auto`}
                  >
                    
                    <div className={`${style.lower_v_2}`} style={{paddingBottom:"2rem"}}>
                      {t(card?.short_description)}
                    </div>
                    {/* <div className="d-flex justify-content-between px-3 mb-3 mt-3 gap-4">
                      <div className={`${style.Currenttext}`}>
                        Current Bid :
                      </div>
                      <div className={`${style.Dollertext}`}>
                        $
                        {card?.highest_Bid?.bid !== 0
                          ? card?.highest_Bid?.bid
                          : card.starting_bid_amount}
                      </div>
                    </div> */}
                    <div className="d-flex justify-content-between gap-4 mt-auto">
                      <button
                        className={`btn ${style.lower_v_3}`}
                        // onClick={() => {
                        //   // navigate(`biding/${card._id}`)
                        //   setBidNowOpen(true);
                        //   setCardId(card?._id);
                        // }}
                        // disabled={
                        //   calculateDateDifference(
                        //     card.startDate,
                        //     card.endDate
                        //   ) == "0 hours left"
                        // }
                      >
                        Connect
                      </button>
                      <button
                        className={`btn ${style.lower_v_Buy}`}
                        // onClick={() => {
                        //   setBuyNowOpen(true);
                        //   setCardId(card?._id);
                        // }}
                        // disabled={
                        //   calculateDateDifference(
                        //     card.startDate,
                        //     card.endDate
                        //   ) == "0 hours left"
                        // }
                      >
                        View Details
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}

      {/* <SecurityDepositDialog payment={payment} setPayment={setPayment} /> */}

      {/* <Dialog
                closable={false}
                visible={visibale}
                className='p-0 m-0'
                onHide={() => setVisibale(false)}
                style={{ width: "40vw", padding: "0px", margin: "0px" }}
                breakpoints={{ "960px": "75vw", "641px": "100vw" }}
            >
                <div className=''>
                    <div className='mb-3'>
                        <span className={`${style.ConfirmationText}`}>Citizenship Confirmation</span>
                    </div>
                    <div className='mb-5'>
                        <span className={style.CitzenText}>Are you a citizen of U.S.A. ?</span>
                    </div>
                    <div className='d-flex justify-content-end gap-3'>
                        <button className={style.Nobutton} onClick={NoSubmit}>No</button>
                        <button className={style.Yesbutton} onClick={() => setVisibale(false)}>Yes</button>
                    </div>
                </div>


            </Dialog> */}

      <Outlet
        context={{
          getAllAuction: getAllAuctions,
        }}
      />
      <BuyNowModal
        show={buyNowOPen}
        reload={getAllAuctions}
        setShow={setBuyNowOpen}
        id={cardId}
      />

      <BidNowDialog
        show={bidNowOpen}
        reload={getAllAuctions}
        setShow={setBidNowOpen}
        id={cardId}
      />
    </div>
  );
};

export default Auction;
