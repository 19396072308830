import React from 'react'

const NoAuctionPost = ({source}) => {
    return (
        <>
            <div className='d-flex justify-content-center w-100'>
                <div className='w-25'>
                    <img className='w-100' src='/images/undercons.png' />
                </div>


            </div>

            <div className={`d-flex justify-content-center lufga_noaucton mt-4`}>
                No Auction Post Added
            </div>

            {source == "ip" ?
                null
                :
                <div className={`d-flex justify-content-center lufga_24px mt-2`}>
                    Earn more with innovative service auction
                </div>
            }
        </>
    )
}

export default NoAuctionPost