import React, { useEffect, useState } from "react";
import style from "./AuctionForm.module.css";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useTranslation } from "react-i18next";
import { getAuctionById } from "../../../../Api/SurrogateMotherApis";
import { useParams } from "react-router";
import Loader from "../../../common/Loader";
import moment from "moment";

const AuctionForm = ({
  resumeone,
  setResumeone,
  viewData,
  getAllSurrogate,
}) => {
  let { t } = useTranslation();
  const [getDataById, setGetDataById] = useState([]);
  const [loader, setLoader] = useState(false);

  let params = useParams();
  console.log(viewData)
  console.log(viewData?.verify_resume?.status)

  return (
    <div className={style.AuctionForm}>
      <Dialog
        closable={false}
        visible={resumeone}
        className="p-0 m-0"
        onHide={() => setResumeone(false)}
        style={{ width: "65vw", padding: "0px", margin: "0px" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <div>
          <div className="d-flex justify-content-end">
            <Button
              icon="pi pi-times"
              className={`p-button-text ${style.crossButton}`}
              onClick={() => setResumeone(false)}
            />
          </div>

          <form action="">
            <div className={`${style.HeaderDialog} `}>
              <div className="d-flex ">
                <div className={style.ProfilePic}>
                  <img
                    src={viewData?.photoUrl}
                    alt=""
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>
                <div className={`${style.PrifileHeding}`}>
                  <div className="d-flex gap-3 align-items-center">
                    <div>
                      {" "}
                      <span className={style.Textname}>{viewData?.name}</span>
                    </div>
                    {viewData?.verify_resume?.status ?  <div>
                      {" "}
                      <div className={style.VerfiedButton1}>
                        <div className=""> 
                        <img
                          src="/images/green tick.png"
                          style={{ width: "20px", marginRight: "3px" }}
                          alt=""
                        />
                        <span className="">
                        Verified
                        </span>
                        </div>
                       
                      </div>
                    </div>:null}
                   
                  </div>
                  <br />
                  <div>
                    <div style={{ width: "100%", height: "100%" }}>
                      <div className={style.ProfileText}>
                        {viewData?.short_description}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-between px-1 pt-2">
                <div className="d-flex gap-3 ">
                  <img src="/images/image 140.png" alt="" />
                  <span className={style.LoctionText}>
                    {viewData?.state},{viewData?.country}
                  </span>
                </div>
                <div className="d-flex gap-3 ">
                  <img src="/images/image 141.png" alt="" />
                  <span className={`me-3 ${style.LoctionText}`}>
                    {viewData?.email}
                  </span>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-md-6">
                <div className="">
                  <span className={style.CurrentText}>Highest Bid :</span>
                  <span className={`ms-3 ${style.CurrentText1}`}>
                    {viewData?.highest_Bid?.bid}
                  </span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="">
                  <span className={style.CurrentText}>Total Bid Counts :</span>
                  <span className={`ms-3 ${style.CurrentText1}`}>
                    {viewData?.bids?.length}
                  </span>
                </div>
              </div>
            </div>
            {/* <div className="row mb-3 mt-5">
              <div className="col-md-6">
                <div className="">
                  <span className={style.CurrentText}>Buy Now Amount :</span>
                  <span className={`ms-3 ${style.CurrentText1}`}>
                    {viewData?.buy_now_price}
                  </span>
                </div>
              </div>
              <div className="col-md-6">
                <div>
                  <span className={style.CurrentText}>Surrogacy Count :</span>
                  <span className={`ms-3 ${style.CurrentText1}`}>
                    {getAllSurrogate?.child_details?.length}
                  </span>
                </div>
              </div>
            </div> */}

            <div className="row mt-4">
              <div className="col-md-6">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("Auction Start Date")}
                  <span className="text-danger"></span>
                </label>

                <input
                  type="date"
                  className={`form-control ${style.InputCustom}`}
                  placeholder=""
                  value={moment(viewData?.startDate).format("YYYY-MM-DD")}
                />
              </div>
              <div className="col-md-6">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("Auction End Date")}
                  <span className="text-danger">*</span>
                </label>

                <input
                  type="date"
                  className={`form-control ${style.InputCustom}`}
                  placeholder=""
                  value={moment(viewData?.endDate).format("YYYY-MM-DD")}
                />
              </div>
            </div>
          </form>
        </div>
      </Dialog>
    </div>
  );
};

export default AuctionForm;
