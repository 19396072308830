
import React, { useState, useEffect } from 'react';
import MonthYearSelect from '../MonthYearSelector';
import { CgClose } from "react-icons/cg";
import moment from "moment";
import './CalenderListing.css';
import ScheduleCalender from '../Calender/ScheduleCalender';
import WeekViewCalender from '../WeekCalender/WeeklyCalender';
import Appoint from '../ApointMentDialog/Appoint';
import { getAppointments } from '../../../../Api/IvfClinic';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from "react-redux";

const CalenderListing = ({ setVisible }) => {

  const MemberId=sessionStorage.getItem("ClinicId")
  const selector = useSelector(e => e.header)
  const navigate = useNavigate();
  const [calenderview, setCalenderview] = useState("month");
  const [selectedMonthYear, setSelectedMonthYear] = useState({
    month: moment().month() + 1,
    year: moment().year(),
  });
  const [selectedDate, setSelectedDate] = useState(moment().toDate());
  const [apoint, setApoint] = useState(false);
  const [editapoint, setEditApoint] = useState(false);
  const [activeButton, setActiveButton] = useState("Monthly");
  const [appointments, setAppointments] = useState([]);

  const handleMonthYearChange = (option) => {
    setSelectedMonthYear(option);
  };

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
    setCalenderview(buttonName === "Daily" ? "week" : "month");
  };

  const fetchAppointments = async () => {
    try {
      let res = await getAppointments({"clinicId":MemberId});
      setAppointments(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAppointments();
  }, [selectedMonthYear]);

  const getCurrentWeekNumber = () => {
    const today = moment();
    if (today.month() + 1 === selectedMonthYear.month && today.year() === selectedMonthYear.year) {
      return today.week();
    } else {
      const firstDayOfMonth = moment(new Date(selectedMonthYear.year, selectedMonthYear.month - 1, 1));
      return firstDayOfMonth.week();
    }
  };

  const [currentWeek, setCurrentWeek] = useState(getCurrentWeekNumber);

  const handleBack = () => {
    setCurrentWeek(currentWeek - 1);
  };

  const handleToday = () => {
    const today = moment();
    setCurrentWeek(today.week());
    setSelectedMonthYear({
      month: today.month() + 1,
      year: today.year(),
    });
  };

  const handleNext = () => {
    setCurrentWeek(currentWeek + 1);
  };

  useEffect(() => {
    setCurrentWeek(getCurrentWeekNumber());
  }, [selectedMonthYear]);

  const currentWeekMoment = moment().week(currentWeek);
  const weekStart = currentWeekMoment.clone().startOf('week').toDate();
  const weekEnd = currentWeekMoment.clone().endOf('week').toDate();

  return (
    <div>
      <Appoint apoint={apoint} setApoint={setApoint} reload={fetchAppointments}/>
      
      <div className='d-flex justify-content-between'>
        <div>
          <div className="monthSelectDiv d-flex gap-4 align-items-center w-100 my-4">
            <div>
              <span
                className={`btns ${activeButton === "Monthly" ? "active_btn" : ""} monthly`}
                onClick={() => handleButtonClick("Monthly")}
              >
                Monthly
              </span>
              <span
                className={`btns ${activeButton === "Daily" ? "active_btn" : ""} daily`}
                onClick={() => handleButtonClick("Daily")}
              >
                Daily
              </span>
            </div>
            <div className="d-flex align-items-center">
              <MonthYearSelect
                selectedMonthYear={selectedMonthYear}
                onChange={handleMonthYearChange}
              />
            </div>
          </div>
        </div>
        <div className='d-flex gap-3 align-items-center'>
          <button className='cancelButton' onClick={() => navigate('doctorslist')}>
            <i className="pi pi-bars" style={{ color: 'black', marginRight: '10px' }}></i>
            Doctor's List
          </button>
          <button className='saveButton' onClick={() => setApoint(true)}>+ New Appointment</button>
        </div>
      </div>
      {calenderview === "week" ? (
        <WeekViewCalender
          selectedMonthYear={selectedMonthYear}
          setSelectedMonthYear={setSelectedMonthYear}
          appointments={appointments}
          height={"800px"}
          weekStart={weekStart}
          reload={fetchAppointments}
          weekEnd={weekEnd}
          currentWeek={currentWeek}
          setCurrentWeek={setCurrentWeek}
        />
      ) : (
        <ScheduleCalender
          selectedMonthYear={selectedMonthYear}
          view={calenderview}
          appointments={appointments}
          selectedDate={selectedDate}
          reload={fetchAppointments}
          onDateClick={(date) => {
            setSelectedDate(date);
            setCalenderview("day");
          }}
        />
      )}
    </div>
  );
};

export default CalenderListing;

