import React, { useState } from "react";
import style from "./Client.module.css";
import { Pagination } from "reactstrap";
import { TableContainer } from "../../common/TableContainer";
import { InputText } from "primereact/inputtext";
import { useNavigate, useParams } from "react-router";

const ClientTable = ({ data, role }) => {
  const navigate = useNavigate();

  const roleToRoute = {
    surrogate_donor: "activesurrogateprofile",
    parents: "activeintendedprofile",
    IvfClinic: "/ivfclinic/myprofile",
    Lawyer: "/lawyer/myprofile",
    admin: "",
  };

  // Function to handle the click event
  const handleViewClick = (userRole, id) => {
    const route = roleToRoute[userRole];
    if (route) {
      const completeRoute = `${route}/${id}`;
      console.log(completeRoute, "its the route you have to follow");
      navigate(completeRoute);
    } else {
      console.error("No route defined for this role");
    }
  };

  return (
    <div className={style.ClientTable}>
      <TableContainer className={"table_container"}>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>User Role</th>
              <th>User Id</th>
              <th>Plan Type</th>
              <th>Date Range</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td
                  className="d-flex align-items-center"
                  style={item?.user_blocked === true ? { opacity: "0.53" } : {}}
                >
                  <span
                    className={` me-3  ${style.ProfileImages} ${
                      item?.user_blocked === true ? style.block_style : ""
                    }
                    `}
                  >
                    <img
                      src={item?.photo || `/images/blankProfile.png`}
                      alt="user img"
                      className={`w-100 `}
                    />
                  </span>
                  {item?.fullName}
                </td>

                <td>
                  {item?.role === "surrogate_donor" ? (
                    <> Surrogate Mother</>
                  ) : item?.role === "parents" ? (
                    <>Intended Parents</>
                  ) : (
                    item?.role
                  )}
                </td>
                <td>{item?.memberId}</td>
                <td>{item?.plan?.plan_name}</td>
                <td>
                  {new Date(item?.plan?.startDate).toLocaleDateString("en-GB") +
                    " - " +
                    new Date(item?.plan?.endDate).toLocaleDateString("en-GB")}
                </td>
                <td>
                  <button
                    className={style.ViewButton}
                    onClick={() => handleViewClick(item.role, item._id)}
                  >
                    <i className="bi bi-eye d-flex align-items-center me-1"></i>{" "}
                    View
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </TableContainer>
    </div>
  );
};

export default ClientTable;
