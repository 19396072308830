import React from "react";
import { Route, Routes } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { SidebarWrapper } from "./SidebarWrapper";
import UnderConstruction from "../components/UnderConstruction/UnderConstruction";
import SurrogateAuction from "../components/SurrogateComponents/Auction/Auction";
import SurrogateResume from "../components/SurrogateComponents/Resume/Resume";
import SurrogateMyprofile from "../components/SurrogateComponents/MyProfile/Myprofile";
import SurrogateSetting from "../components/SurrogateComponents/Setting/Setting";
import SurrogateDashboard from "../components/SurrogateComponents/Dashboard/Dashboard";
import SurrogateSubscriptionPlan from "../components/SurrogateComponents/SubscriptionPlan/SubscriptionPlan";
import Userlisting from "../components/Userlisting/Userlisting";
import IntendedDashboard from "../components/IntendedParentsComponent/DashBoard/IntendedDashboard";
import SubscriptionPlanip from "../components/IntendedParentsComponent/SubscriptionPlan/SubscriptionPlan";
import CreditReport from "../components/IntendedParentsComponent/CreditReport/CreditReport";
import Search from "../components/IntendedParentsComponent/Search/Search";
import FindEggDonor from "../components/IntendedParentsComponent/Search/FindEggDonor/FindEggDonor";
import SavedPost from "../components/IntendedParentsComponent/Search/Savedpost/SavedPost";
import Auction from "../components/IntendedParentsComponent/Auction/Auction";
import MyProfile from "../components/IntendedParentsComponent/MyProfile/MyProfile";
import Setting from "../components/IntendedParentsComponent/Settings/Setting";
import CreatePost from "../components/SurrogateComponents/Auction/CreatePostAuction/CreatePost";
import EditMyProfile from "../components/SurrogateComponents/MyProfile/EditMyProfile";



import Dashboard from "../components/IvfClinicComponents/Dashboard/Dashboard";


import Myprofileivf from "../components/IvfClinicComponents/MyProfile/MyProfile";


import MyclientivfClinic from "../components/IvfClinicComponents/MyClientListing/Myclient";
import ClientViewivfClinic from "../components/IvfClinicComponents/MyClientTable/MyClientView/ClientView";
import ViewReportivfClinic from "../components/IvfClinicComponents/MyClientTable/ViewReport/ViewReport";
import CalenderListingivfClinic from "../components/IvfClinicComponents/CalenderComponent/CalenderListing/CalenderListing";
import SettingivfClinic from "../components/IvfClinicComponents/Setting/Setting";
import DoctorsListivfClinic from "../components/IvfClinicComponents/DoctorsList/DoctorsList";
import EditDoctorivfClinic from "../components/IvfClinicComponents/DoctorsList/EditDoctor/EditDoctor";
import SuperAdminLayout from "../components/SuperAdminLayout/SuperAdminLayout";
import SocialPagesSuperadmin from "../components/SuperAdminLayout/GlobalSettings/SocialPages/SocialPages";
import EditResume from "../components/SurrogateComponents/Resume/EditResume";
import { IntendedParentsAuth } from "../components/Auth/IntendedParentsAuth";
import { SurrogateMother } from "../components/Auth/SurrogateMother";
import AuctionSuperAdmin from "../components/SuperAdminLayout/GlobalSettings/Auction/Auction";
import MyclientSuperAdmin from "../components/SuperAdminLayout/MyClientListing/Myclient";
import SubscriptionplanSuperAdmin from "../components/SuperAdminLayout/SubscriptionPlan/Subscriptionplan";
import EditPostAuction from "../components/SurrogateComponents/Auction/CreatePostAuction/EditPostAuction";
import BidNowDialog from "../components/IntendedParentsComponent/Auction/BidNowDialog/BidNowDialog";
import UserProfileView from "../components/SuperAdminLayout/ActiveUsers/userProfile";
import SurrogateActiveClients from "../components/SuperAdminLayout/ManageAccounts/SurrogateActiveClients/TableComponent/MyClientListing/Myclient";
import LawyerActiveClients from "../components/SuperAdminLayout/ManageAccounts/LawyerActiveClients/TableComponent/MyClientListing/Myclient";
import IntendedParentsActiveClients from "../components/SuperAdminLayout/ManageAccounts/IntendedParentsActiveClients/TableComponent/MyClientListing/Myclient";
import IvfClinicActiveClients from "../components/SuperAdminLayout/ManageAccounts/IvfClinicActiveClients/TableComponent/MyClientListing/Myclient";
import AuctionResumedialog from "../components/IntendedParentsComponent/Auction/AuctionResumedialog/AuctionResumedialog";
import { ViewResume } from "../pages/superAdmin/GeneralSettings/SurrogateDonors/ViewResume";
import ActiveSurrogateMyProfile from "../components/SuperAdminLayout/ManageAccounts/SurrogateActiveClients/SurrogateActiveClients";
import IntendedParentsActiveClient from "../components/SuperAdminLayout/ManageAccounts/IntendedParentsActiveClients/IntendedParentsActiveClients";
import IvfuserProfile from "../components/SuperAdminLayout/ManageAccounts/IvfClinicActiveClients/IvfClinicActiveClients";
import LawyeruserProfile from "../components/SuperAdminLayout/ManageAccounts/LawyerActiveClients/LawyerActiveClients";
import BuyNowModal from "../components/IntendedParentsComponent/Auction/BuyNowModal";
import SuperAdminSetting from "../components/SuperAdminLayout/Settings/Setting";

import MyAuctionSpace from "../components/IntendedParentsComponent/Auction/MyAuctionSpace";

import AuctionCreatepage from "../components/SuperAdminLayout/ManageAuction/AuctionCreatepage/AuctionCreatepage";

import Dashboard_ from "../components/SurrogateComponents/Dashboard/Dashboard_";
import NewsSection from "../components/SuperAdminLayout/NewsSection/NewsSection";
import CreateNews from "../components/SuperAdminLayout/NewsSection/CreateNews/CreateNews";
import EditNews from "../components/SuperAdminLayout/NewsSection/EditNews/EditNews";
import AddPlan from "../components/SuperAdminLayout/SubscriptionPlan/addPlan/AddPlan";
import EditPlan from "../components/SuperAdminLayout/SubscriptionPlan/Edit_Plan/EditPlan";
import { SuperAdminKeeper } from "./SuperAdminKeeper";
import AuctionNew from "../components/SurrogateComponents/AuctionNew/AuctionNew";
import PaymentDialog from "../components/SurrogateComponents/SubscriptionPlan/PaymentDialog";
import SubscribePaymentModule from "../components/SurrogateComponents/SubscriptionPlan/SubscribePaymentModule";
import TermAndServicesAdmin from "../components/SuperAdminLayout/Settings/TermAndServicesAdmin";
import PrivacyPolicy from "../components/SuperAdminLayout/Settings/PrivacyPolicy";
import AboutUs from "../components/SuperAdminLayout/Settings/AboutUs";
import TermAndConditionSurrogate from "../components/SurrogateComponents/Setting/TermAndConditionSurrogate";
import PrivacyPolicySurrogate from "../components/SurrogateComponents/Setting/PrivacyPolicySurrogate";
import AboutUsSurrogate from "../components/SurrogateComponents/Setting/AboutUsSurrogate";
import FaqMainTab from "../components/SuperAdminLayout/Settings/FaqMainTab";
import FaqSurrogate from "../components/SuperAdminLayout/Settings/FaqSurrogate";
import Habbits from "../components/SuperAdminLayout/MasterList/Habbits/Habbits";
import LifeStyle from "../components/SuperAdminLayout/MasterList/LifeStyle/LifeStyle";
import ManageLeads from "../components/SuperAdminLayout/ManageLead/ManageLeads";
import Reports from "../components/SuperAdminLayout/Reports/Reports";
import ManageAuction from "../components/SuperAdminLayout/AuctionManage/ManageAuction";
import ViewDatailedProfileSurro from "../components/IntendedParentsComponent/Search/FindEggDonor/ViewDatailedProfileSurro";
import AboutUsIp from "../components/IntendedParentsComponent/Settings/AboutUsIp";
import TermAndConditonIp from "../components/IntendedParentsComponent/Settings/TermAndConditonIp";
import PrivacyPolicyIp from "../components/IntendedParentsComponent/Settings/PrivacyPolicyIp";
import AuctionList from "../components/SuperAdminLayout/ManageAuction/ListView/AuctionList";
import ApiKeys from "../components/SuperAdminLayout/GlobalSettings/ApiKeys/ApiKeys";
import { EmbryoDonorAuth } from "../components/Auth/EmbryoDonorAuth";
import EmbryoDashboard from "../components/EmbryoDonor/DashBoard/EmbryoDashboard";
import { IvfClinicAuth } from "../components/Auth/IVFClinicAuth";
import EmbryoSearch from "../components/EmbryoDonor/Search/EmbryoSearch";
import SavedContacts from "../components/EmbryoDonor/Search/SavedContacts/SavedContacts";
import EmbryoMyProfile from "../components/EmbryoDonor/MyProfile/EmbryoMyProfile";
import ViewDetailsEmbryo from "../components/EmbryoDonor/Search/ViewDetails/ViewDetailsEmbryo";
import EmbryoSetting from "../components/EmbryoDonor/Settings/EmbryoSetting";
import TermAndConditonEd from "../components/EmbryoDonor/Settings/TermAndConditonEd";
import PrivacyPolicyEd from "../components/EmbryoDonor/Settings/PrivacyPolicyEd";
import AboutUsEd from "../components/EmbryoDonor/Settings/AboutUsEd";
import AppointmentsList from "../components/IvfClinicComponents/MyClientListing/AppointmentsList/AppointmentsList";








import { LawyerAuth } from "../components/Auth/LawyerAuth";
import LawyerDashboard from "../components/LawyerComponent/Dashboard/LawyerDashboard";
import LawyerMyProfile from "../components/LawyerComponent/MyProfile/LawyerMyProfile";
import LawyerMyClient from "../components/LawyerComponent/MyClientListing/LawyerMyclient";
import LawyerClientView from "../components/LawyerComponent/MyClientTable/MyClientView/LawyerClientView";
import LawyerAppointmentsList from "../components/LawyerComponent/MyClientListing/AppointmentsList/LawyerAppointmentsList";
import LawyerCalenderListing from "../components/LawyerComponent/CalenderComponent/CalenderListing/LawyerCalenderListing";
import LawyerSetting from "../components/LawyerComponent/Setting/LawyerSetting";
import LawyerViewReport from "../components/LawyerComponent/MyClientTable/ViewReport/LawyerViewReport";
import SubscriptionPlanED from "../components/EmbryoDonor/SubscriptionPlan/SubscriptionPlanED";
import FindEmbryoDonor from "../components/IntendedParentsComponent/Search/FindEggDonor/FindEmbryoDonor";


export const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/surrogate/dashboard_" element={<Dashboard_ />} />

        <Route path="/surrogate" element={<SurrogateMother />}>
          <Route path="underconstruction" element={<UnderConstruction />} />
          <Route path="auction" element={<SurrogateAuction />} />
          <Route path="auction/createpost" element={<CreatePost />} />
          <Route
            path="auction/editpostauction/:id"
            element={<EditPostAuction />}
          />
          <Route path="resume" element={<SurrogateResume />} />
          <Route path="resume/editResume" element={<EditResume />} />
          <Route path="myprofile" element={<SurrogateMyprofile />} />
          <Route path="myprofile/editmyprofile" element={<EditMyProfile />} />
          <Route path="setting" element={<SurrogateSetting />} />
          <Route path="dashboard" element={<SurrogateDashboard />} />
          <Route path="subplan" element={<SurrogateSubscriptionPlan />}>
            <Route
              path="payment/:id"
              element={<PaymentDialog role="surrogate" />}
            />
            <Route
              path="success"
              element={<SubscribePaymentModule role="surrogate" />}
            />
          </Route>
          <Route path="auctionnew" element={<AuctionNew />} />
          <Route
            path="termAndConditionSurrogate"
            element={<TermAndConditionSurrogate />}
          />
          <Route
            path="privacyPolicySurrogate"
            element={<PrivacyPolicySurrogate />}
          />
          <Route path="aboutUssurrogate" element={<AboutUsSurrogate />} />
        </Route>

        <Route path="/" element={<SidebarWrapper role="userlisting" />}>
          <Route path="userListing" element={<Userlisting />} />
        </Route>

        <Route path="/ivfclinic" element={<IvfClinicAuth />}>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="myprofile" element={<Myprofileivf />} />

          <Route path="myclient" element={<MyclientivfClinic />} />
          {/* <Route path="myclient/clientview" element={<ClientViewivfClinic />} /> */}

          <Route path="myclient/clientview/:id" element={<ClientViewivfClinic />} />
          <Route path="dashboard/clientview/:id" element={<ClientViewivfClinic />} />
          <Route path="myclient/appointmentlist/:clientid" element={<AppointmentsList />} />
          <Route path="myclient/reportlisting/:clientid" element={<ViewReportivfClinic />} />

          <Route path="mycalender" element={<CalenderListingivfClinic />} />
          <Route path="setting" element={<SettingivfClinic />} />

          <Route path="aboutUs" element={<AboutUsSurrogate />} />
          <Route path="privacyPolicy" element={<PrivacyPolicySurrogate />} />
          <Route path="termAndCondition" element={<TermAndConditionSurrogate />} />

          <Route
            path="mycalender/doctorslist"
            element={<DoctorsListivfClinic />}
          />
          <Route
            path="mycalender/doctorslist/doctorvieworedit/:id"
            element={<EditDoctorivfClinic />}
          />

          <Route
            path="mycalender/doctorslist/editdoctor"
            element={<EditDoctorivfClinic />}
          />
        </Route>

        <Route path="/intendedParent" element={<IntendedParentsAuth />}>
          <Route path="dashboard" element={<IntendedDashboard />} />
          <Route path="subscription" element={<SubscriptionPlanip />}>
            <Route path="payment/:id" element={<PaymentDialog role="ip" />} />
            <Route path="success" element={<SubscribePaymentModule role="ip" />} />
          </Route>
          <Route path="creditReport" element={<CreditReport />} />
          <Route path="search" element={<Search />} />
          <Route path="search/:name" element={<FindEggDonor />}></Route>
          <Route path="search/Embryodonor" element={<FindEmbryoDonor />}></Route>
          <Route path="viewDetail/:id" element={<ViewDatailedProfileSurro />} />
          <Route path="search/:name/savepost" element={<SavedPost />} />
          <Route path="aboutusIp" element={<AboutUsIp />} />
          <Route path="termAndconditionIp" element={<TermAndConditonIp />} />
          <Route path="privacypolicyIp" element={<PrivacyPolicyIp />} />

          <Route path="auctions" element={<Auction />}>
            {/* <Route path="biding/:id" element={<BidNowDialog />} />
            <Route path="buyNow/:id" element={<BuyNowModal />} /> */}
          </Route>
          <Route
            path="auctions/auctionResume/:id"
            element={<AuctionResumedialog />}
          />
          <Route path="myprofile" element={<MyProfile />} />
          <Route path="settings" element={<Setting />} />
          <Route path="myauctionspace" element={<MyAuctionSpace />} />
        </Route>

        <Route path="/lawyer" element={<LawyerAuth role="lawyer" />}>
          <Route path="dashboard" element={<LawyerDashboard />} />
          <Route path="myprofile" element={<LawyerMyProfile />} />

          <Route path="myclient" element={<LawyerMyClient />} />


          <Route path="myclient/clientview/:id" element={<LawyerClientView />} />
          <Route path="dashboard/clientview/:id" element={<LawyerClientView />} />
          <Route path="myclient/appointmentlist/:clientid" element={<LawyerAppointmentsList />} />
          <Route path="myclient/reportlisting/:clientid" element={<LawyerViewReport />} />

          <Route path="mycalender" element={<LawyerCalenderListing />} />
          <Route path="setting" element={<LawyerSetting />} />

          <Route path="aboutUs" element={<AboutUsSurrogate />} />
          <Route path="privacyPolicy" element={<PrivacyPolicySurrogate />} />
          <Route path="termAndCondition" element={<TermAndConditionSurrogate />} />

          <Route
            path="mycalender/doctorslist"
            element={<DoctorsListivfClinic />}
          />
          <Route
            path="mycalender/doctorslist/doctorvieworedit/:id"
            element={<EditDoctorivfClinic />}
          />

        </Route>

        <Route path="superadmin" element={<SuperAdminLayout />} />

        <Route path="superadmin" element={<SuperAdminKeeper />}>
          <Route element={<SidebarWrapper role="superadmin" />}>
            <Route path="dashboard" >
              <Route path="socialPages" element={<SocialPagesSuperadmin />} />
              <Route path="auctions" element={<AuctionSuperAdmin />} />
              <Route path="apiKey" element={<ApiKeys />} />
            </Route>

            <Route path="manageaccounts">
              <Route path="surrogate" element={<SurrogateActiveClients />} />
              <Route path="lawyer" element={<LawyerActiveClients />} />
              <Route path="viewResume/:id" element={<ViewResume />} />
              <Route
                path="intendedparents"
                element={<IntendedParentsActiveClients />}
              />
              <Route path="ivfclinics" element={<IvfClinicActiveClients />} />
              <Route
                path="surrogate/avtivesurrgate"
                element={<ActiveSurrogateMyProfile />}
              />
              <Route
                path="intendedparents/intendeduser/:id"
                element={<IntendedParentsActiveClient />}
              />
              <Route
                path="ivfclinics/ivfuserprofile/:id"
                element={<IvfuserProfile />}
              />
              <Route
                path="lawyer/lawyeruserprofile/:id"
                element={<LawyeruserProfile />}
              />
            </Route>
            <Route path="masterlist">
              <Route path="habbits" element={<Habbits />} />
              <Route path="lifeStyle" element={<LifeStyle />} />
            </Route>
            <Route path="manageleads" element={<ManageLeads />} />
            <Route path="reports" element={<Reports />} />

            <Route
              path="manage_auction/auctionResume/:id"
              element={<AuctionResumedialog />}
            />
            <Route
              path="manageleads/viewDetail/:id"
              element={<ViewDatailedProfileSurro />}
            />
            <Route path="manageleads/viewResume/:id" element={<ViewResume />} />

            <Route
              path="manage_auction/auctionlist"
              element={<AuctionList />}
            />

            <Route
              path="manage_auction/editpostauction/:id"
              element={<EditPostAuction />}
            />
            <Route path="manage_auction" element={<ManageAuction />} />

            <Route path="setting" element={<SuperAdminSetting />}></Route>
            <Route
              path="setting/termAndServices"
              element={<TermAndServicesAdmin />}
            />
            <Route path="setting/privacyPolicy" element={<PrivacyPolicy />} />
            <Route path="setting/aboutUs" element={<AboutUs />} />
            <Route path="setting/faqselect" element={<FaqMainTab />} />
            <Route path="setting/faqselect/:mode" element={<FaqSurrogate />} />

            <Route
              path="subscriptionplan"
              element={<SubscriptionplanSuperAdmin />}
            ></Route>

            <Route path="subscriptionplan/addplan" element={<AddPlan />} />
            <Route
              path="subscriptionplan/editplan/:id"
              element={<EditPlan />}
            />

            <Route
              path="manage_auction/AuctionCreatepage"
              element={<AuctionCreatepage />}
            />

            <Route path="activeusers" element={<MyclientSuperAdmin />} />
            <Route
              path="activeusers/activesurrogateprofile/:id"
              element={<SurrogateMyprofile />}
            />
            <Route
              path="activeusers/activeintendedprofile/:id"
              element={<MyProfile />}
            />
            <Route
              path="activeusers/userprofile/:role"
              element={<UserProfileView />}
            />
            <Route path="latestnews" element={<NewsSection />} />
            <Route path="latestnews/createnews" element={<CreateNews />} />
            <Route path="latestnews/EditNews/:id" element={<EditNews />} />
          </Route>
        </Route>


        {/* 
       These are the routes below for Embryo Donor dashboard
       */}

        <Route path="/embryo_donor" element={<EmbryoDonorAuth />}>
          <Route path="dashboard" element={<EmbryoDashboard />} />
          <Route path="subscription" element={<SubscriptionPlanED />}>
            <Route path="payment/:id" element={<PaymentDialog role="embryo_donor" />} />
            <Route
              path="success"
              element={<SubscribePaymentModule role="embryo_donor" />}
            />
          </Route>
          <Route path="creditReport" element={<CreditReport />} />
          <Route path="search" element={<EmbryoSearch />} />
          {/* <Route path="search/:name" element={<FindEggDonor />}></Route> */}
          <Route path="/embryo_donor/viewDetail/:id" element={<ViewDetailsEmbryo />} />
          <Route path="search/savedcontacts" element={<SavedContacts />} />
          <Route path="aboutUsEd" element={<AboutUsEd />} />
          <Route path="privacyPolicyEd" element={<PrivacyPolicyEd />} />
          <Route path="termAndConditionEd" element={<TermAndConditonEd />} />


          <Route path="auctions" element={<Auction />}>
          </Route>

          <Route
            path="auctions/auctionResume/:id"
            element={<AuctionResumedialog />}
          />
          <Route path="myprofile" element={<EmbryoMyProfile />} />
          <Route path="settings" element={<EmbryoSetting />} />
          <Route path="myauctionspace" element={<MyAuctionSpace />} />
        </Route>


      </Routes>
    </BrowserRouter>
  );
};
