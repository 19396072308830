import { useEffect, useState } from "react"
import moment from "moment";
import { useTranslation } from "react-i18next";
import { City, Country, State } from "country-state-city";
import { FaMinus } from "react-icons/fa";
import { MdArrowForwardIos, MdOutlineFileDownload } from "react-icons/md";
import { MdOutlineArrowBackIos } from "react-icons/md";
import style from "./Resumes.module.css"
import { useNavigate } from "react-router";
import { LuDownload } from "react-icons/lu";
import { updateConciseFormOne, updateConciseFormTwo } from "../../../Api/SurrogateMotherApis";
import { userID } from "../../../utils/common";
import Loader from "../../common/Loader";
import { MultiSelect } from "primereact/multiselect";
import { gethabitsdata, getlifestyledata } from "../../../Api/admin";


export const BasicProfile = ({ data, activeEditPage, getAlldata, cti, setEditable, setActiveEditPage }) => {
    // console.log(data)
    let navigate = useNavigate()

    let [loader, setLoader] = useState(false)

    let [disabled1, setDisabled1] = useState(false);
    let [disabled2, setDisabled2] = useState(false);


    let allCountries = Country.getAllCountries()
    let [countryCode, setCountryCode] = useState("")
    let [stateCode, setStateCode] = useState("")



    let { t } = useTranslation();
    let [page, setPage] = useState(0)
    let [formData1, setFormData1] = useState({});
    let [formData2, setFormData2] = useState({});

    useEffect(() => {
        if (activeEditPage !== 0) {
            setDisabled1(false)
            setDisabled2(false)
        }

        else {
            setDisabled1(true)
            setDisabled2(true)
        }
    }, [activeEditPage])


    useEffect(() => {
        if (data.country && data.state) {
            let country = Country.getAllCountries().find(d => d.name == data.country)
            country && setCountryCode(country.isoCode)
            let state = State.getStatesOfCountry(country.isoCode).find(d => d.name == data.state)
            state && setStateCode(state.isoCode)
        }

    }, [])


    const [selectedLifeStyle, setSelectedLifeStyle] = useState([]);
    const [selectedHabits, setSelectedHabits] = useState([]);
    const [lifestyle, setLifestyle] = useState([]);
    const [habits, setHabits] = useState([]);
    const [skinColor, setSkinColor] = useState();
    let step1keys = {
        photos: [],
        firstName: "",
        middleName: "",
        lastName: "",
        not_show_fullName: false,
        height: {
            feet: "",
            inches: ""
        },
        weight: {
            LBs: "",
            oz: ""
        },
        country: "",
        state: "",
        dob: "",
        current_address: "",
        not_show_current_address: false,
        city: "",
        zipCode: "",
        no_of_kids: "",
        no_of_vaginal_deliveries: "",
        no_of_c_section_deliveries: "",
        medical_insurance: "",
        terminate_when_need: "",
        willing_to_work_with_hiv_positive_ip: "",
        willing_to_work_with_hep_b_positive_ip: "",
        currently_employed: "",
        occupation: "",
        child_details: [],
        relationship_status: "",
        skin_color: "",
        life_style: [],
        habits: [],
    }
    let step2Keys = {
        desired_compensaton: '',
        embryo_willing_to_transfer: '',
        no_of_embryo_transfer_attempts: '',
        want_to_become_surrogate: '',
        what_surrogate_means_to_you: '',
        any_concern_about_surrogacy: '',
        willing_to_work_with_non_english_speaker_ip: '',
        willing_to_reduce_caffeine: '',
        willing_to_eat_organic_food: '',
        type_of_family_want_to_work_with: '',
        NOT_want_work_with_intended_parents: '',
        describe_the_ideal_intended_Parent: '',
        note_future_intended_Parent: '',
        presence_of_ip_during_appointment_and_delivery: '',
        anyone_you_want_during_delivery: '',
        fequency_of_contact_with_ip_after_pregnancy: '',
        contact_with_ip_during_pregnancy: '',
        mode_of_communication_with_ip: '',
        willing_to_pump_breast_milk: '',
        leave_termnation_choice_on_ip_for_birth_defect: '',
        leave_termnation_choice_on_ip_for_threatening_condition: '',
        willing_to_be_placed_on_bedrest: '',
        reassurances_for_ip: '',
        describe_support_system: '',
        child_care_available: '',
        ready_to_begin: ''
    }

    async function gethobiesandlifestyle(habits, lifestyle) {
        await getLifestyle(lifestyle);
        await getHabits(habits);
    }

    const getLifestyle = async (lifestyle) => {
        var result = await getlifestyledata();
        if (lifestyle && lifestyle.length !== 0) {
            setSelectedLifeStyle(
                lifestyle
            );
            console.log(
                result?.data?.data?.filter((dd) => lifestyle?.includes(dd._id))
            );
        }

        setLifestyle(result.data.data);
    };

    const getHabits = async (habits) => {
        var result = await gethabitsdata();
        if (habits && habits.length !== 0) {
            setSelectedHabits(
                habits
            );
            console.log(result?.data?.data?.filter((dd) => habits?.includes(dd._id)));
        }
        console.log(habits)
        setHabits(result.data.data);
    };


    useEffect(() => {
        let obj = {};
        for (let i in { ...step1keys }) {
            if (data?.hasOwnProperty(i)) {
                obj[i] = data?.[i]
            }
            else {
                obj[i] = data[i]
            }
        }

        let combo = { ...step1keys, ...obj }
        setFormData1(combo);
        console.log(combo);
        gethobiesandlifestyle(combo?.habits, combo?.life_style);

        setFormData1((pre) => ({ ...pre, ...obj }));
        let obj2 = {};
        for (let i in { ...step2Keys }) {
            if (data?.hasOwnProperty(i)) {
                obj2[i] = data?.[i]
            }
            else {
                obj2[i] = data[i]
            }
        }

        setFormData2((pre) => ({ ...pre, ...obj2 }));
    }, [page])

    function handleInputChange(e) {
        let { name, value } = e.target
        setFormData1(pre => ({ ...pre, [name]: value }))
    }

    async function handleSubmit1(e) {
        e.preventDefault()
        let id = userID()
        let data = {
            ...formData1, life_style: selectedLifeStyle,
            habits: selectedHabits,
        }

        data.photos = formData1.photos.map(({ _id, ...rest }) => rest)
        data.child_details = formData1.child_details.map(({ _id, ...rest }) => rest)

        try {
            setLoader(true)
            let res = await updateConciseFormOne(data, id)
            // console.log(res)
            getAlldata()

            setLoader(false)
        }
        catch (err) {
            console.log(err)
            setLoader(false)
        } finally {
            setDisabled1(true)
            setEditable(true)
            setActiveEditPage(0)
        }
    }

    function countryStateCityInpChange(e, input) {
        let data = JSON.parse(e.target.value)
        let obj = {}
        switch (input) {
            case "country":
                setCountryCode(data.isoCode)
                obj.country = data.name
                obj.state = null
                obj.city = null
                break;
            case "state":
                setStateCode(data.isoCode)
                obj.state = data.name
                obj.city = null
                break;
            case "city":
                obj.city = data.name
                break;
        }
        setFormData1(pre => ({ ...pre, ...obj }))

    }
    function radioButtonChange(e, val) {
        let { name } = e.target;
        setFormData1(pre => {
            pre[name] = val;
            return ({ ...pre })
        })
    };
    function deletechild(ind) {
        setFormData1(pre => {
            let dd = { ...pre }
            let ddd = [...dd.child_details]

            ddd.splice(ind, 1)
            dd.child_details = [...ddd]

            return ({ ...dd })
        })
    }

    function addChild() {


        setFormData1(pre => ({
            ...pre, child_details: [...pre.child_details, {
                gender: "",
                dob: "",
                weeks_at_delivery: "",
                body_weight: {
                    lbs: "",
                    oz: ""
                },
                surrogacy: "",
                singleton_or_multiple: ""
            }]
        }))

    }




    function onInputHandleChange(e) {
        let { value, name, type } = e.target
        if (type === 'radio') {
            if (value == 'true' || value == 'false') {
                setFormData2(pre => {
                    return {
                        ...pre,
                        [name]: JSON.parse(value)
                    }
                })
            } else {
                setFormData2(pre => {
                    return {
                        ...pre,
                        [name]: value
                    }
                })
            }


        } else {
            if (value === ' ') {
                value = ''
            } else {
                if (type === 'number') {
                    setFormData2(pre => {
                        return {
                            ...pre,
                            [name]: parseInt(value)
                        }
                    })
                } else {
                    setFormData2(pre => {
                        return {
                            ...pre,
                            [name]: value
                        }
                    })
                }
            }
        }
    }

    async function handleSubmit2(e) {

        e.preventDefault()
        let id = userID()
        let data = { ...formData2 }

        try {
            setLoader(true)
            let res = await updateConciseFormTwo(data, id)
            setLoader(false)
            getAlldata()
            console.log(res)
        }
        catch (err) {
            setLoader(false)
            // console.log(err)
        } finally {
            setDisabled2(true)
            setEditable(true)
            setActiveEditPage(0)
        }
    }


    return (
        <>
            <Loader open={loader} />
            <section className={style.basicProfile_con}>

                <div className="d-flex gap-3 mt-4 align-items-center justify-content-end">

                    <div className="d-flex justify-content-end">
                        <button className={style.v_download_btn} onClick={cti}>
                            <MdOutlineFileDownload />  Download Profile
                        </button>

                    </div>

                    <div className="d-flex justify-content-end align-items-center gap-3">
                        <div onClick={() => setPage(0)} className={`${style.arrow_icon} ${page === 1 ? style.active_arrow_icon : ""}`}><MdOutlineArrowBackIos /></div>
                        <span className="lufga_20px">Page {page === 1 ? 2 : 1}</span>
                        <div onClick={() => setPage(1)} className={`${style.arrow_icon} ${page === 0 ? style.active_arrow_icon : ""}`}>
                            <MdArrowForwardIos /></div>
                    </div>
                </div>


                {page == 0 ?
                    <form onSubmit={handleSubmit1}>
                        <div className="row">

                            <div className="col-md-6 col-xl-3 mt-4">
                                <label className="form-label fs-5" >{t("First Name")}<span className='text-danger'>*</span></label>
                                <input
                                    disabled={disabled1}
                                    required
                                    type='text'
                                    className='form-control'
                                    placeholder={t("First Name")}
                                    name='firstName'
                                    value={formData1.firstName}
                                    onChange={handleInputChange}
                                />
                            </div>

                            <div className="col-md-6 col-xl-3 mt-4">
                                <label className="form-label fs-5" >{t("Middle Initial")}</label>
                                <input disabled={disabled1}
                                    type='text'
                                    className='form-control'
                                    placeholder={t("Middle Name")}
                                    name='middleName'
                                    value={formData1.middleName}
                                    onChange={handleInputChange}
                                />
                            </div>

                            <div className="col-md-6 col-xl-3 mt-4">
                                <label className="form-label fs-5" >{t("Last Name")}<span className='text-danger'>*</span></label>
                                <input disabled={disabled1}
                                    required
                                    type='text'
                                    className='form-control'
                                    placeholder={t("Last Name")}
                                    name='lastName'
                                    value={formData1.lastName}
                                    onChange={handleInputChange}
                                />
                            </div>

                            <div className="col-md-6 col-xl-3 mt-4">
                                <label className="form-label fs-5" >{t("Date of Birth")}<span className='text-danger'>*</span></label>
                                <input disabled={disabled1} type='date'
                                    className='form-control'
                                    placeholder={t("Date of Birth")}
                                    name='dob'
                                    required
                                    max={moment().format('YYYY-MM-DD')}
                                    value={formData1.dob && moment(formData1.dob).format("YYYY-MM-DD")}
                                    onChange={(e) => {
                                        handleInputChange(e)
                                        // ageCalculate(e.target.value)
                                    }}
                                />
                            </div>

                            <div className="col-12 mt-2">
                                <input disabled={disabled1}
                                    checked={formData1.not_show_fullName === true}
                                    onChange={(e) => {
                                        setFormData1(pre => {
                                            pre.not_show_fullName = e.target.checked
                                            return ({ ...pre })
                                        })
                                    }}
                                    name='not_show_fullName' type="checkbox" />
                                <label className='ms-2 lufga'>Not Shown on Public Profie</label>
                            </div>

                            <div className="col-md-12">
                                <div className="row">
                                    <div className='col-md-6 mt-4'>
                                        <label htmlFor="exampleInputEmail1" className={`form-label  fs-5`}>{t("Height")} <span className='text-danger'>*</span></label>

                                        <div className='d-flex justify-content-between gap-2'>
                                            <input type='number'
                                                disabled={disabled1}
                                                className='form-control'
                                                //placeholder=''
                                                value={formData1?.height?.feet}
                                                required
                                                onChange={(e) => {
                                                    setFormData1(pre => {
                                                        pre.height.feet = e.target.value;
                                                        return { ...pre }
                                                    })
                                                }}
                                            />

                                            <div className="d-flex align-items-center">{t("Ft")}</div>
                                            <input type='number'
                                                disabled={disabled1}
                                                className='form-control'
                                                //placeholder=''
                                                value={formData1?.height?.inches}
                                                required
                                                onChange={(e) => {
                                                    setFormData1(pre => {
                                                        pre.height.inches = e.target.value;
                                                        return { ...pre }
                                                    })
                                                }}
                                            />
                                            <div className="d-flex align-items-center">{t("Inches")}</div>
                                        </div>


                                    </div>

                                    <div className='col-md-6 mt-4'>
                                        <label htmlFor="exampleInputEmail1" className={`form-label fs-5`}>{t("Weight")} <span className='text-danger'>*</span></label>

                                        <div className='d-flex justify-content-between gap-2'>
                                            <input type='number'
                                                disabled={disabled1}
                                                className='form-control'
                                                //placeholder=''
                                                required
                                                value={formData1?.weight?.LBs}
                                                onChange={(e) => {
                                                    setFormData1(pre => {
                                                        pre.weight.LBs = e.target.value;
                                                        return { ...pre }
                                                    })
                                                }}
                                            />
                                            <div className="d-flex align-items-center">{t("Lbs")}</div>
                                            <input type='number'
                                                disabled={disabled1}
                                                className='form-control'
                                                //placeholder=''
                                                required
                                                value={formData1?.weight?.oz}
                                                onChange={(e) => {
                                                    setFormData1(pre => {
                                                        pre.weight.oz = e.target.value;
                                                        return { ...pre }
                                                    })
                                                }}
                                            />
                                            <div className="d-flex align-items-center">{t("Oz")}</div>
                                        </div>


                                    </div>

                                </div>
                            </div>


                            <div className="col-md-12 mt-4">
                                <label className="form-label fs-5" >{t("Current Address")}<span className='text-danger'>*</span></label>
                                <input disabled={disabled1}
                                    type='text'
                                    required
                                    className='form-control'
                                    placeholder={t("Current Addresss")}
                                    name='current_address'
                                    value={formData1.current_address}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col-12 mt-2">
                                <input disabled={disabled1}
                                    checked={formData1.not_show_current_address === true}
                                    onChange={(e) => {
                                        setFormData1(pre => {
                                            pre.not_show_current_address = e.target.checked
                                            return ({ ...pre })
                                        })
                                    }}
                                    name='not_show_current_address' type="checkbox" />
                                <label className='ms-2 lufga'>Not Shown on Public Profie</label>
                            </div>

                            <div className='col-md-6 col-xl-3 mt-4'>
                                <label className="form-label fs-5" >{t("Country")} <span className='text-danger'>*</span></label>
                                <select disabled={disabled1}
                                    required
                                    onChange={(e) => countryStateCityInpChange(e, "country")}
                                    value={JSON.stringify(allCountries.find(e => e.name == formData1.country))}
                                    className='form-select'>
                                    <option value={""}>Select Country</option>
                                    {allCountries.map((res, key) => {
                                        return (
                                            <option value={JSON.stringify(res)}>{res.name}</option>
                                        )
                                    })}
                                </select>
                            </div>

                            <div className='col-md-6 col-xl-3 mt-4'>
                                <label className="form-label fs-5">{t("State of Residence")} </label>
                                <select disabled={disabled1}
                                    value={JSON.stringify(State.getStatesOfCountry(countryCode).find(e => e.name == formData1.state))}
                                    onChange={(e) => countryStateCityInpChange(e, "state")}
                                    className={`form-select`}

                                >
                                    <option value={""}>{t('Select')}</option>
                                    {State.getStatesOfCountry(countryCode).map((res, key) => {
                                        return (
                                            <option value={JSON.stringify(res)}>{res.name}</option>
                                        )
                                    })
                                    }
                                </select>

                            </div>
                            <div className='col-md-6 col-xl-3 mt-4'>
                                <label className="form-label fs-5" >{t("City")} </label>
                                <select disabled={disabled1}
                                    value={JSON.stringify(City.getCitiesOfState(countryCode, stateCode).find(e => e.name == formData1.city))}
                                    onChange={(e) => countryStateCityInpChange(e, "city")} className={`form-select`}>
                                    <option value={""}>{t('Select')}</option>
                                    {City.getCitiesOfState(countryCode, stateCode).map((res, key) => {
                                        return (
                                            <option value={JSON.stringify(res)}>{res.name}</option>
                                        )
                                    })}
                                </select>
                            </div>

                            <div className='col-md-6 col-xl-3 mt-4'>
                                <label className="form-label fs-5" >{t("Zip Code")}<span className='text-danger'>*</span></label>
                                <input disabled={disabled1}
                                    type='number'
                                    required
                                    className="form-control"
                                    name="zipCode"
                                    value={formData1.zipCode}
                                    onChange={handleInputChange} />

                            </div>

                            <div className='col-md-4 mt-4'>
                                <label className="form-label fs-5" >No of Kids<span className='text-danger'>*</span></label>
                                <input disabled={disabled1}
                                    type='number'
                                    required
                                    className="form-control"
                                    name="no_of_kids"
                                    value={formData1.no_of_kids}
                                    onChange={handleInputChange} />

                            </div>

                            <div className='col-md-4 mt-4'>
                                <label className="form-label fs-5" >No of Vaginal Deliveries<span className='text-danger'>*</span></label>
                                <input disabled={disabled1}
                                    type='number'
                                    required
                                    className="form-control"
                                    name="no_of_vaginal_deliveries"
                                    value={formData1.no_of_vaginal_deliveries}
                                    onChange={handleInputChange} />

                            </div>
                            <div className='col-md-4 mt-4'>
                                <label className="form-label fs-5" >No. of C-Section Deliveries<span className='text-danger'>*</span></label>
                                <input disabled={disabled1}
                                    type='number'
                                    required
                                    className="form-control"
                                    name="no_of_c_section_deliveries"
                                    value={formData1.no_of_c_section_deliveries}
                                    onChange={handleInputChange} />
                            </div>
                            <div className="col-md-4 mt-4">
                                <label className="form-label fs-5">
                                    {t("Select your lifestyle")}
                                    <span className="text-danger">*</span>
                                </label>

                                <MultiSelect
                                    value={selectedLifeStyle}
                                    required
                                    onChange={(e) => {
                                        setSelectedLifeStyle(e.value);
                                        console.log(e.value);
                                    }}


                                    options={lifestyle.map((e) => {
                                        return {
                                            value: e._id,
                                            name: e.name,
                                        };
                                    })}
                                    // options={lifestyle}

                                    optionLabel="name"
                                    display="chip"
                                    showSelectAll={false}
                                    closeIcon={false}
                                    placeholder=" "
                                    maxSelectedLabels={5}
                                    className="multiSelectDropDown py-1 ps-2"
                                    name="life_style"
                                    disabled={disabled1}
                                />
                            </div>

                            <div className="col-md-4 mt-4">
                                <label className="form-label fs-5">
                                    {t("Select your habits")}
                                    <span className="text-danger">*</span>
                                </label>
                                <MultiSelect
                                    value={selectedHabits}
                                    required
                                    onChange={(e) => setSelectedHabits(e.value)}
                                    // options={cities2}
                                    options={habits.map((e) => {
                                        return {
                                            value: e._id,
                                            name: e.name,
                                        };
                                    })}
                                    optionLabel="name"
                                    display="chip"
                                    placeholder=" "
                                    showSelectAll={false}
                                    closeIcon={false}
                                    maxSelectedLabels={5}
                                    className="multiSelectDropDown py-1 ps-2"
                                    name="habits"
                                    disabled={disabled1}
                                />
                            </div>
                            <div className="col-md-4 mt-4">
                                <label className="form-label fs-5">
                                    {t("Select your Skin Color")}
                                    <span className="text-danger">*</span>
                                </label>
                                <select
                                    className="form-select p-2"
                                    aria-label="Default select example"
                                    name="skin_color"
                                    value={formData1.skin_color}
                                    onChange={handleInputChange}
                                    disabled={disabled1}
                                >
                                    <option selected disabled>Select skin color</option>
                                    <option value="Light, pale white">Light, pale white</option>
                                    <option value="white, fair">white, fair</option>
                                    <option value="Medium white to olive">
                                        Medium white to olive
                                    </option>
                                    <option value="Olive, mid brown">Olive, mid brown</option>
                                    <option value="Brown, dark brown">Brown, dark brown</option>
                                    <option value="Very dark brown, black">
                                        Very dark brown, black
                                    </option>
                                </select>
                            </div>

                            <div className="col-md-12 mt-4">
                                <label className="form-label fs-5">
                                    {t("Current marital/relationship status")}?
                                    <span className="text-danger">*</span>
                                </label>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-check">
                                            <input
                                                required
                                                disabled={disabled1}
                                                className="form-check-input"
                                                id="relationship_status1"
                                                type="radio"
                                                onChange={(e) => radioButtonChange(e, e.target.value)}
                                                checked={formData1.relationship_status === "Married"}
                                                name="relationship_status"
                                                value="Married"
                                            />

                                            <label
                                                htmlFor="relationship_status1"
                                                className="form-check-label LabeSubHeading "
                                            >
                                                {t("Married")}
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-check">
                                            <input
                                                required
                                                disabled={disabled1}
                                                className="form-check-input"
                                                id="relationship_status1"
                                                type="radio"
                                                onChange={(e) => radioButtonChange(e, e.target.value)}
                                                checked={formData1.relationship_status === 'Single, No Partner'}
                                                name="relationship_status"
                                                value="Single, No Partner"
                                            />
                                            <label
                                                htmlFor="relationship_status1"
                                                className="form-check-label LabeSubHeading "
                                            >
                                                {t("Single, No Partner")}
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-check">
                                            <input
                                                required
                                                disabled={disabled1}
                                                className="form-check-input"
                                                id="relationship_status1"
                                                type="radio"
                                                onChange={(e) => radioButtonChange(e, e.target.value)}
                                                checked={formData1.relationship_status === "Not married, but in a committed relationship"}
                                                name="relationship_status"
                                                value="Not married, but in a committed relationship"
                                            />
                                            <label
                                                htmlFor="relationship_status1"
                                                className="form-check-label LabeSubHeading "
                                            >
                                                {t("Not married, but in a committed relationship")}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row col-md-6 mt-4">
                                <label className="form-label fs-5">Do you have Insurance?</label>
                                <div className="col-md-6">
                                    <div className="form-check">
                                        <input disabled={disabled1}
                                            required
                                            className="form-check-input"
                                            id="medical_insuranceId1"
                                            type="radio"
                                            onChange={(e) => radioButtonChange(e, true)}
                                            checked={formData1.medical_insurance === true}
                                            name="medical_insurance"
                                        />
                                        <label htmlFor='medical_insuranceId1' className="form-check-label LabeSubHeading " >{t("Yes")}</label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-check">
                                        <input disabled={disabled1}
                                            required
                                            className="form-check-input"
                                            id="medical_insuranceId2"
                                            type="radio"
                                            onChange={(e) => radioButtonChange(e, false)}
                                            checked={formData1.medical_insurance === false}
                                            name="medical_insurance"
                                        />
                                        <label htmlFor='medical_insuranceId2' className="form-check-label LabeSubHeading " >{t("No")}</label>
                                    </div>
                                </div>
                            </div>


                            <div className="row col-md-6 mt-4">
                                <label className="form-label fs-5">Will you terminate when needed ?</label>

                                <div className="col-md-6">
                                    <div className="form-check">
                                        <input disabled={disabled1}
                                            required
                                            className="form-check-input"
                                            id="terminate_when_need1"
                                            type="radio"
                                            onChange={(e) => radioButtonChange(e, true)}
                                            checked={formData1.terminate_when_need === true}
                                            name="terminate_when_need"
                                        />
                                        <label htmlFor='terminate_when_need1' className="form-check-label LabeSubHeading " >{t("Yes")}</label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-check">
                                        <input disabled={disabled1}
                                            required
                                            className="form-check-input"
                                            id="terminate_when_need2"
                                            type="radio"
                                            onChange={(e) => radioButtonChange(e, false)}
                                            checked={formData1.terminate_when_need === false}
                                            name="terminate_when_need"
                                        />
                                        <label htmlFor='terminate_when_need2' className="form-check-label LabeSubHeading " >{t("No")}</label>
                                    </div>
                                </div>
                            </div>


                            <div className="row col-md-6 mt-4">
                                <label className="form-label fs-5">Are you willing to work with HIV positive intended parent?</label>
                                <div className="col-md-3">
                                    <div className="form-check">
                                        <input disabled={disabled1}
                                            required
                                            className="form-check-input"
                                            id="willing_to_work_with_hiv_positive_ip1"
                                            type="radio"
                                            onChange={(e) => radioButtonChange(e, true)}
                                            checked={formData1.willing_to_work_with_hiv_positive_ip === true}
                                            name="willing_to_work_with_hiv_positive_ip"
                                        />
                                        <label htmlFor='willing_to_work_with_hiv_positive_ip1' className="form-check-label LabeSubHeading " >{t("Yes")}</label>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-check">
                                        <input disabled={disabled1}
                                            required
                                            className="form-check-input"
                                            id="willing_to_work_with_hiv_positive_ip2"
                                            type="radio"
                                            onChange={(e) => radioButtonChange(e, false)}
                                            checked={formData1.willing_to_work_with_hiv_positive_ip === false}
                                            name="willing_to_work_with_hiv_positive_ip"
                                        />
                                        <label htmlFor='willing_to_work_with_hiv_positive_ip2' className="form-check-label LabeSubHeading " >{t("No")}</label>
                                    </div>
                                </div>
                            </div>


                            <div className="row col-md-6 mt-4">
                                <label className="form-label fs-5">Are you willing to work with HEP B positive intended parent?</label>
                                <div className="col-md-3">
                                    <div className="form-check">
                                        <input disabled={disabled1}
                                            required
                                            className="form-check-input"
                                            id="willing_to_work_with_hep_b_positive_ip1"
                                            type="radio"
                                            onChange={(e) => radioButtonChange(e, true)}
                                            checked={formData1.willing_to_work_with_hep_b_positive_ip === true}
                                            name="willing_to_work_with_hep_b_positive_ip"
                                        />
                                        <label htmlFor='willing_to_work_with_hep_b_positive_ip1' className="form-check-label LabeSubHeading " >{t("Yes")}</label>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-check">
                                        <input disabled={disabled1}
                                            required
                                            className="form-check-input"
                                            id="willing_to_work_with_hep_b_positive_ip2"
                                            type="radio"
                                            onChange={(e) => radioButtonChange(e, false)}
                                            checked={formData1.willing_to_work_with_hep_b_positive_ip === false}
                                            name="willing_to_work_with_hep_b_positive_ip"
                                        />
                                        <label htmlFor='willing_to_work_with_hep_b_positive_ip2' className="form-check-label LabeSubHeading " >{t("No")}</label>
                                    </div>
                                </div>
                            </div>


                            <div className="row col-6 mt-4">
                                <label className="form-label fs-5">Are you Employed?</label>

                                <div className="col-md-6">
                                    <div className="form-check">
                                        <input disabled={disabled1}
                                            required
                                            className="form-check-input"
                                            id="currently_employed1"
                                            type="radio"
                                            name="currently_employed"
                                            onChange={(e) => radioButtonChange(e, true)}
                                            checked={formData1.currently_employed === true}
                                        />
                                        <label htmlFor='currently_employed1' className="form-check-label LabeSubHeading " >{t("Yes")}</label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-check">
                                        <input disabled={disabled1}
                                            required
                                            className="form-check-input"
                                            id="currently_employed2"
                                            type="radio"
                                            onChange={(e) => radioButtonChange(e, false)}
                                            checked={formData1.currently_employed === false}
                                            name="currently_employed"
                                        />
                                        <label htmlFor='currently_employed2' className="form-check-label LabeSubHeading " >{t("No")}</label>
                                    </div>
                                </div>
                            </div>

                            {formData1.currently_employed ? <div className='col-md-6 mt-4'>
                                <label className="form-label fs-5" >Job Role?<span className='text-danger'>*</span></label>
                                <input disabled={disabled1}
                                    type='text'
                                    required
                                    className="form-control"
                                    name="occupation"
                                    value={formData1.occupation}
                                    onChange={handleInputChange} />

                            </div> : null}

                        </div>

                        <div className={`${style.second_heading} text-center my-5`}>Reproductive Information</div>

                        <div className={`${style.complete_text}`}>Complete the following for each child you have delivered:</div>




                        {formData1?.child_details?.map((res, key) => {
                            return (
                                <div key={key} className='mb-3'>
                                    <div className={`${style.complete_text} d-flex align-items-center`}>
                                        <button className={`${style.addChild_btn} me-2`} type='button' disabled={disabled1} onClick={() => deletechild(key)}><FaMinus /></button>
                                        Child #{key + 1}
                                    </div>

                                    <div className="row">

                                        <div className='col-6 col-xl-3 mt-4'>
                                            <label className="form-label fs-5" >Date of Delivery<span className='text-danger'>*</span></label>
                                            <input disabled={disabled1}
                                                type='date'
                                                required
                                                className="form-control"

                                                onChange={(e) => {
                                                    setFormData1(pre => {
                                                        pre.child_details[key].dob = e.target.value
                                                        return ({ ...pre })
                                                    })
                                                }}
                                                value={res?.dob && moment(res?.dob).format("YYYY-MM-DD")}
                                            />

                                        </div>

                                        <div className='col-6 col-xl-3 mt-4'>
                                            <label className="form-label fs-5" >Weeks at Delivery<span className='text-danger'>*</span></label>
                                            <input disabled={disabled1}
                                                type='text'
                                                required
                                                value={res?.weeks_at_delivery}
                                                className="form-control"
                                                onChange={(e) => {
                                                    setFormData1(pre => {
                                                        pre.child_details[key].weeks_at_delivery = e.target.value
                                                        return ({ ...pre })
                                                    })
                                                }}
                                            />

                                        </div>

                                        <div className="row col-6 col-xl-3 mt-4">
                                            <label className="form-label fs-5" >Baby’s Weight<span className='text-danger'>*</span></label>
                                            <div className='col-6 d-flex align-items-center'>
                                                <input disabled={disabled1}
                                                    required
                                                    type='number'
                                                    onChange={(e) => {
                                                        setFormData1(pre => {
                                                            pre.child_details[key].body_weight.lbs = e.target.value
                                                            return ({ ...pre })
                                                        })
                                                    }}
                                                    value={res?.body_weight?.lbs} className='form-control' />
                                                <span className='ms-2'>Lbs</span>
                                            </div>

                                            <div className='col-6 d-flex align-items-center'>
                                                <input disabled={disabled1}
                                                    required
                                                    type='number'
                                                    onChange={(e) => {
                                                        setFormData1(pre => {
                                                            pre.child_details[key].body_weight.oz = e.target.value
                                                            return ({ ...pre })
                                                        })
                                                    }}
                                                    value={res?.body_weight?.oz} className='form-control' />
                                                <span className='ms-2'>Oz</span>
                                            </div>

                                        </div>

                                        {/* <div className='col-md-4'>
                                            <label className="form-label fs-5" >Gender<span className='text-danger'>*</span></label>
                                            <select disabled={disabled1}
                                                required
                                                name='gender'
                                                value={res?.gender}
                                                onChange={(e) => {
                                                    setFormData1(pre => {
                                                        pre.child_details[key].gender = e.target.value
                                                        return ({ ...pre })
                                                    })
                                                }}
                                                className='form-select'>
                                                <option value={""}>Select Gender</option>
                                                <option value={"Male"}>Male</option>
                                                <option value={"Female"}>Female</option>
                                            </select>

                                        </div> */}

                                        <div className='col-6 col-xl-3 mt-4'>
                                            <label className="form-label fs-5" >Delivery Type<span className='text-danger'>*</span></label>
                                            <input disabled={disabled1}
                                                required
                                                className='form-control'
                                                value={res?.delivery_type}
                                                onChange={(e) => {
                                                    setFormData1(pre => {
                                                        pre.child_details[key].delivery_type = e.target.value
                                                        return ({ ...pre })
                                                    })
                                                }}
                                            />
                                        </div>

                                        <div className="row col-6 mt-3">
                                            <label className="form-label fs-5">Surrogacy?</label>
                                            <div className="col-md-6">
                                                <div className="form-check">
                                                    <input disabled={disabled1}
                                                        required
                                                        className="form-check-input"
                                                        onChange={(e) => {
                                                            setFormData1(pre => {
                                                                pre.child_details[key].surrogacy = true
                                                                return ({ ...pre })
                                                            })
                                                        }}
                                                        type="radio"
                                                        name={`surrogacy${key}`}
                                                        checked={formData1.child_details[key].surrogacy == true}

                                                    />
                                                    <label className="form-check-label LabeSubHeading " >{t("Yes")}</label>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-check">
                                                    <input disabled={disabled1}
                                                        required
                                                        className="form-check-input"
                                                        onChange={(e) => {
                                                            setFormData1(pre => {
                                                                pre.child_details[key].surrogacy = false
                                                                return ({ ...pre })
                                                            })
                                                        }}
                                                        checked={formData1.child_details[key].surrogacy === false}
                                                        type="radio"
                                                        name={`surrogacy${key}`}
                                                    />
                                                    <label className="form-check-label LabeSubHeading " >{t("No")}</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row col-6 mt-3">
                                            <label className="form-label fs-5">Singleton or Multiple?</label>
                                            <div className="col-md-6">
                                                <div className="form-check">
                                                    <input disabled={disabled1}
                                                        required
                                                        className="form-check-input"
                                                        onChange={(e) => {
                                                            setFormData1(pre => {
                                                                pre.child_details[key].singleton_or_multiple = "Single"
                                                                return ({ ...pre })
                                                            })
                                                        }}
                                                        checked={formData1.child_details[key].singleton_or_multiple == "Single"}
                                                        type="radio"
                                                        name={`singleton_or_multiple${key}`}
                                                    />
                                                    <label className="form-check-label LabeSubHeading " >Singleton</label>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-check">
                                                    <input disabled={disabled1}
                                                        required
                                                        className="form-check-input"
                                                        onChange={(e) => {
                                                            setFormData1(pre => {
                                                                pre.child_details[key].singleton_or_multiple = "Multiple"
                                                                return ({ ...pre })
                                                            })
                                                        }}
                                                        checked={formData1.child_details[key].singleton_or_multiple == "Multiple"}
                                                        type="radio"
                                                        name={`singleton_or_multiple${key}`}
                                                    />
                                                    <label className="form-check-label LabeSubHeading">Multiple</label>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            )
                        })}
                        <div className="col-12 d-flex w-100 justify-content-center mt-4">
                            <button disabled={disabled1} className={`${style.addChild_btn}`} type='button' onClick={addChild}>+ Add Child</button>
                        </div>
                        <div className="text-end">
                            {!disabled1 &&
                                <button className="btn_fill px-5" type='submit'>Update</button>}

                        </div>

                    </form> :

                    <form onSubmit={handleSubmit2}>
                        <div className="row">
                            <div className="col-md-6 col-xl-4 mt-4 d-flex flex-column">
                                <label className="form-label fs-5" >Desired Compensation<span className='text-danger'>*</span></label>
                                <div className="d-flex gap-1 align-items-center mt-auto">
                                    <div className="theme_color lufga_22px">$</div>
                                    <input disabled={disabled2}
                                        required
                                        type='number'
                                        className='form-control'
                                        name='desired_compensaton'
                                        value={formData2.desired_compensaton}
                                        onChange={onInputHandleChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-xl-4 mt-4 d-flex flex-column">
                                <label className="form-label fs-5" >Embryos willing to transfer<span className='text-danger'>*</span></label>
                                <input disabled={disabled2}
                                    required
                                    type='number'
                                    className='form-control mt-auto'
                                    name='embryo_willing_to_transfer'
                                    value={formData2.embryo_willing_to_transfer}
                                    onChange={onInputHandleChange}
                                />
                            </div>
                            <div className="col-md-6 col-xl-4 mt-4 d-flex flex-column">
                                <label className="form-label fs-5" >Number of Embryo Transfer Attempts<span className='text-danger'>*</span></label>
                                <input disabled={disabled2}
                                    required
                                    type='number'
                                    className='form-control mt-auto'
                                    name='no_of_embryo_transfer_attempts'
                                    value={formData2.no_of_embryo_transfer_attempts}
                                    onChange={onInputHandleChange}
                                />
                            </div>

                            <div className="col-md-12 mt-4 d-flex flex-column">
                                <label className="form-label fs-5" >Why do you want to become a surrogate? What first interested you in surrogacy?<span className='text-danger'>*</span></label>
                                <textarea disabled={disabled2}
                                    rows={3}
                                    required
                                    type='text'
                                    className='form-control mt-auto'
                                    name='want_to_become_surrogate'
                                    value={formData2.want_to_become_surrogate}
                                    onChange={onInputHandleChange}
                                />
                            </div>

                            <div className="col-md-12 mt-4 d-flex flex-column">
                                <label className="form-label fs-5" >What does being a surrogate mean to you?<span className='text-danger'>*</span></label>
                                <textarea disabled={disabled2}
                                    rows={3}
                                    required
                                    type='text'
                                    className='form-control mt-auto'
                                    name='what_surrogate_means_to_you'
                                    value={formData2.what_surrogate_means_to_you}
                                    onChange={onInputHandleChange}
                                />
                            </div>

                            <div className="col-md-12 mt-4 d-flex flex-column">
                                <label className="form-label fs-5" >Do you have any concerns about surrogacy?<span className='text-danger'>*</span></label>
                                <textarea disabled={disabled2}
                                    rows={3}
                                    required
                                    type='text'
                                    className='form-control mt-auto'
                                    name='any_concern_about_surrogacy'
                                    value={formData2.any_concern_about_surrogacy}
                                    onChange={onInputHandleChange}
                                />
                            </div>

                            <div className="col-md-12 mt-4">
                                <label className="form-label fs-5" >Are you willing to work with Intended Parent(s) that does not speak English (translation provided)?<span className='text-danger'>*</span></label>
                                <input
                                    disabled={disabled2}
                                    rows={3}
                                    required
                                    type='text'
                                    className='form-control'
                                    name='willing_to_work_with_non_english_speaker_ip'
                                    value={formData2.willing_to_work_with_non_english_speaker_ip}
                                    onChange={onInputHandleChange}
                                />
                            </div>


                            <div className="col-6 mt-4 d-flex flex-column">
                                <label className="form-label fs-5">Are you willing to reduce caffeine during pregnancy?</label>

                                <div className="row mt-auto mb-auto">

                                    <div className="col-md-6">
                                        <div className="form-check">
                                            <input disabled={disabled2}
                                                required
                                                className="form-check-input"
                                                type="radio"
                                                checked={formData2.willing_to_reduce_caffeine === true}
                                                name='willing_to_reduce_caffeine'
                                                value='true'
                                                onChange={onInputHandleChange}
                                            />
                                            <label htmlFor='medical_insuranceId1' className="form-check-label LabeSubHeading " >{t("Yes")}</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-check">
                                            <input
                                                required disabled={disabled2}
                                                className="form-check-input"
                                                type="radio"
                                                checked={formData2.willing_to_reduce_caffeine === false}
                                                name='willing_to_reduce_caffeine'
                                                value='false'
                                                onChange={onInputHandleChange}
                                            />
                                            <label htmlFor='medical_insuranceId2' className="form-check-label LabeSubHeading " >{t("No")}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-6 mt-4 d-flex flex-column">
                                <label className="form-label fs-5">Are you willing to eat organic foods, if requested (and paid for) by the Intended Parent(s)?</label>
                                <div className="row mt-auto">

                                    <div className="col-md-6">
                                        <div className="form-check">
                                            <input disabled={disabled2}
                                                required
                                                className="form-check-input"
                                                type="radio"
                                                checked={formData2.willing_to_eat_organic_food === true}
                                                name='willing_to_eat_organic_food'
                                                onChange={onInputHandleChange}
                                                value='true'
                                            />
                                            <label htmlFor='medical_insuranceId1' className="form-check-label LabeSubHeading " >{t("Yes")}</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-check">
                                            <input disabled={disabled2}
                                                required
                                                className="form-check-input"
                                                type="radio"
                                                name='willing_to_eat_organic_food'
                                                checked={formData2.willing_to_eat_organic_food === false}
                                                onChange={onInputHandleChange}
                                                value='false'
                                            />
                                            <label htmlFor='medical_insuranceId2' className="form-check-label LabeSubHeading " >{t("No")}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-md-6 mt-4 d-flex flex-column">
                                <label className="form-label fs-5" >What type of family would you like to work with?<span className='text-danger'>*</span></label>
                                <textarea disabled={disabled2}
                                    rows={3}
                                    required
                                    type='text'
                                    className='form-control mt-auto'
                                    name='type_of_family_want_to_work_with'
                                    value={formData2.type_of_family_want_to_work_with}
                                    onChange={onInputHandleChange}
                                />
                            </div>

                            <div className="col-md-6 mt-4 d-flex flex-column">
                                <label className="form-label fs-5" >Is there anyone you are not comfortable working with?<span className='text-danger'>*</span></label>
                                <textarea
                                    rows={3}
                                    required disabled={disabled2}
                                    type='text'
                                    className='form-control mt-auto'
                                    name='NOT_want_work_with_intended_parents'
                                    value={formData2.NOT_want_work_with_intended_parents}
                                    onChange={onInputHandleChange}
                                />
                            </div>


                            <div className="col-md-6 mt-4 d-flex flex-column">
                                <label className="form-label fs-5" >Describe your ideal Intended Parent(s)<span className='text-danger'>*</span></label>
                                <textarea
                                    rows={3}
                                    required disabled={disabled2}
                                    type='text'
                                    className='form-control mt-auto'
                                    name='describe_the_ideal_intended_Parent'
                                    value={formData2.describe_the_ideal_intended_Parent}
                                    onChange={onInputHandleChange}
                                />
                            </div>


                            <div className="col-md-6 mt-4 d-flex flex-column">
                                <label className="form-label fs-5" >What would you like your future Intended Parent(s) to know about you?<span className='text-danger'>*</span></label>
                                <textarea
                                    rows={3}
                                    required disabled={disabled2}
                                    type='text'
                                    className='form-control mt-auto'
                                    name='note_future_intended_Parent'
                                    value={formData2.note_future_intended_Parent}
                                    onChange={onInputHandleChange}
                                />
                            </div>


                            <div className="col-6 mt-4 d-flex flex-column">
                                <label className="form-label fs-5">Are you willing to have the Intended Parent(s) present during doctors appointments and the delivery?<span className='text-danger'>*</span></label>

                                <div className="row mt-auto mb-auto">
                                    <div className="col-md-6">
                                        <div className="form-check">
                                            <input disabled={disabled2}
                                                required
                                                className="form-check-input"
                                                name='presence_of_ip_during_appointment_and_delivery'
                                                type="radio"
                                                checked={formData2.presence_of_ip_during_appointment_and_delivery === true}
                                                onChange={onInputHandleChange}
                                                value='true'
                                            />
                                            <label htmlFor='medical_insuranceId1' className="form-check-label LabeSubHeading " >{t("Yes")}</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-check">
                                            <input disabled={disabled2}
                                                required
                                                className="form-check-input"
                                                type="radio"
                                                name='presence_of_ip_during_appointment_and_delivery'
                                                checked={formData2.presence_of_ip_during_appointment_and_delivery === false}
                                                onChange={onInputHandleChange}
                                                value='false'
                                            />
                                            <label htmlFor='medical_insuranceId2' className="form-check-label LabeSubHeading " >{t("No")}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 mt-4 d-flex flex-column">
                                <label className="form-label fs-5" >Is there anyone you would like present during the delivery (ex: Spouse/Partner, Mother, Sister or Friend)?<span className='text-danger'>*</span></label>
                                <textarea disabled={disabled2}
                                    rows={3}
                                    required
                                    type='text'
                                    className='form-control mt-auto'
                                    name='anyone_you_want_during_delivery'
                                    value={formData2.anyone_you_want_during_delivery}
                                    onChange={onInputHandleChange}
                                />
                            </div>

                            <div className="col-12 mt-4 d-flex flex-column">
                                <label className="form-label fs-5">How often would you like to be in contact with the Intended Parent(s) during the pregnancy?<span className='text-danger'>*</span></label>

                                <div className="row mt-auto">

                                    <div className="col-md-4">
                                        <div className="form-check">
                                            <input disabled={disabled2}
                                                required
                                                className="form-check-input"
                                                name='contact_with_ip_during_pregnancy'
                                                type="radio"
                                                value='Couple times a year'
                                                checked={formData2.contact_with_ip_during_pregnancy === 'Couple times a year'}
                                                onChange={onInputHandleChange}
                                            />
                                            <label htmlFor='medical_insuranceId1' className="form-check-label LabeSubHeading " >Couple times a year</label>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-check">
                                            <input disabled={disabled2}
                                                required
                                                className="form-check-input"
                                                type="radio"
                                                name='contact_with_ip_during_pregnancy'
                                                value='Once a month'
                                                checked={formData2.contact_with_ip_during_pregnancy === 'Once a month'}
                                                onChange={onInputHandleChange}
                                            />
                                            <label htmlFor='medical_insuranceId2' className="form-check-label LabeSubHeading " >Once a month</label>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-check">
                                            <input disabled={disabled2}
                                                required
                                                className="form-check-input"
                                                type="radio"
                                                name='contact_with_ip_during_pregnancy'
                                                value='Not allowed'
                                                checked={formData2.contact_with_ip_during_pregnancy === 'Not allowed'}
                                                onChange={onInputHandleChange}
                                            />
                                            <label htmlFor='medical_insuranceId2' className="form-check-label LabeSubHeading " >Not allowed</label>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-12 mt-4 d-flex flex-column">
                                <label className="form-label fs-5">How often would you like to be in contact with the Intended Parent(s) after the delivery?<span className='text-danger'>*</span></label>

                                <div className="row mt-auto">
                                    <div className="col-md-3">
                                        <div className="form-check">
                                            <input disabled={disabled2}
                                                required
                                                className="form-check-input"
                                                type="radio"
                                                name='fequency_of_contact_with_ip_after_pregnancy'
                                                value='As needed'
                                                checked={formData2.fequency_of_contact_with_ip_after_pregnancy === 'As needed'}
                                                onChange={onInputHandleChange}
                                            />
                                            <label htmlFor='medical_insuranceId1' className="form-check-label LabeSubHeading " >As needed</label>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-check">
                                            <input disabled={disabled2}
                                                required
                                                className="form-check-input"
                                                type="radio"
                                                name='fequency_of_contact_with_ip_after_pregnancy'
                                                value='Couple times a year'
                                                checked={formData2.fequency_of_contact_with_ip_after_pregnancy === 'Couple times a year'}
                                                onChange={onInputHandleChange}
                                            />
                                            <label htmlFor='medical_insuranceId2' className="form-check-label LabeSubHeading " >Couple times a year</label>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-check">
                                            <input disabled={disabled2}
                                                required
                                                className="form-check-input"
                                                type="radio"
                                                name='fequency_of_contact_with_ip_after_pregnancy'
                                                value='Once a month'
                                                checked={formData2.fequency_of_contact_with_ip_after_pregnancy === 'Once a month'}
                                                onChange={onInputHandleChange}
                                            />
                                            <label htmlFor='medical_insuranceId2' className="form-check-label LabeSubHeading " >Once a month</label>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-check">
                                            <input disabled={disabled2}
                                                required
                                                className="form-check-input"
                                                type="radio"
                                                name='fequency_of_contact_with_ip_after_pregnancy'
                                                value='Not willing to'
                                                checked={formData2.fequency_of_contact_with_ip_after_pregnancy === 'Not willing to'}
                                                onChange={onInputHandleChange}
                                            />
                                            <label htmlFor='medical_insuranceId2' className="form-check-label LabeSubHeading " >Not willing to</label>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-12 mt-4 d-flex flex-column">
                                <label className="form-label fs-5">How would you like to be in contact with the Intended Parent(s)?<span className='text-danger'>*</span></label>

                                <div className="row mt-auto">
                                    <div className="col-md-4">
                                        <div className="form-check">
                                            <input disabled={disabled2}
                                                required
                                                className="form-check-input"
                                                name='mode_of_communication_with_ip'
                                                type="radio"
                                                value='Online meeting only'
                                                checked={formData2.mode_of_communication_with_ip === 'Online meeting only'}
                                                onChange={onInputHandleChange}

                                            />
                                            <label htmlFor='medical_insuranceId1' className="form-check-label LabeSubHeading " >Online meeting only</label>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-check">
                                            <input disabled={disabled2}
                                                required
                                                className="form-check-input"
                                                type="radio"
                                                name='mode_of_communication_with_ip'
                                                value='Face to face'
                                                checked={formData2.mode_of_communication_with_ip === 'Face to face'}
                                                onChange={onInputHandleChange}
                                            />
                                            <label htmlFor='medical_insuranceId2' className="form-check-label LabeSubHeading " >Face to face</label>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-check">
                                            <input disabled={disabled2}
                                                required
                                                className="form-check-input"
                                                type="radio"
                                                name='mode_of_communication_with_ip'
                                                value='Phone call only'
                                                checked={formData2.mode_of_communication_with_ip === 'Phone call only'}
                                                onChange={onInputHandleChange}
                                            />
                                            <label htmlFor='medical_insuranceId2' className="form-check-label LabeSubHeading " >Phone call only</label>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-6 mt-4 d-flex flex-column">
                                <label className="form-label fs-5">Are you willing to pump breast milk (compensation available)?<span className='text-danger'>*</span></label>

                                <div className="row mt-auto">
                                    <div className="col-md-4">
                                        <div className="form-check">
                                            <input disabled={disabled2}
                                                required
                                                className="form-check-input"
                                                name='willing_to_pump_breast_milk'
                                                type="radio"
                                                checked={formData2.willing_to_pump_breast_milk === true}
                                                onChange={onInputHandleChange}
                                                value='true'
                                            />
                                            <label htmlFor='medical_insuranceId1' className="form-check-label LabeSubHeading " >{t("Yes")}</label>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-check">
                                            <input disabled={disabled2}
                                                required
                                                className="form-check-input"
                                                type="radio"
                                                name='willing_to_pump_breast_milk'
                                                checked={formData2.willing_to_pump_breast_milk === false}
                                                onChange={onInputHandleChange}
                                                value='false'
                                            />
                                            <label htmlFor='medical_insuranceId2' className="form-check-label LabeSubHeading " >{t("No")}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-6 mt-4 d-flex flex-column">
                                <label className="form-label fs-5">If recommended by a doctor are you willing to be placed on bedrest?<span className='text-danger'>*</span></label>

                                <div className="row mt-auto">

                                    <div className="col-md-4">
                                        <div className="form-check">
                                            <input disabled={disabled2}
                                                required
                                                className="form-check-input"
                                                name='willing_to_be_placed_on_bedrest'
                                                type="radio"
                                                checked={formData2.willing_to_be_placed_on_bedrest === true}
                                                onChange={onInputHandleChange}
                                                value='true'
                                            />
                                            <label htmlFor='medical_insuranceId1' className="form-check-label LabeSubHeading " >{t("Yes")}</label>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-check">
                                            <input disabled={disabled2}
                                                required
                                                className="form-check-input"
                                                type="radio"
                                                name='willing_to_be_placed_on_bedrest'
                                                checked={formData2.willing_to_be_placed_on_bedrest === false}
                                                onChange={onInputHandleChange}
                                                value='false'
                                            />
                                            <label htmlFor='medical_insuranceId2' className="form-check-label LabeSubHeading " >{t("No")}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-6 mt-4 d-flex flex-column">
                                <label className="form-label fs-5">Are you willing to leave termination choices up to the Intended Parent(s) in the case of a serious birth defect?<span className='text-danger'>*</span></label>

                                <div className="row mt-auto">
                                    <div className="col-md-4">
                                        <div className="form-check">
                                            <input disabled={disabled2}
                                                required
                                                className="form-check-input"
                                                name='leave_termnation_choice_on_ip_for_birth_defect'
                                                type="radio"
                                                checked={formData2.leave_termnation_choice_on_ip_for_birth_defect === true}
                                                onChange={onInputHandleChange}
                                                value='true'
                                            />
                                            <label htmlFor='medical_insuranceId1' className="form-check-label LabeSubHeading " >{t("Yes")}</label>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-check">
                                            <input
                                                required disabled={disabled2}
                                                className="form-check-input"
                                                type="radio"
                                                name='leave_termnation_choice_on_ip_for_birth_defect'
                                                checked={formData2.leave_termnation_choice_on_ip_for_birth_defect === false}
                                                onChange={onInputHandleChange}
                                                value='false'
                                            />
                                            <label htmlFor='medical_insuranceId2' className="form-check-label LabeSubHeading " >{t("No")}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-6 mt-4 d-flex flex-column">
                                <label className="form-label fs-5">Are you willing to leave termination choices up to the Intended Parent(s) in the case of a non-life threatening condition?<span className='text-danger'>*</span></label>

                                <div className="row mt-auto">

                                    <div className="col-md-4">
                                        <div className="form-check">
                                            <input disabled={disabled2}
                                                required
                                                className="form-check-input"
                                                name='leave_termnation_choice_on_ip_for_threatening_condition'
                                                type="radio"
                                                checked={formData2.leave_termnation_choice_on_ip_for_threatening_condition === true}
                                                onChange={onInputHandleChange}
                                                value='true'
                                            />
                                            <label htmlFor='medical_insuranceId1' className="form-check-label LabeSubHeading " >{t("Yes")}</label>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-check">
                                            <input disabled={disabled2}
                                                required
                                                className="form-check-input"
                                                type="radio"
                                                name='leave_termnation_choice_on_ip_for_threatening_condition'
                                                checked={formData2.leave_termnation_choice_on_ip_for_threatening_condition === false}
                                                onChange={onInputHandleChange}
                                                value='false'
                                            />
                                            <label htmlFor='medical_insuranceId2' className="form-check-label LabeSubHeading " >{t("No")}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>






                            <div className="col-md-12 mt-4 md-sm-4 d-flex flex-column">
                                <label className="form-label fs-5" >What reassurance can you give your Intended Parent(s) that you will not change your mind<span className='text-danger'>*</span></label>
                                <textarea disabled={disabled2}
                                    rows={3}
                                    required
                                    type='text'
                                    className='form-control mt-auto'
                                    name='reassurances_for_ip'
                                    value={formData2.reassurances_for_ip}
                                    onChange={onInputHandleChange}
                                />
                            </div>

                            <div className="col-md-12 mt-4 md-sm-4 d-flex flex-column">
                                <label className="form-label fs-5" >Please describe your support system and who your support system is:<span className='text-danger'>*</span></label>
                                <textarea disabled={disabled2}
                                    rows={3}
                                    required
                                    type='text'
                                    className='form-control mt-auto'
                                    name='describe_support_system'
                                    value={formData2.describe_support_system}
                                    onChange={onInputHandleChange}
                                />
                            </div>


                            <div className="col-6 mt-4 d-flex flex-column">
                                <label className="form-label fs-5">Do you have child care available?<span className='text-danger'>*</span></label>

                                <div className="row mt-auto">
                                    <div className="col-md-4">
                                        <div className="form-check">
                                            <input disabled={disabled2}
                                                required
                                                className="form-check-input"
                                                name='child_care_available'
                                                type="radio"
                                                checked={formData2.child_care_available === true}
                                                onChange={onInputHandleChange}
                                                value='true'
                                            />
                                            <label htmlFor='medical_insuranceId1' className="form-check-label LabeSubHeading " >{t("Yes")}</label>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-check">
                                            <input disabled={disabled2}
                                                required
                                                className="form-check-input"
                                                type="radio"
                                                name='child_care_available'
                                                checked={formData2.child_care_available === false}
                                                onChange={onInputHandleChange}
                                                value='false'
                                            />
                                            <label htmlFor='medical_insuranceId2' className="form-check-label LabeSubHeading " >{t("No")}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-6 mt-4 md-sm-4 d-flex flex-column">
                                <label className="form-label fs-5" >When are you ready to begin:<span className='text-danger'>*</span></label>
                                <input disabled={disabled2}
                                    required
                                    type='date'
                                    className='form-control mt-auto'
                                    name='ready_to_begin'
                                    value={moment(formData2.ready_to_begin).format('YYYY-MM-DD')}
                                    onChange={onInputHandleChange}
                                />
                            </div>
                        </div>

                        <div className="d-flex justify-content-end flex-md-row flex-sm-row flex-column-reverse mt-5 align-items-center gap-md-0 gap-sm-0 gap-3">


                            {!disabled2 && <button type="submit" className="btn_fill ms-3">Update</button>}
                        </div>
                    </form>

                }

            </section>
        </>
    )
}