import React, { useState } from 'react'
import style from "./Search.module.css"
import { useTranslation } from 'react-i18next'
import { Link,useNavigate } from 'react-router-dom'

const Search = () => {
    let {t}=useTranslation()
    let navigate = useNavigate()


    const [cards,setCards] = useState([
      { title: "Find Surrogate Mother", image: "image 34 (2).png", navlink: "Surrogate Mother" },
      { title: "Find a Embryo Donor", image: "image 34 (1).png", navlink: "Embryodonor"},
    
     
      // { title: "Find a Intended Parent", image: "image 33 (1).png" , navlink: "underconstruction"},
      // { title: "Find Education Provider", image: "image 33.png" , navlink: "underconstruction"},
      // { title: "Find an Agent", image: "image 33 (2).png" , navlink: "underconstruction"},
      // { title: "Find 0-2 Child Care (Day and Night)", image: "image 34 (4).png" , navlink: "underconstruction"},
      // { title: "Find 3-6 Child Care (Day and Night)", image: "image 34 (3).png" , navlink: "underconstruction"},
     
      // { title: "Find Adaption Parents/Kids", image: "image 34 (5).png" , navlink: "underconstruction"},
    
      
     
    ]) 




  return (
 


    <div>
       <div className={style.all_stories}>
        
            <div className="row">
              {cards?.map((card, index) => (
                <div key={index} className="col-12 col-sm-12 col-md-4 p-lg-2 p-md-2 p-sm-2 p-2">
                  <div  className={"navLink"} onClick={()=>navigate(`${card.navlink}`)} style={{height:"100%"}}>
                    <div className={style.card}>
                      <img
                        className={style.cardImgTop}
                        src={`/images/${card.image}`}
                        alt={t("Card image cap")}
                      />
                      <div className={style.cardBody}>
                        <div className={style.lufga_18px}>{t(card.title)}</div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
        </div>
    </div>
  )
}

export default Search