import React from "react";
import RevenueSurrgateReport from "./RevenueSurrgateReport";
import SurrogateReportChart from "./SurrogateReportChart";
import SurrogateMatched from "./SurrogateMatched";
import AuctionPostReport from "./AuctionPostReport";

export default function Reports() {
  return (
    <div>
      <div className="mt-4">
        <RevenueSurrgateReport />
      </div>
      <div className="mt-4">
        <SurrogateReportChart />
      </div>
      <div className="mt-4 d-flex gap-4">
        <div className="w-50">
          <SurrogateMatched />
        </div>
        <div className="w-50 ms-4">
          <AuctionPostReport />
        </div>
      </div>
    </div>
  );
}
