import React, { useEffect, useState } from "react";
import style from "./CreatePost.module.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Country, State, City } from "country-state-city";
import Loader from "../../../common/Loader";
import {
  getAuctionById,
  auctionUpdateById,
  uploadImage,
  getSurrogateMotherData,
} from "../../../../Api/SurrogateMotherApis";
import { useParams } from "react-router";
import moment from "moment";
import { FaArrowLeft } from "react-icons/fa6";
import { useLocation } from 'react-router-dom';
import ImageUpload from "./ImageUpload";


export default function EditPostAuction() {
  

  const location = useLocation();
  const surrogateMotherId = location.state;
  const smId = surrogateMotherId ? surrogateMotherId.data : null;

  console.log(smId,"smId")

  let userData =surrogateMotherId?{userId:smId} :JSON.parse(sessionStorage.getItem("userData"));
  console.log(userData,"userData")

  let { t } = useTranslation();
  const [imageSrc, setImageSrc] = useState(null);
  const [image, setImage] = useState(null);
  const navigate = useNavigate();
  const countries = Country.getAllCountries();
  const [statesListing, setStatesListing] = useState([]);
  const [country, setCountry] = useState();
  const [state, setState] = useState(null);
  const [getDataById, setGetDataById] = useState([]);
  const [loader, setLoader] = useState(false);
  const [getAllSurrogate, setGetAllSurrogate] = useState([]);
  const [fieldValidation, setFieldValidation] = useState(0);

  const getAllSurrogateMotherData = async () => {
    await getSurrogateMotherData(userData.userId)
      .then((res) => {
        // console.log(res);
        setFormData(res.data.data);
        setGetAllSurrogate(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // console.log(getAllSurrogate);

  useEffect(() => {
    getAllSurrogateMotherData();
  }, []);
  let params = useParams();
  function getStates(res,change) {
    let isoCode = res.split("&&")[1];
    setCountry(res);
    let states = State.getStatesOfCountry(isoCode);
    setStatesListing(states);
    if(change){

      setState(states[0]?.name)
    }
      
    
  }
  const [formData, setFormData] = useState({

    userId:userData.userId,
    photoUrl: "",
    photoFileName: "",
    name: "",
    email: "",
    short_description: "",
    country: "",
    state: "",
    buy_now_price: "",
    startDate: "",
    endDate: "",
    starting_bid_amount: 0,
  });

  const validation = () => {
    // console.log(formData);
    if (formData?.short_description.length === 0) {
      setFieldValidation(2);
      return false;
    }
    if (formData?.buy_now_price?.length === 0) {
      setFieldValidation(5);
      return false;
    } else if (formData?.startDate?.length === 0) {
      setFieldValidation(6);
      return false;
    } else if (formData?.endDate?.length === 0) {
      setFieldValidation(7);
      return false;
    } else if (formData?.starting_bid_amount <= 0) {
      setFieldValidation(8);
      return false;
    } else {
      return true;
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFieldValidation(0);
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    setImage(file);
    reader.onload = () => {
      setImageSrc(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };
  const actionGetById = async () => {
    setLoader(true);
    await getAuctionById(params.id)
      .then((res) => {
        setLoader(false);
        setFormData(res.data.data);
        // console.log(res);
        let countryData = countries.filter(
          (e) => e.name === res.data.data.country
        );
        // console.log(countryData);
        if (countryData.length !== 0) {
          setCountry(`${countryData[0].name}&&${countryData[0].isoCode}`);
          getStates(`${countryData[0].name}&&${countryData[0].isoCode}`,false);
        }
        setGetDataById(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };
  const editSurrogate = async (e) => {
    setLoader(true);
    e.preventDefault();
    const valid = validation();
    if (valid) {
      let imageDataupload = await uploadImage(image);
      let objdata = {
        state: state?.split("&&")[0],
        country: country?.split("&&")[0],
        photoUrl: imageDataupload.data.fileLocation,
        photoFileName: imageDataupload.data.fileName,
        name: `${getAllSurrogate?.firstName} ${getAllSurrogate?.middleName} ${getAllSurrogate?.lastName}`,
        email: getAllSurrogate?.email,
      };
      let datas = { ...formData, ...objdata };
      await auctionUpdateById(params.id, datas)
        .then((res) => {
          // console.log(res);
          setLoader(false);
          // navigate("/surrogate/auction");
          navigate(-1)
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
        });
    }
  };

  function endDateDisable (){
    const endDate = moment(formData.endDate);
    const now = moment();
    return endDate.isBefore(now)
  }

  useEffect(() => {
    actionGetById();
  }, [countries]);
  return (
    <>
      <Loader open={loader} />
      <div className="mb-4 mt-4 ms-4">
          <div
            className="d-flex align-items-center gap-2 btn-Secondary p-0 pointer"
            onClick={() => navigate(-1)}
          >
            <FaArrowLeft /> Back
          </div>
        </div>
      <div className={style.CreatePost}>
        <div className="FormParent">
          <form onSubmit={editSurrogate}>
            <div className={style.MainHeading}>Edit Auction Post</div>
            {/* <div className={` mb-4 ${style.Images_Border}`}>
              <label htmlFor="fileInput" className={style.center_content}>
                {imageSrc ? (
                  <img
                    src={imageSrc}
                    alt="Uploaded"
                    style={{ width: "450px", height: "350px" }}
                    className="pointer"
                  />
                ) : (
                  <img
                    src={getDataById.photoUrl}
                    alt=""
                    style={{ width: "450px", height: "350px" }}
                    className="pointer"
                  />
                )}
              </label>
              <input
                type="file"
                id="fileInput"
                accept="image/*"
                //
                onChange={handleImageUpload}
                style={{ display: "none" }}
              />
            </div> */}

  
           
            <ImageUpload setImage={setImage} image={image?image:getDataById.photoUrl} />
            <div className="row mb-3 mt-5">
              <div className="col-md-6">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("Name")}
                  <span className="text-danger"></span>
                </label>

                <input
                  type="text"
                  className={`form-control ${style.InputCustom}`}
                  placeholder=""
                  disabled
                  value={`${getAllSurrogate.firstName} ${getAllSurrogate.middleName} ${getAllSurrogate.lastName}`}
                  name="name"
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-6">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("Email")}
                  <span className="text-danger">*</span>
                </label>

                <input
                  type="email"
                  className={`form-control ${style.InputCustom}`}
                  placeholder=""
                  disabled
                  value={getAllSurrogate.email}
                  defaultValue={getDataById.email}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-12">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("Short Description")}
                  <span className="text-danger"></span>
                </label>

                <textarea
                  type="text"
                  rows={1}
                  className={`form-control ${style.InputCustom}`}
                  placeholder=""
                  name="short_description"
                  defaultValue={getDataById.short_description}
                  onChange={handleInputChange}
                  required
                />
                {/* {console.log(fieldValidation, "fieldValidation")} */}
                {fieldValidation === 2 && (
                  <>
                    <p className="text-danger">
                      Please enter short description
                    </p>
                  </>
                )}
              </div>
              
            </div>

            <div className="row mb-3">
            <div className="col-md-6 ">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("Country")}
                  <span className="text-danger">*</span>{" "}
                </label>
                <select
                  className={`form-select`}
                  value={country ? country : getDataById.country}
                  name="country"
                  defaultValue={getDataById.country}
                  onChange={(e) => {
                    getStates(e.target.value,true);
                  }}
                >
                  <option selected disabled value={null}>
                    {t("select")}
                  </option>
                  {countries.map((res, index) => {
                    return (
                      <option key={index} value={`${res.name}&&${res.isoCode}`}>
                        {res.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-md-6">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("State of Residence")}
                  <span className="text-danger"></span>
                </label>

                <select
                  className={`form-select`}
                  name="state"
                  value={state ? state : getDataById.state}
                  onChange={(e) => {
                    setState(e.target.value);
                  }}
                >
                  <option selected disabled value={null}>
                    {t("select")}
                  </option>
                  {statesListing?.map((res, index) => {
                    return (
                      <option
                        key={index}
                        // value={`${res.name}&&${res.isoCode}&&${res.countryCode}`}
                        value={`${res.name}`}
                      >
                        {res.name}
                      </option>
                    );
                  })}
                </select>
              </div>
             
            </div>
            <div className="row mb-3">
              <div className="col-md-6">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("Auction Start Date")}
                  <span className="text-danger"></span>
                </label>

                <input
                  type="date"
                  className={`form-control ${style.InputCustom}`}
                  placeholder=""
                  name="startDate"
                  value={moment(formData.startDate).format("YYYY-MM-DD")}
                  onChange={handleInputChange}
                  disabled
                />
                {fieldValidation === 6 && (
                  <>
                    <p className="text-danger">
                      Please select auction start date
                    </p>
                  </>
                )}
              </div>
              <div className="col-md-6">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("Auction End Date")}
                  <span className="text-danger">*</span>
                </label>

                <input
                  type="date"
                  className={`form-control ${style.InputCustom}`}
                  placeholder=""
                  name="endDate"
                  value={moment(formData.endDate).format("YYYY-MM-DD")}
                  onChange={handleInputChange}
                  disabled={endDateDisable()}
                />
                {fieldValidation === 7 && (
                  <>
                    <p className="text-danger">
                      Please select auction end date
                    </p>
                  </>
                )}
              </div>
            </div>
            <div className="row mb-3">
            <div className="col-md-6">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("Buy Now Price ($)")}
                  <span className="text-danger">*</span>
                </label>

                <input
                  type="number"
                  className={`form-control ${style.InputCustom}`}
                  placeholder=""
                  name="buy_now_price"
                  defaultValue={getDataById.buy_now_price}
                  onChange={handleInputChange}
                  required
                />
                {fieldValidation === 5 && (
                  <>
                    <p className="text-danger">Please enter buy now price</p>
                  </>
                )}
              </div>
              <div className="col-md-6">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("Starting Bid Amount ($)")}
                  <span className="text-danger">*</span>
                </label>

                <input
                  type="number"
                  className={`form-control ${style.InputCustom}`}
                  placeholder=""
                  name="starting_bid_amount"
                  defaultValue={getDataById?.starting_bid_amount}
                  onChange={handleInputChange}
                  required
                />
                {fieldValidation === 8 && (
                  <>
                    <p className="text-danger">
                      Please enter postive starting bid amount
                    </p>
                  </>
                )}
              </div>
              </div>
            <div className="d-flex justify-content-end gap-3 mb-3">
              <button
                type="button"
                className={style.cancelButton}
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </button>
              <button className={style.SaveButton} type="submit">
                Save Changes
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
