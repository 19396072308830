import React, { useEffect, useState } from "react";
import style from "./style.module.css";
import { Dialog } from "primereact/dialog";
import { useNavigate } from "react-router";

export default function SubscribePaymentModule({ openSucees,role }) {
  const [showPopup, setShowPopUp] = useState(false);
  const navigate = useNavigate();
  const userData =
  role === "ip"
    ? {
        userId: sessionStorage.getItem("userId"),
      }
    : JSON.parse(sessionStorage.getItem("userData"));

  useEffect(() => {
    console.log("adadsd");
  
      setTimeout(() => {
       
        const path =
        role === "surrogate"
          ? "/surrogate/subplan"
          : role === "intendedParent"
            ? "/intendedParent/subscription"
            : role === "embryo_donor"
              ? "/embryo_donor/subscription"
              : "/intendedParent/subscription"; // Add a default path if necessary

      navigate(path);
      }, 6000);
   
  }, [openSucees]);
  return (
    <div>
      <Dialog
        closable={false}
        visible={true}
        onHide={() => {}}
        style={{ width: "40vw" }}
        contentClassName={`${style.bgImg}`}
        breakpoints={{ "960px": "55vw", "641px": "90vw" }}
      >
        <div className="py-4">
          <div className={`d-flex justify-content-center align-items-center`}>
            <span className={`${style.heading}`}>Subscription Activated!</span>
          </div>
          <div className={`mt-3 ${style.heading1} mb-4`}>
            Thank you for subscribing! Enjoy full access to New features and
            exclusive content.
          </div>
        </div>
      </Dialog>
    </div>
  );
}
