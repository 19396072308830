import React, { useState, useEffect } from "react";
import style from "./style.module.css";
import { Dialog } from "primereact/dialog";
import { TableContainer } from "../../../common/TableContainer";
import { LiaEdit } from "react-icons/lia";
import { gethabitsdata } from "../../../../Api/admin";
import { addhabitsdata } from "../../../../Api/admin";
import { edithabitsdata } from "../../../../Api/admin";
import { deletehabits } from "../../../../Api/admin";
import Loader from "../../../common/Loader";
import { ConfirmDelete } from "../../../common/ConfirmDelete";

export default function Habits() {
  const [loader, setLoader] = useState(false);
  const [visible, setVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [habitData, setHabitData] = useState([]);
  const [addHabit, setAddHabit] = useState("");
  const [editHabitValue, setEditHabitValue] = useState("");
  const [editid, setEditid] = useState("");
  const [filterData, setFilterData] = useState([]);

  const handleSearchClick = (e) => {
    let val = e.trim().toLowerCase();
    let searchName = filterData.filter((res) =>
      res?.name?.toLowerCase().includes(val)
    );
    setHabitData(searchName);
  };

  const fetchPlan = async () => {
    setLoader(true);
    var result = await gethabitsdata();
    setHabitData(result.data.data);
    setFilterData(result.data.data);
    setLoader(false);
  };

  const addHabitData = async (e) => {
    e.preventDefault();
    try {
      setLoader(true);
      await addhabitsdata({ name: addHabit });
      fetchPlan();
      setVisible(false);
      setAddHabit("");
      setLoader(false);
    } catch (err) {
      console.log(err);
      setAddHabit("");
      setVisible(false);
      setLoader(false);
    }
  };

  const editClick = (index, id) => {
    setEditid(id);
    setEditVisible(true);
    setEditHabitValue(habitData[index].name);
  };

  const editValueChange = (event) => {
    setEditHabitValue(event.target.value);
  };

  const editHabitData = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      await edithabitsdata(editid, { name: editHabitValue });
      setEditHabitValue("");
      fetchPlan();
      setLoader(false);
      setEditVisible(false);
    } catch (error) {
      console.log(error);
      setEditHabitValue("");
      setEditVisible(false);
    }
    setLoader(false);
  };

  const deleteHabitData = async (id) => {
    setLoader(true);
    try {
      await deletehabits(id);
      fetchPlan();
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };

  useEffect(() => {
    fetchPlan();
  }, []);

  return (
    <>
      <Loader open={loader} />
      <div>
        <Dialog
          closable={false}
          visible={visible}
          className="p-0 m-0"
          onHide={() => setVisible(false)}
          style={{ width: "40vw", padding: "0px", margin: "0px" }}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        >
          <div className={`d-flex justify-content-center ${style.MainHeading}`}>
            Add Habit
          </div>
          <form onSubmit={addHabitData}>
            <div className="row mt-3">
              <div className="col-md-12">

                <label
                  className={`form-check-label mb-1 ${style.LabelText}`}
                  htmlFor="clinicname"
                >
                  Habit
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Habit"
                  onChange={(e) => setAddHabit(e.target.value)}
                  required
                />

              </div>
            </div>
            <div className="d-flex justify-content-end gap-3 mt-4">
              <button
                className={style.CencelButton}
                onClick={() => setVisible(false)}
                type="button"
              >
                Cancel
              </button>
              <button className={style.SubmitButton} type="submit">
                Submit
              </button>
            </div>
          </form>
        </Dialog>

        <Dialog
          closable={false}
          visible={editVisible}
          className="p-0 m-0"
          onHide={() => setEditVisible(false)}
          style={{ width: "40vw", padding: "0px", margin: "0px" }}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        >
          <div className={`d-flex justify-content-center ${style.MainHeading}`}>
            Edit Habit
          </div>
          <form onSubmit={editHabitData}>
            <div className="row mt-3">
              <div className="col-md-12">
                <label
                  className={`form-check-label mb-1 ${style.LabelText}`}
                  htmlFor="clinicname"
                >
                  Habit
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Habit"
                  value={editHabitValue}
                  onChange={editValueChange}
                  required
                />
              </div>
            </div>
            <div className="d-flex justify-content-end gap-3 mt-4">
              <button
                className={style.CencelButton}
                onClick={() => setEditVisible(false)}
                type="button"
              >
                Cancel
              </button>
              <button className={style.SubmitButton} type="submit">Save Changes
              </button>

            </div>
          </form>
        </Dialog>

        <div className="mainContainer">
          <div className="d-flex justify-content-between align-items-center">
            <div className={`${style.t_heading}`}>Habits</div>
            <div className="d-flex align-items-center gap-2">
              <button
                className="theme_button"
                onClick={() => {
                  setVisible(true);
                }}
              >
                <i className="bi bi-plus"></i> Add Habits
              </button>
              <div className="searchInput">
                <input
                  className="form-control ps-5"
                  placeholder="Search"
                  onChange={(e) => handleSearchClick(e.target.value)}
                ></input>
                <i className="pi pi-search searchIcon"></i>
              </div>
            </div>
          </div>
          <div className="CardStyle p-md-4 p-sm-3 p-2 mt-3">
            <TableContainer className={"table_container"}>
              <table>
                <thead>
                  <tr>
                    <th style={{ width: "10%" }}>S.No</th>
                    <th style={{ width: "80%" }}>Habits</th>
                    <th style={{ width: "10%" }}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {habitData.map((res, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{res.name}</td>
                        <td>
                          <span className="d-flex align-items-center gap-4">
                            <LiaEdit
                              style={{ fontSize: "1.5rem", cursor: "pointer" }}
                              onClick={() => {
                                editClick(index, res._id);
                              }}
                            />
                            <div className="">
                              <ConfirmDelete onDelete={deleteHabitData} data={res._id}>
                                <i
                                  className="pi pi-trash pointer"
                                  style={{ color: "red" }}
                                />
                              </ConfirmDelete>
                            </div>
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </TableContainer>
          </div>
        </div>
      </div>
    </>
  );
}
