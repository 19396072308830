import React, { useEffect, useState } from "react";
import ClientTable from "./ClientTable";
import style from "./AuctionList.module.css";
import { InputText } from "primereact/inputtext";
import { getAllAuctionData } from "../../../../Api/admin";
import { InputSwitch } from "primereact/inputswitch";
import Loader from "../../../common/Loader";
import moment from "moment";

const AuctionList = () => {
  const [dataone, setDataone] = useState([]);
  const [data, setData] = useState([])
  const [loader, setloader] = useState(false)
  const [showCompleted, setShowCompleted] = useState(false);
  const [filterData, setFilterData] = useState([]);

  const fetchAllAuctions = async () => {
    setloader(true)
    try {
      const result = await getAllAuctionData()
      setData(result.data.data.active_Auction)
      setFilterData(result.data.data.active_Auction);
      setDataone(result.data.data.active_Auction)
      console.log(result.data.data.active_Auction)
      setloader(false)
    }
    catch (error) {
      console.log(error, "error while fetching auctions")
    }
  }

  const handleSearchClick = (e) => {
    let val = e.trim().toLowerCase();
    let searchName = filterData.filter((res) => {
      const fullName = (res?.name?.trim().toLowerCase().replace(/\s+/g, '') || '') + 
                       (res?.memberId?.trim().toLowerCase().replace(/\s+/g, '') || '');
      return fullName.includes(val.replace(/\s+/g, ''));
    });
    setData(searchName);
    setDataone(searchName)
  };


  async function changeAuctionData(e) {

    setShowCompleted(e.value); // Update the switch state
    if (e.value) {
      const filteredData = dataone.filter((res) => {
        const endDate = moment(res.endDate);
        const now = moment();
        return endDate.isBefore(now) || res?.buyer?.buyer_name;
      });
      setData(filteredData);
      console.log(filteredData,"filtered data after switching")
    } else {
      setData(dataone);
      console.log(dataone,"data after switch off")
    }
  }

  
  useEffect(function () {
    fetchAllAuctions()
  }, [])

  return (
    <div className={style.ClientTable}>
      <Loader open={loader} />
      <div className="d-flex justify-content-between mb-4 mt-3 ">
        <div>
          {" "}
          <span className="lufga_24px fw_600">NHP Auctions</span>
        </div>
        <div className="d-flex gap-2 align-items-center">
        <div className="searchInput w-100">
              <input
                className="form-control ps-5"
                placeholder="Search"
                onChange={(e) => handleSearchClick(e.target.value)}
              ></input>
              <i className="pi pi-search searchIcon"></i>
            </div>
        </div>
      </div>
      <div className="d-flex mb-4 mt-3 gap-2">
        <div className="lufga_16px fw_600">Show only completed auctions</div>
        <div className='d-flex align-items-center'>  <InputSwitch name='completed auctions' checked={showCompleted}
          onChange={changeAuctionData} /></div>
      </div>

      <ClientTable data={data} role="all_clients" />
    </div>
  );
};

export default AuctionList;


