import React from 'react'
import { NavLink } from 'react-router-dom';

const RoleUserlisting = () => {
  return (
    <div>
        <NavLink style={{ textDecoration: "none" }} to={`userlisting`}>
            <div className="navlink">
              <div className="fs-4">
              <i className="pi pi-user" style={{ fontSize: '1.2rem' }}></i>
              </div>
              <div>User Management</div>
            </div>
          </NavLink>    
    </div>
  )
}

export default RoleUserlisting;
