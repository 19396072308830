import React, { useState } from 'react'
import style from './LawyerClient.module.css'
import { Pagination } from 'reactstrap'
import { TableContainer } from '../../common/TableContainer'
import { InputText } from 'primereact/inputtext'
import { useNavigate } from 'react-router'
import moment from 'moment'
import { ConfirmDelete } from '../../common/ConfirmDelete'
import { RiDeleteBinLine } from 'react-icons/ri'
import { deleteLawyerDocumentById } from '../../../Api/Lawyer'
import { deleteClientById } from '../../../Api/Lawyer'
import Loader from '../../common/Loader'

const LawyerClientTable = ({ data, role , update }) => {

    const navigate = useNavigate()
    const [loader, setLoader] = useState(false);

    const deleteReportfunction = async (obj) => {
        setLoader(true);
        await deleteLawyerDocumentById(obj)
            .then((res) => {
                update();
                setLoader(false);

            })
            .catch((res) => {
                console.log(res);
            })
            .finally(() => {
                setLoader(false)
            })
    };

    const deleteClientfunction = async (obj) => {
        setLoader(true);
        await deleteClientById(obj)
            .then((res) => {
                update();
            })
            .catch((res) => {
                console.log(res);
            })
            .finally(() => {
                setLoader(false)
            })
    };

    const handleViewClick = (url) => {
        window.open(url, '_blank');
      };

    return (

        <div className={style.ClientTable}>
            <Loader open={loader}/>

            <TableContainer className={"table_container"}>
                <table>


                    {role === "all_clients" ?

                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Client Type</th>
                                <th>Service Requested</th>
                                <th>Contract Id</th>
                                <th></th>
                            </tr>
                        </thead>

                        :

                        <thead>
                            <tr>
                                <th>Document Name</th>
                                <th>Upload Date</th>
                                <th>Document Number</th>
                                <th></th>
                            </tr>
                        </thead>

                    }

                    <tbody style={{ minHeight: '200px' }}>
                        {data.length === 0 ?

                            (
                                <tr style={{ height: '200px' }}>
                                    <td style={{ height: '200px', width: '100%' }} colSpan="5">
                                        <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
                                            <div className='lufga_24px'>No Data Available</div>
                                        </div>
                                    </td>
                                </tr>
                            )

                            :

                            data.map((res, index) => (
                                role === "all_clients" ?

                                    <tr key={index}>

                                        <td>{res.name}</td>
                                        <td>{res.client_type}</td>
                                        <td>{res.service_requested}</td>
                                        <td>{res.clientId}</td>
                                        <td className='d-flex align-items-center gap-5'>
                                            <button className={style.ViewButton} onClick={() => navigate(`clientView/${res._id}`)}><i className="bi bi-eye d-flex align-items-center me-1"></i> View</button>

                                            <ConfirmDelete onDelete={deleteClientfunction} data={res.clientId} header="Delete Client's Account" deleteMsg="Are you sure you want to delete this client's account">
                                                <button
                                                    className={`d-flex align-items-center gap-1 ${style.ViewButton}`}

                                                >
                                                    <RiDeleteBinLine className="text-danger" />
                                                    Delete
                                                </button>
                                            </ConfirmDelete>


                                        </td>
                                    </tr>

                                    :

                                    <tr key={index}>

                                        <td >{res.legal_document_name}</td>
                                        <td>{moment(res.createdAt).format("DD/MM/YY")}</td>
                                        <td>{res.document_number}</td>
                                        <td className='d-flex align-items-center gap-5'>
                                            <button className={style.ViewButton}
                                             onClick={() => handleViewClick(res.document_file.fileUrl)}
                                            ><i className="bi bi-eye d-flex align-items-center me-1"></i> View</button>

                                            <ConfirmDelete onDelete={deleteReportfunction} data={res._id} header="Delete Document" deleteMsg="Are you sure you want to delete this document">
                                                <button
                                                    className={`d-flex align-items-center gap-1 ${style.ViewButton}`}

                                                >
                                                    <RiDeleteBinLine className="text-danger" />
                                                    Delete
                                                </button>
                                            </ConfirmDelete>

                                        </td>
                                    </tr>

                            ))
                        }
                    </tbody>



                </table>
            </TableContainer>

        </div>

    )
}

export default LawyerClientTable