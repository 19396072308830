import React, { useEffect, useState } from "react";
import style from "./Subscriptionplan.module.css";
import { InputText } from "primereact/inputtext";
import { BsDot } from "react-icons/bs";
import { PopoverItem } from "../../commonComponents/popovericon/popovericon";
import PopOver from "../../commonComponents/popover/popover";
import { FaPowerOff } from "react-icons/fa6";
import { LiaEdit } from "react-icons/lia";
import { updateStatus } from "../../../Api/admin";
import { useNavigate, useParams } from "react-router-dom";
// import Addplan from "./Add_plans/AddPlan";

import DeletePlans from "./delete_Subscription_Plans/DeletePlans";

import EditPlan from "./Edit_Plan/EditPlan";

import { getPlan } from "../../../Api/admin";
import Loader from "../../common/Loader";

const Subscriptionplan = () => {
  const [AddPlan, setAddPlan] = useState(false);
  const [resume, setResume] = useState(false);
  const [EditPlans, setEditPlans] = useState(false);
  const [activeTab, setActiveTab] = useState("home");
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [resId, setResId] = useState()
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();

  var fetchPlan = async () => {
    var result = await getPlan();
    setData(result.data.data);
    setFilteredData(result.data.data);

    console.log("planskkkkk", result.data.data);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    if (tab === "profile") {
      // Assuming "profile" is for active plans
      setFilteredData(data.filter((plan) => plan.status === "active"));
    } else if (tab === "contact") {
      // Assuming "contact" is for inactive plans
      setFilteredData(data.filter((plan) => plan.status === "inactive"));
    } else {
      setFilteredData(data); // "home" tab shows all plans
    }
  };


  const statusUpdate = async (subid, datas) => {
    setLoader(true)
    const payload = {
      "status": datas
    }
    await updateStatus(subid, payload).then((res) => {
      // console.log(res)
      setLoader(false)
      fetchPlan()
    }).catch((err) => {
      console.log(err)
      setLoader(false)
    })
  }

  function changePlan(planId, datas) {
    // This function should toggle the status of the plan in your backend
    // For now, it's only toggling in the frontend state

    statusUpdate(planId, datas);

    setData(
      data.map((plan) => {
        if (plan.id === planId) {
          // Assuming each plan has a unique `id`
          return {
            ...plan,
            status: plan.status === "active" ? "inactive" : "active",
          };
        }
        return plan;
      })
    );
    // You may need to refetch or update your plans list after changing the status
  }

  const activePlansCount = data.filter(
    (plan) => plan.status === "active"
  ).length;
  const inactivePlansCount = data.filter(
    (plan) => plan.status === "inactive"
  ).length;


  useEffect(function() {
    fetchPlan()
  }
  ,[])

  useEffect(function () {


    if (activeTab === "home") {
      setFilteredData(data); // "home" tab shows all plans
    } else if (activeTab === "profile") {
      setFilteredData(data.filter((plan) => plan.status === "active"));
    } else {
      setFilteredData(data.filter((plan) => plan.status === "inactive"));
    }
  }


    , [fetchPlan]);




  return (
    <div className="mt-4">
      <Loader open={loader} />
      <div className={` ${style.social_pages_text}`}>
        <div className={`lufga_24px fw_600 ${style.subheading_color}`}>
          Manage Subscription Plans
        </div>
        <button className={`theme_button lufga_16px`} type="button" onClick={() => navigate('addplan')}>
          + Add Plan
        </button>
      </div>
      <div className={`${style.subheading_color} lufga_18px mt-4 fw_400`}>
        Here are your subscription plans
      </div>

      <div className={style.social_pages_background}>
        <nav>
          <ul
            className={`${style.nav} ${style.navTabs}`}
            id="myTab"
            role="tablist"
          >
            <li className={style.navItem} role="presentation">
              <button
                className={`${style.navLink} ${activeTab === "home" && style.active
                  }`}
                onClick={() => handleTabClick("home")}
              >
                All Plans{" "}
                <div className={`${style.count} fw_600`}>{data.length}</div>
              </button>
            </li>
            <li className={style.navItem} role="presentation">
              <button
                className={`${style.navLink} ${activeTab === "profile" && style.active
                  }`}
                onClick={() => handleTabClick("profile")}
              >
                Active{" "}
                <div className={`${style.count} fw_600`}>
                  {activePlansCount}
                </div>
              </button>
            </li>
            <li className={style.navItem} role="presentation">
              <button
                className={`${style.navLink} ${activeTab === "contact" && style.active
                  }`}
                onClick={() => handleTabClick("contact")}
              >
                Inactive{" "}
                <div className={`${style.count} fw_600`}>
                  {inactivePlansCount}
                </div>
              </button>
            </li>
          </ul>
        </nav>

        <div className="m-4 pb-5" style={{minHeight:"40vh"}}>
          {/* <div className={style.search}>
            <InputText placeholder="Search" className={`${style.searchbar}`} />
          </div> */}

          {filteredData.map((res, ind) => (
            <div key={ind} className={`${style.subcription_main} mt-4`}>
              <div className={`${style.main_left}`}>
                <div>
                  <img src="/images/subscriptionplan_lock.png" alt="" />
                </div>
                <div className={style.plan_details}>
                  <div className={style.plan}>
                    <h3>{res.plan_name}</h3>
                    <h5 className={`${style.txt}`}>({res.user_type})</h5>
                    <h6 className={style.dot}>
                      {res.status === "active" ? (
                        <div
                          style={{
                            fontSize: "1.2rem",
                            color: "#1BA345",
                            marginRight: "-0.4rem",
                          }}
                        >
                          <BsDot /> Active
                        </div>
                      ) : (
                        <div
                          style={{
                            fontSize: "1.2rem",
                            color: "red",
                            marginRight: "-0.4rem",
                          }}
                        >
                          <BsDot /> Inactive
                        </div>
                      )}
                    </h6>
                  </div>
                  <div>{res.plan_description}</div>
                  <div className={style.arr}>
                    {res?.included_features.map((feature, index) => (
                      <div
                        key={index}
                        className={`${style.arr_list} lufga_14px`}
                      >
                        {feature}
                      </div>
                    ))}
                  </div>

                  <div className={style.features_not_included}>
                    {res?.not_included_features.map((feature, index) => (
                      <div
                        key={index}
                        className={`${style.features_not_included_list} lufga_14px`}
                      >
                        {feature}
                      </div>
                    ))}
                  </div>


                </div>
              </div>
              <div className={style.price}>
                <div className={`fw_700 lufga_42px`}>${res.amount}</div>
                <div className={`lufga_16px ${style.validity}`}>
                  <b>Validity:</b>  <span className={`lufga_16px ${style.no_break}`}>{res.validity}</span>
                </div>
              </div>
              <div className={style.dot_icon}>
                <PopOver>
                  <PopoverItem
                    text="Edit Plan"
                    icon={<LiaEdit style={{ fontSize: "1.5rem" }} />}
                    onClick={() => { navigate(`editplan/${res._id}`) }}
                  />
                  <PopoverItem
                    onClick={() => changePlan(res._id, res.status === "active" ? "inactive" : "active")}
                    style={{ color: res.status === "active" ? "red" : "green" }}
                    text={
                      res.status === "active"
                        ? "Inactive Plan"
                        : "Activate Plan"
                    }
                    icon={<FaPowerOff />}
                  />
                  <PopoverItem
                    text="Delete Plan"
                    icon={
                      <i
                        className="pi pi-trash"
                        style={{ fontSize: "1rem" }}
                      />
                    }
                    onClick={() => { setResume(true); setResId(res?._id) }}
                  />
                </PopOver>
              </div>
            </div>
          ))}
        </div>
      </div>

      <DeletePlans resumeone={resume} setResumeone={setResume} id={resId} fetchPlan={fetchPlan} />


    </div>
  );
};

export default Subscriptionplan;
