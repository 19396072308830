import React, { useEffect, useRef, useState } from "react";
import style from "./ViewDetailsEmbryo.module.css";

import {
  getSurrogateMotherData,
  UpdateSurrogateprofile,
} from "../../../../Api/SurrogateMotherApis";
import { uploadDocument } from "../../../../Api/CommonApis";
import Loader from "../../../common/Loader";
import moment from "moment";
import { useDispatch } from "react-redux";
import { Country, State, City } from "country-state-city";
import { useTranslation } from "react-i18next";
import { changeHeaderData } from "../../../../Store/Slices/headerDataSlice";
import { useLocation, useNavigate, useParams } from "react-router";
import { FaArrowLeft } from "react-icons/fa";
import { VscVerifiedFilled } from "react-icons/vsc";
import { TfiImport } from "react-icons/tfi";
import { MdArrowForwardIos, MdOutlineArrowBackIos } from "react-icons/md";
import { saveSurrogate } from "../../../../Api/IntendedParent";
import Toaster from "../../../common/Toaster";
import { getUserDataById } from "../../../../Api/admin";
import jsPDF from 'jspdf';
import * as htmlToImage from 'html-to-image';
import SubscriptionDialog from "./SubscriptionDialog";

export default function ViewDatailsEmbryo() {
  let { t } = useTranslation();
  const countries = Country.getAllCountries();
  const [country, setCountry] = useState(null);
  const [state, setState] = useState(null);
  const [city, setCity] = useState(null);
  const [statesListing, setStatesListing] = useState([]);
  const [citiesListing, setCitiesListing] = useState([]);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [editable, setEditable] = useState(true);
  const [upload, setUpload] = useState(false);
  const [open, setOpen] = useState(false);

  let userData = JSON.parse(sessionStorage.getItem("userData"));
  const paramsId = useParams();
  const dispatch = useDispatch();
  let name = useRef();
  const [toaster, setToaster] = useState({
    show: false,
    message: "",
  });
  const [loader, setLoader] = useState(true);
  const [img, setImg] = useState(null);
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    middleName: "",
    height: {
      feet: 0,
      inches: 0,
    },
    weight: {
      LBs: 0,
      oz: 0,
    },
    email: "",
    homePhone: "",
    workPhone: "",
    cellPhone: "",
    imageUrl: "",
    imageFileName: "",
    short_description: "",
    age: 0,
    dob: "",
    current_address: "",
    country: "",
    state: "",
    city: "",
    zipCode: "",
    emailVerify: true,
    photos: [],
  });
  let [page, setPage] = useState(0);
  const [surrogateSave, setSurrogateSaveData] = useState(false)
  const resumeRef1 = useRef(null);
  const resumeRef2 = useRef(null);
  const [allData, setAlldata] = useState(null)
  let styleSheet = {
    maincontainer: {
      width: '100%',
      height: 'auto',
      position: 'relative',
      padding: '25px',
      background: 'linear-gradient(251.07deg, #FFFFFF 35.06%, #E8F9FA 95.96%)'
      // margin:'0 auto'
    },
    maincontainer2: {
      width: '100%',
      height: 'auto',
      position: 'relative',
      padding: '35px',
      background: 'linear-gradient(251.07deg, #FFFFFF 35.06%, #E8F9FA 95.96%)',
      minHeight: '1500px'
      // margin:'0 auto'
    },
    topContainer: {
      width: '100%',
      display: 'flex',
      // border: '1px solid red',
    },
    topLeft: {
      width: '40%',

    },
    topRight: {
      width: '60%',
      padding: '20px',
    },
    topRightInnerTop: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    codeHeading: {
      fontWeight: '600',
      fontSize: '24px',
      color: 'black',
      fontFamily: 'Lufga'
    },
    codeSub: {
      fontWeight: '600',
      fontSize: '14px',
      color: 'rgba(24, 124, 139, 1)',
      fontFamily: 'Lufga'
    },
    heading: {
      marginTop: '30px',
      fontWeight: '600',
      fontSize: '18px',
      color: 'rgba(24, 124, 139, 1)',
      fontFamily: 'Lufga',
      borderBottom: '2px solid rgba(24, 124, 139, 1)',
      width: '220px',
      paddingBottom: '3px'
    },
    heading2: {
      marginTop: '30px',
      fontWeight: '600',
      fontSize: '18px',
      color: 'rgba(24, 124, 139, 1)',
      fontFamily: 'Lufga',
      borderBottom: '2px solid rgba(24, 124, 139, 1)',
      width: '310px',
      paddingBottom: '3px'
    },
    listContent: {
      marginTop: '25px',
      display: 'flex',
      flexDirection: 'column',
      gap: '5px'
    },
    listHeading: {
      fontWeight: '600',
      fontSize: '14px',
      fontFamily: 'Lufga',
    },
    listText: {
      fontWeight: '400',
      fontSize: '14px',
      fontFamily: 'Lufga',
    },
    childContainer: {
      display: 'grid',
      width: '100%',
      gridTemplateColumns: '33.33% 33.33% 33.33%'
    },
    childHeading: {
      fontSize: '16px',
      fontWeight: '600',
      color: 'rgba(24, 124, 139, 1)',
      fontFamily: 'Lufga',
    },
    topBox: {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100px',
      height: "17px",
      background: 'rgba(24, 124, 139, 1)'
    },
    leftBox: {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '17px',
      height: "350px",
      background: 'rgba(24, 124, 139, 1)'
    },
    rightBox: {
      position: 'absolute',
      bottom: '0',
      right: '0',
      width: '20px',
      height: "100px",
      background: 'rgba(24, 124, 139, 1)'
    },
    bottomBox: {
      position: 'absolute',
      bottom: '0',
      right: '0',
      width: '350px',
      height: "20px",
      background: 'rgba(24, 124, 139, 1)'
    },


  }

  // async function convertToImg() {
  //   setLoader(true)
  //   let arr = [resumeRef1.current, resumeRef2.current];
  //   let photoArr = [];
  //   const pdf = new jsPDF();

  //   for (let index = 0; index < arr.length; index++) {
  //     const res = arr[index];
  //     await htmlToImage.toPng(res, { quality: 0.5 })
  //       .then(function (dataUrl) {
  //         photoArr.push(dataUrl);
  //         const imgProps = pdf.getImageProperties(dataUrl);
  //         const pdfWidth = pdf.internal.pageSize.getWidth();
  //         const pdfHeight = ((imgProps.height * pdfWidth) / imgProps.width) - 10;

  //         pdf.addImage(dataUrl, 'PNG', 0, 0, pdfWidth, pdfHeight);
  //         if (index !== arr.length - 1) {
  //           pdf.addPage();
  //         }
  //       })
  //       .catch(function (error) {
  //         console.error('oops, something went wrong!', error);
  //       }).finally(() => {
  //         setLoader(false)
  //       })
  //   }

  //   pdf.save('Resume');
  // }

  async function convertToImg() {
    setLoader(true);
    let arr = [resumeRef1.current, resumeRef2.current];
    let photoArr = [];
    const pdf = new jsPDF();
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    for (let index = 0; index < arr.length; index++) {
      const res = arr[index];
      await htmlToImage.toPng(res, { quality: 0.5 }) // Reduced quality to 0.5
        .then(function (dataUrl) {
          photoArr.push(dataUrl);
          const imgProps = pdf.getImageProperties(dataUrl);
          const imgWidth = pdfWidth;
          const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;

          // Scale image to fit within PDF dimensions
          const scaleFactor = Math.min(pdfWidth / imgProps.width, pdfHeight / imgProps.height);
          const scaledWidth = imgProps.width * scaleFactor;
          const scaledHeight = imgProps.height * scaleFactor;

          pdf.addImage(dataUrl, 'PNG', 0, 0, scaledWidth, scaledHeight, undefined, 'FAST'); // Added compression option
          if (index !== arr.length - 1) {
            pdf.addPage();
          }
        })
        .catch(function (error) {
          console.error('oops, something went wrong!', error);
        })
        .finally(() => {
          if (index === arr.length - 1) {
            setLoader(false);
          }
        });
    }

    pdf.save('Resume.pdf');
  }



  const getDataUserById = async () => {
    await getUserDataById(userId).then((res) => {
      let responseData = res.data.data
      // console.log(responseData)
      let savedData = responseData.saved_surrogate.includes(paramsId.id)
      // console.log(savedData)
      setSurrogateSaveData(savedData)
    }).catch((err) => {
      console.log(err)
    })
  }
  useEffect(() => {
    getDataUserById()
  }, [])

  async function getUserData() {
    try {
      let res = await getSurrogateMotherData(paramsId.id);
      let resultData = res.data.data;
      // console.log(resultData);
      setAlldata(res.data.data);

      dispatch(
        changeHeaderData({
          firstName: resultData?.firstName,
          lastName: resultData?.lastName,
          photos: resultData?.photos,
          activeIndex: resultData?.photo_profile_index,
        })
      );
      name.current = resultData?.firstName + " " + resultData?.lastName;
      setPhotoIndex(
        resultData.photo_profile_index !== undefined
          ? resultData?.photo_profile_index
          : resultData?.photos?.length - 1
      );
      setData((pre) => {
        return { ...pre, ...resultData };
      });

      setState(resultData.state);
      setCity(resultData.city);

      if (res.data.data.country !== undefined) {
        let cRes = countries?.find(
          (res2) => res2?.name == res.data.data.country
        );
        setCountry(`${cRes.name}&&${cRes.isoCode}`);
        getStates(`${cRes.name}&&${cRes.isoCode}`, resultData.city);
      }
      if (res.data.data.city !== undefined) {
        setCity(res.data.data.city);
      }
      setLoader(false);
    } catch (err) {
      console.log(err);
      setLoader(false);
    }
  }
  // console.log(paramsId)

  const userId = sessionStorage.getItem("userId")
  // console.log(userId)


  const saveSurrogateData = async () => {
    setLoader(true)
    const payloadData = { surrrogate_id: paramsId.id }
    // console.log(data._id)
    await saveSurrogate(userId, payloadData).then((res) => {
      // console.log(res.data.data)
      setSurrogateSaveData(!surrogateSave)
      setToaster({
        show: true,
        message: surrogateSave ? "You have Unsaved profile successfully" : "You have saved profile successfully",
      });
      setLoader(false)
    }).catch((err) => {
      console.log(err)
      setLoader(false)
    })
  }
  useEffect(() => {
    if (statesListing.length !== 0) {
      let sRes = statesListing?.find((res2) => res2?.name == data.state);
      if (sRes !== undefined) {
        setState(`${sRes.name}&&${sRes.isoCode}&&${sRes.countryCode}`);
        getCities(`${sRes.name}&&${sRes.isoCode}&&${sRes.countryCode}`);
      }
    }

  }, [statesListing]);

  async function editProfileData(e) {
    e.preventDefault();
    let payload = {
      ...data,
      city: city.split("&&")[0],
      state: state.split("&&")[0],
      country: country.split("&&")[0],
    };
    // return
    let formdata = new FormData();
    if (img !== null) {
      formdata.append("uploadDoc", img);
    }

    setLoader(true);
    try {
      if (img !== null) {
        let imageData = await uploadDocument(formdata);
        payload.imageFileName = imageData.data.fileName;
        payload.imageUrl = imageData.data.fileLocation;
      }
      let res = await UpdateSurrogateprofile(userData.userId, payload);
      // return
      setImg(null);
      await getUserData();
      setEditable(true);
      setLoader(false);
    } catch (err) {
      setLoader(false);
      console.log(err);
    }
  }

  function getStates(res) {
    let isoCode = res.split("&&")[1];
    setCountry(res);
    let states = State.getStatesOfCountry(isoCode);
    setStatesListing(states);
  }

  function getCities(res) {
    let isoCode = res.split("&&")[1];
    let cCode = res.split("&&")[2];
    setState(res);
    let cities = City.getCitiesOfState(cCode, isoCode);
    setCitiesListing(cities);
  }

  function onInputChange(e) {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }

  useEffect(() => {
    getUserData();
  }, []);

  function ageCalculate(res) {
    return moment().diff(moment(res), 'years');
  }
  const navigate = useNavigate();
  // console.log(allData?.photos[photoIndex]?.fileUrl)

  const location = useLocation();
  const statedata = location.state;
  const datastate = statedata ? statedata : null;

  // console.log(datastate)
  // console.log(statedata)

  return (
    <div>
      <Toaster
        event={toaster.message}
        show={toaster.show}
        severity={"success"}
        setToaster={setToaster}
      />
      <div className="mb-4 mt-4 ms-4 w-25">
        <div
          className="d-flex align-items-center gap-2 btn-Secondary p-0 pointer w-50"
          // onClick={() => navigate("/intendedParent/search/ Surrogate Mother")}
          onClick={() => navigate(-1)}
        >
          <FaArrowLeft /> Back
        </div>
      </div>

      <Loader open={loader} />
      <div className={style.MyProfile}>
        <div className={style.HeaderDialog}>
          <div className="d-flex">
            <div className={style.ProfilePic}>
              <img
                // src={
                //   data?.photos?.length !== 0
                //     ? data?.photos[photoIndex]?.fileUrl
                //     : "/images/blankProfile.png"
                // }
                src="/images/viewProfile.png"
                className="w-100 h-100 object-fit-cover"
                alt=""
              />
            </div>
            <div className={style.PrifileHeding}>
              <div className="d-flex gap-3 align-items-center">
                <span className={style.Textname}>NHPAP101 {data?.memberId}</span>
                {/* {data?.verify_resume?.status ?  <button
                  className={`d-flex align-items-center  ${style.VerfiedButton1}`}
                >
                  <VscVerifiedFilled className="fs-6 me-2" />
                  Verified
                </button>:null }
               */}
                <button className={`d-flex align-items-center ${style.VerfiedButton1}`} >
                  <VscVerifiedFilled className="fs-6 me-2" />
                  Verified
                </button>
              </div>
              <br />
              <div className={`${style.shortDescription}`}>
                <span className={style.ProfileText}>
                  {/* {data?.short_description} */}
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </span>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between px-1 pt-2 mt-4">
            <div className="d-flex gap-4">
              <img src="/images/image 140.png" alt="" className={`${style.LocationImg}`} />
              <span className={style.LoctionText}>
                {/* {" "}
                {data?.city},{data?.state} */}
                Indigo , CA
              </span>
            </div>
            <div className="d-flex gap-3 ">
              {/* <img src="/images/image 141.png" alt="" />
              <span className={`me-3 ${style.LoctionText}`}>{data?.email}</span> */}
              <button className={`${style.ViewRealImage}`} onClick={() => setOpen(true)}>Click here to view real images</button>
            </div>
          </div>
        </div>
        {statedata?.requestedBY ? <div className="d-flex justify-content-between mt-4 mb-5">
          <div className="d-flex align-items-center "><span className={`${style.Textname1}`}>Requested Date:</span><span className="ms-2">{moment(statedata?.requestedData).format("DD-MM-YYYY")}</span></div>
          <div className="d-flex align-items-center "><span className={`${style.Textname1}`}>Requested By:</span><span className="ms-2">{statedata?.requestedBY}</span></div>

        </div> : null}


        <div className="d-flex justify-content-between mt-3">
          <div className="d-flex justify-content-end gap-3">
          </div>
          <div>
            {statedata?.requestedBY ? <button className={` ${style.save_profile} me-3`} onClick={() => { navigate(`/superadmin/manageleads/viewResume/${paramsId.id}`) }}>
              View Resume
            </button> : <>

              {surrogateSave === false ? (<> <button className={` ${style.save_profile} me-3`} onClick={() => { saveSurrogateData() }}>
                Save Profile
              </button></>) : (<>
                <button className={` ${style.save_profile} me-3`} onClick={() => { saveSurrogateData() }} >
                  Unsaved Profile
                </button>
              </>)}
            </>}

            <button className={` ${style.view_profile} align-items-center`} onClick={convertToImg}>
              <TfiImport className="me-2 mb-1" /> Export Profile
            </button>
          </div>
        </div>

        <div className="mt-4">
          {/* {data.not_show_fullName === false ?<> <div className="col-md-3">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("First Name")}
                </label>
                <input
                  type="text"
                  className={`form-control`}
                  placeholder=""
                  disabled={true}
                  value={data?.firstName}
                  required
                  name="firstName"
                />
              </div>
              <div className="col-md-3">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("Middle Initial")}
                </label>
                <input
                  type="text"
                  className={`form-control`}
                  placeholder=""
                  disabled={true}
                  value={data?.middleName}
                  required
                  name="middleName"
                />
              </div>
              <div className="col-md-3">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("Last Name")}
                </label>
                <input
                  type="text"
                  className={`form-control`}
                  placeholder=""
                  disabled={true}
                  value={data?.lastName}
                  required
                  name="lastName"
                />
              </div></>:""} */}

          {/* First Row - DOB , Gender, Race */}

          <div className="row">
            <div className="col-md-4">
              <label
                htmlFor="exampleInputEmail1"
                className={`form-label  ${style.LabelHeading}`}
              >
                {t("Date of Birth")}
              </label>
              <input
                type="date"
                className={`form-control`}
                placeholder=""
                disabled={editable}
                value={data?.[0]}
                required
                name="dob"
              />
            </div>
            <div className="col-md-4">
              <label className={`form-label  ${style.LabelHeading}`}>
                {t("Gender")}
              </label>
              <div className="row">
                <div className="col-md-5">
                  <div className="form-check">
                    <input
                      disabled={editable}
                      required
                      className="form-check-input mt-2"
                      id="medical_insuranceId1"
                      type="radio"
                      // checked={data.medical_insurance === true}
                      name="gender"
                    />
                    <label
                      htmlFor="medical_insuranceId1"
                      className="form-check-label LabeSubHeading mt-1"
                    >
                      {t("Male")}
                    </label>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="form-check">
                    <input
                      disabled={editable}
                      required
                      className="form-check-input mt-2"
                      id="medical_insuranceId2"
                      type="radio"
                      // checked={data.medical_insurance === false}
                      name="gender"
                    />
                    <label
                      htmlFor="medical_insuranceId2"
                      className="form-check-label LabeSubHeading mt-1"
                    >
                      {t("Female")}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <label
                htmlFor="exampleInputEmail1"
                className={`form-label  ${style.LabelHeading}`}
              >
                {t("Race")}
              </label>
              <select
                className={`form-select`}
                value={state}
                required
                disabled={editable}
                onChange={(e) => {
                  getCities(e.target.value);
                  setCity("");
                }}
              >
                <option selected disabled value={""}>
                  {t("Single")}
                </option>
                {/* {statesListing?.map((res, index) => {
                        return (
                        <option
                            key={index}
                            // value={`${res.name}&&${res.isoCode}&&${res.countryCode}`}
                        >
                            {res.name}
                        </option>
                        );
                    })} */}
              </select>

            </div>
          </div>

          {/* Second Row - Spouse DOB , Gender, Race */}

          <div className="row mt-4">
            <div className="col-md-4">
              <label
                htmlFor="exampleInputEmail1"
                className={`form-label  ${style.LabelHeading}`}
              >
                {t("Spouse Date of Birth")}
              </label>
              <input
                type="date"
                className={`form-control`}
                placeholder=""
                disabled={editable}
                // value={data?.dob && moment(data.dob).format("YYYY-MM-DD")}
                required
                name="spouse_dob"
              />
            </div>
            <div className="col-md-4">
              <label className={`form-label  ${style.LabelHeading}`}>
                {t("Spouse Gender")}
              </label>
              <div className="row">
                <div className="col-md-5">
                  <div className="form-check">
                    <input
                      disabled={editable}
                      required
                      className="form-check-input mt-2"
                      id="medical_insuranceId1"
                      type="radio"
                      // checked={data.medical_insurance === true}
                      name="spouse_gender"
                    />
                    <label
                      htmlFor="medical_insuranceId1"
                      className="form-check-label LabeSubHeading mt-1"
                    >
                      {t("Male")}
                    </label>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="form-check">
                    <input
                      disabled={editable}
                      required
                      className="form-check-input mt-2"
                      id="medical_insuranceId2"
                      type="radio"
                      // checked={data.medical_insurance === false}
                      name="spouse_gender"
                    />
                    <label
                      htmlFor="medical_insuranceId2"
                      className="form-check-label LabeSubHeading mt-1 "
                    >
                      {t("Female")}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <label
                htmlFor="exampleInputEmail1"
                className={`form-label  ${style.LabelHeading}`}
              >
                {t("Spouse Race")}
              </label>
              <select
                className={`form-select`}
                // value={state}
                required
                disabled={editable}
                onChange={(e) => {
                  getCities(e.target.value);
                  setCity("");
                }}
              >
                <option selected disabled value={""}>
                  {t("Single")}
                </option>
                {/* {statesListing?.map((res, index) => {
                        return (
                        <option
                            key={index}
                            // value={`${res.name}&&${res.isoCode}&&${res.countryCode}`}
                        >
                            {res.name}
                        </option>
                        );
                    })} */}
              </select>

            </div>
          </div>

          {/* Third Row - Street Address */}
          <div className="row mt-4">
            <div className="col-md-12">
              <label
                htmlFor="exampleInputEmail1"
                className={`form-label  ${style.LabelHeading}`}
              >
                {t("Street Address")}
              </label>

              <input
                type="text"
                className={`form-control`}
                placeholder=""
                disabled={editable}
                required
                // value={data?.current_address}
                name="street_address"
                onChange={onInputChange}
              />
            </div>
          </div>

          {/* Fourth Row - City , State of Residence, Country, Zip Code */}

          <div className="row mt-4">
            <div className="col-md-3">
              <label
                htmlFor="exampleInputEmail1"
                className={`form-label  ${style.LabelHeading}`}
              >
                {t("Country")}
              </label>
              <select
                className={`form-select`}
                value={city}
                required
                disabled={editable}
                onChange={(e) => {
                  getStates(e.target.value);
                  setState("");
                  setCity("");
                }}
              >
                <option selected disabled value={""}>
                  {t("Select")}
                </option>
                {countries.map((res, index) => {
                  return (
                    <option key={index} value={`${res.name}&&${res.isoCode}`}>
                      {res.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="col-md-3">
              <label
                htmlFor="exampleInputEmail1"
                className={`form-label  ${style.LabelHeading}`}
              >
                {t("State of Residence")}
              </label>
              <select
                className={`form-select`}
                value={state}
                required
                disabled={editable}
              // onChange={(e) => {
              //   getStates(e.target.value);
              //   setState("");
              //   setCity("");
              // }}
              >
                <option selected disabled value={""}>
                  {t("Select")}
                </option>
                {/* {countries.map((res, index) => {
                    return (
                      <option key={index} value={`${res.name}&&${res.isoCode}`}>
                        {res.name}
                      </option>
                    );
                  })} */}
              </select>
            </div>
            <div className="col-md-3">
              <label
                htmlFor="exampleInputEmail1"
                className={`form-label  ${style.LabelHeading}`}
              >
                {t("City")}
              </label>
              <select
                className={`form-select`}
                value={country}
                required
                disabled={editable}
                onChange={(e) => setCountry(e.target.value)}
              >
                <option selected disabled value={""}>
                  {t("Select")}
                </option>
                {citiesListing?.map((res, index) => {
                  return (
                    <option key={index} value={`${res.name}`}>
                      {res.name}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="col-md-3">
              <label
                htmlFor="exampleInputEmail1"
                className={`form-label ${style.LabelHeading}`}
              >
                {t("Zip Code")}
              </label>
              <input
                type="text"
                className={`form-control`}
                required
                placeholder=""
                disabled={editable}
                // value={data?.zipCode}
                name="zipCode"
                onChange={onInputChange}
              />
            </div>
          </div>

          {/* 5th - Date of legal Marriage,....... */}

          <div className="row mt-4">

            <div className="col-md-3">
              <label
                htmlFor="exampleInputEmail1"
                className={`form-label  ${style.LabelHeading}`}
              >
                {t("Date of legal Marriage")}
              </label>
              <input
                type="date"
                className={`form-control`}
                placeholder=""
                disabled={editable}
                // value={data?.dob && moment(data.dob).format("YYYY-MM-DD")}
                required
                name="do_legal_marriage"
              />
            </div>

            <div className="col-md-3">
              <label
                htmlFor="exampleInputEmail1"
                className={`form-label  ${style.LabelHeading}`}
              >
                {t("Your Highest Degree of Education")}
              </label>
              <input
                type="text"
                className={`form-control`}
                placeholder=""
                disabled={editable}
                // value={data?.dob && moment(data.dob).format("YYYY-MM-DD")}
                required
                name="highest_degree_of_qualification"
              />
            </div>

            <div className="col-md-4">
              <label
                htmlFor="exampleInputEmail1"
                className={`form-label  ${style.LabelHeading}`}
              >
                {t("Your Spouse's Highest Degree of Qualification")}
              </label>
              <input
                type="text"
                className={`form-control`}
                placeholder=""
                disabled={editable}
                // value={data?.dob && moment(data.dob).format("YYYY-MM-DD")}
                required
                name="spouse_highest_degree_of_qualification"
              />
            </div>

            <div className="col-md-2">
              <label
                htmlFor="exampleInputEmail1"
                className={`form-label  ${style.LabelHeading}`}
              >
                {t("Religion")}
              </label>
              <input
                type="text"
                className={`form-control`}
                placeholder=""
                disabled={editable}
                // value={data?.[0]}
                required
                name="dob"
              />
            </div>
          </div>

          {/* 6th Row - Your Occupation , Spouse Occuptaion , Total Income */}

          <div className="row mt-4">
            <div className="col-md-4">
              <label className={`form-label  ${style.LabelHeading}`}>
                {t('Your Occupation')}
              </label>
              <input
                type="text"
                required
                className="form-control"
                name="your_occupation"
                // value={data?.[0]}
                disabled={editable}
              />
            </div>
            <div className="col-md-4">
              <label className={`form-label  ${style.LabelHeading}`}>
                {t("Spouse's Occupation")}
              </label>
              <input
                type="text"
                required
                className="form-control"
                name="spouse_occupation"
                // value={data.no_of_vaginal_deliveries}
                disabled={editable}
              />
            </div>
            <div className="col-md-4">
              <label className={`form-label  ${style.LabelHeading}`}>
                {t('Total Income')}
              </label>
              <input
                type="number"
                required
                className="form-control"
                name="total_income"
                // value={data.no_of_c_section_deliveries}
                disabled={editable}
              />
            </div>
          </div>

          {/* 7th Row - Breifly describe the reasons for wanting to adapt */}

          <div className="row mt-4">
            <div className="col-md-12">
              <label
                htmlFor="exampleInputEmail1"
                className={`form-label  ${style.LabelHeading}`}
              >
                {t("Breifly describe the reasons for wanting to adapt")}
              </label>

              <input
                type="text"
                className={`form-control`}
                placeholder=""
                disabled={editable}
                required
                // value={data?.current_address}
                name="breifly_describe_reasons_for_wanting_to_adapt"
                onChange={onInputChange}
              />
            </div>
          </div>

          {/* 8th Row - Tell us about any children .......... */}


          <div className="row mt-4">
            <div className="col-md-8">
              <label
                htmlFor="exampleInputEmail1"
                className={`form-label  ${style.LabelHeading}`}
              >
                {t("Tell us about any children you currently have, including their age")}
              </label>

              <input
                type="text"
                className={`form-control`}
                placeholder=""
                disabled={editable}
                required
                // value={data?.current_address}
                name="breifly_describe_reasons_for_wanting_to_adapt"
                onChange={onInputChange}
              />
            </div>

            <div className="col-md-4">
              <label
                htmlFor="exampleInputEmail1"
                className={`form-label  ${style.LabelHeading}`}
              >
                {t("Kids Birth Status")}
              </label>
              <select
                className={`form-select`}
                // value={city}
                required
                disabled={editable}
                onChange={(e) => setCity(e.target.value)}
              >
                <option selected disabled value={""}>
                  {t("Select")}
                </option>
                {citiesListing?.map((res, index) => {
                  return (
                    <option key={index} value={`${res.name}`}>
                      {res.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          {/* 9th Row - Tell us about yourself */}


          <div className="row mt-4">
            <div className="col-md-12">
              <label
                htmlFor="exampleInputEmail1"
                className={`form-label  ${style.LabelHeading}`}
              >
                {t("Tell about yourself")}
              </label>

              <input
                type="text"
                className={`form-control`}
                placeholder=""
                disabled={editable}
                required
                // value={data?.current_address}
                name="tell_about_yourself"
                onChange={onInputChange}
              />
            </div>
          </div>

          {/* 10th Row */}

          <div className="row mt-4">
            <div className="col-md-12">
              <label
                htmlFor="exampleInputEmail1"
                className={`form-label  ${style.LabelHeading}`}
              >
                {t("Tell us about your spouse")}
              </label>

              <input
                type="text"
                className={`form-control`}
                placeholder=""
                disabled={editable}
                required
                // value={data?.current_address}
                name="tell_us_about_your_spouse"
                onChange={onInputChange}
              />
            </div>
          </div>

          {/* 11th Row */}

          <div className="row mt-4">
            <div className="col-md-12">
              <label
                htmlFor="exampleInputEmail1"
                className={`form-label  ${style.LabelHeading}`}
              >
                {t("Tell us about your relationship")}
              </label>

              <input
                type="text"
                className={`form-control`}
                placeholder=""
                disabled={editable}
                required
                // value={data?.current_address}
                name="tell_us_about_your_relationship"
                onChange={onInputChange}
              />
            </div>
          </div>

          {/* 12th Row */}

          <div className="row mt-4">
            <div className="col-md-12">
              <label
                htmlFor="exampleInputEmail1"
                className={`form-label  ${style.LabelHeading}`}
              >
                {t("Tell us about your extended family")}
              </label>

              <input
                type="text"
                className={`form-control`}
                placeholder=""
                disabled={editable}
                required
                // value={data?.current_address}
                name="tell_us_about_your_extended_family"
                onChange={onInputChange}
              />
            </div>
          </div>

          {/* 13th Row */}

          <div className="row mt-4">
            <div className="col-md-12">
              <label
                htmlFor="exampleInputEmail1"
                className={`form-label  ${style.LabelHeading}`}
              >
                {t("Tell us about your adventures you do in your free time")}
              </label>

              <input
                type="text"
                className={`form-control`}
                placeholder=""
                disabled={editable}
                required
                // value={data?.[0]}
                name=""
                onChange={onInputChange}
              />
            </div>
          </div>

          {/* 14th Row */}

          <div className="row mt-4">
            <div className="col-md-12">
              <label
                htmlFor="exampleInputEmail1"
                className={`form-label  ${style.LabelHeading}`}
              >
                {t("Tell us about tradition, holidays and celebrations")}
              </label>

              <input
                type="text"
                className={`form-control`}
                placeholder=""
                disabled={editable}
                required
                // value={data?.current_address}
                name="breifly_describe_reasons_for_wanting_to_adapt"
                onChange={onInputChange}
              />
            </div>
          </div>

          {/* 15th Row */}

          <div className="row mt-4">
            <div className="col-md-12">
              <label
                htmlFor="exampleInputEmail1"
                className={`form-label  ${style.LabelHeading}`}
              >
                {t("Tell us about your home")}
              </label>

              <input
                type="text"
                className={`form-control`}
                placeholder=""
                disabled={editable}
                required
                // value={data?.[0]}
                name=""
                onChange={onInputChange}
              />
            </div>
          </div>

          {/* 16th Row */}

          <div className="row mt-4">
            <div className="col-md-12">
              <label
                htmlFor="exampleInputEmail1"
                className={`form-label  ${style.LabelHeading}`}
              >
                {t("Tell us about your and your spouse's hobby and lifestyle")}
              </label>

              <input
                type="text"
                className={`form-control`}
                placeholder=""
                disabled={editable}
                required
                // value={data?.[0]}
                name=""
                onChange={onInputChange}
              />
            </div>
          </div>


          {/* 17th Row */}
          <div className="row mt-4">
            <span className={`${style.subHeading}`}>Your Favorites</span>
          </div>

          {/* 17th -> 1row */}
          <div className="row mt-4">
            <div className={`${style.favoriteContainer}`}>
              <div className="">
                <label className={`form-label ${style.LabelHeading}`}>Favorite Hobby</label>
                <input type="text" className={`form-control`} placeholder="" disabled={editable} required value={data?.[0]} name=""
                  onChange={onInputChange} />
              </div>
              <div className="">
                <label className={`form-label ${style.LabelHeading}`}>Favorite Movie</label>
                <input type="text" className={`form-control`} placeholder="" disabled={editable} required value={data?.[0]} name=""
                  onChange={onInputChange} />
              </div><div className="">
                <label className={`form-label ${style.LabelHeading}`}>Favorite Sport</label>
                <input type="text" className={`form-control`} placeholder="" disabled={editable} required value={data?.[0]} name=""
                  onChange={onInputChange} />
              </div><div className="">
                <label className={`form-label ${style.LabelHeading}`}>Favorite Holiday</label>
                <input type="text" className={`form-control`} placeholder="" disabled={editable} required value={data?.[0]} name=""
                  onChange={onInputChange} />
              </div><div className="">
                <label className={`form-label ${style.LabelHeading}`}>Total Book</label>
                <input type="text" className={`form-control`} placeholder="" disabled={editable} required value={data?.[0]} name=""
                  onChange={onInputChange} />
              </div>
            </div>
          </div>

          {/* 17th -> 2row */}
          <div className="row mt-4">
            <div className={`${style.favoriteContainer}`}>
              <div className="">
                <label className={`form-label ${style.LabelHeading}`}>Favorite Subject</label>
                <input type="text" className={`form-control`} placeholder="" disabled={editable} required value={data?.[0]} name=""
                  onChange={onInputChange} />
              </div>
              <div className="">
                <label className={`form-label ${style.LabelHeading}`}>Favorite Tradition</label>
                <input type="text" className={`form-control`} placeholder="" disabled={editable} required value={data?.[0]} name=""
                  onChange={onInputChange} />
              </div><div className="">
                <label className={`form-label ${style.LabelHeading}`}>Favorite Destination</label>
                <input type="text" className={`form-control`} placeholder="" disabled={editable} required value={data?.[0]} name=""
                  onChange={onInputChange} />
              </div><div className="">
                <label className={`form-label ${style.LabelHeading}`}>Favorite Job</label>
                <input type="text" className={`form-control`} placeholder="" disabled={editable} required value={data?.[0]} name=""
                  onChange={onInputChange} />
              </div><div className="">
                <label className={`form-label ${style.LabelHeading}`}>Favorite Food</label>
                <input type="text" className={`form-control`} placeholder="" disabled={editable} required value={data?.[0]} name=""
                  onChange={onInputChange} />
              </div>
            </div>
          </div>

          {/* 17th -> 3row */}
          <div className="row mt-4">
            <div className={`${style.favoriteContainer}`}>
              <div className="">
                <label className={`form-label ${style.LabelHeading}`}>Music Type</label>
                <input type="text" className={`form-control`} placeholder="" disabled={editable} required value={data?.[0]} name=""
                  onChange={onInputChange} />
              </div>
              <div className="">
                <label className={`form-label ${style.LabelHeading}`}>Favorite Song</label>
                <input type="text" className={`form-control`} placeholder="" disabled={editable} required value={data?.[0]} name=""
                  onChange={onInputChange} />
              </div><div className="">
                <label className={`form-label ${style.LabelHeading}`}>Favorite Celebrity</label>
                <input type="text" className={`form-control`} placeholder="" disabled={editable} required value={data?.[0]} name=""
                  onChange={onInputChange} />
              </div><div className="">
                <label className={`form-label ${style.LabelHeading}`}>Favorite Moment</label>
                <input type="text" className={`form-control`} placeholder="" disabled={editable} required value={data?.[0]} name=""
                  onChange={onInputChange} />
              </div><div className="">
                <label className={`form-label ${style.LabelHeading}`}>Favorite Author</label>
                <input type="text" className={`form-control`} placeholder="" disabled={editable} required value={data?.[0]} name=""
                  onChange={onInputChange} />
              </div>
            </div>
          </div>


          {/* 18th Row */}

          <div className="row mt-4">
            <span className={`${style.subHeading}`}>Your Spouse Favorites</span>
          </div>

          {/* 18th -> 1row */}
          <div className="row mt-4">
            <div className={`${style.favoriteContainer}`}>
              <div className="">
                <label className={`form-label ${style.LabelHeading}`}>Favorite Hobby</label>
                <input type="text" className={`form-control`} placeholder="" disabled={editable} required value={data?.[0]} name=""
                  onChange={onInputChange} />
              </div>
              <div className="">
                <label className={`form-label ${style.LabelHeading}`}>Favorite Movie</label>
                <input type="text" className={`form-control`} placeholder="" disabled={editable} required value={data?.[0]} name=""
                  onChange={onInputChange} />
              </div><div className="">
                <label className={`form-label ${style.LabelHeading}`}>Favorite Sport</label>
                <input type="text" className={`form-control`} placeholder="" disabled={editable} required value={data?.[0]} name=""
                  onChange={onInputChange} />
              </div><div className="">
                <label className={`form-label ${style.LabelHeading}`}>Favorite Holiday</label>
                <input type="text" className={`form-control`} placeholder="" disabled={editable} required value={data?.[0]} name=""
                  onChange={onInputChange} />
              </div><div className="">
                <label className={`form-label ${style.LabelHeading}`}>Total Book</label>
                <input type="text" className={`form-control`} placeholder="" disabled={editable} required value={data?.[0]} name=""
                  onChange={onInputChange} />
              </div>
            </div>
          </div>
          {/* 18th -> 2row */}
          <div className="row mt-4">
            <div className={`${style.favoriteContainer}`}>
              <div className="">
                <label className={`form-label ${style.LabelHeading}`}>Favorite Subject</label>
                <input type="text" className={`form-control`} placeholder="" disabled={editable} required value={data?.[0]} name=""
                  onChange={onInputChange} />
              </div>
              <div className="">
                <label className={`form-label ${style.LabelHeading}`}>Favorite Tradition</label>
                <input type="text" className={`form-control`} placeholder="" disabled={editable} required value={data?.[0]} name=""
                  onChange={onInputChange} />
              </div><div className="">
                <label className={`form-label ${style.LabelHeading}`}>Favorite Destination</label>
                <input type="text" className={`form-control`} placeholder="" disabled={editable} required value={data?.[0]} name=""
                  onChange={onInputChange} />
              </div><div className="">
                <label className={`form-label ${style.LabelHeading}`}>Favorite Job</label>
                <input type="text" className={`form-control`} placeholder="" disabled={editable} required value={data?.[0]} name=""
                  onChange={onInputChange} />
              </div><div className="">
                <label className={`form-label ${style.LabelHeading}`}>Favorite Food</label>
                <input type="text" className={`form-control`} placeholder="" disabled={editable} required value={data?.[0]} name=""
                  onChange={onInputChange} />
              </div>
            </div>
          </div>
          {/* 18th -> 3row */}
          <div className="row mt-4">
            <div className={`${style.favoriteContainer}`}>
              <div className="">
                <label className={`form-label ${style.LabelHeading}`}>Music Type</label>
                <input type="text" className={`form-control`} placeholder="" disabled={editable} required value={data?.[0]} name=""
                  onChange={onInputChange} />
              </div>
              <div className="">
                <label className={`form-label ${style.LabelHeading}`}>Favorite Song</label>
                <input type="text" className={`form-control`} placeholder="" disabled={editable} required value={data?.[0]} name=""
                  onChange={onInputChange} />
              </div><div className="">
                <label className={`form-label ${style.LabelHeading}`}>Favorite Celebrity</label>
                <input type="text" className={`form-control`} placeholder="" disabled={editable} required value={data?.[0]} name=""
                  onChange={onInputChange} />
              </div><div className="">
                <label className={`form-label ${style.LabelHeading}`}>Favorite Moment</label>
                <input type="text" className={`form-control`} placeholder="" disabled={editable} required value={data?.[0]} name=""
                  onChange={onInputChange} />
              </div><div className="">
                <label className={`form-label ${style.LabelHeading}`}>Favorite Author</label>
                <input type="text" className={`form-control`} placeholder="" disabled={editable} required value={data?.[0]} name=""
                  onChange={onInputChange} />
              </div>
            </div>
          </div>

          {/* 19th row */}
          <div className="row mt-4">
            <span className={`${style.subHeading}`}>Child Prefrences</span>
          </div>

          <div className="row mt-4">

            <div className="col-md-3">
              <label
                htmlFor="exampleInputEmail1"
                className={`form-label  ${style.LabelHeading}`}
              >
                {t("Age Range")}
              </label>
              <div className="row">
                <div className="col-md-6">
                  <input
                    type="number"
                    className={`form-control`}
                    placeholder="Min age"
                    disabled={editable}
                    // value={data?.dob && moment(data.dob).format("YYYY-MM-DD")}
                    required
                    name="do_legal_marriage"
                  />
                </div>
                <div className="col-md-6">
                  <input
                    type="number"
                    className={`form-control`}
                    placeholder="Max age"
                    disabled={editable}
                    // value={data?.dob && moment(data.dob).format("YYYY-MM-DD")}
                    required
                    name="do_legal_marriage"
                  />
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <label
                htmlFor="exampleInputEmail1"
                className={`form-label  ${style.LabelHeading}`}
              >
                {t("Gender")}
              </label>
              <select
                className={`form-select`}
                // value={city}
                required
                disabled={editable}
                onChange={(e) => setCity(e.target.value)}
              >
                <option selected disabled value={""}>
                  {t("Select")}
                </option>
                {citiesListing?.map((res, index) => {
                  return (
                    <option key={index} value={`${res.name}`}>
                      {res.name}
                    </option>
                  );
                })}
              </select>

            </div>

            <div className="col-md-3">
              <label
                htmlFor="exampleInputEmail1"
                className={`form-label  ${style.LabelHeading}`}
              >
                {t("Special Needs")}
              </label>
              <input
                type="text"
                className={`form-control`}
                placeholder=""
                disabled={editable}
                // value={data?.[0]}
                required
                name=""
              />
            </div>

            <div className="col-md-3">
              <label
                htmlFor="exampleInputEmail1"
                className={`form-label  ${style.LabelHeading}`}
              >
                {t("Race of child")}
              </label>
              <input
                type="text"
                className={`form-control`}
                placeholder=""
                disabled={editable}
                // value={data?.[0]}
                required
                name=""
              />
            </div>
          </div>
        </div>
      </div>
      <SubscriptionDialog open={open} setOpen={setOpen} />
    </div>
  );
}
