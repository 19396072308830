import React, { useState } from "react";
import style from "./signin.module.css";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import { loginDashboard } from "../../../Api/admin";
import { useNavigate } from "react-router";
import { FaRegEye } from "react-icons/fa";

const Signin = ({ resumeone, setResumeone }) => {
  let { t } = useTranslation();

  const [signInOpen, setSignInOpen] = useState(true);
  const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);
  const [resetPasswordOpen, setResetPasswordOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(true); //please make sure before push the code this captchaValue should be null
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [loginError, setLoginError] = useState("");
  const navigate = useNavigate();

  const captchaChange = (e) => {
    console.log(e);
    setCaptchaValue(e);
  };

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError("Please enter a valid email address");
      return false;
    }
    setEmailError("");
    return true;
  };

  const validatePassword = () => {
    if (password.trim() === "") {
      setPasswordError("Please enter your password");
      return false;
    }
    setPasswordError("");
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!captchaValue) {
        alert("Please complete the reCAPTCHA verification.");
        return;
    }
    if (!validateEmail() || !validatePassword()) {
      return;
    }

    var body = {
      email: email,
      password: password,
    };

    try {
      const result = await loginDashboard(body);

      if (result.data.error === false) {

        localStorage.setItem("userData", JSON.stringify(result.data.data));
        navigate("/superadmin/manageaccounts/surrogate");

      } else {

        if (result.data.message === "Please enter correct email id") {
          setEmailError("Email is incorrect");
        } else if (result.data.message === "Please enter correct password") {
          setPasswordError("Password is incorrect");
        } else {
          setLoginError("An error occurred. Please try again.");
        }

      }
    } catch (err) {
      console.log(err);
      setLoginError("An error occurred. Please check Email & Password again.");
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  function handleForgotPassword() {
    setForgotPasswordOpen(true);
    setSignInOpen(false);
  }

  function handleLogin() {
    setForgotPasswordOpen(false);
    setResetPasswordOpen(false);
    setSignInOpen(true);
  }

  return (
    <div className={style.AuctionForm}>
      <Dialog
        closable={false}
        visible={signInOpen}
        onHide={() => setSignInOpen(false)}
        contentClassName="p-xl-5 p-lg-4 p-md-4 p-sm-4 p-3"
        style={{ width: "750px"  }}
        breakpoints={{ "960px": "700", "790px": "90vw" }}
      >
        <div>
          <div className={`d-flex justify-content-center`}>
            <div className={`lufga_32px mt-md-4 mt-sm-3 mt-3`}>Sign in</div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <label
                className={`form-check-label mb-1 ${style.LabelText}`}
                htmlFor="flexCheckDefault"
              >
                Email Address
              </label>
              <input
                type="email"
                className="form-control mt-1"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter Your Email"
              />
              {emailError && (
                <span className="text-danger">{emailError}</span>
              )}
            </div>

            <div className="col-md-12 mt-4">
              <label
                className={`form-check-label mb-1 ${style.LabelText}`}
                htmlFor="flexCheckDefault"
              >
                Password
              </label>
              <div className={`mt-1 ${style.eyeBtn}`}>
              <div className={style.eyeIcon} onClick={togglePasswordVisibility}>
              {!showPassword ? <i class="bi bi-eye-slash View pointer" onClick={() => setShowPassword(!showPassword)}></i> : <i className="bi bi-eye View pointer" onClick={() => setShowPassword(!showPassword)}></i>}
              </div>
                
                <input
                  type={showPassword ? "text" : "password"}
                  className="form-control"
                  placeholder="Enter Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              {passwordError && (
                <span className="text-danger">{passwordError}</span>
              )}
              {loginError && (
                <span className="text-danger">{loginError}</span>
              )}
            </div>
            <div className="row">
              <div
                style={{ cursor: "pointer", maxWidth: "11.3rem" }}
                onClick={handleForgotPassword}
                className="theme_color lufga_18px py-3"
              >
                Forgot Password
              </div>
            </div>

            <ReCAPTCHA
              className="mt-3"
              sitekey={"6LfZ0MopAAAAAHd_MaaHxDBCR2hCNjrUfhxW7G_h"}
              onChange={captchaChange}
            />

            <div className="d-flex justify-content-end gap-4">
              <button
                type="button"
                className={style.cancelBtn}
                onClick={() => setSignInOpen(false)}
              >
                Cancel
              </button>

              <button className={style.SaveButton} onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog
        closable={false}
        visible={forgotPasswordOpen}
        className="p-0 m-0"
        onHide={() => setForgotPasswordOpen(false)}
        style={{ width: "50vw", padding: "0px", margin: "0px" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <div>
          <div className={`d-flex justify-content-center`}>
            <div className={`lufga_32px`}>Forgot Password</div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <label
                className={`form-check-label mb-1 ${style.LabelText}`}
                htmlFor="flexCheckDefault"
              >
                Email Address
              </label>
              <input
                type="email"
                className="form-control"
                placeholder="Enter Your Email"
              />
            </div>

            <div className="row">
              <div
                style={{ cursor: "pointer" }}
                className="theme_color lufga_18px py-3"
                onClick={handleLogin}
              >
                Back to Login Screen
              </div>
            </div>

            <div className="d-flex justify-content-end gap-4">
              <button className={`theme_color ${style.CancelButton}`}>
                Resend Reset Link
              </button>
              <button className={style.SaveButton}>Submit</button>
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog
        closable={false}
        visible={resetPasswordOpen}
        className="p-0 m-0"
        onHide={() => setResetPasswordOpen(false)}
        style={{ width: "50vw", padding: "0px", margin: "0px" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <div>
          <div className={`d-flex justify-content-center`}>
            <div className={`lufga_32px`}>Reset Password</div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <label
                className={`form-check-label mb-1 ${style.LabelText}`}
                htmlFor="flexCheckDefault"
              >
                New Password
              </label>
              <input
                type="password"
                className="form-control"
                placeholder="Enter Your New password"
              />
            </div>

            <div className="col-md-6">
              <label
                className={`form-check-label mb-1 ${style.LabelText}`}
                htmlFor="flexCheckDefault"
              >
                Confirm New Password
              </label>
              <input
                type="password"
                className="form-control"
                placeholder="Confirm Your New password"
              />
            </div>

            <div className="row">
              <div
                style={{ cursor: "pointer" }}
                className="theme_color display-inlinelufga_18px py-3"
                onClick={handleLogin}
              >
                Back to Login Screen
              </div>
            </div>

            <div className="d-flex justify-content-end gap-4">
              <button className={style.SaveButton}>Submit</button>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default Signin;
