import React, { useEffect, useState } from "react";
// import style from "./IntendedDashboard.module.css"
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FaArrowTrendDown, FaArrowTrendUp } from "react-icons/fa6";
import LatestNews from "../../SurrogateComponents/Dashboard/LatestNews/LatestNews";
import {
  getDashboardUser,
  updateDashboardUser,
  uploadDocument,
} from "../../../Api/CommonApis";
import { Dialog } from "primereact/dialog";
import { Button } from "reactstrap";
import style from "./IntendedDashboard.module.css";
import MyAuctionSpace from "../Auction/MyAuctionSpace";
import Auction from "../Auction/Auction";

function IntendedDashboard() {
  const [userData, setUserData] = useState({});
  const [loader, setLoader] = useState(true);
  let userId = sessionStorage.getItem("userId");
  const [memberid, setMemberid] = useState();

  async function getUserData() {
    try {
      let res = await getDashboardUser(userId);
      console.log(res, "itsres itsres itsres");
      setUserData(res.data.data);
      setLoader(false);
    } catch (err) {
      console.error(err);
      setLoader(false);
    }
  }

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    console.log(userData, "userdata userdata updated");
    if (userData.memberId) {
      setMemberid(userData.memberId);
    }
  }, [userData]);

  const [visible, setVisible] = useState(false);
  const [position, setPosition] = useState("center");

  const footerContent = (
    <div>
      <Button
        label="No"
        icon="pi pi-times"
        onClick={() => setVisible(false)}
        className="p-button-text"
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        onClick={() => setVisible(false)}
        autoFocus
      />
    </div>
  );

  const show = (position) => {
    setPosition(position);
    setVisible(true);
  };
  let { t } = useTranslation();

  const [uppercards, setUpperCards] = useState([]);

  useEffect(() => {
    setUpperCards([
      // {
      //   title: "Credit Score",
      //   main_content: "689",
      //   graph: "true",
      //   icon: "hammer_for_dashboard.png",
      //   percent: "8.5%",
      //   information_type: " Up from last month",
      // },
      {
        title: "Your Membership Id",
        main_content: memberid || "Loading...", // Provide a default or loading state
        icon: "memberImage.png",
      },
    ]);
  }, [memberid]);

  return (
    <div>
      <div className={`${style.main_container1}`}>
        <div
          className={`${style.surrogate_name} lufga_24px fw_600`}
        >
          Hi <span>{userData?.fullName}</span>,
        </div>
        <div className={`${style.upper_card_section} `}>
          <div className="mt-2 justify-content-left d-flex gap-3">
            {uppercards?.map((card, index) => (
              <div key={index}>
                <div className={`${style.card_wrapper} h-100`}>
                  <Link
                    style={{ height: "100%" }}
                    
                    className="d-flex"
                  >
                    <div className={`${style.card_body}`}>
                      <div className={`${style.card_upper}`}>
                        <div className={`${style.upper_left}`}>
                          <div className={`${style.left_upper} lufga_16px`}>
                            {t(card.title)}
                          </div>
                          <div className={`${style.left_lower}`}>
                            {t(card.main_content)}
                          </div>
                        </div>
                        <div className={`${style.upper_right}`}>
                          <img
                            className={`${style.card_img_top}`}
                            src={`/images/${card.icon}`}
                            alt={t("Card image cap")}
                          />
                        </div>
                      </div>

                      <div className={`${style.card_lower} lufga_16px`}>
                        <div className={`${style.lower_left}`}>
                          {
                            card.graph === "true" ? (
                              <div
                                style={{
                                  color: "green",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <FaArrowTrendUp />
                              </div>
                            ) : card.graph === "false" ? (
                              <div
                                style={{
                                  color: "red",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <FaArrowTrendDown />
                              </div>
                            ) : null // Or any other fallback you want for cases where graph is neither "true" nor "false"
                          }
                        </div>
                        <div className={`${style.lower_middle}`}>
                          {card.graph === "true" ? (
                            <div
                              style={{
                                color: "green",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {t(card.percent)}
                            </div>
                          ) : card.graph === "false" ? (
                            <div
                              style={{
                                color: "red",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {t(card.percent)}
                            </div>
                          ) : (
                            <div
                              style={{
                                color: "#187C8B",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {t(card.percent)}
                            </div>
                          )}
                        </div>
                        <div className={`${style.lower_right}`}>
                          {card.graph === "true" ? (
                            <div
                              style={{
                                color: "green",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {t(card.information_type)}
                            </div>
                          ) : card.graph === "false" ? (
                            <div
                              style={{
                                color: "red",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {t(card.information_type)}
                            </div>
                          ) : (
                            <div
                              style={{
                                color: "#187C8B",
                                display: "flex",
                                alignItems: "center",
                              }}
                              onClick={() => show("right")}
                            >
                              {t(card.information_type)}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div>
          <div className="">
            <div>
              <div className={``}>
                {/* <MyAuctionSpace outsidecall={true} /> */}
                <Auction limit={3}/>
              </div>
            </div>
          </div>
        </div>
        <Dialog
          visible={visible}
          position={position}
          style={{ width: "54vw", height: "100vh" }}
          onHide={() => setVisible(false)}
        >
          <div className="DialogParent">
            <div className={style.DialogHeading}>
              <span className=" ">New Hope Points Member Registration</span>
            </div>
            <div className="d-flex justify-content-between mt-4">
              <div>
                <span className={`${style.SponsorText}`}>Sponsor :</span>
                <span className={style.NameText}>Alex Smith</span>
              </div>
              <div>
                <span className={`${style.SponsorText}`}>
                  Your Membership Id
                </span>
                <span className={style.NameText}>AJ45298</span>
              </div>
            </div>
            <div className="d-flex justify-content-center align-item-center mb-3">
              <div className={style.QrRounded}>
                <div className={style.ScanCodeimg}>
                  <img src="/images/image 137.png" alt="" />
                </div>
              </div>
            </div>

            <div className="">
              <span>
                {" "}
                <i
                  class="bi bi-link-45deg"
                  style={{ color: "#187C8B", fontWeight: "500" }}
                ></i>{" "}
              </span>
              <span className={style.Linkweb}>
                {" "}
                https://agent.newhopepoints/#/auth/signUp?sponsorid=aj45298
              </span>
            </div>

            <div className="mb-3">
              <div className={style.IconViaText}>Or Share via</div>
            </div>

            <div className="right_lower">
              <div className="lower_lower">
                <a href="#">
                  <img
                    src="/images/Group 162562.png"
                    alt="social_media_links"
                  />
                </a>
                <a href="https://linkedin.com/company/101628249/admin/feed/posts">
                  <img src="/images/linkedin.png" alt="social_media_links" />
                </a>
                <a href="">
                  <img
                    src="/images/Group 162558.png"
                    alt="social_media_links"
                  />
                </a>
                <a href="">
                  <img src="/images/facebook.png" alt="social_media_links" />
                </a>
                <a href="https://www.instagram.com/newhopepoints/">
                  <img src="/images/insta.png" alt="social_media_links" />
                </a>

                <a href="#">
                  <img
                    src="/images/Group 1171274993.png"
                    alt="social_media_links"
                  />
                </a>
              </div>
            </div>
          </div>
        </Dialog>
      </div>

      <div className="py-3">
        <LatestNews outsidecall={true}/>
      </div>
    </div>
  );
}

export default IntendedDashboard;
