import React, { useEffect, useState } from "react";
import { getPoliciesData } from "../../../Api/admin";
import { useNavigate } from "react-router";
import Loader from "../../common/Loader";
import { FaArrowLeft } from "react-icons/fa6";
import style from "./Setting.module.css"

export default function PrivacyPolicyEd() {
  let navigator = useNavigate();
  const [getTermandCondition, setTermAndCondition] = useState("");
  const [loader, setLoader] = useState(true);

  const getTermConditionData = async () => {
    await getPoliciesData()
      .then((res) => {
        // console.log(res)
        setLoader(false);

        setTermAndCondition(res?.data?.data?.privacyPolicy);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };
  useEffect(() => {
    getTermConditionData();
  }, []);
  return (
    <div>
      <Loader open={loader} />

      <div className="mainContainer">
        <div className="mb-4">
          <div
            className="d-flex align-items-center gap-2 btn-Secondary p-0 pointer"
            onClick={() => navigator(-1)}
          >
            <FaArrowLeft /> Back
          </div>
        </div>
        <div className={`${style.t_heading}`}>Privacy Policy</div>
        <div className="CardStyle p-md-4 p-sm-3 p-2 mt-2">
          <div dangerouslySetInnerHTML={{ __html: getTermandCondition }}></div>
        </div>
      </div>
    </div>
  );
}
