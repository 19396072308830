import React, { useEffect, useState } from "react";
import style from "./EggDonor.module.css";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import ViewDetails from "../ViewDetailsDialog/ViewDetails";
import {
  addSearchIntendent,
  connectNotification,
  ConnectAddDataSurrogate
} from "../../../../Api/IntendedParent";
import { FaArrowLeft } from "react-icons/fa6";
import Loader from "../../../common/Loader";
import {
  gethabitsdata,
  getlifestyledata,
  getUserDataById,
} from "../../../../Api/admin";
import Toaster from "../../../common/Toaster";
const FindEggDonor = () => {
  let { t } = useTranslation();
  const navigate = useNavigate();
  let params = useParams();
  const [visibale, setVisibale] = useState(false);
  const [loader, setLoader] = useState(false);
  const [getAllHabits, setGetAllHabits] = useState([]);
  const [getAllLifeStyle, setGetAllLifeStyle] = useState([]);
  const [userData, setUserData] = useState();
  const [toaster, setToaster] = useState({
    show: false,
    message: "",
  });

  const [connectedData, setConnectedData] = useState(false);

  const [connectData, setConnectData] = useState()


  // console.log(params)

  const userId = sessionStorage.getItem("userId");

  const getUserDataIP = async () => {
    await getUserDataById(userId)
      .then((res) => {
        console.log(res)
        let responseData = res.data.data;
        // let savedData = responseData.saved_surrogate.includes(surrogate_id)
        setUserData(res.data.data);
        setConnectedData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [formateData, setFormatData] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (e.target.value === "") {
      setFormatData((prevState) => ({
        ...prevState,
        [name]: null,
      }));
    }
    else {
      setFormatData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const connectSuperAdmin = async (cardData) => {
    setLoader(true);
    let payLoadData = {
      role: ["admin"],
      super_admin_message: {
        heading: "New Connection Request",
        msg: `${userData.fullName} has requested to connect with ${cardData?.firstName} (SM)`,
      },
      surrogate_id: cardData.userId,
    };
    await connectNotification(userId, payLoadData)
      .then((res) => {
        // console.log(res)
        setToaster({
          show: true,
          message: "Your connection request has been submitted",
        });
        setLoader(false);
        getUserDataIP();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const ConnectLeads = async (cardData) => {

    setLoader(true);

    let payloadLeads = {
      connected_by: {
        id: userId,
        role: userData.role

      },
      connected_to: {
        id: cardData.userId,
        role: params.name === " Surrogate Mother" ? "surrogate_donor" : "eggDonor",
      }
    }
    await ConnectAddDataSurrogate(payloadLeads).then((res) => {
      console.log(res)
      setLoader(false)
      setToaster({
        show: true,
        message: "Your connection request has been submitted",
      });
      addSearchData()
    }).catch((err) => {
      console.log(err)
      setLoader(false)
    })

  }

  const getAllHabitData = async () => {
    await gethabitsdata()
      .then((res) => {
        // console.log(res)
        setGetAllHabits(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllLifeStyleData = async () => {
    await getlifestyledata()
      .then((res) => {
        // console.log(res)
        setGetAllLifeStyle(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addSearchData = async () => {
    let payloadData = { ...formateData };
    if (payloadData.life_style) {
      payloadData.life_style = [payloadData.life_style];
    }
    if (payloadData.habits) {
      payloadData.habits = [payloadData.habits];
    }
    let payload = {
      connected_by_id: userId,
      service_offer: params.name === "Surrogate Mother" ? "surrogate" : "eggDonor",
      minHeight: '',
      maxHeight: '',
      minWeight: '',
      maxWeight: '',
      ...payloadData
    }
    if (payloadData?.maxHeightFeet) {
      let dataInch = +(payloadData?.maxHeightFeet) * 12
      payload.maxHeight = +(payload.maxHeight) + dataInch
    }

    if (payloadData?.maxHeightInches) {
      let dataInch = +(payloadData?.maxHeightInches)
      payload.maxHeight = +(payload.maxHeight) + dataInch
    }

    if (payloadData?.minHeightFeet) {
      let dataInch = +(payloadData?.minHeightFeet) * 12
      payload.minHeight = +(payload.minHeight) + dataInch
    }

    if (payloadData?.minHeightInches) {
      let dataInch = +(payloadData?.minHeightInches)
      payload.minHeight = +(payload.minHeight) + dataInch
    }

    if (payloadData?.maxWeightLBs) {
      let dataInch = +(payloadData?.maxWeightLBs) * 16
      payload.maxWeight = +(payload.maxWeight) + dataInch
    }

    if (payloadData?.maxWeightOz) {
      let dataInch = +(payloadData?.maxWeightOz)
      payload.maxWeight = +(payload.maxWeight) + dataInch
    }

    if (payloadData?.minWeightLBs) {
      let dataInch = +(payloadData?.minWeightLBs) * 16
      payload.minWeight = +(payload.minWeight) + dataInch
    }

    if (payloadData?.minWeightOz) {
      let dataInch = +(payloadData?.minWeightOz)
      payload.minWeight = +(payload.minWeight) + dataInch
    }



    delete payload?.maxHeightFeet
    delete payload?.maxHeightInches
    delete payload?.maxWeightLBs
    delete payload?.maxWeightOz
    delete payload?.minHeightFeet
    delete payload?.minHeightInches
    delete payload?.minWeightLBs
    delete payload?.minWeightOz
    setLoader(true);
    await addSearchIntendent(payload)
      .then((res) => {
        setCards(res.data.data);
        setLoader(false);
        console.log(res)
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  const clearFilter = async () => {
    console.log(params.name)
    setFormatData({
      service_offer:
        params.name === "Surrogate Mother" ? "surrogate" : "embryo_donor",
    });
    await addSearchIntendent({
      service_offer:
        params.name === "Surrogate Mother" ? "surrogate" : "embryo_donor",
    })
      .then((res) => {
        setCards(res.data.data);
        setLoader(false);
        // console.log(res)
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };
  useEffect(() => {
    addSearchData();
    getAllHabitData();
    getAllLifeStyleData();
    getUserDataIP();
  }, []);
  const [cards, setCards] = useState([]);

  return (
    <div>
      <Toaster
        event={toaster.message}
        show={toaster.show}
        severity={"success"}
        setToaster={setToaster}
      />
      <Loader open={loader} />

      <ViewDetails visibale={visibale} setVisibale={setVisibale} />
      <div className="mb-4 mt-4 ms-4 w-25">
        <div
          className="d-flex align-items-center gap-2 btn-Secondary p-0 pointer w-50"
          onClick={() => navigate("/intendedParent/search")}
        >
          <FaArrowLeft /> Back
        </div>
      </div>
      <div className={style.Search_con}>
        <div className="d-flex justify-content-between mb-2">
          <div className={style.CreditText}>Find a {params.name}</div>
          <div>
            <button
              className={style.ExportButton}
              onClick={() => navigate("savepost")}
            >
              Saved Contacts
            </button>
          </div>
        </div>
        <div className={style.FormCustom}>
          <div className={`${style.styleIngrid}`}>
            <div className="d-flex gap-3">
              <div className="">
                <label
                  className={`form-check-label mb-2 ${style.LabelText}`}
                  htmlFor="flexCheckDefault"
                >
                  {t("Age Range")}
                </label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Min age"
                  name="minAge"
                  onChange={handleInputChange}
                  value={formateData?.minAge ? formateData?.minAge : ""}
                />
                {/* {console.log(formateData?.minAge)} */}
              </div>
              <div className="">
                <label
                  className={`form-check-label mb-2 ${style.LabelText}`}
                  htmlFor="flexCheckDefault"
                >
                  {t("")}  <span className="text-danger"></span>
                </label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Max Age"
                  name="maxAge"
                  onChange={handleInputChange}
                  value={formateData?.maxAge ? formateData?.maxAge : ""}
                />
              </div>
            </div>
           {params.name === "Surrogate Mother" ?<>
            <div className="">
              <label
                className={`form-check-label mb-2 ${style.LabelText}`}
                htmlFor="flexCheckDefault"
              >
                {t("Maritial Status")}
              </label>
              <select
                className="form-select"
                aria-label="Default select example"
                onChange={handleInputChange}
                name="relationship_status"
                value={formateData?.relationship_status ? formateData?.relationship_status : "noValue"}
              >
                <option value={"noValue"} selected disabled>
                  Select Maritial Status
                </option>
                <option value={"Married"}>Married</option>
                <option value={"Single, No Partner"}>Single, No Partner</option>
                <option value={"Not married, but in a committed relationship"}>
                  Not Married, but in a committed relationship
                </option>
              </select>
            </div>
            <div className="">
              <label
                className={`form-check-label mb-2 ${style.LabelText}`}
                htmlFor="flexCheckDefault"
              >
                {t("Specify lifestyle")}
              </label>
              <select
                className="form-select"
                aria-label="Default select example"
                name="life_style"
                onChange={handleInputChange}
                value={formateData?.life_style ? formateData?.life_style : "noValue"}

              >
                <option value={"noValue"} selected disabled>
                  Select Lifestyle
                </option>
                {getAllLifeStyle.map((res, index) => {
                  return (
                    <>
                      <option value={res._id}>{res.name}</option>
                    </>
                  );
                })}
              </select>
            </div>
            <div className="">
              <label
                className={`form-check-label mb-2 ${style.LabelText}`}
                htmlFor="flexCheckDefault"
              >
                {t("Specify Habits")}
              </label>
              <select
                className="form-select"
                aria-label="Default select example"
                onChange={handleInputChange}
                name="habits"
                value={formateData?.habits ? formateData?.habits : "noValue"}
              >
                <option value={"noValue"} selected disabled>
                  Select Habits
                </option>
                {getAllHabits.map((res) => {
                  return (
                    <>
                      <option value={res._id}>{res.name}</option>
                    </>
                  );
                })}
              </select>
            </div>
            <div className="">
              <label
                className={`form-check-label mb-2 ${style.LabelText}`}
                htmlFor="flexCheckDefault"
              >
                {t("Skin Color")}
              </label>
              <select
                className="form-select"
                aria-label="Default select example"
                onChange={handleInputChange}
                name="skin_color"
                value={formateData?.skin_color ? formateData?.skin_color : "noValue"}

              >
                <option value={"noValue"} selected disabled>
                  Select Skin color
                </option>
                <option value="Light, pale white">Light, pale white</option>
                <option value="white, fair">white, fair</option>
                <option value="Medium white to olive">
                  Medium white to olive
                </option>
                <option value="Olive, mid brown">Olive, mid brown</option>
                <option value="Brown, dark brown">Brown, dark brown</option>
                <option value="Very dark brown, black">
                  Very dark brown, black
                </option>
              </select>
            </div>
           </>:''}
          </div>

          <div>

            <div className="row mt-2">
              <div className='col-md-6'>
                <label htmlFor="exampleInputEmail1" className={`form-check-label mb-2 ${style.LabelText}`}>{t("Min Height")}</label>

                <div className='d-flex justify-content-between gap-2'>
                  <input type='number'
                    className='form-control'
                    placeholder='Min Height'
                    value={formateData?.minHeightFeet ? formateData?.minHeightFeet : ""}
                    name="minHeightFeet"
                    onChange={handleInputChange}
                  />

                  <div className="d-flex align-items-center">{t("Ft")}</div>
                  <input type='number'

                    className='form-control'
                    placeholder='Min Height'
                    value={formateData?.minHeightInches ? formateData?.minHeightInches : ""}
                    name="minHeightInches"
                    onChange={handleInputChange}
                  />
                  <div className="d-flex align-items-center">{t("In")}</div>
                </div>

                <label htmlFor="exampleInputEmail1" className={`form-check-label mb-2 ${style.LabelText} mt-3`}>{t("Max Height")}</label>
                <div className='d-flex justify-content-between gap-2'>
                  <input type='number'
                    className='form-control'
                    placeholder='Max Height'
                    value={formateData?.maxHeightFeet ? formateData?.maxHeightFeet : ""}
                    name="maxHeightFeet"
                    onChange={handleInputChange}
                  />

                  <div className="d-flex align-items-center">{t("Ft")}</div>
                  <input type='number'

                    className='form-control'
                    placeholder='Max Height'
                    value={formateData?.maxHeightInches ? formateData?.maxHeightInches : ""}
                    name="maxHeightInches"
                    onChange={handleInputChange}
                  />
                  <div className="d-flex align-items-center">{t("In")}</div>
                </div>


              </div>

              <div className='col-md-6'>
                <label htmlFor="exampleInputEmail1" className={`form-check-label mb-2 ${style.LabelText}`}>{t("Min Weight")}</label>

                <div className='d-flex justify-content-between gap-2'>
                  <input type='number'

                    className='form-control'
                    placeholder='Min weight'
                    name="minWeightLBs"
                    value={formateData?.minWeightLBs ? formateData?.minWeightLBs : ""}
                    onChange={handleInputChange}
                  />
                  <div className="d-flex align-items-center">{t("Lbs")}</div>
                  <input type='number'

                    className='form-control'
                    placeholder='Min weight'
                    name="minWeightOz"
                    value={formateData?.minWeightOz ? formateData?.minWeightOz : ""}
                    onChange={handleInputChange}
                  />
                  <div className="d-flex align-items-center">{t("Oz")}</div>
                </div>

                <label htmlFor="exampleInputEmail1" className={`form-check-label mb-2 ${style.LabelText} mt-3`}>{t("Max Weight")}</label>

                <div className='d-flex justify-content-between gap-2'>
                  <input type='number'

                    className='form-control'
                    placeholder='Max weight'
                    name="maxWeightLBs"
                    value={formateData?.maxWeightLBs ? formateData?.maxWeightLBs : ""}
                    onChange={handleInputChange}
                  />
                  <div className="d-flex align-items-center">{t("Lbs")}</div>
                  <input type='number'

                    className='form-control'
                    placeholder='Max weight'
                    name="maxWeightOz"
                    value={formateData?.maxWeightOz ? formateData?.maxWeightOz : ""}
                    onChange={handleInputChange}
                  />
                  <div className="d-flex align-items-center">{t("Oz")}</div>
                </div>


              </div>
            </div>
          </div>

          <div className="d-flex justify-content-center mt-4">
            <div className="d-flex gap-2">
              <button
                className={style.SearchButton}
                onClick={() => {
                  addSearchData();
                }}
              >
                Search {params.name}
              </button>
              <button
                className={` ${style.clear_profile} me-3`}
                onClick={() => {
                  clearFilter();
                }}
              >
                Clear Search
              </button>
            </div>
          </div>
        </div>

        <div className={`${style.cardContainer} mt-5`}>
          {cards?.map((card, index) => (
            <div key={index} className={`w-100  ${style.maincardContainer}`}>
              <div
                className={`${style.card_upper} pointer`}

              >
                <div
                  className={`${style.card_upper_mask}`}
                  style={{
                    backgroundImage: `url(${card?.photos[
                      card.photo_profile_index ? card?.photo_profile_index : 0
                    ]?.fileUrl
                      })`,
                  }}
                >

                </div>
                <img
                  className={`${style.card_upper_image}`}
                  src={card?.photos[
                    card.photo_profile_index ? card?.photo_profile_index : 0
                  ]?.fileUrl}
                />
                <div className={`${style.upper_v_1}`}></div>
              </div>
              <div className={`${style.card_lower} d-flex flex-column`}>
                <div className={`${style.cardAgeText} `}>
                  SM Id: {t(card.memberId)}, Age:{t(card.age)}
                </div>
                <div className="d-flex justify-content-between align-items-center py-2">
                  <div className={`${style.lower_v_2}  `}>
                    {card?.height?.feet || card?.height?.inches ? `Height:` : null}
                    {card?.height?.feet || card?.height?.inches
                      ? `${card?.height?.feet}.${card?.height?.inches} Ft,`
                      : ""}{" "}
                    {card?.skin_color}
                  </div>
                </div>
                <div
                  className={`${style.card_lower_v_1} d-flex flex-column mt-auto`}
                >
                  <div className="d-flex justify-content-between gap-4 mt-auto">
                    {card?.is_connected === true ? (
                      <div className={`${style.lower_v_3}`}>Connected</div>
                    ) : (
                      <button
                        className={`btn ${style.lower_v_3}`}
                        onClick={() => {
                          ConnectLeads(card);
                        }}
                      >
                        Connect
                      </button>
                    )}

                    <button
                      className={`btn ${style.lower_v_Buy}`}
                      onClick={() => {
                        navigate(`/intendedParent/viewDetail/${card.userId}`);
                      }}
                    >
                      View Details
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FindEggDonor;
