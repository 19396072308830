module.exports = {
  baseUrl: "https://newhopepoints-backend-5c94ad9d7e3b.herokuapp.com",

  dashboardService: {
    getUserById: "/userDashboard/getUserDashboardById/",
    updateUser: "/userDashboard/updateProfile/",
  },
  services: {
    uploadDoc: "/service/uploadDoc",
    deleteDoc: "/service/deleteDocsApi",
    changePassword: "/userDashboard/changePassword/",
    uploadMultiDocs: "/service/uploadMultiDocs",
  },

  auctionService: {
    getAllAuctions: "/auction/getAllAuction",
    auctionBidDetails: "/auction/auctionBidDetails/",
    getAuctionById: "/auction/getAuctionByid/",
    bidOnAuction: "/auction/bidOnSurrogate/",
    buyNow: "/auction/buySurrogate/",
    auctionStatus: "/auction/activeStatus/",
  },

  intendedParent: {
    getResumeData: "/surrogate/getResumaData/",
    myAuctiongetData: "/auction/myAuctionData/",
    emailNotification: "/userDashboard/emailNotification_IP/",
    pushNotification: "/userDashboard/pushNotification_IP/",
    changepassword: "/userDashboard/changePassword/",
    searchSurrogate: "/surrogate/searchUsers",
    userDashBoard: "/userDashboard/savedSurrogate/",
    getsaveUsersurrogat: "/userDashboard/getSavedSurrogate/",
    connectsurrogate: "/notification/addNotification/",
    connectDataSurrogate: "/connection/addConnection",
    getIntendedParentById: "/intended/getIntendedParentById/",
    addIntendedParent: "/intended/addIntendedParent/",
    verifyIntendedResumeById: "/intended/VerifyIntendedParent/",
  },

  adoptingParent: {
    addAdoptingParent: "/adopting/addAdoptingParent/",
    getAdoptingParentById: "/adopting/getAdoptingParentById/",
    verifyIntendedResumeById: "/adopting/verifyAdoptingParent/",
  },

  surrogateMother: {
    getAllData: "/surrogate/getAllData/",
    deletePhoto: "/surrogate/deletePhoto/",
    getResumeData: "/surrogate/getResumaData/",
    updateResumeData: "/surrogate/updateResuma/",
    selectProfilePhoto: "/surrogate/selectProfilePhoto/",
    selectResumePhoto: "/surrogate/selectResumePhoto/",
    updateProfile: "/surrogate/updateProfile/",
    addAuction: "/auction/addAuction",
    imgaeUpload: "/service/uploadDoc",
    getAllActionByUserId: "/auction/getAuctionListByid/",
    getAuctionById: "/auction/getAuctionByid/",
    updateAuctionById: "/auction/updateAuction/",
    deleteAuctionById: "/auction/deleteAuctionByid/",
    emailNotification: "/userDashboard/emailNotification/",
    pushNotification: "/userDashboard/pushNotification/",
    getAllSurrogateDonors: "/superAdmin/getSurrogateMother",
    addSuperAdminSurrogate: "/superAdmin/addSurrogateIP",
    conciseFormOne: "/surrogate/AddFormConcise1/",
    conciseFormtwo: "/surrogate/AddFormConcise2/",
    verifyResume: "/surrogate/verifyResume/",
    pushNotificationOfAll: "/userDashboard/pushNotification/",
  },
  ivfClinic: {
    getUserById: "/userDashboard/getUserDashboardById/",
    getAppointments: "/clinicAppointment/getClinicAppointment",
    addAppointment: "/clinicAppointment/addClinicAppointment",
    editAppointment: "/clinicAppointment/updateClinicAppointment/",
    getClinicAppointmentById: "/clinicAppointment/getClinicAppointmentById/",
    deleteAppointment: "/clinicAppointment/deleteClinicAppointment/",
    deleteClient: "/clinicAppointment/deleteByClientId/",
    deleteReport: "/report/deleteReportById/",
    getClientsOfClinic: "/clinicAppointment/getAllClient",
    getClientDataById: "/clinicAppointment/getClientById/",
    EditClientDataById: "/clinicAppointment/editClientById/",
    addAppointmentByClientId: "/clinicAppointment/addClinicAppointmentAgain",
    getAppointmentByClientId: "/clinicAppointment/getAppointmentByClientId/",
    getReportByClientId: "/report/getReportByClientId",
    addReportivfCLinic: "/report/addReport",
    addDoctorInClinic: "/doctor/addDoctor",
    getDoctorsListApi: "/doctor/getDoctorByClinicId/",
    deleteDoctorByIdApi: "/doctor/deleteDoctor/",
    getDoctorByIdApi: "/doctor/getDoctorById/",
    updateDoctorByIdApi: "/doctor/updateDoctor/",
  },
  admin: {
    addUser: "/user/createUser/",
    addPlan: "/subscriptionPlan/addSubscriptionPlan",
    getPlan: "/subscriptionPlan/getSubscriptionPlan/",
    deletePlan: "/subscriptionPlan/deleteSubscriptionPlan/",
    updateStatus: "/subscriptionPlan/updateSubscriptionStatus/",
    addFeature: "/plan_feature/addFeature/",
    getFeature: "/plan_feature/getFeature/",
    updatePlan: "/subscriptionPlan/updateSubscriptionPlan/",
    addSocialPage: "/socialPage/addSocialPage/",
    getSocialPage: "/socialPage/getSocialPage",
    getSocialPageById: "/socialPage/getSocialPageById/",
    updateSocialPage: "/socialPage/updateSocialPage/",
    deleteSocialPage: "/socialPage/deleteSocialPage/",
    getplanById: "/subscriptionPlan/getSubscriptionPlanById/",
    loginDashboard: "/superAdmin/loginDashboard",
    getActiveSubscriber: "/superAdmin/getActiveSubscriber",
    getIntendedParents: "/superAdmin/getIntendedParents",
    deleteUser: "/superAdmin/softDeleteUserAccount/",
    activeInactive: "/superAdmin/blockUserAccount/",
    deleteuserbyId: "/userDashboard/deleteUserDashboard/",
    addLawyer: "/superAdmin/addLawyer/",
    getLawyers: "/superAdmin/getLawyers/",
    gettermscondition: "/termsPolicy/getTermsPolicy",
    updatetermscondition: "/termsPolicy/updateTermsPolicy/",
    addClinic: "/superAdmin/addClinic/",
    getIvfClinics: "/superAdmin/getIvfClinics/",
    getUserById: "/userDashboard/getUserDashboardById/",
    updatePolicies: "/termsPolicy/updateTermsPolicy",
    getPolicies: "/termsPolicy/getTermsPolicy",
    getAboutUs: "/about/getAbout",
    updateAboutUs: "/about/updateAbout",
    addFaq: "/frequentlyAsked/addFrequentlyAskedByRole/",
    getFaqbyrole: "/frequentlyAsked/getFrequentlyAskedByRole/",
    deletFaq: "/frequentlyAsked/deleteFrequentlyAsked/",
    updateFaq: "/frequentlyAsked/updateFrequentlyAsked/",
    getByIdFaq: "/frequentlyAsked/getFrequentlyAskedById/",
    getAllLifestyle: "/lifestyle/getAllLifestyle",
    addLifestyle: "/lifestyle/addLifestyle",
    editlifestyle: "/lifestyle/updateLifestyle/",
    deletelifestyle: "/lifestyle/deleteLifestyle/",
    getAllHabits: "/habit/getAllHabits",
    addHabits: "/habit/addHabit",
    editHabits: "/habit/updateHabit/",
    deleteHabits: "/habit/deleteHabit/",
    getallusersforauction: "/superAdmin/getUser",
    getAllAuctions: "/superAdmin/auctionList",
    getAllLeadsData: "/connection/getAllConnection",
    getSurrogateMatchedData: "/superReport/surrogateMatched",
    getAuctionPostData: "/superReport/auctionPosts",
    getSurrogateRegistrationData: "/superReport/surrogateMother",
    revenueFromSubscription: "/superReport/revenueFromSubscription",
  },
  news: {
    addNews: "/news/addNews",
    getNews: "/news/getNews",
    getNewsById: "/news/getNewsById/",
    updateNews: "/news/updateNews/",
    deleteNews: "/news/deleteNews/",
    getAllNews: "/news/getNews",
  },
  defaultAuction: {
    getDefaultAuction: "/defaultAuction/getDefault_auction",
    updateDefaultAuction: "/defaultAuction/updateDefault_auction",
  },
  subscription: {
    buySubscriptionPlan: "/userDashboard/subscriptionPlan/",
  },

  lawyer: {
    getUserById: "/userDashboard/getUserDashboardById/",
    getAppointments: "/lawyerAppointment/getlawyerAppointment",
    addAppointment: "/lawyerAppointment/addlawyerAppointment",
    editAppointment: "/lawyerAppointment/updatelawyerAppointment/",
    getlawyerAppointmentById: "/lawyerAppointment/getlawyerAppointmentById/",
    deleteAppointment: "/lawyerAppointment/deletelawyerAppointment/",
    deleteClient: "/lawyerAppointment/deleteByClientId/",
    deleteDocument: "/lawyerDocument/deleteDocumentById/",
    getClientsOflawyer: "/lawyerAppointment/getAllClient",
    getClientDataById: "/lawyerAppointment/getClientById/",
    EditClientDataById: "/lawyerAppointment/editClientById/",
    addAppointmentByClientId: "/lawyerAppointment/addlawyerAppointmentAgain",
    getAppointmentByClientId: "/lawyerAppointment/getAppointmentByClientId/",
    getDocumentByClientId: "/lawyerDocument/getDocumentByClientId",
    addDocumentlawyer: "/lawyerDocument/addDocument",
    addDoctorInlawyer: "/doctor/addDoctor",
    getDoctorsListApi: "/doctor/getDoctorBylawyerId/",
    deleteDoctorByIdApi: "/doctor/deleteDoctor/",
    getDoctorByIdApi: "/doctor/getDoctorById/",
    updateDoctorByIdApi: "/doctor/updateDoctor/",
    addAndUpdatePoc: "/lawyerAppointment/addAndUpdatePoc",
    deletePocApi: "/lawyerAppointment/deletePOC",
  },

  embryoDonor: {
    addEmbryoMother: "/embryo/addEmbryoDonorMother/",
    addEmbryoFather: "/embryo/addEmbryoDonorFather/",
    embryoDonorFormData: "/embryo/getEmbryoDonorById/",
    getProfileDataForEmbryoApi: "/embryo/getEmbryoDonorById/",
    getProfileDataForEmbryoByUserIdApi: "/embryo/getEmbryoDonorByUserId/",
    verifyResume: "/embryo/verifyEmbryoDonor/",
    getAllEmbryo:"/embryo/getEmbryoDonor",
    searchEmbryo: "/embryo/searchEmbryos",
  },
};
