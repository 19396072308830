import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import style from './LawyerAddDocument.module.css';
import { addDocumentlawyer, getClientDataById } from '../../../../Api/Lawyer';
import moment from 'moment';
import ImageUpload from '../../../SurrogateComponents/Auction/CreatePostAuction/ImageUpload';
import { uploadImage } from '../../../../Api/SurrogateMotherApis';
import Loader from '../../../common/Loader';

const LawyerAddDocument = ({ addocument, setAddDocument, appointmentId, reload }) => {
    const [loader, setLoader] = useState(false);
    const [image, setImage] = useState(null);
    const [fieldValidation, setFieldValidation] = useState(false);

    console.log('appointmentId', appointmentId)
    const [data, setData] = useState({
        "appointmentId": `${appointmentId}`,
        "clientId": "",
        "legal_document_name": "",
        "document_number": "",
        "document_file": {
            "fileName": "",
            "fileUrl": ""
        }

    });

    const getClientDataByIdFunction = async () => {
        try {
            setLoader(true);
            const response = await getClientDataById(appointmentId);
            const clientData = response.data.data;
            clientData.date = moment(clientData.date).format('YYYY-MM-DD');

            setData((prevData) => ({
                ...prevData,
                ...clientData,
                services_requested: clientData.service_requested 
            }));
        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false);
        }
    };

    const addReport = async (e) => {
        e.preventDefault();
        setLoader(true);
        if (image === null) {
            setFieldValidation(true);
            setLoader(false);
            return;
        }

        try {
            const imageDataupload = await uploadImage(image);
            const document_file = {
                fileName: imageDataupload.data.fileName,
                fileUrl: imageDataupload.data.fileLocation
            };

            const updatedData = {
                ...data,
                document_file
            };

            await addDocumentlawyer(updatedData);
            reload();
            resetForm();
            setAddDocument(false);
        } catch (err) {
            console.log(err);
            setLoader(false);
        }
        finally {
            setLoader(false)
        }
    };

    const resetForm = () => {
        setData({
            "appointmentId": `${appointmentId}`,
            "clientId": "",
            "legal_document_name": "",
            "document_number": "",
            "document_file": {
                "fileName": "",
                "fileUrl": ""
            }
        });
        setImage(null);
        setFieldValidation(false);
    };

    const onInputChange = (e) => {
        const { name, value } = e.target;
        setData((prevData) => ({ ...prevData, [name]: value }));
        setFieldValidation(false);
    };

    useEffect(() => {
        if (addocument) {
            getClientDataByIdFunction();
        }
    }, [addocument]);

    return (
        <div>
            <Loader open={loader} />
            <Dialog
                closable={false}
                visible={addocument}
                className='p-0 m-0'
                onHide={() => setAddDocument(false)}
                style={{ width: '70vw', padding: '0px', margin: '0px' }}
                breakpoints={{ '1400px': '80vw', '641px': '100vw' }}
            >
                <div className='Verfied_con'>
                    <div className='d-flex justify-content-center '>
                        <span className={`fW_600 ${style.verfideText}`}>Upload Document</span>
                    </div>

                    <form onSubmit={addReport}>
                        <div className='row'>
                            <div className='col-md-6 mt-3'>
                                <label className={`form-check-label mb-1 ${style.LabelText}`} htmlFor='report_name'>Legal Document Name</label>
                                <input
                                    name='legal_document_name'
                                    required
                                    type='text'
                                    className='form-control'
                                    placeholder='Enter Legal Document Name'
                                    onChange={onInputChange}
                                    value={data.legal_document_name}
                                />
                            </div>
                            <div className='col-md-6 mt-3'>
                                <label className={`form-check-label mb-1 ${style.LabelText}`} htmlFor='report_name'>Document Number</label>
                                <input
                                    name='document_number'
                                    required
                                    type='text'
                                    className='form-control'
                                    placeholder='Enter Document Number'
                                    onChange={onInputChange}
                                    value={data.document_number}
                                />
                            </div>

                        </div>

                        <div className='my-5 d-flex flex-column gap-2'>
                            <div onClick={() => setFieldValidation(false)}>
                                <ImageUpload image={image} setImage={setImage} allowedFileTypes={["image/jpeg", "image/png", "application/pdf"]} />
                            </div>
                            {fieldValidation && (
                                <div className='d-flex justify-content-center' role='alert'><span className='w-50 alert alert-danger text-center'>Please select an image or PDF to upload</span></div>
                            )}
                        </div>
                        <div className='d-flex justify-content-end gap-3'>
                            <button className={style.CencelButton} type='button' onClick={() => { setAddDocument(false); resetForm() }}>Cancel</button>
                            <button className={style.SubmitButton} type='submit' disabled={loader}>Upload</button>
                        </div>
                    </form>
                </div>
            </Dialog>
        </div>
    );
};

export default LawyerAddDocument;
