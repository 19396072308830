import React from "react";
import { useTranslation } from "react-i18next";
import style from "./auctionPost.module.css";

import { Link, NavLink } from "react-router-dom";
import { useState } from "react";
import AuctionForm from "../../Auction/AuctionForm/AuctionForm";
import PopOver from "../../../common/PopOver";
import {
  auctionStatus,
  getAllSurrogateAuction,
} from "../../../../Api/SurrogateMotherApis";
import { useEffect } from "react";
import moment from "moment";
import Loader from "../../../common/Loader";
import Pop from "../../../commonComponents/popover/popover";
import { PopoverItem } from "../../../commonComponents/popovericon/popovericon";
import { useNavigate } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import { deleteAuctionById } from "../../../../Api/SurrogateMotherApis";
import { getSurrogateMotherData } from "../../../../Api/SurrogateMotherApis";
import UnderConstruction from "../../../UnderConstruction/UnderConstruction";



const AuctionPost = ({ getData, showhead, getALLData }) => {

  const [resume, setResume] = useState(false);
  let userData = JSON.parse(sessionStorage.getItem("userData"));
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const [resId, setResId] = useState();
  const [visible, setVisible] = useState(false);
  const [viewData, setViewData] = useState("");
  const [getAllSurrogate, setGetAllSurrogate] = useState([]);
  //   let startTime = new Date().toISOString();
  //   let duration = moment.duration(moment().diff(startTime));

  console.log(userData);

  const getAllSurrogateMotherData = async () => {
    await getSurrogateMotherData(userData.userId)
      .then((res) => {
        console.log(res);
        setGetAllSurrogate(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const calculateDateDifference = (startDate, endDate) => {
    const start = moment(startDate);
    const end = moment(endDate);
    const duration = moment.duration(end.diff(start));

    const days = duration.days();
    const hours = duration.hours();

    if (days > 0) {
      return `${days} days ${hours} hours left`;
    } else {
      return `${hours} hours left`;
    }
  };

  const auction_status_changes = async (id) => {
    await auctionStatus(id, {
      active_status: false,
      userId: userData.userId,
    });
    getALLData();
  };
  console.log(resId);
  useEffect(() => {
    getAllSurrogateMotherData();
  }, []);

  let { t } = useTranslation();

  const auctionDelete = async (id) => {
    setLoader(true);
    await deleteAuctionById(id)
      .then((res) => {
        setLoader(false);
        setVisible(false);
        getALLData();
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };
  return (
    <div>
      <Dialog
        modal
        visible={visible}
        style={{ width: "40rem" }}
        onHide={() => setVisible(false)}
        closable={false}
        draggable={false}
      >
        <div>
          <h3>Delete Post</h3>
          <p className="mt-2">Are you sure you want to delete this post ?</p>
          <div className="d-flex justify-content-end mt-5">
            <div className="d-flex">
              <button
                className="btn btn-secondary me-2"
                onClick={() => {
                  setVisible(false);
                }}
              >
                Cancel
              </button>
              <button
                className="btn btn-danger"
                onClick={() => {
                  auctionDelete(resId);
                }}
              >
                Delete <i className="pi pi-trash danger" />
              </button>
            </div>
          </div>
        </div>
      </Dialog>
      <Loader open={loader} />

      <AuctionForm
        resumeone={resume}
        setResumeone={setResume}
        viewData={viewData}
        getAllSurrogate={getAllSurrogate}
      />
      <div className={`container-fluid`}>
        <div className="row justify-content-start">
          {showhead && (
            <>
              <h4>Active Post</h4>
            </>
          )}
          {getData?.length === 0 && (
            <>
              <div className="text-center mt-5 mb-5">
                {" "}
                <h5 className="text-success">No Active Post</h5>
              </div>
            </>
          )}
          {getData?.map((res, index) => (
            <div
              key={index}
              className="col-12 col-sm-6 col-md-4 p-lg-2 p-md-2 p-sm-2 p-2"
            >
              <div className={`${style.card_wrapper} h-100`}>
                <div
                  style={{ height: "100%" }}
                  className="d-flex align-items-end"
                >
                  <div className={`${style.auction_card}`}>
                    <div className={`${style.card_upper}`} style={{
                      backgroundImage: `url('${res?.photoUrl}')`
                    }} >
                      {/* <img
                        className="card-img-top"
                        src={`${res.photoUrl}`}
                        alt={t("Card image cap")}
                      /> */}

                      <div className={`${style.upper_v_1}`}>
                        <div className={`${style.upper_v_1_1}`}>
                          <div className={`${style.upper_v_1_1_1}`}>
                            <Pop onClick={() => setResId(res?._id)}>
                              <PopoverItem
                                text={"Edit"}
                                icon={<img
                                  src="/images/edit.png"
                                  style={{ height: "19px", width: "19px" }}
                                />}
                                onClick={() =>
                                  navigate(`/surrogate/auction/editpostauction/${res._id}`)
                                }
                              />
                              <PopoverItem
                                text={<span style={{ color: "red " }}>Delete</span>}
                                icon={<img
                                  src="/images/reddelete.png"
                                  style={{ height: "18px", width: "18px" }}
                                />}

                                onClick={() => {
                                  setVisible(true);
                                  setResId(res?._id);
                                }}
                              />
                              <PopoverItem
                                text={"Inactive"}
                                icon={
                                  <img
                                    src="/images/Inactive.svg"
                                    style={{ height: "18px", width: "18px" }}
                                  />
                                }

                                onClick={() => {
                                  // setVisible(true);
                                  // setResId(res?._id);
                                  auction_status_changes(res._id);
                                }}
                              />
                            </Pop>
                          </div>
                        </div>
                      </div>
                      <div className={`${style.upper_v_2}`}>
                        <div className={`${style.upper_v_2_1}`}>
                        End Date &nbsp;{" "}
                                    {t(
                                      // calculateDateDifference(
                                      //   res.startDate,
                                      //   res.endDate
                                      // )
                                      moment(res.endDate).format("DD/MM/YYYY")
                                    )}
                        </div>
                      </div>
                    </div>

                    <div className={`${style.card_lower}`}>
                      <div className={`${style.lower_v_1} lufga_18px`}>
                        {t(res.name)},{t()}
                      </div>
                      <div className={`${style.lower_v_2}`}>
                        {t(res.short_description)}
                      </div>
                      <div
                        className={`${style.lower_v_3}`}
                        onClick={() => {
                          setViewData(res);
                          setResume(true);
                        }}
                      >
                        View Details
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
    //   <>
    //      <div className={`${style.image_section}`}>
    //          <img src='/images/undercons.png' alt='Under Construction Image' />
    //       </div>
    //       <div className={`${style.content_section}`}>
    //         <div className={`${style.lufga_20px}`}>No Auction Post Added </div>
    //          <div className={`${style.lufga_20px} `}>Earn more with innovative services auction</div>
    //           <button className={`${style.AuctionSpaceButton}`}  onClick={() => naviagte("createpost")}>Add Post</button>
    //       </div>
    //  </>
  );
};

export default AuctionPost;
