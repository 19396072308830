import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ViewDetails from "../ViewDetails/ViewDetailsEmbryo";
import { FaArrowLeft } from "react-icons/fa";
import style from "../EmbryoSearch.module.css";
import {
  getsaveSurrogate,
  connectNotification,
  ConnectAddDataSurrogate,
} from "../../../../Api/IntendedParent";
import Toaster from "../../../common/Toaster";
import Loader from "../../../common/Loader";
import { getUserDataById } from "../../../../Api/admin";
import NoSaveUI from "./NoSaveUI";
import { InputText } from "primereact/inputtext";
const SavedContacts = () => {
  let params = useParams();

  let { t } = useTranslation();
  const [visibale, setVisibale] = useState(false);
  const [loader, setLoader] = useState(true);

  const [toaster, setToaster] = useState({
    show: false,
    message: "",
  });
  const userId = sessionStorage.getItem("userId");
  const [userData, setUserData] = useState();

  // console.log(params);

  const getUserDataIP = async () => {
    await getUserDataById(userId)
      .then((res) => {
        setUserData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const connectSuperAdmin = async (cardData) => {
    setLoader(true);
    let payLoadData = {
      role: ["admin"],
      super_admin_message: {
        heading: "New Connection Request",
        msg: `${userData.fullName} has requested to connect with ${cardData?.firstName} (SM)`,
      },
      surrogate_id: cardData.userId,
    };
    await connectNotification(userId, payLoadData)
      .then((res) => {
        // console.log(res);
        setToaster({
          show: true,
          message: "Your connection request has been submitted",
        });
        setLoader(false);
        getUserDataIP()
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const ConnectLeads = async(cardData)=>{
  
    setLoader(true);
  
      let payloadLeads = {
        connected_by:{
          id:userId,
          role:userData.role
  
        },
        connected_to:{
        id:cardData.userId,
        role:params.name === " Surrogate Mother" ? "surrogate_donor" : "eggDonor",
        }
      }
      await ConnectAddDataSurrogate(payloadLeads).then((res)=>{
        console.log(res)
        setLoader(false)
        setToaster({
          show: true,
          message: "Your connection request has been submitted",
        });
        getSavedDataById()
      }).catch((err)=>{
        console.log(err)
        setLoader(false)
      })
  
    }
  const [cards, setCards] = useState([]);

  const getSavedDataById = async () => {
    setLoader(true);
    await getsaveSurrogate(userId)
      .then((res) => {
        // console.log(res);
        let dtaa = res.data.data[0].surrogateData;

        setCards(
          dtaa.filter(
            (s) =>
              s.service_offer ===
              (params.name === " Surrogate Mother" ? "surrogate" : "eggDonor")
          )
        );
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  useEffect(() => {
    getSavedDataById();
    getUserDataIP();
  }, []);

  const navigate = useNavigate();

  //Dummy Data
  const dummyCards = [
    {
      "userId" : "11",
      "photo_url" : "https://s3-alpha-sig.figma.com/img/6312/8d05/7add7afdc466b2448fdf82fac4423011?Expires=1720396800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=YqoagWiKIlcYzUsStaghtBggUKbEzQPYslciXSWae8Ojm66pWrZaIXSp-8-Adp15OHx3O5CnDGxwA6gppnYwzBYzpReBRLkue73Cn9EnMuH8sKR6ZJJEYfP-JiJmcGzsIrSezo8v8kmsMHT~eJog5E0xnX4e6i8Ec1M5790D6R~q0GKIitLtLEqlPmrdXOELZMmV6fPbM3DQObQNqHo9Mmn2lj3uJ1Nd0dGwe246LEzuFr701xDqZP48OdG0SD2s-7JIGJZPSWtW4OW6mkaqBaaGdpzrvMVKwlZIiL-RlMo-e-GWYCgYmFywmCuI5HMlfBDGwK3aVM7V3XF5cuHkgw__",
      "memberId" : "NHPSM001",
      "category" : "Adopting Parents",
      "description" : "Successful Executives , Both work from home , Excited to adopt our first baby"
    },{
      "userId" : "12",
      "photo_url" : "https://s3-alpha-sig.figma.com/img/584a/f944/2e228f14e77513ae619f1deaa560c167?Expires=1720396800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=BiGNz5cPJmakkS~pafVx6utFGTtO0EsNWqEPUzBN4RoNhc~WDcJ0n2ZP45lR1mguesJh-G1BrOQs1Vd51vMsWzTgnRHjGcmr9o8cST5zVhfFHaKP4cCTW~s~TjoB2ZS0WASeML522e6cl3CSs0-lEgA43cvTGXUl6gbjSvVdApvyP-YvHiUzIuW3CS5pM7UH-qo4fIEwnFcTt3S1790i~zL-6H8unq3J1YlO4~VboLJ98T3OJXy6ZUfucjz4TDCaWzFWQsMF9D2chbLAStKf9OmUh3xpRysPUAUbh7Fhnt6-2SGVfPvaUUropOdRHzYoUYEmbPo-eP2lvH8FzdETiA__",
      "memberId" : "NHPSM001",
      "category" : "Adopting Parents",
      "description" : "Successful Executives , Both work from home , Excited to adopt our first baby"
    },,{
      "userId" : "13",
      "photo_url" : "https://s3-alpha-sig.figma.com/img/6312/8d05/7add7afdc466b2448fdf82fac4423011?Expires=1720396800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=YqoagWiKIlcYzUsStaghtBggUKbEzQPYslciXSWae8Ojm66pWrZaIXSp-8-Adp15OHx3O5CnDGxwA6gppnYwzBYzpReBRLkue73Cn9EnMuH8sKR6ZJJEYfP-JiJmcGzsIrSezo8v8kmsMHT~eJog5E0xnX4e6i8Ec1M5790D6R~q0GKIitLtLEqlPmrdXOELZMmV6fPbM3DQObQNqHo9Mmn2lj3uJ1Nd0dGwe246LEzuFr701xDqZP48OdG0SD2s-7JIGJZPSWtW4OW6mkaqBaaGdpzrvMVKwlZIiL-RlMo-e-GWYCgYmFywmCuI5HMlfBDGwK3aVM7V3XF5cuHkgw__",
      "memberId" : "NHPSM001",
      "category" : "Intended Parents",
      "description" : "Successful Executives , Both work from home , Excited to adopt our first baby"
    }
  ]

  return (
    <div>
      <Toaster
        event={toaster.message}
        show={toaster.show}
        severity={"success"}
        setToaster={setToaster}
      />
      <Loader open={loader} />
      <div className="mb-4 mt-4 ms-4">
        <div
          className="d-flex align-items-center gap-2 btn-Secondary p-0 pointer"
          onClick={() => navigate(-1)}
        >
          <FaArrowLeft /> Back
        </div>
      </div>
      <div className={`${style.Savedpost_icon} mt-4`}>
        <div className="d-flex justify-content-between">
          <div className={style.CreditText}>Saved Contacts {params.name}</div>
          <div className={style.SearchContainer}>
              <span className={`p-input-icon-left d-flex align-items-center `}>
                  <i className={`pi pi-search ${style.SearchInputIcon}`}  />
                  <InputText type="search" placeholder="Search" className={style.InputSearch}/>
              </span>
          </div>
        </div>
        {dummyCards.length === 0 ?
        <>
       <div>
        <div className="d-flex justify-content-center  w-100">
        <div className="w-25">
          <img className="w-100" src="/images/undercons.png" />
        </div>
      </div>
      
          <div className={`d-flex justify-content-center lufga_noaucton mt-4`}>No Saved Contacts</div>
          <div className={`d-flex justify-content-center lufga_24px mt-2`}>
          Save Your Favourite {params.name} Profiles for Later
          </div>
        
    </div>
        </>:
        <>
         <div className={`${style.cardContainer} mt-5`}>
          {dummyCards.map((card, index) => (
            <div key={index} className={`w-100  ${style.maincardContainer}`}>
              <div
                className={`${style.card_upper} pointer`}
                // style={{
                //   backgroundImage: `url(${
                //     card?.photos[card.photo_profile_index]?.fileUrl
                //   })`,
                // }}
              >
                 <div
                   className={`${style.card_upper_mask}`}
                //      style={{
                //   backgroundImage: `url(${
                //     card?.photos[
                //       card.photo_profile_index ? card?.photo_profile_index : 0
                //     ]?.fileUrl
                //   })`,
                // }}
                  >

                  </div>
                  <img
                    className={`${style.card_upper_image}`}
                    src={card?.photo_url}
                    // { card?.photos[
                    //   card.photo_profile_index ? card?.photo_profile_index : 0
                    // ]?.fileUrl}
                  />
                <div className={`${style.upper_v_1}`}></div>
              </div>
              <div className={`${style.card_lower} d-flex flex-column`}>
                <div className={`${style.cardAgeText} `}>
                    AP Id: {t(card.memberId)}
                </div>
                <div className="d-flex flex-column justify-content-between py-2">
                  <div className={`${style.category}`}>  
                    {t(card.category)}
                  </div>
                  <div className={`${style.lower_v_2}  `}>
                    {t(card.description)}
                  </div>
                </div>
                <div
                  className={`${style.card_lower_v_1} d-flex flex-column mt-auto`}
                >
                  <div className="d-flex justify-content-between gap-4 mt-auto">
                    {card?.is_connected === true ? (
                      <div className={`${style.lower_v_3}`}>Connected</div>
                    ) : (
                      <button
                        className={`btn ${style.lower_v_3}`}
                        onClick={() => {
                          ConnectLeads(card);
                        }}
                      >
                        Connect
                      </button>
                    )}
                    <button
                      className={`btn ${style.lower_v_Buy}`}
                      onClick={() => {
                        navigate(`/embryo_donor/viewDetail/${card.userId}`);
                      }}
                    >
                      View Details
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        </>}
       
      </div>
    </div>
  );
};

export default SavedContacts;
