import React, { useEffect, useState } from "react";
import style from "./AuctionCreatepage.module.css";
import { useTranslation } from "react-i18next";
import {
  createAuction,
  getSurrogateMotherData,
  uploadImage,
} from "../../../../Api/SurrogateMotherApis";
import { useNavigate } from "react-router";
import { Country, State, City } from "country-state-city";
import Loader from "../../../common/Loader";
import { getDefaultAuction } from "../../../../Api/defaultAuction";
import moment from "moment";
import { getusersforauction } from "../../../../Api/admin";
import { FaArrowLeft } from "react-icons/fa6";
import Toaster from "../../../common/Toaster";
import { all } from "axios";
import ImageUpload from "../../../SurrogateComponents/Auction/CreatePostAuction/ImageUpload";



const AuctionCreatepage = () => {
  let { t } = useTranslation();
  const [loader, setLoader] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [image, setImage] = useState(null);
  const countries = Country.getAllCountries();
  const [statesListing, setStatesListing] = useState([]);
  const [country, setCountry] = useState();
  const [fieldValidation, setFieldValidation] = useState(0);
  const [allUsersData, setAllUsersData] = useState([]);
  const [state, setState] = useState();
  const [city, setCity] = useState('')
  const [citiesListing, setCitiesListing] = useState([])
  const [getAllSurrogate, setGetAllSurrogate] = useState([]);
  const [roleOfUser, setRoleOfUser] = useState("surrogate_donor")
  const [UserId, setUserId] = useState()
  const [selectedSurrogate, setSelectedSurrogate] = useState(null)
  const [toaster, setToaster] = useState({
    show: false,
    message: "",
  });




  /**
   * this function is to get all users data so we can get the data role wise for our usecase
   */

  const getAllUsers = async () => {
    try {
      await getusersforauction().then((res) => {
        setAllUsersData(res.data.data.filter(res => res.role === roleOfUser));
      });
      return true;
    }
    catch (error) {
      console.log(error, "this is the error from the get all users api in surrogate")
    }
  }


  /**
   * getNameAndId function is used to get the userId of user which admin selects to create auction for them
   */
  function getNameAndId(e) {
    setFieldValidation(0);
    let value = JSON.parse(e.target.value)

    console.log(value, "this is the selected value")
    setSelectedSurrogate(value)
    setUserId(value._id)

    setFormData((prevState) => ({
      ...prevState,
      ...{
        name: value.fullName,
        userId: value._id
      }
    }));
  }




  /**
   * This function is responsible to get the whole data of a surrogate so we can use that data to create a auction
   */
  const getAllSurrogateMotherData = async () => {
    setLoader(true);
    await getSurrogateMotherData(UserId)
      .then((res) => {
        setGetAllSurrogate(res.data.data);
        console.log(res.data.data, "[][][][][][][][]")


        if (res?.data?.data?.state) {
          setFormData(pre => ({ ...pre, state: res?.data?.data?.state }))
        }
        else {
          setFormData(pre => ({ ...pre, state: "" }))
          setState("")
          // setCountry(null)
        }
        if (res?.data?.data?.country) {
          // setFormData(pre => ({ ...pre, country: res?.data?.data?.country }))
        }
        else {
          setFormData(pre => ({ ...pre, country: "" }))
          setCountry()
          // setCountry(null)
        }

        if (res.data.data.country !== undefined) {
          let cRes = countries?.find(res2 => res2?.name == res.data.data.country)
          setCountry(`${cRes.name}&&${cRes.isoCode}`)
          getStates(`${cRes.name}&&${cRes.isoCode}`)
        }
        else{
          setCountry("")
          setState("")
        }



        if (res.data.data.city !== undefined) {
          setCity(res.data.data.city)
        }

      })
      .catch((err) => {
        console.log(err);
      });
    setLoader(false);
  };


  /**
 * function to get states of a country after selection of country field
 */

  function getStates(res) {
    let isoCode = res.split('&&')[1]
    setCountry(res)
    let states = State.getStatesOfCountry(isoCode)

    setStatesListing(states)

  }




  function getCities(res) {
    let isoCode = res.split('&&')[1]
    let cCode = res.split('&&')[2]
    setState(res)
    let cities = City.getCitiesOfState(cCode, isoCode)
    setCitiesListing(cities)
  }




  /**
   * This state is use to store the data which superadmin fill , which we use later to create a auction
   */
  const [formData, setFormData] = useState({
    userId: UserId,
    photoUrl: "",
    photoFileName: "",
    name: "",
    email: "",
    short_description: "",
    country: "",
    state: "",
    buy_now_price: "",
    startDate: moment().format("YYYY-MM-DD"),
    endDate: "",
    starting_bid_amount: 0,
  });


  /**
   * Function to handle input change of fields of same type like input box
   */
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFieldValidation(0);
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  /**
   * this is handling navigation to other pages
   */
  const navigate = useNavigate();


  /**
   * To Show the validation on screen to user and to make sure no data will go empty to the auction
   */
  const validation = () => {
    if (image === null) {
      setFieldValidation(1);
      return false;
    }
    else if (formData.name === "") {
      setFieldValidation(9);
      return false;
    }
    else if (formData.short_description.length === 0) {
      setFieldValidation(2);
      return false;
    } else if (!country || country.length === 0) {
      // console.log(formData.country);
      setFieldValidation(3);
      return false;
    }
    //  else if (!state || state.length === 0) {
    //   setFieldValidation(4);
    //   return false;
    // } 
    else if (formData.buy_now_price.length === 0) {
      setFieldValidation(5);
      return false;
    } else if (formData.startDate.length === 0) {
      setFieldValidation(6);
      return false;
    } else if (formData.endDate.length === 0) {
      setFieldValidation(7);
      return false;
    } else if (formData.starting_bid_amount <= 0) {
      setFieldValidation(8);
      return false;
    } else {
      return true;
    }
  };


  const addsurrogate = async (e) => {
    e.preventDefault();
    let valid = validation();
    if (valid) {
      setLoader(true);
      let imageDataupload = await uploadImage(image);
      let objdata = {
        state: state?.split("&&")[0],
        country: country?.split("&&")[0],
        photoUrl: imageDataupload.data.fileLocation,
        photoFileName: imageDataupload.data.fileName,
        name: `${getAllSurrogate?.firstName} ${getAllSurrogate?.middleName} ${getAllSurrogate?.lastName}`,
        email: getAllSurrogate?.email,
      };
      let data = { ...formData, ...objdata };

      // console.log(imageDataupload);
      await createAuction(data)
        .then((res) => {
          // console.log(res);
          setLoader(false);
          navigate("/superadmin/manage_auction");
        })
        .catch((err) => {
          console.log(err);
          setToaster({
            show: true,
            message: "This Surrogate Mother Currently Have a Active Post, So you can not Create One More",
          });
          setLoader(false);
        });
    }
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    setFieldValidation(0);
    setImage(file);
    reader.onload = () => {
      setImageSrc(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const defaultAuctionTime = async () => {
    const defultData = await getDefaultAuction();
    let duration = Number(defultData.data.data.auction_duration);
    let add_data = moment().add(duration, "days").format("YYYY-MM-DD");

    setFormData((prevState) => ({
      ...prevState,
      endDate: add_data,
    }));
  };



  useEffect(() => {
    getAllUsers();
  }, []);


  useEffect(() => {
    defaultAuctionTime();
  }, [UserId]);


  useEffect(() => {
    getAllSurrogateMotherData();
  }, [UserId]);

  useEffect(() => {

    if (statesListing.length !== 0) {

      let sRes = statesListing?.find(res2 => res2?.name == formData.state)

      if (sRes !== undefined) {

        setState(`${sRes.name}&&${sRes.isoCode}&&${sRes.countryCode}`)

        getCities(`${sRes.name}&&${sRes.isoCode}&&${sRes.countryCode}`)

      }


    }

  }, [statesListing])


  return (
    <>
      {toaster && (
        <>
          <Toaster
            event={toaster.message}
            show={toaster.show}
            severity={"error"}
            setToaster={setToaster}
          />
        </>
      )}
      <Loader open={loader} />
      <div className="mb-4 mt-4 ms-4">
        <div
          className="d-flex align-items-center gap-2 btn-Secondary p-0 pointer"
          onClick={() => navigate("/superadmin/manage_auction")}
        >
          <FaArrowLeft /> Back
        </div>
      </div>
      {/* {console.log(fieldValidation, "dasda")} */}
      <div className={style.CreatePost}>
        <div className="FormParent">
          <form onSubmit={addsurrogate}>
            <div className={style.MainHeading}>Create Auction Post</div>
            {/* <div className={` mb-4 ${style.Images_Border} `}>
              {imageSrc ? (
                <>
                  <label htmlFor="fileInput" className={style.center_content}>
                    <img
                      src={imageSrc}
                      alt="Uploaded"
                      style={{ width: "450px", height: "350px" }}
                      className="pointer"
                    />
                  </label>
                  <input
                    type="file"
                    id="fileInput"
                    accept="image/*"
                    onChange={handleImageUpload}
                    style={{ display: "none" }}
                    className="pointer"
                  />
                </>
              ) : (
                <>
                  <label htmlFor="fileInput" className={style.center_content}>
                    <i
                      className={`bi bi-card-image  mb-3 ${style.imagesCustom}`}
                    ></i>
                    <div>
                      <div className={style.UploadName}>Upload image</div>
                    </div>
                  </label>
                  <input
                    type="file"
                    id="fileInput"
                    accept="image/*"
                    onChange={handleImageUpload}
                    style={{ display: "none" }}
                    className="pointer"
                  />
                </>
              )}
            </div> */}
            <div onClick={() =>setFieldValidation(0) }>
              <ImageUpload setImage={setImage} image={image} />
            </div>
            <div className="d-flex justify-content-center ">
              {fieldValidation === 1 && (
                <>
                  <p className="text-danger">Please upload image</p>
                </>
              )}
            </div>
            <div className="row mb-3 mt-5">
              <div className="col-md-6">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label pointer ${style.LabelHeading}`}
                >
                  {t("Name")}
                  <span className="text-danger"></span>
                </label>

                <select
                  required
                  type="select"
                  className={`form-select ${style.InputCustom}`}
                  aria-label="Default select example"
                  // value={JSON.stringify(selectedSurrogate)}
                  value={selectedSurrogate ? JSON.stringify(selectedSurrogate) : "aa"}
                  placeholder=""
                  name="name"
                  onChange={getNameAndId}
                >
                  <option value="aa" selected disabled>Please Select a User To Create Auction</option>
                  {
                    allUsersData?.map((res, index) => {
                      return (
                        <option
                          key={index}
                          onClick={() => {
                            UserId = res.userId;
                          }}
                          value={JSON.stringify(res)}>
                          {res.fullName} ({res.memberId})
                        </option>
                      );
                    })
                  }
                </select>
                {fieldValidation === 9 && (
                  <>
                    <p className="text-danger">Please select a Surrogate</p>
                  </>
                )}
              </div>
              <div className="col-md-6">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("Email")}
                  <span className="text-danger">*</span>
                </label>

                <input
                  type="email"
                  className={`form-control ${style.InputCustom}`}
                  placeholder=""
                  disabled
                  name="email"
                  value={getAllSurrogate.email}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-12">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("Short Description")}
                  <span className="text-danger">*</span>
                </label>

                <textarea
                  type="text"
                  row={1}
                  className={`form-control ${style.InputCustom}`}
                  placeholder=""
                  name="short_description"
                  onChange={handleInputChange}
                />
                {fieldValidation === 2 && (
                  <>
                    <p className="text-danger">
                      Please enter short description
                    </p>
                  </>
                )}
              </div>
            </div>

            <div className="row mb-3">

              <div className="col-md-6 ">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("Country")}
                  <span className="text-danger">*</span>{" "}
                </label>
                <select
                  className={`form-select`}
                  name="country"
                  value={country}
                  required
                  onChange={(e) => {
                    getStates(e.target.value)
                    setState('')
                    setCity('')
                  }}
                >
                  <option selected disabled value={""}>
                    {t("select")}
                  </option>
                  {countries.map((res, index) => {
                    return (
                      <option key={index} value={`${res.name}&&${res.isoCode}`}>
                        {res.name}
                      </option>
                    );
                  })}
                </select>
                {fieldValidation === 3 && (
                  <>
                    <p className="text-danger">Please select country</p>
                  </>
                )}
              </div>


              <div className="col-md-6">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("State of Residence")}
                  
                </label>

                <select
                  className={`form-select`}
                  name="state"
                  value={state}
                  onChange={(e) => {
                    getCities(e.target.value)
                    setCity('')
                    setFieldValidation(0);
                  }}
                >
                  <option selected disabled value={""}>
                    {t("select")}
                  </option>
                  {statesListing?.map((res, index) => {
                    return (
                      <option
                        key={index}
                        value={`${res.name}&&${res.isoCode}&&${res.countryCode}`}
                      >
                        {res.name}
                      </option>
                    );
                  })}
                </select>
                
              </div>

            </div>

            <div className="row mb-3">

              <div className="col-md-6">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("Buy Now Price ($)")}
                  <span className="text-danger">*</span>
                </label>

                <input
                  type="number"
                  className={`form-control ${style.InputCustom}`}
                  placeholder=""
                  name="buy_now_price"
                  onChange={handleInputChange}
                />
                {fieldValidation === 5 && (
                  <>
                    <p className="text-danger">Please enter buy now price</p>
                  </>
                )}
              </div>
              <div className="col-md-6">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("Auction Start Date")}
                  <span className="text-danger"></span>
                </label>

                <input
                  type="date"
                  className={`form-control ${style.InputCustom}`}
                  placeholder=""
                  name="startDate"
                  value={formData.startDate}
                  onChange={handleInputChange}
                  disabled
                />
                {fieldValidation === 6 && (
                  <>
                    <p className="text-danger">
                      Please select auction start date
                    </p>
                  </>
                )}
              </div>

            </div>
            <div className="row mb-3">
              <div className="col-md-6">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("Auction End Date")}
                  <span className="text-danger">*</span>
                </label>

                <input
                  type="date"
                  className={`form-control ${style.InputCustom}`}
                  placeholder=""
                  value={formData.endDate}
                  name="endDate"
                  onChange={handleInputChange}
                />
                {fieldValidation === 7 && (
                  <>
                    <p className="text-danger">
                      Please select auction end date
                    </p>
                  </>
                )}
              </div>
              <div className="col-md-6">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("Starting Bid Amount ($)")}
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="number"
                  className={`form-control ${style.InputCustom}`}
                  placeholder=""
                  name="starting_bid_amount"
                  onChange={handleInputChange}
                />
                {fieldValidation === 8 && (
                  <>
                    <p className="text-danger">
                      Please enter Starting Bid Amount{" "}
                    </p>
                  </>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-end gap-3 mb-3">
              <button
                className={style.cancelButton}
                onClick={() => {
                  navigate("/superadmin/manage_auction");
                }}
              >
                Cancel
              </button>
              <button className={style.SaveButton} type="submit">
                Create Post
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AuctionCreatepage;
