import { Navigate, Outlet } from "react-router";

export const SuperAdminKeeper = () => {
  let user = localStorage.getItem("userData");
  let userData = {};
  if (user) {
    userData = JSON.parse(user);
  }
  if (userData.token && userData.role=="admin") {
    return <Outlet />;
  }
  return <Navigate to="/superadmin" />;
};
