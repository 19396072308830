import React, { useEffect, useState } from "react";
import style from "./CreatePost.module.css";
import { useTranslation } from "react-i18next";
import {
  createAuction,
  getSurrogateMotherData,
  uploadImage,
} from "../../../../Api/SurrogateMotherApis";
import { useNavigate } from "react-router";
import { Country, State, City } from "country-state-city";
import Loader from "../../../common/Loader";
import { getDefaultAuction } from "../../../../Api/defaultAuction";
import moment from "moment";
import { FaArrowLeft } from "react-icons/fa6";
import ImageUpload from "./ImageUpload";
// import plus from "../../../../../public/images/AddLogo.png"
// import upload from "../../../../../public/images/uploadLogo.png"
const CreatePost = () => {
  let { t } = useTranslation();
  const [loader, setLoader] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [image, setImage] = useState(null);
  const countries = Country.getAllCountries();
  const [statesListing, setStatesListing] = useState([]);
  const [country, setCountry] = useState();
  const [fieldValidation, setFieldValidation] = useState(0);

  const [state, setState] = useState();
  const [getAllSurrogate, setGetAllSurrogate] = useState([]);

  let userData = JSON.parse(sessionStorage.getItem("userData"));

  function getStates(res) {
    let isoCode = res.split("&&")[1];
    setCountry(res);
    let states = State.getStatesOfCountry(isoCode);
    setStatesListing(states);
    setFieldValidation(0);
  }
  // console.log(imageSrc);

  const getAllSurrogateMotherData = async () => {
    await getSurrogateMotherData(userData.userId)
      .then((res) => {
        let resultData=res.data.data;
        console.log(res, "sm data sm data sm data");

        if (res.data.data.country !== undefined) {
          let cRes = countries?.find(
            (res2) => res2?.name == res.data.data.country
          );
          setCountry(`${cRes.name}&&${cRes.isoCode}`);
          getStates(`${cRes.name}&&${cRes.isoCode}`, resultData.city);
        }

        setGetAllSurrogate(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };


  function getStates(res) {
    let isoCode = res.split("&&")[1];
    setCountry(res);
    let states = State.getStatesOfCountry(isoCode);
    setStatesListing(states);
    
  }


  useEffect(() => {
    getAllSurrogateMotherData();
  }, []);
  const [formData, setFormData] = useState({
    userId: userData.userId,
    photoUrl: "",
    photoFileName: "",
    name: "",
    email: "",
    short_description: "",
    country: "",
    state: "",
    buy_now_price: "",
    startDate: moment().format("YYYY-MM-DD"),
    endDate: "",
    starting_bid_amount: 0,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFieldValidation(0);
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const navigate = useNavigate();

  const validation = () => {
    if (image === null) {
      setFieldValidation(1);
      return false;
    } 
    else if (!country || country.length === 0) {
      // console.log(formData.country);
      setFieldValidation(3);
      return false;
    }
    
    else if (formData.short_description.length === 0) {
      setFieldValidation(2);
      return false;
    } 
 
    else if (formData.buy_now_price.length === 0) {
      setFieldValidation(5);
      return false;
    } 
    else if (formData.starting_bid_amount <= 0) {
      setFieldValidation(8);
      return false;
    }
    else if (formData.startDate.length === 0) {
      setFieldValidation(6);
      return false;
    }
     else if (formData.endDate.length === 0) {
      setFieldValidation(7);
      return false;
    }
     else {
      return true;
    }
  };

  const addsurrogate = async (e) => {
    e.preventDefault();
    let valid = validation();
    if (valid) {
      setLoader(true);
      let imageDataupload = await uploadImage(image);
      let objdata = {
        state: state?.split("&&")[0],
        country: country?.split("&&")[0],
        photoUrl: imageDataupload.data.fileLocation,
        photoFileName: imageDataupload.data.fileName,
        name: `${getAllSurrogate?.firstName} ${getAllSurrogate?.middleName} ${getAllSurrogate?.lastName}`,
        email: getAllSurrogate?.email,
      };
      let data = { ...formData, ...objdata };

      // console.log(imageDataupload);
      await createAuction(data)
        .then((res) => {
          // console.log(res);
          setLoader(false);
          navigate("/surrogate/auction");
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
        });
    }
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    setFieldValidation(0);
    setImage(file);
    reader.onload = () => {
      setImageSrc(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const defaultAuctionTime = async () => {
    const defultData = await getDefaultAuction();
    let duration = Number(defultData.data.data.auction_duration);
    let add_data = moment().add(duration, "days").format("YYYY-MM-DD");
    // console.log(add_data)
    setFormData((prevState) => ({
      ...prevState,
      endDate: add_data,
    }));
  };
  useEffect(() => {
    defaultAuctionTime();
  }, []);
  return (
    <>
      <Loader open={loader} />
      <div className="mb-4 mt-4 ms-4">
        <div
          className="d-flex align-items-center gap-2 btn-Secondary p-0 pointer"
          onClick={() => navigate("/surrogate/auction")}
        >
          <FaArrowLeft /> Back
        </div>
      </div>
      {/* {console.log(fieldValidation, "dasda")} */}
      <div className={style.CreatePost}>
        <div className="FormParent">
          <form onSubmit={addsurrogate}>
            <div className={style.MainHeading}>Create Auction Post</div>
            {/* <div className={` mb-4 ${style.Images_Border} `}>
              {imageSrc ? (
                <>
                  <label htmlFor="fileInput" className={style.center_content}>
                    <img
                      src={imageSrc}
                      alt="Uploaded"
                      style={{ width: "450px", height: "350px" }}
                      className="pointer"
                    />
                  </label>
                  <input
                    type="file"
                    id="fileInput"
                    accept="image/*"
                    onChange={handleImageUpload}
                    style={{ display: "none" }}
                    className="pointer"
                  />
                </>
              ) : (
                <>
                  <label htmlFor="fileInput" className={style.center_content}>
                    <i
                      className={`bi bi-card-image  mb-3 ${style.imagesCustom}`}
                    ></i>
                    <div>
                      <div className={style.UploadName}>Upload image</div>
                    </div>
                  </label>
                  <input
                    type="file"
                    id="fileInput"
                    accept="image/*"
                    onChange={handleImageUpload}
                    style={{ display: "none" }}
                    className="pointer"
                  />
                </>
              )}
            </div> */}
            <ImageUpload setImage={setImage} image={image} />
            <div className="d-flex justify-content-center ">
              {fieldValidation === 1 && (
                <>
                  <p className="text-danger">Please upload image</p>
                </>
              )}
            </div>
            <div className="row mb-3 mt-2">

              <div className="col-md-4 mt-4">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label pointer ${style.LabelHeading}`}
                >
                  {t("NHP ID")}
                  <span className="text-danger"></span>
                </label>

                <input
                  type="text"
                  className={`form-control  ${style.InputCustom}`}
                  disabled
                  value={`${getAllSurrogate.memberId}`}
                  placeholder=""
                  name="name"
                  onChange={handleInputChange}
                />
              </div>


              <div className="col-md-4 mt-4">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("Country")}
                  <span className="text-danger">*</span>{" "}
                </label>
                <select
                  className={`form-select `}
                  value={country}
                  name="country"
                  onChange={(e) => {
                    getStates(e.target.value);
                  }}
                >
                  <option selected disabled value={null}>
                    {t("select")}
                  </option>
                  {countries.map((res, index) => {
                    return (
                      <option key={index} value={`${res.name}&&${res.isoCode}`}>
                        {res.name}
                      </option>
                    );
                  })}
                </select>
                {fieldValidation === 3 && (
                  <>
                    <p className="text-danger">Please select country</p>
                  </>
                )}
              </div>

              <div className="col-md-4 mt-4">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("State of Residence")}
                 
                </label>

                <select
                  className={`form-select `}
                  name="state"
                  onChange={(e) => {
                    setState(e.target.value);
                    setFieldValidation(0);
                  }}
                >
                  <option selected disabled value={null}>
                    {t("select")}
                  </option>
                  {statesListing?.map((res, index) => {
                    return (
                      <option
                        key={index}
                        value={`${res.name}&&${res.isoCode}&&${res.countryCode}`}
                      >
                        {res.name}
                      </option>
                    );
                  })}
                </select>
                {fieldValidation === 4 && (
                  <>
                    <p className="text-danger">Please select state</p>
                  </>
                )}
              </div>






              {/* <div className="col-md-6">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("Email")}
                  <span className="text-danger">*</span>
                </label>

                <input
                  type="email"
                  className={`form-control ${style.InputCustom}`}
                  placeholder=""
                  disabled
                  name="email"
                  value={getAllSurrogate.email}
                  onChange={handleInputChange}
                />
              </div> */}



              <div className="col-md-12 mt-3">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("Short Description")}
                  <span className="text-danger">*</span>
                </label>

                <textarea
                  type="text"
                  rows={1}
                  className={`form-control ${style.InputCustom}`}
                  placeholder=""
                  name="short_description"
                  onChange={handleInputChange}
                />
                {fieldValidation === 2 && (
                  <>
                    <p className="text-danger">
                      Please enter short description
                    </p>
                  </>
                )}
              </div>




              <div className="col-md-3 mt-4">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("Buy Now Price ($)")}
                  <span className="text-danger">*</span>
                </label>

                <input
                  type="number"
                  className={`form-control ${style.InputCustom}`}
                  placeholder=""
                  name="buy_now_price"
                  onChange={handleInputChange}
                />
                {fieldValidation === 5 && (
                  <>
                    <p className="text-danger">Please enter buy now price</p>
                  </>
                )}
              </div>


              <div className="col-md-3 mt-4">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("Bidding Starting Price ($)")}
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="number"
                  className={`form-control  ${style.InputCustom}`}
                  placeholder=""
                  name="starting_bid_amount"
                  onChange={handleInputChange}
                />
                {fieldValidation === 8 && (
                  <>
                    <p className="text-danger">
                      Please enter Starting Bid Amount{" "}
                    </p>
                  </>
                )}
              </div>


              <div className="col-md-3 mt-4">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("Auction Start Date")}
                  <span className="text-danger"></span>
                </label>

                <input
                  type="date"
                  className={`form-control ${style.InputCustom}`}
                  placeholder=""
                  name="startDate"
                  value={formData.startDate}
                  onChange={handleInputChange}
                  disabled
                />
                {fieldValidation === 6 && (
                  <>
                    <p className="text-danger">
                      Please select auction start date
                    </p>
                  </>
                )}
              </div>

              <div className="col-md-3 mt-4">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("Auction End Date")}
                  <span className="text-danger">*</span>
                </label>

                <input
                  type="date"
                  className={`form-control  ${style.InputCustom}`}
                  placeholder=""
                  value={formData.endDate}
                  name="endDate"
                  onChange={handleInputChange}
                />
                {fieldValidation === 7 && (
                  <>
                    <p className="text-danger">
                      Please select auction end date
                    </p>
                  </>
                )}
              </div>



            </div>


            <div className="d-flex justify-content-end gap-3 mb-3 mt-5">
              <button
                className={style.cancelButton}
                type="button"
                onClick={() => {
                  navigate("/surrogate/auction");
                }}
              >
                Cancel
              </button>
              <button className={style.SaveButton} type="submit">
                Create Post
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default CreatePost;
