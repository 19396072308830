import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './common.css';

export default function SubscriptionCard({ product, navigator, selectedPlanData }) {
    const { t } = useTranslation();
    // console.log(product,"pppppppppppppppppppppppppppppppp")

    // Log data for debugging
    useEffect(() => {
        // console.log("Selected Plan Data:", selectedPlanData);
        // console.log("Product:", product);
        // console.log("Comparing plan IDs:", selectedPlanData?.subscription_plan?.plan_id, product?._id);
    }, [selectedPlanData, product]);

    // Check if data is valid
    const isCurrentPlan = selectedPlanData?.subscription_plan?.plan_id === product?._id && selectedPlanData?.subscription_plan?.status;
    
    console.log(isCurrentPlan)

    return (
        <div className='style_container py-3'>
            <div className="category-slider-card">
                <div className="p-0 w-100 borderShodowCard">
                    <div className='px-md-5 px-sm-4 px-4' style={{ minHeight: '350px' }}>
                        <div className='CardCustom mb-3 pt-5'>
                            <span className='FreeText pt-3'>{t(product?.plan_name)}</span>
                            <span className='dollerText'>{t("$")}<span>{t(product?.amount)}</span></span>
                            <span className='CompleteFreeText'>{t("user/mo")}</span>
                            <hr />
                        </div>
                        <div className='CardCustom mb-4'>
                            <span className='CompleteFreeText'>{t(product?.plan_description)}</span>
                            <hr />
                            {isCurrentPlan
                                ? <button className='SelectPlantTextfree mb-3'>{t("Current Plan")}</button>
                                : <button className='SelectPlantText mb-3' onClick={() => navigator(product)}>{t("Select Plan")}</button>
                            }
                        </div>
                    </div>
                    <div className='text-center FeaturesMain'>
                        <span className='FeaturesText'>{t("All features options")}</span>
                    </div>
                    <div className='CardCustom p-3 features_card'>
                        {product?.included_features?.map((res, index) => (
                            <span key={index} className='TextICon d-flex'>
                                <i className="bi bi-check2 me-1" style={{ alignSelf: 'start' }}></i>
                                <span>{t(res)}</span>
                            </span>
                        ))}
                        {product?.not_included_features?.map((res, index) => (
                            <span key={index} className='TextICon d-flex'>
                                <i className="bi bi-x me-1" style={{ alignSelf: 'start' }}></i>
                                <span>{t(res)}</span>
                            </span>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
