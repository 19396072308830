import React from 'react'
import 'primeicons/primeicons.css';

import { NavLink } from "react-router-dom";
import { GoChecklist } from "react-icons/go";
import { RxDashboard } from 'react-icons/rx';


const Surrogate = () => {
  
  function navigateToLogin(){
    window.location.href = `https://www.newhopepoints.org/signIn`;
    sessionStorage.clear()
  }
  
  return (
    <div>
      <NavLink style={{ textDecoration: "none" }} to={`dashboard`}>
        <div className="navlink">
          <div className="fs-4 d-flex align-items-center">
            
            <RxDashboard style={{ fontSize: '1.3rem' }}/>

          </div>
          <div>Dashboard</div>
        </div>
      </NavLink>

      <NavLink style={{ textDecoration: "none" }} to={`subplan`}>
        <div className="navlink">
          <div className="fs-4">
            <i className="pi pi-dollar" style={{ fontSize: '1.2rem' }}></i>
          </div>
          <div>Subscription Plan</div>
        </div>
      </NavLink>

      <NavLink style={{ textDecoration: "none" }} to={`resume`}>
        <div className="navlink">
          <div className="fs-4">
            <GoChecklist />
          </div>
          <div>Resume</div>
        </div>
      </NavLink>

      <NavLink style={{ textDecoration: "none" }} to={`auction`}>
        <div className="navlink">
          <div className="fs-4">
            <i className="pi pi-money-bill" style={{ fontSize: '1.2rem' }}></i>
          </div>
          <div>Auction</div>
        </div>
      </NavLink>

      <NavLink style={{ textDecoration: "none" }} to={`myprofile`}>
        <div className="navlink">
          <div className="fs-4">
            <i className="pi pi-user" style={{ fontSize: '1.2rem' }}></i>
          </div>
          <div>My Profile</div>
        </div>
      </NavLink>
      <hr></hr>

      <NavLink style={{ textDecoration: "none" }} to={`setting`}>
        <div className="navlink">
          <div className="fs-4" style={{ width: '20px' }}>

          </div>
          <div>Settings</div>
        </div>
      </NavLink>

      <div className='pointer' style={{ textDecoration: "none" }} onClick={navigateToLogin}>
        <div className="navlink">
          <div className="fs-4" style={{ width: '20px' }}>

          </div>
          <div>Logout</div>
        </div>
      </div>
    </div>
  )
}
export default Surrogate;