import React from 'react'
import { Dialog } from 'primereact/dialog';
import style from "./Verified.module.css"
const Verfied = ({ visibale, setVisibale }) => {
    return (
        <div>
            <Dialog
                closable={false}
                visible={visibale}
                className='p-0 m-0'
                onHide={() => setVisibale(false)}
                style={{ width: "50vw", padding: "0px", margin: "0px" }}
                breakpoints={{ "960px": "75vw", "641px": "100vw" }}
            >
                <div className='Verfied_con'>

                    <div className='d-flex justify-content-center '>
                        <span className={style.verfideText}>Verify your Profile</span>

                    </div>
                    <div className='d-flex justify-content-center'>
                        <span className={style.BackgroundText}> Background Verification</span>
                    </div>
                    <form action="">
                        <div className="row mb-4">

                            <div className="col-md-12">

                                <label className={`form-check-label mb-1 ${style.LabelText}`} htmlFor="flexCheckDefault">Full Name<span className='text-danger'>*</span></label>
                                <input type="text" className="form-control" placeholder="Enter Your Name" />
                            </div>
                        </div>
                        <div className="row mb-4">

                            <div className="col-md-6">
                                <label className={`form-check-label mb-1 ${style.LabelText}`} htmlFor="flexCheckDefault">Contact Number<span className='text-danger'>*</span></label>
                                <input type="number" className="form-control" placeholder="Enter Your Contact Number" />
                            </div>
                            <div className="col-md-6">
                                <label className={`form-check-label mb-1 ${style.LabelText}`} htmlFor="flexCheckDefault">Email Address<span className='text-danger'>*</span></label>
                                <input type="email" className="form-control" placeholder="Enter Your Email Address" />
                            </div>
                        </div>
                        <div className="row mb-4">

                            <div className="col-md-6">
                                <label className={`form-check-label mb-1 ${style.LabelText}`} htmlFor="flexCheckDefault">SSN Number<span className='text-danger'>*</span></label>
                                <input type="number" className="form-control" placeholder="Enter your SSN Number " />
                            </div>
                            <div className="col-md-6">
                                <label className={`form-check-label mb-1 ${style.LabelText}`} htmlFor="flexCheckDefault">Email Address<span className='text-danger'>*</span></label>
                                <input type="email" className="form-control" placeholder="Enter your driving license number" />
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col-md-12">
                                <label className={`form-check-label mb-1 ${style.LabelText}`} htmlFor="flexCheckDefault">Current Address<span className='text-danger'>*</span></label>
                                <input type="text" className="form-control" placeholder="Enter Your Address" />
                            </div>
                        </div>

                        <div className='d-flex justify-content-end gap-3'>
                            <button className={style.CencelButton} onClick={() => setVisibale(false)}>Cancel</button>
                            <button className={style.SubmitButton} onClick={() => setVisibale(false)}>Submit</button>
                        </div>
                    </form>
                </div>
            </Dialog>
        </div>
    )
}

export default Verfied