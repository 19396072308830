import React from 'react'
import 'primeicons/primeicons.css';

import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { GoQuestion, GoChecklist, GoPerson } from "react-icons/go";
import { RxDashboard } from 'react-icons/rx';


const Lawyer = () => {

  function navigateToLogin() {
    window.location.href = `https://www.newhopepoints.org/signIn`;
    sessionStorage.clear()
  }


  return (
    <div>
      <NavLink style={{ textDecoration: "none" }} to={`dashboard`}>
        <div className="navlink">
          <div className="svg_container d-flex align-items-center">

            <RxDashboard style={{ fontSize: '1.3rem' }} />


          </div>
          <div>Dashboard</div>
        </div>
      </NavLink>

      <NavLink style={{ textDecoration: "none" }} to={`myclient`}>
        <div className="navlink">
          <div className="svg_container d-flex align-items-center">
            <i className="pi pi-list" style={{ fontSize: '1.2rem' }}></i>
          </div>
          <div>My Client</div>
        </div>
      </NavLink>

      <NavLink style={{ textDecoration: "none" }} to={`mycalender`}>
        <div className="navlink">
          <div className="d-flex align-items-center">

            <i className="pi pi-calendar" style={{ fontSize: '1.2rem' }}></i>

          </div>
          <div>My Calender</div>
        </div>
      </NavLink>

      <NavLink style={{ textDecoration: "none" }} to={`myprofile`}>
        <div className="navlink">
          <div className="d-flex align-items-center">
            <i className="pi pi-user" style={{ fontSize: '1.2rem' }}></i>
          </div>
          <div>My Profile</div>
        </div>
      </NavLink>


      <hr></hr>

      <NavLink style={{ textDecoration: "none" }} to={`setting`}>
        <div className="navlink">
          <div className="fs-4" style={{ width: '20px' }}>

          </div>
          <div>Settings</div>
        </div>
      </NavLink>


      <div className='pointer' style={{ textDecoration: "none" }} onClick={navigateToLogin}>
        <div className="navlink">
          <div className="fs-4" style={{ width: '20px' }}>

          </div>
          <div>Logout</div>
        </div>
      </div>


    </div>




  )
}
export default Lawyer;