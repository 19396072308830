import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { SidebarWrapper } from "../../routes/SidebarWrapper";
import { changeHeaderData } from "../../Store/Slices/headerDataSlice";
import { useDispatch } from "react-redux";

export const EmbryoDonorAuth = () => {

  function navigateToLogin() {
    window.location.href = `https://www.newhopepoints.org/signIn`;
    sessionStorage.clear()
  }

  const { search } = useLocation();
  const dispatch = useDispatch();

  function jwtoken() {
    if (search !== "") {
      let token = new URLSearchParams(window.location.search).get("token");
      // let email = new URLSearchParams(window.location.search).get("email");
      let userId = new URLSearchParams(window.location.search).get("id");
      let getDataId= new URLSearchParams(window.location.search).get("getDataId")

      if (token && userId) {
        sessionStorage.setItem("authToken", token);
        sessionStorage.setItem("userId", userId);
        sessionStorage.setItem("getDataId", getDataId);
      }

      dispatch(
        changeHeaderData({
          ClinicProfileId: userId,
        })
      );

      return true;

    } else {
      if (
        sessionStorage.getItem("authToken") &&
        sessionStorage.getItem("userId")
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  return (
    <>
      {jwtoken() ? (
        <SidebarWrapper role="embryo_donor">
          <Outlet></Outlet>
        </SidebarWrapper>
      ) : (
        navigateToLogin()
      )}
    </>
  );
};
