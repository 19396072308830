import React, { useState } from 'react'
import { Dialog } from 'primereact/dialog';
import style from './AddDocument.module.css'

import Toaster from '../../../common/Toaster';
import { City, Country, State } from 'country-state-city';
import { uploadImage } from '../../../../Api/SurrogateMotherApis';
import Loader from '../../../common/Loader'
import ImageUpload from '../../../SurrogateComponents/Auction/CreatePostAuction/ImageUpload';
import { addDoctorInClinic } from '../../../../Api/IvfClinic';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import PhoneInputComponent from '../../../commonComponents/PhoneInput/PhoneInput';

const AddDocument = ({ addocument, setAddDocument, reload }) => {

    const selector = useSelector(e => e.header)
    const dispatch = useDispatch()

    const [image, setImage] = useState(null);
    const [fieldValidation, setFieldValidation] = useState(false);
    const [loader, setLoader] = useState(false);

    const [country, setCountry] = useState("");
    const countries = Country.getAllCountries()
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [statesListing, setStatesListing] = useState([]);
    const [citiesListing, setCitiesListing] = useState([]);


    const [toaster, setToaster] = useState({
        show: false,
        message: "",
    });

    const clinic_id = sessionStorage.getItem("userId")

    const [formData, setFormData] = useState({
        "clinic_id": `${clinic_id}`,
        "firstName": "",
        "total_experience": "",
        "date_of_birth": "",
        "email_address": "",
        "mobile_number": "",
        "current_address": "",
        "state_of_residence": "",
        "country": "",
        "age": "",
        "city": "",
        "zip_code": "",
        "image_file": {
            "file_name": "",
            "file_url": ""
        }
    }
    );

    const handleInputChange = (e) => {
        console.log(e)
        const { name, value } = e.target;

        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    function ageCalculate(res) {
        const dob = moment(res);
        const now = moment();
        const age = now.diff(dob, "years");
        setFormData((pre) => {
            return { ...pre, age: age };
        });
    }


    const addDoctorInClinicFuction = async (e) => {
        e.preventDefault();
        setLoader(true);


        if (!image) {
            setFieldValidation(true);
            setLoader(false);
            return;
        }

        try {
            const imageDataupload = await uploadImage(image);


            const image_file = {
                file_name: imageDataupload.data.fileName,
                file_url: imageDataupload.data.fileLocation
            };


            let body = {
                ...formData,
                country: country.split('&&')[0],
                state_of_residence: state.split('&&')[0],
                city: city.split('&&')[0],
                image_file
            };

            await addDoctorInClinic(body)
                .then((res) => {

                    setLoader(false);
                    setAddDocument(false);

                })
            resetForm();
            setAddDocument(false)
        }
        catch (err) {
            console.log(err);

            setToaster({
                show: true,
                message: err.response.data.errormessage,
            });

            setLoader(false);
        }
        finally {
            setLoader(false);

            reload();

        }

    };

    const resetForm = () => {
        setFormData({
            "clinic_id": `${clinic_id}`,
            "firstName": "",
            "total_experience": "",
            "date_of_birth": "",
            "email_address": "",
            "short_descrip": "",
            "mobile_number": "",
            "current_address": "",
            "state_of_residence": "",
            "country": "",
            "age": "",
            "city": "",
            "zip_code": "",
            "image_file": {
                "file_name": "",
                "file_url": ""
            }
        });
        setImage(null);
        setFieldValidation(false);
    };


    function getStates(res) {
        let isoCode = res.split('&&')[1]
        setCountry(res)
        let states = State.getStatesOfCountry(isoCode)
        setStatesListing(states)
        console.log(states)
    }

    function getCities(res) {
        let stIsoCode = res.split('&&')[1]
        let cnCode = res.split('&&')[2]
        setState(res)
        let cities = City.getCitiesOfState(cnCode, stIsoCode)
        setCitiesListing(cities)
    }

    const todayDate = moment().format("YYYY-MM-DD");


    return (
        <div>
            <Loader open={loader} />
            {Toaster && (
                <>
                    <Toaster
                        event={toaster.message}
                        show={toaster.show}
                        severity={"error"}
                        setToaster={setToaster}
                    />
                </>
            )}
            <Dialog
                closable={false}
                visible={addocument}
                className='p-0 m-0'
                onHide={() => setAddDocument(false)}
                style={{ width: "60vw", padding: "0px", margin: "0px" }}
                breakpoints={{ "960px": "75vw", "641px": "100vw" }}
            >
                <div className='Verfied_con'>

                    <div className='d-flex justify-content-center '>
                        <span className={`fW_600 ${style.verfideText}`}>Add New Doctor</span>

                    </div>

                    <form onSubmit={addDoctorInClinicFuction}>

                        <div className="row ">

                            <div className="col-md-6 mt-3">
                                <label className={`form-check-label mb-1 ${style.LabelText}`} htmlFor="flexCheckDefault">First Name<span className='text-danger'>*</span></label>
                                <input
                                    required
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Your Name"
                                    name="firstName"
                                    value={formData.firstName}
                                    onChange={handleInputChange}
                                />
                            </div>

                            <div className="col-md-6 mt-3">
                                <label className={`form-check-label mb-1 ${style.LabelText}`} htmlFor="flexCheckDefault">Total Experience<span className='text-danger'>*</span></label>
                                <input
                                    required
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Total Experience"
                                    name="total_experience"
                                    value={formData.total_experience}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>

                        <div className="row ">

                            <div className="col-md-6 mt-3">
                                <label className={`form-check-label mb-1 ${style.LabelText}`} htmlFor="flexCheckDefault">Age<span className='text-danger'>*</span></label>
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Enter Your Age"
                                    disabled
                                    name="age"
                                    value={formData.age}
                                    onChange={handleInputChange}
                                />
                            </div>

                            <div className="col-md-6 mt-3">
                                <label className={`form-check-label mb-1 ${style.LabelText}`} htmlFor="flexCheckDefault">Date of Birth<span className='text-danger'>*</span></label>
                                <input
                                    required
                                    type="date"
                                    className="form-control"
                                    placeholder="Enter Your DOB"
                                    name="date_of_birth"
                                    value={formData.date_of_birth}
                                    max={todayDate}
                                    onChange={(e) => {
                                        handleInputChange(e);
                                        ageCalculate(e.target.value);
                                    }}
                                />
                            </div>

                        </div>


                        <div className="row ">

                            <div className="col-md-6 mt-3">
                                <label
                                    className={`form-check-label mb-1 ${style.LabelText}`}
                                    htmlFor="clinicname"
                                >
                                    Email Address<span className='text-danger'>*</span>
                                </label>
                                <input
                                    required
                                    type="email"
                                    className="form-control"
                                    placeholder="Enter Email Address"
                                    name="email_address"
                                    value={formData.email_address}
                                    onChange={handleInputChange}
                                />
                            </div>

                            <div className="col-md-6 mt-3">
                                <label
                                    className={`form-check-label mb-1 ${style.LabelText}`}
                                    htmlFor="flexCheckDefault"
                                >
                                    Mobile Number<span className='text-danger'>*</span>
                                </label>

                                {/* <PhoneInput
                                    country={'us'} // default country
                                    value={formData.mobile_number}
                                    onChange={value => handleInputChange({ target: { name: 'mobile_number', value } })}
                                    inputProps={{
                                        name: 'mobile_number',
                                        required: true,
                                        className: 'form-control w-100',
                                    }}
                                    placeholder="Enter Your Contact Number"
                                /> */}


                                <PhoneInputComponent
                                    value={formData.mobile_number}
                                    onChange={handleInputChange}
                                    name="mobile_number"
                                    required={true}
                                    disabled={false}
                                    placeholder="Enter Your Contact Number"
                                />


                            </div>

                        </div>

                        <div className="row ">

                            <div className="col-md-12 mt-3">
                                <label
                                    className={`form-check-label mb-1 ${style.LabelText}`}
                                    htmlFor="flexCheckDefault"
                                >
                                    Current Address<span className='text-danger'>*</span>
                                </label>
                                <input
                                    type="text"
                                    required
                                    className="form-control"
                                    placeholder="Enter Your Current Address"
                                    name="current_address"
                                    value={formData.current_address}
                                    onChange={handleInputChange}
                                />
                            </div>

                            <div className="col-md-12 mt-3">
                                <label
                                    className={`form-check-label mb-1 ${style.LabelText}`}
                                    htmlFor="flexCheckDefault"
                                >
                                    Description<span className='text-danger'>*</span>
                                </label>
                                <textarea
                                    type="text"
                                    rows={1}
                                    required
                                    className="form-control"
                                    placeholder="Enter a Short Description"
                                    name="short_descrip"
                                    value={formData.short_descrip}
                                    onChange={handleInputChange}
                                />
                            </div>



                        </div>

                        <div className="row ">

                            <div className="col-md-6 mt-3">
                                <label
                                    className={`form-check-label mb-1 ${style.LabelText}`}
                                    htmlFor="flexCheckDefault"
                                >
                                    Country<span className='text-danger'>*</span>
                                </label>
                                <select className={`form-select`} required onChange={(e) => {
                                    getStates(e.target.value)
                                    setState('')
                                    setCity('')

                                }}
                                >
                                    <option selected disabled value={''}>{"Select"}</option>
                                    {countries.map((res, index) => {
                                        return <option key={index} value={`${res.name}&&${res.isoCode}`} >{res.name}</option>
                                    })}
                                </select>
                            </div>

                            <div className="col-md-6 mt-3">
                                <label
                                    className={`form-check-label mb-1 ${style.LabelText}`}
                                    htmlFor="flexCheckDefault"
                                >
                                    State of Residence
                                </label>
                                <select className={`form-select`} value={state} onChange={(e) => {
                                    getCities(e.target.value)
                                    setCity('')
                                }}
                                >
                                    <option disabled selected value={''}>{"Select"}</option>
                                    {statesListing?.map((res, index) => {
                                        return <option key={index} value={`${res.name}&&${res.isoCode}&&${res.countryCode}`}>{res.name}</option>
                                    })}

                                </select>
                            </div>

                        </div>

                        <div className="row ">
                            <div className="col-md-6 mt-3">
                                <label
                                    className={`form-check-label mb-1 ${style.LabelText}`}
                                    htmlFor="flexCheckDefault"
                                >
                                    City
                                </label>
                                <select className={`form-select`} value={city} onChange={(e) => setCity(e.target.value)} >
                                    <option selected disabled value={''}>{"Select"}</option>
                                    {citiesListing?.map((res, index) => {
                                        return <option key={index} value={`${res.name}`}>{res.name}</option>
                                    })}

                                </select>
                            </div>

                            <div className="col-md-6 mt-3">
                                <label
                                    className={`form-check-label mb-1 ${style.LabelText}`}
                                    htmlFor="flexCheckDefault"
                                >
                                    Zip code<span className='text-danger'>*</span>
                                </label>
                                <input
                                    type="number"
                                    className="form-control"
                                    required
                                    name="zip_code"
                                    placeholder="Enter Zip Code"
                                    value={formData.zip_code}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>

                        <div className='my-5 d-flex flex-column gap-2'>
                            <div onClick={() => setFieldValidation(false)}>
                                <ImageUpload setImage={setImage} image={image}
                                />
                            </div>
                            {fieldValidation && (
                                <div className='d-flex justify-content-center' role='alert'><span className='w-50 alert alert-danger text-center'>Please select an image to upload</span></div>
                            )}
                        </div>

                        <div className='d-flex justify-content-end gap-3'>
                            <button className={style.CencelButton}
                                onClick={() => { setAddDocument(false); resetForm() }}
                                type='button'>Cancel</button>
                            <button className={style.SubmitButton} type='submit'>Submit</button>
                        </div>

                    </form >

                </div>

            </Dialog >
        </div >
    )
}

export default AddDocument





