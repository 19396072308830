import React, { useState, useEffect } from 'react';
import ScheduleCalender from '../Calender/ScheduleCalender';
import moment from 'moment';

const WeekViewCalender = ({
  reload,
  selectedMonthYear,
  setSelectedMonthYear,
  appointments,
  height,
  currentWeek,
  setCurrentWeek,
}) => {
  const getCurrentWeekNumber = () => {
    const today = moment();
    if (today.month() + 1 === selectedMonthYear.month && today.year() === selectedMonthYear.year) {
      return today.week();
    } else {
      const firstDayOfMonth = moment(new Date(selectedMonthYear.year, selectedMonthYear.month - 1, 1));
      return firstDayOfMonth.week();
    }
  };

  const handleBack = () => {
    setCurrentWeek(currentWeek - 1);
  };

  const handleToday = () => {
    const today = moment();
    setCurrentWeek(today.week());
    setSelectedMonthYear({
      month: today.month() + 1,
      year: today.year(),
    });
  };

  const handleNext = () => {
    setCurrentWeek(currentWeek + 1);
  };

  useEffect(() => {
    setCurrentWeek(getCurrentWeekNumber());
  }, [selectedMonthYear]);

  const currentWeekMoment = moment().week(currentWeek);
  const weekStart = currentWeekMoment.clone().startOf('week').toDate();
  const weekEnd = currentWeekMoment.clone().endOf('week').toDate();

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-2">
        <div>
          <button className="btn" onClick={handleBack}>Back</button>
          <button className="btn" onClick={handleToday}>Today</button>
          <button className="btn" onClick={handleNext}>Next</button>
        </div>
        <div>
          <span>Week {currentWeek}</span>
        </div>
      </div>
      <div style={{ height }}>
        <ScheduleCalender
          reload={reload}
          view="week"
          selectedMonthYear={selectedMonthYear}
          appointments={appointments}
          height={height}
          weekStart={weekStart}
          weekEnd={weekEnd}
        />
      </div>
    </div>
  );
};

export default WeekViewCalender;

