import React, { useEffect, useRef, useState } from "react";
import style from "./EggDonor.module.css";

import {
  getSurrogateMotherData,
  UpdateSurrogateprofile,
} from "../../../../Api/SurrogateMotherApis";
import { uploadDocument } from "../../../../Api/CommonApis";
import Loader from "../../../common/Loader";
import moment from "moment";
import { useDispatch } from "react-redux";
import { Country, State, City } from "country-state-city";
import { useTranslation } from "react-i18next";
import { changeHeaderData } from "../../../../Store/Slices/headerDataSlice";
import { useLocation, useNavigate, useParams } from "react-router";
import { FaArrowLeft } from "react-icons/fa";
import { VscVerifiedFilled } from "react-icons/vsc";
import { TfiImport } from "react-icons/tfi";
import { MdArrowForwardIos, MdOutlineArrowBackIos } from "react-icons/md";
import { saveSurrogate } from "../../../../Api/IntendedParent";
import Toaster from "../../../common/Toaster";
import { getUserDataById } from "../../../../Api/admin";
import jsPDF from 'jspdf';
import * as htmlToImage from 'html-to-image';

export default function ViewDatailedProfileSurro() {
  let { t } = useTranslation();
  const countries = Country.getAllCountries();
  const [country, setCountry] = useState(null);
  const [state, setState] = useState(null);
  const [city, setCity] = useState(null);
  const [statesListing, setStatesListing] = useState([]);
  const [citiesListing, setCitiesListing] = useState([]);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [editable, setEditable] = useState(true);
  const [upload, setUpload] = useState(false);
  let userData = JSON.parse(sessionStorage.getItem("userData"));
  const paramsId = useParams();
  const dispatch = useDispatch();
  let name = useRef();
  const [toaster, setToaster] = useState({
    show: false,
    message: "",
  });
  const [loader, setLoader] = useState(true);
  const [img, setImg] = useState(null);
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    middleName: "",
    height: {
      feet: 0,
      inches: 0,
    },
    weight: {
      LBs: 0,
      oz: 0,
    },
    email: "",
    homePhone: "",
    workPhone: "",
    cellPhone: "",
    imageUrl: "",
    imageFileName: "",
    short_description: "",
    age: 0,
    dob: "",
    current_address: "",
    country: "",
    state: "",
    city: "",
    zipCode: "",
    emailVerify: true,
    photos: [],
  });
  let [page, setPage] = useState(0);
  const [surrogateSave, setSurrogateSaveData] = useState(false)
  const resumeRef1 = useRef(null);
  const resumeRef2 = useRef(null);
  const [allData, setAlldata] = useState(null)
  let styleSheet = {
    maincontainer: {
      width: '100%',
      height: 'auto',
      position: 'relative',
      padding: '25px',
      background: 'linear-gradient(251.07deg, #FFFFFF 35.06%, #E8F9FA 95.96%)'
      // margin:'0 auto'
    },
    maincontainer2: {
      width: '100%',
      height: 'auto',
      position: 'relative',
      padding: '35px',
      background: 'linear-gradient(251.07deg, #FFFFFF 35.06%, #E8F9FA 95.96%)',
      minHeight: '1500px'
      // margin:'0 auto'
    },
    topContainer: {
      width: '100%',
      display: 'flex',
      // border: '1px solid red',
    },
    topLeft: {
      width: '40%',

    },
    topRight: {
      width: '60%',
      padding: '20px',
    },
    topRightInnerTop: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    codeHeading: {
      fontWeight: '600',
      fontSize: '24px',
      color: 'black',
      fontFamily: 'Lufga'
    },
    codeSub: {
      fontWeight: '600',
      fontSize: '14px',
      color: 'rgba(24, 124, 139, 1)',
      fontFamily: 'Lufga'
    },
    heading: {
      marginTop: '30px',
      fontWeight: '600',
      fontSize: '18px',
      color: 'rgba(24, 124, 139, 1)',
      fontFamily: 'Lufga',
      borderBottom: '2px solid rgba(24, 124, 139, 1)',
      width: '220px',
      paddingBottom: '3px'
    },
    heading2: {
      marginTop: '30px',
      fontWeight: '600',
      fontSize: '18px',
      color: 'rgba(24, 124, 139, 1)',
      fontFamily: 'Lufga',
      borderBottom: '2px solid rgba(24, 124, 139, 1)',
      width: '310px',
      paddingBottom: '3px'
    },
    listContent: {
      marginTop: '25px',
      display: 'flex',
      flexDirection: 'column',
      gap: '5px'
    },
    listHeading: {
      fontWeight: '600',
      fontSize: '14px',
      fontFamily: 'Lufga',
    },
    listText: {
      fontWeight: '400',
      fontSize: '14px',
      fontFamily: 'Lufga',
    },
    childContainer: {
      display: 'grid',
      width: '100%',
      gridTemplateColumns: '33.33% 33.33% 33.33%'
    },
    childHeading: {
      fontSize: '16px',
      fontWeight: '600',
      color: 'rgba(24, 124, 139, 1)',
      fontFamily: 'Lufga',
    },
    topBox: {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100px',
      height: "17px",
      background: 'rgba(24, 124, 139, 1)'
    },
    leftBox: {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '17px',
      height: "350px",
      background: 'rgba(24, 124, 139, 1)'
    },
    rightBox: {
      position: 'absolute',
      bottom: '0',
      right: '0',
      width: '20px',
      height: "100px",
      background: 'rgba(24, 124, 139, 1)'
    },
    bottomBox: {
      position: 'absolute',
      bottom: '0',
      right: '0',
      width: '350px',
      height: "20px",
      background: 'rgba(24, 124, 139, 1)'
    },


  }

  // async function convertToImg() {
  //   setLoader(true)
  //   let arr = [resumeRef1.current, resumeRef2.current];
  //   let photoArr = [];
  //   const pdf = new jsPDF();

  //   for (let index = 0; index < arr.length; index++) {
  //     const res = arr[index];
  //     await htmlToImage.toPng(res, { quality: 0.5 })
  //       .then(function (dataUrl) {
  //         photoArr.push(dataUrl);
  //         const imgProps = pdf.getImageProperties(dataUrl);
  //         const pdfWidth = pdf.internal.pageSize.getWidth();
  //         const pdfHeight = ((imgProps.height * pdfWidth) / imgProps.width) - 10;

  //         pdf.addImage(dataUrl, 'PNG', 0, 0, pdfWidth, pdfHeight);
  //         if (index !== arr.length - 1) {
  //           pdf.addPage();
  //         }
  //       })
  //       .catch(function (error) {
  //         console.error('oops, something went wrong!', error);
  //       }).finally(() => {
  //         setLoader(false)
  //       })
  //   }

  //   pdf.save('Resume');
  // }

  async function convertToImg() {
    setLoader(true);
    let arr = [resumeRef1.current, resumeRef2.current];
    let photoArr = [];
    const pdf = new jsPDF();
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    for (let index = 0; index < arr.length; index++) {
      const res = arr[index];
      await htmlToImage.toPng(res, { quality: 0.5 }) // Reduced quality to 0.5
        .then(function (dataUrl) {
          photoArr.push(dataUrl);
          const imgProps = pdf.getImageProperties(dataUrl);
          const imgWidth = pdfWidth;
          const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;

          // Scale image to fit within PDF dimensions
          const scaleFactor = Math.min(pdfWidth / imgProps.width, pdfHeight / imgProps.height);
          const scaledWidth = imgProps.width * scaleFactor;
          const scaledHeight = imgProps.height * scaleFactor;

          pdf.addImage(dataUrl, 'PNG', 0, 0, scaledWidth, scaledHeight, undefined, 'FAST'); // Added compression option
          if (index !== arr.length - 1) {
            pdf.addPage();
          }
        })
        .catch(function (error) {
          console.error('oops, something went wrong!', error);
        })
        .finally(() => {
          if (index === arr.length - 1) {
            setLoader(false);
          }
        });
    }

    pdf.save('Resume.pdf');
  }



  const getDataUserById = async () => {
    await getUserDataById(userId).then((res) => {
      let responseData = res.data.data
      // console.log(responseData)
      let savedData = responseData.saved_surrogate.includes(paramsId.id)
      // console.log(savedData)
      setSurrogateSaveData(savedData)
    }).catch((err) => {
      console.log(err)
    })
  }
  useEffect(() => {
    getDataUserById()
  }, [])

  async function getUserData() {
    try {
      let res = await getSurrogateMotherData(paramsId.id);
      let resultData = res.data.data;
      // console.log(resultData);
      setAlldata(res.data.data);

      dispatch(
        changeHeaderData({
          firstName: resultData?.firstName,
          lastName: resultData?.lastName,
          photos: resultData?.photos,
          activeIndex: resultData?.photo_profile_index,
        })
      );
      name.current = resultData?.firstName + " " + resultData?.lastName;
      setPhotoIndex(
        resultData.photo_profile_index !== undefined
          ? resultData?.photo_profile_index
          : resultData?.photos?.length - 1
      );
      setData((pre) => {
        return { ...pre, ...resultData };
      });

      setState(resultData.state);
      setCity(resultData.city);

      if (res.data.data.country !== undefined) {
        let cRes = countries?.find(
          (res2) => res2?.name == res.data.data.country
        );
        setCountry(`${cRes.name}&&${cRes.isoCode}`);
        getStates(`${cRes.name}&&${cRes.isoCode}`, resultData.city);
      }
      if (res.data.data.city !== undefined) {
        setCity(res.data.data.city);
      }
      setLoader(false);
    } catch (err) {
      console.log(err);
      setLoader(false);
    }
  }
  // console.log(paramsId)

  const userId = sessionStorage.getItem("userId")
  // console.log(userId)


  const saveSurrogateData = async () => {
    setLoader(true)
    const payloadData = { surrrogate_id: paramsId.id }
    // console.log(data._id)
    await saveSurrogate(userId, payloadData).then((res) => {
      // console.log(res.data.data)
      setSurrogateSaveData(!surrogateSave)
      setToaster({
        show: true,
        message: surrogateSave ? "You have Unsaved profile successfully" : "You have saved profile successfully",
      });
      setLoader(false)
    }).catch((err) => {
      console.log(err)
      setLoader(false)
    })
  }
  useEffect(() => {
    if (statesListing.length !== 0) {
      let sRes = statesListing?.find((res2) => res2?.name == data.state);
      if (sRes !== undefined) {
        setState(`${sRes.name}&&${sRes.isoCode}&&${sRes.countryCode}`);
        getCities(`${sRes.name}&&${sRes.isoCode}&&${sRes.countryCode}`);
      }
    }

  }, [statesListing]);

  async function editProfileData(e) {
    e.preventDefault();
    let payload = {
      ...data,
      city: city.split("&&")[0],
      state: state.split("&&")[0],
      country: country.split("&&")[0],
    };
    // return
    let formdata = new FormData();
    if (img !== null) {
      formdata.append("uploadDoc", img);
    }

    setLoader(true);
    try {
      if (img !== null) {
        let imageData = await uploadDocument(formdata);
        payload.imageFileName = imageData.data.fileName;
        payload.imageUrl = imageData.data.fileLocation;
      }
      let res = await UpdateSurrogateprofile(userData.userId, payload);
      // return
      setImg(null);
      await getUserData();
      setEditable(true);
      setLoader(false);
    } catch (err) {
      setLoader(false);
      console.log(err);
    }
  }

  function getStates(res) {
    let isoCode = res.split("&&")[1];
    setCountry(res);
    let states = State.getStatesOfCountry(isoCode);
    setStatesListing(states);
  }

  function getCities(res) {
    let isoCode = res.split("&&")[1];
    let cCode = res.split("&&")[2];
    setState(res);
    let cities = City.getCitiesOfState(cCode, isoCode);
    setCitiesListing(cities);
  }

  function onInputChange(e) {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }

  useEffect(() => {
    getUserData();
  }, []);

  function ageCalculate(res) {
    return moment().diff(moment(res), 'years');
  }
  const navigate = useNavigate();
  // console.log(allData?.photos[photoIndex]?.fileUrl)

  const location = useLocation();
  const statedata = location.state;
  const datastate = statedata ? statedata : null;

  // console.log(datastate)
  // console.log(statedata)

  return (
    <div>
      {allData?.firstName ? <div
        style={{ width: '905px' }}
      >
        <div
          style={{
            height: "0px",
            overflow: "hidden",
          }}
        >
          <div style={styleSheet.maincontainer} ref={resumeRef1} id='print'>
            <div style={styleSheet.topBox} />
            <div style={styleSheet.leftBox} />
            <div style={styleSheet.topContainer}>
              <div style={styleSheet.topLeft}>
                {/* <img crossOrigin='anonymous' 
                // src={
                //   allData?.photos?.length !== 0 &&
                //     allData.photos[photoIndex]?.fileUrl
                //     ? allData.photos[photoIndex]?.fileUrl
                //     : "/images/blankProfile.png"
                // } 
                src={
                  allData?.photos?.length !== 0 &&
                     allData?.photos[photoIndex]?.fileUrl
                     ? allData?.photos[photoIndex]?.fileUrl
                    : "/images/blankProfile.png"
                }
                alt='' style={{ width: '100%', height: '100%' }} /> */}

                <img crossOrigin='anonymous' src={allData?.photos?.length >= 1 ? `${allData?.photos[photoIndex]?.fileUrl}?${moment().format('mm:ss')}` : "/images/blankProfile.png"} alt='' style={{ width: '100%', height: '100%' }} />
              </div>
              <div style={styleSheet.topRight}>
                <div style={styleSheet.topRightInnerTop}>
                  <div>
                    {/* <div style={styleSheet.codeHeading}>{allData?.memberId}</div> */}
                    <div style={styleSheet.codeSub}>Surrogate Mother</div>
                  </div>
                  <div><img src='/images/bigLogo.png' alt='' style={{ width: '140px' }} /></div>
                </div>
                <div style={styleSheet.heading}>
                  General Information
                </div>
                <div style={styleSheet.listContent}>
                  <div><span className='v_listHeading'>SM Id:</span>&nbsp;<span className='v_listText'>{allData?.memberId}</span></div>
                  {statedata?.requestedBY ? <>{allData?.not_show_fullName ? '' : <div><span className='v_listHeading'>Name:</span>&nbsp;<span className='v_listText'>{`${allData?.firstName} ${allData?.middleName} ${allData?.lastName}`}</span></div>}</> : null}
                  <div><span className='v_listHeading'>Age:</span>&nbsp;<span className='v_listText'>{ageCalculate(allData?.dob)}</span></div>
                  <div><span className='v_listHeading'>Location:</span>&nbsp;<span className='v_listText'>{`${allData?.state}, ${allData?.country !== undefined ? allData?.country : ''}`}</span></div>
                  <div><span style={styleSheet.listHeading}>Kids:</span>&nbsp;<span style={styleSheet.listText}>{allData?.no_of_kids}</span></div>
                  <div><span style={styleSheet.listHeading}>Vaginal Deliveries:</span>&nbsp;<span style={styleSheet.listText}>{allData?.no_of_vaginal_deliveries}</span></div>
                  <div><span style={styleSheet.listHeading}>C-Section Deliveries:</span>&nbsp;<span style={styleSheet.listText}>{allData?.no_of_c_section_deliveries}</span></div>
                  <div><span style={styleSheet.listHeading}>Insurance:</span>&nbsp;<span style={styleSheet.listText}>{allData?.medical_insurance ? 'Yes' : 'No'}</span></div>
                  <div><span style={styleSheet.listHeading}>Will you Terminate if needed:</span>&nbsp;<span style={styleSheet.listText}>{allData?.terminate_when_need ? 'Yes' : 'No'}</span></div>
                  <div><span style={styleSheet.listHeading}>Are you Willing to work with HIV positive intended parent:</span>&nbsp;<span style={styleSheet.listText}>{allData?.willing_to_work_with_hiv_positive_ip ? 'Yes' : 'No'}</span></div>
                  <div><span style={styleSheet.listHeading}>Are you Willing to work with HEP B positive intended parent:</span>&nbsp;<span style={styleSheet.listText}>{allData?.willing_to_work_with_hep_b_positive_ip ? 'Yes' : 'No'}</span></div>
                  <div><span style={styleSheet.listHeading}>Are you Employed:</span>&nbsp;<span style={styleSheet.listText}>{allData?.currently_employed ? 'Yes' : 'No'}</span></div>
                  <div><span style={styleSheet.listHeading}>Job Role:</span>&nbsp;<span style={styleSheet.listText}>{allData?.occupation}</span></div>
                </div>
              </div>
            </div>
            {allData?.child_details?.length < 1 ? <>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </> : <>
              <div style={styleSheet.heading}>
                Pregnancy History
              </div>
              <div style={styleSheet.childContainer}>

                {allData?.child_details?.map((res, index) => {
                  return (

                    <div style={styleSheet.listContent} key={index}>
                      <div style={styleSheet.childHeading}>Child #{index + 1} -:</div>
                      <div><span style={styleSheet.listHeading}>Date of Delivery:</span>&nbsp;<span style={styleSheet.listText}>{moment(res?.dob).format('DD/MM/YYYY')}</span></div>
                      <div><span style={styleSheet.listHeading}>Weeks at Delivery:</span>&nbsp;<span style={styleSheet.listText}>{res?.weeks_at_delivery}</span></div>
                      <div><span style={styleSheet.listHeading}>Baby’s weight:</span>&nbsp;<span style={styleSheet.listText}>{`${res?.body_weight.lbs} Lbs ${res?.body_weight.oz} Oz`}</span></div>
                      <div><span style={styleSheet.listHeading}>Delivery Type:</span>&nbsp;<span style={styleSheet.listText}>{res?.delivery_type}</span></div>
                      <div><span style={styleSheet.listHeading}>Surrogacy:</span>&nbsp;<span style={styleSheet.listText}>{res?.surrogacy ? 'Yes' : 'No'}</span></div>
                      <div><span style={styleSheet.listHeading}>Singleton or Multiple:</span>&nbsp;<span style={styleSheet.listText}>{res?.singleton_or_multiple}</span></div>
                    </div>
                  )
                })}
              </div>
            </>}
            {allData?.child_details?.length > 3 ? "" : <>
              <div style={styleSheet.heading2}>
                Compensation and Ideology
              </div>
              <div style={styleSheet.listContent}>
                <div><span style={styleSheet.listHeading}>Desired Compensation:</span>&nbsp;<span style={styleSheet.listText}>${allData?.desired_compensaton}</span></div>
                <div><span style={styleSheet.listHeading}>Embryos willing to transfer:</span>&nbsp;<span style={styleSheet.listText}>{allData?.embryo_willing_to_transfer}</span></div>
                <div><span style={styleSheet.listHeading}>Number of Embryo Transfer Attempts:</span>&nbsp;<span style={styleSheet.listText}>{allData?.no_of_embryo_transfer_attempts}</span></div>
                <div><span style={styleSheet.listHeading}>Why do you want to become a surrogate? What first interested you in surrogacy?</span>&nbsp;<span style={styleSheet.listText}>{allData?.want_to_become_surrogate}</span></div>
                <div><span style={styleSheet.listHeading}>What does being a surrogate mean to you?</span>&nbsp;<span style={styleSheet.listText}>{allData?.what_surrogate_means_to_you}</span></div>
                <div><span style={styleSheet.listHeading}>Do you have any concerns about surrogacy?</span>&nbsp;<span style={styleSheet.listText}>{allData?.any_concern_about_surrogacy}</span></div>
                <div><span style={styleSheet.listHeading}>Are you willing to work with Intended Parent(s) that does not speak English (translation provided)?</span>&nbsp;<span style={styleSheet.listText}>{allData?.willing_to_work_with_non_english_speaker_ip}</span></div>
                <div><span style={styleSheet.listHeading}>Are you willing to reduce caffeine during pregnancy?</span>&nbsp;<span style={styleSheet.listText}>{allData?.willing_to_reduce_caffeine ? 'Yes' : 'No'}</span></div>
                <div><span style={styleSheet.listHeading}>Are you willing to eat organic foods, if requested (and paid for) by the Intended Parent(s)?</span>&nbsp;<span style={styleSheet.listText}>{allData?.willing_to_eat_organic_food ? 'Yes' : 'No'}</span></div>
                <div><span style={styleSheet.listHeading}>What type of family would you like to work with?</span>&nbsp;<span style={styleSheet.listText}>{allData?.type_of_family_want_to_work_with}</span></div>
                <div><span style={styleSheet.listHeading}>Is there anyone you are not comfortable working with?</span>&nbsp;<span style={styleSheet.listText}>{allData?.NOT_want_work_with_intended_parents}</span></div>
              </div>
            </>}
            <br />
            <br />
            <br />
            <br />
          </div>
          <div style={styleSheet.maincontainer2} ref={resumeRef2} >
            <div style={styleSheet.topBox} />
            <div style={styleSheet.leftBox} />
            <div style={styleSheet.topRightInnerTop}>
              <div>&nbsp;</div>
              <div><img src='/images/bigLogo.png' alt='' style={{ width: '140px' }} /></div>
            </div>
            {allData?.child_details?.length > 3 ? <>
              <br />
              <div style={styleSheet.heading2}>
                Compensation and Ideology
              </div>
              <div style={styleSheet.listContent}>
                <div><span style={styleSheet.listHeading}>Desired Compensation:</span>&nbsp;<span style={styleSheet.listText}>${allData?.desired_compensaton}</span></div>
                <div><span style={styleSheet.listHeading}>Embryos willing to transfer:</span>&nbsp;<span style={styleSheet.listText}>{allData?.embryo_willing_to_transfer}</span></div>
                <div><span style={styleSheet.listHeading}>Number of Embryo Transfer Attempts:</span>&nbsp;<span style={styleSheet.listText}>{allData?.no_of_embryo_transfer_attempts}</span></div>
                <div><span style={styleSheet.listHeading}>Why do you want to become a surrogate? What first interested you in surrogacy?</span>&nbsp;<span style={styleSheet.listText}>{allData?.want_to_become_surrogate}</span></div>
                <div><span style={styleSheet.listHeading}>What does being a surrogate mean to you?</span>&nbsp;<span style={styleSheet.listText}>{allData?.what_surrogate_means_to_you}</span></div>
                <div><span style={styleSheet.listHeading}>Do you have any concerns about surrogacy?</span>&nbsp;<span style={styleSheet.listText}>{allData?.any_concern_about_surrogacy}</span></div>
                <div><span style={styleSheet.listHeading}>Are you willing to work with Intended Parent(s) that does not speak English (translation provided)?</span>&nbsp;<span style={styleSheet.listText}>{allData?.willing_to_work_with_non_english_speaker_ip}</span></div>
                <div><span style={styleSheet.listHeading}>Are you willing to reduce caffeine during pregnancy?</span>&nbsp;<span style={styleSheet.listText}>{allData?.willing_to_reduce_caffeine ? 'Yes' : 'No'}</span></div>
                <div><span style={styleSheet.listHeading}>Are you willing to eat organic foods, if requested (and paid for) by the Intended Parent(s)?</span>&nbsp;<span style={styleSheet.listText}>{allData?.willing_to_eat_organic_food ? 'Yes' : 'No'}</span></div>
                <div><span style={styleSheet.listHeading}>What type of family would you like to work with?</span>&nbsp;<span style={styleSheet.listText}>{allData?.type_of_family_want_to_work_with}</span></div>
                <div><span style={styleSheet.listHeading}>Is there anyone you are not comfortable working with?</span>&nbsp;<span style={styleSheet.listText}>{allData?.NOT_want_work_with_intended_parents}</span></div>
              </div>
              <br />
            </> : ''}
            <div style={styleSheet.heading}>
              Interest
            </div>
            <div style={styleSheet.listContent}>
              <div><span style={styleSheet.listHeading}>Describe your ideal Intended Parent(s):</span>&nbsp;<span style={styleSheet.listText}>{allData?.describe_the_ideal_intended_Parent}</span></div>
              <div><span style={styleSheet.listHeading}>What would you like your future Intended Parent(s) to know about you?</span>&nbsp;<span style={styleSheet.listText}>{allData?.note_future_intended_Parent}</span></div>
              <div><span style={styleSheet.listHeading}>Are you willing to have the Intended Parent(s) present during doctors appointments and the delivery?</span>&nbsp;<span style={styleSheet.listText}>{allData?.presence_of_ip_during_appointment_and_delivery ? 'Yes' : 'No'}</span></div>
              <div><span style={styleSheet.listHeading}>There anyone you would like present during the delivery (example: Spouse/Partner, Mother, Sister or Friend)?</span>&nbsp;<span style={styleSheet.listText}>{allData?.anyone_you_want_during_delivery}</span></div>
              <div><span style={styleSheet.listHeading}>How often would you like to be in contact with the Intended Parent(s) during the pregnancy?</span>&nbsp;<span style={styleSheet.listText}>{allData?.contact_with_ip_during_pregnancy}</span></div>
              <div><span style={styleSheet.listHeading}>How often would you like to be in contact with the Intended Parent(s) after the delivery?</span>&nbsp;<span style={styleSheet.listText}>{allData?.fequency_of_contact_with_ip_after_pregnancy}</span></div>
              <div><span style={styleSheet.listHeading}>How would you like to be in contact with the Intended Parent(s)?</span>&nbsp;<span style={styleSheet.listText}>{allData?.mode_of_communication_with_ip}</span></div>
              <div><span style={styleSheet.listHeading}>Are you willing to pump breast milk (compensation available)?</span>&nbsp;<span style={styleSheet.listText}>{allData?.willing_to_pump_breast_milk ? 'Yes' : 'No'}</span></div>
              <div><span style={styleSheet.listHeading}>Are you willing to leave termination choices up to the Intended Parent(s) in the case of a serious birth defect?</span>&nbsp;<span style={styleSheet.listText}>{allData?.terminate_when_need ? 'Yes' : 'No'}</span></div>
              <div><span style={styleSheet.listHeading}>Are you willing to leave termination choices up to the Intended Parent(s) in the case of a non-life threatening condition?</span>&nbsp;<span style={styleSheet.listText}>{allData?.leave_termnation_choice_on_ip_for_threatening_condition ? 'Yes' : 'No'}</span></div>
              <div><span style={styleSheet.listHeading}>If recommended by a doctor are you willing to be placed on bedrest?</span>&nbsp;<span style={styleSheet.listText}>{allData?.willing_to_be_placed_on_bedrest ? 'Yes' : 'No'}</span></div>
              <div><span style={styleSheet.listHeading}>What reassurance can you give your Intended Parent(s) that you will not change your mind?</span>&nbsp;<span style={styleSheet.listText}>{allData?.reassurances_for_ip}</span></div>
              <div><span style={styleSheet.listHeading}>Please describe your support system and who your support system is:</span>&nbsp;<span style={styleSheet.listText}>{allData?.describe_support_system}</span></div>
              <div><span style={styleSheet.listHeading}>Do you have child care available?</span>&nbsp;<span style={styleSheet.listText}>{allData?.child_care_available ? 'Yes' : 'No'}</span></div>
              <div><span style={styleSheet.listHeading}>When are you ready to begin:</span>&nbsp;<span style={styleSheet.listText}>{moment(allData?.ready_to_begin).format('DD/MM/YYYY')}</span></div>
            </div>
          </div>
        </div>
      </div> : ''}
      <Toaster
        event={toaster.message}
        show={toaster.show}
        severity={"success"}
        setToaster={setToaster}
      />
      <div className="mb-4 mt-4 ms-4 w-25">
        <div
          className="d-flex align-items-center gap-2 btn-Secondary p-0 pointer w-50"
          // onClick={() => navigate("/intendedParent/search/ Surrogate Mother")}
          onClick={() => navigate(-1)}
        >
          <FaArrowLeft /> Back
        </div>
      </div>

      <Loader open={loader} />
      <div className={style.MyProfile}>
        <div className={style.HeaderDialog}>
          <div className="d-flex">
            <div className={style.ProfilePic}>
              <img
                src={
                  data?.photos?.length !== 0
                    ? data?.photos[photoIndex]?.fileUrl
                    : "/images/blankProfile.png"
                }
                className="w-100 h-100"
                alt=""
              />
            </div>
            <div className={style.PrifileHeding}>
              <div className="d-flex gap-3 align-items-center">
                <span className={style.Textname}>SM Id: {data?.memberId}</span>
                {data?.verify_resume?.status ? <button
                  className={`d-flex align-items-center  ${style.VerfiedButton1}`}
                >
                  <VscVerifiedFilled className="fs-6 me-2" />
                  Verified
                </button> : null}

              </div>
              <br />
              <div>
                <span className={style.ProfileText}>
                  {data?.short_description}
                </span>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between px-1 pt-2">
            <div className="d-flex gap-3 ">
              <img src="/images/image 140.png" alt="" />
              <span className={style.LoctionText}>
                {" "}
                {data?.city},{data?.state}
              </span>
            </div>
            <div className="d-flex gap-3 ">
              {/* <img src="/images/image 141.png" alt="" />
              <span className={`me-3 ${style.LoctionText}`}>{data?.email}</span> */}
            </div>
          </div>
        </div>
        {statedata?.requestedBY ? <div className="d-flex justify-content-between mt-4 mb-5">
          <div className="d-flex align-items-center "><span className={`${style.Textname1}`}>Requested Date:</span><span className="ms-2">{moment(statedata?.requestedData).format("DD-MM-YYYY")}</span></div>
          <div className="d-flex align-items-center "><span className={`${style.Textname1}`}>Requested By:</span><span className="ms-2">{statedata?.requestedBY}</span></div>

        </div> : null}


        <div className="d-flex justify-content-between mt-3">
          <div className="d-flex justify-content-end gap-3">
            <div
              onClick={() => setPage(0)}
              className={`${style.arrow_icon} ${page === 1 ? style.active_arrow_icon : ""
                }`}
            >
              <MdOutlineArrowBackIos />
            </div>
            <div
              onClick={() => setPage(1)}
              className={`${style.arrow_icon} ${page === 0 ? style.active_arrow_icon : ""
                }`}
            >
              <MdArrowForwardIos />
            </div>
          </div>
          <div>
            {statedata?.requestedBY ? <button className={` ${style.save_profile} me-3`} onClick={() => { navigate(`/superadmin/manageleads/viewResume/${paramsId.id}`) }}>
              View Resume
            </button> : <>

              {surrogateSave === false ? (<> <button className={` ${style.save_profile} me-3`} onClick={() => { saveSurrogateData() }}>
                Save Profile
              </button></>) : (<>
                <button className={` ${style.save_profile} me-3`} onClick={() => { saveSurrogateData() }} >
                  Unsaved Profile
                </button>
              </>)}
            </>}

            <button className={` ${style.view_profile} align-items-center`} onClick={convertToImg}>
              <TfiImport className="me-2 mb-1" /> Export Profile
            </button>
          </div>
        </div>

        {page == 0 ? (
          <div className="mt-4">
            <div className="row">
              {/* {data.not_show_fullName === false ?<> <div className="col-md-3">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("First Name")}
                </label>
                <input
                  type="text"
                  className={`form-control`}
                  placeholder=""
                  disabled={true}
                  value={data?.firstName}
                  required
                  name="firstName"
                />
              </div>
              <div className="col-md-3">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("Middle Initial")}
                </label>
                <input
                  type="text"
                  className={`form-control`}
                  placeholder=""
                  disabled={true}
                  value={data?.middleName}
                  required
                  name="middleName"
                />
              </div>
              <div className="col-md-3">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("Last Name")}
                </label>
                <input
                  type="text"
                  className={`form-control`}
                  placeholder=""
                  disabled={true}
                  value={data?.lastName}
                  required
                  name="lastName"
                />
              </div></>:""} */}


              <div className="col-md-6">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("Date of Birth")}
                </label>
                <input
                  type="date"
                  className={`form-control`}
                  placeholder=""
                  disabled={editable}
                  value={data?.dob && moment(data.dob).format("YYYY-MM-DD")}
                  required
                  name="dob"
                />
              </div>
              {data.not_show_current_address === false ?
                <div className="col-md-6 ">
                  <label
                    htmlFor="exampleInputEmail1"
                    className={`form-label  ${style.LabelHeading}`}
                  >
                    {t("Current Address")}
                  </label>

                  <input
                    type="text"
                    className={`form-control`}
                    placeholder=""
                    disabled={editable}
                    required
                    value={data?.current_address}
                    name="current_address"
                    onChange={onInputChange}
                  />
                </div>
                : ""}
            </div>



            {/* <div className="col">
              {data.not_show_current_address === false ? <div className="row">
                <div className="col-md-12  mt-3">
                  <label
                    htmlFor="exampleInputEmail1"
                    className={`form-label  ${style.LabelHeading}`}
                  >
                    {t("Current Address")}
                  </label>

                  <input
                    type="text"
                    className={`form-control`}
                    placeholder=""
                    disabled={editable}
                    required
                    value={data?.current_address}
                    name="current_address"
                    onChange={onInputChange}
                  />
                </div>
              </div>:""}
              
            </div> */}

            <div className="row">
              <div className="col-md-6 mt-4">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("Height")}
                </label>

                <div className="row">
                  <div className="col-6 d-flex gap-2">
                    <input
                      type="number"
                      className="form-control"
                      required
                      disabled={editable}
                      value={data?.height?.feet || ""}
                    />

                    <div className="d-flex align-items-center">Ft</div>
                  </div>


                  <div className="col-6 d-flex gap-2">
                    <input
                      type="number"
                      className="form-control"
                      disabled={editable}
                      value={data?.height?.inches || ""}
                      required
                    />


                    <div className="d-flex align-items-center">Inches</div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 mt-4">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label ${style.LabelHeading}`}
                >
                  {t("Weight")}
                </label>


                <div className="row">
                  <div className="col-6 d-flex gap-2">
                    <input
                      type="number"
                      disabled={editable}
                      value={data?.weight?.LBs || ""}
                      required
                      className="form-control"
                    />

                    <div className="d-flex align-items-center">Lbs</div>
                  </div>
                  <div className="col-6 d-flex gap-2">
                    <input
                      type="number"
                      disabled={editable}
                      value={data?.weight?.oz || ""}
                      required
                      className="form-control"
                    />
                    <div className="d-flex align-items-center">Oz</div>
                  </div>
                </div>
              </div>
            </div>


            <div className="row">
              <div className="col-md-3  mt-3">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("Country")}
                </label>
                <select
                  className={`form-select`}
                  value={country}
                  required
                  disabled={editable}
                  onChange={(e) => {
                    getStates(e.target.value);
                    setState("");
                    setCity("");
                  }}
                >
                  <option selected disabled value={""}>
                    {t("Select")}
                  </option>
                  {countries.map((res, index) => {
                    return (
                      <option key={index} value={`${res.name}&&${res.isoCode}`}>
                        {res.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-md-3  mt-3">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("State of Residence")}
                </label>
                <select
                  className={`form-select`}
                  value={state}
                  required
                  disabled={editable}
                  onChange={(e) => {
                    getCities(e.target.value);
                    setCity("");
                  }}
                >
                  <option selected disabled value={""}>
                    {t("Select")}
                  </option>
                  {statesListing?.map((res, index) => {
                    return (
                      <option
                        key={index}
                        value={`${res.name}&&${res.isoCode}&&${res.countryCode}`}
                      >
                        {res.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-md-3  mt-3">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("City")}
                </label>
                <select
                  className={`form-select`}
                  value={city}
                  required
                  disabled={editable}
                  onChange={(e) => setCity(e.target.value)}
                >
                  <option selected disabled value={""}>
                    {t("Select")}
                  </option>
                  {citiesListing?.map((res, index) => {
                    return (
                      <option key={index} value={`${res.name}`}>
                        {res.name}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="col-md-3  mt-3">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label ${style.LabelHeading}`}
                >
                  {t("Zip Code")}
                </label>
                <input
                  type="text"
                  className={`form-control`}
                  required
                  placeholder=""
                  disabled={editable}
                  value={data?.zipCode}
                  name="zipCode"
                  onChange={onInputChange}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-3 mt-4">
                <label className={`form-label  ${style.LabelHeading}`}>
                  No of Kids
                </label>
                <input
                  type="number"
                  required
                  className="form-control"
                  name="no_of_kids"
                  value={data.no_of_kids}
                  disabled={true}
                />
              </div>
              <div className="col-md-3 mt-4">
                <label className={`form-label  ${style.LabelHeading}`}>
                  No of Vaginal Deliveries
                </label>
                <input
                  type="number"
                  required
                  className="form-control"
                  name="no_of_vaginal_deliveries"
                  value={data.no_of_vaginal_deliveries}
                  disabled={true}
                />
              </div>
              <div className="col-md-3 mt-4">
                <label className={`form-label  ${style.LabelHeading}`}>
                  No. of C-Section Deliveries

                </label>
                <input
                  type="number"
                  required
                  className="form-control"
                  name="no_of_c_section_deliveries"
                  value={data.no_of_c_section_deliveries}
                  disabled={true}
                />
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-6">
                <label className={`form-label  ${style.LabelHeading}`}>
                  Do you have Insurance?
                </label>
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-check">
                      <input
                        disabled={true}
                        required
                        className="form-check-input"
                        id="medical_insuranceId1"
                        type="radio"
                        checked={data.medical_insurance === true}
                        name="medical_insurance"
                      />
                      <label
                        htmlFor="medical_insuranceId1"
                        className="form-check-label LabeSubHeading "
                      >
                        {t("Yes")}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-check">
                      <input
                        disabled={true}
                        required
                        className="form-check-input"
                        id="medical_insuranceId2"
                        type="radio"
                        checked={data.medical_insurance === false}
                        name="medical_insurance"
                      />
                      <label
                        htmlFor="medical_insuranceId2"
                        className="form-check-label LabeSubHeading "
                      >
                        {t("No")}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <label className={`form-label  ${style.LabelHeading}`}>
                  Will you terminate when needed ?
                </label>
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-check">
                      <input
                        disabled={true}
                        required
                        className="form-check-input"
                        id="terminate_when_need1"
                        type="radio"
                        checked={data.terminate_when_need === true}
                        name="terminate_when_need"
                      />
                      <label
                        htmlFor="terminate_when_need1"
                        className="form-check-label LabeSubHeading "
                      >
                        {t("Yes")}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-check">
                      <input
                        disabled={true}
                        required
                        className="form-check-input"
                        id="terminate_when_need2"
                        type="radio"
                        checked={data.terminate_when_need === false}
                        name="terminate_when_need"
                      />
                      <label
                        htmlFor="terminate_when_need2"
                        className="form-check-label LabeSubHeading "
                      >
                        {t("No")}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-6">
                <label className={`form-label  ${style.LabelHeading}`}>
                  Are you willing to work with HIV positive intended parent?
                </label>
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-check">
                      <input
                        disabled={true}
                        required
                        className="form-check-input"
                        id="willing_to_work_with_hiv_positive_ip1"
                        type="radio"
                        checked={
                          data.willing_to_work_with_hiv_positive_ip === true
                        }
                        name="willing_to_work_with_hiv_positive_ip"
                      />
                      <label
                        htmlFor="willing_to_work_with_hiv_positive_ip1"
                        className="form-check-label LabeSubHeading "
                      >
                        {t("Yes")}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-check">
                      <input
                        disabled={true}
                        required
                        className="form-check-input"
                        id="willing_to_work_with_hiv_positive_ip2"
                        type="radio"
                        checked={
                          data.willing_to_work_with_hiv_positive_ip === false
                        }
                        name="willing_to_work_with_hiv_positive_ip"
                      />
                      <label
                        htmlFor="willing_to_work_with_hiv_positive_ip2"
                        className="form-check-label LabeSubHeading "
                      >
                        {t("No")}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <label className={`form-label  ${style.LabelHeading}`}>
                  Are you willing to work with HEP B positive intended parent?
                </label>
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-check">
                      <input
                        disabled={true}
                        required
                        className="form-check-input"
                        id="willing_to_work_with_hep_b_positive_ip1"
                        type="radio"
                        checked={
                          data.willing_to_work_with_hep_b_positive_ip === true
                        }
                        name="willing_to_work_with_hep_b_positive_ip"
                      />
                      <label
                        htmlFor="willing_to_work_with_hep_b_positive_ip1"
                        className="form-check-label LabeSubHeading "
                      >
                        {t("Yes")}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-check">
                      <input
                        disabled={true}
                        required
                        className="form-check-input"
                        id="willing_to_work_with_hep_b_positive_ip2"
                        type="radio"
                        checked={
                          data.willing_to_work_with_hep_b_positive_ip === false
                        }
                        name="willing_to_work_with_hep_b_positive_ip"
                      />
                      <label
                        htmlFor="willing_to_work_with_hep_b_positive_ip2"
                        className="form-check-label LabeSubHeading "
                      >
                        {t("No")}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-6">
                <label className={`form-label  ${style.LabelHeading}`}>
                  Are you Employed?
                </label>
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-check">
                      <input
                        disabled={true}
                        required
                        className="form-check-input"
                        id="currently_employed1"
                        type="radio"
                        name="currently_employed"
                        checked={data.currently_employed === true}
                      />
                      <label
                        htmlFor="currently_employed1"
                        className="form-check-label LabeSubHeading "
                      >
                        {t("Yes")}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-check">
                      <input
                        disabled={true}
                        required
                        className="form-check-input"
                        id="currently_employed2"
                        type="radio"
                        checked={data.currently_employed === false}
                        name="currently_employed"
                      />
                      <label
                        htmlFor="currently_employed2"
                        className="form-check-label LabeSubHeading "
                      >
                        {t("No")}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 ">
                <label className={`form-label  ${style.LabelHeading}`}>
                  Job Role?
                </label>
                <input
                  disabled={true}
                  type="text"
                  required
                  className="form-control"
                  name="occupation"
                  value={data.occupation}
                />
              </div>
            </div>
            {data?.child_details && data?.child_details?.length !== 0 ? <div className={`${style.second_heading} text-center my-4 mt-4`}>
              Reproductive Information
            </div> : null}

            {data?.child_details?.map((res, key) => {
              return (
                <div key={key} className="mb-3">
                  <div
                    className={`${style.complete_text} d-flex align-items-center`}
                  >
                    Child #{key + 1}
                  </div>

                  <div className="row">
                    <div className="col-md-3">
                      <label className={`form-label  ${style.LabelHeading}`}>
                        Date of Delivery
                      </label>
                      <input
                        disabled={true}
                        type="date"
                        required
                        className="form-control"
                        value={
                          res?.dob && moment(res?.dob).format("YYYY-MM-DD")
                        }
                      />
                    </div>

                    <div className="col-md-3">
                      <label className={`form-label  ${style.LabelHeading}`}>
                        Weeks at Delivery
                      </label>
                      <input
                        disabled={true}
                        type="text"
                        required
                        value={res?.weeks_at_delivery}
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-3">
                      <div className="row  ">
                        <label className={`form-label  ${style.LabelHeading}`}>
                          Baby’s Weight
                        </label>
                        <div className="col-6 d-flex align-items-center">
                          <input
                            disabled={true}
                            required
                            type="number"
                            value={res?.body_weight?.lbs}
                            className="form-control"
                          />
                          <span className="ms-2">Lbs</span>
                        </div>
                        <div className="col-6 d-flex align-items-center">
                          <input
                            disabled={true}
                            required
                            type="number"
                            value={res?.body_weight?.oz}
                            className="form-control"
                          />
                          <span className="ms-2">Oz</span>
                        </div>
                      </div>
                    </div>

                    <div className=" col-md-3">
                      <label className={`form-label  ${style.LabelHeading}`}>
                        Delivery Type
                      </label>
                      <input
                        disabled={true}
                        required
                        className="form-control"
                        value={res?.delivery_type}
                      />
                    </div>
                  </div>
                  <div className="row mt-1">
                    <div className="row col-6 mt-3">
                      <label className={`form-label  ${style.LabelHeading}`}>
                        Surrogacy?
                      </label>
                      <div className="col-md-6">
                        <div className="form-check">
                          <input
                            disabled={true}
                            required
                            className="form-check-input"
                            type="radio"
                            name={`surrogacy${key}`}
                            checked={data.child_details[key].surrogacy == true}
                          />
                          <label className="form-check-label LabeSubHeading ">
                            {t("Yes")}
                          </label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-check">
                          <input
                            disabled={true}
                            required
                            className="form-check-input"
                            checked={
                              data.child_details[key].surrogacy === false
                            }
                            type="radio"
                            name={`surrogacy${key}`}
                          />
                          <label className="form-check-label LabeSubHeading ">
                            {t("No")}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row col-6 mt-3">
                      <label className={`form-label  ${style.LabelHeading}`}>
                        Singleton or Multiple?
                      </label>
                      <div className="col-md-6">
                        <div className="form-check">
                          <input
                            disabled={true}
                            required
                            className="form-check-input"
                            checked={
                              data?.child_details[key]?.singleton_or_multiple ==
                              "Single"
                            }
                            type="radio"
                            name={`singleton_or_multiple${key}`}
                          />
                          <label className="form-check-label LabeSubHeading ">
                            Singleton
                          </label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-check">
                          <input
                            disabled={true}
                            required
                            className="form-check-input"
                            checked={
                              data.child_details[key].singleton_or_multiple ==
                              "Multiple"
                            }
                            type="radio"
                            name={`singleton_or_multiple${key}`}
                          />
                          <label className="form-check-label LabeSubHeading">
                            Multiple
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div>
            <div className={`${style.second_heading} text-center my-4 mt-4`}>
              Surrogracy Information
            </div>
            <div className="row">
              <div className="col-md-4">
                <label className={`form-label  ${style.LabelHeading}`}>
                  Desired Compensation (In $)

                </label>
                <input
                  disabled={true}
                  required
                  type="number"
                  className="form-control"
                  name="desired_compensaton"
                  value={data.desired_compensaton}
                />
              </div>
              <div className="col-md-4">
                <label className={`form-label  ${style.LabelHeading}`}>
                  Embryos willing to transfer

                </label>
                <input
                  disabled={true}
                  required
                  type="number"
                  className="form-control"
                  name="embryo_willing_to_transfer"
                  value={data.embryo_willing_to_transfer}
                />
              </div>
              <div className="col-md-4">
                <label className={`form-label  ${style.LabelHeading}`}>
                  Number of Embryo Transfer Attempts

                </label>
                <input
                  disabled={true}
                  required
                  type="number"
                  className="form-control"
                  name="no_of_embryo_transfer_attempts"
                  value={data.no_of_embryo_transfer_attempts}
                />
              </div>
            </div>
            <div className="col-md-12 mt-4">
              <label className={`form-label  ${style.LabelHeading}`}>
                Why do you want to become a surrogate? What first interested you
                in surrogacy?
              </label>
              <textarea
                disabled={true}
                rows={3}
                required
                type="text"
                className="form-control"
                name="want_to_become_surrogate"
                value={data.want_to_become_surrogate}
              />
            </div>
            <div className="col-md-12 mt-4">
              <label className={`form-label  ${style.LabelHeading}`}>
                What does being a surrogate mean to you?

              </label>
              <textarea
                disabled={true}
                rows={3}
                required
                type="text"
                className="form-control"
                name="what_surrogate_means_to_you"
                value={data.what_surrogate_means_to_you}
              />
            </div>

            <div className="col-md-12 mt-4">
              <label className={`form-label  ${style.LabelHeading}`}>
                Do you have any concerns about surrogacy?

              </label>
              <textarea
                disabled={true}
                rows={3}
                required
                type="text"
                className="form-control"
                name="any_concern_about_surrogacy"
                value={data.any_concern_about_surrogacy}
              />
            </div>
            <div className="col-md-12 mt-4">
              <label className={`form-label  ${style.LabelHeading}`}>
                Are you willing to work with Intended Parent(s) that does not
                speak English (translation provided)?

              </label>
              <textarea
                disabled={true}
                rows={3}
                required
                type="text"
                className="form-control"
                name="willing_to_work_with_non_english_speaker_ip"
                value={data.willing_to_work_with_non_english_speaker_ip}
              />
            </div>

            <div className="row mt-4">
              <div className="col-md-6">
                <label className={`form-label  ${style.LabelHeading}`}>
                  Are you willing to reduce caffeine during pregnancy?
                </label>
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-check">
                      <input
                        disabled={true}
                        required
                        className="form-check-input"
                        type="radio"
                        checked={data.willing_to_reduce_caffeine === true}
                        name="willing_to_reduce_caffeine"
                        value="true"
                      />
                      <label
                        htmlFor="medical_insuranceId1"
                        className="form-check-label LabeSubHeading "
                      >
                        {t("Yes")}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-check">
                      <input
                        required
                        disabled={true}
                        className="form-check-input"
                        type="radio"
                        checked={data.willing_to_reduce_caffeine === false}
                        name="willing_to_reduce_caffeine"
                        value="false"
                      />
                      <label
                        htmlFor="medical_insuranceId2"
                        className="form-check-label LabeSubHeading "
                      >
                        {t("No")}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <label className={`form-label  ${style.LabelHeading}`}>
                  Are you willing to eat organic foods, if requested (and paid
                  for) by the Intended Parent(s)?
                </label>
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-check">
                      <input
                        disabled={true}
                        required
                        className="form-check-input"
                        type="radio"
                        checked={data.willing_to_eat_organic_food === true}
                        name="willing_to_eat_organic_food"
                        value="true"
                      />
                      <label
                        htmlFor="medical_insuranceId1"
                        className="form-check-label LabeSubHeading "
                      >
                        {t("Yes")}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-check">
                      <input
                        disabled={true}
                        required
                        className="form-check-input"
                        type="radio"
                        name="willing_to_eat_organic_food"
                        checked={data.willing_to_eat_organic_food === false}
                        value="false"
                      />
                      <label
                        htmlFor="medical_insuranceId2"
                        className="form-check-label LabeSubHeading "
                      >
                        {t("No")}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-6">
                <label className={`form-label  ${style.LabelHeading}`}> What type of family would you like to work with?</label>
                <textarea disabled={true}
                  rows={2}
                  required
                  type='text'
                  className='form-control'
                  name='type_of_family_want_to_work_with'
                  value={data.type_of_family_want_to_work_with}

                />
              </div>
              <div className="col-md-6">
                <label className={`form-label  ${style.LabelHeading}`}> Is there anyone you are not comfortable working with?</label>
                <textarea
                  rows={2}
                  required disabled={true}
                  type='text'
                  className='form-control'
                  name='NOT_want_work_with_intended_parents'
                  value={data.NOT_want_work_with_intended_parents}

                />
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-6">
                <label className={`form-label  ${style.LabelHeading}`}> Describe your ideal Intended Parent(s)</label>
                <textarea
                  rows={2}
                  required disabled={true}
                  type='text'
                  className='form-control'
                  name='describe_the_ideal_intended_Parent'
                  value={data.describe_the_ideal_intended_Parent}

                />
              </div>
              <div className="col-md-6">
                <label className={`form-label  ${style.LabelHeading}`}> What would you like your future Intended Parent(s) to know about you?</label>
                <textarea
                  rows={2}
                  required disabled={true}
                  type='text'
                  className='form-control'
                  name='note_future_intended_Parent'
                  value={data.note_future_intended_Parent}

                />
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-6">
                <label className={`form-label  ${style.LabelHeading}`}> Are you willing to have the Intended Parent(s) present during doctors appointments and the delivery?</label>
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-check">
                      <input disabled={true}
                        required
                        className="form-check-input"
                        name='presence_of_ip_during_appointment_and_delivery'
                        type="radio"
                        checked={data.presence_of_ip_during_appointment_and_delivery === true}

                        value='true'
                      />
                      <label htmlFor='medical_insuranceId1' className="form-check-label LabeSubHeading " >{t("Yes")}</label>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-check">
                      <input disabled={true}
                        required
                        className="form-check-input"
                        type="radio"
                        name='presence_of_ip_during_appointment_and_delivery'
                        checked={data.presence_of_ip_during_appointment_and_delivery === false}
                        value='false'
                      />
                      <label htmlFor='medical_insuranceId2' className="form-check-label LabeSubHeading " >{t("No")}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <label className={`form-label  ${style.LabelHeading}`}>Is there anyone you would like present during the delivery (example: Spouse/Partner, Mother, Sister or Friend)?</label>
                <textarea disabled={true}
                  rows={2}
                  required
                  type='text'
                  className='form-control'
                  name='anyone_you_want_during_delivery'
                  value={data.anyone_you_want_during_delivery}
                />
              </div>
            </div>
            <div className="row col-12 mt-4">
              <label className={`form-label  ${style.LabelHeading}`}>How often would you like to be in contact with the Intended Parent(s) during the pregnancy?</label>

              <div className="col-md-4">
                <div className="form-check">
                  <input disabled={true}
                    required
                    className="form-check-input"
                    name='contact_with_ip_during_pregnancy'
                    type="radio"
                    value='Couple times a year'
                    checked={data.contact_with_ip_during_pregnancy === 'Couple times a year'}

                  />
                  <label htmlFor='medical_insuranceId1' className="form-check-label LabeSubHeading " >Couple times a year</label>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-check">
                  <input disabled={true}
                    required
                    className="form-check-input"
                    type="radio"
                    name='contact_with_ip_during_pregnancy'
                    value='Once a month'
                    checked={data.contact_with_ip_during_pregnancy === 'Once a month'}

                  />
                  <label htmlFor='medical_insuranceId2' className="form-check-label LabeSubHeading " >Once a month</label>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-check">
                  <input disabled={true}
                    required
                    className="form-check-input"
                    type="radio"
                    name='contact_with_ip_during_pregnancy'
                    value='Not allowed'
                    checked={data.contact_with_ip_during_pregnancy === 'Not allowed'}

                  />
                  <label htmlFor='medical_insuranceId2' className="form-check-label LabeSubHeading " >Not allowed</label>
                </div>
              </div>
            </div>
            <div className="row col-12 mt-4">
              <label className={`form-label  ${style.LabelHeading}`}>How often would you like to be in contact with the Intended Parent(s) after the delivery?</label>

              <div className="col-md-3">
                <div className="form-check">
                  <input disabled={true}
                    required
                    className="form-check-input"
                    type="radio"
                    name='fequency_of_contact_with_ip_after_pregnancy'
                    value='As needed'
                    checked={data.fequency_of_contact_with_ip_after_pregnancy === 'As needed'}

                  />
                  <label htmlFor='medical_insuranceId1' className="form-check-label LabeSubHeading " >As needed</label>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-check">
                  <input disabled={true}
                    required
                    className="form-check-input"
                    type="radio"
                    name='fequency_of_contact_with_ip_after_pregnancy'
                    value='Couple times a year'
                    checked={data.fequency_of_contact_with_ip_after_pregnancy === 'Couple times a year'}

                  />
                  <label htmlFor='medical_insuranceId2' className="form-check-label LabeSubHeading " >Couple times a year</label>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-check">
                  <input disabled={true}
                    required
                    className="form-check-input"
                    type="radio"
                    name='fequency_of_contact_with_ip_after_pregnancy'
                    value='Once a month'
                    checked={data.fequency_of_contact_with_ip_after_pregnancy === 'Once a month'}

                  />
                  <label htmlFor='medical_insuranceId2' className="form-check-label LabeSubHeading " >Once a month</label>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-check">
                  <input disabled={true}
                    required
                    className="form-check-input"
                    type="radio"
                    name='fequency_of_contact_with_ip_after_pregnancy'
                    value='Not willing to'
                    checked={data.fequency_of_contact_with_ip_after_pregnancy === 'Not willing to'}

                  />
                  <label htmlFor='medical_insuranceId2' className="form-check-label LabeSubHeading " >Not willing to</label>
                </div>
              </div>
            </div>

            <div className="row col-12 mt-4">
              <label className={`form-label  ${style.LabelHeading}`}>How would you like to be in contact with the Intended Parent(s)?</label>

              <div className="col-md-4">
                <div className="form-check">
                  <input disabled={true}
                    required
                    className="form-check-input"
                    name='mode_of_communication_with_ip'
                    type="radio"
                    value='Online meeting only'
                    checked={data.mode_of_communication_with_ip === 'Online meeting only'}

                  />
                  <label htmlFor='medical_insuranceId1' className="form-check-label LabeSubHeading " >Online meeting only</label>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-check">
                  <input disabled={true}
                    required
                    className="form-check-input"
                    type="radio"
                    name='mode_of_communication_with_ip'
                    value='Face to face'
                    checked={data.mode_of_communication_with_ip === 'Face to face'}

                  />
                  <label htmlFor='medical_insuranceId2' className="form-check-label LabeSubHeading " >Face to face</label>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-check">
                  <input disabled={true}
                    required
                    className="form-check-input"
                    type="radio"
                    name='mode_of_communication_with_ip'
                    value='Phone call only'
                    checked={data.mode_of_communication_with_ip === 'Phone call only'}

                  />
                  <label htmlFor='medical_insuranceId2' className="form-check-label LabeSubHeading " >Phone call only</label>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-6">
                <label className={`form-label  ${style.LabelHeading}`}>Are you willing to pump breast milk (compensation available)?</label>
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-check">
                      <input disabled={true}
                        required
                        className="form-check-input"
                        name='willing_to_pump_breast_milk'
                        type="radio"
                        checked={data.willing_to_pump_breast_milk === true}
                        value='true'
                      />
                      <label htmlFor='medical_insuranceId1' className="form-check-label LabeSubHeading " >{t("Yes")}</label>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-check">
                      <input disabled={true}
                        required
                        className="form-check-input"
                        type="radio"
                        name='willing_to_pump_breast_milk'
                        checked={data.willing_to_pump_breast_milk === false}
                        value='false'
                      />
                      <label htmlFor='medical_insuranceId2' className="form-check-label LabeSubHeading " >{t("No")}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <label className={`form-label  ${style.LabelHeading}`}>Are you willing to leave termination choices up to the Intended Parent(s) in the case of a serious birth defect?</label>
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-check">
                      <input disabled={true}
                        required
                        className="form-check-input"
                        name='leave_termnation_choice_on_ip_for_birth_defect'
                        type="radio"
                        checked={data.leave_termnation_choice_on_ip_for_birth_defect === true}

                        value='true'
                      />
                      <label htmlFor='medical_insuranceId1' className="form-check-label LabeSubHeading " >{t("Yes")}</label>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-check">
                      <input
                        required disabled={true}
                        className="form-check-input"
                        type="radio"
                        name='leave_termnation_choice_on_ip_for_birth_defect'
                        checked={data.leave_termnation_choice_on_ip_for_birth_defect === false}

                        value='false'
                      />
                      <label htmlFor='medical_insuranceId2' className="form-check-label LabeSubHeading " >{t("No")}</label>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-6">
                <label className={`form-label  ${style.LabelHeading}`}>Are you willing to leave termination choices up to the Intended Parent(s) in the case of a non-life threatening condition?</label>
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-check">
                      <input disabled={true}
                        required
                        className="form-check-input"
                        name='leave_termnation_choice_on_ip_for_threatening_condition'
                        type="radio"
                        checked={data.leave_termnation_choice_on_ip_for_threatening_condition === true}
                        value='true'
                      />
                      <label htmlFor='medical_insuranceId1' className="form-check-label LabeSubHeading " >{t("Yes")}</label>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-check">
                      <input disabled={true}
                        required
                        className="form-check-input"
                        type="radio"
                        name='leave_termnation_choice_on_ip_for_threatening_condition'
                        checked={data.leave_termnation_choice_on_ip_for_threatening_condition === false}

                        value='false'
                      />
                      <label htmlFor='medical_insuranceId2' className="form-check-label LabeSubHeading " >{t("No")}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <label className={`form-label  ${style.LabelHeading}`}>If recommended by a doctor are you willing to be placed on bedrest?</label>
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-check">
                      <input disabled={true}
                        required
                        className="form-check-input"
                        name='willing_to_be_placed_on_bedrest'
                        type="radio"
                        checked={data.willing_to_be_placed_on_bedrest === true}

                        value='true'
                      />
                      <label htmlFor='medical_insuranceId1' className="form-check-label LabeSubHeading " >{t("Yes")}</label>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-check">
                      <input disabled={true}
                        required
                        className="form-check-input"
                        type="radio"
                        name='willing_to_be_placed_on_bedrest'
                        checked={data.willing_to_be_placed_on_bedrest === false}
                        value='false'
                      />
                      <label htmlFor='medical_insuranceId2' className="form-check-label LabeSubHeading " >{t("No")}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 mt-4 md-sm-4">
              <label className={`form-label  ${style.LabelHeading}`} >What reassurance can you give your Intended Parent(s) that you will not change your mind</label>
              <textarea disabled={true}
                rows={1}
                required
                type='text'
                className='form-control'
                name='reassurances_for_ip'
                value={data.reassurances_for_ip}

              />
            </div>
            <div className="col-md-12 mt-4 md-sm-4">
              <label className={`form-label  ${style.LabelHeading}`}>Please describe your support system and who your support system is:</label>
              <textarea disabled={true}
                rows={1}
                required
                type='text'
                className='form-control'
                name='describe_support_system'
                value={data.describe_support_system}

              />
            </div>

            <div className="row mt-4">
              <div className="col-md-6">
                <label className={`form-label  ${style.LabelHeading}`}>Do you have child care available?</label>
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-check">
                      <input disabled={true}
                        required
                        className="form-check-input"
                        name='child_care_available'
                        type="radio"
                        checked={data.child_care_available === true}

                        value='true'
                      />
                      <label htmlFor='medical_insuranceId1' className="form-check-label LabeSubHeading " >{t("Yes")}</label>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-check">
                      <input disabled={true}
                        required
                        className="form-check-input"
                        type="radio"
                        name='child_care_available'
                        checked={data.child_care_available === false}

                        value='false'
                      />
                      <label htmlFor='medical_insuranceId2' className="form-check-label LabeSubHeading " >{t("No")}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <label className={`form-label  ${style.LabelHeading}`}>When are you ready to begin:</label>
                <input disabled={true}
                  required
                  type='date'
                  className='form-control'
                  name='ready_to_begin'
                  value={moment(data.ready_to_begin).format('YYYY-MM-DD')}

                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
