import { Accordion, AccordionTab } from 'primereact/accordion'
import style from "./ViewResume.module.css"
import React, { Fragment, useEffect, useState } from 'react'
import { HEAR_ABOUT_US, SURROGATE_MOTHER_PREFERRED_METHOD, SURROGATE_MOTHER_RACE, CONTSDESCRIBE_SUFFERED_DEPRESSION, DEPRESSION_TREATMENT } from '../../../../constants';
import { getSurrogateMotherData } from '../../../../Api/SurrogateMotherApis';
import { useLocation, useNavigate, useParams } from 'react-router';
import Loader from '../../../../components/common/Loader';
import moment from 'moment';
import { FaArrowLeft } from "react-icons/fa6";
import ActiveDelete from '../../../../components/commonComponents/ActiveDelete/ActiveDelete';

export const ViewResume = () => {
    let userId = useParams().id
    let navigate = useNavigate();
    let [loader, setLoader] = useState(true)

    const location = useLocation();
    const state = location.state;
    console.log(state,"its the value of user is blocked or not")


    /**
     * Below are the states for active block section
     */
    const [editable, setEditable] = useState(true)
    const [defaultImg, setDefaultimg] = useState('/images/blankProfile.png')

    /**
     * states for active block section end
     */

    useEffect(() => {
        getAllResumeData();
    }, []);

    let { id } = useParams();

    let [resumeData, setResumeData] = useState({});

    async function getAllResumeData() {
        try {

            let res = await getSurrogateMotherData(id);
            console.log(res, "THis is res")
            console.log(res.data.access_desktop);
            console.log(resumeData.access_desktop);
            setResumeData(res.data.data);
        }
        catch (err) {
            console.log(err);
        }
        finally {
            setLoader(false)
        }
    };

    return (
        <div>
            <Loader open={loader} />

            <div className='mt-4'>
                <div
                    className="d-flex align-items-center gap-2 btn-Secondary p-0 pointer"
                    onClick={() => navigate(-1)}
                >
                    <FaArrowLeft /> Back
                </div>

            </div>

            <h1 className="lufga_24px fw_600 my-3">Surrogate Resume</h1>


            <div className={` ${style.bannersize} mb-3` }>
                <div className='d-flex  gap-3 ps-2'>
                    {/* <i className="bi bi-chevron-left pointer fs-3" onClick={() => navigate(-1)}></i> */}

                    <div className={`${style.ProfileImg1} mt-3`} >
                    <img 
    src={
        (resumeData && resumeData.photos && resumeData.photos[resumeData.photo_resume_index]?.fileUrl) ||
        (resumeData && resumeData.photos && resumeData.photos[resumeData.photo_profile_index]?.fileUrl) ||
        (resumeData && resumeData.photos && resumeData.photos[0]?.fileUrl) ||
        '/images/blankProfile.png'
    }
    className='w-100 ' 
    alt="" 
/>

                    </div>


                    <div className='d-flex flex-column align-item-center justify-content-center gap-3'>
                        <div className={style.BnnerFirstText}>{resumeData.memberId} : Surrogate Mother</div>
                        <div className={style.BnnersecondtText}>{resumeData.fullName}</div>
                        <div><ActiveDelete id={resumeData?.userId} user_blocked={state?.data} target={"surrogate"} /></div>
                    </div>
                </div>
                <div className={`p-3 p-relative ${style.ProfileImg}`}>
                    <img src="/images/child5 (1).png" className={`w-100`} alt="" />
                    {/* <div className={`d-flex justify-content-end ${style.ChlidButton}`}>
                        <button className={`text-center d-flex align-items-center ${style.VerfiedButton}`}
                        // onClick={() => setEditable(false)}
                        >
                            <i class="bi bi-pencil-square me-1 d-flex align-items-center"></i>
                            <span className={style.verfiedText} >Edit Details</span>
                        </button>
                    </div> */}
                </div>
            </div>




            <Accordion multiple activeIndex={[0]}>

                <AccordionTab header="General Information">
                    <div className='row px-3'>
                        <div className="col-12 align-items-start mt-3 border rounded d-flex flex-wrap gap-2 p-2">
                            {resumeData?.photos?.map((res, key) => {
                                return (
                                    <Fragment key={key}>
                                        <img style={{ width: "100px" }} src={res?.fileUrl} />
                                        &nbsp;&nbsp;
                                    </Fragment>
                                )
                            })}
                        </div>

                        <div className="col-4 mt-3">
                            <label>First Name</label>
                            <input value={resumeData?.firstName} className='form-control' disabled />
                        </div>
                        <div className="col-4 mt-3">
                            <label>Middle Name</label>
                            <input value={resumeData?.middleName} className='form-control' disabled />
                        </div>
                        <div className="col-4 mt-3">
                            <label>Last Name</label>
                            <input value={resumeData?.lastName} className='form-control' disabled />
                        </div>
                        <div className="col-6 mt-3">
                            <label>Maiden Name</label>
                            <input value={resumeData?.maidenName} className='form-control' disabled />
                        </div>
                        <div className="col-6 mt-3">
                            <label>Nick Name</label>
                            <input value={resumeData?.nickName} className='form-control' disabled />
                        </div>
                        <div className="col-12 mt-3">
                            <label>Email Address</label>
                            <input value={resumeData?.email} className='form-control' disabled />
                        </div>

                        <div className="col-4 mt-3">
                            <label>Home Phone</label>
                            <input value={resumeData?.homePhone} className='form-control' disabled />
                        </div>
                        <div className="col-4 mt-3">
                            <label>Work Phone</label>
                            <input value={resumeData?.workPhone} className='form-control' disabled />
                        </div>
                        <div className="col-4 mt-3">
                            <label>Cell Phone</label>
                            <input value={resumeData?.cellPhone} className='form-control' disabled />
                        </div>

                        <div className="col-12 m-0 row p-0 mt-3">
                            <label>Preferred method(s) of contact</label>

                            {SURROGATE_MOTHER_PREFERRED_METHOD.map((res, key) => {
                                return (
                                    <div key={key} className='col-md-4'>
                                        <input checked={resumeData?.method_contect?.includes(res)} type='checkbox' />
                                        <label className='ms-1'>{res}</label>
                                    </div>
                                )
                            })}
                        </div>

                        <div className="col-12 mt-3">{resumeData?.ever_married == undefined ? '' : resumeData?.ever_married == true ? "Yes" : "No"}
                            Are you currently working with an intake coordinator at NHP (NewHopePoints)? : <span className='text-danger'>
                                {/* {resumeData?.current_Working == undefined?'':resumeData?.ever_married==true ? "Yes" : "No"} */}
                                {resumeData?.current_Working == undefined ? '' : resumeData?.current_Working == true ? "Yes" : "No"}
                            </span>
                        </div>
                        <div className="col-12 mt-3">
                            Do you have access to a desktop/laptop computer?* : <span className='text-danger'>{resumeData?.access_desktop == undefined ? '' : resumeData.access_desktop == true ? "Yes" : "No"}</span>
                        </div>

                        <div className="col-12 mt-3">
                            <label>Current Address</label>
                            <input value={resumeData?.current_address} className='form-control' disabled />
                        </div>
                        <div className="col-4 mt-3">
                            <label>City</label>
                            <input value={resumeData?.city} className='form-control' disabled />
                        </div>
                        <div className="col-4 mt-3">
                            <label>State of Residence</label>
                            <input value={resumeData?.state} className='form-control' disabled />
                        </div>

                        <div className="col-4 mt-3">
                            <label>Zip Code</label>
                            <input className='form-control' disabled value={resumeData?.zipCode} />
                        </div>
                        <div className="col-4 mt-3 ">
                            <label>How long have you lived at this address?</label>
                            <input value={resumeData?.lived_duration} className='form-control ' disabled />
                        </div>
                        <div className="col-4 mt-3 d-flex flex-column">
                            <label>Closest major airport</label>
                            <input className='form-control mt-auto' disabled value={resumeData?.closest_airport} />
                        </div>

                        <div className="col-12 mt-3 m-0 row">
                            <label>How did you hear about us?</label>
                            {
                                HEAR_ABOUT_US.map((res, key) => {
                                    return (
                                        <div className="col-md-4 d-flex align-items-center mt-2">
                                            <input checked={resumeData?.hear_about_us == res} type="radio" />
                                            <label className='ms-2'>{res}</label>
                                        </div>

                                    )
                                })
                            }


                        </div>

                        <div className="col-12 mt-3">
                            Have you EVER applied with any other agencies/programs to be a Surrogate or Egg Donor? : <span className='text-danger'>{resumeData?.ever_appled_other_agencies == undefined ? '' : resumeData.ever_appled_other_agencies == true ? "Yes" : "No"}
                            </span>
                            {/* {console.log(resumeData?.ever_appled_other_agencies )} */}
                        </div>


                    </div>
                </AccordionTab>

                <AccordionTab header="Physical/Personal Information">


                    <div className="row">

                        <div className="col-6 mt-3">
                            <label>Age</label>
                            <input value={resumeData?.age} disabled className='form-control' />
                        </div>

                        <div className="col-6 mt-3">
                            <label>Date of Birth</label>
                            <input value={resumeData?.dob ? moment(resumeData?.dob).format('DD-MM-YYYY') : ''} disabled className='form-control' />
                        </div>

                        <div className="col-6 m-0 row p-0 mt-3">
                            <label>Height</label>
                            <div className="col-6 d-flex align-items-center gap-1">
                                <input value={resumeData?.height?.feet} type='text' placeholder='Height' disabled className='form-control' />
                                <span>Ft</span>
                            </div>
                            <div className="col-6 d-flex align-items-center gap-1">
                                <input value={resumeData?.height?.inches} type='text' placeholder='Height' disabled className='form-control' />
                                <span>inches</span>
                            </div>
                        </div>

                        <div className="col-6 m-0 row p-0 mt-3">
                            <label>Weight</label>
                            <div className="col-6 d-flex align-items-center gap-1">
                                <input value={resumeData?.weight?.LBs} type='text' placeholder='Weight' disabled className='form-control' />
                                <span>Lbs</span>
                            </div>
                            <div className="col-6 d-flex align-items-center gap-1">
                                <input type='text' value={resumeData?.weight?.oz} placeholder='Weight' disabled className='form-control' />
                                <span>oz</span>
                            </div>
                        </div>

                        <div className="col-12 mt-3 m-0 p-0 row">
                            <label>Race (check all that apply)</label>

                            {SURROGATE_MOTHER_RACE.map((res, key) => {
                                return (
                                    <div className='col-4 mt-2 d-flex align-items-center'>
                                        <input checked={resumeData?.race?.includes(res)} type="checkbox" className='me-2' />
                                        <label>{res}</label>
                                    </div>
                                )
                            })}
                        </div>

                        <div className="col-12 mt-3">
                            <label>Religious Affiliation</label>
                            <input value={resumeData?.religious_affliation} className='form-control' disabled />
                        </div>

                        <div className="col-6 mt-3">
                            <label>Are you a US Citizen? : <span className='text-danger'>{resumeData?.us_citizen}</span></label>
                        </div>

                        <div className="col-6 mt-3">
                            <label>Have you ever lived in another country? : <span className='text-danger'>
                                {resumeData?.lived_another_country == undefined ? '' : resumeData?.ever_married == true ? "Yes" : "No"}
                                {/* {resumeData?.lived_another_country ? "Yes" : "No"} */}
                            </span></label>
                        </div>
                        <div className="col-6 mt-3">
                            <label>How many years you stay in USA?</label>
                            <input value={resumeData?.many_years_in_us} className='form-control' disabled />

                        </div>


                        <div className="col-6 mt-3">
                            <label>Have you traveled outside the continental United States in the past year? : <span className='text-danger'>{resumeData?.travel_outsite_us == undefined ? '' : resumeData.travel_outsite_us == true ? "Yes" : "No"}</span></label>
                        </div>

                        <div className="col-12 mt-3">
                            <label>What languages do you speak/write?</label>
                            <input value={resumeData?.language_speak?.join(",")} className='form-control' disabled />
                        </div>

                        <div className="col-12 mt-3 m-0 p-0 row">

                            <div className="col-6 mt-3">
                                <label>Current maritial/relationship status : <span className='text-danger'>{resumeData?.relationship_status}</span></label>
                            </div>




                            <div className="col-6 mt-3">
                                <label>Have you EVER been married? : <span className='text-danger'>{resumeData?.ever_married == undefined ? '' : resumeData?.ever_married == true ? "Yes" : "No"}</span></label>
                            </div>

                            <div className="col-6 mt-3">
                                <label>Have you EVER been divorced? : <span className='text-danger'>{resumeData?.ever_divorced == undefined ? '' : resumeData?.ever_divorced == true ? "Yes" : "No"}</span></label>
                                {console.log(resumeData?.ever_divorced)}
                            </div>

                            <div className="col-6 mt-3">
                                <label>Evergency contact name/relation to surrogate</label>
                                <input disabled className='form-control' value={resumeData?.emergency_contect_name} />
                            </div>
                            <div className="col-6 mt-3">
                                <label>Emergency contact phone number</label>
                                <input disabled className='form-control' value={resumeData?.emergency_contect_number} />
                            </div>
                            <div className="col-6 mt-3">
                                <label>Emergency contact alternate phone number</label>
                                <input disabled className='form-control' value={resumeData?.emergency_alter_contect_number} />
                            </div>






                        </div>


                    </div>



                </AccordionTab>

                <AccordionTab header="Compensation / Benefits Information">
                    <div className="row">

                        <div className="col-6 mt-3">
                            <label>Base Compensation (In $)</label>
                            <input value={resumeData?.base_compensation} disabled className='form-control' />
                        </div>
                        <div className="col-6 mt-3">
                            <label>Medicated Mock Cycle or Cancelled Cycle Fee (In $)</label>
                            <input value={resumeData?.mock_or_cancelled_cycle_fee} disabled className='form-control' />
                        </div>

                        <div className="col-6 mt-3">
                            <label>Med Start Fee (In $)</label>
                            <input value={resumeData?.med_start_fee} disabled className='form-control' />
                        </div>
                        <div className="col-6 mt-3">
                            <label>Embryo Transfer Fee (per transfer) (In $)</label>
                            <input value={resumeData?.embryo_transfer_fee} disabled className='form-control' />
                        </div>
                        <div className="col-6 mt-3">
                            <label>Monthly Allowance (to begin at start of medications) (In $)</label>
                            <input value={resumeData?.monthly_allowance} disabled className='form-control' />
                        </div>
                        <div className="col-6 mt-3">
                            <label>Multiple Fee (per additional fetus) (In $)</label>
                            <input value={resumeData?.multiple_fee} disabled className='form-control' />
                        </div>
                        <div className="col-6 mt-3">
                            <label>Invasive Procedure Fee (per invasive prodedure) (In $)</label>
                            <input value={resumeData?.invasive_procedure_fee} disabled className='form-control' />
                        </div>
                        <div className="col-6 mt-3">
                            <label>Childcare (pre hour) (In $)</label>
                            <input value={resumeData?.childCare_perHour} disabled className='form-control' />
                        </div>
                        <div className="col-6 mt-3">
                            <label>Housekeeping (when medically necessary) (cost/week) (In $)</label>
                            <input value={resumeData?.houseKeeping_perWeek} disabled className='form-control' />
                        </div>
                        <div className="col-6 mt-3">
                            <label>C-section Fee (In $)</label>
                            <input value={resumeData?.c_section_fee} disabled className='form-control' />
                        </div>

                        <h5 className='mt-3'>Maternity Clothing Allowance (paid at 14 weeks) (In $)</h5>

                        <div className="col-6">
                            <label>Sigleton</label>
                            <input value={resumeData?.singleton} disabled className='form-control' />
                        </div>


                        <div className="col-6">
                            <label>Multiples</label>
                            <input value={resumeData?.multiples} disabled className='form-control' />
                        </div>

                        <h5 className='mt-3'>Loss of Reproductive Organs Fee (In $)</h5>

                        <div className="col-6">
                            <label>Partial Hysterectomy</label>
                            <input value={resumeData?.partial_hysterectomy} disabled className='form-control' />
                        </div>


                        <div className="col-6">
                            <label>Full Hysterectomy</label>
                            <input value={resumeData?.full_hysterectomy} disabled className='form-control' />
                        </div>


                        <h5 className='mt-3'>Lost wages of Surrogate (In $)</h5>

                        <div className="col-6">
                            <label>Time off requested after vaginal delivery</label>
                            <input value={resumeData?.time_off_requested_after_vaginal_delivery} disabled className='form-control' />
                        </div>


                        <div className="col-6">
                            <label>Time off requested after C-section delivery</label>
                            <input value={resumeData?.time_off_requested_after_cSection_delivery} disabled className='form-control' />
                        </div>
                        <h5 className='mt-3'>Lost wages for Spouse (In $)</h5>

                        <div className="col-12">
                            <label>Time off requested for spouse from preganacy confirmation through post-delivery recovery</label>
                            <input value={resumeData?.time_off_pregnancy_post_delivery_recovery} disabled className='form-control' />
                        </div>





                    </div>


                </AccordionTab>

                <AccordionTab header="Important Matching Details">

                    <div>

                        <b>Would you be willing to carry for (please check ALL that apply)</b>
                        <ul>
                            {resumeData?.willing_to_carry_for?.map((res, key) => {
                                return <li key={key}>{res}</li>
                            })}
                        </ul>

                        <b>Please select your TOP choice for the type of intended parent(s) you would prefer to carry for.</b>

                        <ul>
                            <li>{resumeData?.top_choice_intended_parent}</li>
                        </ul>

                        <b>If your first preference were unavailable, what type of parent(s) would be your SECOND choice?</b>

                        <ul>
                            <li>{resumeData?.second_choice_intended_parent}</li>
                        </ul>


                        <b>If your first and second preferences were not available, what type of parent(s) would be your THIRD choice?</b>

                        <ul>
                            <li>{resumeData?.third_choice_intended_parent}</li>

                        </ul>

                        <b>Please describe the “ideal” Intended Parent(s) with whom you’d like to work</b>
                        <input disabled value={resumeData?.describe_the_ideal_intended_Parent} type='text' className='form-control' />

                        <b>List any circumstances which would cause you to NOT want to work with specific Intended Parents</b>
                        <input disabled type='text' value={resumeData?.NOT_want_work_with_intended_parents} className='form-control' />


                        <b>If you’d like to, please include a note to your future Intended Parent(s)</b>
                        <input disabled type='text' value={resumeData?.note_future_intended_Parent} className='form-control' />

                        <br />

                        <div className='text-center lufga_24px'>Important Matching Details (Continued)</div>

                        <label>Do yout want a : <span className='text-danger'>{resumeData?.do_you_want}</span></label>
                        <br />

                        <label className='mt-2'>In the event of a multiple pregnancy, will you reduce at the request of the Intended Parent(s)? : <span className='text-danger'>{resumeData?.diagoned_condition_terminate == undefined ? '' : resumeData.diagoned_condition_terminate == true ? "Yes" : "No"}</span></label>
                        <br />

                        <label className='mt-2'>If the baby is diagnosed with a condition that is incompatible with life, will you terminate at the request of the Intended Parent(s)? : <span className='text-danger'>{resumeData?.multiple_pergancy_reduce_request == undefined
                            ? '' : resumeData.multiple_pergancy_reduce_request == true ? "Yes" : "No"}</span></label>
                        <br />
                        <label className='mt-2'>If the baby is diagnosed with Down Syndrome, will you terminate at the request of the Intended Parent(s)? : <span className='text-danger'>{resumeData?.diagoned_downSyndrom_terminate == undefined
                            ? '' : resumeData.diagoned_downSyndrom_terminate ? "Yes" : "No"}</span></label>
                        <br />
                        <label className='mt-2'>Would you be willing to have an amniocentesis, if requested by the Intended Parent(s) (and recommended by a physician)? : <span className='text-danger'>{resumeData?.wlling_amniocentesis == undefined
                            ? '' : resumeData?.wlling_amniocentesis == true ? "Yes" : "No"}</span></label>
                        <br />
                        {/* <label className='mt-2'>Do yout want a : <span className='text-danger'>Single Embyo Transfer</span></label> */}
                        <br />

                        <label className='mt-2'>What type of contact do you desire to have with your Intended Parent(s) throughout the pregnancy? (Please be specific as we aim to make the best match for you and your Intended Parents!)</label>
                        <input value={resumeData?.type_contect_desire_with_ip} className='form-control' disabled />


                        <label className='mt-2'>Are you interested in continuing contact post birth? <span className='text-danger'>{resumeData?.interest_continuing_contect_post_birth == undefined
                            ? '' : resumeData.interest_continuing_contect_post_birth == true ? "Yes" : "No"}</span></label>
                        <br />
                        <label className='mt-2'>Are you comfortable with your Intended Parent(s) visit you during pregnancy? : <span className='text-danger'>{resumeData?.comfortable_vist_during_pregnancy == undefined
                            ? '' : resumeData.comfortable_vist_during_pregnancy == true ? "Yes" : "No"}</span></label>
                        <br />
                        <label className='mt-2'>Are you comfortable with your Intended Parent(s) joining you at doctor appointments? : <span className='text-danger'>{resumeData?.comfortable_join_doctor_appointment == undefined
                            ? '' : resumeData.comfortable_join_doctor_appointment == true ? "Yes" : "No"}</span></label>
                        <br />
                        <label className='mt-2'>Are you comfortable with your Intended Parent(s) taking pictures during this journey? : <span className='text-danger'>{resumeData?.comfortable_taking_photos == undefined
                            ? '' : resumeData.comfortable_taking_photos ? "Yes" : "No"}</span></label>
                        <br />
                        <label className='mt-2'>Are you comfortable with your Intended Parent(s) being in the delivery room? : <span className='text-danger'>{resumeData?.comfortable_being_delivery_room == undefined
                            ? '' : resumeData.comfortable_being_delivery_room ? "Yes" : "No"}</span></label>
                        <br />
                        <label className='mt-2'>What type of birth experience do you want? : <span className='text-danger'>{resumeData?.birth_experience}</span></label>
                        <br />

                        <label className='mt-2'>
                            Please expand, if you wish, on anything specific you envision during the birth experience
                        </label>
                        <textarea rows={5} value={resumeData?.specific_envision_birth_experience} disabled className='form-control' />

                        {/* <input value={resumeData?.specific_envision_birth_experience} className='form-control' disabled /> */}


                    </div>


                </AccordionTab>

                <AccordionTab header="Insurance Information">

                    <div>
                        <label className='mt-3'>Do you have medical insurance ? : <span className='text-danger'>{resumeData?.medical_insurance == undefined ? '' : resumeData.medical_insurance == true ? "Yes" : "No"}</span></label>
                        <br />
                        {resumeData?.medical_insurance ? <>
                            <label className='mt-3'>Is your insurance through ? : <span className='text-danger'>{resumeData?.insurance_through}</span></label>
                        </> : ''}
                        <div className="row">
                            {resumeData?.medical_insurance ? <><div className="col-6 mt-3">
                                <label>Name of Health Insurance Company</label>
                                <input value={resumeData?.health_insurance_company} disabled className='form-control' />
                            </div>
                                <div className="col-6 mt-3">
                                    <label>Date expired</label>
                                    <input value={resumeData?.date_expired && new Date(resumeData?.date_expired).toLocaleDateString()} disabled className='form-control' />
                                </div>

                                <div className="col-6 mt-3">
                                    <label>Does your medical insurance include maternity covereage ? : <span className='text-danger'>{resumeData?.maternity_coverage == undefined ? '' : resumeData.maternity_coverage == true ? 'Yes' : 'No'}</span></label>
                                    {/* <input value={resumeData?.maternity_coverage?'Yes':'No'} disabled className='form-control' /> */}
                                </div>

                                <div className="col-6 mt-3">
                                    <label>Yearly deductible (In $)</label>
                                    <input value={resumeData?.yearly_deductible} disabled className='form-control' />
                                </div>
                                <div className="col-6 mt-3">
                                    <label>Coverage percentage (In $)</label>
                                    <input value={resumeData?.coverage_percentage} disabled className='form-control' />
                                </div>
                                <div className="col-6 mt-3">
                                    <label>Co-pay amount per office visit (In $)</label>
                                    <input value={resumeData?.Copay_amount} disabled className='form-control' />
                                </div>
                                <div className="col-6 mt-3">
                                    <label>Monthly premium payment (In $)</label>
                                    <input value={resumeData?.monthly_premium_payment} disabled className='form-control' />
                                </div>
                                <div className="col-6 mt-3">
                                    <label>Yearly maximum out-of-pocket expense (In $)</label>
                                    <input value={resumeData?.yearly_maximum_out_of_pocket_expense} disabled className='form-control' />
                                </div></> : ''}

                            <div className="text-center my-3 lufga_24px">Educational Information</div>

                            {/* <label>Please select your TOP choice for the type of intended parent(s) you would prefer to carry for. : <span className='text-danger'>High School Diploma</span></label> */}

                            <div className="col-12 mt-3">
                                <label>Name and location of high school</label>
                                <input value={resumeData?.high_school_name_location} disabled className='form-control' />
                            </div>

                            <div className="col-6 mt-3">
                                <label>Graduation date</label>
                                <input value={resumeData?.graduation_date && new Date(resumeData?.graduation_date).toLocaleDateString()} disabled className='form-control' />
                            </div>
                            <div className="col-6 mt-3">
                                <label>Did you attend college/university ? : <span className='text-danger'>{resumeData.attend_college == undefined ? '' : resumeData.attend_college == true ? 'Yes' : 'No'}</span></label>
                            </div>

                            <div className="col-12 mt-3">
                                <label>Please list any degrees you are currently pursuing</label>
                                <textarea rows={5} value={resumeData?.pursuing_degree_list} disabled className='form-control' />
                                {/* <input value={resumeData?.pursuing_degree_list} className='form-control' disabled /> */}
                            </div>
                            <div className="col-12 mt-3">
                                <label>Other licenses/certificates/areas of training, etc</label>
                                <input value={resumeData?.other_certificates_training} className='form-control' disabled />
                            </div>

                        </div>

                    </div>

                </AccordionTab>

                <AccordionTab header="Spouse/Partner Information">


                    <div className="row">

                        <div className="col-6 mt-3">
                            <label>Spouse/partner's name</label>
                            <input value={resumeData?.spouse_name} disabled className='form-control' />
                        </div>

                        <div className="col-6 mt-3">
                            <label>Spouse/partner's Birth Date</label>
                            <input
                                value={resumeData?.spouse_dob ? moment(resumeData.spouse_dob).format('DD-MM-YYYY') : ''} disabled className='form-control' />                           {console.log(resumeData?.spouse_dob)}
                        </div>
                        <div className="col-6 mt-3">
                            <label>Spouse/partner's email address</label>
                            <input value={resumeData?.spouse_email} disabled className='form-control' />
                        </div>
                        <div className="col-6 mt-3">
                            <label>Date of your marriage</label>
                            <input value={resumeData?.spouse_marriage_date ? moment(resumeData?.spouse_marriage_date).format('DD-MM-YYYY') : ''} disabled className='form-control' />
                        </div>

                        <div className="col-12 mt-3">
                            <label>Is your spouse/partner employed <span className='text-danger'>{resumeData?.spouse_employed == undefined ? '' : resumeData?.spouse_employed == true ? "Yes" : "No"}</span></label>
                        </div>

                        <div className="col-6 mt-3">
                            <label>Spouse/partner’s current occupation/position</label>
                            <input value={resumeData?.spouse_occupation} disabled className='form-control' />
                        </div>
                        <div className="col-6 mt-3">
                            <label>Spouse/partner's start date with current employer</label>
                            <input value={resumeData?.spouse_startDate_employer ? moment(resumeData?.spouse_startDate_employer).format('DD-MM-YYYY') : ''} disabled className='form-control' />
                        </div>
                        <div className="col-6 mt-3">
                            <label>Average number hours worked per week</label>
                            <input value={resumeData?.spouse_working_hours_per_week} disabled className='form-control' />
                        </div>

                        <div className="col-6 mt-3">
                            <label>Hourly wage or annual salary (in $)</label>
                            <input value={resumeData?.hourly_wages_annual_salary} disabled className='form-control' />
                        </div>

                        <div className="col-12 mt-3">
                            <label>Has spouse/partner ever seen a psychologist or any other mental health professional? : <span className='text-danger'>{resumeData?.spouse_ever_meet_psychologist == undefined ? '' : resumeData.spouse_ever_meet_psychologist == true ? "Yes" : "No"}</span></label>
                        </div>

                        {/* <div className="col-6 mt-3">
                            <label>Hourly wage or annual salary</label>
                            <input disabled className='form-control' />
                        </div> */}

                        <div className="col-12 mt-3">
                            <label>Has spouse/partner ever been prescribed psychiatric medication(s)? : <span className='text-danger'>{resumeData?.explain_psychiatric_medication == undefined ? '' : resumeData.explain_psychiatric_medication == true ? "Yes" : "No"}</span></label>
                        </div>
                        <div className="col-12 mt-3">
                            <label>Please list dates and circumstances</label>
                            <textarea rows={5} disabled className='form-control' />
                        </div>

                        <div className="col-12 mt-3">
                            <label>Has spouse/partner ever been hospitalized due to a psychiatric issue? : <span className='text-danger'>{resumeData?.explain_hospitalized_psychiatric == undefined ? '' : resumeData.explain_hospitalized_psychiatric == true ? "Yes" : "No"}</span></label>
                        </div>
                        <div className="col-6 mt-3">
                            <label>Please list dates and circumstances</label>
                            <textarea rows={5} disabled className='form-control' />
                        </div>

                    </div>


                </AccordionTab>

                <AccordionTab header="Reproductive Information">

                    <div className='row'>

                        <label>Have you ever beed a surrogate? : <span className='text-danger'>{resumeData?.ever_surrogate == undefined ? '' : resumeData.ever_surrogate == true ? "Yes" : "No"}</span></label>
                        <br />

                        <label className='mt-3'>
                            Type : <span className='text-danger'>{resumeData?.surrogate_type?.[0]}</span>
                        </label>

                        <div className='d-flex mt-3 align-items-center'>
                            <label className='text-nowrap me-3'>Number of times : </label>
                            <input value={resumeData?.surrogate_times} className='form-control' disabled />
                        </div>

                        <div className="col-12 mt-3">
                            <span>List all outcomes</span>
                            <input value={resumeData?.surrogate_outcome} disabled className='form-control' />
                        </div>

                        <label className='mt-3'>
                            Have you ever been an Egg Donor? : <span className='text-danger'>{resumeData?.ever_egg_donor == undefined ? '' : resumeData.ever_egg_donor == true ? "Yes" : "No"}</span>
                        </label>

                        <div className='d-flex mt-3 align-items-center'>
                            <label className='text-nowrap me-3'>Number of times : </label>
                            <input value={resumeData?.egg_Donor_times} className='form-control' disabled />
                        </div>

                        <div className="col-12 mt-3">
                            <span>List all outcomes</span>
                            <textarea rows={5} value={resumeData?.egg_Donor_outcome} disabled className='form-control' />
                        </div>

                        <label className='mt-3'>
                            How many times have you been pregnant? : <span className='text-danger'>{resumeData?.many_time_pregnant}</span>
                        </label>


                        {resumeData?.child_details?.map((res, key) => {
                            return (
                                <>
                                    <div className='lufga_24px'>Child {key + 1}</div>
                                    <div className="col-6 mt-3">
                                        <label>Gender <span className='text-danger'>{res?.gender}</span></label>
                                    </div>
                                    <div className="col-6 mt-3">
                                        <label>Date of Birth</label>
                                        <input className='form-control' disabled value={moment(res?.dob).format('DD-MM-YYYY')} />
                                    </div>

                                    <div className="col-6 mt-3">
                                        <label>Months to conceive</label>
                                        <input className='form-control' disabled value={res?.conceive} />
                                    </div>
                                    <div className="col-6 mt-3">
                                        <label>Weeks at Delivery</label>
                                        <input className='form-control' disabled value={res?.weeks_at_delivery} />
                                    </div>
                                    <div className="col-6 mt-3 m-0 p-0 row">

                                        <label>Weeks at Delivery</label>
                                        <div className="col-6 d-flex align-items-center gap-2">
                                            <input value={res?.body_weight?.lbs} disabled className='form-control' />
                                            <span>Lbs</span>
                                        </div>
                                        <div className="col-6 d-flex align-items-center gap-2">
                                            <input value={res?.body_weight?.oz} disabled className='form-control' />
                                            <span>oz</span>
                                        </div>
                                    </div>
                                    <div className="col-12 mt-3">
                                        <label>Name of OB</label>
                                        <input className='form-control' value={res?.ob_name} disabled />
                                    </div>
                                    <div className="col-12 mt-3">
                                        <label>Delivery Hospital and State</label>
                                        <input className='form-control' value={res?.delivery_hospital_state} disabled />
                                    </div>
                                    <div className="col-12 mt-3">
                                        <label>This Baby was : <span className='text-danger'>{res?.this_baby_was}</span></label>
                                    </div>
                                </>

                            )
                        })}






                        <div className='text-center lufga_24px'>Reproductive Information (Continued)</div>

                        <div className="col-12 mt-3">
                            <label>Do you have physical custody of all your children? : <span className='text-danger'>{resumeData?.physical_custody_children == undefined ? '' : resumeData.physical_custody_children == true ? "Yes" : "No"}</span></label>
                        </div>
                        <div className="col-12 mt-3">
                            <label>Do any of your children have physical health problems? : <span className='text-danger'>{resumeData?.child_physical_health_problem == undefined ? '' : resumeData.child_physical_health_problem == true ? "Yes" : "No"}</span></label>
                        </div>

                        <div className="col-12 mt-3">
                            <label>Please Explain</label>
                            <textarea rows={5} value={resumeData?.explain_child_physical_problem} className='form-control' disabled />
                        </div>

                        <div className="col-12 mt-3">
                            <label>Have you ever placed a child for adoption? : <span className='text-danger'>{resumeData?.ever_placed_child_adoption == undefined ? '' : resumeData.ever_placed_child_adoption == true ? "Yes" : "No"}</span></label>
                        </div>
                        <div className="col-12 mt-3">
                            <label>Please list date(s) of adoption and explain</label>
                            <textarea rows={5} value={resumeData?.explain_placed_child_adoption} className='form-control' disabled />
                        </div>


                        <div className="col-12 mt-3">
                            <label>Do you have any deceased children? : <span className='text-danger'>{resumeData?.deceased_child == undefined ? '' : resumeData.deceased_child == true ? "Yes" : "No"}</span></label>
                        </div>
                        <div className="col-12 mt-3">
                            <label>Please list date(s) and explain*</label>
                            <textarea rows={5} value={resumeData?.explain_deceased_child} className='form-control' disabled />
                        </div>


                        <div className="col-12 mt-3">
                            <label>Do you have any adopted or foster children? : <span className='text-danger'>{resumeData?.any_child_adoption == undefined ? '' : resumeData.any_child_adoption == true ? "Yes" : "No"}</span></label>
                        </div>

                        <div className="col-12 mt-3">
                            <label>Please list date(s) and explain</label>
                            <textarea rows={5} value={resumeData?.explain_child_adoption} className='form-control' disabled />
                        </div>

                        <div className="col-6 mt-3">
                            <label>Are you currently breastfeeding? : <span className='text-danger'>{resumeData?.breastfeeding == undefined ? '' : resumeData.breastfeeding == true ? "Yes" : "No"}</span></label>
                        </div>

                        <div className="col-6 mt-3">
                            <label>Estimated completion date</label>
                            <input className='form-control' value={resumeData?.breastfeeding_completion_date ? moment(resumeData?.breastfeeding_completion_date).format('DD-MM-YYYY') : ''} disabled />
                        </div>


                        <div className="col-12 mt-3">
                            <label>Have you ever had any form of fertility treatment (for ANY reason: surrogacy, egg donation, or personal)? : <span className='text-danger'>{resumeData.fertility_treatment == undefined ? '' : resumeData.fertility_treatment == true ? "Yes" : "No"}</span></label>
                        </div>

                        <div className="col-12 mt-3">
                            <label>Please list date(s) and explain</label>
                            <textarea rows={5} value={resumeData?.explain_fertility_treatment} className='form-control' disabled />
                        </div>

                        <div className="col-12 mt-3">
                            <label>Have you ever delivered a premature baby? : <span className='text-danger'>{resumeData?.delivered_premature_baby == undefined ? '' : resumeData.delivered_premature_baby == true ? "Yes" : "No"}</span></label>
                        </div>

                        <div className="col-12 mt-3">
                            <label>List date(s) and weeks gestation at delivery</label>
                            <textarea rows={5} className='form-control' value={resumeData?.explain_delivered_premature_baby} disabled />
                        </div>

                        <div className="col-12 mt-3">
                            <label>Have you ever had a miscarriage? : <span className='text-danger'>{resumeData?.miscarriage == undefined ? '' : resumeData.miscarriage == true ? "Yes" : "No"}</span></label>
                        </div>

                        <div className="col-12 mt-3">
                            <label>List date(s) and weeks gestation at miscarriage</label>
                            <textarea rows={5} className='form-control' value={resumeData?.explain_miscarriage} disabled />
                        </div>
                        <div className="col-12 mt-3">
                            <label>Have you ever had a stillborn baby? : <span className='text-danger'>{resumeData?.ever_stillborn_baby == undefined ? '' : resumeData.ever_stillborn_baby == true ? "Yes" : "No"}</span></label>
                        </div>

                        <div className="col-12 mt-3">
                            <label>List date(s) and weeks gestation at birth</label>
                            <textarea rows={5} className='form-control' value={resumeData?.explain_stillborn_baby} disabled />
                        </div>

                        <div className="col-12 mt-3">
                            <label>Have you ever had an abortion? : <span className='text-danger'>{resumeData?.ever_abortion == undefined ? '' : resumeData.ever_abortion == true ? "Yes" : "No"}</span></label>
                        </div>

                        <div className="col-12 mt-3">
                            <label>List date(s)</label>
                            <textarea rows={5} className='form-control' value={resumeData?.explain_date_abortion} disabled />
                        </div>

                        <div className='mt-3'>
                            <label>Have you ever had any pregnancy and/or delivery complications? Select all applicable:</label>
                            <ul>
                                <li>Placenta previa</li>
                                <li>Norplant</li>
                            </ul>
                        </div>

                        <div className="col-12 mt-3">
                            <label>Have you ever had a cesarean section? : <span className='text-danger'>{resumeData?.ever_cesarean_section == undefined ? '' : resumeData.ever_cesarean_section == true ? "Yes" : "No"}</span></label>
                        </div>

                        <div className="col-12 mt-3">
                            <label>List date(s)</label>
                            <textarea rows={5} className='form-control' value={resumeData?.explain_cesarean_section} disabled />
                        </div>
                        <div className="col-12 mt-3">
                            <label>What was the reason for your C-section(s)?</label>
                            <textarea rows={5} className='form-control' value={resumeData?.reason_cSection} disabled />
                        </div>

                        <div className="col-6 mt-3">
                            <label>Do you have regular menstrual cycles? : <span className='text-danger'>{resumeData?.regular_menstrual_cycles == undefined ? '' : resumeData.regular_menstrual_cycles == true ? "Yes" : "No"}</span></label>
                        </div>

                        <div className="col-6 mt-3">
                            <label>How many days per cycle?</label>
                            <input value={resumeData?.menstrual_cycles_days} className='form-control' disabled />
                        </div>

                        <div className='mt-3'>
                            <label>Current method(s) of birth control</label>
                            <ul>
                                {resumeData?.birth_control_method?.map((res, key) => {
                                    return <li key={key}>{res}</li>
                                })}
                            </ul>
                        </div>

                        <div className="col-6 mt-3">
                            <label>Date you began using method(s) of birth control</label>
                            <input className='form-control' value={resumeData?.birth_control_method_begin ? moment(resumeData?.birth_control_method_begin).format('DD-MM-YYYY') : ''} disabled />
                        </div>



                    </div>

                </AccordionTab>

                <AccordionTab header="Personal Health and Medical Information">
                    <div className='row'>
                        <div className="col-6 mt-3">
                            <label>Date of most recent OB/GYN visit</label>
                            <input value={resumeData?.recent_OB ? moment(resumeData?.recent_OB).format('DD-MM-YYYY') : ''} className='form-control' disabled />
                        </div>
                        <div className="col-6 mt-3">
                            <label>Date of most recent pap smear</label>
                            <input value={resumeData?.recent_pop_smear ? moment(resumeData?.recent_pop_smear).format('DD-MM-YYYY') : ''} className='form-control' disabled />
                        </div>

                        <div className="col-6 mt-3">
                            <label>Results of most revent pap smear</label>
                            <input value={resumeData?.result_pop_smear} className='form-control' disabled />
                        </div>

                        <div className="col-6 mt-3">
                            <label>What is your blood type?</label>
                            <input value={resumeData?.blood_type} className='form-control' disabled />
                        </div>

                        <div className='col-12 mt-3'>
                            <label>Do you have any allergies? : <span className='text-danger'>{resumeData?.have_allergies == undefined ? '' : resumeData.have_allergies == true ? "Yes" : "No"}</span></label>
                            {/* {console.log(resumeData?.have_allergies)} */}
                            {/* {console.log(resumeData?.any_precription_medication)} */}
                        </div>


                        <div className="col-6 mt-3">
                            <label>To what?</label>
                            <input value={resumeData?.allergies_to_what} className='form-control' disabled />
                        </div>

                        <div className='col-12 mt-3'>
                            <label>Do you currently take any prescription or over-the-counter medications? : <span className='text-danger'>{resumeData?.any_precription_medication == undefined ? '' : resumeData.any_precription_medication == true ? "Yes" : "No"}</span></label>
                        </div>


                        <div className="col-12 mt-3">
                            <label>Please list all medications and the reason you take them</label>
                            <textarea rows={5} value={resumeData?.list_all_medications} className='form-control' disabled />
                        </div>

                        <div className='col-6 mt-3'>
                            <label>Have you ever been tested for HIV/AIDS? : <span className='text-danger'>{resumeData?.tested_HIV_AIDS == undefined ? '' : resumeData.tested_HIV_AIDS == true ? "Yes" : "No"}</span></label>
                        </div>
                        <div className='col-6 mt-3'>
                            <label>Do you have Hepatitis B? : <span className='text-danger'>{resumeData?.hepatitis_B == undefined ? '' : resumeData.hepatitis_B == true ? "Yes" : "No"}</span></label>
                        </div>

                        <div className="col-12 mt-3">
                            <label>List date(s) and results</label>
                            <textarea rows={5} value={resumeData?.list_hiv_hepatitis_result} className='form-control' disabled />
                        </div>

                        <div className='col-12 mt-3'>
                            <label>Have you ever suffered from depression? : <span className='text-danger'>{resumeData?.suffered_depression == undefined ? '' : resumeData.suffered_depression == true ? "Yes" : "No"}</span></label>
                        </div>
                        {/* {console.log(resumeData?.suffered_depression)} */}

                        <div className="col-12 m-0 row p-0 mt-3">
                            <label>Please select the option(s) that best describe your depression
                                {/* : <span className='text-danger'>{resumeData?.describe_suffered_depression?.join(", ")} </span> */}
                            </label>
                            {CONTSDESCRIBE_SUFFERED_DEPRESSION.map((res, key) => {
                                return (
                                    <div className='col-4 mt-2 d-flex align-items-center'>
                                        <input checked={resumeData?.describe_suffered_depression?.includes(res)} type="checkbox" className='me-2' />
                                        <label>{res}</label>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="col-12 m-0 row p-0 mt-3">
                            <label>Please select the option(s) that described your treatment
                                {/* : <span className='text-danger'>{resumeData?.depression_treatment?.join(", ")} </span> */}
                            </label>
                            {DEPRESSION_TREATMENT.map((res, key) => {
                                return (
                                    <div className='col-4 mt-2 d-flex align-items-center'>
                                        <input checked={resumeData?.depression_treatment?.includes(res)} type="checkbox" className='me-2' />
                                        <label>{res}</label>
                                    </div>
                                )
                            })}
                        </div>
                        <div className='col-12 mt-3'>
                            <label>Have you ever seen a psychologist or any other mental health professional for any reason? : <span className='text-danger'>{resumeData?.seen_psychologist == undefined ? '' : resumeData.seen_psychologist == true ? "Yes" : "No"}</span></label>
                        </div>


                        <div className="col-12 mt-3">
                            <label>Please list dates and explain</label>
                            <textarea rows={5} value={resumeData?.explain_seen_psychologish} className='form-control' disabled />
                        </div>

                        <div className='col-12 mt-3'>
                            <label>Have you ever been hospitalized due to a psychiatric issue? : <span className='text-danger'>{resumeData?.psychiatric_medication == undefined ? '' : resumeData.psychiatric_medication == true ? "Yes" : "No"}</span></label>
                        </div>

                        <div className="col-12 mt-3">
                            <label>Please list dates and explain*</label>
                            <textarea rows={5} value={resumeData?.explain_psychiatric_medication} className='form-control' disabled />

                        </div>
                        <div className="col-12 mt-3">
                            <label>Please describe your eating habits in detail. In example, specify if you are a vegetarian/vegan, how many meals you eat in a typical day, what types of food you eat on a regular basis, etc.</label>
                            <textarea rows={5} value={resumeData?.eating_habits_detail} className='form-control' disabled />
                        </div>


                        <div className='col-6 mt-3'>
                            <label>Do you exercise? : <span className='text-danger'>{resumeData?.your_exercise == undefined ? '' : resumeData.your_exercise == true ? "Yes" : "No"}</span></label>
                        </div>


                        <div className="col-6 mt-3">
                            <label>Frequency of exercise</label>
                            <input value={resumeData?.frequency_exercise} className='form-control' disabled />
                        </div>

                        <div className='col-12 mt-3'>
                            <label>Have you ever had an eating disorder? : <span className='text-danger'>{resumeData?.eating_disorder == undefined ? '' : resumeData.eating_disorder == true ? "Yes" : "No"}</span></label>
                        </div>

                        <div className="col-12 mt-3">
                            <label>Please list dates and explain</label>
                            <textarea rows={5} value={resumeData?.explain_eating_disorder} className='form-control' disabled />
                        </div>

                        <div className='col-12 mt-3'>
                            <label>Have you ever had surgery (minor or major)? : <span className='text-danger'>{resumeData?.have_any_surgery == undefined ? '' : resumeData.have_any_surgery == true ? "Yes" : "No"}</span></label>
                        </div>
                        <div className="col-12 mt-3">
                            <label>Please list date(s) and reason(s)</label>
                            <textarea rows={5} value={resumeData?.explain_surgery} className='form-control' disabled />
                        </div>

                        <div className='col-12 mt-3'>
                            <label>Outside of childbirth, have you ever been hospitalized or had a major illness? : <span className='text-danger'>{resumeData?.hospitalized_major_illness == undefined ? '' : resumeData.hospitalized_major_illness == true ? "Yes" : "No"}</span></label>
                        </div>
                        <div className="col-12 mt-3">
                            <label>Please list date(s) and reasons(s)</label>
                            <textarea rows={5} value={resumeData?.explain_hospitalized_major_illness} className='form-control' disabled />
                        </div>

                        <div className='text-center lufga_24px'>Personal Health and Medical Information (continued)</div>


                        <div className='col-12 mt-3'>

                            <label>Do you have any chronic medical conditions/concerns? : <span className='text-danger'>{resumeData?.chronic_medical_conditions == undefined ? '' : resumeData.chronic_medical_conditions == true ? "Yes" : "No"}</span></label>
                            {/* {console.log(resumeData.suffered_depression)} */}
                        </div>
                        <div className="col-12 mt-3">
                            <label>Please list date(s) and condition(s)</label>
                            <textarea rows={5} value={resumeData?.explain_chronic_medical_conditions} className='form-control' disabled />
                        </div>

                        <div className='col-6 mt-3'>
                            <label>Do you smoke cigarettes? : <span className='text-danger'>{resumeData?.smoke_cigarettes == undefined ? '' : resumeData.smoke_cigarettes == true ? "Yes" : "No"}</span></label>
                        </div>
                        <div className="col-6 mt-3">
                            <label>If yes, how often?</label>
                            <input value={resumeData?.smoke_cigarettes_Frequency} className='form-control' disabled />
                        </div>

                        <div className='col-12 mt-3'>
                            <label>Have you smoked any form of tobacco within the past year? : <span className='text-danger'>{resumeData?.smoked_tobacco_past_year == undefined ? '' : resumeData.smoked_tobacco_past_year == true ? "Yes" : "No"}</span></label>
                        </div>
                        <div className="col-12 mt-3">
                            <label>Please list date(s)</label>
                            <textarea rows={5} value={resumeData?.date_smoked_tobacco} className='form-control' disabled />
                        </div>

                        <div className='col-12 mt-3'>
                            <label>Does anybody in your home smoke any tobacco products? : <span className='text-danger'>{resumeData?.smoked_tobacco_inHome == undefined ? '' : resumeData.explain_smoked_tobacco_inHome == true ? "Yes" : "No"}</span></label>
                        </div>
                        <div className="col-12 mt-3">
                            <label>Please share who, which type of product, and frequency of use</label>
                            <textarea rows={5} value={resumeData?.explain_smoked_tobacco_inHome} className='form-control' disabled />
                            {/* <input value={resumeData?.explain_smoked_tobacco_inHome} className='form-control' disabled /> */}
                        </div>

                        <div className='col-12 mt-3'>
                            <label>Do you drink alcohol? : <span className='text-danger'>{resumeData?.drink_alcohol == undefined ? '' : resumeData.drink_alcohol == true ? "Yes" : "No"}</span></label>
                        </div>
                        <div className='col-12 mt-3'>
                            <label>How often? : <span className='text-danger'>{resumeData?.drink_alcohol_often}</span></label>
                        </div>
                        <div className='col-12 mt-3'>
                            <label>Have you ever used marijuana (including medicinally)? : <span className='text-danger'>{resumeData?.ever_used_marijuana == undefined ? '' : resumeData.ever_used_marijuana == true ? "Yes" : "No"}</span></label>
                        </div>


                        <div className="col-12 mt-3">
                            <label>Please list date(s) and reason used</label>
                            <textarea rows={5} value={resumeData?.explain_ever_used_marijuana} className='form-control' disabled />
                        </div>

                        <div className='col-12 mt-3'>
                            <label>Have you ever used illegal drugs?: <span className='text-danger'>{resumeData?.ever_used_illegal_drugs == undefined ? '' : resumeData.explain_ever_used_illegal_drugs == true ? "Yes" : "No"}</span></label>
                        </div>

                        <div className="col-12 mt-3">
                            <label>Please list date(s) and type of substance used</label>
                            <textarea rows={5} value={resumeData?.explain_ever_used_illegal_drugs} className='form-control' disabled />
                        </div>


                        <div className='col-12 mt-3'>
                            <label>Have either you or your spouse/partner ever been diagnosed with any of the following STDs? : <span className='text-danger'>{resumeData?.spouse_ever_diagnosed_STDs?.join(", ")}</span></label>
                        </div>

                        <div className="col-12 mt-3">
                            <label>Please explain in detail, including date(s)</label>
                            <textarea rows={5} value={resumeData?.explain_spouse_diagnosed_STDs} className='form-control' disabled />
                        </div>



                        <div className='col-12 mt-3'>
                            <label>Have you ever been diagnosed with any of the following conditions/illnesses : <span className='text-danger'>{resumeData?.diagnosed_any_illnesses?.join(", ")}</span></label>
                        </div>




                        <div className="col-12 mt-3">
                            <label>Please explain in detail, including date(s)</label>
                            <textarea rows={5} value={resumeData?.explain_diagnosed_any_illnesses} className='form-control' disabled />
                        </div>



                    </div>

                </AccordionTab>

                <AccordionTab header="Legal Information">

                    <div className="row">
                        <div className="col-12 mt-3">
                            <label> Do either you or your spouse/partner have any legal claims/cases currently pending? : <span className='text-danger'>{resumeData?.spouse_legal_cases == undefined ? '' : resumeData.spouse_legal_cases == true ? "Yes" : "No"}</span></label>
                        </div>

                        <div className="col-12 mt-3">
                            <label>Please list date (s) and explain</label>
                            <textarea rows={5} value={resumeData?.explain_spouse_legal_cases} className='form-control' disabled />
                        </div>

                        <div><b>Have you or your spouse/partner ever:</b></div>

                        <div className="col-12">
                            <div className="col-12 mt-3">
                                <label>Been arrested or had conflicts with the law (include DUI arrests) : <span className='text-danger'>{resumeData?.spouse_arrest_conflicts == undefined ? '' : resumeData.spouse_arrest_conflicts == true ? "Yes" : "No"}</span></label>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="col-12 mt-3">
                                <label>Been convicted of a felony : <span className='text-danger'>{resumeData?.spouse_convicted_felony == undefined ? '' : resumeData.spouse_convicted_felony == true ? "Yes" : "No"}</span></label>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="col-12 mt-3">
                                <label>Been accused and/or convicted of child abuse or spousal abuse : <span className='text-danger'>{resumeData?.spouse_accused_spousal_abuse == undefined ? '' : resumeData.spouse_accused_spousal_abuse == true ? "Yes" : "No"}</span></label>
                            </div>
                        </div>
                        {/* <div className="col-12">
                            <div className="col-12 mt-3">
                                <label>Lost custody of a child : <span className='text-danger'>{resumeData?.spouse_legal_cases?"Yes":"No"}</span></label>
                            </div>
                        </div> */}
                        <div className="col-12">
                            <div className="col-12 mt-3">
                                <label>Been late with child support payments : <span className='text-danger'>{resumeData?.child_support_payments == undefined ? '' : resumeData.child_support_payments == true ? "Yes" : "No"}</span></label>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="col-12 mt-3">
                                <label>Been turned down by an adoption agency : <span className='text-danger'>{resumeData?.turned_down_adoption_agency == undefined ? '' : resumeData.turned_down_adoption_agency == true ? "Yes" : "No"}</span></label>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="col-12 mt-3">
                                <label>Been in a substance abuse program : <span className='text-danger'>{resumeData?.substance_abuse_program == undefined ? '' : resumeData.substance_abuse_program == true ? "Yes" : "No"}</span></label>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="col-12 mt-3">
                                <label>Declared bankruptcy : <span className='text-danger'>{resumeData?.declared_bankruptcy == undefined ? '' : resumeData.declared_bankruptcy == true ? "Yes" : "No"}</span></label>
                            </div>
                        </div>

                        <div className="col-12 mt-3">
                            <label>Please explain all items you answered "yes" to above</label>
                            <textarea rows={5} className='form-control' value={resumeData?.explain_all_items_answered} disabled />
                        </div>


                    </div>


                </AccordionTab>

                <AccordionTab header="Expectations for Your Gestational Surrogate Journey">

                    <div className="mt-3">


                        <div className="col-12 mt-3">
                            <label>Are your partner and other family members aware of your plan to become a Surrogate? : <span className='text-danger'>{resumeData?.spouse_aware_about_surrogate == undefined ? '' : resumeData.spouse_aware_about_surrogate == true ? "Yes" : "No"}</span></label>
                        </div>

                        <div className="mt-3">
                            <label>Please share their response</label>
                            <textarea rows={5} value={resumeData?.reason_spouse_aware_about_surrogate} disabled className='form-control' />
                        </div>
                        <div className="mt-3">
                            <label>Who will support you emotionally during and after your surrogacy experience?</label>
                            <textarea rows={5} disabled className='form-control' value={resumeData?.support_emotionally_during_after_surrogacy} />
                        </div>

                        <div className="col-12 mt-3">
                            <label>Do you have any concerns about sharing this information with your children? : <span className='text-danger'>{resumeData?.concerns_sharing_information_children == undefined ? '' : resumeData.concerns_sharing_information_children == true ? "Yes" : "No"}</span></label>
                        </div>

                        <div className="mt-3">
                            <label>Please explain</label>
                            <textarea rows={5} disabled value={resumeData?.explain_concerns_sharing_information_children} className='form-control' />
                        </div>

                        <div className="col-12 mt-3">
                            <label>Is there anybody in your life who is NOT supportive of your desire to become a Surrogate? : <span className='text-danger'>{resumeData?.anybody_not_supportive_surrogate == undefined ? '' : resumeData.anybody_not_supportive_surrogate == true ? "Yes" : "No"}</span></label>
                        </div>

                        <div className="mt-3">
                            <label>Please explain</label>
                            <textarea rows={5} value={resumeData?.explain_anybody_not_supportive_surrogate} disabled className='form-control' />
                        </div>
                        <div className="mt-3">
                            <label>Please share your primary reason for your decision to become a Surrogate</label>
                            <textarea rows={5} value={resumeData?.share_primary_reason_surrogate} disabled className='form-control' />
                        </div>

                        <div className="text-center my-3">Please tell us all about you!</div>

                        <div className="mt-3">
                            <label>Describe your personality</label>
                            <textarea rows={5} disabled value={resumeData?.describe_personality} className='form-control' />
                        </div>
                        <div className="mt-3">
                            <label>Tell us about your biggest strengths</label>
                            <textarea rows={5} disabled value={resumeData?.biggest_strengths} className='form-control' />
                        </div>
                        <div className="mt-3">
                            <label>How do you enjoy spending your free time? What are your interests and hobbies?</label>
                            <textarea rows={5} value={resumeData?.spending_free_time_hobby} disabled className='form-control' />
                        </div>
                        <div className="mt-3">
                            <label>Tell us how you envision feeling, on an emotional level, about carrying and delivering a child that will not be your own</label>
                            <textarea rows={5} value={resumeData?.tellUs_envision_feeling_emotional_level} disabled className='form-control' />
                        </div>

                    </div>



                </AccordionTab>

                <AccordionTab header="References">

                    <div className="row m-0">
                        <div className="col-6 mt-3">
                            <label>Reference #1 Name</label>
                            <input value={resumeData?.reference_name1} className='form-control' disabled />
                        </div>
                        <div className="col-6 mt-3">
                            <label>Relationship</label>
                            <input value={resumeData?.relationship1} className='form-control' disabled />
                        </div>
                        <div className="col-6 mt-3">
                            <label>Phone</label>
                            <input value={resumeData?.phone_no1} className='form-control' disabled />
                        </div>
                        <div className="col-6 mt-3"></div>
                        <div className="col-6 mt-3">
                            <label>Reference #2 Name</label>
                            <input value={resumeData?.reference_name2} className='form-control' disabled />
                        </div>
                        <div className="col-6 mt-3">
                            <label>Relationship</label>
                            <input value={resumeData?.relationship2} className='form-control' disabled />
                        </div>
                        <div className="col-6 mt-3">
                            <label>Phone</label>
                            <input value={resumeData?.phone_no2} className='form-control' disabled />
                        </div>

                        <div className="text-center lufga_24px fs-4 my-3">Agreement</div>
                        <div>
                            By entering my name below, I/we agree that the responses on this application are true and accurate. If any false information has been intentionally provided, or if any pertinent information has been deliberately omitted, this agreement may be considered null and void.
                        </div>

                        <div className='my-3'><b>Agree e-sign</b></div>

                        <div className="col-12 d-flex align-items-center">
                            <span className='text-nowrap me-3'>I AGREE</span>
                            <input value={resumeData?.agreement_name} className='form-control' disabled />
                        </div>

                    </div>
                </AccordionTab>

            </Accordion>

            <br />

            <div className="d-flex justify-content-end">
                <button onClick={() => navigate("/superadmin/manageaccounts/surrogate")} className='cancelBtn' >Cancel</button>
            </div>

        </div>
    );
};
