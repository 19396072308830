import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import style from "./AddDocument.module.css";
import { Country, State, City } from "country-state-city";
import { addLawyer } from "../../../../../../../Api/admin";
import Loader from "../../../../../../common/Loader";
import Toaster from "../../../../../../common/Toaster";

const AddDocument = ({ addocument, setAddDocument, fetchClient }) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [country, setCountry] = useState("");
  const countries = Country.getAllCountries()
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [statesListing, setStatesListing] = useState([]);
  const [citiesListing, setCitiesListing] = useState([]);
  const [loader, setLoader] = useState(false);
  const [toaster, setToaster] = useState({
    show: false,
    message: "",
  });

  const [formData, setFormData] = useState({
    fullName: "",
    mobile_No: "",
    mobile_No_alter: "",
    email: "",
    address: "",
    city: "",
    country: "",
    state: "",
    zip_code: "",
    role: "lawyer",
    agree_teams_policy: true,
    firm_name: "",
    email_alter: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value.trim(),
    }));
  };

  const addSuperAdminLawyer = async (e) => {
    e.preventDefault();
    setLoader(true);
    let body = {
      ...formData,
      country: country.split('&&')[0],
      state: state.split('&&')[0],
      city: city.split('&&')[0],
    };
    await addLawyer(body)
      .then((res) => {
        console.log(res);
        setLoader(false);
        setAddDocument(false);
        fetchClient()
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.data?.errormessage === "this email already exists") {
          setToaster({
            show: true,
            message: "This Email Already Exists",

          })
        }
        else {
          setToaster({
            show: true,
            message: "Mandatory Fields are required",
          })
        };
        setLoader(false);
      });
  };

  function getStates(res) {
    let isoCode = res.split('&&')[1]
    setCountry(res)
    let states = State.getStatesOfCountry(isoCode)
    setStatesListing(states)
    console.log(states)
  }

  function getCities(res) {
    let stIsoCode = res.split('&&')[1]
    let cnCode = res.split('&&')[2]
    setState(res)
    let cities = City.getCitiesOfState(cnCode, stIsoCode)
    setCitiesListing(cities)
  }

  // const handleImageUpload = (event) => {
  //     const file = event.target.files[0];
  //     const reader = new FileReader();

  //     reader.onload = () => {
  //         setImageSrc(reader.result);
  //     };

  //     if (file) {
  //         reader.readAsDataURL(file);
  //     }
  // };

  return (
    <div>
      {toaster && (
        <>
          <Toaster
            event={toaster.message}
            show={toaster.show}
            severity={"error"}
            setToaster={setToaster}
          />
        </>
      )}
      <Loader open={loader} />
      <Dialog
        closable={false}
        visible={addocument}
        className="p-0 m-0"
        onHide={() => setAddDocument(false)}
        style={{ width: "60vw", padding: "0px", margin: "0px" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <div className="Verfied_con">
          <div className="d-flex justify-content-center mb-3">
            <span className={`fW_600 ${style.verfideText}`}>
              Create Account : Lawyer
            </span>
          </div>

          <form onSubmit={addSuperAdminLawyer}>
            <div className="row mb-4">
              <div className="col-md-12">
                <label
                  className={`form-check-label mb-1 ${style.LabelText}`}
                  htmlFor="clinicname"
                >
                  Full Name
                  <span className="text-danger">*</span>
                </label>
                <input required
                  type="text"
                  className="form-control"
                  placeholder="Enter name"
                  name="fullName"
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-12">
                <label
                  className={`form-check-label mb-1 ${style.LabelText}`}
                  htmlFor="clinicname"
                >
                  Firm Name
                  <span className="text-danger">*</span>

                </label>
                <input required
                  type="text"
                  name="firm_name"
                  className="form-control"
                  placeholder="Enter firm name"
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-6">
                <label
                  className={`form-check-label mb-1 ${style.LabelText}`}
                  htmlFor="flexCheckDefault"
                >
                  Contact Number
                  <span className="text-danger">*</span>
                </label>
                <input required
                  type="number"
                  name="mobile_No"
                  className="form-control"
                  placeholder="Enter your contact number"
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-6">
                <label
                  className={`form-check-label mb-1 ${style.LabelText}`}
                  htmlFor="flexCheckDefault"
                >
                  Alternative Contact number
                </label>
                <input
                  type="number"
                  name="mobile_No_alter"
                  className="form-control"
                  placeholder="Enter your alternative contact number"
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-6">
                <label
                  className={`form-check-label mb-1 ${style.LabelText}`}
                  htmlFor="clinicname"
                >
                  Email Address
                  <span className="text-danger">*</span>

                </label>
                <input required
                  type="email"
                  name="email"
                  className="form-control"
                  placeholder="Enter email address"
                  onChange={handleInputChange}
                />
              </div>

              <div className="col-md-6">
                <label
                  className={`form-check-label mb-1 ${style.LabelText}`}
                  htmlFor="clinicname"
                >
                  Alternative Email Address
                </label>
                <input
                  type="email"
                  name="email_alter"
                  className="form-control"
                  placeholder="Enter alternative email address"
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-12">
                <label
                  className={`form-check-label mb-1 ${style.LabelText}`}
                  htmlFor="flexCheckDefault"
                >
                  Current Address
                  <span className="text-danger">*</span>

                </label>
                <input required
                  type="text"
                  name="address"
                  className="form-control"
                  placeholder="Enter your address "
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="row mb-4">


              <div className="col-md-6">
                <label
                  className={`form-check-label mb-1 ${style.LabelText}`}
                  htmlFor="flexCheckDefault"
                >
                  Country
                  <span className="text-danger">*</span>

                </label>
                {/* <input required
                type="text"
                className="form-control"
                placeholder=" "
                name="country"
                onChange={handleInputChange}
              /> */}
                <select className={`form-select`} required onChange={(e) => {
                  getStates(e.target.value)
                  setState('')
                  setCity('')

                }}
                >
                  <option selected disabled value={''}>{"select"}</option>
                  {countries.map((res, index) => {
                    return <option key={index} value={`${res.name}&&${res.isoCode}`} >{res.name}</option>
                  })}
                </select>
              </div>

              <div className="col-md-6">
                <label
                  className={`form-check-label mb-1 ${style.LabelText}`}
                  htmlFor="flexCheckDefault"
                >
                  State of Residence
                </label>
                <select className={`form-select`} value={state} onChange={(e) => {
                  getCities(e.target.value)
                  setCity('')
                }}
                >
                  <option disabled selected value={''}>{"select"}</option>
                  {statesListing?.map((res, index) => {
                    return <option key={index} value={`${res.name}&&${res.isoCode}&&${res.countryCode}`}>{res.name}</option>
                  })}

                </select>
              </div>
            </div>

            <div className="row mb-4">

              <div className="col-md-6">
                <label
                  className={`form-check-label mb-1 ${style.LabelText}`}
                  htmlFor="flexCheckDefault"
                >
                  City
                </label>
                <select className={`form-select`} value={city} onChange={(e) => setCity(e.target.value)} >
                  <option selected disabled value={''}>{"select"}</option>
                  {citiesListing?.map((res, index) => {
                    return <option key={index} value={`${res.name}`}>{res.name}</option>
                  })}

                </select>
              </div>
              <div className="col-md-6">
                <label
                  className={`form-check-label mb-1 ${style.LabelText}`}
                  htmlFor="flexCheckDefault"
                >
                  Zip code
                  <span className="text-danger">*</span>
                </label>
                <input required
                  type="text"
                  className="form-control"
                  placeholder="Enter zip Code"
                  name="zip_code"
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="d-flex justify-content-end gap-3">
              <button
                className={style.CencelButton}
                onClick={() => setAddDocument(false)}
                type="button"
              >
                Cancel
              </button>
              <button
                className={style.SubmitButton}
                //onClick={() => setAddDocument(false)}
                type="submit"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </Dialog>
    </div>
  );
};

export default AddDocument;
