
import React, { useEffect, useState } from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import style from './Auction.module.css'
import { InputSwitch } from "primereact/inputswitch";
import { getDefaultAuction, updateDefaultAuction } from '../../../../Api/defaultAuction';
import Loader from '../../../common/Loader';
const Auction = () => {

  const [editduration, setEditduration] = useState(true);
  const [editfees, setEditfees] = useState(false);
  const [data, setData] = useState();
  const [newData, setNewData] = useState()
  const [depoFee, setDepoFee] = useState()
  const [newFee, setNewFee] = useState()
  const [loader, setLoader] = useState(false);


  const fetchDefaultAuction = async()=>{
    setLoader(true)
    const result = await getDefaultAuction()
    //console.log(result.data.data.auction_duration)
    setData(result.data.data.auction_duration)
    setDepoFee(result.data.data.deposit_fee_per)
    setLoader(false)
  }

  const handleEdit = async()=>{
    setLoader(true)
    const result = await updateDefaultAuction({"auction_duration":newData});
    console.log(result)
    fetchDefaultAuction()
  }

  const handlePercentage = async()=>{
    setLoader(true)
    const result = await updateDefaultAuction({"deposit_fee_per":newFee});
    console.log(result)
    fetchDefaultAuction()
  }

  useEffect(function(){
    fetchDefaultAuction()
  },[])

  return (
    <div>
      <Loader open={loader} />
      <div className="mt-3">
        <Accordion multiple activeIndex={[0]} className={style.AccordionTabheader}>
          <AccordionTab header="Auction Duration Management" className={style.AccordionBAck}>

            <div className={style.PasswordBack}>
             
              
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label className={`form-check-label mb-2 ${style.LabelText}`} htmlFor="flexCheckDefault">Default Auction Duration (Days)<span className='text-danger'>*</span></label>
                    </div>
                    <div className="col-md-6">
                      <input 
                          type="number" 
                          disabled={editduration} 
                          className={`form-control ${style.InputCustom}`} 
                          //aria-label="Default select example" 
                          value={ editduration ? data : newData}
                          onChange={(e)=>setNewData(e.target.value)}
                      />

                        {/* <option value={3}>3 days</option>
                        <option value={4}>4 days</option>
                        <option value={5}>5 days</option>
                        <option value={6}>6 days</option>

                      </select> */}
                    </div>
                  </div>
       {editduration ?    
                <>  
                  <div className='d-flex justify-content-end gap-3 mb-3'>
                    <button className={style.SaveButton} onClick={() =>{
                        setEditduration(false)
                      }
                    }>
                      Edit Duration</button>
                  </div>

                </>

                :

                <>
                  {/* <div className="row mb-3">
                    <div className="col-md-6">
                      <label className={`form-check-label mb-2 ${style.LabelText}`} htmlFor="flexCheckDefault">Default Auction Duration<span className='text-danger'>*</span></label>
                    </div>

                    <div className="col-md-6">
                      <select className={`form-select ${style.InputCustom}`} aria-label="Default select example">

                        <option selected>3 days</option>
                        <option value={1}>4 days</option>
                        <option value={2}>5 days</option>
                        <option value={3}>6 days</option>

                      </select>

                    </div>
                  </div> */}

                  <div className='d-flex justify-content-end gap-3 mb-3'>
                    <button className={style.cancelButton} onClick={() => setEditduration(true)}>Cancel</button>

                    <button className={style.SaveButton} onClick={() => {
                        setEditduration(true)
                        handleEdit()
                      }
                    }

                    >
                      Save Changes</button>
                  </div>

                </>
              }
            </div>

          </AccordionTab>


//////////////////////////////////////////////////////////////////////////////

          <AccordionTab header="Auction Deposit Fees Percentage" className={style.AccordionBAck}>

            <div className={style.PasswordBack}>
            
                
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label className={`form-check-label mb-2 ${style.LabelText}`} htmlFor="flexCheckDefault">Auction Deposit Fees Percentage<span className='text-danger'>*</span></label>
                    </div>
                    <div className="col-md-6">
                      <input disabled={!editfees} type="text" 
                        className="form-control" 
                        value={ !editfees ? depoFee : newFee}
                        onChange={(e)=>setNewFee(e.target.value)}
                      />
                    </div>
                  </div>
              {!editfees ?   
                <> 
                  <div className='d-flex justify-content-end gap-3 mb-3'>
                    <button className={style.SaveButton} onClick={() => setEditfees(!editfees)}>Edit Percentage</button>
                  </div>

                </>

                :

                <>
                  {/* <div className="row mb-3">
                    <div className="col-md-6">
                      <label className={`form-check-label mb-2 ${style.LabelText}`} htmlFor="flexCheckDefault">Auction Deposit Fees Percentage<span className='text-danger'>*</span></label>
                    </div>

                    <div className="col-md-6">
                      <input type="number" className="form-control" placeholder=" " />
                    </div>
                  </div> */}

                  <div className='d-flex justify-content-end gap-3 mb-3'>
                    <button className={style.cancelButton} onClick={() => setEditfees(!editfees)}>Cancel</button>
                    <button className={style.SaveButton} 
                      onClick={
                        () => {
                               setEditfees(!editfees)
                               handlePercentage()
                              }
                      }
                    >
                      Save Changes
                    </button>
                  </div>

                </>
              }
            </div>
          </AccordionTab>


        </Accordion>
      </div>
    </div>
  )
}

export default Auction