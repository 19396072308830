import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { SidebarWrapper } from "../../routes/SidebarWrapper";

export const SurrogateMother = () => {
    const { search } = useLocation()

    function jwtoken() {
        
        // if (search !== '') {
        //     let url = search.split('?A=')[1]
        //     let token = url.split('&&id=')[0]
        //     let userId = url.split('&&id=')[1]

        //     sessionStorage.setItem('authToken',token)
        //     sessionStorage.setItem('userId',userId)

        //     return true
        // } else {
            if(sessionStorage.getItem('userData') ){
                return true
            }else{
                return false
            }
        // }
    }

    return <>{jwtoken() ? <SidebarWrapper role="surrogate"><Outlet></Outlet></SidebarWrapper> : <div className="BackPage">Login to Access</div>}</>;
};
