import React, { useEffect, useRef, useState } from "react";
import ClientTable from "../MyClientTable/ClientTable";
import style from "./MyClient.module.css";
import { InputText } from "primereact/inputtext";
import AddDocument from "../MyClientTable/AddDocumentDialog/AddDocument";
import { RiDownload2Line } from "react-icons/ri";
import {
  getAllSurrogateDonors,
  getSurrogateMotherData,
} from "../../../../../../Api/SurrogateMotherApis";
import { TableContainer } from "../../../../../common/TableContainer";
import { useNavigate } from "react-router";
import Loader from "../../../../../common/Loader";
import { RiDeleteBinLine } from "react-icons/ri";
import { deleteUser, deleteuserbyId } from "../../../../../../Api/admin";
import jsPDF from "jspdf";
import * as htmlToImage from "html-to-image";
import moment from "moment";
import { Dialog } from "primereact/dialog";
import { ConfirmDelete } from "../../../../../common/ConfirmDelete";


const Myclient = () => {
  const [addocument, setAddDocument] = useState(false);
  const resumeRef1 = useRef(null);
  const resumeRef2 = useRef(null);
  const [allSurrogatesData, setAllSurrogateData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [allData, setAllData] = useState(null)
  const [testModal, setTestModal] = useState(false)

  /**
   * filter data state is needed to filter data from it , basically its a copy of alldata
   */
  const [filterData, setFilterData] = useState([])


  let styleSheet = {
    maincontainer: {
      width: '100%',
      height: 'auto',
      position: 'relative',
      padding: '25px',
      background: 'linear-gradient(251.07deg, #FFFFFF 35.06%, #E8F9FA 95.96%)'
      // margin:'0 auto'
    },
    maincontainer2: {
      width: '100%',
      height: 'auto',
      position: 'relative',
      padding: '35px',
      background: 'linear-gradient(251.07deg, #FFFFFF 35.06%, #E8F9FA 95.96%)',
      minHeight: '1500px'
      // margin:'0 auto'
    },
    topContainer: {
      width: '100%',
      display: 'flex',
      // border: '1px solid red',
    },
    topLeft: {
      width: '40%',

    },
    topRight: {
      width: '60%',
      padding: '20px',
    },
    topRightInnerTop: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    codeHeading: {
      fontWeight: '600',
      fontSize: '24px',
      color: 'black',
      fontFamily: 'Lufga'
    },
    codeSub: {
      fontWeight: '600',
      fontSize: '14px',
      color: 'rgba(24, 124, 139, 1)',
      fontFamily: 'Lufga'
    },
    heading: {
      marginTop: '30px',
      fontWeight: '600',
      fontSize: '18px',
      color: 'rgba(24, 124, 139, 1)',
      fontFamily: 'Lufga',
      borderBottom: '2px solid rgba(24, 124, 139, 1)',
      width: '220px',
      paddingBottom: '3px'
    },
    heading2: {
      marginTop: '30px',
      fontWeight: '600',
      fontSize: '18px',
      color: 'rgba(24, 124, 139, 1)',
      fontFamily: 'Lufga',
      borderBottom: '2px solid rgba(24, 124, 139, 1)',
      width: '310px',
      paddingBottom: '3px'
    },
    listContent: {
      marginTop: '25px',
      display: 'flex',
      flexDirection: 'column',
      gap: '5px'
    },
    listHeading: {
      fontWeight: '600',
      fontSize: '14px',
      fontFamily: 'Lufga',
    },
    listText: {
      fontWeight: '400',
      fontSize: '14px',
      fontFamily: 'Lufga',
    },
    childContainer: {
      display: 'grid',
      width: '100%',
      gridTemplateColumns: '33.33% 33.33% 33.33%'
    },
    childHeading: {
      fontSize: '16px',
      fontWeight: '600',
      color: 'rgba(24, 124, 139, 1)',
      fontFamily: 'Lufga',
    },
    topBox: {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100px',
      height: "17px",
      background: 'rgba(24, 124, 139, 1)'
    },
    leftBox: {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '17px',
      height: "350px",
      background: 'rgba(24, 124, 139, 1)'
    },
    rightBox: {
      position: 'absolute',
      bottom: '0',
      right: '0',
      width: '20px',
      height: "100px",
      background: 'rgba(24, 124, 139, 1)'
    },
    bottomBox: {
      position: 'absolute',
      bottom: '0',
      right: '0',
      width: '350px',
      height: "20px",
      background: 'rgba(24, 124, 139, 1)'
    },


  }
  const navigate = useNavigate();

  useEffect(() => {
    getAllSurrogate();
  }, []);


  /**
   * This Below function is for search functionality 
   */
  const handleSearchClick = (e) => {
    let val = e.trim().toLowerCase();
    let searchName = filterData.filter((res) => {
      const fullName = (res?.fullName?.trim().toLowerCase().replace(/\s+/g, '') || '') +
        (res?.memberId?.trim().toLowerCase().replace(/\s+/g, '') || '');
      return fullName.includes(val.replace(/\s+/g, ''));
    });
    setAllSurrogateData(searchName);
  };

  async function getAllSurrogate() {
    try {
      let res = await getAllSurrogateDonors();
      setAllSurrogateData(res.data.data);
      setFilterData(res.data.data);
      console.log(res.data.data, "its surrogate data for table");
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  }

  const updatAllData = () => {
    getAllSurrogate();
  };

  let token = new URLSearchParams(window.location.search).get("token");
  let email = new URLSearchParams(window.location.search).get("email");
  let userId = new URLSearchParams(window.location.search).get("id");
  // let navigate = useNavigate()

  useEffect(() => {
    setUserData();
  }, []);

  const deleteuser = async (obj) => {
    setLoader(true);
    await deleteUser(obj, { isDelete: true })
      .then((res) => {
        setLoader(false);
        getAllSurrogate();
      })
      .catch((res) => {
        console.log(res);
      })
      .finally(() => {
        setLoader(false)
      })
  };

  async function exportDataAsPdf(_id) {
    setLoader(true);

    try {
      let res = await getSurrogateMotherData(_id);
      // console.log(res.data.data);
      setAllData(res.data.data);
      setTimeout(() => {
        setTestModal(true);
      }, 1000)
      setLoader(false);
    } catch (err) {
      setLoader(false);
      console.log(err);
    }
  }

  function setUserData() {
    if (token && email && userId) {
      sessionStorage.setItem(
        "userData",
        JSON.stringify({ token, userId, email })
      );
      navigate("/superadmin/manageaccounts/surrogate");
    } else {
      if (!sessionStorage.getItem("userData")) {
        // alert("user logout out")
      }
    }
  }

  function ageCalculate(res) {
    return moment().diff(moment(res), 'years');
  }

  // async function convertToImg() {
  //   setLoader(true);
  //   let arr = [resumeRef1.current, resumeRef2.current];
  //   let photoArr = [];
  //   const pdf = new jsPDF();

  //   for (let index = 0; index < arr.length; index++) {
  //     const res = arr[index];
  //     await htmlToImage
  //       .toPng(res, { quality: 0.5 })
  //       .then(function (dataUrl) {
  //         photoArr.push(dataUrl);
  //         const imgProps = pdf.getImageProperties(dataUrl);
  //         const pdfWidth = pdf.internal.pageSize.getWidth();
  //         const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width - 10;

  //         pdf.addImage(dataUrl, "PNG", 0, 0, pdfWidth, pdfHeight);
  //         if (index !== arr.length - 1) {
  //           pdf.addPage();
  //         }
  //       })
  //       .catch(function (error) {
  //         console.error("oops, something went wrong!", error);
  //       })
  //       .finally(() => {
  //         setLoader(false);
  //         // setTestModal(false)
  //       });
  //   }

  //   pdf.save("Resume");
  //   setTestModal(false)
  //   return 'ok'
  // }

  async function convertToImg() {
    setLoader(true);
    let arr = [resumeRef1.current, resumeRef2.current];
    let photoArr = [];
    const pdf = new jsPDF();
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    for (let index = 0; index < arr.length; index++) {
      const res = arr[index];
      await htmlToImage.toPng(res, { quality: 0.5 }) // Reduced quality to 0.5
        .then(function (dataUrl) {
          photoArr.push(dataUrl);
          const imgProps = pdf.getImageProperties(dataUrl);
          const imgWidth = pdfWidth;
          const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;

          // Scale image to fit within PDF dimensions
          const scaleFactor = Math.min(pdfWidth / imgProps.width, pdfHeight / imgProps.height);
          const scaledWidth = imgProps.width * scaleFactor;
          const scaledHeight = imgProps.height * scaleFactor;

          pdf.addImage(dataUrl, 'PNG', 0, 0, scaledWidth, scaledHeight, undefined, 'FAST'); // Added compression option
          if (index !== arr.length - 1) {
            pdf.addPage();
          }
        })
        .catch(function (error) {
          console.error('oops, something went wrong!', error);
        })
        .finally(() => {
          if (index === arr.length - 1) {
            setLoader(false);
            setTestModal(false)
          }
        });
    }

    pdf.save('Resume.pdf');
  }

  return (
    <>
      <Dialog
        contentClassName="p-lg-4 p-md-4 p-sm-4 p-3 "
        style={{ width: "650px", borderRadius: "0px" }}
        breakpoints={{ "960px": "600", "790px": "90vw" }}
        visible={testModal}
        onHide={() => setTestModal(false)}
        closable={false}
      >
        <div className="d-flex justify-content-center w-100 mt-3"><RiDownload2Line className="fs-1 main_color" /></div>
        <div className="mt-3 fs-4 text-center" style={{ fontWeight: '500' }}>Please click the "Download Profile" button to retrieve the surrogate profile.</div>

        <div className="d-flex gap-4 align-items-center w-100 justify-content-end mt-3">
          <div><button className="cancelBtn" onClick={() => { setTestModal(false) }}>Cancel</button></div>
          <div><button className="theme_button" onClick={convertToImg}>Download Profile</button></div>
        </div>
      </Dialog>


      <Loader open={loader} />
      <div className={style.ClientTable}>
        <AddDocument

          addocument={addocument}
          setAddDocument={setAddDocument}
          updatAllData={updatAllData}
        />
        {testModal ? <div
          style={{ width: '905px' }}
        >
          <div
            style={{
              height: "0px",
              overflow: "hidden",
            }}
          >
            <div style={styleSheet.maincontainer} ref={resumeRef1} id='print'>
              <div style={styleSheet.topBox} />
              <div style={styleSheet.leftBox} />
              <div style={styleSheet.topContainer}>
                <div style={styleSheet.topLeft}>
                  <img crossOrigin='anonymous' src={allData?.photos?.length >= 1 ? `${allData?.photos[0]?.fileUrl}?${moment().format('mm:ss')}` : ''} alt='' style={{ width: '100%', height: '100%' }} />
                </div>
                <div style={styleSheet.topRight}>
                  <div style={styleSheet.topRightInnerTop}>
                    <div>
                      {/* <div style={styleSheet.codeHeading}>{allData?.memberId}</div> */}
                      <div style={styleSheet.codeSub}>Surrogate Mother</div>
                    </div>
                    <div><img src='/images/bigLogo.png' alt='' style={{ width: '140px' }} /></div>
                  </div>
                  <div style={styleSheet.heading}>
                    General Information
                  </div>
                  <div style={styleSheet.listContent}>
                    <div><span className='v_listHeading'>SM Id:</span>&nbsp;<span className='v_listText'>{allData?.memberId}</span></div>
                    <div><span className='v_listHeading'>Name:</span>&nbsp;<span className='v_listText'>{`${allData?.firstName} ${allData?.middleName} ${allData?.lastName}`}</span></div>
                    <div><span className='v_listHeading'>Age:</span>&nbsp;<span className='v_listText'>{ageCalculate(allData?.dob)}</span></div>
                    <div><span className='v_listHeading'>Location:</span>&nbsp;<span className='v_listText'>{`${allData?.state}, ${allData?.country !== undefined ? allData?.country : ''}`}</span></div>
                    <div><span style={styleSheet.listHeading}>Kids:</span>&nbsp;<span style={styleSheet.listText}>{allData?.no_of_kids}</span></div>
                    <div><span style={styleSheet.listHeading}>Vaginal Deliveries:</span>&nbsp;<span style={styleSheet.listText}>{allData?.no_of_vaginal_deliveries}</span></div>
                    <div><span style={styleSheet.listHeading}>C-Section Deliveries:</span>&nbsp;<span style={styleSheet.listText}>{allData?.no_of_c_section_deliveries}</span></div>
                    <div><span style={styleSheet.listHeading}>Insurance:</span>&nbsp;<span style={styleSheet.listText}>{allData?.medical_insurance ? 'Yes' : 'No'}</span></div>
                    <div><span style={styleSheet.listHeading}>Will you Terminate if needed:</span>&nbsp;<span style={styleSheet.listText}>{allData?.terminate_when_need ? 'Yes' : 'No'}</span></div>
                    <div><span style={styleSheet.listHeading}>Are you Willing to work with HIV positive intended parent:</span>&nbsp;<span style={styleSheet.listText}>{allData?.willing_to_work_with_hiv_positive_ip ? 'Yes' : 'No'}</span></div>
                    <div><span style={styleSheet.listHeading}>Are you Willing to work with HEP B positive intended parent:</span>&nbsp;<span style={styleSheet.listText}>{allData?.willing_to_work_with_hep_b_positive_ip ? 'Yes' : 'No'}</span></div>
                    <div><span style={styleSheet.listHeading}>Are you Employed:</span>&nbsp;<span style={styleSheet.listText}>{allData?.currently_employed ? 'Yes' : 'No'}</span></div>
                    <div><span style={styleSheet.listHeading}>Job Role:</span>&nbsp;<span style={styleSheet.listText}>{allData?.occupation}</span></div>
                  </div>
                </div>
              </div>
              {allData?.child_details?.length < 1 ? <>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
              </> : <>
                <div style={styleSheet.heading}>
                  Pregnancy History
                </div>
                <div style={styleSheet.childContainer}>

                  {allData?.child_details?.map((res, index) => {
                    return (

                      <div style={styleSheet.listContent} key={index}>
                        <div style={styleSheet.childHeading}>Child #{index + 1} -:</div>
                        <div><span style={styleSheet.listHeading}>Date of Delivery:</span>&nbsp;<span style={styleSheet.listText}>{moment(res?.dob).format('DD/MM/YYYY')}</span></div>
                        <div><span style={styleSheet.listHeading}>Weeks at Delivery:</span>&nbsp;<span style={styleSheet.listText}>{res?.weeks_at_delivery}</span></div>
                        <div><span style={styleSheet.listHeading}>Baby’s weight:</span>&nbsp;<span style={styleSheet.listText}>{`${res?.body_weight.lbs} Lbs ${res?.body_weight.oz} Oz`}</span></div>
                        <div><span style={styleSheet.listHeading}>Delivery Type:</span>&nbsp;<span style={styleSheet.listText}>{res?.delivery_type}</span></div>
                        <div><span style={styleSheet.listHeading}>Surrogacy:</span>&nbsp;<span style={styleSheet.listText}>{res?.surrogacy ? 'Yes' : 'No'}</span></div>
                        <div><span style={styleSheet.listHeading}>Singleton or Multiple:</span>&nbsp;<span style={styleSheet.listText}>{res?.singleton_or_multiple}</span></div>
                      </div>
                    )
                  })}
                </div>
              </>}
              {allData?.child_details?.length > 3 ? "" : <>
                <div style={styleSheet.heading2}>
                  Compensation and Ideology
                </div>
                <div style={styleSheet.listContent}>
                  <div><span style={styleSheet.listHeading}>Desired Compensation:</span>&nbsp;<span style={styleSheet.listText}>${allData?.desired_compensaton}</span></div>
                  <div><span style={styleSheet.listHeading}>Embryos willing to transfer:</span>&nbsp;<span style={styleSheet.listText}>{allData?.embryo_willing_to_transfer}</span></div>
                  <div><span style={styleSheet.listHeading}>Number of Embryo Transfer Attempts:</span>&nbsp;<span style={styleSheet.listText}>{allData?.no_of_embryo_transfer_attempts}</span></div>
                  <div><span style={styleSheet.listHeading}>Why do you want to become a surrogate? What first interested you in surrogacy?</span>&nbsp;<span style={styleSheet.listText}>{allData?.want_to_become_surrogate}</span></div>
                  <div><span style={styleSheet.listHeading}>What does being a surrogate mean to you?</span>&nbsp;<span style={styleSheet.listText}>{allData?.what_surrogate_means_to_you}</span></div>
                  <div><span style={styleSheet.listHeading}>Do you have any concerns about surrogacy?</span>&nbsp;<span style={styleSheet.listText}>{allData?.any_concern_about_surrogacy}</span></div>
                  <div><span style={styleSheet.listHeading}>Are you willing to work with Intended Parent(s) that does not speak English (translation provided)?</span>&nbsp;<span style={styleSheet.listText}>{allData?.willing_to_work_with_non_english_speaker_ip}</span></div>
                  <div><span style={styleSheet.listHeading}>Are you willing to reduce caffeine during pregnancy?</span>&nbsp;<span style={styleSheet.listText}>{allData?.willing_to_reduce_caffeine ? 'Yes' : 'No'}</span></div>
                  <div><span style={styleSheet.listHeading}>Are you willing to eat organic foods, if requested (and paid for) by the Intended Parent(s)?</span>&nbsp;<span style={styleSheet.listText}>{allData?.willing_to_eat_organic_food ? 'Yes' : 'No'}</span></div>
                  <div><span style={styleSheet.listHeading}>What type of family would you like to work with?</span>&nbsp;<span style={styleSheet.listText}>{allData?.type_of_family_want_to_work_with}</span></div>
                  <div><span style={styleSheet.listHeading}>Is there anyone you are not comfortable working with?</span>&nbsp;<span style={styleSheet.listText}>{allData?.NOT_want_work_with_intended_parents}</span></div>
                </div>
              </>}
              <br />
              <br />
              <br />
              <br />
            </div>
            <div style={styleSheet.maincontainer2} ref={resumeRef2} >
              <div style={styleSheet.topBox} />
              <div style={styleSheet.leftBox} />
              <div style={styleSheet.topRightInnerTop}>
                <div>&nbsp;</div>
                <div><img src='/images/bigLogo.png' alt='' style={{ width: '140px' }} /></div>
              </div>
              {allData?.child_details?.length > 3 ? <>
                <br />
                <div style={styleSheet.heading2}>
                  Compensation and Ideology
                </div>
                <div style={styleSheet.listContent}>
                  <div><span style={styleSheet.listHeading}>Desired Compensation:</span>&nbsp;<span style={styleSheet.listText}>${allData?.desired_compensaton}</span></div>
                  <div><span style={styleSheet.listHeading}>Embryos willing to transfer:</span>&nbsp;<span style={styleSheet.listText}>{allData?.embryo_willing_to_transfer}</span></div>
                  <div><span style={styleSheet.listHeading}>Number of Embryo Transfer Attempts:</span>&nbsp;<span style={styleSheet.listText}>{allData?.no_of_embryo_transfer_attempts}</span></div>
                  <div><span style={styleSheet.listHeading}>Why do you want to become a surrogate? What first interested you in surrogacy?</span>&nbsp;<span style={styleSheet.listText}>{allData?.want_to_become_surrogate}</span></div>
                  <div><span style={styleSheet.listHeading}>What does being a surrogate mean to you?</span>&nbsp;<span style={styleSheet.listText}>{allData?.what_surrogate_means_to_you}</span></div>
                  <div><span style={styleSheet.listHeading}>Do you have any concerns about surrogacy?</span>&nbsp;<span style={styleSheet.listText}>{allData?.any_concern_about_surrogacy}</span></div>
                  <div><span style={styleSheet.listHeading}>Are you willing to work with Intended Parent(s) that does not speak English (translation provided)?</span>&nbsp;<span style={styleSheet.listText}>{allData?.willing_to_work_with_non_english_speaker_ip}</span></div>
                  <div><span style={styleSheet.listHeading}>Are you willing to reduce caffeine during pregnancy?</span>&nbsp;<span style={styleSheet.listText}>{allData?.willing_to_reduce_caffeine ? 'Yes' : 'No'}</span></div>
                  <div><span style={styleSheet.listHeading}>Are you willing to eat organic foods, if requested (and paid for) by the Intended Parent(s)?</span>&nbsp;<span style={styleSheet.listText}>{allData?.willing_to_eat_organic_food ? 'Yes' : 'No'}</span></div>
                  <div><span style={styleSheet.listHeading}>What type of family would you like to work with?</span>&nbsp;<span style={styleSheet.listText}>{allData?.type_of_family_want_to_work_with}</span></div>
                  <div><span style={styleSheet.listHeading}>Is there anyone you are not comfortable working with?</span>&nbsp;<span style={styleSheet.listText}>{allData?.NOT_want_work_with_intended_parents}</span></div>
                </div>
                <br />
              </> : ''}
              <div style={styleSheet.heading}>
                Interest
              </div>
              <div style={styleSheet.listContent}>
                <div><span style={styleSheet.listHeading}>Describe your ideal Intended Parent(s):</span>&nbsp;<span style={styleSheet.listText}>{allData?.describe_the_ideal_intended_Parent}</span></div>
                <div><span style={styleSheet.listHeading}>What would you like your future Intended Parent(s) to know about you?</span>&nbsp;<span style={styleSheet.listText}>{allData?.note_future_intended_Parent}</span></div>
                <div><span style={styleSheet.listHeading}>Are you willing to have the Intended Parent(s) present during doctors appointments and the delivery?</span>&nbsp;<span style={styleSheet.listText}>{allData?.presence_of_ip_during_appointment_and_delivery ? 'Yes' : 'No'}</span></div>
                <div><span style={styleSheet.listHeading}>There anyone you would like present during the delivery (example: Spouse/Partner, Mother, Sister or Friend)?</span>&nbsp;<span style={styleSheet.listText}>{allData?.anyone_you_want_during_delivery}</span></div>
                <div><span style={styleSheet.listHeading}>How often would you like to be in contact with the Intended Parent(s) during the pregnancy?</span>&nbsp;<span style={styleSheet.listText}>{allData?.contact_with_ip_during_pregnancy}</span></div>
                <div><span style={styleSheet.listHeading}>How often would you like to be in contact with the Intended Parent(s) after the delivery?</span>&nbsp;<span style={styleSheet.listText}>{allData?.fequency_of_contact_with_ip_after_pregnancy}</span></div>
                <div><span style={styleSheet.listHeading}>How would you like to be in contact with the Intended Parent(s)?</span>&nbsp;<span style={styleSheet.listText}>{allData?.mode_of_communication_with_ip}</span></div>
                <div><span style={styleSheet.listHeading}>Are you willing to pump breast milk (compensation available)?</span>&nbsp;<span style={styleSheet.listText}>{allData?.willing_to_pump_breast_milk ? 'Yes' : 'No'}</span></div>
                <div><span style={styleSheet.listHeading}>Are you willing to leave termination choices up to the Intended Parent(s) in the case of a serious birth defect?</span>&nbsp;<span style={styleSheet.listText}>{allData?.terminate_when_need ? 'Yes' : 'No'}</span></div>
                <div><span style={styleSheet.listHeading}>Are you willing to leave termination choices up to the Intended Parent(s) in the case of a non-life threatening condition?</span>&nbsp;<span style={styleSheet.listText}>{allData?.leave_termnation_choice_on_ip_for_threatening_condition ? 'Yes' : 'No'}</span></div>
                <div><span style={styleSheet.listHeading}>If recommended by a doctor are you willing to be placed on bedrest?</span>&nbsp;<span style={styleSheet.listText}>{allData?.willing_to_be_placed_on_bedrest ? 'Yes' : 'No'}</span></div>
                <div><span style={styleSheet.listHeading}>What reassurance can you give your Intended Parent(s) that you will not change your mind?</span>&nbsp;<span style={styleSheet.listText}>{allData?.reassurances_for_ip}</span></div>
                <div><span style={styleSheet.listHeading}>Please describe your support system and who your support system is:</span>&nbsp;<span style={styleSheet.listText}>{allData?.describe_support_system}</span></div>
                <div><span style={styleSheet.listHeading}>Do you have child care available?</span>&nbsp;<span style={styleSheet.listText}>{allData?.child_care_available ? 'Yes' : 'No'}</span></div>
                <div><span style={styleSheet.listHeading}>When are you ready to begin:</span>&nbsp;<span style={styleSheet.listText}>{moment(allData?.ready_to_begin).format('DD/MM/YYYY')}</span></div>
              </div>
            </div>
          </div>
        </div> : ''}
        <div className="d-flex justify-content-between mb-4 mt-3 ">
          <div>
            {" "}
            <span className="lufga_24px fw_600">Surrogate Mother</span>
          </div>
          <div className="d-flex gap-2 align-items-center">
            <div>
              <button
                className="theme_button"
                onClick={() => setAddDocument(true)}
              >
                + Add Account
              </button>
            </div>
            <div>
              <span className={`p-input-icon-left d-flex align-items-center `}>
                {/* <i className={`pi pi-search ${style.search_icon}`} /> */}
                <div className="searchInput w-100">
                  <input
                    className="form-control ps-5"
                    placeholder="Search"
                    onChange={(e) => handleSearchClick(e.target.value)}
                  ></input>
                  <i className="pi pi-search searchIcon"></i>
                </div>
              </span>
            </div>
          </div>
        </div>

        <TableContainer className={"table_container"}>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>User Role</th>
                <th>User ID</th>
                <th>Plan Type</th>
                <th>Account Created</th>
                <th>Country</th>
                <th>Source</th>

                <th></th>
              </tr>
            </thead>
            <tbody>
              {allSurrogatesData.length > 0 ? (allSurrogatesData.map((res, index) => (
                <tr key={index}>
                  <td className="d-flex align-items-center" style={res.user_blocked === true ? { "opacity": '0.53' } : {}}>
                    <span className={` me-3  ${style.ProfileImages} ${res.user_blocked === true ? style.block_style : ''}
                    `}>
                      <img src={res.resume?.photos?.[res.resume?.photo_resume_index]?.fileUrl ||
                        res.resume?.photos?.[res.resume?.photo_profile_index]?.fileUrl ||
                        res.resume?.photos?.[0]?.fileUrl ||
                        '/images/blankProfile.png'}
                        alt="jjmage"
                        className={`w-100 `} />
                    </span>
                    <div>{res?.fullName}</div>
                  </td>
                  <td style={res.user_blocked === true ? { "opacity": '0.53' } : {}}>{res?.role == "surrogate_donor" ? "Surrogate Mother" : ""}</td>
                  <td style={res.user_blocked === true ? { "opacity": '0.53' } : {}}>{res.memberId}</td>
                  <td style={res.user_blocked === true ? { "opacity": '0.53' } : {}}>
                    {res?.subscription_plan?.plan_name
                      ? res?.subscription_plan?.plan_name
                      : "Free"}
                  </td>
                  <td style={res.user_blocked === true ? { "opacity": '0.53' } : {}}>{res.createdAt ? moment(res.createdAt).format("DD/MM/YYYY") : "-N/A-"}</td>
                  <td style={res.user_blocked === true ? { "opacity": '0.53' } : {}}>{res?.resume?.country ? res?.resume?.country : <>N/A</>}</td>
                  <td style={res.user_blocked === true ? { "opacity": '0.53' } : {}}>
                    {res.source ? res.source : "-N/A-"}
                  </td>

                  <td className="d-flex align-items-center gap-3">
                    <button
                      className={style.ViewButton}
                      onClick={() =>
                        navigate(`/superadmin/manageaccounts/viewResume/${res._id}`, { state: { data: res.user_blocked } })

                      }
                    >
                      <i className="bi bi-eye d-flex align-items-center "></i>{" "}
                      View
                    </button>
                    <ConfirmDelete onDelete={deleteuser} data={res._id} header="Delete Surrogate Mother" deleteMsg="Are you sure you want to delete this surrogate mother account">
                      <button
                        className={`d-flex align-items-center gap-1 ${style.ViewButton}`}

                      >
                        <RiDeleteBinLine className="text-danger" />
                        Delete
                      </button>
                    </ConfirmDelete>

                    <button className={`${style.ViewButton}`} disabled={!res?.concise_resuma_complete} onClick={() => exportDataAsPdf(res._id)}>Export</button>
                  </td>
                </tr>
              )))
              : (
                <tr style={{height:"60vh"}}>
                  <td colSpan="8" className="text-center">
                    <h1>No data available</h1>
                  </td>
                </tr>
              )
              }
            </tbody>
          </table>
        </TableContainer>
      </div>
    </>
  );
};

export default Myclient;
