import React, { useState, useEffect } from "react";
import ClientTable from "../ClientTable";
import style from "../../MyClientListing/MyClient.module.css";
import { InputText } from "primereact/inputtext";
import { getReportByClientId } from "../../../../Api/IvfClinic";
import AddDocument from "../AddDocumentDialog/AddDocument";

import Loader from "../../../common/Loader";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import BackButton from "../../../commonComponents/BackButton/BackButton";

const ViewReport = () => {

  // const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);
  // const appointmentId = queryParams.get('appointmentId');

  const location = useLocation();
  const { appointmentId,ClinetName } = location.state;


  const params = useParams()
  const clientId = params.clientid;
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [filterData, setFilterData] = useState([]);

  const [addocument, setAddDocument] = useState(false);

  const handleSearchClick = (e) => {
    let val = e.trim().toLowerCase();
    let searchName = filterData.filter((res) => {
      const fullName = (res?.date?.trim().toLowerCase().replace(/\s+/g, '') || '') +
        (res?.report_name?.trim().toLowerCase().replace(/\s+/g, '') || '');
      return fullName.includes(val.replace(/\s+/g, ''));
    });
    setData(searchName);
  };


  const getReportsFunction = async () => {
    try {
      setLoader(true);
      const response = await getReportByClientId({ "clientId": clientId });
      console.log(response, "Reports response");
      setData(response.data.data);
      setFilterData(response.data.data)
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getReportsFunction();
  }, []);

  return (
    <>
      <Loader open={loader} />
      <AddDocument addocument={addocument} setAddDocument={setAddDocument} appointmentId={appointmentId} reload={getReportsFunction} />

      <BackButton text="Back" path={-1}/>

      <div className={`${style.ClientTable}`}>
        <div className="d-flex justify-content-between mb-4">
          <div className="d-flex flex-column gap-2 align-items-start lufga_24px fw_600">
            <div>Reports</div>
            <span className="lufga_22px">
              <span className="">{clientId}: </span>
              {ClinetName}
            </span>
          </div>

          <div className="d-flex align-items-center gap-3">
            <div className="searchInput w-100">
              <input
                className="form-control ps-5"
                placeholder="Search"
                onChange={(e) => handleSearchClick(e.target.value)}
              ></input>
              <i className="pi pi-search searchIcon"></i>
            </div>

            <div>
              <button className="saveButton wsnb" onClick={() => { setAddDocument(!addocument) }}>
                + Add Report
              </button>
            </div>
          </div>

        </div>
        <ClientTable data={data} role="reports" update={getReportsFunction} />
      </div>
    </>
  );
};

export default ViewReport;
