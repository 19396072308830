
import React, { useEffect, useState } from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import style from './Setting.module.css'
import { InputSwitch } from "primereact/inputswitch";
import Loader from '../../common/Loader';
import { getDashboardUser } from '../../../Api/CommonApis';
import { emailNotificationData, pushNotificationData } from '../../../Api/IntendedParent';
import { IoIosArrowForward } from "react-icons/io";
import { t } from 'i18next';
import { useNavigate } from 'react-router';
const Setting = () => {
  const [loader, setLoader] = useState(true)
  let userId = sessionStorage.getItem('userId')
  const navigate = useNavigate()
  const [pushNotification, setPushNotification] = useState({
    welcome_notifications: false,
    subscription_payment_confirmations: false,
    password_reset_confirmations: false,
    notification_for_profile_data_edits: false,
    new_match_making_alerts: false,
    shortlisted_candidate_submissions: false,
    new_auction_joins: false,
    latest_auction_price: false,
    security_deposit_success_notifications: false,
    new_bids_alerts: false,
    auction_winner_declarations: false
  })
  const [emailNotification,setEmailNotification] = useState({
    welcome_notifications: false,
    subscription_payment_confirmation: false,
    password_reset: false,
    profile_data_edit_emails: false,
    email_alerts_for_new_match_making: false,
    shortlisted_candidate_submissions: false,
    new_auction_joins: false,
    security_deposit_success: false,
    new_bids: false,
    auction_winner_declarations: false,
    security_deposit_refunds: false
  })
  


  async function getUserData() {

    try {
      let res = await getDashboardUser(userId)
      if(res.data.data.pushNotification_ip !== undefined){
        setPushNotification(pre=>{
          return {...pre,...res.data.data.pushNotification_ip}
        })
      }

      if(res.data.data.emailNotification_ip !==undefined){
        setEmailNotification(pre=>{
          return {...pre,...res.data.data.emailNotification_ip}
        })
      }
    
      setLoader(false)
      console.log(res)
    } catch (err) {
      console.log(err)
      setLoader(false)
    }
  }


  async function inputChange(e,type){

    if(type == 'pushNotification'){
      let obj = {...pushNotification,[e.target.name]:e.target.value}
      setPushNotification(obj)
      await pushNotificationData(userId,obj)
    }else if (type == 'emailNotification'){
      let obj = {...emailNotification,[e.target.name]:e.target.value}
      setEmailNotification(obj)
      await emailNotificationData(userId,obj)
    }
  }




  useEffect(() => {
    getUserData()
  }, [])



  return (
    <div>
      <Loader open={loader} />
      <div className="mt-3">
        <Accordion multiple activeIndex={[0]} className={style.AccordionTabheader}>
          <AccordionTab header="Email Notification" className={style.AccordionBAck}>

            <div className={style.AccordingTab}>
              <div className={style.TabHeading}>Auction Update</div>
              <div className='d-flex align-items-center'>  <InputSwitch checked={pushNotification.welcome_notifications} name='welcome_notifications' onChange={(e) => inputChange(e,'pushNotification')} /></div>
            </div>

            <div className={style.AccordingTab}>
              <div className={style.TabHeading}>Latest News</div>
              <div className='d-flex align-items-center'>  <InputSwitch checked={pushNotification.subscription_payment_confirmations} name='subscription_payment_confirmations' onChange={(e) => inputChange(e,'pushNotification')} /></div>
            </div>

            <div className={style.AccordingTab}>
              <div className={style.TabHeading}>Important Update</div>
              <div className='d-flex align-items-center'> <InputSwitch checked={pushNotification.password_reset_confirmations} name='password_reset_confirmations' onChange={(e) => inputChange(e,'pushNotification')} /></div>
            </div>

            <div className={style.AccordingTab}>
              <div className={style.TabHeading}>Promotional Notification</div>
              <div className='d-flex align-items-center'> <InputSwitch checked={pushNotification.notification_for_profile_data_edits} name='notification_for_profile_data_edits' onChange={(e) => inputChange(e,'pushNotification')} /></div>
            </div>

            
          </AccordionTab>



          <AccordionTab header="Change Password" className={style.AccordionBAck}>
            <div className={style.PasswordBack}>
              <form action="">
                <div className="row mb-3">
                <div className="col-md-4">
                    <label className={`form-check-label mb-2 ${style.LabelText}`} htmlFor="flexCheckDefault">Current Password<span className='text-danger'>*</span></label>
                    <input type="password" className="form-control" placeholder="Enter Current Password" />
                  </div>
                  <div className="col-md-4">
                    <label className={`form-check-label mb-2 ${style.LabelText}`} htmlFor="flexCheckDefault">New Password<span className='text-danger'>*</span></label>
                    <input type="password" className="form-control" placeholder="Enter New Password" />
                  </div>
                  <div className="col-md-4">
                    <label className={`form-check-label mb-2 ${style.LabelText}`} htmlFor="flexCheckDefault">Confirm Password<span className='text-danger'>*</span></label>
                    <input type="password" className="form-control" placeholder="Confirm New Password" />
                  </div>
                </div>
                <div className='d-flex justify-content-end gap-3 mb-3'>
                  <button className={style.cancelButton}>cancel</button>
                  <button className={style.SaveButton}>Save Changes</button>
                </div>
              </form>
            </div>

          </AccordionTab>


          <AccordionTab header="Informative Content Access" className={style.AccordionBAck}>

            <div className={`${style.AccordingTab} pointer`} onClick={()=>navigate('termAndServices')}>
              <div className={style.TabHeading}>Terms & Conditions</div>
              <div className={style.round}><IoIosArrowForward /></div>
            </div>

            <div className={`${style.AccordingTab} pointer`} onClick={()=>navigate('privacyPolicy')}>
              <div className={style.TabHeading}>Privacy Policy</div>
              <div className={style.round}><IoIosArrowForward /></div>

            </div>
            <div className={`${style.AccordingTab} pointer`} onClick={()=>navigate('faqselect')}>
              <div className={style.TabHeading}>Frequently Asked Question</div>
              <div className={style.round}><IoIosArrowForward /></div>

            </div>

            <div className={`${style.AccordingTab} pointer`} onClick={()=>navigate('aboutUs')}>
              <div className={style.TabHeading}>About Us</div>
              <div className={style.round}><IoIosArrowForward /></div>

            </div>
          </AccordionTab>
          
        </Accordion>
      </div>
    </div>
  )
}

export default Setting