import React, { useEffect, useState } from "react";
import style from "../../../LawyerComponent/MyProfile/LawyerMyProfile.module.css";
import styles from './LawyerActiveClients.module.css'
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { Country, State, City } from "country-state-city";
import ActiveDelete from "../../../commonComponents/ActiveDelete/ActiveDelete";
import { getDashboardUser } from "../../../../Api/CommonApis";
import Loader from "../../../common/Loader";
import { FaArrowLeft } from "react-icons/fa";

const LawyeruserProfile = () => {
  let { t } = useTranslation();
  let userId = useParams().id;
  const navigate = useNavigate();
  function EditProfile() {
    navigate("editlawyerprofile");
  }

  const [userData, setUserData] = useState({});
  const [loader, setLoader] = useState(true);

  async function getUserData() {
    try {
      console.log(userId);
      let res = await getDashboardUser(userId);
      setUserData(res.data.data);
      console.log(res);

      // if (res.data.data.country !== undefined) {
      //     let cRes = countries?.find(res2 => res2?.name == res.data.data.country)
      //     setCountry(`${cRes.name}&&${cRes.isoCode}`)
      //     getStates(`${cRes.name}&&${cRes.isoCode}`)
      // }
      // if (res.data.data.city !== undefined) {
      //     setCity(res.data.data.city)
      // }
      setLoader(false);
    } catch (err) {
      console.log(err);
      setLoader(false);
    }
  }

  useEffect(() => {
    getUserData();
  }, []);
  return (
    <>
      <Loader open={loader} />
     
          <div className="mt-4">
            <div
              className="d-flex align-items-center gap-2 btn-Secondary p-0 pointer"
              onClick={() => navigate(-1)}
            >
              <FaArrowLeft /> Back
            </div>
          </div>
        
      <div className={style.MyProfile}>
        <div className={`mb-5 ${style.bannersize}`}>
          <div className="d-flex">
            
            <div className="p-4">
              <img src={userData?.imageUrl || `/images/blankProfile.png`} alt="" className={styles.ProfileImages} />
            </div>

            <div className="d-flex flex-column align-item-center justify-content-center gap-3">
              <div className={style.BnnerFirstText}>
                {userData.memberId} : Lawyer
              </div>
              <div className={style.BnnersecondtText}>{userData.fullName}</div>
              <div>
                <ActiveDelete id={userId} user_blocked={userData?.user_blocked} target={"lawyer"} />
              </div>
            </div>
          </div>
          <div className={`p-2 ${style.ParentButton}`}>
            <img src="/images/image 244.png" alt="" />
            {/* <div className={`d-flex justify-content-end ${style.ChlidButton}`}>
                        <button className={`text-center d-flex align-items-center ${style.VerfiedButton}`}><i class="bi bi-pencil-square me-1 "></i>
                            <span className={style.verfiedText} onClick={EditProfile}>Edit Details</span>
                        </button>
                    </div> */}
          </div>
        </div>

        <form action="">
          <div className="row mb-4">
            <div className="col-md-12">
              <label
                htmlFor="exampleInputEmail1"
                className={`form-label  ${style.LabelHeading}`}
              >
                {t("Firm Name")}
                <span className="text-danger">*</span>
              </label>

              <input
                type="text"
                disabled
                className={`form-control ${style.InputCustom}`}
                placeholder=""
                value={userData.firm_name}
              />
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-md-6">
              <label
                htmlFor="exampleInputEmail1"
                className={`form-label  ${style.LabelHeading}`}
              >
                {t("Communication Email Address")}
                <span className="text-danger">*</span>
              </label>

              <input
                type="email"
                disabled
                className={`form-control ${style.InputCustom}`}
                placeholder=""
                value={userData.email}
              />
            </div>
            <div className="col-md-6">
              <label
                htmlFor="exampleInputEmail1"
                className={`form-label  ${style.LabelHeading}`}
              >
                {t("Password")}
              </label>

              <input
                type="password"
                disabled
                className={`form-control ${style.InputCustom}`}
                placeholder=""
                value={userData.password}
              />
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-md-6">
              <label
                htmlFor="exampleInputEmail1"
                className={`form-label  ${style.LabelHeading}`}
              >
                {t("Contact Number")}
              </label>

              <input
                type="number"
                disabled
                className={`form-control ${style.InputCustom}`}
                placeholder=""
                value={userData.mobile_No}
              />
            </div>
            <div className="col-md-6">
              <label
                htmlFor="exampleInputEmail1"
                className={`form-label  ${style.LabelHeading}`}
              >
                {t("Alternative Contact Number")}
              </label>

              <input
                type="number"
                disabled
                className={`form-control ${style.InputCustom}`}
                placeholder=""
                value={userData.mobile_No_alter}
              />
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-md-12">
              <label
                htmlFor="exampleInputEmail1"
                className={`form-label  ${style.LabelHeading}`}
              >
                {t("Current Address")}
                <span className="text-danger">*</span>
              </label>

              <input
                type="text"
                disabled
                className={`form-control ${style.InputCustom}`}
                placeholder=""
                value={userData.address}
              />
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-md-6 mb-4">
              <label
                htmlFor="exampleInputEmail1"
                className={`form-label  ${style.LabelHeading}`}
              >
                {t("Country")}
                <span className="text-danger">*</span>{" "}
              </label>
              <select
                className={`form-select ${style.InputCustom}`}
                aria-label="Default select example"
                disabled
              >
                <option selected>{t(userData.country)}</option>
                {/* <option value={1}>{t("Noida")}</option>
                                <option value={2}>{t("Aligarh")}</option>
                                <option value={3}>{t("Rampur")}</option> */}
              </select>
            </div>
            <div className="col-md-6 mb-4">
              <label
                htmlFor="exampleInputEmail1"
                className={`form-label  ${style.LabelHeading}`}
              >
                {t("City")}
                <span className="text-danger">*</span>{" "}
              </label>
              <select
                className={`form-select ${style.InputCustom}`}
                aria-label="Default select example" disabled
              >
                <option selected>{t(userData.city)}</option>
                {/* <option value={1}>{t("Noida")}</option>
              <option value={2}>{t("Aligarh")}</option>
              <option value={3}>{t("Rampur")}</option> */}
              </select>
            </div>
            <div className="col-md-6">
              <label
                htmlFor="exampleInputEmail1"
                className={`form-label  ${style.LabelHeading}`}
              >
                {t("State of Residence")} <span className="text-danger">*</span>
              </label>
              <select
                className={`form-select ${style.InputCustom}`}
                aria-label="Default select example" disabled
              >
                <option selected>{t(userData.state)}</option>
                {/* <option value={1}>{t("07")}</option>
              <option value={2}>{t("08")}</option>
              <option value={3}>{t("09")}</option> */}
              </select>
            </div>
            <div className="col-md-6">
              <label
                htmlFor="exampleInputEmail1"
                className={`form-label  ${style.LabelHeading}`}
              >
                {t("Zip Code")}
                <span className="text-danger">*</span>{" "}
              </label>
              <input
                type="text"
                disabled
                className={`form-control ${style.InputCustom}`}
                placeholder=""
                value={userData.zip_code}
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default LawyeruserProfile;
