import { classNames } from 'primereact/utils'
import React from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const PhoneInputComponent = ({ value, onChange, disabled, required, placeholder, name }) => {
    
    const handleChange = (value, country, e, formattedValue) => {
        if (value) {
            const event = {
                target: {
                    name,
                    value: formattedValue,
                },
            };
            onChange(event);
        }
    };
    
    return (
        <PhoneInput
            country={'us'}
            value={value}

            onChange={handleChange}


            disabled={disabled}
            inputStyle={{
                backgroundColor: disabled ? '#e9ecef' : 'white', 
                cursor: disabled ? 'not-allowed' : 'text'
            }}
            // dropdownStyle={{
            //     display: disabled ? 'none' : 'block' // Hide the dropdown when disabled
            // }}
            containerStyle={{
                width: '100%',
            }}
            inputProps={{
                name,
                required,
                placeholder,
                className: 'form-control w-100'
            }}
        />
    )
}

export default PhoneInputComponent


