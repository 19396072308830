import React, { useEffect } from "react";
import style from "./Editsocialpages.module.css";
import { Dialog } from "primereact/dialog";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { deleteSocialPage, getSocialPageById, updateSocialPage } from "../../../../../Api/admin";
import { deleteDocsApi, uploadDocument } from "../../../../../Api/CommonApis";
import Loader from "../../../../common/Loader";

const EditSocialMedia = ({ EditSocialpage, setEditSocialpage ,id,  refresh, setRefresh }) => {
  let { t } = useTranslation();
  // const [imageSrc, setImageSrc] = useState(null);
  // const [selectedOption, setSelectedOption] = useState("");
  const [platformLink, setPlatformLink] = useState("");
  const [platform, setPlatform] = useState("");
  const [img, setImg] = useState(null);
  const [imageName, setImageName] = useState("");
  const [defaultImage, setDefaultImage] = useState(null)
  const [imageUrl, setImageUrl] = useState("");
  const [loader, setLoader] = useState(false)
  console.log(imageUrl)


  const handleDropdownChange = (event) => {
    setPlatform(event.target.value);
  };

  const handleEdit = async()=>{
    let formdata= new FormData()
      if(img !== null){
        formdata.append('uploadDoc', img)
      }

      setLoader(true)
    let imageData;
    try {
      if (img !== null) {
         
          const deletedImage = await deleteDocsApi({fileName:imageName})
          console.log("deletedImageeeeee",deletedImage);

          imageData = await uploadDocument(formdata)
          console.log("imageData",imageData);

          setImageName(imageData?.data?.fileName)
          setImageUrl(imageData?.data?.fileLocation)
          setLoader(false)
      }
    } catch (err) {
        console.log("error",err)
    }

    const body = {
        "platform": platform,
        "platformLink": platformLink,
        "platform_logo_name": imageData?.data?.fileName,
        "platform_logo_url": imageData?.data?.fileLocation,
    }
    console.log("bodyyyy",body)
    const result = await updateSocialPage(id,body)
    console.log(result)
    setEditSocialpage(false)
    setRefresh(!refresh)
    setLoader(false)  
    setDefaultImage(null)
    setPlatformLink("")
    setPlatform("")
    setImg(null)
    setImageName("")
    setImageUrl("")
  }

  const fetchPlatform = async()=>{
    if(id){
      setLoader(true)
      const result = await getSocialPageById(id)
      console.log(result.data.data)
      setPlatformLink(result.data.data[0].platformLink)
      setPlatform(result.data.data[0].platform)
      setImageName(result.data.data[0].platform_logo_name)
      setImageUrl(result.data.data[0].platform_logo_url)
      setLoader(false)
    }
  }

  const handledelete = async ()=>{
    try {
      if (imageName !== null) {
          setLoader(true)
          const deletedImage = await deleteDocsApi({fileName:imageName})
          console.log(deletedImage)
      }
    } catch (err) {
        console.log("error",err)
    }
    const result = await deleteSocialPage(id)
    setEditSocialpage(false)
    setLoader(false)
    setRefresh(!refresh)
    setDefaultImage(null)
    setDefaultImage(null)
    setPlatformLink("")
    setPlatform("")
    setImg(null)
    setImageName("")
    setImageUrl("")
  }
    console.log(id)

  useEffect(()=>{
     fetchPlatform()
   },[id])

  return (
    <div className={style.AuctionForm}>
      <Loader open={loader} />
      <Dialog
        closable={false}
        visible={EditSocialpage}
        className="p-0 m-0"
        onHide={() => setEditSocialpage(false)}
        style={{ width: "50vw", padding: "0px", margin: "0px" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <div>
          <div className={`d-flex justify-content-between`}>
          {/* <i class=""></i> */}
        <i className="bi bi-chevron-left fs-3 pointer" onClick={()=>setEditSocialpage(false)}></i>
            <div className={`lufga_32px fw_600 `}>Edit Platform</div>
            <div></div>
          </div>

          <div className="row">
            <div className="col-md-12 mt-2">
              <label
                className={`form-check-label mb-1 ${style.LabelText} fw_600`}
                htmlFor="flexCheckDefault"
              >
                Select Platform
              </label>
              <div>
                {/* <label htmlFor="dropdown"></label> */}
                <select
                  className={`${style.input_text}
                `}
                  id="dropdown"
                  value={platform}
                  onChange={handleDropdownChange}
                >
                  <option value="">Select Platform</option>
                  <option value="Facebook">Facebook</option>
                  <option value="Instagram">Instagram</option>
                  <option value="Tiktok">Tiktok</option>
                  <option value="Twitter">Twitter</option>
                  <option value="LinkedIn">LinkedIn</option>
                  <option value="Pinterest">Pinterest</option>
                  <option value="Reddit">Reddit</option>
                  <option value="Discord">Discord</option>
                  <option value="Youtube">Youtube</option>
                  <option value="Bilibili">Bilibili</option>
                  <option value="Telegram">Telegram</option>
                </select>
              </div>
              {/* <input
                type="email"
                className="form-control"
                placeholder="Lorem Ipsum"
              /> */}
            </div>

              <div className={`form-check-label mb-1 ${style.LabelText} fw_600`}>Logo</div>
            <div className={` ${style.Images_Border}`}>
              <label htmlFor="fileInput" className={style.center_content}>
                <i
                  className={`bi bi-card-image  mb-3 ${style.imagesCustom}`}
                ></i>
                <div>
                  <div className={style.UploadName}>
                    Upload or Drop your image
                  </div>
                </div>
              </label>
              <input
                type="file"
                id="fileInput"
                required
                onChange={(e)=>{setImg(e.target.files[0])
                  setDefaultImage(URL.createObjectURL(e.target.files[0]))
                }}
                accept="image/*"
                /* onChange={handleImageUpload} */ style={{ display: "none" }}
              />
              {/* {imageSrc && ( */}
               <div   className="  d-flex align-items-center justify-content-center">  
               
               <img
                  src={defaultImage || imageUrl}
                  alt=""
                  style={{ width: "100px", height: "100px" , }}
                />
               </div>
              {/* )} */}
            </div>

            


            <div className="col-md-12 mt-2">
              <label
                className={`form-check-label mb-1 fw_600 ${style.LabelText}`}
                htmlFor="flexCheckDefault"
              >
                Platform Link
              </label>
              <input
                type="text"
                value={platformLink}
                className="form-control"
                placeholder="www.instagram.com/NewHopePoints/"
                onChange={(e)=>setPlatformLink(e.target.value)}
              />
            </div>

            <div className="d-flex mt-4 justify-content-end gap-4">
              <button
                className={style.cancelBtn}
                onClick={handledelete}
              >
                Delete Platform
              </button>
              <button className={style.SaveButton} onClick={handleEdit}>Submit</button>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default EditSocialMedia;
