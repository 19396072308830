import React from 'react'
import MyProfilesurrogate from "../../SurrogateComponents/MyProfile/Myprofile"
import MyProfileintended from "../../IntendedParentsComponent/MyProfile/MyProfile"
import MyProfileivf from "../../IvfClinicComponents/MyProfile/MyProfile"
import LawyerMyProfile from '../../LawyerComponent/MyProfile/LawyerMyProfile'

import { useParams } from 'react-router'

const UserProfile = () => {

    const params=useParams() 
    const role=params.role 

    

  return (
    <div>
        {
            role === "surrogate" ? 
            <MyProfilesurrogate /> 
            :
            role==="intendedParent"?  
            <MyProfileintended/>
            :
            role==="lawyer"?
            <LawyerMyProfile/>
            :
            <MyProfileivf/>
        }
    </div>
  )
}

export default UserProfile
