import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  firstName: "",
  lastName: "",
  photos: [{ fileUrl: "/images/user_profile_img.png" }],
  clinicName: "",
  clinicPhoto: [{ fileUrl: "/images/ivfclinichospitalimage.png" }],
  ClinicId: "",
  ClinicProfileId: "",
  activeIndex: null,
  lawyerName: "",
  lawyerPhoto: [{ fileUrl: "/images/lawyerDefaultPic.png" }],
  LawyerId: "",
  LawyerProfileId: "",
  embryoDonorName: "",
  embryoDonorPhoto: [{ fileUrl: "/images/user_profile_img.png" }],
  embryoDonorId: "",
  embryoDonorProfileId: "",
  IntendedParentName: "",
  IntendedParentPhoto: [{ fileUrl: "/images/IpIcon.png" }],
  IntendedParentId: "",
  IntendedParentProfileId: "",
};

export const headerSlice = createSlice({
  name: "headerData",
  initialState,
  reducers: {
    changeHeaderData: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export let { changeHeaderData } = headerSlice.actions;
