
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { PrimeReactProvider } from 'primereact/api';
import { Provider } from 'react-redux';
import { store } from './Store/store';
import { CustomToast } from './components/common/CustomToast';
import { Router } from './routes/Router';
import "./components/commonComponents/font_classes.css";
import "./components/commonComponents/accordion_classes.css";
import "./components/commonComponents/button_classes.css"
        
function App({pageProps}) {
  return (
    <PrimeReactProvider>
      <Provider store={store}>
        <CustomToast/>
      <Router {...pageProps} />
      </Provider>
    </PrimeReactProvider>
  );
}

export default App;
