import React from "react";
// import style from "./delete_Plans.module.css"
import style from "./Delete_Plans.module.css";
import { Dialog } from "primereact/dialog";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { RiDeleteBinLine } from "react-icons/ri";
import { deletePlan } from "../../../../Api/admin";
import Loader from "../../../common/Loader";

export default function DeletePlans({ resumeone, setResumeone,id,fetchPlan }) {
  let { t } = useTranslation();

  const [selectedOption, setSelectedOption] = useState("");
  const [loader, setLoader] = useState(false);

  const handleDropdownChange = (event) => {
    setSelectedOption(event.target.value);
  };

  
  const planDelete = async(subid) =>{
    setLoader(true)
    await deletePlan(subid).then((res)=>{
        // console.log(res)
        setLoader(false)
        setResumeone(false)
        fetchPlan()
    }).catch((err)=>{
        console.log(err)
        setLoader(false)
    })
  }



  return (
    <>
      <div className={style.AuctionForm}>
        <Dialog
          closable={false}
          visible={resumeone}
          className="p-0 m-0"
          onHide={() => setResumeone(false)}
          style={{
            width: "50vw",
            padding: "0px",
            margin: "0px",
            border: "2px solid #187c8b",
          }}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        >
          <div>
            <div className={`d-flex mb-3 text-black`}>
              <div className={`lufga_30px fw_600`}>Delete Plan</div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="d-flex fw_600 lufga_16px">
                  Are you sure you want to delete this subscription plan ?
                </div>
              </div>

              <div className="d-flex mt-4 justify-content-end gap-4">
                <button
                  className={style.cancelBtn}
                  onClick={() => setResumeone(false)}
                >
                  Cancel
                </button>

                <button className={style.SaveButton}  onClick={()=>{planDelete(id)}}>
                  <RiDeleteBinLine /> Delete
                </button>
              </div>
            </div>
          </div>
        </Dialog>
        <Loader open={loader} /> 
      </div>
    </>
  );
}
