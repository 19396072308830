import axios from "axios"
import { auctionService, baseUrl, intendedParent, news } from "./EndPoint"


let token = sessionStorage.getItem("authToken")

let config = {
    headers: {
        Authorization: `Bearer ${token}`,
    }
  }

export async function getAllNews(){
    return await axios.get(`${baseUrl}${news.getNews}`,config)
}


export async function addNews(data){
    return await axios.post(`${baseUrl}${news.addNews}`,data,config)
}


export async function getNewsById(id){
    return await axios.get(`${baseUrl}${news.getNewsById}${id}`,config)
}


export async function updateNews(id,data){
    return await axios.put(`${baseUrl}${news.updateNews}${id}`,data,config)
}


export async function deleteNews(id){
    return await axios.delete(`${baseUrl}${news.deleteNews}${id}`,config)
}