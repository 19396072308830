import React, { useEffect, useState } from "react";
import style from "./ManageAuction.module.css";

import { useNavigate, Outlet } from "react-router-dom";
import { BsThreeDotsVertical } from "react-icons/bs";
import { t } from "i18next";
import { getAllAuctionData } from "../../../Api/admin";
import Loader from "../../common/Loader";
import moment from "moment";
import Pop from "../../commonComponents/popover/popover";
import { PopoverItem } from "../../commonComponents/popovericon/popovericon";
import NoNhpPost from "../../SurrogateComponents/Dashboard/NoAuctionPost/NoNhpPost";
import { ConfirmDelete } from "../../common/ConfirmDelete";
import { deleteAuctionById } from "../../../Api/SurrogateMotherApis";

const ManageAuction = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [allData, setAllData] = useState([]);
  const [resId, setResId] = useState();
  const [visible, setVisible] = useState(false);
  const [filterData, setFilterData] = useState([])


  const userId = sessionStorage.getItem("userId");

  const [cardId, setCardId] = useState()


  async function getAllAuctions() {
    try {
      let res = await getAllAuctionData();
      setAllData(res.data.data.active_Auction);
      setFilterData(res.data.data.active_Auction);
      setLoader(false);
      console.log(res.data.data, "all auctions")
      console.log(res.data.data.active_Auction, "active auctions")
    } catch (err) {
      console.log(err);
      setLoader(false);
    }

  }

  const handleSearchClick = (e) => {
    let val = e.trim().toLowerCase();
    let searchName = filterData.filter((res) => {
      const fullName = (res?.name?.trim().toLowerCase().replace(/\s+/g, '') || '') +
        (res?.memberId?.trim().toLowerCase().replace(/\s+/g, '') || '');
      return fullName.includes(val.replace(/\s+/g, ''));
    });
    setAllData(searchName);
  };

  const deleteAuction = async (id) => {
    setLoader(true);
    try {
      await deleteAuctionById(id);
      getAllAuctions()
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };

  const calculateDateDifference = (startDate, endDate) => {
    const start = moment(startDate);
    const end = moment(endDate);
    const duration = moment.duration(end.diff(start));

    const days = duration.days();
    const hours = duration.hours();

    if (days > 0) {
      return `${days} days ${hours} hours left`;
    } else {
      return `${hours} hours left`;
    }
  };

  useEffect(() => {
    getAllAuctions();
  }, []);

  function checkMyBid(data) {
    let allBids = data?.bids;
    let myhighBid = allBids?.filter((res) => res?.biderId == userId);
    let sortedBids = myhighBid?.sort((a, b) => a?.bid - b?.bid).reverse();
    return sortedBids?.length !== 0
      ? `My highest bid $${sortedBids[0]?.bid}`
      : "";
  }
  // console.log(allData);

  return (
    <div>
      <Loader open={loader} />
      <div className={`${style.header_bar_container} mt-3`}>
        <div className="d-flex justify-content-between align-items-center">
          <div className="lufga_24px fw_600">
            NHP Auction
          </div>
          <div className="d-flex align-items-center gap-3">
            <div className="searchInput w-50">
              <input
                className="form-control ps-5"
                placeholder="Search"
                onChange={(e) => handleSearchClick(e.target.value)}
              ></input>
              <i className="pi pi-search searchIcon"></i>
            </div>
            <div>
              <button className="cancelButton" style={{ whiteSpace: "nowrap" }}
                onClick={() => {
                  navigate("auctionlist")
                }}>
                <i className="pi pi-bars"></i><span className="ms-2">Auction Listing</span></button>
            </div>
            <div>
              <button className="saveButton" onClick={() => {
                navigate("auctioncreatepage")
              }}
                style={{ whiteSpace: "nowrap" }}><span className="me-2 lufga_24px">+</span>Create Auction</button>
            </div>

          </div>
        </div>
      </div>

      {allData.length === 0 ?
        <>
          <div className="w-100 mt-5 d-flex flex-column justify-content-center align-items-center">
            <NoNhpPost />
          </div>
        </> :
        <>
          <div className={`${style.cardContainer} mt-5`}>
            {allData?.map((card, index) => (
              <div key={index} className={`w-100  ${style.maincardContainer}`}>
                <div
                  className={`${style.card_upper}`}
                >
                  <div
                    className={`${style.card_upper_mask}`}
                    style={{
                      backgroundImage: `url('${card?.photoUrl}')`,
                    }}
                  >

                  </div>
                  <img
                    className={`${style.card_upper_image}`}
                    src={card?.photoUrl}
                  />

                  <div className={`${style.upper_v_1}`}>
                    <div className={`${style.upper_v_1_1}`}>
                      <div className={`${style.upper_v_1_1_1}`}>
                        <Pop onClick={(e) => {

                          setResId(card?._id)
                        }}>
                          <PopoverItem
                            text={"Edit"}
                            icon={
                              <img
                                src="/images/edit.png"
                                style={{
                                  height: "19px",
                                  width: "19px",
                                }}
                              />
                            }
                            onClick={() =>
                              navigate(
                                `editpostauction/${card._id}`, { state: { data: card.userId } }
                              )
                            }
                          />
                          <ConfirmDelete onDelete={deleteAuction} data={card?._id}>
                            <PopoverItem
                              text={
                                <span style={{ color: "red " }}>
                                  Delete
                                </span>
                              }
                              icon={
                                <img
                                  src="/images/reddelete.png"
                                  style={{
                                    height: "18px",
                                    width: "18px",
                                  }}
                                />
                              }
                              onClick={() => {
                                setVisible(true);
                                setResId(card?._id);
                              }}
                            />
                          </ConfirmDelete>

                        </Pop>
                      </div>
                    </div>
                  </div>
                  <div className={`${style.upper_v_2}`}>
                    <div className={`${style.upper_v_2_1}`}>
                      End Date- {moment(card.endDate).format("DD/MM/YYYY")}
                    </div>
                  </div>

                </div>

                <div className={`${style.card_lower} d-flex flex-column`}>
                  <div className="d-flex justify-content-between align-items-center py-2">
                    <div
                      className={`${style.lower_v_1} lufga_18px pointer`}
                      onClick={() =>
                        navigate(`auctionResume/${card._id}`, { state: { data: card.highest_Bid } })
                      }
                    >
                      {t(card.name)}
                    </div>
                    {/* <div className={`${style.sBtn}`}>{checkMyBid(card)}</div> */}
                  </div>
                  <div className={`${style.card_lower_v_1} d-flex flex-column mt-auto`}>
                    <div className={`my-4 ${style.sBtn}`}>Surrogate Mother</div>
                    <div className={`${style.lower_v_2}`}>
                      {t(card?.short_description)}
                    </div>
                    <div className="d-flex justify-content-between px-3 mb-1 mt-2">
                      <div className={`${style.Currenttext}`}>Current Bid :</div>
                      <div className={`${style.Dollertext}`}>
                        ${card?.highest_Bid?.bid !== 0 ? card?.highest_Bid?.bid : card.starting_bid_amount}
                      </div>
                    </div>

                    <div className="d-flex justify-content-between px-3 mb-3">
                      <div className={`${style.Currenttext}`}>
                        {card?.buyer?.buyer_name ? <div>Bought At</div> : null}
                      </div>
                      <div className={`${style.Dollertext}`}>
                        {card?.buyer?.buyer_name ?
                          <span>$</span> : null}
                        {card?.buyer?.buyer_name ?
                          card?.buyer?.price : null}
                      </div>
                    </div>

                    <div
                      className={`${style.lower_v_3}`}
                      onClick={(e) => {
                        e.stopPropagation()
                        navigate(`auctionResume/${card._id}`, { state: { data: card.highest_Bid } })
                      }}
                    >
                      View Details
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>}




    </div>
  );
};

export default ManageAuction;
