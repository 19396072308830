import React, { useEffect, useState } from 'react'
import 'primeicons/primeicons.css';

import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { GoQuestion, GoChecklist, GoPerson } from "react-icons/go";
import { use } from 'i18next';
import Verfied from '../IntendedParentsComponent/DialogVerified/Verfied';
import { RxDashboard } from 'react-icons/rx';


const EmbryoDonor = () => {
  const [visibale, setVisibale] = useState(false)
  const { search } = useLocation()
  function navigateToLogin() {
    window.location.href = `https://www.newhopepoints.org/signIn`;
    sessionStorage.clear()
  }

  async function setAuth() {
    if (search !== '') {

    }
  }

  useEffect(() => {
    setAuth()
  }, [])
  /**
   * 
   */

  return (

    <div>
      <Verfied visibale={visibale} setVisibale={setVisibale} />
      <NavLink style={{ textDecoration: "none" }} to={`dashboard`}>
        <div className="navlink">
          <div className="fs-4">
            <RxDashboard style={{ fontSize: '1.3rem', verticalAlign: "unset" }} />
          </div>
          <div>Dashboard</div>
        </div>
      </NavLink>

      <NavLink style={{ textDecoration: "none" }} to={`subscription`}>
        <div className="navlink">
          <div className="fs-4">
            <i className="pi pi-dollar" style={{ fontSize: '1.2rem' }}></i>
          </div>
          <div>Subscription Plan</div>
        </div>
      </NavLink>


      <NavLink style={{ textDecoration: "none" }} to={`search`}>
        <div className="navlink">
          <div className="fs-4">
            {/* <span className=""></span> */}
            <i className="pi pi-search" style={{ fontSize: '1.2rem' }}></i>

          </div>
          <div>Search</div>
        </div>
      </NavLink>


      <NavLink style={{ textDecoration: "none" }} to={`myprofile`}>
        <div className="navlink">
          <div className="fs-4">
            <i className="pi pi-user" style={{ fontSize: '1.2rem' }}></i>
          </div>
          <div>My Profile</div>
        </div>
      </NavLink>

      <hr></hr>

      <NavLink style={{ textDecoration: "none" }} to={`settings`}>
        <div className="navlink">
          <div className="fs-4" style={{ width: '20px' }}>

          </div>
          <div>Settings</div>
        </div>
      </NavLink>

      <NavLink style={{ textDecoration: "none" }} to={`underconstruction`}>
        <div className="navlink" onClick={navigateToLogin}>
          <div className="fs-4" style={{ width: '20px' }}>

          </div>
          <div>Logout</div>
        </div>
      </NavLink>



    </div>
  )
}
export default EmbryoDonor;