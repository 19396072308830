import React, { useEffect, useRef } from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';
import "./popover.css";
import { BsThreeDotsVertical } from "react-icons/bs";

export default function PopOver({ children, item, reset }) {
    const op = useRef(null);

    useEffect(() => {
        let dd = document.querySelector(".children_con")
        if (dd) {
            dd.addEventListener("scroll", () => {
                if (op.current) {
                    op.current.hide()
                }
            })
        }
    }, [])
    
    useEffect(() => {
        if (reset && op.current) {
            op.current.hide();
        }
    }, [reset]);

    const togglePanel = (e) => {
        op.current.toggle(e);
    };

    return (
        <div className=''>
            <button
                className='p-1 three_dot_btn'
                type='button'
                onClick={(e) => togglePanel(e)}
            >
                {item || <BsThreeDotsVertical />}
            </button>
            <OverlayPanel ref={op} appendTo={document.body} className='p-0'>
                {children}
            </OverlayPanel>
        </div>
    );
}
