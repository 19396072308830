import React, { useEffect, useState } from "react";
import style from "./ClientView.module.css";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import Loader from "../../../common/Loader";
import moment from "moment";
import { getClientDataById, editAppointment } from "../../../../Api/IvfClinic";
import AgainAppoint from "../../CalenderComponent/ApointMentDialog/AgainAppoint";
import BackButton from "../../../commonComponents/BackButton/BackButton"

const ClientView = () => {
  let { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const clientId = params.id;

  const [loader, setLoader] = useState(false);
  const [editable, setEditable] = useState(true);
  const [apoint, setApoint] = useState(false);

  const [data, setData] = useState({
    clientId: "",
    name: "",
    appointment_title: "",
    contact_number: "",
    email_address: "",
    appointment_purpose: "",
    client_type: "",
    service_requested: "",
    start_time: "",
    end_time: "",
    date: "",
    location: "",
    important_note: "",
  });

  const getClientDataByIdFunction = async () => {
    try {
      setLoader(true);
      const response = await getClientDataById(clientId);
      console.log(response, "user profile data ")
      const clientData = response.data.data;
      clientData.date = moment(clientData.date).format("YYYY-MM-DD");
      setData(clientData);
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const EditClientDataByIdFunction = async (e) => {
    e.preventDefault();
    try {
      setLoader(true);
      await editAppointment(clientId, data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
      setEditable(true);
    }
  };

  useEffect(() => {
    getClientDataByIdFunction();
  }, []);

  return (
    <>
      <AgainAppoint apoint={apoint} setApoint={setApoint}
        //reload={fetchAppointments}
        clientId={data.clientId}
        appointId={data._id}
      />
      <Loader open={loader} />

     
        <BackButton text="Back" path={-1} />


      <div className={`${style.MyProfile}`}>
        <div className={`mb-5 ${style.bannersize}`}>
          <div className="d-flex">
            <div className="d-flex flex-column align-item-center justify-content-center gap-3 ps-3">
              <div className={style.BnnerFirstText}>
                {data.clientId} : {data.client_type}
              </div>
              <div className={style.BnnersecondtText}>{data.name}</div>
              <div className={`${style.headerButtonGroup} d-flex align-items-center gap-3`}>
                <button className={style.addAppointmentButton} onClick={() => setApoint(true)}><span style={{ fontSize: '1.5rem' }}>+</span> Add Appointment</button>
                <button className={style.appointmentListButton} onClick={() => 
                  navigate(`/ivfclinic/myclient/appointmentlist/${data.clientId}`, { state: { appointmentId: clientId,ClinetName:`${data.name}` } })
                }
                  ><span><i className="pi pi-list" style={{ fontSize: '1rem' }}></i></span> Appointments Detail</button>
                <button className={style.NotificationText} onClick={() =>
                  navigate(`/ivfclinic/myclient/reportlisting/${data.clientId}`, { state: { appointmentId: clientId,ClinetName:`${data.name}` } })
                }
                >View Reports</button>
              </div>
            </div>
          </div>
          <div className={`p-2 ${style.ParentButton}`}>
            <img src="/images/ivf_clinic_my_profile_header_right.png" alt="" />
            <div className={`d-flex justify-content-end ${style.ChlidButton}`}>
              {editable ? (
                <button
                  className={`text-center d-flex align-items-center ${style.VerfiedButton}`}
                  onClick={() => setEditable(false)}
                >
                  <i className="bi bi-pencil-square me-1"></i>
                  <span className={style.verfiedText}>Edit Details</span>
                </button>
              ) : null}
            </div>
          </div>
        </div>
        <div className="Verfied_con">
          <form onSubmit={EditClientDataByIdFunction}>
            <div className="row mt-4">
              <div className="col-md-4">
                <label
                  className={`form-check-label mb-1 ${style.LabelText}`}
                  htmlFor="flexCheckDefault"
                >
                  Name<span className="text-danger">*</span>
                </label>
                <input
                  disabled={true}

                  type="text"
                  className="form-control"
                  placeholder="Enter Your Name"
                  name="name"
                  value={data.name}
                  onChange={onInputChange}
                />
              </div>
              <div className="col-md-4">
                <label
                  className={`form-check-label mb-1 ${style.LabelText}`}
                  htmlFor="flexCheckDefault"
                >
                  Email Address<span className="text-danger">*</span>
                </label>
                <input
                  disabled={true}

                  type="email"
                  className="form-control"
                  placeholder="Enter Your Email Address"
                  name="email_address"
                  value={data.email_address}
                  onChange={onInputChange}
                />
              </div>
              <div className="col-md-4">
                <label
                  className={`form-check-label mb-1 ${style.LabelText}`}
                  htmlFor="flexCheckDefault"
                >
                  Contact Number<span className="text-danger">*</span>
                </label>
                <input
                  disabled={true}

                  type="tel"
                  className="form-control"
                  placeholder="Enter Your Contact Number"
                  name="contact_number"
                  value={data.contact_number}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-6">
                <label
                  className={`form-check-label mb-1 ${style.LabelText}`}
                  htmlFor="flexCheckDefault"
                >
                  Client Type<span className="text-danger">*</span>
                </label>
                <input
                  disabled={true}

                  type="text"
                  className="form-control"
                  placeholder="Client Type"
                  name="client_type"
                  value={data.client_type}
                  onChange={onInputChange}
                />
              </div>
              <div className="col-md-6">
                <label
                  className={`form-check-label mb-1 ${style.LabelText}`}
                  htmlFor="flexCheckDefault"
                >
                  Service Requested<span className="text-danger">*</span>
                </label>
                <input
                  disabled={true}

                  type="text"
                  className="form-control"
                  placeholder="Service Requested"
                  name="service_requested"
                  value={data.service_requested}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12">
                <label
                  className={`form-check-label mb-1 ${style.LabelText}`}
                  htmlFor="flexCheckDefault"
                >
                  Appointment Purpose<span className="text-danger">*</span>
                </label>
                <input
                  disabled={editable}
                  required
                  type="text"
                  className="form-control"
                  placeholder="Appointment Purpose"
                  name="appointment_purpose"
                  value={data.appointment_purpose}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12">
                <label
                  className={`form-check-label mb-1 ${style.LabelText}`}
                  htmlFor="flexCheckDefault"
                >
                  Location
                </label>
                <input
                  disabled={editable}
                  type="text"
                  className="form-control"
                  placeholder="Enter Your Location"
                  name="location"
                  value={data.location}
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="row mt-3 mb-4">
              <div className="col-md-12">
                <label
                  className={`form-check-label mb-1 ${style.LabelText}`}
                  htmlFor="flexCheckDefault"
                >
                  Important Note
                </label>
                <input
                  disabled={editable}
                  type="text"
                  className="form-control"
                  placeholder="Important Note"
                  name="important_note"
                  value={data.important_note}
                  onChange={onInputChange}
                />
              </div>
            </div>
            {editable ? null : (
              <div className="d-flex justify-content-end gap-3">
                <button
                  className={style.CencelButton}
                  type="button"
                  onClick={() => {
                    setEditable(!editable);
                    getClientDataByIdFunction()
                  }}
                >
                  Cancel
                </button>
                <button
                  className={style.SubmitButton}

                  type="submit"
                >
                  Submit
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default ClientView;
