import React from "react";
import style from "../SuperAdminLayout/SubscriptionPlan/delete_Subscription_Plans/Delete_Plans.module.css";
import { Dialog } from "primereact/dialog";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { RiDeleteBinLine } from "react-icons/ri";
import Loader from "./Loader";


export const ConfirmDelete = ({ children, data, onDelete, header, deleteMsg , cancelButtonText, deleteButtonText }) => {

    let [dialog, setDialog] = useState(false);

    const handleDelete = () => {
        setDialog(false); 
        onDelete(data)
        // call the passed onDelete function with data
    };


    return (
        <>
            <div onClick={() => setDialog(true)}>
                {children}
            </div>

            <Dialog
                closable={false}
                visible={dialog}
                className="p-0 m-0"
                onHide={() => setDialog(false)}
                style={{
                    width: "40vw",
                    padding: "0px",
                    margin: "0px",
                    border: "2px solid #187c8b",
                }}
                breakpoints={{ "960px": "75vw", "641px": "100vw" }}
            >
                <div>
                    <div className={`d-flex mb-3 text-black`}>
                        <div className={`lufga_30px fw_600`}>{header || "Delete data"}</div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="d-flex fw_600 lufga_16px">
                                {deleteMsg || "Are you sure you want to delete this data?"}
                            </div>
                        </div>

                        <div className="d-flex mt-4 justify-content-end gap-4">
                            <button
                                className={style.cancelBtn}
                                onClick={() => setDialog(false)}
                            >
                                {cancelButtonText ||"Cancel"}
                            </button>

                            <button className={style.SaveButton} onClick={handleDelete}>
                                <RiDeleteBinLine /> {deleteButtonText ||"Delete"}
                            </button>
                        </div>
                    </div>
                </div>

            </Dialog>


        </>
    )
}
