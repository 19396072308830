import React from "react";
import { Outlet } from "react-router";
import { Sidebar } from "../components/Sidebar/Sidebar";

export const SidebarWrapper = ({role}) => {
  return (
    <Sidebar role={role}>
      <Outlet />
    </Sidebar>
  );
};
