import React, { useEffect, useRef, useState } from "react";
import style from "./MyProfile.module.css";
import { Checkbox } from 'primereact/checkbox';


import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import {
  deleteDocsApi,
  getDashboardUser,
  updateDashboardUser,
  uploadDocument,
  uploadMultipleFiles,
} from "../../../Api/CommonApis";
import { MultiSelect } from "primereact/multiselect";
import {
  addAdoptingParent,
  addIntendedParentFormData,
  deleteDocs,
  getAdoptingParentFormData,
  getIntendedParentsFormData,
  verifyAdoptingParentResume,
  verifyIntendedParentResume
} from "../../../Api/IntendedParent";
import { CiImageOn } from "react-icons/ci";
import Loader from "../../common/Loader";
import { Country, State, City } from "country-state-city";
import { FaArrowLeft, FaPlus } from "react-icons/fa";
import { VscVerifiedFilled } from "react-icons/vsc";
import { MdOutlineFileDownload, MdOutlineVerified } from "react-icons/md";

import { MultipleImageUpload } from "../../commonComponents/MultipleImageUpload/MultipleImageUpload";
import moment from "moment/moment";
import { IoMdAdd } from "react-icons/io";
import { changeHeaderData } from "../../../Store/Slices/headerDataSlice";
import { useDispatch } from "react-redux";
import IntendedParentResume from "../IpResumeDownload/IpResumeDownload";
import VerifyResume from "../../SurrogateComponents/Resume/VerifyResume/VerifyResume";
import { GoVerified } from "react-icons/go";

const EmbryoMyProfile = () => {

  let { t } = useTranslation();


  let params = useParams();
  const paramId = params.id;

  const userId = paramId ? paramId : sessionStorage.getItem("userId");

  // Create a ref to the form container
  const formRef = useRef(null);

  const id = sessionStorage.getItem('userId')

  const role = sessionStorage.getItem('role')

  console.log("this is role", role)





  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [editable, setEditable] = useState(true);

  const [img, setImg] = useState(null);
  const [defaultImg, setDefaultimg] = useState("/images/IpIcon.png");
  let [activeTab, setActiveTab] = useState(0);
  const [activeEditPage, setActiveEditPage] = useState(0);
  const [visible, setVisible] = useState(false);
  const [imgForRemove, setImgForRemove] = useState([]);
  let [imagesValue, setImagesValue] = useState([]);
  const [visibale, setVisibale] = useState(false);
  const [users, setUsers] = useState(null);

  const countries = Country.getAllCountries()
  const [country, setCountry] = useState('')
  const [state, setState] = useState('')
  const [city, setCity] = useState('')
  const [statesListing, setStatesListing] = useState([])
  const [citiesListing, setCitiesListing] = useState([])
  let [stateCode, setStateCode] = useState("");
  const [parentData, setParentData] = useState([]);



  const [selectedRaces, setSelectedRaces] = useState([]);
  const [selectedSpouseRaces, setSelectedSpouseRaces] = useState([]);
  const [selectedChildRaces, setSelectedChildRaces] = useState([]);

  const dispatch = useDispatch()

  const races = [
    { name: "White", code: "W" },
    { name: "Black or African American", code: "B" },
    { name: "American Indian or Alaska Native", code: "AI" },
    { name: "Asian", code: "A" },
    { name: "Native Hawaiian", code: "NH" },
    { name: "Other", code: "O" },
  ];


  const [formData, setFormData] = useState({
    parent_photos: [
      {
        fileName: "",
        fileUrl: "",
      },
    ],
    photo_resume_index: 0,
    full_name: "",
    not_show_on_public_profile_full_name: false,
    date_of_birth: "",
    gender: "",
    race: [],
    spouse_name: "",
    not_show_on_public_profile_spouse_name: false,
    spouse_date_of_birth: "",
    spouse_gender: "",
    spouse_race: [],
    street_address: "",
    not_show_on_public_profile_street_address: false,
    city: "",
    state_of_residence: "",
    country: "",
    zip_code: "",
    date_of_legal_marriage: "",
    your_highest_degree_of_education: "",
    your_spouse_highest_degree_of_education: "",
    religion: "",
    your_occupation: "",
    spouse_occupation: "",
    total_income: "",
    briefly_describe_your_reason_for_wanting_to_adopt: "",
    tell_us_about_any_children_you_currently_have: "",
    kid_birth_status: "",
    tell_us_about_your_self: "",
    tell_us_about_your_spouse: "",
    tell_us_about_your_relationship: "",
    tell_us_about_your_extended_family: "",
    tell_us_about_adventure_you_do_in_your_free_time: "",
    tell_us_about_tradition: "",
    tell_us_about_home: "",
    tell_us_about_your_and_your_spouse_hobbies: "",
    your_favorites: {
      hobby: "",
      movie: "",
      sport: "",
      holiday: "",
      book: "",
      subject: "",
      tradition: "",
      destination: "",
      job: "",
      food: "",
      music_type: "",
      song: "",
      celebrity: "",
      moment: "",
      author: "",
    },
    your_spouse_favorites: {
      hobby: "",
      movie: "",
      sport: "",
      holiday: "",
      book: "",
      subject: "",
      tradition: "",
      destination: "",
      job: "",
      food: "",
      music_type: "",
      song: "",
      celebrity: "",
      moment: "",
      author: "",
    },
    child_preferences: {
      age_range: {
        min: "",
        max: "",
      },
      gender: "",
      special_need: "",
      race_of_child: "",
    },
  }
  )


  async function getFormdata(id) {
    try {
      console.log("trying to get Data")
      let res = await (
        role === "parents"
          ? getIntendedParentsFormData(id)
          : getAdoptingParentFormData(id)
      );
      console.log("this is res", res)
      setUsers(pre => { return { ...pre, ...res.data.data } });
      console.log("setting users in getFormdata", users)
      const { race, spouse_race, child_preferences } = res.data.data;
      const { race_of_child } = child_preferences;
      setSelectedRaces(race);
      setSelectedSpouseRaces(spouse_race);
      setSelectedChildRaces(race_of_child);
      setParentData(res.data.data);
      setFormData({ ...formData, ...res.data.data });


      if (res.data.data.country !== undefined) {
        let cRes = countries?.find(res2 => res2?.name == res.data.data.country)
        setCountry(`${cRes.name}&&${cRes.isoCode}`)
        getStates(`${cRes.name}&&${cRes.isoCode}`)
      }
      if (res.data.data.city !== undefined) {
        setCity(res.data.data.city)
      }

    } catch (error) {
      console.log(error);
    }
  }

  function getStates(res) {
    let isoCode = res.split('&&')[1]
    setCountry(res)
    let states = State.getStatesOfCountry(isoCode)
    setStatesListing(states)
  }




  function getCities(res) {
    let isoCode = res.split('&&')[1]
    let cCode = res.split('&&')[2]
    setState(res)
    let cities = City.getCitiesOfState(cCode, isoCode)
    setCitiesListing(cities)
  }


  function multipleImageInputChange(e) {
    console.log(e, "aaaaaaa");
    setImagesValue(e);
  }


  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };


  const handleFavoritesChange = (e, type) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [type]: {
        ...prevFormData[type],
        [name]: value,
      },
    }));
  };

  function radioButtonChange(e, val) {
    let { name } = e.target;
    setFormData((pre) => {
      pre[name] = val;
      return { ...pre };
    });
  }



  const handleChildChange = (e) => {
    const { name, value } = e.target;
    console.log("target", e.target)
    const nameParts = name.split(".");
    console.log("nameparts", nameParts)

    setFormData((prevFormData) => {
      const newFormData = { ...prevFormData };
      let current = newFormData;

      for (let i = 0; i < nameParts.length - 1; i++) {
        if (!current[nameParts[i]]) {
          current[nameParts[i]] = {}; // Initialize the object if it does not exist
        }
        current = current[nameParts[i]];
      }

      current[nameParts[nameParts.length - 1]] = value;
      return newFormData;
    });
  };


  async function handleSubmit(e) {
    e.preventDefault();

    setLoader(true);
    try {
      let img = imagesValue;
      if (imagesValue.length == 0) {
        alert(t("please select images"));
        setLoader(false);
        return;
      }
      if (imgForRemove.length > 0) {
        let imagesData = await Promise.all(
          imgForRemove.map(
            async (e) => await deleteDocsApi({ fileName: e.fileName })
          )
        );
      }

      let httpimage = [];
      let formData2 = new FormData();
      let nonhttpImage = [];
      img.forEach((e) => {
        if (e?.fileUrl?.includes("blob")) {
          nonhttpImage.push(e);
        } else {
          httpimage.push({
            fileName: e.fileName,
            fileUrl: e.fileUrl,
          });
        }
      });
      for (const item of nonhttpImage.map((e) => e.file)) {
        formData2.append("uploadMultiDocs", item);
      }
      let uploadResponse = await uploadMultipleFiles(formData2);
      let res2 = {
        ...formData,
        parent_photos: [...httpimage, ...uploadResponse.data.data],
        race: selectedRaces,
        spouse_race: selectedSpouseRaces,
        child_preferences: {
          ...formData.child_preferences,
          race_of_child: selectedChildRaces,
        },
        city: city.split('&&')[0],
        state_of_residence: state.split('&&')[0],
        country: country.split('&&')[0],
      };


      console.log("api hit start");
      let res;
      if (role === "parents") {
        res = await addIntendedParentFormData(id, res2);
      } else {
        res = await addAdoptingParent(id, res2);
      }

      console.log("api hit end")


      if (img !== null) {
        uploadEmbUserImg()
      }


      setLoader(false);




    } catch (error) {
      console.log(error);
    } finally {
      setEditable(true)
      setLoader(false);
    }
  }




  const [imageSrc, setImageSrc] = useState(null);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      setImageSrc(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };




  const [dashboardUserData, setDashboardUserData] = useState({
    "fullName": "",
    "mobile_No": "",
    "email": "",
    "mobile_No_alter": "",
    "email_alter": "",
    "imageUrl": "",
    "imageFileName": "",
    "country": "",
    "address": "",
    "city": "",
    "state": "",
    "zip_code": "",
    "textSMS_mobile": true,
    "textSMS_mobile_alter": true
  })





  async function verifyResume(e) {
    e.preventDefault()
    let payload = {
      "verify_resume": {
        "price": "99",
        "status": true
      }
    }
    setLoader(true)

    try {
      await (
        role === "parents"
          ? verifyIntendedParentResume(payload , id)
          : verifyAdoptingParentResume(payload, id)
      );

    } catch (err) {
      console.log(err)
    } finally {
      setVisibale(false)
      await getFormdata(id)
      setLoader(false)
    }

  }



  async function getUserDataById() {
    try {
      let res = await getDashboardUser(userId);

      setDashboardUserData(res.data.data)

      setUsers(pre => {
        return {
          ...pre
          , fullName: res.data.data.fullName,
          email: res.data.data.email
        }
      });
      console.log("setting users in get user data by id", users)
    }
    catch (error) {
      console.log(error, "this is error while getting data of user in profile of Intended Parents")
    }
  }




  /**
   * This Function wil help the user to get the url for a puicture in case when he/she trying to set a new picture as user profile , and to see the picture on real time we have to convert the pic in the url .
   */
  function getImgPreview(file) {
    let url = null;
    if (file && file.name) {
      url = URL.createObjectURL(file);
    }
    else {
      url = file
    }
    return url;
  }




  /**
   * This function is to set the image for the whole user on the top layer , because by default user is not getting any photo of their account
   */
  const uploadEmbUserImg = async () => {
    if (img == null) {
      return
    }
    let payload = {
      ...dashboardUserData,
    }
    let formdata = new FormData()
    if (img !== null) {
      formdata.append('uploadDoc', img)
    }

    setLoader(true)
    try {
      if (img !== null) {
        let imageData = await uploadDocument(formdata)
        payload.imageFileName = imageData.data.fileName
        payload.imageUrl = imageData.data.fileLocation
      }
      let res = await updateDashboardUser(userId, payload)
      setImg(null)

      await getUserDataById();

      let responseForData = await getDashboardUser(userId)

      setEditable(true)
      setLoader(false)

      dispatch(
        changeHeaderData({
          IntendedParentName: responseForData?.data?.data?.fullName,
          IntendedParentPhoto: responseForData?.data?.data?.imageUrl,
        })
      );

    } catch (err) {
      setLoader(false)
      console.log(err)
    }

  }









  /**
   * This useEffect is to set the height of all textarea according to the length of the content , because initially the height of textarea is 1 row and for the design requirement we have to set it as the length of data which we get from api
   */

  useEffect(() => {
    if (!formRef.current) {
      console.warn("Form reference is not set yet.");
      return;
    }

    // Get all textarea elements within the form
    const textareas = formRef.current.querySelectorAll("textarea");
    textareas.forEach((textarea) => {
      adjustTextareaHeight(textarea);
    });
  }, [formData, activeTab]);


  const adjustTextareaHeight = (textarea) => {
    textarea.style.height = 'auto'; // Reset the height
    textarea.style.height = `${textarea.scrollHeight}px`; // Set height to scrollHeight
  };






  function EditData(page) {
    if (activeTab == 0) {
      setActiveEditPage(0);
      setEditable(false);
    }
    else {
      setActiveEditPage(1);
      setEditable(true);
    }
  }


  /**
   * This useEffect is to run two functions on the load of the page one is for user data and other is to get the resume data of the user
   */
  useEffect(() => {
    getFormdata(userId)
    getUserDataById();
  }, []);



  /**
   * This useEffect is used to set pictures which user gets from api of response in the setImageValue because this is the state which is used in the multiselect to get and set the multiple pictures.
   */

  useEffect(() => {

    if (formData?.parent_photos) {

      let photoArr = formData?.parent_photos?.map((res, index) => {
        return {
          ...res,
          fileType: "server",
        };
      });

      setImagesValue(photoArr);
    }
  }, [formData]);



  useEffect(() => {
    if (statesListing.length !== 0) {
      let sRes = statesListing?.find(res2 => res2?.name == formData.state_of_residence)
      if (sRes !== undefined) {
        setState(`${sRes.name}&&${sRes.isoCode}&&${sRes.countryCode}`)
        getCities(`${sRes.name}&&${sRes.isoCode}&&${sRes.countryCode}`)
      }
    }

  }, [statesListing])


  useEffect(() => {
    if (parentData?.parent_photos) {
      let photoArr = parentData?.parent_photos?.map((res, index) => {
        return {
          ...res,
          fileType: "server",
        };
      });
      console.log(photoArr, "lllllllllllllllllllllllll");
      setImagesValue(photoArr);
    }
  }, [parentData]);



  return (
    <>
      {!params?.id ? null : (
        <div className="mt-4">
          <div
            className="d-flex align-items-center gap-2 btn-Secondary p-0 pointer"
            onClick={() => navigate(-1)}
          >
            <FaArrowLeft /> Back
          </div>
        </div>
      )}



      <Loader open={loader} />
      <div className={style.MyProfile}>


        <div className={` ${style.bannersize} px-3`}>
          <div className="d-flex align-items-center gap-3">

            {editable ? (
              <>
                <div className={`${style.ProfileImg1}  mt-3`}>
                  <img src={dashboardUserData?.imageUrl
                    ? (dashboardUserData?.imageUrl || "/images/Ipicon.png")
                    : (formData?.mother_photos?.[0]?.fileUrl || "/images/ivfclinichospitalimage.png")} className='w-100 h-100' alt="intended parent" />
                </div>
              </>
            ) : (
              <>

                <label className={`${style.ProfileImg1} pointer border mt-3`} >
                  <div className={`${style.inputIcon}`}>
                    <CiImageOn />
                  </div>
                  <img src={dashboardUserData?.imageUrl && img == null
                    ? dashboardUserData.imageUrl
                    : defaultImg == '/images/IpIcon.png'
                      ? defaultImg
                      : getImgPreview(defaultImg)}
                    htmlFor='imgInp' className='w-100 h-100' alt="" />

                  <input type='file' hidden id='imgInp' onChange={(e) => {
                    setDefaultimg(e.target.files[0])
                    setImg(e.target.files[0])
                  }} />
                </label>

              </>
            )}



            <div className={`d-flex flex-column align-item-center justify-content-center gap-4 mx-3`}>
              <div className={style.BnnerFirstText}>
                <span> {dashboardUserData?.memberId} </span> : {role === "parents" ? <span>Intended Parent</span> : <span>Adopting Parent</span>}
              </div>

              <div className={style.BnnersecondtText}>

                {dashboardUserData?.fullName}
              </div>


              <div className="d-flex gap-3">
                <button
                  className=
                  {`${style.VerifyResumeButton} ${users?.verify_resume !== undefined
                    ? users?.verify_resume?.status
                      ? style.verified : ''
                    :
                    ''}`
                  }
                  onClick={() => users?.verify_resume !== undefined
                    ? users?.verify_resume?.status
                      ? {}
                      : setVisibale(true)
                    : setVisibale(true)}
                >
                  {users?.verify_resume !== undefined ? users?.verify_resume?.status
                    ? <><GoVerified className="fs-5" /> Verified</>
                    : 'Verify Resume' : 'Verify Resume'}

                </button>
              </div>



            </div>
          </div>
          <div className={`p-3 p-relative ${style.ProfileImg}`}>
            <img src="/images/EmbryoRightImg.png" className={`w-100`} alt="" />
          </div>
        </div>

        {/* Edit Details Section */}

        <div className={`${style.tabs_container} mb-5`}>

          <div onClick={() => {
            setActiveTab(0)
            setActiveEditPage(0);
            // setEditable(true);
          }} className={`${style.navLink} ${activeTab === 0 ? style.active : ""}`}>
            Resume
          </div>

          <div className={`ms-auto me-3 d-flex gap-3 align-items-center`}>

            <div>
              <IntendedParentResume>
                <button className={style.v_download_btn}>
                  <MdOutlineFileDownload />  Download Profile
                </button>
              </IntendedParentResume>
            </div>

            <div>

              {editable && activeEditPage == 0 ? (
                <div
                  className={`d-flex justify-content-end m-auto ${style.ChlidButton}`}
                >
                  <button
                    className={`text-center d-flex align-items-center ${style.VerfiedButton}`}
                    onClick={EditData}
                  >
                    <i class="bi bi-pencil-square me-1 d-flex align-items-center"></i>
                    <span className={style.verfiedText}>Edit Details</span>
                  </button>
                </div>
              ) : (

                ""
              )}
            </div>
          </div>
        </div>


        <div className={`${style.wrapper_for_Addopting_parent} wrapper_for_Addopting_parent`} ref={formRef}>
          <form onSubmit={handleSubmit}>
            <div className="primary_resume_main_con">


              <MultipleImageUpload
                disabled={editable}
                value={imagesValue}
                onChange={multipleImageInputChange}
                maximg={5}
                setDeletedImage={setImgForRemove}
              />

              <div className="Form2Parent row">
                <div className="col-sm-6 col-xxl-3 mt-4 d-flex flex-column">
                  <label
                    htmlFor="exampleInputEmail1"
                    className="form-label LabelHeading"
                  >
                    {t("Full Name")}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    disabled={editable}
                    type="text"
                    required
                    className="form-control mt-auto"
                    placeholder="Enter Your Name"
                    name="full_name"
                    value={formData?.full_name}
                    onChange={handleChange}
                  />
                </div>

                <div className="col-sm-6 col-xxl-3 mt-4 d-flex flex-column">
                  <label className="form-label labelHeadin">
                    {t("Date of Birth")}:<span className="text-danger">*</span>
                  </label>
                  <input
                    disabled={editable}
                    required
                    type="date"
                    className="form-control mt-auto"
                    placeholder="Enter Your DOB"
                    max={moment().format("YYYY-MM-DD")}
                    name="date_of_birth"
                    value={formData?.date_of_birth}
                    onChange={handleChange}
                  />
                </div>

                <div className="col-sm-6 col-xxl-3 mt-4 d-flex flex-column">
                  <label
                    htmlFor="exampleInputEmail1"
                    className="form-label LabelHeading"
                  >
                    {t("Gender")}
                    <span className="text-danger">*</span>
                  </label>

                  <div className="mt-auto">
                    <div className="row">
                      <div className="col-6">
                        <div className="form-check">
                          <input
                            disabled={editable}
                            className="form-check-input"
                            type="radio"
                            required
                            // name="flexRadioDefault"
                            id="flexRadioDefault1"
                            value="Male"
                            name="gender"
                            checked={formData?.gender === "Male"}
                            onChange={(e) => radioButtonChange(e, e.target.value)}
                          />
                          <label
                            className="form-check-label LabeSubHeading "
                            htmlFor="flexRadioDefault1"
                          >
                            {t("Male")}
                          </label>
                        </div>
                      </div>

                      <div className="col-6">
                        <div className="form-check">
                          <input
                            disabled={editable}
                            className="form-check-input"
                            type="radio"
                            required
                            // name="flexRadioDefault"
                            id="flexRadioDefault1"
                            name="gender"
                            value="female"
                            checked={formData?.gender === "female"}
                            onChange={(e) => radioButtonChange(e, e.target.value)}
                          />
                          <label
                            className="form-check-label LabeSubHeading "
                            htmlFor="flexRadioDefault1"
                          >
                            {t("Female")}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 col-xxl-3 mt-4 d-flex flex-column">
                  <label
                    htmlFor="exampleInputEmail1"
                    className="form-label LabelHeading"
                  >
                    {t("Race")} <span className="text-danger">*</span>
                  </label>

                  <MultiSelect
                    disabled={editable}
                    value={selectedRaces}
                    required
                    onChange={(e) => {
                      setSelectedRaces(e.value);
                      // console.log(e.value);
                    }}
                    options={races}
                    optionLabel="name"
                    display="chip"
                    showSelectAll={false}
                    closeIcon={false}
                    placeholder="Select Your Race"
                    maxSelectedLabels={5}
                    className="multiSelectDropDown ps-2"
                    name="race"
                  />
                </div>

                <div className="col-md-12 mt-4">
                  <div className=" form-check">
                    <input
                      disabled={editable}
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck1"
                      name="not_show_on_public_profile_full_name"
                      value={formData?.not_show_on_public_profile_full_name}
                      checked={
                        formData?.not_show_on_public_profile_full_name === true
                      }
                      onChange={handleChange}
                    />
                    <label
                      className="form-check-label LabeSubHeading"
                      htmlFor="exampleCheck1"
                    >
                      {t(" Not Shown on Public Profile")}
                    </label>
                  </div>
                </div>

                <div className="col-sm-6 col-xxl-3 mt-4 d-flex flex-column">
                  <label
                    htmlFor="exampleInputEmail1"
                    className="form-label LabelHeading"
                  >
                    {t("Spouse Name")}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    disabled={editable}
                    type="text"
                    required
                    className="form-control mt-auto"
                    placeholder="Enter Your Name"
                    name="spouse_name"
                    value={formData?.spouse_name}
                    onChange={handleChange}
                  />
                </div>

                <div className="col-sm-6 col-xxl-3 mt-4 d-flex flex-column">
                  <label className="form-label labelHeadin">
                    {t("Spouse Date of Birth")}:
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    disabled={editable}
                    required
                    type="date"
                    className="form-control mt-auto"
                    placeholder="Enter Your DOB"
                    max={moment().format("YYYY-MM-DD")}
                    name="spouse_date_of_birth"
                    // value={moment().format("YYYY-MM-DD")}
                    value={formData?.spouse_date_of_birth}
                    onChange={handleChange}
                  />
                </div>

                <div className="col-sm-6 col-xxl-3 mt-4 d-flex flex-column">
                  <label
                    htmlFor="exampleInputEmail1"
                    className="form-label LabelHeading"
                  >
                    {t("Spouse Gender")}
                    <span className="text-danger">*</span>
                  </label>

                  <div className="mt-auto">
                    <div className="row">
                      <div className="col-6">
                        <div className="form-check">
                          <input
                            disabled={editable}
                            className="form-check-input"
                            type="radio"
                            required
                            // name="flexRadioDefault"
                            id="flexRadioDefault1"
                            value="Male"
                            name="spouse_gender"
                            checked={formData?.spouse_gender === "Male"}
                            onChange={(e) => radioButtonChange(e, e.target.value)}
                          />
                          <label
                            className="form-check-label LabeSubHeading "
                            htmlFor="flexRadioDefault1"
                          >
                            {t("Male")}
                          </label>
                        </div>
                      </div>

                      <div className="col-6">
                        <div className="form-check">
                          <input
                            disabled={editable}
                            className="form-check-input"
                            type="radio"
                            required
                            // name="flexRadioDefault"
                            id="flexRadioDefault1"
                            name="spouse_gender"
                            value="Female"
                            checked={formData?.spouse_gender === "Female"}
                            onChange={(e) => radioButtonChange(e, e.target.value)}
                          />
                          <label
                            className="form-check-label LabeSubHeading "
                            htmlFor="flexRadioDefault1"
                          >
                            {t("Female")}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 col-xxl-3 mt-4 d-flex flex-column">
                  <label
                    htmlFor="exampleInputEmail1"
                    className="form-label LabelHeading"
                  >
                    {t("Spouse Race")} <span className="text-danger">*</span>
                  </label>

                  <MultiSelect
                    disabled={editable}
                    value={selectedSpouseRaces}
                    required
                    onChange={(e) => {
                      setSelectedSpouseRaces(e.value);
                      // console.log(e.value);
                    }}
                    options={races}
                    optionLabel="name"
                    display="chip"
                    showSelectAll={false}
                    closeIcon={false}
                    placeholder="Select Spouse Race"
                    maxSelectedLabels={5}
                    className="multiSelectDropDown ps-2"
                    name="race"
                  />
                </div>

                <div className="col-md-12 mt-4">
                  <div className=" form-check">
                    <input
                      disabled={editable}
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck1"
                      name="not_show_on_public_profile_spouse_name"
                      value={formData?.not_show_on_public_profile_spouse_name}
                      checked={
                        formData?.not_show_on_public_profile_spouse_name === true
                      }
                      onChange={handleChange}
                    />
                    <label
                      className="form-check-label LabeSubHeading"
                      htmlFor="exampleCheck1"
                    >
                      {t(" Not Shown on Public Profile")}
                    </label>
                  </div>
                </div>

                {/* Third Row - Street Address */}

                <div className="col-md-12 mt-4">
                  <label
                    htmlFor="exampleInputEmail1"
                    className={`form-label ${style.LabelHeading}`}
                  >
                    {t("Street Address")}
                    <span className="text-danger">*</span>
                  </label>

                  <input
                    disabled={editable}
                    type="text"
                    className={`form-control`}
                    placeholder="Enter your address"
                    required
                    value={formData?.street_address}
                    name="street_address"
                    onChange={handleChange}
                  />
                </div>

                <div className="col-md-12 mt-4">
                  <div className=" form-check">
                    <input
                      disabled={editable}
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck1"
                      name="not_show_on_public_profile_street_address"
                      value={formData?.not_show_on_public_profile_street_address}
                      checked={
                        formData?.not_show_on_public_profile_street_address ===
                        true
                      }
                      onChange={handleChange}
                    />
                    <label
                      className="form-check-label LabeSubHeading"
                      htmlFor="exampleCheck1"
                    >
                      {t(" Not Shown on Public Profile")}
                    </label>
                  </div>
                </div>

                {/* Fourth Row - City , State of Residence, Country, Zip Code */}




                <div className="col-sm-6 col-xxl-3 mt-4">

                  <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`}>{t("Country")}<span className='text-danger'>*</span> </label>
                  <select
                    disabled={editable} className={`form-select`} value={country} required onChange={(e) => {
                      getStates(e.target.value)
                      setState('')
                      setCity('')
                    }}>
                    <option selected disabled value={''}>{t("select")}</option>
                    {countries.map((res, index) => {
                      return <option key={index} value={`${res.name}&&${res.isoCode}`}>{res.name}</option>
                    })}
                  </select>

                </div>



                <div className="col-sm-6 col-xxl-3 mt-4">

                  <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`} >{t("State of Residence")}</label>
                  <select
                    disabled={editable} className={`form-select`} value={state} onChange={(e) => {
                      getCities(e.target.value)
                      setCity('')
                    }} >
                    <option selected disabled value={''}>{t("select")}</option>
                    {statesListing?.map((res, index) => {
                      return <option key={index} value={`${res.name}&&${res.isoCode}&&${res.countryCode}`}>{res.name}</option>
                    })}

                  </select>



                </div>


                <div className="col-sm-6 col-xxl-3 mt-4">

                  <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`}>{t("City")}</label>
                  <select
                    disabled={editable} className={`form-select`} value={city} onChange={(e) => setCity(e.target.value)} >
                    <option selected disabled value={''}>{t("select")}</option>
                    {citiesListing?.map((res, index) => {
                      return <option key={index} value={`${res.name}`}>{res.name}</option>
                    })}

                  </select>


                </div>



                <div className="col-sm-6 col-xxl-3 mt-4">
                  <label
                    htmlFor="exampleInputEmail1"
                    className={`form-label ${style.LabelHeading}`}
                  >
                    {t("Zip Code")}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    disabled={editable}
                    type="number"
                    className={`form-control`}
                    required
                    placeholder={t("")}
                    // // // disabled={editable}
                    value={formData?.zip_code}
                    name="zip_code"
                    onChange={handleChange}
                  />
                </div>

                {/* 5th - Date of legal Marriage,....... */}
                <div className="col-sm-6 col-xxl-3 mt-4">
                  <label
                    htmlFor="exampleInputEmail1"
                    className={`form-label  ${style.LabelHeading}`}
                  >
                    {t("Date of legal Marriage")}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    disabled={editable}
                    type="date"
                    className={`form-control`}
                    placeholder="Enter Date"
                    max={moment().format("YYYY-MM-DD")}
                    value={moment(formData?.date_of_legal_marriage).format("YYYY-MM-DD")}
                    onChange={handleChange}
                    required
                    name="date_of_legal_marriage"
                  />
                </div>

                <div className="col-sm-6 col-xxl-3 mt-4">
                  <label
                    htmlFor="exampleInputEmail1"
                    className={`form-label  ${style.LabelHeading}`}
                  >
                    {t("Your Highest Degree of Education")}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    disabled={editable}
                    type="text"
                    required
                    className={`form-control`}
                    placeholder="Enter Highest Degree"
                    name="your_highest_degree_of_education"
                    value={formData?.your_highest_degree_of_education}
                    onChange={handleChange}
                  />
                </div>

                <div className="col-sm-8 col-xxl-4 mt-4">
                  <label
                    htmlFor="exampleInputEmail1"
                    className={`form-label  ${style.LabelHeading}`}
                  >
                    {t("Your Spouse's Highest Degree of Qualification")}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    disabled={editable}
                    type="text"
                    required
                    className={`form-control`}
                    placeholder="Enter Spouse Highest Degree"
                    name="your_spouse_highest_degree_of_education"
                    value={formData?.your_spouse_highest_degree_of_education}
                    onChange={handleChange}
                  />
                </div>

                <div className="col-sm-4 col-xxl-2 mt-4">
                  <label
                    htmlFor="exampleInputEmail1"
                    className={`form-label  ${style.LabelHeading}`}
                  >
                    {t("Religion")}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    disabled={editable}
                    type="text"
                    required
                    className={`form-control`}
                    name="religion"
                    placeholder="Enter Your Religion"
                    value={formData?.religion}
                    onChange={handleChange}
                  />
                </div>

                {/* 6th Row - Your Occupation , Spouse Occuptaion , Total Income */}

                <div className="col-md-4 mt-4">
                  <label className={`form-label  ${style.LabelHeading}`}>
                    {t("Your Occupation")}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    disabled={editable}
                    type="text"
                    required
                    placeholder="Enter Occupation"
                    className="form-control"
                    name="your_occupation"
                    value={formData?.your_occupation}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-4 mt-4">
                  <label className={`form-label  ${style.LabelHeading}`}>
                    {t("Spouse Occupation")}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    disabled={editable}
                    type="text"
                    required
                    placeholder={t("Enter Spouse Occupation")}
                    className="form-control"
                    name="spouse_occupation"
                    value={formData?.spouse_occupation}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-4 mt-4">
                  <label className={`form-label  ${style.LabelHeading}`}>
                    {t("Total Income($)")}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    disabled={editable}
                    type="number"
                    required
                    placeholder={t("Enter Your Income")}
                    className="form-control"
                    name="total_income"
                    value={formData?.total_income}
                    onChange={handleChange}
                  />
                </div>

                {/* 7th Row - Breifly describe the reasons for wanting to adapt */}

                <div className="col-md-12 mt-4">
                  <label
                    htmlFor="exampleInputEmail1"
                    className={`form-label  ${style.LabelHeading}`}
                  >
                    {t("Breifly describe the reasons for wanting to adopt")}
                    <span className="text-danger">*</span>
                  </label>

                  <input
                    disabled={editable}
                    type="text"
                    className={`form-control`}
                    placeholder={t(
                      "Breifly describe the reasons for wanting to adopt"
                    )}
                    required
                    value={
                      formData?.briefly_describe_your_reason_for_wanting_to_adopt
                    }
                    name="briefly_describe_your_reason_for_wanting_to_adopt"
                    onChange={handleChange}
                  />
                </div>

                {/* 8th Row - Tell us about any children .......... */}

                <div className="col-md-8 mt-4">
                  <label
                    htmlFor="exampleInputEmail1"
                    className={`form-label  ${style.LabelHeading}`}
                  >
                    {t(
                      "Tell us about any children you currently have, including their age"
                    )}
                    <span className="text-danger">*</span>
                  </label>

                  <input
                    disabled={editable}
                    type="text"
                    className={`form-control`}
                    placeholder={t("Enter the Name and Age of your children")}
                    required
                    value={
                      formData?.tell_us_about_any_children_you_currently_have
                    }
                    name="tell_us_about_any_children_you_currently_have"
                    onChange={handleChange}
                  />
                </div>

                <div className="col-md-4 mt-4 d-flex flex-column">
                  <label
                    htmlFor="exampleInputEmail1"
                    className={`form-label  ${style.LabelHeading}`}
                  >
                    {t("Kids Birth Status")}
                    <span className="text-danger">*</span>
                  </label>
                  <select
                    disabled={editable}
                    className={`form-select mt-auto`}
                    value={formData?.kid_birth_status}
                    name="kid_birth_status"
                    required
                    onChange={handleChange}
                  >
                    <option selected disabled value={""}>
                      {t("Select")}
                    </option>
                    <option value="Biological Children">Biological Children</option>
                    <option value="Adopted Children">Adopted Children</option>
                    <option value="Both Biological and Adopted Children">Both Biological and Adopted Children</option>
                    <option value="No Children">No Children</option>
                  </select>
                </div>

                {/* 9th Row - Tell us about yourself */}

                <div className="col-md-12 mt-4">
                  <label
                    htmlFor="exampleInputEmail1"
                    className={`form-label  ${style.LabelHeading}`}
                  >
                    {t("Tell about yourself")}
                    <span className="text-danger">*</span>
                  </label>

                  <textarea
                    disabled={editable}
                    type="text"
                    rows={1}
                    className={`form-control mt-auto`}
                    placeholder={t("Enter details about yourself")}
                    required
                    value={formData?.tell_us_about_your_self}
                    name="tell_us_about_your_self"
                    onChange={handleChange}
                  />
                </div>

                {/* 10th Row */}

                <div className="col-md-12 mt-4">
                  <label
                    htmlFor="exampleInputEmail1"
                    className={`form-label  ${style.LabelHeading}`}
                  >
                    {t("Tell us about your spouse")}
                    <span className="text-danger">*</span>
                  </label>

                  <textarea
                    disabled={editable}
                    type="text"
                    rows={1}
                    className={`form-control`}
                    placeholder={t("Enter details about your spouse")}
                    required
                    value={formData?.tell_us_about_your_spouse}
                    name="tell_us_about_your_spouse"
                    onChange={handleChange}
                  />
                </div>

                {/* 11th Row */}

                <div className="col-md-12 mt-4">
                  <label
                    htmlFor="exampleInputEmail1"
                    className={`form-label  ${style.LabelHeading}`}
                  >
                    {t("Tell us about your relationship")}
                    <span className="text-danger">*</span>
                  </label>

                  <textarea
                    disabled={editable}
                    type="text"
                    rows={1}
                    className={`form-control mt-auto`}
                    placeholder={t("Enter details about your relationship")}
                    required
                    value={formData?.tell_us_about_your_relationship}
                    name="tell_us_about_your_relationship"
                    onChange={handleChange}
                  />
                </div>

                {/* 12th Row */}

                <div className="col-md-12 mt-4">
                  <label
                    htmlFor="exampleInputEmail1"
                    className={`form-label  ${style.LabelHeading}`}
                  >
                    {t("Tell us about your extended family")}
                    <span className="text-danger">*</span>
                  </label>

                  <textarea
                    disabled={editable}
                    type="text"
                    rows={1}
                    className={`form-control mt-auto`}
                    placeholder={t("Enter details about your extended family")}
                    required
                    value={formData?.tell_us_about_your_extended_family}
                    name="tell_us_about_your_extended_family"
                    onChange={handleChange}
                  />
                </div>

                {/* 13th Row */}

                <div className="col-md-12 mt-4">
                  <label
                    htmlFor="exampleInputEmail1"
                    className={`form-label  ${style.LabelHeading}`}
                  >
                    {t("Tell us about your adventures you do in your free time")}
                    <span className="text-danger">*</span>
                  </label>

                  <textarea
                    disabled={editable}
                    type="text"
                    rows={1}
                    className={`form-control mt-auto`}
                    placeholder={t("Enter details about your adventures")}
                    required
                    value={
                      formData?.tell_us_about_adventure_you_do_in_your_free_time
                    }
                    name="tell_us_about_adventure_you_do_in_your_free_time"
                    onChange={handleChange}
                  />
                </div>

                {/* 14th Row */}

                <div className="col-md-12 mt-4">
                  <label
                    htmlFor="exampleInputEmail1"
                    className={`form-label  ${style.LabelHeading}`}
                  >
                    {t("Tell us about tradition, holidays and celebrations")}
                    <span className="text-danger">*</span>
                  </label>

                  <textarea
                    disabled={editable}
                    type="text"
                    rows={1}
                    className={`form-control mt-auto`}
                    placeholder={t(
                      "Enter details about your tradition, holidays and celebrations"
                    )}
                    required
                    value={formData?.tell_us_about_tradition}
                    name="tell_us_about_tradition"
                    onChange={handleChange}
                  />
                </div>

                {/* 15th Row */}

                <div className="col-md-12 mt-4">
                  <label
                    htmlFor="exampleInputEmail1"
                    className={`form-label  ${style.LabelHeading}`}
                  >
                    {t("Tell us about your home")}
                    <span className="text-danger">*</span>
                  </label>

                  <textarea
                    disabled={editable}
                    type="text"
                    rows={1}
                    className={`form-control mt-auto`}
                    placeholder={t("Enter details about your home")}
                    required
                    value={formData?.tell_us_about_home}
                    name="tell_us_about_home"
                    onChange={handleChange}
                  />
                </div>

                {/* 16th Row */}

                <div className="col-md-12 mt-4">
                  <label
                    htmlFor="exampleInputEmail1"
                    className={`form-label  ${style.LabelHeading}`}
                  >
                    {t(
                      "Tell us about your and your spouse's hobby and lifestyle"
                    )}
                    <span className="text-danger">*</span>
                  </label>

                  <textarea
                    disabled={editable}
                    type="text"
                    rows={1}
                    className={`form-control mt-auto`}
                    placeholder={t(
                      "Enter details about your and your spouse's hobby and lifestyle"
                    )}
                    required
                    value={formData?.tell_us_about_your_and_your_spouse_hobbies}
                    name="tell_us_about_your_and_your_spouse_hobbies"
                    onChange={handleChange}
                  />
                </div>

                {/* 17th Row */}
                <div className="row mt-4">
                  <span className={`${style.subHeading}`}>Your Favorites</span>
                </div>

                {/* 17th -> 1row */}
                <div className={`mt-4 ${style.favoriteContainer}`}>
                  <div className="mt-2">
                    <label className={`form-label ${style.LabelHeading}`}>
                      {t("Favorite Hobby")}

                      <span className="text-danger">*</span>
                    </label>
                    <input
                      disabled={editable}
                      type="text"
                      className={`form-control`}
                      placeholder="Enter Your Favorite Hobby"
                      required
                      value={formData?.your_favorites?.hobby}
                      name="hobby"
                      onChange={(e) => handleFavoritesChange(e, "your_favorites")}
                    />
                  </div>
                  <div className="mt-2">
                    <label className={`form-label ${style.LabelHeading}`}>
                      {t("Favorite Movie")}

                      <span className="text-danger">*</span>
                    </label>
                    <input
                      disabled={editable}
                      type="text"
                      className={`form-control`}
                      placeholder={t("Enter Your Favorite Movie")}
                      required
                      name="movie"
                      value={formData?.your_favorites?.movie}
                      onChange={(e) => handleFavoritesChange(e, "your_favorites")}
                    />
                  </div>
                  <div className="mt-2">
                    <label className={`form-label ${style.LabelHeading}`}>
                      {t("Favorite Sport")}

                      <span className="text-danger">*</span>
                    </label>
                    <input
                      disabled={editable}
                      type="text"
                      className={`form-control`}
                      placeholder={t("Enter Your Favorite Sport")}
                      required
                      value={formData?.your_favorites?.sport}
                      name="sport"
                      onChange={(e) => handleFavoritesChange(e, "your_favorites")}
                    />
                  </div>
                  <div className="mt-2">
                    <label className={`form-label ${style.LabelHeading}`}>
                      {t("Favorite Holiday")}

                      <span className="text-danger">*</span>
                    </label>
                    <input
                      disabled={editable}
                      type="text"
                      className={`form-control`}
                      placeholder={t("Enter Your Favorite Holiday")}
                      required
                      value={formData?.your_favorites?.holiday}
                      name="holiday"
                      onChange={(e) => handleFavoritesChange(e, "your_favorites")}
                    />
                  </div>
                  <div className="mt-2">
                    <label className={`form-label ${style.LabelHeading}`}>
                      {t("Favorite Book")}

                      <span className="text-danger">*</span>
                    </label>
                    <input
                      disabled={editable}
                      type="text"
                      className={`form-control`}
                      placeholder={t("Enter Your Favorite Book")}
                      required
                      value={formData?.your_favorites?.book}
                      name="book"
                      onChange={(e) => handleFavoritesChange(e, "your_favorites")}
                    />
                  </div>
                  <div className="mt-2">
                    <label className={`form-label ${style.LabelHeading}`}>
                      {t("Favorite Subject")}

                      <span className="text-danger">*</span>
                    </label>

                    <input
                      disabled={editable}
                      type="text"
                      className={`form-control`}
                      placeholder={t("Enter Your Favorite Subject")}
                      required
                      value={formData?.your_favorites?.subject}
                      name="subject"
                      onChange={(e) => handleFavoritesChange(e, "your_favorites")}
                    />
                  </div>
                  <div className="mt-2">
                    <label className={`form-label ${style.LabelHeading}`}>
                      {t("Favorite Tradition")}

                      <span className="text-danger">*</span>
                    </label>
                    <input
                      disabled={editable}
                      type="text"
                      className={`form-control`}
                      placeholder={t("Enter Your Favorite Tradition")}
                      required
                      value={formData?.your_favorites?.tradition}
                      name="tradition"
                      onChange={(e) => handleFavoritesChange(e, "your_favorites")}
                    />
                  </div>
                  <div className="mt-2">
                    <label className={`form-label ${style.LabelHeading}`}>
                      {t("Favorite Destination")}

                      <span className="text-danger">*</span>
                    </label>
                    <input
                      disabled={editable}
                      type="text"
                      className={`form-control`}
                      placeholder={t("Enter Your Favorite Destination")}
                      required
                      value={formData?.your_favorites?.destination}
                      name="destination"
                      onChange={(e) => handleFavoritesChange(e, "your_favorites")}
                    />
                  </div>
                  <div className="mt-2">
                    <label className={`form-label ${style.LabelHeading}`}>
                      {t("Favorite Job")}

                      <span className="text-danger">*</span>
                    </label>
                    <input
                      disabled={editable}
                      type="text"
                      className={`form-control`}
                      placeholder={t("Enter Your Favorite Job")}
                      required
                      value={formData?.your_favorites?.job}
                      name="job"
                      onChange={(e) => handleFavoritesChange(e, "your_favorites")}
                    />
                  </div>
                  <div className="mt-2">
                    <label className={`form-label ${style.LabelHeading}`}>
                      {t("Favorite Food")}

                      <span className="text-danger">*</span>
                    </label>
                    <input
                      disabled={editable}
                      type="text"
                      className={`form-control`}
                      placeholder={t("Enter Your Favorite Food")}
                      required
                      value={formData?.your_favorites?.food}
                      name="food"
                      onChange={(e) => handleFavoritesChange(e, "your_favorites")}
                    />
                  </div>
                  <div className="mt-2">
                    <label className={`form-label ${style.LabelHeading}`}>
                      {t("Music Type")}

                      <span className="text-danger">*</span>
                    </label>
                    <input
                      disabled={editable}
                      type="text"
                      className={`form-control`}
                      placeholder={t("Enter Your Favorite Music Type")}
                      required
                      value={formData?.your_favorites?.music_type}
                      name="music_type"
                      onChange={(e) => handleFavoritesChange(e, "your_favorites")}
                    />
                  </div>
                  <div className="mt-2">
                    <label className={`form-label ${style.LabelHeading}`}>
                      {t("Favorite Song")}

                      <span className="text-danger">*</span>
                    </label>
                    <input
                      disabled={editable}
                      type="text"
                      className={`form-control`}
                      placeholder={t("Enter Your Favorite Song")}
                      required
                      value={formData?.your_favorites?.song}
                      name="song"
                      onChange={(e) => handleFavoritesChange(e, "your_favorites")}
                    />
                  </div>
                  <div className="mt-2">
                    <label className={`form-label ${style.LabelHeading}`}>
                      {t("Favorite Celebrity")}

                      <span className="text-danger">*</span>
                    </label>
                    <input
                      disabled={editable}
                      type="text"
                      className={`form-control`}
                      placeholder={t("Enter Your Favorite Celebrity")}
                      required
                      value={formData?.your_favorites?.celebrity}
                      name="celebrity"
                      onChange={(e) => handleFavoritesChange(e, "your_favorites")}
                    />
                  </div>
                  <div className="mt-2">
                    <label className={`form-label ${style.LabelHeading}`}>
                      {t("Favorite Moment")}

                      <span className="text-danger">*</span>
                    </label>
                    <input
                      disabled={editable}
                      type="text"
                      className={`form-control`}
                      placeholder={t("Enter Your Favorite Moment")}
                      required
                      value={formData?.your_favorites?.moment}
                      name="moment"
                      onChange={(e) => handleFavoritesChange(e, "your_favorites")}
                    />
                  </div>
                  <div className="mt-2">
                    <label className={`form-label ${style.LabelHeading}`}>
                      {t("Favorite Author")}

                      <span className="text-danger">*</span>
                    </label>
                    <input
                      disabled={editable}
                      type="text"
                      className={`form-control`}
                      placeholder={t("Enter Your Favorite Author")}
                      required
                      value={formData?.your_favorites?.author}
                      name="author"
                      onChange={(e) => handleFavoritesChange(e, "your_favorites")}
                    />
                  </div>
                </div>

                {/* 18th Row */}

                <div className="row mt-4">
                  <span className={`${style.subHeading}`}>
                    Your Spouse Favorites
                  </span>
                </div>

                <div className={`mt-4 ${style.favoriteContainer}`}>
                  <div className="mt-2">
                    <label className={`form-label ${style.LabelHeading}`}>
                      {t("Favorite Hobby")}

                      <span className="text-danger">*</span>
                    </label>
                    <input
                      disabled={editable}
                      type="text"
                      className={`form-control`}
                      placeholder={t("Enter Spouse's Favorite Hobby")}
                      required
                      value={formData?.your_spouse_favorites?.hobby}
                      name="hobby"
                      onChange={(e) =>
                        handleFavoritesChange(e, "your_spouse_favorites")
                      }
                    />
                  </div>
                  <div className="mt-2">
                    <label className={`form-label ${style.LabelHeading}`}>
                      {t("Favorite Movie")}

                      <span className="text-danger">*</span>
                    </label>
                    <input
                      disabled={editable}
                      type="text"
                      className={`form-control`}
                      placeholder={t("Enter Spouse's Favorite Movie")}
                      required
                      value={formData?.your_spouse_favorites?.movie}
                      name="movie"
                      onChange={(e) =>
                        handleFavoritesChange(e, "your_spouse_favorites")
                      }
                    />
                  </div>
                  <div className="mt-2">
                    <label className={`form-label ${style.LabelHeading}`}>
                      {t("Favorite Sport")}

                      <span className="text-danger">*</span>
                    </label>
                    <input
                      disabled={editable}
                      type="text"
                      className={`form-control`}
                      placeholder={t("Enter Spouse's Favorite Sport")}
                      required
                      value={formData?.your_spouse_favorites?.sport}
                      name="sport"
                      onChange={(e) =>
                        handleFavoritesChange(e, "your_spouse_favorites")
                      }
                    />
                  </div>
                  <div className="mt-2">
                    <label className={`form-label ${style.LabelHeading}`}>
                      {t("Favorite Holiday")}

                      <span className="text-danger">*</span>
                    </label>
                    <input
                      disabled={editable}
                      type="text"
                      className={`form-control`}
                      placeholder={t("Enter Spouse's Favorite Holiday")}
                      required
                      value={formData?.your_spouse_favorites?.holiday}
                      name="holiday"
                      onChange={(e) =>
                        handleFavoritesChange(e, "your_spouse_favorites")
                      }
                    />
                  </div>
                  <div className="mt-2">
                    <label className={`form-label ${style.LabelHeading}`}>
                      {t("Favortie Book")}

                      <span className="text-danger">*</span>
                    </label>
                    <input
                      disabled={editable}
                      type="text"
                      className={`form-control`}
                      placeholder={t("Enter Spouse's Favorite Book")}
                      required
                      value={formData?.your_spouse_favorites?.book}
                      name="book"
                      onChange={(e) =>
                        handleFavoritesChange(e, "your_spouse_favorites")
                      }
                    />
                  </div>
                  <div className="mt-2">
                    <label className={`form-label ${style.LabelHeading}`}>
                      {t("Favorite Subject")}

                      <span className="text-danger">*</span>
                    </label>
                    <input
                      disabled={editable}
                      type="text"
                      className={`form-control`}
                      placeholder={t("Enter Spouse's Favorite Subject")}
                      required
                      value={formData?.your_spouse_favorites?.subject}
                      name="subject"
                      onChange={(e) =>
                        handleFavoritesChange(e, "your_spouse_favorites")
                      }
                    />
                  </div>
                  <div className="mt-2">
                    <label className={`form-label ${style.LabelHeading}`}>
                      {t("Favorite Tradition")}

                      <span className="text-danger">*</span>
                    </label>
                    <input
                      disabled={editable}
                      type="text"
                      className={`form-control`}
                      placeholder={t("Enter Spouse's Favorite Tradition")}
                      required
                      value={formData?.your_spouse_favorites?.tradition}
                      name="tradition"
                      onChange={(e) =>
                        handleFavoritesChange(e, "your_spouse_favorites")
                      }
                    />
                  </div>
                  <div className="mt-2">
                    <label className={`form-label ${style.LabelHeading}`}>
                      {t("Favorite Destination")}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      disabled={editable}
                      type="text"
                      className={`form-control`}
                      placeholder={t("Enter Spouse's Favorite Destination")}
                      required
                      value={formData?.your_spouse_favorites?.destination}
                      name="destination"
                      onChange={(e) =>
                        handleFavoritesChange(e, "your_spouse_favorites")
                      }
                    />
                  </div>
                  <div className="mt-2">
                    <label className={`form-label ${style.LabelHeading}`}>
                      {t("Favorite Job")}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      disabled={editable}
                      type="text"
                      className={`form-control`}
                      placeholder={t("Enter Spouse's Favorite Job")}
                      required
                      value={formData?.your_spouse_favorites?.job}
                      name="job"
                      onChange={(e) =>
                        handleFavoritesChange(e, "your_spouse_favorites")
                      }
                    />
                  </div>
                  <div className="mt-2">
                    <label className={`form-label ${style.LabelHeading}`}>
                      {t("Favorite Food")}

                      <span className="text-danger">*</span>
                    </label>
                    <input
                      disabled={editable}
                      type="text"
                      className={`form-control`}
                      placeholder={t("Enter Spouse's Favorite Food")}
                      required
                      value={formData?.your_spouse_favorites?.food}
                      name="food"
                      onChange={(e) =>
                        handleFavoritesChange(e, "your_spouse_favorites")
                      }
                    />
                  </div>
                  <div className="mt-2">
                    <label className={`form-label ${style.LabelHeading}`}>
                      {t("Music Type")}

                      <span className="text-danger">*</span>
                    </label>
                    <input
                      disabled={editable}
                      type="text"
                      className={`form-control`}
                      placeholder={t("Enter Spouse's Favorite Music Type")}
                      required
                      value={formData?.your_spouse_favorites?.music_type}
                      name="music_type"
                      onChange={(e) =>
                        handleFavoritesChange(e, "your_spouse_favorites")
                      }
                    />
                  </div>
                  <div className="mt-2">
                    <label className={`form-label ${style.LabelHeading}`}>
                      {t("Favorite Song")}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      disabled={editable}
                      type="text"
                      className={`form-control`}
                      placeholder={t("Enter Spouse's Favorite Song")}
                      required
                      value={formData?.your_spouse_favorites?.song}
                      name="song"
                      onChange={(e) =>
                        handleFavoritesChange(e, "your_spouse_favorites")
                      }
                    />
                  </div>
                  <div className="mt-2">
                    <label className={`form-label ${style.LabelHeading}`}>
                      {t("Favorite Celebrity")}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      disabled={editable}
                      type="text"
                      className={`form-control`}
                      placeholder={t("Enter Spouse's Favorite Celebrity")}
                      required
                      value={formData?.your_spouse_favorites?.celebrity}
                      name="celebrity"
                      onChange={(e) =>
                        handleFavoritesChange(e, "your_spouse_favorites")
                      }
                    />
                  </div>
                  <div className="mt-2">
                    <label className={`form-label ${style.LabelHeading}`}>
                      {t("Favorite Moment")}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      disabled={editable}
                      type="text"
                      className={`form-control`}
                      placeholder="Enter Spouse's Favorite Moment"
                      required
                      value={formData?.your_spouse_favorites?.moment}
                      name="moment"
                      onChange={(e) =>
                        handleFavoritesChange(e, "your_spouse_favorites")
                      }
                    />
                  </div>
                  <div className="mt-2">
                    <label className={`form-label ${style.LabelHeading}`}>
                      {t("Favorite Author")}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      disabled={editable}
                      type="text"
                      className={`form-control`}
                      placeholder="Enter Spouse's Favorite Author"
                      required
                      value={formData?.your_spouse_favorites?.author}
                      name="author"
                      onChange={(e) =>
                        handleFavoritesChange(e, "your_spouse_favorites")
                      }
                    />
                  </div>
                </div>

                {/* 19th row */}
                <div className="row mt-4">
                  <span className={`${style.subHeading}`}>Child Prefrences</span>
                </div>

                <div className="col-sm-6 col-xxl-3 mt-4">
                  <label
                    htmlFor="exampleInputEmail1"
                    className={`form-label  ${style.LabelHeading}`}
                  >
                    {t("Age Range")}
                    <span className="text-danger">*</span>
                  </label>
                  <div className="row">
                    <div className="col-6">
                      <input
                        disabled={editable}
                        type="number"
                        className={`form-control`}
                        placeholder="Min age"
                        value={formData?.child_preferences?.age_range?.min}
                        name="child_preferences.age_range.min"
                        onChange={handleChildChange}
                        required
                      />
                    </div>
                    <div className="col-6">
                      <input
                        disabled={editable}
                        type="number"
                        className={`form-control`}
                        placeholder="Max age"
                        required
                        value={formData?.child_preferences?.age_range?.max}
                        name="child_preferences.age_range.max"
                        onChange={handleChildChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 col-xxl-3 mt-4">
                  <label
                    htmlFor="exampleInputEmail1"
                    className={`form-label  ${style.LabelHeading}`}
                  >
                    {t("Gender")}
                    <span className="text-danger">*</span>
                  </label>
                  <select
                    disabled={editable}
                    className={`form-select`}
                    name="child_preferences.gender"
                    value={formData?.child_preferences?.gender}
                    required
                    onChange={handleChildChange}
                  >
                    <option selected disabled value={""}>
                      {t("Select")}
                    </option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="no_preference">No Preference</option>
                  </select>
                </div>

                <div className="col-sm-6 col-xxl-3 mt-4">
                  <label
                    htmlFor="exampleInputEmail1"
                    className={`form-label  ${style.LabelHeading}`}
                  >
                    {t("Special Needs")}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    disabled={editable}
                    type="text"
                    className={`form-control`}
                    placeholder="Enter Special Needs"
                    required
                    value={formData?.child_preferences?.special_need}
                    name="child_preferences.special_need"
                    onChange={handleChildChange}
                  />
                </div>

                <div className="col-sm-6 col-xxl-3 mt-4">
                  <label
                    htmlFor="exampleInputEmail1"
                    className={`form-label  ${style.LabelHeading}`}
                  >
                    {t("Race of child")}
                    <span className="text-danger">*</span>
                  </label>
                  <MultiSelect
                    disabled={editable}
                    value={selectedChildRaces}
                    required
                    onChange={(e) => {
                      setSelectedChildRaces(e.value);
                      // console.log(e.value);
                    }}
                    options={races}
                    optionLabel="name"
                    display="chip"
                    showSelectAll={false}
                    closeIcon={false}
                    placeholder="Select Race"
                    maxSelectedLabels={5}
                    className="multiSelectDropDown  ps-2"
                    name="race"
                  />
                </div>
              </div>

              {editable ? (
                ""
              ) : (
                <div className="d-flex justify-content-end gap-4 mt-5">
                  <button
                    type="button"
                    onClick={() => {
                      setEditable(true);
                      getFormdata(userId)
                    }}
                    className={style.CancelButton}
                  >
                    Cancel
                  </button>
                  <button type="submit" className={style.SaveButton}>
                    Save Changes
                  </button>
                </div>
              )}

            </div>
          </form>
        </div>




        <VerifyResume visibale={visibale} setVisibale={setVisibale} verifyResume={verifyResume} userData={users} />
      </div >


    </>


  );
};

export default EmbryoMyProfile;
