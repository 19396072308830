import React, { useEffect, useState } from 'react'
import { Dialog } from 'primereact/dialog'
import style from "./BidNowDialog.module.css"


export default function PaymentSuccessFull({openSucees}) {
    const [showPopup,setShowPopUp] = useState(false)
    useEffect(()=>{
        console.log("adadsd")
if(openSucees){
    setShowPopUp(true)
    setTimeout(()=>{
        setShowPopUp(false)
    },2000)
}
    },[openSucees])
  return (
    <div>
           <Dialog
                closable={false}
                visible={showPopup}
                onHide={() => { }}
                style={{ width: "60vw",  }}
                contentClassName={`${style.bgImg}`}
                breakpoints={{ "960px": "75vw", "641px": "100vw" }}
            >
                
                <div className='py-4'>
                    <div className={`d-flex justify-content-center align-items-center`}>
                     <span className={`${style.heading}`}>Payment Successful</span>  <img src='/images/tick12.png' style={{width:'80px'}} alt='' className='ms-2'/>
                    </div>
                    <div className={`mt-3 ${style.heading1} mb-4`}>Thank you for your purchase! Your payment for the surrogate has been successfully processed</div>
                   
                </div>


            </Dialog>
    </div>
  )
}
