import React, { useState } from 'react'
import { CiImageOn } from 'react-icons/ci'
import { IoMdAdd } from 'react-icons/io'
import Toaster from '../../common/Toaster'
import "./Input.css"

import { RxCross2 } from "react-icons/rx";
import { t } from 'i18next';
export const MultipleImageUpload = ({ value, onChange, maximg, setDeletedImage, disabled = false }) => {

    const [toaster, setToaster] = useState({
        show: false,
        message: "",
    });


    function inpChange(e) {
        if (e.target.files?.length > maximg) {
            setToaster({
                show: true,
                message: `You can choose max ${maximg} images`,
            });
            return
        }
        if (value?.length > maximg - 1) {
            setToaster({
                show: true,
                message: `You can choose max ${maximg} images`,
            });
            return
        }
        if ((value?.length + e.target.files.length) > maximg) {
            setToaster({
                show: true,
                message: `You can choose max ${maximg} images`,
            });
            return
        }
        let selectedFile = Object.values(e.target.files)
        let obj = selectedFile.map(res => {
            let localUrl = URL.createObjectURL(res)
            return {
                fileName: res.name,
                fileUrl: localUrl,
                file: res,
                fileType: 'local'
            }
        })
        onChange([...value, ...obj])
        return
        // let localUrl = URL.createObjectURL(selectedFile)
        // let obj = {
        //     fileName: selectedFile.name,
        //     fileUrl: localUrl,
        //     file: selectedFile
        // }
    }

    function deleteImage(ind) {
        let arr = [...value]
        if (arr[ind]?.type === 'server') {
            setDeletedImage(pre => [...pre, arr[ind]])
        }
        arr.splice(ind, 1)
        onChange(arr)
    }


    return (
        <>
            <Toaster
                event={toaster.message}
                show={toaster.show}
                severity={"error"}
                setToaster={setToaster}
            />
            <div className='wrapper_for_multiple_image_upload'>


                <input
                    type="file"
                    id="primaryResumeFileInput"
                    accept=".png,.jpg,.jpeg,.heic"
                    hidden
                    onChange={inpChange}
                    disabled={disabled}
                    multiple
                    name="photos"
                />
                <div className='multipleImageInputLabel'>
                    {value?.map((res, key) => {
                        return <div key={key} className='img_con'>
                            <img src={res.fileUrl} className='w-100 h-100' />
                            {!disabled && <div onClick={() => deleteImage(key)} className='img_cross_icon pointer'>
                                <RxCross2 />
                            </div>}
                        </div>
                    })}
                    {!disabled ?
                        <label htmlFor='primaryResumeFileInput' className={`pointer ${value?.length === 0 ? "w-100" : "input_box_design"}`}>
                            {value?.length === 0 ?
                                <div>
                                    <div className='text-center input_img_icon'>
                                        <CiImageOn />
                                    </div>
                                    <div className='mt-2 upload_text text-center'>{t('Upload Image(s) (Max 5)')}</div>
                                </div>
                                :
                                <div><IoMdAdd /></div>
                            }
                        </label>
                        :
                        null
                    }

                </div>

            </div>

        </>
    )
}
