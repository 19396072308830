import axios from "axios"
import { admin, baseUrl } from "./EndPoint"

let token;
let userData = localStorage.getItem("userData")
if (userData) {
    token = JSON.parse(userData).token
}

let  config = {
    headers: {
        Authorization: `Bearer ${token}`,
    }
  }

export const addUser = async (data) => {
    return await axios.post(baseUrl+admin.addUser, data,config)
}

export const addPlan = async (data) => {
    return await axios.post(`${baseUrl}${admin.addPlan}`, data,config)
}

export const getPlan = async (data) => {
    return await axios.get(`${baseUrl}${admin.getPlan}`,data,config)
}

export const deletePlan=async(id)=>{

    return await axios.delete(`${baseUrl}${admin.deletePlan}${id}`,config)
}

export const updateStatus=async(id,data)=>{
    return await axios.put(`${baseUrl}${admin.updateStatus}${id}`,data,config)
}

export const addFeature=async(data)=>{
    return await axios.post(`${baseUrl}${admin.addFeature}`,data,config)
}

export const getFeature=async(data)=>{
    return await axios.get(`${baseUrl}${admin.getFeature}`,data,config)
}

export const addSocialPage = async (data) => {
    return await axios.post(`${baseUrl}${admin.addSocialPage}`, data,config)
}

export const getSocialPage = async () => {
    return await axios.get(`${baseUrl}${admin.getSocialPage}`,config)
}

export const getSocialPageById = async (id) => {
    return await axios.get(`${baseUrl}${admin.getSocialPageById}${id}`,config)
}

export const updateSocialPage = async (id,data) => {
    return await axios.put(`${baseUrl}${admin.updateSocialPage}${id}`,data,config)
}

export const deleteSocialPage = async (id) => {
    return await axios.delete(`${baseUrl}${admin.deleteSocialPage}${id}`,config)
}

export const getPlanByIdData = async(id)=>{
    return await axios.get(`${baseUrl}${admin.getplanById}${id}`,config)
}

export const updatePlan=async(id,body)=>{
    return await axios.put(`${baseUrl}${admin.updatePlan}${id}`,body,config)
}

export const loginDashboard = async (body)=>{
    return await axios.post(`${baseUrl}${admin.loginDashboard}`,body)
}

export const getActiveSubscriber = async ()=>{
    return await axios.get(`${baseUrl}${admin.getActiveSubscriber}`,config)
}

export const getIntendedParents = async ()=>{
    return await axios.get(`${baseUrl}${admin.getIntendedParents}`,config)
}

export const deleteUser = async (id,data)=>{
    return await axios.put(`${baseUrl}${admin.deleteUser}${id}`,data,config)
}

export const activeInactive = async (id,data)=>{
    return await axios.put(`${baseUrl}${admin.activeInactive}${id}`,data,config)
}

export const deleteuserbyId = async (id)=>{
    return await axios.delete(`${baseUrl}${admin.deleteuserbyId}${id}`,config)
}

export const addLawyer = async (data) => {
    return await axios.post(`${baseUrl}${admin.addLawyer}`, data,config)
}

export const getLawyers = async () => {
    return await axios.get(`${baseUrl}${admin.getLawyers}`,config)
}

export const gettermscondition = async ()=>{
    return await axios.get(`${baseUrl}${admin.gettermscondition}`,config)
}

export const updatetermscondition = async (body)=>{
    return await axios.put(`${baseUrl}${admin.updatetermscondition}`,body,config)
}

export const addClinic = async (data) => {
    return await axios.post(`${baseUrl}${admin.addClinic}`, data,config)
}

export const getIvfClinics = async () => {
    return await axios.get(`${baseUrl}${admin.getIvfClinics}`,config)
}

export const getUserDataById = async (id) => {
    return await axios.get(`${baseUrl}${admin.getUserById}${id}`,config)
}

export const getPoliciesData = async () => {
    return await axios.get(`${baseUrl}${admin.getPolicies}`,config)
}

export const updatePolicies = async (data) => {
    return await axios.put(`${baseUrl}${admin.updatePolicies}`,data,config)
}


export const getAboutData = async () => {
    return await axios.get(`${baseUrl}${admin.getAboutUs}`,config)
}

export const updateAbout = async (data) => {
    return await axios.post(`${baseUrl}${admin.updateAboutUs}`,data,config)
}

export const addFaqData = async (id,data) =>{
    return await axios.post(`${baseUrl}${admin.addFaq}${id}`,data,config)
}

export const getFaqDatabyrole = async(id) =>{
    return await axios.get(`${baseUrl}${admin.getFaqbyrole}${id}`,config)
}

export const deleteFaqById = async(id) =>{
    return await axios.delete(`${baseUrl}${admin.deletFaq}${id}`,config)   
}

export const updateFaqById = async(id,data)=>{
    return await axios.put(`${baseUrl}${admin.updateFaq}${id}`,data,config)
}

export const getdataFaqById = async(id)=>{
    return await axios.get(`${baseUrl}${admin.getByIdFaq}${id}`,config)
}

export const getlifestyledata=async() =>{
    return await axios.get(`${baseUrl}${admin.getAllLifestyle}`,config)
}

export const addlifestyledata=async(data) =>{
    return await axios.post(`${baseUrl}${admin.addLifestyle}`,data,config)
}

export const editlifestyledata=async(id,data) =>{
    return await axios.put(`${baseUrl}${admin.editlifestyle}${id}`,data,config)
}

export const deletelifestyle=async(id) =>{
    return await axios.delete(`${baseUrl}${admin.deletelifestyle}${id}`,config)
}


export const gethabitsdata=async() =>{
    return await axios.get(`${baseUrl}${admin.getAllHabits}`,config)
}

export const addhabitsdata=async(data) =>{
    return await axios.post(`${baseUrl}${admin.addHabits}`,data,config)
}

export const edithabitsdata=async(id,data) =>{
    return await axios.put(`${baseUrl}${admin.editHabits}${id}`,data,config)
}

export const deletehabits=async(id) =>{
    return await axios.delete(`${baseUrl}${admin.deleteHabits}${id}`,config)
}

export const getusersforauction=async()=>{
    return await axios.get(`${baseUrl}${admin.getallusersforauction}`,config)
}

export const getAllAuctionData=async()=>{
    return await axios.get(`${baseUrl}${admin.getAllAuctions}`,config)
}

export const getAllManageLeadsData = async()=>{
    return await axios.get(`${baseUrl}${admin.getAllLeadsData}`,config)
}

export const getSurrogateMatchedData=async(obj)=>{
    return await axios.post(`${baseUrl}${admin.getSurrogateMatchedData}`,obj,config)
}

export const getAuctionPostData=async(obj)=>{
    return await axios.post(`${baseUrl}${admin.getAuctionPostData}`,obj,config)
}
export const getSurrogateRegistrationData=async(obj)=>{
    return await axios.post(`${baseUrl}${admin.getSurrogateRegistrationData}`,obj,config)
}

export const revenueFromSubscription=async(obj)=>{
    return await axios.post(`${baseUrl}${admin.revenueFromSubscription}`,obj,config)
}