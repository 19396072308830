import React, { useState } from 'react'
import style from "./MyProfile.module.css"
import { useTranslation } from 'react-i18next'
import { Update } from '@mui/icons-material'
const EditMyProfile = () => {
    let { t } = useTranslation()
  const [update,setUpdate]=useState(
    {
        mobile_No_alter: '',
        email_alter: '',
        address: '',
        city: '',
        state: '',
        zip_code: '',
        textSMS_mobile: true,
        textSMS_mobile_alter: true,
        auctionUpdates: true,
        latestNews: true,
        importantUpdate: true,
        promotionalNotifications: true
      }
  )
  function UpdateProfile (e){
    e.preventDefault()
    console.log(update)
  }
  function handleChange(){
    let name
  }
    return (
        <div>
            <div className={style.MyProfile}>
                <div className={`mb-5 ${style.bannersize}`}>
                    <div className='d-flex'>
                        <div className={`p-2 ${style.Profilebackground}`}>
                            <i className="pi pi-image text-white" style={{ fontSize: '1rem' }}></i>
                        </div>

                        <div className='d-flex flex-column align-item-center justify-content-center gap-3'>
                            <div className={style.BnnerFirstText}>NPHIP001 : Intended Parents</div>
                            <div className={style.BnnersecondtText}>Alexa Smith</div>
                            {/* <div ><button className={style.NotificationText1}>Notification of</button></div> */}
                        </div>


                    </div>
                    <div className={`p-2 `}>
                        <img src="/images/child5 (1).png" alt="" />
                    </div>
                </div>

              <form onSubmit={UpdateProfile}>
              <div className="row">
                    <div className="col-md-4">

                        <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`}>{t("First Name")}<span className='text-danger'>*</span></label>

                        <input type="text"
                         className={`form-control ${style.InputCustom}`}
                          placeholder=""
                          name='mobile_No_alter'
                          value={update.mobile_No_alter}
                          onChange={handleChange}
                          
                          />

                    </div>
                    <div className="col-md-4">

                        <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`}>{t("Middle Initial")}<span className='text-danger'>*</span></label>

                        <input type="text" className={`form-control ${style.InputCustom}`} placeholder="" />

                    </div>
                    <div className="col-md-4">

                        <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`}>{t("Last Name")}<span className='text-danger'>*</span></label>

                        <input type="text" className={`form-control ${style.InputCustom}`} placeholder="" />

                    </div>

                </div>
                <div className="row">
                    <div className="col-md-6">

                        <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`}>{t("Age")}<span className='text-danger'>*</span></label>

                        <input type="email" className={`form-control ${style.InputCustom}`} placeholder="" />

                    </div>
                    <div className="col-md-6">

                        <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`}>{t("Date of Birth")}<span className='text-danger'>*</span></label>

                        <input type="date" className={`form-control ${style.InputCustom}`} placeholder="" />

                    </div>

                    <div className="row">


                        <div className='col-md-6'>
                            <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`}>{t("Height")} <span className='text-danger'>*</span></label>

                            <div className='d-flex justify-content-between gap-2'>
                            <select className={`form-select ${style.InputCustom}`} aria-label="Default select example">
                                    <option selected>{t("select")}</option>
                                    <option value={1}>{t("07")}</option>
                                    <option value={2}>{t("08")}</option>
                                    <option value={3}>{t("09")}</option>

                                </select>

                                <div className="d-flex align-items-center">ft</div>

                                <select className={`form-select ${style.InputCustom}`} aria-label="Default select example">
                                    <option selected>{t("select")}</option>
                                    <option value={1}>{t("07")}</option>
                                    <option value={2}>{t("08")}</option>
                                    <option value={3}>{t("09")}</option>

                                </select>
                                <div className="d-flex align-items-center">inches</div>
                            </div>


                        </div>

                        <div className='col-md-6'>
                            <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`}>{t("Weight")} <span className='text-danger'>*</span></label>

                            <div className='d-flex justify-content-between gap-2'>
                            <select className={`form-select ${style.InputCustom}`} aria-label="Default select example">
                                    <option selected>{t("select")}</option>
                                    <option value={1}>{t("07")}</option>
                                    <option value={2}>{t("08")}</option>
                                    <option value={3}>{t("09")}</option>

                                </select>
                                <div className="d-flex align-items-center">lbs</div>
                                <select className={`form-select $`} aria-label="Default select example">
                                    <option selected>{t("select")}</option>
                                    <option value={1}>{t("07")}</option>
                                    <option value={2}>{t("08")}</option>
                                    <option value={3}>{t("09")}</option>

                                </select>
                                <div className="d-flex align-items-center">oz</div>
                            </div>


                        </div>

                    </div>

                    <div className="row">
                        <div className="col-md-12">

                            <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`}>{t("Email Address")}</label>

                            <input type="email" className={`form-control ${style.InputCustom}`} placeholder="" />

                        </div>

                    </div>

                    <div className="row mb-4">
                        <div className="col-md-4">

                            <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`}>{t("Home Phone*")}<span className='text-danger'>*</span></label>

                            <input type="number" className={`form-control ${style.InputCustom}`} placeholder="" />

                        </div>
                        <div className="col-md-4">

                            <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`}>{t("Work Phone")}<span className='text-danger'>*</span></label>

                            <input type="number" className={`form-control ${style.InputCustom}`} placeholder="" />

                        </div>
                        <div className="col-md-4">

                            <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`}>{t("Cell Phone")}<span className='text-danger'>*</span></label>

                            <input type="number" className={`form-control ${style.InputCustom}`} placeholder="" />

                        </div>

                    </div>
                    <div className="row mb-4">
                        <div className="col-md-12">

                            <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`}>{t("Current Address")}<span className='text-danger'>*</span></label>

                            <input type="text" className={`form-control ${style.InputCustom}`} placeholder="" />

                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-md-4">
                            <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`}>{t("City")} </label>
                            <select className={`form-select ${style.InputCustom}`} aria-label="Default select example">
                                <option selected>{t("select")}</option>
                                <option value={1}>{t("Noida")}</option>
                                <option value={2}>{t("Aligarh")}</option>
                                <option value={3}>{t("Rampur")}</option>

                            </select>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`} >{t("State of Residence")} </label>
                            <select className={`form-select ${style.InputCustom}`} aria-label="Default select example">
                                <option selected>{t("select")}</option>
                                <option value={1}>{t("07")}</option>
                                <option value={2}>{t("08")}</option>
                                <option value={3}>{t("09")}</option>

                            </select>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`} >{t("Zip Code")} </label>
                            <input type="text" className={`form-control ${style.InputCustom}`} placeholder="" />
                        </div>
                    </div>
                    <div className='d-flex justify-content-end gap-4'>
                        <button type='button' className={style.CancelButton}>cancel</button>
                        <button className={style.SaveButton} type='submit'>Save Changes</button>
                    </div>
                </div>
              </form>
            </div>
        </div>
    )
}

export default EditMyProfile