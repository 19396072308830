import React, { useEffect, useState } from "react";
import "./sidebar.css";
import { Logo } from "../common/Logo";
import { useLocation, useNavigate } from "react-router-dom";
import { AiOutlineMenu } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import "primeicons/primeicons.css";
import Surrogate from "../Roles/Surrogate";
import RoleUserlisting from "../Roles/RoleUserlisting";
import Intendedparents from "../Roles/Intendedparents";
import Lawyer from "../Roles/Lawyer";
import IvfClinic from "../Roles/IvfClinic";
import SuperAdmin from "../Roles/SuperAdmin";
import EmbryoDonor from "../Roles/EmbryoDonor";
import { getSurrogateMotherData } from "../../Api/SurrogateMotherApis";
import { useDispatch, useSelector } from "react-redux";
import { changeHeaderData } from "../../Store/Slices/headerDataSlice";
import { getUserDataById } from "../../Api/admin";
import { getDashboardUser } from "../../Api/CommonApis";
import Loader from "../common/Loader";
import { intendedParent, surrogateMother } from "../../Api/EndPoint";
var memberIdOfSurrogate;



export const Sidebar = ({ children, role }) => {


  let userIdOfSurrogate = sessionStorage.getItem("userId");

  let { t } = useTranslation();
  const [sideNavShow, setSideNavShow] = useState(false);
  const selector = useSelector(e => e.header)

  const dispatch = useDispatch()
  const location = useLocation();
  let navigate = useNavigate();
  const [loader, setLoader] = useState(false)

  const handleHide = () => {
    setSideNavShow(!sideNavShow);
  };

  const roleComponents = {
    surrogate: Surrogate,
    userlisting: RoleUserlisting,
    intendedparent: Intendedparents,
    lawyer: Lawyer,
    ivfclinic: IvfClinic,
    superadmin: SuperAdmin,
    embryo_donor: EmbryoDonor,
  };

  const RoleComponent = roleComponents[role];

  const getUserData = async () => {
    if (sessionStorage.getItem("userData")) {
      const user = JSON.parse(sessionStorage.getItem("userData"));
      let item = await getSurrogateMotherData(user.userId);

      dispatch(changeHeaderData({ firstName: item.data.data?.firstName, lastName: item.data.data?.lastName, photos: item.data.data?.photos, activeIndex: item.data.data?.photo_resume_index }))
    }
  };


  const getUserDataIvfClinic = async () => {
    setLoader(true);
    try {
      if (sessionStorage.getItem("userId")) {
        const userId = sessionStorage.getItem("userId");

        let res = await getUserDataById(userId);

        dispatch(changeHeaderData({
          fullName: res?.data?.data?.fullName,
          imageUrl: res?.data?.data?.imageUrl,
          clinicName: res?.data?.data?.fullName,
          clinicPhoto: res?.data?.data?.imageUrl,
          ClinicId: res?.data?.data?.memberId,
          ClinicProfileId: res?.data?.data?._id
        }))

      }
    } catch (err) {
      console.log("err while fetching data in sidebar", err)
    }
    finally {
      setLoader(false)
    }

  }


  const getUserDataLawyer = async () => {
    setLoader(true);
    try {
      if (sessionStorage.getItem("userId")) {
        const userId = sessionStorage.getItem("userId");

        let res = await getUserDataById(userId);

        console.log(res, "this is response in topbar")

        dispatch(changeHeaderData({
          lawyerName: res?.data?.data?.fullName,
          lawyerPhoto: res?.data?.data?.imageUrl,
          LawyerId: res?.data?.data?.memberId,
          LawyerProfileId: res?.data?.data?._id
        }))

      }
    } catch (err) {
      console.log("err while fetching data in sidebar", err)
    }
    finally {
      setLoader(false)
    }

  }

  const getUserDataEmbryo = async () => {
    setLoader(true);
    try {
      if (sessionStorage.getItem("userId")) {
        const userId = sessionStorage.getItem("userId");

        let res = await getUserDataById(userId);

        dispatch(changeHeaderData({
          embryoDonorName: res?.data?.data?.fullName,
          embryoDonorPhoto: res?.data?.data?.imageUrl,
          embryoDonorId: res?.data?.data?.memberId,
          embryoDonorProfileId: res?.data?.data?._id
        }))

      }
    } catch (err) {
      console.log("err while fetching data in sidebar", err)
    }
    finally {
      setLoader(false)
    }

  }




  const getUserDataIntendedParent = async () => {
    setLoader(true);
    try {
      if (sessionStorage.getItem("userId")) {
        const userId = sessionStorage.getItem("userId");

        let res = await getUserDataById(userId);

        dispatch(changeHeaderData({
          IntendedParentName: res?.data?.data?.fullName,
          IntendedParentPhoto: res?.data?.data?.imageUrl,
          IntendedParentId: res?.data?.data?.memberId,
          IntendedParentProfileId: res?.data?.data?._id
        }))

      }
    } catch (err) {
      console.log("err while fetching data in sidebar", err)
    }
    finally {
      setLoader(false)
    }

  }




  const profilePhoto = () => {
    if (selector?.photos?.length !== 0) {
      if (
        selector?.activeIndex !== undefined &&
        selector?.activeIndex !== null
      ) {

        return selector?.photos[selector.activeIndex]?.fileUrl;
      } else {
        let index = selector?.photos?.length - 1
        return selector?.photos?.[index]?.fileUrl;
      }
    } else {
      return "/images/blankProfile.png";
    }
  };






  useEffect(() => {
    profilePhoto()
  }, [selector])






  useEffect(() => {

    if (RoleComponent === IvfClinic) {
      getUserDataIvfClinic();
    }

    else if (RoleComponent === Lawyer) {
      getUserDataLawyer();
    }

    else if (RoleComponent === EmbryoDonor) {
      getUserDataEmbryo();
    }

    else if (RoleComponent === Intendedparents) {
      getUserDataIntendedParent()
    }

    else {
      getUserData();
    }
  }, [RoleComponent]);
  return (
    <div
      className="sidebar_con"
      // style={{ gridTemplateColumns: sideNavShow ? "68px 1fr" : "250px 1fr" }}
      style={{ gridTemplateColumns: sideNavShow ? "0px 1fr" : "250px 1fr" }}
    >
      <Loader open={loader} />
      <div className="left_con">
        <div
          className="logo_main_con"
          style={{ marginLeft: sideNavShow ? "15px" : "15px" }}
        >
          <Logo />
        </div>
        <div className="navlink_con">{RoleComponent && <RoleComponent />}</div>
      </div>
      <div className="right_con">
        <div className="top_bar_con">
          <div className="d-flex gap-3">
            <div className="d-flex gap-3 align-items-center">
              <span>
                <AiOutlineMenu
                  size={28}
                  style={{ cursor: "pointer" }}
                  onClick={handleHide}
                />
              </span>
            </div>

            {/* {RoleComponent === RoleUserlisting ? (
              ""
            ) : (
              <div className="">
                <input
                  type="search"
                  placeholder="Search"
                  className="searchbar"
                  // value={searchInput}
                  // onChange={(e) => filterData(e.target.value)}
                />
              </div>
            )} */}
          </div>

          <div className={`d-flex gap-3 align-items-center`}>
            <div>
              <i className="pi pi-bell" style={{ fontSize: "1.4rem" }}></i>
            </div>

            {/* <div className={`d-flex align-items-center gap-2`}>
              <img src="/images/UK Flag.png" alt="country flag" />
              <div className={``}>English</div>
            </div> */}

            {RoleComponent === Surrogate ? (
              <div className={`d-flex align-items-center gap-2`}>
                <div
                  className={`d-flex align-items-center justify-content-center`}
                >

                  <img
                    src={
                      profilePhoto()
                    }
                    alt="profile_img"
                    style={{
                      height: "50px",
                      width: "50px",
                      borderRadius: "50%",
                    }}
                  />
                </div>

                <div
                  className={`d-flex flex-column justify-content-between align-items-center`}
                >
                  <div
                    className={`fs-5`}
                  >{`${selector?.firstName} ${selector?.lastName}`}</div>
                  <div className={``}>Surrogate</div>
                </div>
              </div>
            ) : (
              ""
            )}
            {RoleComponent === Intendedparents ? (
              <div className={`d-flex align-items-center gap-2`}>
                <div
                  className={`d-flex align-items-center justify-content-center`}
                >

                  <img
                    src={selector?.IntendedParentPhoto ? selector.IntendedParentPhoto : "/images/IpIcon.png"}
                    alt="Icon"
                    style={{
                      height: "50px",
                      width: "50px",
                      borderRadius: "50%",
                    }}
                  />
                </div>

                <div
                  className={`d-flex flex-column justify-content-between align-items-center`}
                >
                  <div
                    className={`fs-5`}
                  >{`${selector?.IntendedParentName ? selector?.IntendedParentName : ""} `}</div>
                  <div className={``}>
                    {sessionStorage.getItem("role") === "parents" 
                    ? 
                    <span>Intended Parent</span> 
                    : 
                    <span>Adopting Parent</span>}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {RoleComponent === SuperAdmin ? (
              <>
                <div className={`d-flex align-items-center gap-2`}>
                  <div
                    className={`d-flex flex-column justify-content-between align-items-center`}
                  >

                    <div className={``}>Superadmin</div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}

            {RoleComponent === IvfClinic ? (
              <div className={`d-flex align-items-center gap-2`}>
                <div className={`d-flex align-items-center justify-content-center`}>
                  <img
                    src={selector?.clinicPhoto ? selector.clinicPhoto : "/images/ivfclinichospitalimage.png"}
                    alt="profile_img"
                    style={{
                      height: "50px",
                      width: "50px",
                      borderRadius: "50%",
                    }}
                  />
                </div>

                <div className={`d-flex flex-column justify-content-between align-items-center`}>
                  <div className={`fs-5`}>{`${selector?.clinicName}`}</div>
                  <div className={``}>Ivf Clinic</div>
                </div>
              </div>
            ) : (
              ""
            )}

            {RoleComponent === Lawyer ? (
              <div className={`d-flex align-items-center gap-2`}>
                <div className={`d-flex align-items-center justify-content-center`}>
                  <img
                    src={selector?.lawyerPhoto ? selector.lawyerPhoto : "/images/lawyerDefaultPic.png"}
                    alt="profile_img"
                    style={{
                      height: "50px",
                      width: "50px",
                      borderRadius: "50%",
                    }}
                  />
                </div>

                <div className={`d-flex flex-column justify-content-between align-items-center`}>
                  <div className={`fs-5`}>{`${selector?.lawyerName}`}</div>
                  <div className={``}>Lawyer</div>
                </div>
              </div>
            ) : (
              ""
            )}

            {RoleComponent === EmbryoDonor ? (
              <div className={`d-flex align-items-center gap-2`}>
                {selector?.embryoDonorPhoto &&
                  <div className={`d-flex align-items-center justify-content-center`}>
                    {console.log(selector?.embryoDonorPhoto, "asdasdasdasdasdasdasdasdasdasdasd")}
                    <img
                      src={selector?.embryoDonorPhoto ? selector.embryoDonorPhoto : "/images/ivfclinichospitalimage.png"}
                      alt="profile_img"
                      style={{
                        height: "50px",
                        width: "50px",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                }
                <div className={`d-flex flex-column justify-content-between align-items-center`}>
                  <div className={`fs-5`}>{`${selector?.embryoDonorName}`}</div>
                  <div className={``}>Embryo Donor</div>
                </div>
              </div>
            ) : (
              ""
            )}



          </div>
        </div>
        <div className="children_con">
          <div>{children}</div>
        </div>
      </div>
    </div>
  );
};
