import React from "react";
import style from "./AddPlan.module.css";
import { Dialog } from "primereact/dialog";
// import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { RxCross2 } from "react-icons/rx";
import { addPlan } from "../../../../Api/admin";
import AddFeatures from "./AddFeatures/AddFeatures";
import { getFeature } from "../../../../Api/admin";
import Loader from "../../../common/Loader";
import { useNavigate } from "react-router";
import Toaster from "../../../common/Toaster";

const AddPlan = () => {
  const [open, setopen] = useState(false);
  const [featuredata, setFeaturedata] = useState([]);
  const navigate = useNavigate();
  const [featureinc, setfeatureinc] = useState([]);
  const [featurenotinc, setfeaturenotinc] = useState([]);

  const [includedFeature, setIncludedFeature] = useState("");
  const [excludedFeature, setExcludedFeature] = useState("");
  const [toaster, setToaster] = useState({
    show: false,
    message: "",
  });

  const [loader, setLoader] = useState(false);
  const [wordCount, setWordCount] = useState(0);
  const limit = 50;

  const handleTextChange = (event) => {
    const words = event.target.value.split(/\s+/).filter(Boolean);
    if (words.length <= limit) {
      setWordCount(words.length);
      const { name, value } = event.target;
      setUserData({ ...userData, [name]: value });
    } else {
      setToaster({
        show: true,
        message: `you cant write more then ${limit} words in description`,
      });
    }
  };

  var fetchfeature = async () => {
    try {
      let result = await getFeature();
      setFeaturedata(result.data.data);
      console.log("planskkkkk", result.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const [userData, setUserData] = useState({
    plan_name: "",
    user_type: "",
    validity: "",
    amount: "",
    plan_description: "",
    included_features: [],
    not_included_features: [],
  });

  const handleDropdownChange = (event, index) => {
    const { name, value } = event.target;
    setUserData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDropdownChangeFeature = (event) => {
    const { name, value } = event.target;

    // Determine which list to update and filter the feature from the main list
    if (name === "included_features") {
      setIncludedFeature("");
      setfeatureinc((prev) => [...prev, value]); // Adding to included features list
    } else {
      setExcludedFeature("");
      setfeaturenotinc((prev) => [...prev, value]); // Adding to not included features list
    }

    // Filter out the selected feature from the main feature data
    const updatedFeatures = featuredata.filter(
      (item) => item.feature_name !== value
    );

    // Update the main feature data list to reflect the removal
    setFeaturedata(updatedFeatures);
  };

  const removeFeature = (featureName, isIncluded) => {
    if (isIncluded) {
      const updatedFeatures = featureinc.filter((item) => item !== featureName);
      setfeatureinc(updatedFeatures);
    } else {
      const updatedFeatures = featurenotinc.filter(
        (item) => item !== featureName
      );
      setfeaturenotinc(updatedFeatures);
    }

    // Optionally, add back the feature to the main list
    setFeaturedata((prev) => [...prev, { feature_name: featureName }]);
  };

  const handleChnage = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      console.log(userData);
      userData.plan_description = userData.plan_description.trim();
      let data = {
        ...userData,
        not_included_features: [...featurenotinc],
        included_features: [...featureinc],
      };

      console.log(data);

      let res = await addPlan(data);
      console.log(res);

      setUserData({
        plan_name: "",
        user_type: "",
        validity: "",
        amount: "",
        plan_description: "",
        included_features: [],
        not_included_features: [],
      });

      setfeatureinc([]);
      setfeaturenotinc([]);

      setLoader(false);
      navigate("/superadmin/subscriptionplan");
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  // useEffect(
  //   function () {
  //     fetchfeature();
  //   },
  //   [handleSubmit]
  // );

  return (
    <div>
      <AddFeatures
        resumeone={open}
        setResumeone={setopen}
        func={fetchfeature}
      />

      {toaster && (
        <>
          <Toaster
            // event={"Please InActive Current Post then you Add new Post"}
            event={toaster.message}
            show={toaster.show}
            severity={"warn"}
            setToaster={setToaster}
          />
        </>
      )}

      <form onSubmit={handleSubmit}>
        <div>
          <div
            className={`d-flex mb-4 justify-content-between mt-4 align-items-center`}
          >
            <div>
              {" "}
              <i
                className="bi bi-chevron-left pointer fs-3"
                onClick={() => navigate("/superadmin/subscriptionplan")}
              ></i>
            </div>
            <div className={`lufga_32px fw_600`}>Add Subscription Plan</div>
            <button
              type="button"
              className={`theme_button lufga_16px`}
              onClick={() => setopen(true)}
            >
              Add feature
            </button>
          </div>

          <div className={`${style.formContainer}`}>
            <div className={`row mt-3`}>
              <div className={`col-6`}>
                <div className={`lufga_18px `}>Plan Name</div>
                <div>
                  <input
                    required
                    onChange={handleChnage}
                    className={`lufga_16px form-control`}
                    name="plan_name"
                    value={userData.plan_name}
                    type="text"
                    placeholder="Plan Name"
                  />
                </div>
              </div>
              <div className={`col-6`}>
                <div className={`lufga_18px `}>User Type</div>
                <div>
                  <select
                    required
                    name="user_type"
                    className={`lufga_16px ${style.input_text_dropdown}`}
                    id="dropdown"
                    value={userData.user_type}
                    onChange={handleDropdownChange}
                  >
                    <option value="" selected disabled>
                      Select user type
                    </option>
                    <option value="Surrogate mother">Surrogate Mother</option>
                    <option value="Intended Parent">Intended Parent</option>
                    <option value="Embryo Donor">Embryo Donor</option>
                    <option value="Adopting Parent">Adopting Parent</option>
                  </select>
                </div>
              </div>
            </div>

            <div className={`row mt-3`}>
              <div className={`col-6`}>
                <div>
                  <div className={`lufga_18px`}>Validity</div>
                  <div>
                    <select
                      required
                      name="validity"
                      className={`lufga_16px ${style.input_text_dropdown}`}
                      id="dropdown"
                      value={userData.validity}
                      onChange={handleDropdownChange}
                    >
                      <option value="" selected disabled>
                        Select Validity
                      </option>
                      <option value="1 Months">1 Months</option>
                      <option value="3 Months">3 Months</option>
                      <option value="6 Months">6 Months</option>
                      <option value="12 Months">12 Months</option>
                      <option value="24 Months">24 Months</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className={`col-6`}>
                <div className={`lufga_18px`}>Amount per user</div>
                <div>
                  <input
                    required
                    onChange={handleChnage}
                    className={`lufga_16px form-control`}
                    name="amount"
                    value={userData.amount}
                    type="number"
                    placeholder="Enter amount"
                  />
                </div>
              </div>
            </div>

            <div className={`row mt-3`}>
              <div className={`col-md-12`}>
                <label
                  className={`form-check-label lufga_18px d-flex align-items-center ${style.LabelText}`}
                  htmlFor="flexCheckDefault"
                >
                  Plan Description
                  <span className={`${style.words_left}`}>
                    (Remaining: {limit - wordCount} / {limit})
                  </span>
                </label>
                <textarea
                  required
                  rows={2}
                  id="plan_description"
                  type="text"
                  className="form-control lufga_16px"
                  placeholder="Enter Plan Description"
                  name="plan_description"
                  value={userData.plan_description}
                  onChange={handleTextChange}
                />
              </div>
            </div>

            <div className={`row mt-3`}>
              <div className="col-md-12">
                <label
                  className={`form-check-label lufga_18px ${style.LabelText}`}
                  htmlFor="flexCheckDefault"
                >
                  Features
                </label>
                <div>
                  <select
                    name="included_features"
                    className={`lufga_16px ${style.input_text_dropdown}`}
                    onChange={handleDropdownChangeFeature}
                    value={includedFeature}
                  >
                    <option value="" disabled selected>
                      Select a feature
                    </option>

                    {featuredata.map((res) => (
                      <option key={res.feature_name} value={res.feature_name}>
                        {res.feature_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className={style.arr}>
              {featureinc.map((feature, index) => (
                <div key={index}>
                  <div className={`${style.arr_list} lufga_14px`}>
                    {feature}{" "}
                    <RxCross2
                      style={{ color: "#358C99" }}
                      onClick={() => removeFeature(feature, true)}
                    />
                  </div>
                </div>
              ))}
            </div>

            <div className={`row mt-3`}>
              <div className="col-md-12">
                <label
                  className={`form-check-label lufga_18px ${style.LabelText}`}
                  htmlFor="flexCheckDefault"
                >
                  Features Not Included
                </label>
                <div>
                  <select
                    name="not_included_features"
                    className={`lufga_16px  ${style.input_text_dropdown}`}
                    onChange={handleDropdownChangeFeature}
                    value={excludedFeature}
                  >
                    <option value="" disabled selected>
                      Select a feature to Not Include
                    </option>

                    {featuredata.map((res) => (
                      <option key={res.feature_name} value={res.feature_name}>
                        {res.feature_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className={style.arr}>
              {featurenotinc.map((feature, index) => (
                <div key={index}>
                  <div className={`${style.not_arr_list} lufga_14px`}>
                    {feature}{" "}
                    <RxCross2
                      style={{ color: "#DC1919" }}
                      onClick={() => removeFeature(feature, false)}
                    />
                  </div>
                </div>
              ))}
            </div>

            <div className="d-flex mt-5 justify-content-end gap-4">
              <button
                type="button"
                className={style.cancelBtn}
                onClick={() => navigate("/superadmin/subscriptionplan")}
              >
                Cancel
              </button>

              <button type="submit" className={`theme_button lufga_16px`}>
                Save
              </button>
            </div>
          </div>
        </div>
      </form>

      <Loader open={loader} />
    </div>
  );
};

export default AddPlan;
