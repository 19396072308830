import React, { useEffect, useState } from "react";
import style from "./EditNews.module.css";
import { useNavigate, useParams } from "react-router-dom";
// import { addNews } from "../../../../Api/news";
import { updateNews, getNewsById } from "../../../../Api/news";
import { uploadImage } from "../../../../Api/SurrogateMotherApis";
// import { uploadDocument } from "../../../../Api/CommonApis";
// import { RxCross2 } from "react-icons/rx";
// import { RxCross1 } from "react-icons/rx";
import { ImCross } from "react-icons/im";
import moment from "moment";
import Loader from "../../../common/Loader";

function CreateNews() {
  const [imageSrc, setImageSrc] = useState(null);
  const [image, setImage] = useState(null);
  const [getDataById, setGetDataById] = useState([]);
  const [loader, setLoader] = useState(false);
  let params = useParams();

  const navigate = useNavigate();

  const [data, setData] = useState({
    news_headline: "",
    news_headline_zh:"",
    news_headline_es:"",
    news_link: "",
    news_date: "",
    news_source_name: "",
    news_source_name_zh:"",
    news_source_name_es:"",
    news_photo_name: "",
    news_photo_url: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // setData({ ...data, [name]: value });
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
    console.log(data);
  };
  const handleChange2 = (e) => {
    setData({ ...data, news_date: e.target.value });
  };

  const getNewsDataById = async () => {
    await getNewsById(params.id)
      .then((res) => {
        console.log(res.data.data);
        setGetDataById(res.data.data);
        setData(res.data.data)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getNewsDataById();
  }, []);

  const handleSubmitCreate = async (e) => {
    e.preventDefault();
    let imageDataupload = await uploadImage(image);
    let objdata = {
      news_photo_url: imageDataupload.data.fileLocation,
      news_photo_name: imageDataupload.data.fileName,
    };
    let obj = { ...data, ...objdata };
    await updateNews(params.id, obj)
      .then((res) => {
        console.log(res);
        navigate("/superadmin/latestnews");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    setImage(file);
    reader.onload = () => {
      setImageSrc(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
      // console.log(file);
    }
  };
  return (
    <>
      <div className="m-3">
        <div className="lufga_36px fw_700">Edit News</div>
        <form onSubmit={handleSubmitCreate}>
          <div className="col-md-12 mt-5">
            <label
              htmlFor="headline"
              className={`form-check-label mb-1 lufga_18px ${style.LabelText}`}
            >
              Headline<span className="text-danger"></span>
            </label>
            <input
              type="text"
              className="form-control mt-1"
              placeholder="Enter Headline"
              name="news_headline"
              defaultValue={getDataById.news_headline}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="col-md-12 mt-5">
            <label
              htmlFor="headline"
              className={`form-check-label mb-1 lufga_18px ${style.LabelText}`}
            >
              Titular<span className="text-danger"></span>
            </label>
            <input
              type="text"
              className="form-control mt-1"
              placeholder="Introduzca el título"
              name="news_headline_es"
              defaultValue={getDataById.news_headline_es}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="col-md-12 mt-5">
            <label
              htmlFor="headline"
              className={`form-check-label mb-1 lufga_18px ${style.LabelText}`}
            >
               标题<span className="text-danger"></span>
            </label>
            <input
              type="text"
              className="form-control mt-1"
              placeholder="输入标题"
              name="news_headline_zh"
              defaultValue={getDataById.news_headline_zh}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="row mt-5">
            <div className="col-md-6">
              <div className={`lufga_20px fw_400 mb-1`}>Link</div>
              <input
                type="text"
                className={`lufga_16px form-control`}
                name="news_link"
                defaultValue={getDataById.news_link}
                onChange={handleInputChange}
                required
              />
            </div>
          
            <div className="col-md-6">
              <div className={`lufga_20px fw_400 mb-1`}>Date</div>
              <input
                type="date"
                className={`lufga_16px form-control`}
                name="news_date"
                defaultValue={moment(getDataById?.news_date).format("YYYY-MM-DD")}
                onChange={handleChange2}
                required
              />
            </div>
          </div>

          <div className="row mt-5">
          <div className="col-md-4">
              <div className={`lufga_20px fw_400 mb-1`}>Source</div>
              <input
                type="text"
                className={`lufga_16px form-control`}
                name="news_source_name"
                defaultValue={getDataById.news_source_name}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="col-md-4">
              <div className={`lufga_20px fw_400 mb-1`}>Fuente</div>
              <input
                type="text"
                className={`lufga_16px form-control`}
                name="news_source_name_es"
                defaultValue={getDataById.news_source_name_es}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="col-md-4">
              <div className={`lufga_20px fw_400 mb-1`}>来源</div>
              <input
                type="text"
                className={`lufga_16px form-control`}
                name="news_source_name_zh"
                defaultValue={getDataById.news_source_name_zh}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>


          <div className={`${style.Images_Border}`}>
            <label htmlFor="fileInput" className={style.center_content}>
              {!imageSrc && !getDataById.news_photo_url && (
                <>
                  <i className={`bi bi-card-image mb-3 ${style.imagesCustom}`}></i>
                  <div>
                    <div className={style.UploadName}>Upload or Drop your image</div>
                  </div>
                </>
              )}
              {imageSrc && (
                <div style={{ display: "flex", width: '200px', height: '200px' }}>
                  <div className={style.crossMain}>
                    <span onClick={(e) => { e.stopPropagation(); e.preventDefault(); setImageSrc(null) }} className={style.crossIcon}><ImCross style={{ fontSize: '900px' }} /></span>
                    <img src={imageSrc} alt="Uploaded" className={style.uploadImg} />
                  </div>
                </div>
              )}
              {!imageSrc && getDataById.news_photo_url && (
                <div style={{ display: "flex", width: '200px', height: '200px' }}>
                  <div className={style.crossMain}>
                    <span onClick={(e) => { e.stopPropagation(); e.preventDefault(); setGetDataById({ ...getDataById, news_photo_url: null }); }} className={style.crossIcon}><ImCross className={style.iconsize} /></span>
                    <img src={getDataById.news_photo_url} alt="Uploaded" className={style.uploadImg} />
                  </div>
                </div>
              )}
              <input
                type="file"
                id="fileInput"
                accept="image/*"
                className={style.uploadImg}
                onChange={handleImageUpload}
                style={{ display: "none" }}
                disabled={imageSrc || getDataById.news_photo_url}
              />
            </label>
          </div>








          <div className="d-flex mt-5 justify-content-end gap-4">
            <button
              type="button"
              className={style.cancelBtn}
              onClick={() => navigate("/superadmin/latestnews")}
            >
              Cancel
            </button>
            <button type="submit" className={style.SaveButton}>
              Save Changes
            </button>
          </div>
        </form>
      </div>
      <Loader open={loader} />
    </>

  );
}

export default CreateNews;
