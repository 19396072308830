import React, { useState } from 'react'
import { Dialog } from "primereact/dialog";
import Toaster from './Toaster';
import style from "./common.module.css";
import PaymentSuccessFull from '../IntendedParentsComponent/Auction/BidNowDialog/PaymentSuccessFull';


export default function CommonPaymentDialogBox(props) {
 const { visible, payment, setOutDialog, setVisible, setPaymentData,otherFunction,functionData,reloadData} = props
 
  const [selectPaymentMethod, setSelectPaymentMethod] = useState("paypal");
  const [toaster, setToaster] = useState({
    show: false,
    message: "",
  });
  const [openSucees,setOpenSuccess] = useState(false)
async function paymentFunction(){
   await otherFunction(functionData)
    setOutDialog(false);
    setVisible(false)
    reloadData()
    // setToaster({
    //   show: true,
    //   message:"Payment Successful",
    // });
    setOpenSuccess(true)
}
  return (
    <>
      <Toaster
        event={toaster.message}
        show={toaster.show}
        severity={"success"}
        setToaster={setToaster}
      />
       <Dialog
        closable={false}
        visible={visible}
       
        contentClassName="p-lg-5 p-md-4 p-sm-4 p-3 "
        style={{ width: "750px" }}
        breakpoints={{ "960px": "700", "790px": "90vw" }}
      >
        <div className='d-flex justify-content-center'>
          <span className={`${style.header}`}>Choose How To Pay</span>
        </div>
        <div className='d-flex justify-content-center'>
        <span className={`${style.discription}`}>Payment Method</span>
        </div>
          <div className="d-flex align-items-center mt-4 mb-5">
          <input
            className="form-check-input me-3"
            type="radio"
            value="paypal"
            checked={selectPaymentMethod === "paypal"}
            id="flexRadioDefault1"
            onChange={(e) => setSelectPaymentMethod(e.target.value)}
          />
          <label className="fs-5 label" htmlFor="flexRadioDefault1">
            Paypal
          </label>
        </div>
        <hr />
        <div className="d-flex align-items-center my-5">
          <input
            className="form-check-input me-3"
            type="radio"
            value="cashApp"
            checked={selectPaymentMethod === "cashApp"}
            id="flexRadioDefault"
            onChange={(e) => setSelectPaymentMethod(e.target.value)}
          />
          <label className="fs-5 label" htmlFor="flexRadioDefault">
            Cash App
          </label>
        </div>
        <div className="d-flex justify-content-end gap-2">
          <button
            className="btn-Secondary"
            onClick={() =>
              {setVisible(false)}
            }
          >
            Cancel
          </button>
          <button className="btn-Primary" onClick={() =>{paymentFunction()}}>
            Pay ${payment}
          </button>
        </div>
      </Dialog>
      <PaymentSuccessFull openSucees={openSucees}/>
    </>
  )
}
