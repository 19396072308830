import React, { useState } from 'react'
import style from './Client.module.css'
import { Pagination } from 'reactstrap'
import { TableContainer } from '../../../../../common/TableContainer'
import { InputText } from 'primereact/inputtext'
import { useNavigate } from 'react-router'
import { ConfirmDelete } from '../../../../../common/ConfirmDelete'
import { RiDeleteBinLine } from 'react-icons/ri'
import { deleteUser } from '../../../../../../Api/admin'
import Loader from '../../../../../common/Loader'
import moment from 'moment'


const ClientTable = ({ data, update, role }) => {

    const [loader, setLoader] = useState(false);
    const navigate = useNavigate()

    const roleToRoute = {
        'Surrogacy': '/surrogate/myprofile',
        'IntendedParent': '/intendedParent/myprofile',
        'IvfClinic': '/ivfclinic/myprofile',
        'Lawyer': '/lawyer/myprofile',
    };

    const deleteuser = async (obj) => {
        setLoader(true);

        await deleteUser(obj, { isDelete: true })
            .then((res) => {
                console.log(res)
            })
            .catch((res) => {
                console.log(res);
            })
            .finally(() => {
                update();
                setLoader(false)
            })

    };


    // Function to handle the click event
    const handleViewClick = (id) => {
        navigate(`intendeduser/${id}`)

        // const route = roleToRoute[userRole];
        // if (route) {
        //     navigate(route);
        // } else {
        //     console.error('No route defined for this role');
        // }
    };

    function convertDate(res) {

        const date = res ? new Date(res) : null;

        const formattedDate = date ? `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}` : '';

        return formattedDate;
    }

    return (

        <div className={style.ClientTable}>
            <Loader open={loader} />

            <TableContainer className={"table_container"}>
                <table>



                    <thead>
                        <tr>

                            <th>Name</th>
                            <th>User ID</th>
                            <th>Plan Type</th>
                            <th>Account Created</th>
                            <th>Source</th>
                            <th></th>
                        </tr>


                    </thead>


                    <tbody>
                        {data.length > 0 ? data.map((res, index) => (


                            <tr key={index}

                            >

                                <td className={`d-flex align-items-center`} style={res.user_blocked === true ? { "opacity": '0.53' } : {}}>
                                    <span className={` me-3  ${style.ProfileImages} ${res.user_blocked === true ? style.block_style : ''}
                    `}>
                                        <img src={res?.imageUrl || '/images/blankProfile.png'} alt="jjmage"
                                            className={` me-3  ${style.ProfileImages}`} />
                                    </span>
                                    {res?.fullName}
                                </td>
                                <td style={res.user_blocked === true ? { "opacity": '0.53' } : {}}>{res?.memberId}</td>
                                <td style={res.user_blocked === true ? { "opacity": '0.53' } : {}}>
                                    {res?.subscription_plan?.plan_name
                                        ? res?.subscription_plan?.plan_name
                                        : "Free"}
                                </td>
                                <td style={res.user_blocked === true ? { "opacity": '0.53' } : {}}>{res.createdAt ? moment(res.createdAt).format("DD/MM/YYYY") : "-N/A-"}</td>

                                <td style={res.user_blocked === true ? { "opacity": '0.53' } : {}}>
                                    {res.source ? res.source : "-N/A-"}
                                </td>

                                <td className="d-flex align-items-center gap-3">
                                    <button
                                        className={style.ViewButton}
                                        onClick={() => handleViewClick(res._id)}>
                                        <i className="bi bi-eye d-flex align-items-center "></i>{" "}
                                        View
                                    </button>
                                    <ConfirmDelete onDelete={deleteuser} data={res._id} header="Delete Intended Parent" deleteMsg="Are you sure you want to delete this Intended parent">
                                        <button
                                            className={`d-flex align-items-center gap-1 ${style.ViewButton}`}

                                        >
                                            <RiDeleteBinLine className="text-danger" />
                                            Delete
                                        </button>
                                    </ConfirmDelete>

                                </td>



                            </tr>

                        )) : (
                            <tr style={{height:"60vh"}}>
                              <td colSpan="8" className="text-center">
                                <h1>No data available</h1>
                              </td>
                            </tr>
                          )}
                    </tbody>



                </table>
            </TableContainer>

        </div>

    )
}

export default ClientTable