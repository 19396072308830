import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import style from "./LatestNews.module.css";
import { Link } from "react-router-dom";
import { useState } from "react";
import PopOver from "../../../commonComponents/popover/popover";
import { PopoverItem } from "../../../commonComponents/popovericon/popovericon";
import { useNavigate } from "react-router-dom";
import { LiaEdit } from "react-icons/lia";
import { getAllNews } from "../../../../Api/news";
// import DeleteNews from "../DeleteNews/DeleteNews";
import Loader from "../../../common/Loader";
const LatestNews = (outsidecall) => {
  const navigate = useNavigate();


  const [cards, setCards] = useState([]);
  const { t } = useTranslation();
  const [resId, setResId] = useState();
  const [resume, setResume] = useState(false);
  const [loader, setLoader] = useState(false);

  const [allNewsData, setAllNewsData] = useState([]);

  const getAllNewsData = async () => {
    setLoader(true);
    await getAllNews()
      .then((res) => {
        console.log(res.data.data);
        setAllNewsData(res.data.data.slice(0, 3));
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      })
  };

  function refreshData() {
    getAllNewsData();
  }
  useEffect(() => {
    getAllNewsData();
  }, []);

  const changedate = (dt) => {
    const inputDate = new Date(dt);

    const formattedDate = inputDate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    return formattedDate;
  };

  const Linkforward = (link) => {

    window.open(link, "_blank");
  };


  const sortedNewsData = allNewsData.sort((a, b) => {
    return new Date(b.news_date) - new Date(a.news_date);
  });



  return (
    <div>
      <Loader open={loader} />
      <div className={`${outsidecall?null:`container-fluid`}`}>
        <div className="d-flex mt-3 justify-content-between">
          <div className={`lufga_26px fw_600 ${!outsidecall?`fw_600`:null}`}>Latest News</div>
          <Link
            style={{ height: "100%" }}
            to={"https://www.newhopepoints.org/latestnews"}
            target={"_blank"}
            className={`d-flex align-items-end ${style.linkStyle}`}
          >
            <div className={`lufga_18px fw_400 theme_color`}>View All News</div>
          </Link>
        </div>
        <div className="row justify-content-start">
          {allNewsData?.map((card, index) => (
            <div
              key={index}
              className={`col-11 col-sm-5 col-md-4 p-lg-3 p-md-1 p-sm-2 p-2 ${outsidecall ? 'p-lg-t-0 p-md-t-0 p-sm-t-0 p-t-0' : ''}`}
            >
              <div className={`${style.card_wrapper} h-100`}>

                <Link
                  onClick={() => Linkforward(card.news_link)}
                  style={{ height: "100%" }}
                  className="d-flex align-items-end"
                >
                  <div className={`h-100 card ${style.card}`}>
                    <img
                      className={style.top_img}
                      src={`${card.news_photo_url}`}
                      alt={"Card image cap"}
                    />

                    <div className="card-body d-flex flex-column justify-content-between">
                      <div className="lufga_20px fw_400 px-2">
                        {card.news_headline}
                      </div>
                      <div className="lufga_18px d-flex align-items-center justify-content-between mt-3">
                        <div className="d-flex align-items-end">
                          <span className="custom_gray lufga_12px d-flex align-items-center">
                            {card.news_source_name}
                          </span>
                        </div>
                        <div className="lufga_12px custom_gray d-flex align-items-end">
                          {changedate(card.news_date)}
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>

    </div>
  );
};

export default LatestNews;