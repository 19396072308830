import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router";
import style from "./Setting.module.css";
import { Dialog } from "primereact/dialog";
import Loader from "../../common/Loader";
import {
  addFaqData,
  getFaqDatabyrole,
  deleteFaqById,
  getdataFaqById,
  updateFaqById,
} from "../../../Api/admin";
import PopOver from "../../commonComponents/popover/popover";
import { PopoverItem } from "../../commonComponents/popovericon/popovericon";

import { LiaEdit } from "react-icons/lia";
import Toaster from "../../common/Toaster";

export default function FaqSurrogate() {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [toaster, setToaster] = useState({
    show: false,
    message: "",
  });
  const [formatData, setFormatData] = useState({
    question: "",
    answer: "",
  });
  const [getData, setData] = useState([]);

  const { mode } = useParams();
  const [resId, setResId] = useState();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const trimValue = value.trim();

    setFormatData((prevState) => ({
      ...prevState,
      [name]: trimValue,
    }));
  };

  const addFaqSurrogate = async (e) => {
    e.preventDefault();
    setLoader(true);
    if (formatData.question === "" || formatData.answer === "") {
      setToaster({
        show: true,
        message: "All Fields are Mandatory",
      });
      setLoader(false);
      return;
    }
    let payLoadData = { ...formatData };
    await addFaqData(mode, payLoadData)
      .then((res) => {
        // console.log(res);
        setLoader(false);
        setVisible(false);
        getDataFaqByRole();
      })
      .catch((err) => {
        console.log(err);

        setLoader(false);
      });
  };

  const editFaqSurrogate = async (e) => {
    e.preventDefault();
    setLoader(true)
    if (formatData.question === "" || formatData.answer === "") {
      setToaster({
        show: true,
        message: "All Fields are Mandatory",
      });
      setLoader(false);
      return;
    }
    let payLoadData = { ...formatData };
    await updateFaqById(resId, payLoadData)
      .then((res) => {
        // console.log(res);
        setLoader(false);
        setEditVisible(false);
        setFormatData({
          question: "",
          answer: "",
        });
        getDataFaqByRole();
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  const getDataFaqByRole = async () => {
    setLoader(true);
    await getFaqDatabyrole(mode)
      .then((res) => {
        console.log(res);
        setLoader(false);
        setData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };
  const getfaqData = async (id) => {
    setLoader(true);
    setResId(id);
    let faq = await getdataFaqById(id).catch((err) => console.log(err));
    console.log(faq.data.data);
    setFormatData(faq.data.data);
    setLoader(false);
  };
  const deleDataFaq = async (id) => {
    setLoader(true)
    await deleteFaqById(id)
      .then((res) => {
        setLoader(true);
        console.log(res);
        getDataFaqByRole();
        setDeleteModal(false);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  useEffect(() => {
    getDataFaqByRole();
  }, []);

  return (
    <div>
      {toaster && (
        <>
          <Toaster
            event={toaster.message}
            show={toaster.show}
            severity={"error"}
            setToaster={setToaster}
          />
        </>
      )}
      <Loader open={loader} />
      <Dialog
        closable={false}
        visible={deleteModal}
        className="p-0 m-0"
        onHide={() => setDeleteModal(false)}
        style={{ width: "40vw", padding: "0px", margin: "0px" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <div className={`d-flex justify-content-start ${style.MainHeading}`}>
          Delete FAQ
        </div>

        <div className="">Are you sure you want to delete this FAQ ?</div>

        <div className="d-flex justify-content-end mt-4 gap-3">
          <button
            className={style.CencelButton}
            onClick={() => {
              setDeleteModal(false);
            }}
          >
            Cancel
          </button>
          <button
            className="btn btn-danger"
            onClick={() => {
              deleDataFaq(resId);
              console.log(resId);
            }}
          >
            Delete <i className="pi pi-trash" />
          </button>
        </div>
      </Dialog>

      <Dialog
        closable={false}
        visible={editVisible}
        className="p-0 m-0"
        onHide={() => setEditVisible(false)}
        style={{ width: "40vw", padding: "0px", margin: "0px" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <div>
          <form onSubmit={editFaqSurrogate}>
            <div
              className={`d-flex justify-content-center ${style.MainHeading}`}
            >
              Edit Question
            </div>
            <div className="row mt-3">
              <div className="col-md-12">
                <label
                  className={`form-check-label mb-1 ${style.LabelText}`}
                  htmlFor="clinicname"
                >
                  Question
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter question"
                  name="question"
                  defaultValue={formatData?.question}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12">
                <label
                  className={`form-check-label mb-1 ${style.LabelText}`}
                  htmlFor="clinicname"
                >
                  Answer
                </label>
                <textarea
                  rows="5"
                  className="form-control"
                  placeholder="Enter answer"
                  name="answer"
                  defaultValue={formatData?.answer}
                  onChange={handleInputChange}
                  required
                ></textarea>
              </div>
            </div>
            <div className="d-flex justify-content-end gap-3 mt-4">
              <button
                className={style.CencelButton}
                onClick={() => setEditVisible(false)}
              >
                Cancel
              </button>
              <button className={style.SubmitButton} type="submit">
                Submit
              </button>
            </div>
          </form>
        </div>
      </Dialog>

      <Dialog
        closable={false}
        visible={visible}
        className="p-0 m-0"
        onHide={() => setVisible(false)}
        style={{ width: "40vw", padding: "0px", margin: "0px" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <div>
          <form onSubmit={addFaqSurrogate}>
            <div
              className={`d-flex justify-content-center ${style.MainHeading}`}
            >
              Add Question
            </div>
            <div className="row mt-3">
              <div className="col-md-12">
                <label
                  className={`form-check-label mb-1 ${style.LabelText}`}
                  htmlFor="clinicname"
                >
                  Question
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter question"
                  name="question"
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12">
                <label
                  className={`form-check-label mb-1 ${style.LabelText}`}
                  htmlFor="clinicname"
                >
                  Answer
                </label>
                <textarea
                  rows="5"
                  className="form-control"
                  placeholder="Enter answer"
                  name="answer"
                  onChange={handleInputChange}
                  required
                ></textarea>
              </div>
            </div>
            <div className="d-flex justify-content-end gap-3 mt-4">
              <button
                className={style.CencelButton}
                onClick={() => setVisible(false)}
              >
                Cancel
              </button>
              <button
                className={style.SubmitButton}
                //onClick={() => setAddDocument(false)}
                type="submit"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </Dialog>
      <div className="mainContainer">
        <div className="mb-4">
          <div
            className="d-flex align-items-center gap-2 btn-Secondary p-0 pointer"
            onClick={() => navigate("/superadmin/setting/faqselect")}
          >
            <FaArrowLeft /> Back
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <div className={`${style.t_heading}`}>Frequently Asked Questions</div>
          <button
            className="btn-Primary"
            onClick={() => {
              setVisible(true);
            }}
          >
            <i class="bi bi-plus" style={{ fontSize: "25px" }}></i> Add Question
          </button>
        </div>

        <div className="CardStyle p-md-4 p-sm-3 p-2 mt-3">
          <div className={`${style.get_faqStyle}`}>
            {getData.map((res, index) => {
              return (
                <>
                  <div
                    key={index}
                    style={{ borderBottom: "1px solid #EBEBEB" }}
                    className="p-4"
                  >
                    <div className="d-flex justify-content-between">
                      <div className={`${style.question_style}`}>
                        {res.question}
                      </div>
                      <div>
                        <PopOver
                          onClick={() => {
                            setResId(res._id);
                            // console.log(res._id);
                          }}
                        >
                          <PopoverItem
                            text={"Edit"}
                            icon={<LiaEdit style={{ fontSize: "1.5rem" }} />}
                            onClick={() => {
                              getfaqData(res._id);
                              setEditVisible(true);
                            }}
                          />

                          <PopoverItem
                            style={{ color: "red" }}
                            text={"Delete"}
                            icon={<i className="pi pi-trash" />}
                            onClick={() => {
                              setDeleteModal(true);
                              setResId(res._id);
                            }}
                          />
                        </PopOver>
                      </div>
                    </div>
                    <div>{res.answer}</div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
