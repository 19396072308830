import React, { useEffect, useState } from "react";
import style from "./SocialPages.module.css";

import AddSocialMedia from "./Add platform/Platform";
import EditSocialMedia from "./EditPages/Editsocialpages";
import { getSocialPage, getUserDataById } from "../../../../Api/admin";
import Loader from "../../../common/Loader";

const SocialPages = () => {
  const [resume, setResume] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [Socialpageedit, setSocialpageedit] = useState(false);
  const [visible,setVisible]=useState(false)
  const [loader, setloader] = useState(true)
  const [user, setUser] = useState()

  const [socialMedia, setSocialMedia] = useState([
    {
      logo: "social_pages_insta_icon_image.png",
      name: "Instagram",
      Link: "www.instagram.com/NewHopePoints/",
    },
    {
      logo: "social_pages-facebook_image.png",
      name: "Facebook",
      Link: "www.instagram.com/NewHopePoints/",
    },
    {
      logo: "social_pages_tiktok_image.png",
      name: "Tik Tok",
      Link: "www.instagram.com/NewHopePoints/",
    },
  ]);

  

  const fetchSuperAdminData = async()=>{

    let result = JSON.parse(localStorage.getItem("userData"))
    let res = await getUserDataById(result.userId) 
    setUser(res.data.data)
    console.log(res)
  }

  const [data,setData] = useState([])
  const [id,setId] = useState("")

  const fetchSocialPage= async()=>{
    try{
      const result = await getSocialPage() 
     setData(result.data.data)
     setloader(false)
      console.log(result.data.data)
    }catch(err){
      console.log(err)
    }

  }

  const editHandle = (id)=>{


    console.log(id)
    setSocialpageedit(true)
    setId(id)
  }

  useEffect(()=>{
    fetchSocialPage()
    fetchSuperAdminData()
  },[refresh])

  return (
    <div className="m-4">
       <Loader open={loader} />
    <div className={style.social_pages_background}>
      <div className={style.social_pages_text}>
        <div className={`lufga_18px fw_600 ls_1`}>Social Media Handles</div>
        <button className={` ${style.addBtn} `} onClick={() => setResume(true)}>
          + Add Platform
        </button>
      </div>
      <div className="lufga_18px mt-4">
        Here are your connected social handles
      </div>

      {data?.map((item, index) => (
        <div key={index} className={style.social_handel}>
          <div className={style.logo}>
            <img src={item.platform_logo_url} alt="" style={{width:"50px",height:"50px"}}/>
            <div className="lufga_16px fw_600">{item.platform}</div>
          </div>

          <div className={style.input_text}>
            <div className={style.main_input}>{item.platformLink}</div>
            <div>
              <img
                onClick={()=>
                  editHandle(item?._id)}

                src="/images/social_pages_edit_img.png"
                alt=""
              />
            </div>
          </div>
        </div>
      ))}

      <AddSocialMedia resumeone={resume} setResumeone={setResume}
                refresh={refresh} 
                setRefresh={setRefresh}
                setVisible={setVisible}
                visible={visible}
      />
      <EditSocialMedia
        EditSocialpage={Socialpageedit}
        setEditSocialpage={setSocialpageedit}
        id={id}
        refresh={refresh} 
        setRefresh={setRefresh}
      />
    </div>
    </div>
  );
};

export default SocialPages;
