import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { getAboutData } from "../../../Api/admin";
import Loader from "../../common/Loader";
import { FaArrowLeft } from "react-icons/fa6";
import style from "./Setting.module.css"


export default function AboutUsIp() {
  const [loader, setLoader] = useState(true);
  const [getData, setGetData] = useState("");
  let navigator = useNavigate();
  const getDataAbout = async () => {
    await getAboutData()
      .then((res) => {
        console.log(res);
        setGetData(res?.data?.data[0]?.about_data);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getDataAbout();
  }, []);
  return (
    <div>
      <Loader open={loader} />
      <div className="mainContainer">
        <div className="mb-4">
          <div
            className="d-flex align-items-center gap-2 btn-Secondary p-0 pointer"
            onClick={() => navigator("/intendedParent/settings")}
          >
            <FaArrowLeft /> Back
          </div>
        </div>
        <div className={`${style.t_heading}`}>About Us</div>
        <div className="CardStyle p-md-4 p-sm-3 p-2 mt-2">
          <div dangerouslySetInnerHTML={{ __html: getData }}></div>
        </div>
      </div>
    </div>
  );
}
