import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import style from "./DoctorsList.module.css";
import AddDocument from "./AddDocumentDialog/AddDocument";
import { Link, NavLink } from "react-router-dom";
import { useState } from "react";
import { useNavigate } from 'react-router'

import AuctionForm from "../../SurrogateComponents/Auction/AuctionForm/AuctionForm";

import DoctorView from "./doctorview/doctorview";

import Pop from "../../commonComponents/popover/popover"
import { PopoverItem } from "../../commonComponents/popovericon/popovericon"

import PopOver from "../../common/PopOver";
import BackButton from "../../commonComponents/BackButton/BackButton";
import { useDispatch, useSelector } from "react-redux";
import { deleteDoctorByIdApi, getDoctorsListApi } from "../../../Api/IvfClinic";
import { ConfirmDelete } from "../../common/ConfirmDelete";
import Loader from "../../common/Loader";

const DoctorsList = () => {

    const selector = useSelector(e => e.header);
    const dispatch = useDispatch();

    const navigate = useNavigate()
    const [addocument, setAddDocument] = useState(false);
    const [resume, setResume] = useState(false);
    const [loader, setLoader] = useState(false);
    const [doctorsList, setDoctorsList] = useState([]);
    const [resId, setResId] = useState();
    const [reset, setReset] = useState(false);


    const [visible, setVisible] = useState(false);

    const clinic_id = sessionStorage.getItem("userId")
    console.log("selector on doctor list page", selector)

    const getDoctorsListFuction = async () => {
        setLoader(true);
        try {
            const response = await getDoctorsListApi(clinic_id)
            console.log("response", response)
            setDoctorsList(response.data.data)
        }
        catch (err) {
            console.log("this is error while fetching doctors", err)
        }
        finally {
            setLoader(false)
        }
    }

    const deleteuser = async (obj) => {
        setLoader(true);
        await deleteDoctorByIdApi(obj)
            .then((res) => {
                getDoctorsListFuction();
                setLoader(false);

            })
            .catch((res) => {
                console.log(res);
            })
            .finally(() => {
                setLoader(false)
            })
    };


    useEffect(() => {
        getDoctorsListFuction();
    }, [clinic_id])

    useEffect(() => {
        setReset(prev => !prev);
    }, [doctorsList]);

    let { t } = useTranslation();

    return (
        <div>

            <Loader open={loader} />

            <AddDocument addocument={addocument} setAddDocument={setAddDocument} reload={getDoctorsListFuction} />

            <DoctorView resumeone={resume} setResumeone={setResume} />

            <BackButton path={-1} text="Back" />

            <div className={`container-fluid`}>

                <div className="d-flex justify-content-between my-3">
                    <div className="lufga_24px fw_600">
                        Doctor's List
                    </div>
                    <div>
                        <button className="theme_button" onClick={() => setAddDocument(true)}>
                            + Add new Doctor
                        </button>
                    </div>

                </div>





                {doctorsList.length <= 0
                    ?
                    <div className="d-flex justify-content-center align-items-center flex-column gap-4">
                        <div className="d-flex justify-content-center align-items-center">
                            <img className="img-fluid"
                            style={{maxWidth:"50%"}}
                                src="/images/no_doctor_available_in_clinic.png"
                            />
                        </div>
                        <div className="d-flex justify-content-center align-items-center lufga_24px text-center">No doctors available. Click "Add New Doctor" to add one.</div>

                    </div>
                    :

                    <div
                        // key={index}
                        className={`${style.cardContainer} mt-5`}
                    >
                        {doctorsList?.map((res, index) => (

                            <div className={`${style.maincardContainer} w-100`}>
                                <div
                                    style={{ height: "100%" }}
                                    className="d-flex align-items-end"
                                >
                                    <div className={`w-100  ${style.maincardContainer}`}>
                                        <div
                                            className={`${style.card_upper}`}

                                        >
                                            <div
                                                className={`${style.card_upper_mask}`}
                                                style={{
                                                    backgroundImage: `url('${res?.image_file?.file_url}')`,
                                                }}
                                            >

                                            </div>
                                            <img
                                                className={`${style.card_upper_image}`}
                                                src={res?.image_file?.file_url}
                                            />


                                            <div className={`${style.upper_v_1}`}>
                                                <div className={`${style.upper_v_1_1}`}>
                                                    <div className={`${style.upper_v_1_1_1}`}>

                                                        <Pop reset={reset}
                                                            onClick={() => setResId(res?._id)}>
                                                            <PopoverItem
                                                                text={"Edit"}
                                                                icon={
                                                                    <img
                                                                        src="/images/edit.png"
                                                                        style={{
                                                                            height: "19px",
                                                                            width: "19px",
                                                                        }}
                                                                    />
                                                                }
                                                                onClick={() =>
                                                                    navigate(`doctorvieworedit/${res._id}`, { state: { call: "edit" } })
                                                                }
                                                            />
                                                            <ConfirmDelete onDelete={deleteuser} data={res._id} header="Delete Doctor" deleteMsg="Are you sure you want to delete this doctor account">
                                                                <PopoverItem
                                                                    text={
                                                                        <span style={{ color: "red " }}>
                                                                            Delete
                                                                        </span>
                                                                    }
                                                                    icon={
                                                                        <img
                                                                            src="/images/reddelete.png"
                                                                            style={{
                                                                                height: "18px",
                                                                                width: "18px",
                                                                            }}
                                                                        />
                                                                    }
                                                                    onClick={() => {
                                                                        setVisible(true);
                                                                        setResId(res?._id);
                                                                    }}
                                                                />
                                                            </ConfirmDelete>
                                                        </Pop>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className={`${style.card_lower}`}>
                                            <div
                                                className={`${style.lower_v_1} lufga_18px`}
                                            >
                                                {t(res.firstName)}, EX- {t(res.total_experience)}
                                            </div>
                                            <div className={`${style.lower_v_2}`}>
                                                {t(res.short_descrip)}
                                            </div>
                                            <div
                                                className={`${style.lower_v_3}`}
                                                onClick={() => {

                                                    navigate(`doctorvieworedit/${res._id}`, { state: { call: "view" } });

                                                }}



                                            >
                                                View Details
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        ))}

                    </div>
                }










            </div>

        </div>
    );
};

export default DoctorsList;



