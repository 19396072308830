import React, { useState } from 'react'
import style from "./Credit.module.css"
import Verfied from '../DialogVerified/Verfied'
import { Rating } from "primereact/rating";
const CreditReport = () => {
  const [visibale, setVisibale] = useState(false)
  const [value, setValue] = useState(null);
  return (
    <div className={style.CreditReport_con}>
      <Verfied visibale={visibale} setVisibale={setVisibale} />
      <div className='d-flex justify-content-between mb-2'>
        <div className={style.CreditText}>
          <span>Credit Report</span>
        </div>
        <div>
          <button className={style.ExportButton}>Export Report</button>
        </div>
      </div>
      <div className={`mb-3 ${style.bannersize}`}>
        <div className='d-flex'>
          <div className='p-4'>
            <img src="/images/child5 (2).png" className={style.ProfileImg} alt="" />
          </div>

          <div className='d-flex flex-column align-item-center justify-content-center gap-3'>
            <div className={style.BnnerFirstText}>NPHIP001 : Intended Parents</div>
            <div className={style.BnnersecondtText}>Alexa Smith</div>
            <div className='d-flex gap-3'>
            
              <button className={`text-center d-flex align-items-center ${style.VerfiedButton}`}><i class="bi bi-patch-check text-white text-center d-flex align-items-center me-1" ></i>Verified</button>
             
            <Rating value={value} onChange={(e) => setValue(e.value)} cancel={false} />
        
            </div>
          </div>


        </div>
        <div className='p-4'>
          <img src="/images/child5 (1).png" alt="" className={style.ProfileImg} />
        </div>
      </div>

      <div className='section3 mx-2'>
        <div className="row">
          <div className="col ">
            <div className={style.NumberText}>
              <span className='d-flex justify-content-center'>250</span>
            </div>
            <div className=' d-flex align-item-center justify-content-center mb-3'><img src="/images/pngaaa 1.png"  alt="" /></div>
            <div className={style.scoreText}>
              <span className='d-flex justify-content-center'>Overall Credit Score</span>
            </div>
          </div>
          <div className="col d-flex flex-column gap-4">
            <div className={`${style.box1}`}>
              <div className='d-flex justify-content-center align-item-center'>
                <span className={style.boxText}>Organization 1 : </span>
                <span className={style.boxnumberText}>542</span>
              </div>
            </div>
            <div className={`${style.box2}`}>
              <span className={style.boxText}>Organization 2 : </span>
              <span className={style.boxnumberText}>212</span>
            </div>
            <div className={`${style.box3}`}>
              <span className={style.boxText}>Organization 3 : </span>
              <span className={style.boxnumberText}>321</span>
            </div>

          </div>
          <div className="col ">
            <div className=''>
              <div className={`mb-2 ${style.RemarksText}`}>Remarks:</div>
              <div className={`mb-2 ${style.lastHeding}`}>Your credit score is 250 which is considered low due to this you can't participate in auctions.</div>
              <div className={`${style.lastHeding1}`}>Increase your credit scores by timely payments</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreditReport