import React, { useEffect, useState } from "react";
import style from "./EmbryoSearch.module.css";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
// import ViewDetails from "./ViewDetailsDialog/ViewDetailsEmbryo";
import { InputText } from 'primereact/inputtext';
import {
  addSearchIntendent,
  connectNotification,
  ConnectAddDataSurrogate
} from "../../../Api/IntendedParent";
import { FaArrowLeft } from "react-icons/fa6";
import Loader from "../../common/Loader";
import {
  gethabitsdata,
  getlifestyledata,
  getUserDataById,
} from "../../../Api/admin";
import Toaster from "../../common/Toaster";
const EmbryoSearch = () => {
  let { t } = useTranslation();
  const navigate = useNavigate();
  let params = useParams();
  const [visibale, setVisibale] = useState(false);
  const [loader, setLoader] = useState(false);
  const [getAllHabits, setGetAllHabits] = useState([]);
  const [getAllLifeStyle, setGetAllLifeStyle] = useState([]);
  const [userData, setUserData] = useState();
  const [toaster, setToaster] = useState({
    show: false,
    message: "",
  });

  const [connectedData, setConnectedData] = useState(false);

  const [connectData, setConnectData] = useState()


  // console.log(params)

  const userId = sessionStorage.getItem("userId");

  const getUserDataIP = async () => {
    await getUserDataById(userId)
      .then((res) => {
        console.log(res)
        let responseData = res.data.data;
        // let savedData = responseData.saved_surrogate.includes(surrogate_id)
        setUserData(res.data.data);
        setConnectedData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [formateData, setFormatData] = useState({
    service_offer:
      params.name === " Surrogate Mother" ? "surrogate" : "eggDonor",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormatData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const connectSuperAdmin = async (cardData) => {
    setLoader(true);
    let payLoadData = {
      role: ["admin"],
      super_admin_message: {
        heading: "New Connection Request",
        msg: `${userData.fullName} has requested to connect with ${cardData?.firstName} (SM)`,
      },
      surrogate_id: cardData.userId,
    };
    await connectNotification(userId, payLoadData)
      .then((res) => {
        // console.log(res)
        setToaster({
          show: true,
          message: "Your connection request has been submitted",
        });
        setLoader(false);
        getUserDataIP();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const ConnectLeads = async (cardData) => {

    setLoader(true);

    let payloadLeads = {
      connected_by: {
        id: userId,
        role: userData.role

      },
      connected_to: {
        id: cardData.userId,
        role: params.name === " Surrogate Mother" ? "surrogate_donor" : "eggDonor",
      }
    }
    await ConnectAddDataSurrogate(payloadLeads).then((res) => {
      console.log(res)
      setLoader(false)
      setToaster({
        show: true,
        message: "Your connection request has been submitted",
      });
      addSearchData()
    }).catch((err) => {
      console.log(err)
      setLoader(false)
    })

  }

  const getAllHabitData = async () => {
    await gethabitsdata()
      .then((res) => {
        // console.log(res)
        setGetAllHabits(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllLifeStyleData = async () => {
    await getlifestyledata()
      .then((res) => {
        // console.log(res)
        setGetAllLifeStyle(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addSearchData = async () => {
    let payloadData = { ...formateData, connected_by_id: userId };
    if (payloadData.life_style) {
      payloadData.life_style = [payloadData.life_style];
    }
    if (payloadData.habits) {
      payloadData.habits = [payloadData.habits];
    }
    setLoader(true);

    await addSearchIntendent(payloadData)
      .then((res) => {
        setCards(res.data.data);
        setLoader(false);
        console.log(res)
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  const clearFilter = async () => {
    setFormatData({
      service_offer:
        params.name === " Surrogate Mother" ? "surrogate" : "eggDonor",
    });
    await addSearchIntendent({
      service_offer:
        params.name === " Surrogate Mother" ? "surrogate" : "eggDonor",
    })
      .then((res) => {
        setCards(res.data.data);
        setLoader(false);
        // console.log(res)
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };
  useEffect(() => {
    addSearchData();
    getAllHabitData();
    getAllLifeStyleData();
    getUserDataIP();
  }, []);
  const [cards, setCards] = useState([]);

  //dummy data

  const getAllResidence = [
    {
      "_id": "1",
      "name": "California"
    }, {
      "_id": "2",
      "name": "Gerogia"
    }, {
      "_id": "3",
      "name": "Hawaii"
    }, {
      "_id": "4",
      "name": "California"
    },
  ]

  const getAllRace = [
    {
      "_id": "1",
      "name": "Indian"
    }, {
      "_id": "2",
      "name": "American"
    }, {
      "_id": "3",
      "name": "Japanese"
    },
  ]


  const getAllReligion = [
    {
      "_id": "1",
      "name": "Christianity"
    }, {
      "_id": "2",
      "name": "Islam"
    }, {
      "_id": "3",
      "name": "Buddhism"
    }, {
      "_id": "4",
      "name": "Hinduism"
    },
  ]

  const dummyCards = [
    {
      "userId": "1",
      "photo_url": "/images/embryoSearch1.png",
      "memberId": "NHPSM001",
      "category": "Adopting Parents",
      "description": "Successful Executives , Both work from home , Excited to adopt our first baby"
    }, {
      "userId": "2",
      "photo_url": "/images/embryoSearch2.png",
      "memberId": "NHPSM001",
      "category": "Adopting Parents",
      "description": "Successful Executives , Both work from home , Excited to adopt our first baby"
    }, {
      "userId": "3",
      "photo_url": "/images/embryoSearch3.png",      
      "memberId": "NHPSM001",
      "category": "Adopting Parents",
      "description": "Successful Executives , Both work from home , Excited to adopt our first baby"
    },{
      "userId": "1",
      "photo_url": "/images/embryoSearch1.png",
      "memberId": "NHPSM001",
      "category": "Adopting Parents",
      "description": "Successful Executives , Both work from home , Excited to adopt our first baby"
    }, {
      "userId": "2",
      "photo_url": "/images/embryoSearch2.png",
      "memberId": "NHPSM001",
      "category": "Adopting Parents",
      "description": "Successful Executives , Both work from home , Excited to adopt our first baby"
    }, {
      "userId": "3",
      "photo_url": "/images/embryoSearch3.png",      
      "memberId": "NHPSM001",
      "category": "Adopting Parents",
      "description": "Successful Executives , Both work from home , Excited to adopt our first baby"
    },
  ]

  return (
    <div>
      <Toaster
        event={toaster.message}
        show={toaster.show}
        severity={"success"}
        setToaster={setToaster}
      />
      <Loader open={loader} />

      {/* <ViewDetails visibale={visibale} setVisibale={setVisibale} /> */}
      {/* <div className="mb-4 mt-4 ms-4 w-25">
        {/* <div
          className="d-flex align-items-center gap-2 btn-Secondary p-0 pointer w-50"
          onClick={() => navigate("/intendedParent/search")}
        >
          <FaArrowLeft /> Back
          </div> 
        </div>*/}
      <div className={style.Search_con}>
        <div className={`d-flex justify-content-between mb-2 ${style.UpperContainer}`}>
          <div className={style.CreditText}>Find a Parent</div>
          <div className="d-flex justify-content-evenly" >
            <div className="">
              <div className={style.SearchContainer}>
                <span className={`p-input-icon-left d-flex align-items-center `}>
                  <i className={`pi pi-search ${style.SearchInputIcon}`} />
                  <InputText type="search" placeholder="Search" className={style.InputSearch} />
                </span>
              </div>
            </div>
            <div>
              <button
                className={style.SearchButton}
                onClick={() => navigate("savedcontacts")}
              >
                Saved Contacts
              </button>
            </div>
          </div>
        </div>
        <div className={style.FormCustom}>
          <div className={`${style.styleIngrid}`}>
            <div className="">
              <label
                className={`form-check-label mb-2 ${style.LabelText}`}
                htmlFor="flexCheckDefault"
              >
                {t("Race")}
              </label>
              <select
                className="form-select"
                aria-label="Default select example"
                onChange={handleInputChange}
                name="relationship_status"
                value={formateData?.relationship_status ? formateData?.relationship_status : "noValue"}
              >
                <option value={"noValue"} selected disabled>
                  White
                </option>
                {getAllRace.map((res, index) => {
                  return (
                    <>
                      <option value={res._id}>{res.name}</option>
                    </>
                  );
                })}
              </select>
            </div>
            <div className="">
              <label
                className={`form-check-label mb-2 ${style.LabelText}`}
                htmlFor="flexCheckDefault"
              >
                {t("State of Residence")}
              </label>
              <select
                className="form-select"
                aria-label="Default select example"
                name="residence"
                onChange={handleInputChange}
                value={formateData?.life_style ? formateData?.life_style : "noValue"}

              >
                <option value={"noValue"} selected disabled>
                  White
                </option>
                {getAllResidence.map((res, index) => {
                  return (
                    <>
                      <option value={res._id}>{res.name}</option>
                    </>
                  );
                })}
              </select>
            </div>
            <div className="">
              <label
                className={`form-check-label mb-2 ${style.LabelText}`}
                htmlFor="flexCheckDefault"
              >
                {t("Kids")}
              </label>
              <select
                className="form-select"
                aria-label="Default select example"
                onChange={handleInputChange}
                name="habits"
                value={formateData?.habits ? formateData?.habits : "noValue"}
              >
                <option value={"noValue"} selected disabled>
                  White
                </option>
                {/* {getAllHabits.map((res) => {
                  return (
                    <>
                      <option value={res._id}>{res.name}</option>
                    </>
                  ); 
                })} */}
              </select>
            </div>
            <div className="">
              <label
                className={`form-check-label mb-2 ${style.LabelText}`}
                htmlFor="flexCheckDefault"
              >
                {t("Religion")}
              </label>
              <select
                className="form-select"
                aria-label="Default select example"
                onChange={handleInputChange}
                name="religion"
                value={formateData?.skin_color ? formateData?.skin_color : "noValue"}

              >
                <option value={"noValue"} selected disabled>
                  White
                </option>
                {getAllReligion.map((res, index) => {
                  return (
                    <>
                      <option value={res._id}>{res.name}</option>
                    </>
                  );
                })}
              </select>
            </div>
          </div>

          <div className="d-flex justify-content-center mt-4">
            <div className="d-flex gap-2">
              <button
                className={style.SearchButton}
                onClick={() => {
                  addSearchData();
                }}
              >
                Filter Parent
              </button>
            </div>
          </div>
        </div>

        <div className={`${style.cardContainer} mt-5`}>
          {dummyCards?.map((card, index) => (
            <div key={index} className={`w-100  ${style.maincardContainer}`}>
              <div
                className={`${style.card_upper} pointer`}

              >
                <div
                  className={`${style.card_upper_mask}`}
                //      style={{
                //       backgroundImage: `url(${
                //     card?.photos[
                //       card.photo_profile_index ? card?.photo_profile_index : 0
                //     ]?.fileUrl
                //   })`,
                // }}
                >

                </div>
                <img
                  className={`${style.card_upper_image}`}
                  src={card?.photo_url}
                  alt="Image"
                />
                <div className={`${style.upper_v_1}`}></div>
              </div>
              <div className={`${style.card_lower} d-flex flex-column`}>
                <div className={`${style.cardAgeText} `}>
                  AP Id: {t(card.memberId)}
                </div>
                <div className="d-flex flex-column justify-content-between py-2">
                  {/* <div className={`${style.lower_v_2}  `}>
                   {card?.height?.feet || card?.height?.inches ? `Height:`:null} 
                    {card?.height?.feet || card?.height?.inches
                      ? `${card?.height?.feet}.${card?.height?.inches} Ft,`
                      : ""}{" "}
                    {card?.skin_color}
                  </div> */}
                  <div className={`${style.category}`}>  {t(card.category)}</div>
                  <div className={`${style.lower_v_2}  `}>
                    {t(card.description)}
                  </div>
                  {/* <div>{t(card.description)}</div> */}
                </div>
                <div
                  className={`${style.card_lower_v_1} d-flex flex-column mt-auto`}
                >
                  <div className="d-flex justify-content-between gap-4 mt-auto">
                    {card?.is_connected === true ? (
                      <div className={`${style.lower_v_3}`}>Connected</div>
                    ) : (
                      <button
                        className={`btn ${style.lower_v_3}`}
                        onClick={() => {
                          ConnectLeads(card);
                        }}
                      >
                        Connect
                      </button>
                    )}

                    <button
                      className={`btn ${style.lower_v_Buy}`}
                      onClick={() => {
                        navigate(`/embryo_donor/viewDetail/${card.userId}`);
                      }}
                    >
                      View Details
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EmbryoSearch;
