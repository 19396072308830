import React, { useEffect, useState } from 'react'
import style from "./style.module.css"
import { InputText } from "primereact/inputtext";
import { TableContainer } from '../../common/TableContainer';
import { getAllManageLeadsData } from '../../../Api/admin';
import moment from 'moment';
import { useNavigate } from 'react-router';
import Loader from '../../common/Loader';



export default function ManageLeads() {
  
const [manaLeadsData,setManageLeadsData] = useState([])
const navigator = useNavigate()
const [loader,setLoader] = useState(false)
const [searchData, setSearchData] = useState([])


const getAllData = async()=>{
  setLoader(true)
  await getAllManageLeadsData().then((res)=>{
    setLoader(false)
    console.log(res)
    setManageLeadsData(res.data.data)
    setSearchData(res.data.data)

  }).catch((err)=>{
    console.log(err)
    setLoader(false)
  })
}

function roleData(role){
  if(role === "surrogate_donor"){
    return "Surrogate Mother"
  }else if(role === "embryo_donor"){
      return "Embryo Donor"
  }else if(role === "adopting_parent"){
    return "Adopting Parent"
  }else if(role === "parents"){
    return "Intendent Parent"
  }else if(role === "lawyer"){
    return "Lawyer"
  }else if(role === "clinic"){
    return "Clinic"
  }
}
useEffect(()=>{
  getAllData()
},[])

const handleSearchClick = (e) => {
  const val = e.target.value.trim().toLowerCase();
  const filteredData = searchData.filter((res) => {
    const fullName = res?.connected_to?.account?.fullName?.toLowerCase() || "";
    const connecteeUserId = res?.connected_to?.account?.memberId?.toLowerCase() || "";
    const requestedBy = res?.connected_by?.account?.fullName?.toLowerCase() || "";
    const connectorUserId = res?.connected_by?.account?.memberId?.toLowerCase() || "";
    return (
      fullName.includes(val) ||
      connecteeUserId.includes(val) ||
      requestedBy.includes(val) ||
      connectorUserId.includes(val)
    );
  });
  setManageLeadsData(filteredData);
};

  return (
    <div>
      <div className="d-flex justify-content-between mb-4 mt-3 ">
          <div>
            {" "}
            <span className="lufga_24px fw_600">Manage Leads</span>
          </div>
          
          <div>
              <span className={`p-input-icon-left d-flex align-items-center `}>
                {/* <i className={`pi pi-search ${style.search_icon}`} /> */}
                <div className="searchInput w-100">
                  <input
                    className="form-control ps-5"
                    placeholder="Search"
                    onChange={handleSearchClick}
                  ></input>
                  <i className="pi pi-search searchIcon"></i>
                </div>
              </span>
            </div>
        </div>

        <TableContainer className={"table_container"}>
        <table>
        <thead>
              <tr>
                <th>Name</th>
                <th>User Role</th>
                <th>Connectee User ID</th>
                <th>Requested By</th>
                <th>Connector 
                User ID</th>
                <th>Request Date</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {manaLeadsData.map((res,index)=>{
                return(
                  <>
                  <tr key={index}>
                  <td className='d-flex align-items-center'>
                    {console.log(res?.connected_to?.resume.photos?.length !== 0? res?.connected_to?.resume?.photos[res?.connected_to?.resume?.photo_resume_index]?.fileUrl: "/images/blankProfile.png")}
                    <span>
                      <img src={res?.connected_to?.resume.photos?.length !== 0? res?.connected_to?.resume?.photos[res?.connected_to?.resume?.photo_resume_index?res?.connected_to?.resume?.photo_resume_index:[0]]?.fileUrl: "/images/blankProfile.png"} width={50} height={50} style={{borderRadius:"50%"}}/>
                    </span>
                    <span className='ms-3'>
                      {res?.connected_to?.account?.fullName}
                    </span>
                  </td>
                  <td>
                    {roleData(res?.connected_to?.account?.role)}
                  </td>
                  <td>{res.connected_to?.account?.memberId}</td>
                  <td>{res?.connected_by?.account?.fullName}</td>
                  <td>{res?.connected_by?.account?.memberId}</td>
                  <td>{moment(res?.createdAt).format("DD/MM/YYYY")}</td>
                  <td>
                  <button
                    className={style.ViewButton}
                  onClick={()=>{
                    navigator(`viewDetail/${res?.connected_to?.resume?.userId}`, { state: { requestedData:res.createdAt,requestedBY:res?.connected_by?.account?.fullName} })
                  }}
                  >
                    <i className="bi bi-eye d-flex align-items-center me-1"></i>{" "}
                    View
                  </button>
                </td>
                  </tr>
                  </>
                )
              })}
            </tbody>
        </table>
        </TableContainer>
        <Loader open={loader} />
    </div>
  )
}
