import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { SidebarWrapper } from "../../routes/SidebarWrapper";

export const IntendedParentsAuth = () => {
    const { search } = useLocation()

    function jwtoken() {
        if (search !== '') {
            let token = new URLSearchParams(window.location.search).get("token");
            // let email = new URLSearchParams(window.location.search).get("email");
            
            let userId = new URLSearchParams(window.location.search).get("id");
            let role = new URLSearchParams(window.location.search).get("role");
            sessionStorage.setItem('authToken',token)
            sessionStorage.setItem('userId',userId)
            sessionStorage.setItem('role',role);


            return true
        } else {
            if(sessionStorage.getItem('authToken') && sessionStorage.getItem('userId')){
                return true
            }else{
                return false
            }
        }
    }

    return <>{jwtoken() ? <SidebarWrapper role="intendedparent"><Outlet></Outlet></SidebarWrapper> : <div className="BackPage">Login to Access</div>}</>;
};
