import React, { useState } from 'react'
import style from '../../MyClientTable/Client.module.css'
import { Pagination } from 'reactstrap'
import { TableContainer } from '../../../common/TableContainer'
import { InputText } from 'primereact/inputtext'
import { useNavigate } from 'react-router'
import moment from 'moment';

const AppointmentListTable = ({ data, role }) => {

    const navigate = useNavigate()

    return (

        <div className={style.ClientTable}>


            <TableContainer className={"table_container"}>
                <table>




                    <thead>
                        <tr>

                            <th>
                            Appointment Purpose
                            </th>
                            <th>Date</th>
                            <th>Start Time</th>
                            <th>End Time</th>
                        </tr>


                    </thead>



                    <tbody style={{ minHeight: '200px' }}>
                        {data.length === 0 ?

                            (
                                <tr style={{ height: '200px' }}>
                                    <td style={{ height: '200px', width: '100%' }} colSpan="5">
                                        <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
                                            <div className='lufga_24px'>No Data Available for Appointments</div>
                                        </div>
                                    </td>
                                </tr>
                            )

                            :

                            data.map((res, index) => (


                                <tr key={index}>
                                    <td>
                                        <div className=''>
                                            {res.appointment_purpose}
                                        </div>
                                    </td>
                                    <td>{moment(res.date).format('DD/MM/YYYY')}</td>
                                    <td>{res.start_time}</td>
                                    <td>{res.end_time}</td>
                                   
                                </tr>
                            ))
                        }

                    </tbody>
                </table>
            </TableContainer>

        </div>

    )
}

export default AppointmentListTable