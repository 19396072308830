import { Dialog } from 'primereact/dialog'
import React, { useEffect, useState } from 'react'
import style from './ViewDetailsEmbryo.module.css'


export default function SubscriptionDialog( {open, setOpen}) {

    return (
        <>
    
          <div className={`${style.Sub_Main}`}>
          <Dialog
                // closable={false}
                // visible={open}
                // onHide={() => { }}
                // contentClassName="p-lg-5 p-md-4 p-sm-4 p-3 "
                // style={{ width: "750px" }}
                // breakpoints={{ "960px": "700", "790px": "90vw" }}
                 visible={open} className={style.Sub_Main} style={{ width: '70vw' }} onHide={() => {if (!open) return; setOpen(false); }}
            >
                <div className={`d-flex flex-column justify-content-center align-items-center gap-4`}>
                    
                                    
                    <img src='/images/goldenlock.png' alt='profile'/>
                    
                    <div className={`${style.Sub_Text}`}>
                        Sorry! This service only open to Silver or Gold members Want to join?
                    </div>
                    
                    <button className={`${style.Sub_Btn} mb-2`}>
                        View Subscription Plans
                    </button>
                    
                </div>
            


            </Dialog>
          </div>
        </>
    )
}
