import React from "react";
import UnderConstruction from "../../UnderConstruction/UnderConstruction";
import style from "./Dashboard.module.css";
import { useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";
import { useState } from "react";
import LatestNews from "./LatestNews/LatestNews";
import AuctionPost from "./AuctionPost/auctionPost";
import Latestclasses from "./LatestClasees/Latestclasses";
import ScanCards from "./ScanCards/ScanCards";
import { useEffect } from "react";
import { useNavigate } from "react-router/dist";
import {  getDashboardUser2 } from "../../../Api/CommonApis";
import NoAuctionPost from "./NoAuctionPost/NoAuctionPost";

const Dashboard_ = () => {
  let token = new URLSearchParams(window.location.search).get("token");
  let email = new URLSearchParams(window.location.search).get("email");
  let userId = new URLSearchParams(window.location.search).get("id");
  let navigate = useNavigate();
  const [uppercards, setUpperCards] = useState([
    {
      title: "Current Auction",
      main_content: "40,689",
      graph: "true",
      icon: "hammer_for_dashboard.png",
      percent: "8.5%",
      information_type: "from last bid",
    },
  ]);

  let { t } = useTranslation();

  const getUserDatas = async (id) => {
    if (sessionStorage.getItem("userData")) {
      let userData = JSON.parse(sessionStorage.getItem("userData"));
      const data = await getDashboardUser2(userData.userId);

      let obj = {
        title: "Your Membership Id ",
        main_content: data.data.data.memberId,
        icon: "qr_code_for_dashboard.png",
        information_type: "Recommend to Others",
      };
      setUpperCards([...uppercards, obj]);
    }
  };
  useEffect(() => {
    setUserData();

    getUserDatas();
  }, []);

  function setUserData() {
    if (token && email && userId) {
      sessionStorage.setItem(
        "userData",
        JSON.stringify({ token, userId, email })
      );
      // setTimeout(() => {
        navigate("/surrogate/resume");
      // }, 500);
    } else {
      if (!sessionStorage.getItem("userData")) {
        // alert("user logout out")
      }
    }
  }

  return (
    <>
    {/* <div>
      <div className={`${style.main_container}`}>
        <div className={`${style.surrogate_name} lufga_24px container-fluid`}>
          Hii Rebeca,
        </div>

        <div className={`${style.upper_card_section}`}>
          <ScanCards cardObject={uppercards} />
        </div>
      </div>

      <div>
        <div
          className={`d-flex align-items-center justify-content-between container-fluid`}
        >
          <div className={`lufga_24px`}>My Auction Post</div>
          <div className={`theme_color lufga_24px`}>view all Posts</div>
        </div>

      

        <AuctionPost />
      </div>

      <div>
        <LatestNews />
      </div>

      <div>
        <Latestclasses />
      </div>
    </div> */}
    </>
  );
};
export default Dashboard_;
