import { Dialog } from 'primereact/dialog';
import React from 'react';
import { useState } from 'react'
import style from './VerifyResume.module.css'
import { useTranslation } from 'react-i18next';
import VerifyPaymentDialog from "./PaymentDialog";

const VerifyResume = ({ visibale, setVisibale, verifyResume ,userData }) => {
    const [payment, setPayment] = useState(false)
    let { t } = useTranslation()

    

    return (
        <div className={style.ViewALL}>
            <VerifyPaymentDialog payment={payment} setPayment={setPayment} />
            <Dialog
                closable={false}
                visible={visibale}
                onHide={() => setVisibale(false)}
                contentClassName="p-lg-5 p-md-4 p-sm-4 p-3 "
                style={{ width: "800px" }}
                breakpoints={{ "960px": "700", "790px": "90vw" }}
            >
                <div>
                    <form onSubmit={(e)=>verifyResume(e,userData._id)}>
                        <div className='mb-4'>
                            <div className={`${style.MainHeading}`}>
                                Verify your Resume
                            </div>
                            <div className={style.verifySubtext}>
                                Verify your resume & get a trust badge : Higher Reach & Higher Trust
                            </div>
                        </div>

                        <div className="row ">
                            <div className="col-12 mb-3">
                                <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`}>{t("Full Name")}<span className='text-danger'>*</span></label>
                                <input type="text" required className={`form-control`} defaultValue={userData?.fullName} disabled />
                            </div>

                            <div className="col-12">
                                <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`}>{t("Email Address")}<span className='text-danger'>*</span></label>
                                <input type="email" required className={`form-control`} defaultValue={userData?.email} disabled/>
                            </div>
                        </div>

                        <div className='row mt-5'>
                            <div className='col-12 d-flex justify-content-end gap-2'>
                                <button className='btn-Secondary' type='button' onClick={() => setVisibale(false)}>
                                    Cancel
                                </button>
                                <button className='btn-Primary' type='submit'>
                                    Pay $ 99
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </Dialog>
        </div>
    )
}

export default VerifyResume;