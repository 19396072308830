import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import style from "./AddDocument.module.css";
import { Country, State, City } from "country-state-city";
import { addClinic } from "../../../../../../../Api/admin";
import Toaster from "../../../../../../common/Toaster";

const AddDocument = ({ addocument, setAddDocument, fetchClient }) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [country, setCountry] = useState("");
  const countries = Country.getAllCountries()
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [statesListing, setStatesListing] = useState([]);
  const [citiesListing, setCitiesListing] = useState([]);
  const [loader, setLoader] = useState(false);
  const [toaster, setToaster] = useState({
    show: false,
    message: "",
  });

  const [formData, setFormData] = useState({
    fullName: "",
    mobile_No: "",
    mobile_No_alter: "",
    email: "",
    address: "",
    city: "",
    country: "",
    state: "",
    zip_code: "",
    role: "clinic",
    agree_teams_policy: true,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const trimValue = value.trim();
    setFormData((prevState) => ({
      ...prevState,
      [name]: trimValue,
    }));
  };

  const addSuperAdminClinic = async (e) => {
    e.preventDefault();
    setLoader(true);
    let body = {
      ...formData,
      country: country.split('&&')[0],
      state: state.split('&&')[0],
       city: city.split('&&')[0],
    };
    await addClinic(body)
      .then((res) => {
        console.log(res);
        setLoader(false);
        setAddDocument(false);
        fetchClient()
      })
      .catch((err) => {
        console.log(err);
        if(err?.response?.data?.errormessage === "this email already exists"){
          setToaster({
            show: true,
            message:"This Email Already Exists",
            
          })
          }
          else{
            setToaster({
              show: true,
              message:"Mandatory Fields are required",  
            })
          };
          setLoader(false)
      });
  };

  function getStates(res) {
    let isoCode = res.split('&&')[1]
    setCountry(res)
    let states = State.getStatesOfCountry(isoCode)
    setStatesListing(states)
    console.log(states)
  }

  function getCities(res) {
    let stIsoCode = res.split('&&')[1]
    let cnCode = res.split('&&')[2]
    setState(res)
    let cities = City.getCitiesOfState(cnCode, stIsoCode)
    setCitiesListing(cities)
  }

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      setImageSrc(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };
  return (
    <div>
      {toaster && (
        <>
          <Toaster
            event={toaster.message}
            show={toaster.show}
            severity={"error"}
            setToaster={setToaster}
          />
        </>
      )}
      <Dialog
        closable={false}
        visible={addocument}
        className="p-0 m-0"
        onHide={() => setAddDocument(false)}
        style={{ width: "60vw", padding: "0px", margin: "0px" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <div className="Verfied_con">
          <div className="d-flex justify-content-between ">
            <i
              className="bi bi-chevron-left pointer fs-3"
              onClick={() => setAddDocument(false)}
            ></i>
            <div className={`fW_600 ${style.verfideText}`}>
              Create Account : IVF Clinic
            </div>
            <div></div>
          </div>

          <form onSubmit={addSuperAdminClinic}>
          <div className="row mb-4">
            <div className="col-md-12">
              <label
                className={`form-check-label mb-1 ${style.LabelText}`}
                htmlFor="clinicname"
              >
                Clinic Name
                <span className="text-danger">*</span>

              </label>
              <input
                type="text"
                required
                className="form-control"
                placeholder="Enter clinic name"
                name="fullName"
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-md-12">
              <label
                className={`form-check-label mb-1 ${style.LabelText}`}
                htmlFor="clinicname"
              >
                Communication Email Address
                <span className="text-danger">*</span>
              
              </label>
              <input
                type="email"
                required
                className="form-control"
                placeholder="Enter email adress"
                name="email"
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-md-6">
              <label
                className={`form-check-label mb-1 ${style.LabelText}`}
                htmlFor="flexCheckDefault"
              >
                Contact Number
                <span className="text-danger">*</span>
              
              </label>
              <input
                type="number"
                required
                className="form-control"
                placeholder="Enter Your contact number"
                name="mobile_No"
                onChange={handleInputChange}
              />
            </div>
            <div className="col-md-6">
              <label
                className={`form-check-label mb-1 ${style.LabelText}`}
                htmlFor="flexCheckDefault"
              >
                Alternative Contact number
              </label>
              <input
                type="number"
                className="form-control"
                placeholder="Enter your alternative contact number"
                name="mobile_No_alter"
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-md-12">
              <label
                className={`form-check-label mb-1 ${style.LabelText}`}
                htmlFor="flexCheckDefault"
              >
                Current Address
                <span className="text-danger">*</span>
              
              </label>
              <input
                type="text"
                required
                className="form-control"
                placeholder="Enter your current address"
                name="address"
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="row mb-4">
          <div className="col-md-6">
              <label
                className={`form-check-label mb-1 ${style.LabelText}`}
                htmlFor="flexCheckDefault"
              >
                Country
                <span className="text-danger">*</span>
              
              </label>
              <select className={`form-select`}  required  onChange={(e) => {
                  getStates(e.target.value)               
                  setState('')
                  setCity('')
                  
                  }}
              >
                  <option selected disabled value={''}>{"select"}</option>
                  {countries.map((res, index) => {
                      return <option key={index} value={`${res.name}&&${res.isoCode}`} >{res.name}</option>
                  })}
              </select>
            </div>

            <div className="col-md-6">
              <label
                className={`form-check-label mb-1 ${style.LabelText}`}
                htmlFor="flexCheckDefault"
              >
                State of Residence
              
              </label>
              <select className={`form-select`} value={state} onChange={(e) => {
                                getCities(e.target.value)
                                setCity('')
                            }} 
              >
                <option disabled selected value={''}>{"select"}</option>
                {statesListing?.map((res, index) => {
                  return <option key={index} value={`${res.name}&&${res.isoCode}&&${res.countryCode}`}>{res.name}</option>
                })}

              </select>
            </div>
          </div>

          <div className="row mb-4">
            
            <div className="col-md-6">
              <label
                className={`form-check-label mb-1 ${style.LabelText}`}
                htmlFor="flexCheckDefault"
              >
                City
              </label>
              <select className={`form-select`} value={city} onChange={(e) => setCity(e.target.value)} >
                <option selected disabled value={''}>{"select"}</option>
                {citiesListing?.map((res, index) => {
                    return <option key={index} value={`${res.name}`}>{res.name}</option>
                })}

              </select>
            </div>

            <div className="col-md-6">
              <label
                className={`form-check-label mb-1 ${style.LabelText}`}
                htmlFor="flexCheckDefault"
              >
                Zip code
                <span className="text-danger">*</span>
              
              </label>
              <input type="text" className="form-control" placeholder=" Enter Zip code"
              required
               name="zip_code"
               onChange={handleInputChange} />
            </div>
          </div>

          <div className="d-flex justify-content-end gap-3">
            <button
              className={style.CencelButton}
              onClick={() => setAddDocument(false)}
              type="button"
            >
              Cancel
            </button>
            <button
              className={style.SubmitButton}
              //onClick={() => setAddDocument(false)}
              type="submit"
            >
              Submit
            </button>
          </div>
          </form>
        </div>
      </Dialog>
    </div>
  );
};

export default AddDocument;
