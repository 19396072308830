import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import style from "./MyProfile.module.css";
import { useTranslation } from "react-i18next";
import {
  deletePhoto,
  getSurrogateMotherData,
  selectResumePhoto,
} from "../../../Api/SurrogateMotherApis";
import { selectProfilePhoto } from "../../../Api/SurrogateMotherApis";
import { deleteDocsApi, uploadDocument } from "../../../Api/CommonApis";
import { RxCross1 } from "react-icons/rx";
import Loader from "../../common/Loader";
import { Snackbar } from "@mui/material";
import { toast } from "../../../utils/common";

const ProfileUploadDialog = ({ upload, setUpload, getUserData }) => {
  let { t } = useTranslation([]);
  let userData = JSON.parse(sessionStorage.getItem("userData"));
  const [data, setData] = useState();
  const [ImageIndex, setImageIndex] = useState();
  const [uploadImage, setUploadImage] = useState();
  const [imageUrl, setImageUrl] = useState("");
  const [imageData, setImageData] = useState([]);
  let [loader, setLoader] = useState(false);

  async function getUserImages() {
    try {

      let res = await getSurrogateMotherData(userData.userId);
      console.log(res.data.data);
      let object = res?.data?.data;
      setData(object);
      setImageData(object.photos);
      setImageIndex(object.photo_profile_index);

    } catch (err) {
      console.log(err);
      // setLoader(false)
    }
  }

  const handleSave = async () => {
    let imageData;
    setLoader(true)
    if (uploadImage) {
      let formdata = new FormData();
      formdata.append("uploadDoc", uploadImage);
      imageData = await uploadDocument(formdata);
      console.log(imageData.data);
      setUploadImage(null)
    }
    let payload;
    if (imageData) {
      payload = {
        photo_profile_index: data.photos.length !== 0 ? data.photos.length : 0,
        photo_to_add: {
          fileName: imageData.data.fileName,
          fileUrl: imageData.data.fileLocation,
        },
      };
    } else {
      payload = {
        photo_profile_index: ImageIndex,
      };
    }
    await selectProfilePhoto(data._id, payload);
    setUpload(false);
    setLoader(false)
    setImageUrl("");
    getUserData();
    getUserImages();
  };

  const removeImage = async (fileData) => {
    console.log(fileData);
    setLoader(true)
    try {

      let dataIndex = imageData.findIndex((obj) => obj._id === fileData._id);

      let payload = {}
      if (ImageIndex === dataIndex) {
        payload = {
          photo_profile_index: ImageIndex === 0 ? 0 : ImageIndex - 1,
        };
      } else {
        payload = {
          photo_profile_index: ImageIndex - 1,
        };
      }

      await selectProfilePhoto(data._id, payload);

      if (ImageIndex === data.photo_resume_index) {
        let payload1 = {
          photo_resume_index: ImageIndex === 0 ? 0 : ImageIndex - 1,
        };
        await selectResumePhoto(data._id, payload1);
      }

      await deletePhoto(data._id, { delete_id: fileData._id });
      await deleteDocsApi({ fileName: fileData.fileName });
      getUserImages()
      getUserData()
      setImageData(imageData.filter((e) => e.fileName !== fileData.fileName));
      setLoader(false)
    }
    catch (err) {
      setLoader(false)

    }
  };
  const handleImageChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageUrl(e.target.result);
      };
      reader.readAsDataURL(selectedFile);
    }
  };
  useEffect(() => {
    getUserImages();
    // const response =data?.map((res)=>console.log(res,"fffffffffff "))
    // console.log(response)
  }, []);


  return (
    <div>
      <Loader open={loader} />

      <Dialog
        closable={false}
        visible={upload}
        className="p-0 m-0"
        onHide={() => setUpload(false)}
        style={{ width: "60vw", padding: "0px", margin: "0px" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <div className="Verfied_con">
          <div className="d-flex justify-content-center mb-5">
            <span className={`fW_600 ${style.verfideText}`}>
              Change Profile Picture
            </span>
          </div>

          {/* <form action=""> */}
          {imageUrl.length === 0 ? (
            <>
              <div className=" ">
                <input
                  type="file"
                  id="fileInput"
                  hidden
                  name="photoUrl"
                  accept="image/png, image/gif, image/jpeg"
                  onChange={(e) => {
                    if (data.photos.length >= 5) {
                      toast.error("Please Remove Old Photo (Limit 5 photo)")
                    } else {
                      setUploadImage(e.target.files[0]);
                      handleImageChange(e);
                    }
                  }}
                />
                <label htmlFor="fileInput" className="w-100">
                  <div className={` mb-3 ${style.Images_Border}`}>
                    <div className={style.center_content}>
                      <i
                        className={`bi bi-card-image  mb-2 ${style.imagesCustom}`}
                      ></i>
                      <div>
                        <div className={style.UploadName}>
                          {t("Upload or Drop Image")}
                        </div>
                      </div>
                    </div>
                  </div>
                </label>
              </div>
            </>
          ) : (
            <>
              <div>
                <img src={imageUrl} alt="" className={style.ImgesCustom} />
              </div>
            </>
          )}
          <div className="mt-3">
            <span className={style.ChoosImages}>
              Or Choose from Uploaded Images
            </span>
          </div>
          <div className="d-flex justify-content-start align-items-center gap-3 my-4">
            {imageData?.map((res, index) => (
              <>
                <div className={`ImgesandIcon ${style.profile_img_con}`}>
                  <div className={style.imgegeCrossIcon}>
                    <RxCross1
                      className={`pointer`}
                      onClick={() => removeImage(res)}
                    />
                  </div>
                  <div onClick={() => setImageIndex(index)} className="pointer">
                    <img
                      src={res.fileUrl}
                      alt=""
                      className={style.ImgesCustom}
                      style={
                        { border: ImageIndex === index ? "3px solid #187C8B" : "", borderRadius: "9px" }
                      }
                    />
                  </div>
                </div>
              </>
            ))}
          </div>

          <div className="d-flex justify-content-end gap-3">
            <button
              className={style.CancelButton}
              onClick={() => setUpload(false)} >
              Cancel
            </button>
            <button className={style.SaveButton} onClick={() => handleSave()}>
              Save Changes
            </button>
          </div>
          {/* </form> */}
        </div>
      </Dialog>
    </div>
  );
};

export default ProfileUploadDialog;
