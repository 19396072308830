import React, { useEffect, useState } from "react";
import ClientTable from "../MyClientTable/ClientTable";
import style from "./MyClient.module.css";
import { InputText } from "primereact/inputtext";
import AddDocument from "../MyClientTable/AddDocumentDialog/AddDocument";
import { getIntendedParents } from "../../../../../../Api/admin";
import Loader from "../../../../../common/Loader";
import { Troubleshoot } from "@mui/icons-material";

const Myclient = () => {


  const [addocument, setAddDocument] = useState(false);
  // const [refresh,setRefresh] = useState(false)
  const [filterData,setFilterData]=useState([])
   
    const [data, setData] = useState([])  
    const [loader, setloader] = useState(true)

    const fetchParents = async()=>{
      setloader(true)
      const result = await getIntendedParents()
      setData(result.data.data)
      setFilterData(result.data.data)
      console.log(result.data.data,"this is the data for all ip")
      setloader(false)
    }
    const handleSearchClick = (e) => {
      let val = e.trim().toLowerCase();
      let searchName = filterData.filter((res) => {
        const fullName = (res?.fullName?.trim().toLowerCase().replace(/\s+/g, '') || '') +
          (res?.memberId?.trim().toLowerCase().replace(/\s+/g, '') || '');
        return fullName.includes(val.replace(/\s+/g, ''));
      });
      setData(searchName);
    };
    

    useEffect(()=>{
      fetchParents()
    },[])  

  
  return (
    <div className={style.ClientTable}>
          <Loader open={loader} />
      <AddDocument addocument={addocument} setAddDocument={setAddDocument} updatAllData={fetchParents}/>
      <div className="d-flex justify-content-between mb-4 mt-3 ">
        <div>
          {" "}
          <span className="lufga_24px fw_600">Intended Parent</span>
        </div>
        <div className="d-flex gap-2 align-items-center">
        <div>
            <button
              className="theme_button"
             onClick={() => setAddDocument(true)}
            >
              + Add Account
            </button>
          </div>
          <div>
          <span className={`p-input-icon-left d-flex align-items-center `}>
                {/* <i className={`pi pi-search ${style.search_icon}`} /> */}
                <div className="searchInput w-100">
                  <input
                    className="form-control ps-5"
                    placeholder="Search"
                    onChange={(e) => handleSearchClick(e.target.value)}
                  ></input>
                  <i className="pi pi-search searchIcon"></i>
                </div>
              </span>
          </div>

        </div>
      </div>

      <ClientTable data={data} update={fetchParents} role="all_clients"/>

    </div>
  );
};

export default Myclient;
