import React, { useEffect, useState } from "react";
import style from "./style.module.css";
import { useTranslation } from "react-i18next";
import { getPlan } from "../../../Api/admin";
import Slider from "../../commonComponents/slider/slider";
import SubscriptionCard from "../../common/SubscriptionCard";
import { Skeleton } from "primereact/skeleton";
import { Outlet, useNavigate } from "react-router";
import { getDashboardUser } from "../../../Api/CommonApis";
import Toaster from "../../common/Toaster";

const SubscriptionPlanED = () => {
  let { t } = useTranslation();
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  let [loaderArr, setLoaderArr] = useState([1, 2, 3]);
  const [canBuy, setCanBuy] = useState(true);
  let userData = sessionStorage.getItem("userId");
  const [toaster, setToaster] = useState({
    show: false,
    message: "",
  });
  const sliderTworesponsiveOptions = [
    {
      breakpoint: "2400px",
      numVisible: 4,
      numScroll: 1,
    },
    {
      breakpoint: "1600px",
      numVisible: 3,
      numScroll: 1,
    },
    {
      breakpoint: "1199px",
      numVisible: 2,
      numScroll: 2,
    },
    {
      breakpoint: "890px",
      numVisible: 1,
      numScroll: 1,
    },
  ];

  const [selectedPlanData,setSelectedPlanData] = useState()


  useEffect(() => {
    let width = window.innerWidth;

    if (width <= 2400 && width >= 1600) {
      setLoaderArr([1, 2, 3, 4]);
    } else if (width <= 1599 && width >= 1200) {
      setLoaderArr([1, 2, 3]);
    } else if (width <= 1199 && width >= 890) {
      setLoaderArr([1, 2]);
    } else {
      setLoaderArr([1]);
    }
  }, []);

  let fetchPlan = async () => {
    let demo = {
      _id: "66224f0c1441032f90559038",
      plan_name: "Free",
      user_type: "embryo_donor",
      validity: "option3",
      amount: 0,
      plan_description: "Completely free for your basic needs",
      included_features: [
        "Basic Matching",
        "Profile Setup",
        "Legal Guidance",
        "Community Support",
        "Safety Verification",
      ],
      not_included_features: [
        "Advanced Matching",
        "Premium Communication",
        "Full Legal Assistance",
        "Exclusive Education",
        "Comprehensive Safety",
        "Priority Support",
        "Enhanced Profile",
      ],
      status: "active",
      isActive: true,
    };

    try {
      setLoader(true);
      let uData = await getDashboardUser(userData);
      let result = await getPlan();
      let response = result.data.data;
      let userRes = uData?.data?.data;
      setSelectedPlanData(userRes)
      let filterdData = response?.filter(
        (res) => res.status == "active" && res.user_type == "Embryo Donor"
      );
      if (userRes?.subscription_plan !== undefined && userRes.subscription_plan.price > 0) {
        setCanBuy(false);
        filterdData = filterdData?.map((res) => {
          if (res.status == "active" && res.user_type == "Embryo Donor") {
            if (userRes?.subscription_plan?.plan_id == res._id) {
              return {
                ...res,
                isActive: true,
              };
            } else {
              return {
                ...res,
                isActive: false,
              };
            }
          }
        });
      } else {
        setCanBuy(true);
        filterdData = filterdData?.map((res) => {
          if (res.status == "active" && res.user_type == "Embryo Donor") {
            return {
              ...res,
              isActive: false,
            };
          }
        });
      }
      filterdData.sort((a, b) => a.amount - b.amount)
      setData([...filterdData]);
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  };

  useEffect(function () {
    fetchPlan();
  }, []);

  function click(e) {
    if (canBuy) {
      navigate(`payment/${e._id}`);
    } else {
      // alert("You already have an active plan on your account.")
      setToaster({
        show: true,
        message: "You already have an active plan on your account.",
      });
    }
  }

  return (
    <>
      <Toaster
        // event={"Please InActive Current Post then you Add new Post"}
        event={toaster.message}
        show={toaster.show}
        severity={"warn"}
        setToaster={setToaster}
      />
      <div className={style.container}>
        <div className={style.mainHeading}>
          {t("Putting Together the Puzzle of Parenthood")}
        </div>
        <div className="text-center text-black px-md-5 mx-sm-3 px-2 mx-md-5 mx-sm-3 mx-2">
          {t(
            "Choose the plan that’s right for your needs. Whether you’re just getting started with surrogate journey or well down the path to parenthood, we’re got you covered"
          )}
        </div>

        {loader ? (
          <div className="row mt-4 px-5">
            {loaderArr?.map((res, index) => {
              return (
                <div className="style_container py-3 col-md-4">
                  <div className="category-slider-card">
                    <div className=" p-0 w-100 borderShodowCard">
                      <div
                        className="px-md-5 px-sm-4 px-4"
                        style={{ minHeight: "350px" }}
                      >
                        <div className="CardCustom mb-3 pt-5">
                          <Skeleton
                            width="4rem"
                            height="1rem"
                            className="mb-2 mx-auto"
                          ></Skeleton>
                          <Skeleton
                            width="2rem"
                            height="2rem"
                            className="mb-2 mx-auto"
                          ></Skeleton>
                          <Skeleton
                            width="4rem"
                            height="1rem"
                            className="mb-2 mx-auto"
                          ></Skeleton>
                          <hr />
                        </div>
                        <div className=" mb-4">
                          <Skeleton className=" w-100 mb-2 mx-auto"></Skeleton>
                          <Skeleton className="w-75 mb-4 mx-auto"></Skeleton>
                          <hr />
                          <Skeleton
                            height="3rem"
                            className="w-100 mb-4 mx-auto"
                          ></Skeleton>
                        </div>
                      </div>
                      <div className="text-center FeaturesMain my-3">
                        <span className="FeaturesText">&nbsp;</span>
                      </div>
                      <div className="CardCustom p-3 features_card">
                        <div className="d-flex align-item-center mb-2">
                          <Skeleton
                            shape="circle"
                            size="1.1rem"
                            className="me-2"
                          ></Skeleton>
                          <Skeleton className=" w-100 "></Skeleton>
                        </div>
                        <div className="d-flex align-item-center mb-2">
                          <Skeleton
                            shape="circle"
                            size="1.1rem"
                            className="me-2"
                          ></Skeleton>
                          <Skeleton className=" w-100 "></Skeleton>
                        </div>
                        <div className="d-flex align-item-center mb-2">
                          <Skeleton
                            shape="circle"
                            size="1.1rem"
                            className="me-2"
                          ></Skeleton>
                          <Skeleton className=" w-100 "></Skeleton>
                        </div>
                        <div className="d-flex align-item-center mb-2">
                          <Skeleton
                            shape="circle"
                            size="1.1rem"
                            className="me-2"
                          ></Skeleton>
                          <Skeleton className=" w-100 "></Skeleton>
                        </div>
                        <div className="d-flex align-item-center mb-2">
                          <Skeleton
                            shape="circle"
                            size="1.1rem"
                            className="me-2"
                          ></Skeleton>
                          <Skeleton className=" w-100 "></Skeleton>
                        </div>
                        <div className="d-flex align-item-center mb-2">
                          <Skeleton
                            shape="circle"
                            size="1.1rem"
                            className="me-2"
                          ></Skeleton>
                          <Skeleton className=" w-100 "></Skeleton>
                        </div>
                        <div className="d-flex align-item-center mb-2">
                          <Skeleton
                            shape="circle"
                            size="1.1rem"
                            className="me-2"
                          ></Skeleton>
                          <Skeleton className=" w-100 "></Skeleton>
                        </div>
                        <div className="d-flex align-item-center mb-2">
                          <Skeleton
                            shape="circle"
                            size="1.1rem"
                            className="me-2"
                          ></Skeleton>
                          <Skeleton className=" w-100 "></Skeleton>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="mt-4 px-5">
            <Slider
              ProductService={data}
              responsiveOptions={sliderTworesponsiveOptions}
              productTemplate={(e) => (
                <SubscriptionCard navigator={click} product={e} selectedPlanData={selectedPlanData}/>
              )}
            />
          </div>
        )}
      </div>
      <Outlet
        context={{
          data: data,
          fetchData: fetchPlan,
        }}
      />
    </>
  );
};

export default SubscriptionPlanED;
