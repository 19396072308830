import axios from "axios";
import { baseUrl, dashboardService, lawyer } from "./EndPoint"

let userData = JSON.parse(sessionStorage.getItem("userData"))

let config = {
    headers: {
        Authorization: `Bearer ${userData?.token}`,
    }
  }







  export async function addDoctorInlawyer(payload){
    return await axios.post(`${baseUrl}${lawyer.addDoctorInlawyer}`,payload,config)
}


export async function getDoctorsListApi (id){
    return await axios.get(`${baseUrl}${lawyer.getDoctorsListApi}${id}`,config)
}

export async function deleteDoctorByIdApi (id){
    return await axios.delete(`${baseUrl}${lawyer.deleteDoctorByIdApi}${id}`,config)
}


export async function getDoctorByIdApi (id){
    return await axios.get(`${baseUrl}${lawyer.getDoctorByIdApi}${id}`,config)
}


export async function updateDoctorByIdApi (id,payload){
    return await axios.put(`${baseUrl}${lawyer.updateDoctorByIdApi}${id}`,payload,config)
}

















export async function getuserDataById (id) {
    return await axios.get(`${baseUrl}${lawyer.getUserById}${id}`)
}

export async function getAppointments (payload){
    return await axios.post(`${baseUrl}${lawyer.getAppointments}`,payload, config)
}

export async function addAppointment (payload){
    return await axios.post(`${baseUrl}${lawyer.addAppointment}`, payload,config)
}

export async function editAppointment (id,payload){
    return await axios.put(`${baseUrl}${lawyer.editAppointment}${id}`,payload,config)
}

export async function getlawyerAppointmentById (id){
    return await axios.get(`${baseUrl}${lawyer.getlawyerAppointmentById}${id}`,config)
}

export async function deleteAppointmentById (id){
    return await axios.delete(`${baseUrl}${lawyer.deleteAppointment}${id}`,config)
}

export async function deleteClientById (id){
    return await axios.delete(`${baseUrl}${lawyer.deleteClient}${id}`,config)
}

export async function deleteLawyerDocumentById (id){
    return await axios.delete(`${baseUrl}${lawyer.deleteDocument}${id}`,config)
}

export async function getClientsOflawyer(payload){
    return await axios.post(`${baseUrl}${lawyer.getClientsOflawyer}`,payload,config)
}

export async function getClientDataById(id){
    return await axios.get(`${baseUrl}${lawyer.getClientDataById}${id}`,config)
}

export async function addAppointmentByClientId(payload){
    return await axios.post(`${baseUrl}${lawyer.addAppointmentByClientId}`,payload,config)
}

export async function getAppointmentByClientId(payload){
    return await axios.post(`${baseUrl}${lawyer.getAppointmentByClientId}`,payload,config)
}

export async function getLawyerDocumentByClientId(payload){
    return await axios.post(`${baseUrl}${lawyer.getDocumentByClientId}`,payload,config)
}

export async function addDocumentlawyer(payload){
    return await axios.post(`${baseUrl}${lawyer.addDocumentlawyer}`,payload,config)
}

export async function addAndUpdatePoc (payload){
    return await axios.post(`${baseUrl}${lawyer.addAndUpdatePoc}`,payload,config)
}

export async function deletePocApi (payload){
    return await axios.post(`${baseUrl}${lawyer.deletePocApi}`,payload,config)
}






