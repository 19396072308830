import React, { useEffect, useState } from "react";
import style from "./Auction.module.css";
import AuctionForm from "./AuctionForm/AuctionForm";
import AuctionPost from "../Dashboard/AuctionPost/auctionPost";
import DisactiveAuctionPost from "../Dashboard/AuctionPost/disactiveAuctionPost";
import { useNavigate } from "react-router";
import { getAllSurrogateAuction } from "../../../Api/SurrogateMotherApis";
import Loader from "../../common/Loader";
import Toaster from "../../common/Toaster";
import { Message } from "@mui/icons-material";
import AuctionNew from "../AuctionNew/AuctionNew";
import { all } from "axios";

const Auction = () => {
  const naviagte = useNavigate();
  const [resumepost, setResumePost] = useState(false);
  const [loader, setLoader] = useState(false);
  const [activeData, setActiveData] = useState([]);
  const [disactiveData, setDisactiveData] = useState([]);
  const [alldata, setAlldata] = useState([])
  const [toaster, setToaster] = useState({
    show: false,
    message: "",
  });
  const [error, setError] = useState();

  let userData = JSON.parse(sessionStorage.getItem("userData"));

  function CreatePost() {
    alert("sdfhjdddd");
    naviagte("auction/createpost");
  }

  // console.log("wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww")

  const getALLData = async () => {
    setLoader(true);
    let response = await getAllSurrogateAuction(userData.userId).catch(
      (err) => {
        console.log("Error : ", err);
      }
    );
    console.log("itsresponse")
    console.log(response.data.data)
    setAlldata(response.data.data);
    setLoader(false);
  };

  useEffect(() => {
    getALLData();
  }, []);
  return (
    <div>
      <Loader open={loader} />
      {toaster && (
        <>
          <Toaster
            // event={"Please InActive Current Post then you Add new Post"}
            event={toaster.message}
            show={toaster.show}
            severity={"warn"}
            setToaster={setToaster}
          />
        </>
      )}
      <AuctionForm resumepost={resumepost} setResumePost={setResumePost} />
      <div className={`d-flex align-items-center justify-content-between mt-3`}>
        <div className={`lufga_24px fw_600`}>My Auction Post</div>

        <div>
          <div
            className={`${style.AuctionSpaceButton}`}
            onClick={() => {
              
              if ((alldata.active_Auction).length !== 0) {
                setToaster({
                  show: true,
                  message: "Please InActive Current Post then you can add new Post",
                });
              } else {
                naviagte("createpost");
              }
            }}
          >
            <img
              src="/images/create.png"
              style={{ height: "17px", width: "17px", marginRight: "3px" }}
            />
            <span>
              Create Post
            </span>
          </div>
        </div>
      </div>
      <div className="mt-3">
        {/* <AuctionPost
          getData={activeData}
          showhead={true}
          getALLData={getALLData}
        /> */}

        <AuctionNew
          getData={alldata}
          showhead={true}
          getALLData={getALLData}
          setToaster={setToaster}
        />
      </div>

      {/* <div className={`lufga_34px`}>My Auction Post</div> */}
      {/* <div className="mt-3">
        <DisactiveAuctionPost
          getData={disactiveData}
          getALLData={getALLData}
          setToaster={setToaster}
        />
      </div> */}
    </div>
  );
};

export default Auction;
