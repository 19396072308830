import React, { useEffect, useState } from "react";
import style from "../../../SurrogateComponents/MyProfile/MyProfile.module.css";
import { useTranslation } from "react-i18next";
import { CiImageOn } from "react-icons/ci";
import Loader from "../../../common/Loader";
import {
  getDashboardUser,
  updateDashboardUser,
  uploadDocument,
} from "../../../../Api/CommonApis";
import { Country, State, City } from "country-state-city";
import {
  UpdateSurrogateProfile,
  UpdateSurrogateprofile,
  getSurrogateMotherData,
} from "../../../../Api/SurrogateMotherApis";
import moment from "moment";
import ProfileUploadDialog from "../../../SurrogateComponents/MyProfile/ProfileUploadDialog";
import ActiveDelete from "../../../commonComponents/ActiveDelete/ActiveDelete";


const ActiveSurrogateMyProfile = () => {
  let { t } = useTranslation();
  const countries = Country.getAllCountries();
  const [country, setCountry] = useState(null);
  const [state, setState] = useState(null);
  const [city, setCity] = useState(null);
  const [statesListing, setStatesListing] = useState([]);
  const [citiesListing, setCitiesListing] = useState([]);
  const [editable, setEditable] = useState(true);
  const [upload, setUpload] = useState(false);


  let userId = "6603a8a60d77811be28fdea5";

  const [loader, setLoader] = useState(false);
  const [img, setImg] = useState(null);
  const [defaultImg, setDefaultimg] = useState("/images/blankProfile.png");
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    middleName: "",
    height: {
      feet: 0,
      inches: 0,
    },
    weight: {
      LBs: 0,
      oz: 0,
    },
    email: "",
    homePhone: "",
    workPhone: "",
    cellPhone: "",
    imageUrl: "",
    imageFileName: "",
    short_description: "",
    age: 0,
    dob: "",
    current_address: "",
    country: "",
    state: "",
    city: "",
    zipCode: "",
    emailVerify: true,
  });
  console.log(data.firstName);

  async function getUserData() {
    try {
      let res = await getSurrogateMotherData(userId);
      console.log(res);
      // return
      setData((pre) => {
        return { ...pre, ...res.data.data };
      });

      if (res.data.data.country !== undefined) {
        let cRes = countries?.find(
          (res2) => res2?.name == res.data.data.country
        );
        setCountry(`${cRes.name}&&${cRes.isoCode}`);
        getStates(`${cRes.name}&&${cRes.isoCode}`);
      }
      if (res.data.data.city !== undefined) {
        setCity(res.data.data.city);
      }
      setLoader(false);
    } catch (err) {
      console.log(err);
      setLoader(false);
    }
  }
  useEffect(() => {
    if (statesListing.length !== 0) {
      let sRes = statesListing?.find((res2) => res2?.name == "delhi");
      if (sRes !== undefined) {
        setState(`${sRes.name}&&${sRes.isoCode}&&${sRes.countryCode}`);
        getCities(`${sRes.name}&&${sRes.isoCode}&&${sRes.countryCode}`);
      }
    }
  }, [statesListing]);

  function getImgPreview(file) {
    let url = URL.createObjectURL(file);
    return url;
  }

  async function editProfileData(e) {
    e.preventDefault();
    console.log(data);
    let payload = {
      ...data,
      city: city.split("&&")[0],
      state: state.split("&&")[0],
      country: country.split("&&")[0],
    };
    console.log(payload);
    // return
    let formdata = new FormData();
    if (img !== null) {
      formdata.append("uploadDoc", img);
    }

    setLoader(true);
    try {
      if (img !== null) {
        let imageData = await uploadDocument(formdata);
        payload.imageFileName = imageData.data.fileName;
        payload.imageUrl = imageData.data.fileLocation;
      }
      let res = await UpdateSurrogateprofile(userId, payload);
      console.log(res);
      // return
      setImg(null);
      await getUserData();
      setEditable(true);
      setLoader(false);
    } catch (err) {
      setLoader(false);
      console.log(err);
    }
  }

  function getStates(res) {
    let isoCode = res.split("&&")[1];
    setCountry(res);
    let states = State.getStatesOfCountry(isoCode);
    setStatesListing(states);
  }

  function getCities(res) {
    let isoCode = res.split("&&")[1];
    let cCode = res.split("&&")[2];
    setState(res);
    let cities = City.getCitiesOfState(cCode, isoCode);
    setCitiesListing(cities);
  }

  function onInputChange(e) {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }
  // const handleProfileImageClick = () => {
  //     setUpload(true);
  // }

  

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <>
      
      <Loader open={loader} />
      <div className={style.MyProfile}>
        <form onSubmit={editProfileData}>
          <div className={` ${style.bannersize}`}>
            <div className="d-flex align-items-center gap-3">


              <label
                className={`${style.ProfileImg1} pointer border`}
              //  onClick={handleProfileImageClick}
              >
                <div className={`${style.inputIcon}`}>
                  <CiImageOn />
                </div>
                <img
                  src="/images/first_image_of_card.png"
                  htmlFor="imgInp"
                  className="w-100 h-100"
                  alt=""
                />
                <input
                  type="file"
                  hidden
                  id="imgInp"
                  onChange={(e) => {
                    setDefaultimg(e.target.files[0]);
                    setImg(e.target.files[0]);
                  }}
                />
              </label>


              <div className="d-flex flex-column align-item-center justify-content-center gap-3">
                <div className={style.BnnerFirstText}>
                  NPHIP001 : Intended Parents
                </div>
                <div className={style.BnnersecondtText}>Alexa Smith</div>

                <ActiveDelete />

              </div>
            </div>
            <div className={`p-3 p-relative ${style.ProfileImg}`}>
              <img src="/images/child5 (1).png" className={`w-100`} alt="" />
              {/* {editable ? (
                <div
                  className={`d-flex justify-content-end ${style.ChlidButton}`}
                >
                  <button
                    className={`text-center d-flex align-items-center ${style.VerfiedButton}`}
                    onClick={() => setEditable(false)}
                  >
                    <i class="bi bi-pencil-square me-1 d-flex align-items-center"></i>
                    <span className={style.verfiedText}>Edit Details</span>
                  </button>
                </div>
              ) : (
                ""
              )} */}
            </div>
          </div>
          <div className="row pb-3">
            <div className="col-md-4">
              <label
                htmlFor="exampleInputEmail1"
                className={`form-label  ${style.LabelHeading}`}
              >
                {t("First Name")}
                <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className={`form-control`}
                placeholder=""
                disabled={editable}
                value={data?.firstName}
                required
                name="firstName"
                onChange={onInputChange}
              />
            </div>

            <div className="col-md-4">
              <label
                htmlFor="exampleInputEmail1"
                className={`form-label  ${style.LabelHeading}`}
              >
                {t("Middle Initial")}
                <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className={`form-control`}
                placeholder=""
                disabled={editable}
                value={data?.middleName}
                name="middleName"
                onChange={onInputChange}
              />
            </div>
            <div className="col-md-4">
              <label
                htmlFor="exampleInputEmail1"
                className={`form-label  ${style.LabelHeading}`}
              >
                {t("Last Name")}
                <span className="text-danger">*</span>
              </label>

              <input
                type="text"
                className={`form-control`}
                placeholder=""
                disabled={editable}
                value={data?.lastName}
                required
                name="lastName"
                onChange={onInputChange}
              />
            </div>
          </div>

          <div className="row pb-3">
            <div className="col-md-6">
              <label
                htmlFor="exampleInputEmail1"
                className={`form-label  ${style.LabelHeading}`}
              >
                {t("Age")}
                <span className="text-danger">*</span>
              </label>
              <input
                type="number"
                className={`form-control`}
                placeholder=""
                disabled={editable}
                value={data?.age}
                required
                name="age"
                onChange={onInputChange}
              />
            </div>

            <div className="col-md-6">
              <label
                htmlFor="exampleInputEmail1"
                className={`form-label  ${style.LabelHeading}`}
              >
                {t("Date of Birth")}
                <span className="text-danger">*</span>
              </label>
              <input
                type="date"
                className={`form-control`}
                placeholder=""
                disabled={editable}
                value={data?.dob && moment(data.dob).format("YYYY-MM-DD")}
                required
                name="dob"
                onChange={onInputChange}
              />
            </div>
          </div>

          <div className="col pb-3">
            <div className="row">
              <div className="col-md-6">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  LabelHeading`}
                >
                  {t("Height")} <span className="text-danger">*</span>
                </label>

                <div className="d-flex justify-content-between gap-2">
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    name="feet"
                    disabled={editable}
                    value={data.height.feet}
                    onChange={(e) => {
                      setData((pre) => {
                        pre.height.feet = e.target.value;
                        return { ...pre };
                      });
                    }}
                  >
                    <option selected>{t("select")}</option>
                    <option value={4}>{t("04")}</option>
                    <option value={5}>{t("05")}</option>
                    <option value={6}>{t("06")}</option>
                  </select>

                  <div className="d-flex align-items-center">ft</div>

                  <select
                    className="form-select"
                    aria-label="Default select example"
                    name="inches"
                    disabled={editable}
                    value={data.height.inches}
                    onChange={(e) => {
                      setData((pre) => {
                        pre.height.inches = e.target.value;
                        return { ...pre };
                      });
                    }}
                  >
                    <option selected>{t("select")}</option>
                    {[...Array(12)].map((e, key) => (
                      <option value={key}>{key + 1}</option>
                    ))}
                  </select>
                  <div className="d-flex align-items-center">inches</div>
                </div>
              </div>

              <div className="col-md-6">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label LabelHeading`}
                >
                  {t("Weight")} <span className="text-danger">*</span>
                </label>

                <div className="d-flex justify-content-between gap-2">
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    name="LBs"
                    disabled={editable}
                    value={data.weight.LBs}
                    onChange={(e) => {
                      setData((pre) => {
                        pre.weight.LBs = e.target.value;
                        return { ...pre };
                      });
                    }}
                  >
                    <option selected>{t("select")}</option>
                    <option value={1}>{t("07")}</option>
                    <option value={2}>{t("08")}</option>
                    <option value={3}>{t("09")}</option>
                  </select>
                  <div className="d-flex align-items-center">lbs</div>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    name="oz"
                    disabled={editable}
                    value={data.weight.oz}
                    onChange={(e) => {
                      setData((pre) => {
                        pre.weight.oz = e.target.value;
                        return { ...pre };
                      });
                    }}
                  >
                    <option selected>{t("select")}</option>
                    <option value={1}>{t("07")}</option>
                    <option value={2}>{t("08")}</option>
                    <option value={3}>{t("09")}</option>
                  </select>
                  <div className="d-flex align-items-center">oz</div>
                </div>
              </div>
            </div>

            <div className="row pb-3">
              <div className="col-md-12">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("Email Address")}
                </label>
                <input
                  type="text"
                  className={`form-control`}
                  placeholder=""
                  disabled={editable}
                  required
                  name="email"
                  value={data?.email}
                  onChange={onInputChange}
                />
              </div>
            </div>

            <div className="row pb-3">
              <div className="col-md-4">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("Home Phone")}
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className={`form-control`}
                  placeholder=""
                  disabled={editable}
                  required
                  value={data?.homePhone}
                  name="homePhone"
                  onChange={onInputChange}
                />
              </div>

              <div className="col-md-4">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("Work Phone")}
                </label>
                <input
                  type="text"
                  className={`form-control`}
                  placeholder=""
                  disabled={editable}
                  required
                  value={data?.workPhone}
                  name="workPhone"
                  onChange={onInputChange}
                />
              </div>

              <div className="col-md-4">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("Cell Phone")}
                </label>
                <input
                  type="text"
                  className={`form-control`}
                  placeholder=""
                  disabled={editable}
                  required
                  value={data?.cellPhone}
                  name="cellPhone"
                  onChange={onInputChange}
                />
              </div>
            </div>

            <div className="row pb-3">
              <div className="col-md-12">
                <label
                  htmlFor="exampleInputEmail1"
                  className={`form-label  ${style.LabelHeading}`}
                >
                  {t("Current Address")}
                  <span className="text-danger">*</span>
                </label>

                <input
                  type="text"
                  className={`form-control`}
                  placeholder=""
                  disabled={editable}
                  required
                  value={data?.current_address}
                  name="current_address"
                  onChange={onInputChange}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 mb-3">
              <label
                htmlFor="exampleInputEmail1"
                className={`form-label  ${style.LabelHeading}`}
              >
                {t("Country")}
                <span className="text-danger">*</span>{" "}
              </label>
              <select
                className={`form-select`}
                value={country}
                required
                disabled={editable}
                onChange={(e) => {
                  getStates(e.target.value);
                  setState("");
                  setCity("");
                }}
              >
                <option selected disabled value={""}>
                  {t("select")}
                </option>
                {countries.map((res, index) => {
                  return (
                    <option key={index} value={`${res.name}&&${res.isoCode}`}>
                      {res.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="col-md-6 mb-3">
              <label
                htmlFor="exampleInputEmail1"
                className={`form-label  ${style.LabelHeading}`}
              >
                {t("State of Residence")}
                <span className="text-danger">*</span>{" "}
              </label>
              <select
                className={`form-select`}
                value={state}
                required
                disabled={editable}
                onChange={(e) => {
                  getCities(e.target.value);
                  setCity("");
                }}
              >
                <option selected disabled value={""}>
                  {t("select")}
                </option>
                {statesListing?.map((res, index) => {
                  return (
                    <option
                      key={index}
                      value={`${res.name}&&${res.isoCode}&&${res.countryCode}`}
                    >
                      {res.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="col-md-6 mb-3">
              <label
                htmlFor="exampleInputEmail1"
                className={`form-label  ${style.LabelHeading}`}
              >
                {t("City")}
                <span className="text-danger">*</span>{" "}
              </label>
              <select
                className={`form-select`}
                value={city}
                required
                disabled={editable}
                onChange={(e) => setCity(e.target.value)}
              >
                <option selected disabled value={""}>
                  {t("select")}
                </option>
                {citiesListing?.map((res, index) => {
                  return (
                    <option key={index} value={`${res.name}`}>
                      {res.name}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="col-md-6 mb-3">
              <label
                htmlFor="exampleInputEmail1"
                className={`form-label ${style.LabelHeading}`}
              >
                {t("Zip Code")}
                <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className={`form-control`}
                required
                placeholder=""
                disabled={editable}
                value={data?.zipCode}
                name="zipCode"
                onChange={onInputChange}
              />
            </div>
          </div>
          <div className="col-md-12">
            {editable ? (
              ""
            ) : (
              <div className="d-flex justify-content-end gap-4 mt-5">
                <button className={style.CancelButton}>Cancel</button>
                <button className={style.SaveButton} type="submit">
                  Save Changes
                </button>
              </div>
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default ActiveSurrogateMyProfile;
