import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  show: false,
  msg: "",
  type: "",
};

export const snackBarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    callSnackBar: (state, action) => {
      let { show, type, msg } = action.payload;
      return { show, type, msg };
    },
  },
});

export let { addDataToCache ,callSnackBar} = snackBarSlice.actions