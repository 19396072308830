import { Dialog } from 'primereact/dialog'
import React, { useState } from 'react'
import style from "./PaymentDialog.module.css"
import { Calendar } from 'primereact/calendar';
const VerifyPaymentDialog = ({payment,setPayment}) => {
    const [date, setDate] = useState(null);
    

    return (
        <div>
            <Dialog
                closable={false}
                visible={payment}
                onHide={() => setPayment(false)}
                style={{ width: "50vw" }}
                breakpoints={{ "960px": "75vw", "641px": "100vw" }}
            >
                <div className='Payment_con'>
                    <div className={` mb-2 text-center ${style.GoldText}`}>
                        <h3>Verify Your Resume</h3>
                    </div>
                    <div className={`mb-4 text-center ${style.PaymentMethodText}`}>
                        <p className='theme_color'>Payment Methods</p>
                    </div>
                    <div className='d-flex'>
                        <div className="form-check align-item-center mt-1" >
                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                        </div>
                        <div className={`mb-3 ${style.CraeditCardDiv}`}>
                            <label className={`form-check-label  ${style.CreaditCradText}`} htmlFor="flexRadioDefault1">
                                Credit Card
                            </label>
                            <label className={`form-check-label  ${style.accptCrads}`} htmlFor="flexRadioDefault1">
                                We accept all major credit cards.
                            </label>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div className="images">
                        <img src="/images/Frame 440.png" alt="" />
                        <img src="/images/Frame 441.png" alt="" />
                        <img src="/images/Frame 442.png" alt="" />
                        <img src="/images/Frame 443.png" alt="" />
                    </div>
                    <div className='row mb-4'>
                        <div className="col-md-6">
                            <input
                                type="text"
                                className={`form-control  ${style.InputCustom}`}
                                placeholder="Card number"
                                name="name"
                                required
                            />
                        </div>
                        <div className="col-md-6">
                            <input
                                type="text"
                                className={`form-control  ${style.InputCustom}`}
                                placeholder="Name of card"
                                name="name"
                                required
                            />
                        </div>
                        <div className={`col-md-6  ${style.EyeView}`}>
                            <input
                                type="text"
                                className={`form-control  ${style.InputCustom}`}
                                placeholder="Security Code"
                                name="name"
                                required
                            />
                            <i className={` bi bi-eye ${style.View}`}></i>
                        </div>
                        <div className="col-md-6 ">
                     
            <Calendar value={date}onChange={(e) => setDate(e.value)} view="month" dateFormat="mm/yy" className={style.InputCustom1}
            placeholder="Expiration date (MM/YY)"
            
            />
        
                        </div>
                    </div>
                    <div className="form-check align-item-center mt-1 mb-5" >
                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                        <label className="form-check-label CreaditCradText" htmlFor="flexRadioDefault1">
                            Paypol
                        </label>
                    </div>
                    <div className='d-flex justify-content-end gap-2'>
                        <button className={style.CancelPayButton} onClick={() => setPayment(false)}>Cancel</button>
                        <button className={style.PayButton} onClick={() => setPayment(false)}>Pay $99</button>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

export default VerifyPaymentDialog