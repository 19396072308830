import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import style from "./AuctionResumeDialog.module.css"
import { VscVerifiedFilled } from "react-icons/vsc";
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment'
import { getAuctionDataById, getResumeData } from '../../../../Api/IntendedParent';
import Loader from '../../../common/Loader';
import 'primeicons/primeicons.css';
import BidNowDialog from '../BidNowDialog/BidNowDialog';
import BuyNowModal from '../BuyNowModal';
import { useLocation } from 'react-router-dom';
import { getUserDataById } from '../../../../Api/admin';
import { MultiSelect } from 'primereact/multiselect';
import { gethabitsdata, getlifestyledata } from '../../../../Api/admin';


const AuctionResumedialog = () => {
    let { t } = useTranslation()
    const { id } = useParams()
    const navigate = useNavigate()
    const [loader, setLoader] = useState(true)
    const [allData, setAllData] = useState({})
    const [auctionData, setAuctiondata] = useState({})
    const [bidNowOpen, setBidNowOpen] = useState(false)
    const [buyNowOPen, setBuyNowOpen] = useState(false)
    const [bidderName, setBidderName] = useState("")
    const [selectedLifeStyle, setSelectedLifeStyle] = useState([]);
    const [selectedHabits, setSelectedHabits] = useState([]);
    const [lifestyle, setLifestyle] = useState([]);
    const [habits, setHabits] = useState([]);
    const [auctionCompletedByDate, setAuctionCompletedByDate] = useState(false)

    const location = useLocation();
    const state = location.state;
    const data = state ? state.data : null;



    function AuctionCompletedOrNOt() {

        const filteredData = auctionData.filter((res) => {
            const endDate = moment(res.endDate);
            const now = moment();
            return endDate.isBefore(now)
        });

    }


    const fetchHighestBidderName = async (id) => {
        try {

            let bidderdata = await getUserDataById(id);
            setBidderName(bidderdata.data.data.fullName);
        }
        catch (error) {
            console.log(error, "error while fetching highest bidder data")
        }
    }

    if (data?.biderId) {
        fetchHighestBidderName(data.biderId);
    }



    function Bidnow(e) {
        e.preventDefault()
    }


    async function getData() {
        try {
            let auction = await getAuctionDataById(id)
            setAuctiondata(auction.data.data)
            console.log(auction.data.data, "auction data")

            let res = await getResumeData(auction?.data?.data?.userId)
            console.log(res, "Resume data of surrogate mother for auction profile")
            setAllData(res.data.data)

            await gethobiesandlifestyle(res.data.data.habits, res.data.data.life_style)
            setLoader(false)
        } catch (err) {
            setLoader(false)
            console.log(err, "error while fetching the auction data and resume data")
        }
    }

    async function gethobiesandlifestyle(habits, lifestyle) {
        await getLifestyle(lifestyle);
        await getHabits(habits);
    }

    const getLifestyle = async (lifestyle) => {
        var result = await getlifestyledata();

        setSelectedLifeStyle(result?.data?.data?.filter(dd => lifestyle?.includes(dd._id)))
        // console.log(result?.data?.data?.filter(dd => lifestyle?.includes(dd._id)))
        setLifestyle(result.data.data);
    };

    const getHabits = async (habits) => {
        var result = await gethabitsdata();

        setSelectedHabits(result?.data?.data?.filter(dd => habits?.includes(dd._id)))
        // console.log(result?.data?.data?.filter(dd => habits?.includes(dd._id)))

        setHabits(result.data.data);
    };



    useEffect(() => {
        getData()
        setAuctionCompletedByDate(setAuctionCompletedByDate())
       
    }, [])

    console.log(auctionCompletedByDate,"auctionCompleteStatus")


    function getDob(date) {
        let dob = moment(date)
        return dob.format('DD-MM-YYYY')
    }

    return (
        <>
            <Loader open={loader} />
            <div className='p-lg-4 p-md-4 p-sm-3 p-2'>
                <div className={style.ViewALL}>

                    <div className='d-flex align-items-center text-align-center mb-3'>

                        <i className='pi pi-arrow-left pointer'
                            //  onClick={() => { navigate('/intendedParent/auctions')}}
                            onClick={() => { navigate(-1) }}
                        />
                        <h2 className='ms-5' >Surrogate Mother Details</h2>
                    </div>
                    <div className={style.HeaderDialog}>

                        <div className="d-flex">
                            <div className={style.ProfilePic}>
                                <img src={auctionData?.photoUrl} className='w-100 h-100' alt="" />
                            </div>
                            <div className={style.PrifileHeding}>
                                <div className='d-flex gap-3 align-items-center'>
                                    <span className={style.Textname}>SM Id: {auctionData?.memberId}</span>
                                    {auctionData?.verify_resume?.status ? <button className={`d-flex align-items-center  ${style.VerfiedButton1}`}><VscVerifiedFilled className='fs-6 me-2' />Verified</button> : null}
                                </div>
                                <br />
                                <div><span className={style.ProfileText}>{auctionData?.short_description}</span></div>
                            </div>
                        </div>
                        <div className='d-flex justify-content-between px-1 pt-2'>
                            <div className='d-flex gap-3 '>
                                <img src="/images/image 140.png" alt="" />
                                <span className={style.LoctionText}>{allData?.state},{allData?.country}</span>
                            </div>
                            <div className='d-flex gap-3 '>
                                {/* <img src="/images/image 141.png" alt="" /> */}
                                {/* <span className={`me-3 ${style.LoctionText}`}>{auctionData?.email}</span> */}
                            </div>
                        </div>
                    </div>
                    <div className='my-4 px-4'>
                        <div className='d-flex w-100'>
                            <div className='w-50'>
                                <table className='w-100'>
                                    <tbody>
                                        <tr className=''>
                                            <td className='w-50'>

                                                {state
                                                    ?
                                                    auctionData?.buyer
                                                        ?
                                                        <><span className={style.CurrentText}>Bought At :</span></>
                                                        :
                                                        auctionCompletedByDate ?
                                                            <><span className={style.CurrentText}>Winning Bid :</span></>
                                                            :
                                                            <><span className={style.CurrentText}>Highest Bid :</span></>

                                                    :
                                                    <><span className={style.CurrentText}>Current Bid :</span></>
                                                }



                                                {/* <span className={style.CurrentText}>Current Bid :</span> */}

                                            </td>
                                            <td className='w-50'>

                                                {state
                                                    ?
                                                    auctionData?.buyer
                                                        ?
                                                        <><span className={style.CurrentText}>${auctionData?.buyer?.price}</span></>
                                                        :
                                                        auctionCompletedByDate ?
                                                            <><span className={style.CurrentText}>${auctionData?.highest_Bid?.bid !== 0 ? auctionData?.highest_Bid?.bid : auctionData.starting_bid_amount}</span></>
                                                            :
                                                            <><span className={style.CurrentText}>${auctionData?.highest_Bid?.bid !== 0 ? auctionData?.highest_Bid?.bid : auctionData.starting_bid_amount}</span></>

                                                    :
                                                    <><span className={style.CurrentText}>${auctionData?.highest_Bid?.bid !== 0 ? auctionData?.highest_Bid?.bid : auctionData.starting_bid_amount}</span></>
                                                }

                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className='w-50'>
                                <table className='w-100'>
                                    <tbody>
                                        <tr className=''>
                                            <td className='w-50'>
                                            {state
                                                    ?
                                                    auctionData?.buyer
                                                        ?
                                                        <><span className={style.CurrentText}>Buy Now Price :</span></>
                                                        :
                                                        auctionCompletedByDate ?
                                                            <><span className={style.CurrentText}>Buy Now Price :</span></>
                                                            :
                                                            <><span className={style.CurrentText}>Buy Now Price :</span></>

                                                    :
                                                    <><span className={style.CurrentText}>Buy Now Price :</span></>
                                                }

                                            </td>

                                            <td className='w-50'>
                                            {state
                                                    ?
                                                    auctionData?.buyer
                                                        ?
                                                        <><span className={style.CurrentText}>${auctionData?.buy_now_price}</span></>
                                                        :
                                                        auctionCompletedByDate ?
                                                            <><span className={style.CurrentText}>${auctionData?.buy_now_price}</span></>
                                                            :
                                                            <><span className={style.CurrentText}>${auctionData?.buy_now_price}</span></>

                                                    :
                                                    <><span className={style.CurrentText}>${auctionData?.buy_now_price}</span></>
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className='d-flex w-100 mt-4'>

                            <div className='w-50'>
                                <table className='w-100'>
                                    <tbody>
                                        <tr>
                                            <td className='w-50'>

                                            {state
                                                    ?
                                                    auctionData?.buyer
                                                        ?
                                                        <><span className={style.CurrentText}>Bought By :</span></>
                                                        :
                                                        
                                                        auctionCompletedByDate ?
                                                            <><span className={style.CurrentText}>Winning Bidder :</span></>
                                                            :
                                                            <><span className={style.CurrentText}>Highest Bidder :</span></>

                                                    :
                                                    <><span className={style.CurrentText}>Surrogacy Count :</span></>
                                                }

                                              
                                            </td>
                                            <td className='w-50'>
                                                <span className={`${style.CurrentText1}`}>
                                                     {state
                                                    ?
                                                    auctionData?.buyer
                                                        ?
                                                        <><span className={style.CurrentText}>{auctionData?.buyer?.buyer_name}</span></>
                                                        :
                                                        auctionCompletedByDate ?
                                                            <><span className={style.CurrentText}>{auctionData?.highest_Bid?.bidder_name||<>No Bidder</>}</span></>
                                                            :
                                                            <><span className={style.CurrentText}>{auctionData?.highest_Bid?.bidder_name||<>No Bidder Till Now</>}</span></>

                                                    :
                                                    <><span className={style.CurrentText}>2</span></>
                                                }


                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className={style.child1}>
                        <div >
                            <div className='CheckBoxParent row '>
                                <div className="col-md-6">
                                    <label className={`form-label  ${style.LabelHeading}`} htmlFor="flexRadioDefault1">
                                        {t("Service Offered")}<span className='text-danger'>*</span>
                                    </label>
                                    <div className="row ">
                                        <div className="col-md-6">
                                            <div className="form-check">
                                                <input className={`form-check-input ${style.InputCustom}`} type="checkbox" checked={allData?.service_offer == "surrogate"} id="flexCheckDefault" />
                                                <label className={`form-check-label ${style.SubLAbelHedaing}`} htmlFor="flexCheckDefault">
                                                    {t("Surrogate Mother")}
                                                </label>
                                            </div>
                                        </div>

                                        <div className="col-md-6">  <div className="form-check">
                                            <input className={`form-check-input ${style.InputCustom}`} type="checkbox" checked={allData?.service_offer == "donner"} id="flexCheckDefault" />
                                            <label className={`form-check-label ${style.SubLAbelHedaing}`} htmlFor="flexCheckDefault">
                                                {t("Egg/Sperm Donor")}
                                            </label>
                                        </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className="row ">
                            <div className="col-md-6">
                                <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`}>{t("Age")}<span className='text-danger'></span></label>
                                <input disabled={true} type="text" value={allData?.age} className={`form-control ${style.InputCustom}`} placeholder="" />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`} >{t("Date of Birth")}<span className='text-danger'>*</span></label>
                                <input disabled={true} type="text" value={getDob(allData?.bod)} className={`form-control ${style.InputCustom}`} placeholder="" />
                            </div>
                        </div>


                        <div className="row">


                            <div className='col-md-6'>
                                <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`}>{t("Height")} <span className='text-danger'>*</span></label>
                                <div className='d-flex w-100 justify-content-between gap-2 align-items-center'>
                                    <input disabled={true} type="text" value={allData?.height?.feet} className={`form-control ${style.InputCustom}`} placeholder="" />
                                    <div className="pb-3">ft</div>
                                    <input disabled={true} type="text" value={allData?.height?.inches} className={`form-control ${style.InputCustom}`} placeholder="" />
                                    <div className="pb-3">inches</div>
                                </div>
                            </div>

                            <div className='col-md-6'>
                                <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`}>{t("Weight")} <span className='text-danger'>*</span></label>

                                <div className='d-flex w-100 justify-content-between gap-2 align-items-center'>
                                    <input disabled={true} type="text" value={allData?.weight?.LBs} className={`form-control ${style.InputCustom}`} placeholder="" />
                                    <div className="pb-3">Lbs</div>
                                    <input disabled={true} type="text" value={allData?.weight?.oz} className={`form-control ${style.InputCustom}`} placeholder="" />
                                    <div className="pb-3">oz</div>
                                </div>


                            </div>

                        </div>


                        <div className="row ">
                            <div className="col-md-12">
                                <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`}>{t("Current Address")}<span className='text-danger'>*</span></label>
                                <input disabled={true} type="text" value={allData?.current_address} className={`form-control ${style.InputCustom}`} placeholder="" />
                            </div>
                        </div>
                        <div className="row ">
                            <div className="col-md-6">
                                <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`}>{t("Country")} </label>
                                <input disabled={true} type="text" value={allData?.country} className={`form-control ${style.InputCustom}`} placeholder="" />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`} >{t("State of Residence")} </label>
                                <input disabled={true} type="text" value={allData?.state} className={`form-control ${style.InputCustom}`} placeholder="" />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`}>{t("City")} </label>
                                <input disabled={true} type="text" value={allData?.city} className={`form-control ${style.InputCustom}`} placeholder="" />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`} >{t("Zip Code")} </label>
                                <input disabled={true} type="text" value={allData?.zipCode} className={`form-control ${style.InputCustom}`} placeholder="" />
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col-md-6">
                                <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`} >{t("Lifestyle")} </label>
                                <MultiSelect
                                    value={selectedLifeStyle}
                                    disabled={true}
                                    // onChange={(e) => setSelectedLifeStyle(e.value)}

                                    options={lifestyle}
                                    optionLabel="name"
                                    display="chip"
                                    showSelectAll={false}
                                    closeIcon={false}
                                    placeholder=" "
                                    maxSelectedLabels={5}
                                    className={`multiSelectDropDown py-2 ps-2`}
                                />
                            </div>

                            <div className="col-md-6">
                                <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`} >{t("Habits")} </label>
                                <MultiSelect
                                    value={selectedHabits}
                                    disabled={true}
                                    // onChange={(e) => setSelectedHabits(e.value)}

                                    options={habits}
                                    optionLabel="name"
                                    display="chip"
                                    placeholder=" "
                                    showSelectAll={false}
                                    closeIcon={false}
                                    maxSelectedLabels={5}
                                    className={`multiSelectDropDown py-2 ps-2`}
                                />
                            </div>


                        </div>


                        <div className="row ">


                            <div className="col-md-6">
                                <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`} >{t("Race")} </label>
                                <input disabled={true} type="text" value={allData?.race?.join()} className={`form-control ${style.InputCustom}`} placeholder="" />
                            </div>
                            <div className="col-md-6">

                            </div>
                        </div>

                        <div className="section2">
                            {console.log(allData?.child_details, "child details")}
                            {allData?.child_details && allData?.child_details?.length !== 0 ? <div className={style.HistoryText}>Reproductive History</div> : null}

                            {allData?.child_details?.map((res, index) => {
                                return (
                                    <div className="child1" key={index}>
                                        <div >
                                            <span className={`${style.MiddleSubHeading}`}>{t("Child")}#{index + 1}</span>

                                            <div className='CheckBoxParent row mt-5'>
                                                <div className="col-md-6">
                                                    <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`} >{t("Gender")} </label>

                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-check">
                                                                <input className={`form-check-input ${style.InputCustom}`} type="radio" checked={res?.gender?.toLowerCase() == "male"} id="flexRadioDefault1" />
                                                                <label className={`form-check-label ${style.SubLAbelHedaing}`} htmlFor="flexRadioDefault1">
                                                                    {t("Male")}
                                                                </label>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">

                                                            <div className="form-check">
                                                                <input className={`form-check-input ${style.InputCustom}`} type="radio" checked={res?.gender?.toLowerCase() == "female"} id="flexRadioDefault1" />
                                                                <label className={`form-check-label ${style.SubLAbelHedaing}`} htmlFor="flexRadioDefault1">
                                                                    {t("Female")}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`} >{t("Date of Birth")} </label>
                                                    <input disabled={true} type="text" value={getDob(res?.dob)} className={`form-control ${style.InputCustom}`} placeholder="" />

                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`} >{t("Months to conceive")} </label>
                                                    <input disabled={true} type="text" value={res?.conceive} className={`form-control ${style.InputCustom}`} placeholder="" />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`} >{t("Weeks at Delivery")} </label>
                                                    <input disabled={true} type="text" value={res?.weeks_at_delivery} className={`form-control ${style.InputCustom}`} placeholder="" />

                                                </div>
                                            </div>


                                            <div className="row">
                                                <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`}>{t("Weight")} <span className='text-danger'>*</span></label>
                                                <div className='col-md-6'>
                                                    <div className='d-flex justify-content-between align-items-center gap-2'>
                                                        <input disabled={true} type="text" value={res?.body_weight?.lbs} className={`form-control ${style.InputCustom}`} placeholder="" />
                                                        <div className="pb-3">Lbs</div>
                                                    </div>
                                                </div>

                                                <div className='col-md-6'>
                                                    <div className='d-flex justify-content-between align-items-center gap-2'>
                                                        <input disabled={true} type="text" value={res?.body_weight?.oz} className={`form-control ${style.InputCustom}`} placeholder="" />
                                                        <div className="pb-3">oz</div>
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="row ">
                                                <div className="col-md-12">
                                                    <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`} >{t("Name of OB")} </label>
                                                    <input disabled={true} type="text" value={res?.ob_name} className={`form-control ${style.InputCustom}`} placeholder="" />

                                                </div>
                                                <div className="col-md-12">
                                                    <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`} >{t("Delivery Hospital and State")} </label>
                                                    <input disabled={true} type="text" value={res?.delivery_hospital_state} className={`form-control ${style.InputCustom}`} placeholder="" />

                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-8">
                                                    <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`} >{t("This baby was")} </label>

                                                    <div className="row">
                                                        <div className="col-md-8">
                                                            <div className="form-check">
                                                                <input className={`form-check-input ${style.InputCustom}`} type="radio" checked={res?.this_baby_was?.toLowerCase() == 'my own child'} id="flexRadioDefault1" />
                                                                <label className={`form-check-label ${style.SubLAbelHedaing}`} htmlFor="flexRadioDefault1">
                                                                    {t("My Own Child")}
                                                                </label>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4">
                                                            <div className="form-check">
                                                                <input className={`form-check-input ${style.InputCustom}`} type="radio" checked={res?.this_baby_was?.toLowerCase() == 'surrogate birth'} id="flexRadioDefault1" />
                                                                <label className={`form-check-label ${style.SubLAbelHedaing}`} htmlFor="flexRadioDefault1">
                                                                    {t("Surrogate Birth")}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                )
                            })}
                        </div>

                        {state ?
                            null :
                            <>
                                {auctionData.buyer ? "" : <div className=' d-flex gap-4 justify-content-between mt-5'>
                                    <button className={`${style.BuyNowButtom}`} onClick={() => { setBuyNowOpen(true) }}>Buy Now</button>
                                    <button className={`${style.widButton}`} onClick={() => {
                                        setBidNowOpen(true);
                                    }}>Bid Now</button>
                                </div>}
                            </>
                        }



                    </div>




                </div>
            </div>
            <BuyNowModal show={buyNowOPen} reload={getData} setShow={setBuyNowOpen} id={id} />

            <BidNowDialog show={bidNowOpen} reload={getData} setShow={setBidNowOpen} id={id} />
        </>
    )
}

export default AuctionResumedialog