import React, { useEffect, useState } from "react";
import ClientTable from "../MyClientTable/ClientTable";
import style from "./MyClient.module.css";
import { InputText } from "primereact/inputtext";
import AddDocument from "../MyClientTable/AddDocumentDialog/AddDocument";
import { getIvfClinics } from "../../../../../../Api/admin";
import Loader from "../../../../../common/Loader";


const Myclient = () => {


  const [addocument, setAddDocument] = useState(false);
  const [loader, setloader] = useState(true)
  /**
 * filter data state is needed to filter data from it , basically its a copy of alldata
 */
  const [filterData, setFilterData] = useState([])


  const [dataone, setDataone] = useState([

  ]);


  const fetchClient = async () => {
    setloader(true)
    const result = await getIvfClinics()
    console.log(result.data.data)
    setDataone(result.data.data)
    setFilterData(result.data.data)
    setloader(false)
  }

  /**
   * This Below function is for search functionality 
   */
  const handleSearchClick = (e) => {
    let val = e.trim().toLowerCase();
    let searchName = filterData.filter((res) => {
      const fullName = (res?.fullName?.trim().toLowerCase().replace(/\s+/g, '') || '') +
        (res?.memberId?.trim().toLowerCase().replace(/\s+/g, '') || '');
      return fullName.includes(val.replace(/\s+/g, ''));
    });
    setDataone(searchName);
  };

  useEffect(() => {
    fetchClient()
  }, [])

  return (
    <div className={style.ClientTable}>
      <Loader open={loader} />
      <AddDocument addocument={addocument} setAddDocument={setAddDocument} fetchClient={fetchClient} />
      <div className="d-flex justify-content-between mb-4 mt-3 ">
        <div>
          {" "}
          <span className="lufga_24px fw_600">IVF Clinics</span>
        </div>
        <div className="d-flex gap-2 align-items-center">
          <div>
            <button
              className="theme_button"
              onClick={() => setAddDocument(true)}
            >
              + Add Account
            </button>
          </div>
          <div>
            <span className={`p-input-icon-left d-flex align-items-center `}>
              <div className="searchInput w-100">
                <input
                  className="form-control ps-5"
                  placeholder="Search"
                  onChange={(e) => handleSearchClick(e.target.value)}
                ></input>
                <i className="pi pi-search searchIcon"></i>
              </div>
            </span>
          </div>

        </div>
      </div>

      <ClientTable data={dataone} update={fetchClient} role="all_clients" />

    </div>
  );
};

export default Myclient;
