import React, { useEffect, useState } from "react";
import style from "./AuctionNew.module.css";
import { useTranslation } from "react-i18next";
import { InputText } from "primereact/inputtext";
import { BsDot } from "react-icons/bs";
import { PopoverItem } from "../../commonComponents/popovericon/popovericon";
import PopOver from "../../commonComponents/popover/popover";
import Pop from "../../../components/commonComponents/popover/popover";
import { FaPowerOff } from "react-icons/fa6";
import { LiaEdit } from "react-icons/lia";
import { useNavigate, useParams } from "react-router-dom";
import {
  auctionStatus,
  deleteAuctionById,
  getAllSurrogateAuction,
  getSurrogateMotherData,
} from "../../../Api/SurrogateMotherApis";
import moment from "moment";

import AuctionForm from "../Auction/AuctionForm/AuctionForm";
import { Dialog } from "primereact/dialog";

// import { updateStatus } from "../../../Api/admin";
// import Addplan from "./Add_plans/AddPlan";

// import DeletePlans from "./delete_Subscription_Plans/DeletePlans";

// import EditPlan from "./Edit_Plan/EditPlan";

import { getPlan } from "../../../Api/admin";
import Loader from "../../common/Loader";

const AuctionNew = ({ getData, showhead, getALLData, setToaster }) => {
  let { t } = useTranslation();

  const [AddPlan, setAddPlan] = useState(false);
  const [resume, setResume] = useState(false);
  const [EditPlans, setEditPlans] = useState(false);
  const [activeTab, setActiveTab] = useState("home");
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [resId, setResId] = useState();
  const [loader, setLoader] = useState(false);
  const [activeauction, setactiveauction] = useState([]);
  const [inactiveauction, setinactiveauction] = useState([]);
  const [allauctions, setallauctions] = useState([]);
  const [showData, setShowData] = useState(false);

 

  let userData = JSON.parse(sessionStorage.getItem("userData"));

  const [visible, setVisible] = useState(false);
  const [viewData, setViewData] = useState("");
  const [getAllSurrogate, setGetAllSurrogate] = useState([]);
  //   let startTime = new Date().toISOString();
  //   let duration = moment.duration(moment().diff(startTime));
  const navigate = useNavigate();



  const getAllSurrogateMotherData = async () => {
    await getSurrogateMotherData(userData.userId)
      .then((res) => {
      
        setGetAllSurrogate(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const auction_status_changes = async (status, id) => {
    if (status === true) {
      await auctionStatus(id, {
        active_status: false,
        userId: userData.userId,
      });
    } else {
      await auctionStatus(id, {
        active_status: true,
        userId: userData.userId,
      }).catch((error) => {
        setToaster({
          show: true,
          message: "you can't active more than one auctions at the same time",
        });
      });
    }

    getALLData();
  };


  useEffect(() => {
    if (getData && getData.active_Auction && getData.inactive_Auction) {
      setactiveauction(getData.active_Auction || []);
      setinactiveauction(getData.inactive_Auction || []);
      setallauctions([
        ...(getData.active_Auction || []),
        ...(getData.inactive_Auction || []),
      ]);
      if (activeTab === "home") {
        setFilteredData([
          ...(getData.active_Auction || []),
          ...(getData.inactive_Auction || []),
        ]); // Set filteredData on initial load
      } else if (activeTab === "profile") {
        setFilteredData(getData.active_Auction); // Set filteredData on initial load
      } else {
        setFilteredData(getData.inactive_Auction); // Set filteredData on initial load
      }
      setShowData(true);
    }
  }, [getData]); // Ensure this effect runs whenever getData changes

  const auctionDelete = async (id) => {
    setLoader(true);
    await deleteAuctionById(id)
      .then((res) => {
        setLoader(false);
        setVisible(false);
        getALLData();
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };



  var fetchPlan = async () => {
    var result = await getPlan();
    setData(result.data.data);
    setFilteredData(result.data.data);
   
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    if (tab === "profile") {
      // Assuming "profile" is for active plans
      setFilteredData(activeauction);
    } else if (tab === "contact") {
      // Assuming "contact" is for inactive plans
      setFilteredData(inactiveauction);
    } else {
      setFilteredData(allauctions); // "home" tab shows all plans
    }
  };

 

 
  const allPlansCount = allauctions.length;
  const activePlansCount = activeauction.length;
  const inactivePlansCount = inactiveauction.length;

  useEffect(function () {
    getAllSurrogateMotherData();
  }, []);

  return (
    <div className="mt-4">
      <Loader open={loader} />

      <Dialog
        modal
        visible={visible}
        style={{ width: "40rem" }}
        onHide={() => setVisible(false)}
        closable={false}
        draggable={false}
      >
        <div>
          <h3>Delete Post</h3>
          <p className="mt-2">Are you sure you want to delete this post ?</p>
          <div className="d-flex justify-content-end mt-5">
            <div className="d-flex">
              <button
                className="btn btn-secondary me-2"
                onClick={() => {
                  setVisible(false);
                }}
              >
                Cancel
              </button>
              <button
                className="btn btn-danger"
                onClick={() => {
                  auctionDelete(resId);
                }}
              >
                Delete <i className="pi pi-trash danger" />
              </button>
            </div>
          </div>
        </div>
      </Dialog>

      <AuctionForm
        resumeone={resume}
        setResumeone={setResume}
        viewData={viewData}
        getAllSurrogate={getAllSurrogate}
      />

      <div className={style.social_pages_background}>
        <nav>
          <ul
            className={`${style.nav} ${style.navTabs}`}
            id="myTab"
            role="tablist"
          >
            <li className={style.navItem} role="presentation">
              <button
                className={`${style.navLink} ${
                  activeTab === "home" && style.active
                }`}
                onClick={() => handleTabClick("home")}
              >
                All Auction{" "}
                <div className={`${style.count} fw_600`}>
                  {allauctions.length}
                </div>
              </button>
            </li>
            <li className={style.navItem} role="presentation">
              <button
                className={`${style.navLink} ${
                  activeTab === "profile" && style.active
                }`}
                onClick={() => handleTabClick("profile")}
              >
                Active{" "}
                <div className={`${style.count} fw_600`}>
                  {activePlansCount}
                </div>
              </button>
            </li>
            <li className={style.navItem} role="presentation">
              <button
                className={`${style.navLink} ${
                  activeTab === "contact" && style.active
                }`}
                onClick={() => handleTabClick("contact")}
              >
                Inactive{" "}
                <div className={`${style.count} fw_600`}>
                  {inactivePlansCount}
                </div>
              </button>
            </li>
          </ul>
        </nav>

        <div className="m-4 row pb-5">
          {showData && (
            <>
              {allPlansCount === 0 ? (
                <>
                  <div>
                    <div className={`${style.image_section}`}>
                      <img
                        src="/images/undercons.png"
                        alt="Under Construction Image"
                      />
                    </div>

                    <div className={`${style.content_section}`}>
                      <div className={`${style.lufga_20px}`}>
                        No Auction Post Added
                      </div>
                      <div
                        className={`${style.lufga_20px} ${style.theme_color}`}
                      >
                        Earn more with innovative service auction
                      </div>
                      <button
                        className="theme_button"
                        onClick={() => {
                          navigate("createpost");
                        }}
                      >
                        Add Post
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {filteredData.length === 0 ? (
                    <>
                      <div>
                        <div className={`${style.image_section}`}>
                          <img
                            src="/images/undercons.png"
                            alt="Under Construction Image"
                          />
                        </div>

                        <div className={`${style.content_section}`}>
                          <div className={`${style.lufga_20px}`}>
                            No{" "}
                            {activeTab === "profile"
                              ? "Active"
                              : activeTab === "contact"
                              ? "Inactive"
                              : ""}{" "}
                            Auction Post 
                          </div>
                        
                        </div>
                      </div>
                    </>
                  ) : (
                    
                    <div
                    // key={index}
                    className={`${style.cardContainer} mt-5`}
                  >
                    {filteredData?.map((res, index) => (
                    
                      // <div
                      //   key={index}
                      //   className={`${style.cardContainer} mt-5`}
                      // >
                       
                        <div className={`${style.maincardContainer} w-100`}>
                          <div
                            style={{ height: "100%" }}
                            className="d-flex align-items-end"
                          >
                            <div className={`w-100  ${style.maincardContainer}`}>
                              <div
                                className={`${style.card_upper}`}
                                // style={{
                                //   backgroundImage: `url('${res?.photoUrl}')`,
                                // }}
                              >
                                  <div
                   className={`${style.card_upper_mask}`}
                   style={{
                    backgroundImage: `url('${res?.photoUrl}')`,
                  }}
                  >

                  </div>
                  <img
                    className={`${style.card_upper_image}`}
                    src={ res?.photoUrl}
                  />
                                {/* <img
                     className="card-img-top"
                     src={`${res.photoUrl}`}
                     alt={t("Card image cap")}
                   /> */}

                                <div className={`${style.upper_v_1}`}>
                                  <div className={`${style.upper_v_1_1}`}>
                                    <div className={`${style.upper_v_1_1_1}`}>
                                      <Pop onClick={() => setResId(res?._id)}>
                                        <PopoverItem
                                          text={"Edit"}
                                          icon={
                                            <img
                                              src="/images/edit.png"
                                              style={{
                                                height: "19px",
                                                width: "19px",
                                              }}
                                            />
                                          }
                                          onClick={() =>
                                            navigate(
                                              `editpostauction/${res._id}`
                                            )
                                          }
                                        />
                                        <PopoverItem
                                          text={
                                            <span style={{ color: "red " }}>
                                              Delete
                                            </span>
                                          }
                                          icon={
                                            <img
                                              src="/images/reddelete.png"
                                              style={{
                                                height: "18px",
                                                width: "18px",
                                              }}
                                            />
                                          }
                                          onClick={() => {
                                            setVisible(true);
                                            setResId(res?._id);
                                          }}
                                        />
                                        <PopoverItem
                                          text={
                                            res.active_status === true
                                              ? "Inactive"
                                              : "Active"
                                          }
                                          icon={
                                            res.active_status === true ? (
                                              <img
                                                src="/images/Inactive.svg"
                                                style={{
                                                  height: "18px",
                                                  width: "18px",
                                                }}
                                              />
                                            ) : (
                                              <img
                                                src="/images/active.svg"
                                                style={{
                                                  height: "18px",
                                                  width: "18px",
                                                }}
                                              />
                                            )
                                          }
                                          onClick={() => {
                                            // setVisible(true);
                                            // setResId(res?._id);
                                            auction_status_changes(
                                              res.active_status,
                                              res._id
                                            );
                                          }}
                                        />
                                      </Pop>
                                    </div>
                                  </div>
                                </div>
                                <div className={`${style.upper_v_2}`}>
                                  <div className={`${style.upper_v_2_1}`}>
                                    End Date &nbsp;{" "}
                                    {t(
                                     
                                      moment(res.endDate).format("DD/MM/YYYY")
                                    )}
                                  </div>

                                  {res.active_status === true ? (
                                    <div
                                      className={`${style.upper_v_2_2}`}
                                      style={{ color: "green" }}
                                    >
                                      <BsDot />
                                      {t("Active")}
                                    </div>
                                  ) : (
                                    <div
                                      className={`${style.upper_v_2_2}`}
                                      style={{ color: "red" }}
                                    >
                                      <BsDot />
                                      {t("Inactive")}
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className={`${style.card_lower}`}>
                                <div
                                  className={`${style.lower_v_1} lufga_18px`}
                                >
                                  {t(res.name)},{t()}
                                </div>
                                <div className={`${style.lower_v_2}`}>
                                  {t(res.short_description)}
                                </div>
                                <div
                                  className={`${style.lower_v_3}`}
                                  onClick={() => {
                                    setViewData(res);
                                    setResume(true);
                                  }}
                                >
                                  View Details
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      // </div>
                    ))}
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>

      {/* <DeletePlans resumeone={resume} setResumeone={setResume} id={resId} fetchPlan={fetchPlan} /> */}
    </div>
  );
};

export default AuctionNew;
