import React, { useEffect, useState } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import style from "./Setting.module.css";
import { InputSwitch } from "primereact/inputswitch";
import Loader from "../../common/Loader";
import { getDashboardUser } from "../../../Api/CommonApis";
import {
  emailNotificationData,
  pushNotificationData,
} from "../../../Api/IntendedParent";

import { changeUserPassword } from "../../../Api/CommonApis";
import { t } from "i18next";
import Toaster from "../../common/Toaster";
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router";
import { getFaqDatabyrole } from "../../../Api/admin";

const Setting = () => {
  const [loader, setLoader] = useState(true);
  const [pushNotification, setPushNotification] = useState({
    welcome_notifications: false,
    subscription_payment_confirmations: false,
    password_reset_confirmations: false,
    notification_for_profile_data_edits: false,
    new_match_making_alerts: false,
    shortlisted_candidate_submissions: false,
    new_auction_joins: false,
    latest_auction_price: false,
    security_deposit_success_notifications: false,
    new_bids_alerts: false,
    auction_winner_declarations: false,
  });
  const [emailNotification, setEmailNotification] = useState({
    welcome_notifications: false,
    subscription_payment_confirmation: false,
    password_reset: false,
    profile_data_edit_emails: false,
    email_alerts_for_new_match_making: false,
    shortlisted_candidate_submissions: false,
    new_auction_joins: false,
    security_deposit_success: false,
    new_bids: false,
    auction_winner_declarations: false,
    security_deposit_refunds: false,
  });
  let userId = sessionStorage.getItem("userId");
  
  let navigator = useNavigate();

  const [passwordsMatch, setPasswordMatch] = useState(true);
  const [error, setError] = useState("");
  const [error2, setError2] = useState("");
  const [error3, setError3] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });

  const [toaster, setToaster] = useState({
    show: false,
    message: "",
  });
  const [faqData, setfaqData] = useState([]);

  const getDataFaqByRole = async () => {
    setLoader(true);
    await getFaqDatabyrole("intended_parents")
      .then((res) => {
        console.log(res);
        setLoader(false);
        setfaqData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };
 

  async function changePassword(e) {
    e.preventDefault();

    if (error !== "" || error2 !== "" || error3 !== "") {
      return;
    }

    if (passwordsMatch === false) {
      return;
    }

    setLoader(true);

    try {
      await changeUserPassword(userId, {
        newPassword: formData.password,
      });
    } catch (err) {
      console.log(err);
    } finally {
      setFormData({
        password: "",
        confirmPassword: "",
      });
      setToaster({
        show: true,
        message: "Your password has been changed successfully",
      });
      setLoader(false);
    }
  }
  function inpChange(e) {
    let { name, value } = e.target;

    if (name === "password") {
      if (value.trim() !== "") {
        if (
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+}{":;'?/><,.\[\]\\]).{8,}$/.test(
            value.trim()
          )
        ) {
          setError2("");
          setError("");
          setError3("");
        } else {
          if (value.length < 8) {
            setError2(t("Password must have at least 8 characters"));
          } else {
            setError2("");
          }

          if (!/[a-z]/.test(value) || !/[A-Z]/.test(value)) {
            setError(
              t("A mix of both uppercase and lowercase letters is required")
            );
          } else {
            setError("");
          }

          if (!/[!@#$%^&*()_+}{":;'?/><,.\[\]\\]/.test(value)) {
            setError3(
              t(
                "Inclusion of at least one special character (e.g., @ # ?) is required"
              )
            );
          } else {
            setError3("");
          }
        }
      } else {
        setError("");
        setError2("");
        setError3("");
      }
      if (formData?.confirmPassword !== "") {
        if (formData.confirmPassword === value) {
          setPasswordMatch(true);
        } else {
          setPasswordMatch(false);
        }
      }
    } else if (name === "confirmPassword") {
      if (formData.password === value) {
        setPasswordMatch(true);
      } else {
        setPasswordMatch(false);
      }
    }

    setFormData((prevState) => ({ ...prevState, [name]: value.trim() }));
  }

  async function getUserData() {
    try {
      let res = await getDashboardUser(userId);
      if (res.data.data.pushNotification_ip !== undefined) {
        setPushNotification((pre) => {
          return { ...pre, ...res.data.data.pushNotification_ip };
        });
      }

      if (res.data.data.emailNotification_ip !== undefined) {
        setEmailNotification((pre) => {
          return { ...pre, ...res.data.data.emailNotification_ip };
        });
      }

      setLoader(false);
      console.log(res);
    } catch (err) {
      console.log(err);
      setLoader(false);
    }
  }

  async function inputChange(e, type) {
    if (type == "pushNotification") {
      let obj = { ...pushNotification, [e.target.name]: e.target.value };
      setPushNotification(obj);
      await pushNotificationData(userId, obj);
    } else if (type == "emailNotification") {
      let obj = { ...emailNotification, [e.target.name]: e.target.value };
      setEmailNotification(obj);
      await emailNotificationData(userId, obj);
    }
  }

  useEffect(() => {
    getUserData();
    getDataFaqByRole();
  }, []);

  return (
    <div>
      <Toaster
        // event={"Please InActive Current Post then you Add new Post"}
        event={toaster.message}
        show={toaster.show}
        severity={"success"}
        setToaster={setToaster}
      />
      <Loader open={loader} />
      <div className="mt-3">
        <Accordion
          multiple
          activeIndex={[0]}
          className={style.AccordionTabheader}
        >
          <AccordionTab
            header="Push Notification"
            className={style.AccordionBAck}
          >
            <div className={style.AccordingTab}>
              <div className={style.TabHeading}>Welcome notifications</div>
              <div className="d-flex align-items-center">
                {" "}
                <InputSwitch
                  checked={pushNotification.welcome_notifications}
                  name="welcome_notifications"
                  onChange={(e) => inputChange(e, "pushNotification")}
                />
              </div>
            </div>

            <div className={style.AccordingTab}>
              <div className={style.TabHeading}>
                Subscription payment confirmations
              </div>
              <div className="d-flex align-items-center">
                {" "}
                <InputSwitch
                  checked={pushNotification.subscription_payment_confirmations}
                  name="subscription_payment_confirmations"
                  onChange={(e) => inputChange(e, "pushNotification")}
                />
              </div>
            </div>

            <div className={style.AccordingTab}>
              <div className={style.TabHeading}>
                Password reset confirmations
              </div>
              <div className="d-flex align-items-center">
                {" "}
                <InputSwitch
                  checked={pushNotification.password_reset_confirmations}
                  name="password_reset_confirmations"
                  onChange={(e) => inputChange(e, "pushNotification")}
                />
              </div>
            </div>

            <div className={style.AccordingTab}>
              <div className={style.TabHeading}>
                Notifications for profile data edits,
              </div>
              <div className="d-flex align-items-center">
                {" "}
                <InputSwitch
                  checked={pushNotification.notification_for_profile_data_edits}
                  name="notification_for_profile_data_edits"
                  onChange={(e) => inputChange(e, "pushNotification")}
                />
              </div>
            </div>

            <div className={style.AccordingTab}>
              <div className={style.TabHeading}>New match-making alerts</div>
              <div className="d-flex align-items-center">
                {" "}
                <InputSwitch
                  checked={pushNotification.new_match_making_alerts}
                  name="new_match_making_alerts"
                  onChange={(e) => inputChange(e, "pushNotification")}
                />
              </div>
            </div>

            <div className={style.AccordingTab}>
              <div className={style.TabHeading}>
                Shortlisted candidate submissions
              </div>
              <div className="d-flex align-items-center">
                <InputSwitch
                  checked={pushNotification.shortlisted_candidate_submissions}
                  name="shortlisted_candidate_submissions"
                  onChange={(e) => inputChange(e, "pushNotification")}
                />
              </div>
            </div>

            <div className={style.AccordingTab}>
              <div className={style.TabHeading}>New auction joins</div>
              <div className="d-flex align-items-center">
                <InputSwitch
                  checked={pushNotification.new_auction_joins}
                  name="new_auction_joins"
                  onChange={(e) => inputChange(e, "pushNotification")}
                />
              </div>
            </div>

            <div className={style.AccordingTab}>
              <div className={style.TabHeading}>Latest auction price</div>
              <div className="d-flex align-items-center">
                <InputSwitch
                  checked={pushNotification.latest_auction_price}
                  name="latest_auction_price"
                  onChange={(e) => inputChange(e, "pushNotification")}
                />
              </div>
            </div>
            <div className={style.AccordingTab}>
              <div className={style.TabHeading}>
                Security deposit success notifications
              </div>
              <div className="d-flex align-items-center">
                <InputSwitch
                  checked={
                    pushNotification.security_deposit_success_notifications
                  }
                  name="security_deposit_success_notifications"
                  onChange={(e) => inputChange(e, "pushNotification")}
                />
              </div>
            </div>
            <div className={style.AccordingTab}>
              <div className={style.TabHeading}>New bids alerts</div>
              <div className="d-flex align-items-center">
                <InputSwitch
                  checked={pushNotification.new_bids_alerts}
                  name="new_bids_alerts"
                  onChange={(e) => inputChange(e, "pushNotification")}
                />
              </div>
            </div>
            <div className={style.AccordingTab}>
              <div className={style.TabHeading}>
                Auction winner declarations.
              </div>
              <div className="d-flex align-items-center">
                <InputSwitch
                  checked={pushNotification.auction_winner_declarations}
                  name="auction_winner_declarations"
                  onChange={(e) => inputChange(e, "pushNotification")}
                />
              </div>
            </div>
          </AccordionTab>

          <AccordionTab
            header="Email Notifications"
            className={style.AccordionBAck}
          >
            <div className={style.AccordingTab}>
              <div className={style.TabHeading}>Welcome notifications</div>
              <div className="d-flex align-items-center">
                {" "}
                <InputSwitch
                  checked={emailNotification.welcome_notifications}
                  name="welcome_notifications"
                  onChange={(e) => inputChange(e, "emailNotification")}
                />
              </div>
            </div>

            <div className={style.AccordingTab}>
              <div className={style.TabHeading}>
                Subscription payment confirmations
              </div>
              <div className="d-flex align-items-center">
                {" "}
                <InputSwitch
                  checked={emailNotification.subscription_payment_confirmation}
                  name="subscription_payment_confirmation"
                  onChange={(e) => inputChange(e, "emailNotification")}
                />
              </div>
            </div>

            <div className={style.AccordingTab}>
              <div className={style.TabHeading}>Password reset</div>
              <div className="d-flex align-items-center">
                {" "}
                <InputSwitch
                  checked={emailNotification.password_reset}
                  name="password_reset"
                  onChange={(e) => inputChange(e, "emailNotification")}
                />
              </div>
            </div>

            <div className={style.AccordingTab}>
              <div className={style.TabHeading}>Profile data edit emails</div>
              <div className="d-flex align-items-center">
                {" "}
                <InputSwitch
                  checked={emailNotification.profile_data_edit_emails}
                  name="profile_data_edit_emails"
                  onChange={(e) => inputChange(e, "emailNotification")}
                />
              </div>
            </div>

            <div className={style.AccordingTab}>
              <div className={style.TabHeading}>
                Email alerts for new match-making
              </div>
              <div className="d-flex align-items-center">
                {" "}
                <InputSwitch
                  checked={emailNotification.email_alerts_for_new_match_making}
                  name="email_alerts_for_new_match_making"
                  onChange={(e) => inputChange(e, "emailNotification")}
                />
              </div>
            </div>

            <div className={style.AccordingTab}>
              <div className={style.TabHeading}>
                Shortlisted candidate submissions
              </div>
              <div className="d-flex align-items-center">
                <InputSwitch
                  checked={emailNotification.shortlisted_candidate_submissions}
                  name="shortlisted_candidate_submissions"
                  onChange={(e) => inputChange(e, "emailNotification")}
                />
              </div>
            </div>

            <div className={style.AccordingTab}>
              <div className={style.TabHeading}>New auction joins</div>
              <div className="d-flex align-items-center">
                <InputSwitch
                  checked={emailNotification.new_auction_joins}
                  name="new_auction_joins"
                  onChange={(e) => inputChange(e, "emailNotification")}
                />
              </div>
            </div>

            <div className={style.AccordingTab}>
              <div className={style.TabHeading}>Security deposit success</div>
              <div className="d-flex align-items-center">
                <InputSwitch
                  checked={emailNotification.security_deposit_success}
                  name="security_deposit_success"
                  onChange={(e) => inputChange(e, "emailNotification")}
                />
              </div>
            </div>
            <div className={style.AccordingTab}>
              <div className={style.TabHeading}>New bids </div>
              <div className="d-flex align-items-center">
                <InputSwitch
                  checked={emailNotification.new_bids}
                  name="new_bids"
                  onChange={(e) => inputChange(e, "emailNotification")}
                />
              </div>
            </div>
            <div className={style.AccordingTab}>
              <div className={style.TabHeading}>
                Auction winner declarations.
              </div>
              <div className="d-flex align-items-center">
                <InputSwitch
                  checked={emailNotification.auction_winner_declarations}
                  name="auction_winner_declarations"
                  onChange={(e) => inputChange(e, "emailNotification")}
                />
              </div>
            </div>
            <div className={style.AccordingTab}>
              <div className={style.TabHeading}>Security deposit refunds</div>
              <div className="d-flex align-items-center">
                <InputSwitch
                  checked={emailNotification.security_deposit_refunds}
                  name="security_deposit_refunds"
                  onChange={(e) => inputChange(e, "emailNotification")}
                />
              </div>
            </div>
          </AccordionTab>
          
          <AccordionTab
            header="Change Password"
            className={style.AccordionBAck}
          >
            <div className={style.PasswordBack}>
              <form onSubmit={changePassword}>
                <div className="row mt-3">
                  <div className="col-md-6">
                    <label
                      className={`form-check-label mb-2 ${style.LabelText}`}
                      htmlFor="flexCheckDefault"
                    >
                      New Password<span className="text-danger">*</span>
                    </label>
                    <div className="p-relative">
                      <input
                        type={showPassword ? "text" : "password"}
                        required
                        className="form-control"
                        name="password"
                        value={formData.password}
                        placeholder="Enter New Password"
                        onChange={inpChange}
                      />
                      {!showPassword ? (
                        <i
                          className="bi bi-eye-slash viewPasswordIcon pointer"
                          onClick={() => setShowPassword(!showPassword)}
                        ></i>
                      ) : (
                        <i
                          className="bi bi-eye viewPasswordIcon pointer"
                          onClick={() => setShowPassword(!showPassword)}
                        ></i>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label
                      className={`form-check-label mb-2 ${style.LabelText}`}
                      htmlFor="flexCheckDefault"
                    >
                      Confirm Password<span className="text-danger">*</span>
                    </label>
                    <div className="p-relative">
                      <input
                        type={showConfirmPassword ? "text" : "password"}
                        required
                        className="form-control"
                        name="confirmPassword"
                        placeholder="Confirm New Password"
                        value={formData.confirmPassword}
                        onChange={inpChange}
                      />
                      {!showConfirmPassword ? (
                        <i
                          className="bi bi-eye-slash viewPasswordIcon pointer"
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                        ></i>
                      ) : (
                        <i
                          className="bi bi-eye viewPasswordIcon pointer"
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                        ></i>
                      )}
                    </div>
                    <div className="text-danger mt-1">
                      {passwordsMatch ? "" : t("Password doesn't match")}
                    </div>
                  </div>
                </div>
                {error && <li className="text-danger mt-1">{error}</li>}
                {error2 && <li className="text-danger mt-1">{error2}</li>}
                {error3 && <li className="text-danger mt-1">{error3}</li>}
                <div className="d-flex justify-content-end gap-3 my-4">
                  {/* <button type="button" className='btn-Secondary'>Cancel</button> */}
                  <button type="submit" className="btn-Primary">
                    Save Changes
                  </button>
                </div>
              </form>
            </div>
          </AccordionTab>

          <AccordionTab
            header="Informative Content Access"
            className={style.AccordionBAck}
          >
            <div
              className={style.AccordingTab}
              onClick={() => {
                navigator("/intendedParent/termAndconditionIp");
              }}
            >
              <div className={style.TabHeading}>Terms & Conditions</div>
              <div className={style.round}>
                <IoIosArrowForward />
              </div>
            </div>

            <div
              className={style.AccordingTab}
              onClick={() => {
                navigator("/intendedParent/privacypolicyIp");
              }}
            >
              <div className={style.TabHeading}>Privacy Policy</div>
              <div className={style.round}>
                <IoIosArrowForward />
              </div>
            </div>

            <div
              className={style.AccordingTab}
              onClick={() => {
                navigator("/intendedParent/aboutusIp");
              }}
            >
              <div className={style.TabHeading}>About Us</div>
              <div className={style.round}>
                <IoIosArrowForward />
              </div>
            </div>
          </AccordionTab>
          <AccordionTab
            header="Frequently Asked Questions"
            className={style.AccordionBAck}
          >
            {faqData.map((res) => (
              <>
                <Accordion>
                  <AccordionTab
                    header={res.question}
                    className={style.AccordionBAck}
                  >
                    <div className={style.AccordingTab}>
                      <div className={style.TabHeading}>{res.answer}</div>
                    </div>
                  </AccordionTab>
                </Accordion>
              </>
            ))}
          </AccordionTab>
        </Accordion>
      </div>
    </div>
  );
};

export default Setting;
