import React from 'react';
import Select from 'react-select';
import moment from 'moment';

const LawyerMonthYearSelect = ({ selectedMonthYear, onChange }) => {
  const months = moment.months().map((month, index) => ({
    label: month,
    value: index + 1,
  }));

  const currentYear = moment().year();
  const years = [];
  for (let i = currentYear - 10; i <= currentYear + 10; i++) {
    years.push({
      label: i.toString(),
      value: i,
    });
  }

  const handleMonthChange = (selectedMonth) => {
    onChange({
      ...selectedMonthYear,
      month: selectedMonth.value,
    });
  };

  const handleYearChange = (selectedYear) => {
    onChange({
      ...selectedMonthYear,
      year: selectedYear.value,
    });
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      padding: '4px 14px',
      backgroundColor: '#187c8b',
      color: 'white',
      borderRadius: '25px',
      border: 'none',
      outline: 'none',
    }),
    singleValue: (base) => ({
      ...base,
      color: 'white',
    }),
    menu: (base) => ({
      ...base,
      zIndex: 9999,
    }),
  };

  return (
    <div style={{ display: 'flex', gap: '10px' }}>
      <Select
        options={months}
        value={months.find((month) => month.value === selectedMonthYear.month)}
        onChange={handleMonthChange}
        placeholder="Select Month"
        styles={customStyles}
        isSearchable={false}
      />
      <Select
        options={years}
        value={years.find((year) => year.value === selectedMonthYear.year)}
        onChange={handleYearChange}
        placeholder="Select Year"
        styles={customStyles}
        isSearchable={false}
      />
    </div>
  );
};

export default LawyerMonthYearSelect;

