import axios from "axios";
import { baseUrl, dashboardService, embryoDonor } from "./EndPoint"

let userData = JSON.parse(sessionStorage.getItem("userData"))

let config = {
    headers: {
        Authorization: `Bearer ${userData?.token}`,
    }
  }


export async function getProfileDataForEmbryoApi (id){
    return await axios.get(`${baseUrl}${embryoDonor.getProfileDataForEmbryoApi}${id}`)
}

export async function getProfileDataForEmbryoByUserIdApi (id){
    return await axios.get(`${baseUrl}${embryoDonor.getProfileDataForEmbryoByUserIdApi}${id}`)
}

export const addEmbryoMother = async (userId, body) => {
    return await axios.post(
      `${baseUrl}${embryoDonor.addEmbryoMother}${userId}`,
      body
    );
  }; 

  export const addEmbryoFather = async (userId, body) => {
    return await axios.post(
      `${baseUrl}${embryoDonor.addEmbryoFather}${userId}`,
      body
    );
  };  


  export async function verifyEmbryoResume(data,id){
    return await axios.put(`${baseUrl}${embryoDonor.verifyResume}${id}`,data,config)
}

  export async function getAllEmbryosData(){
    return await axios.put(`${baseUrl}${embryoDonor.getAllEmbryo}`)
}


export async function addSearchEmbryos(data){
  return await axios.post(`${baseUrl}${embryoDonor.searchEmbryo}`,data,config)
}


