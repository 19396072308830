import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import style from "./AddDocument.module.css";
import { addSurrogateSuperAdmin } from "../../../../../../../Api/SurrogateMotherApis";
import Loader from "../../../../../../common/Loader";
import Toaster from "../../../../../../common/Toaster";

const AddDocument = ({ addocument, setAddDocument, updatAllData }) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [loader, setLoader] = useState(false)
  const [toaster, setToaster] = useState({
    show: false,
    message: "",
  });

  const [formData, setFormData] = useState({
    fullName: "",
    mobile_No: "",
    email: "",
    role: "surrogate_donor",
    agree_teams_policy: true,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const trimValue = value.trim()
    
    
    setFormData((prevState) => ({
      ...prevState,
      [name]: trimValue,
    }));
  };
  const addSuperAdminSurrogate = async (e) => {
    e.preventDefault()
    setLoader(true)
    let data = { ...formData }
    await addSurrogateSuperAdmin(data).then((res) => {
      console.log(res)
      setLoader(false)
      setAddDocument(false)
      updatAllData()
    }).catch((err) => {
      console.log(err)
      if(err?.response?.data?.errormessage === "this email already exists"){
      setToaster({
        show: true,
        message:"This Email Already Exists",
        
      })
      }
      else{
        setToaster({
          show: true,
          message:"All Fields are mandatory",  
        })
      };
      setLoader(false)

    })

  };

  return (
    <div>
      {toaster && (
        <>
          <Toaster
            event={toaster.message}
            show={toaster.show}
            severity={"error"}
            setToaster={setToaster}
          />
        </>
      )}
      <Loader open={loader} />


      <Dialog
        closable={false}
        visible={addocument}
        className="p-0 m-0"
        onHide={() => setAddDocument(false)}
        style={{ width: "60vw", padding: "0px", margin: "0px" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        <div className="Verfied_con">
          <div className="d-flex justify-content-center mb-4">
            <span className={`fW_600 ${style.verfideText}`}>
              Create Account : Surrogate Mother
            </span>
          </div>

          <form onSubmit={addSuperAdminSurrogate}>

            <div className="row mb-4">
              <div className="col-md-12">
                <label
                  className={`form-check-label mb-1 ${style.LabelText}`}
                  htmlFor="flexCheckDefault"
                >
                  Full Name
                  <span className="text-danger">*</span>

                </label>
                <input
                  required
                  type="text"
                  className="form-control"
                  placeholder="Enter name"
                  name="fullName"
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="row mb-5">
              <div className="col-md-6">
                <label
                  className={`form-check-label mb-1 ${style.LabelText}`}
                  htmlFor="flexCheckDefault"
                >
                  Contact Number
                  <span className="text-danger">*</span>

                </label>
                <input
                  required
                  type="number"
                  className="form-control"
                  placeholder="Mobile number"
                  name="mobile_No"
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-6">
                <label
                  className={`form-check-label mb-1 ${style.LabelText}`}
                  htmlFor="flexCheckDefault"
                >
                  Email Address
                  <span className="text-danger">*</span>

                </label>
                <input
                  required
                  type="email"
                  className="form-control"
                  placeholder="Please enter email id"
                  name="email"
                  onChange={handleInputChange}

                />
              </div>
            </div>

            <div className="d-flex justify-content-end gap-3">
              <button
                className={style.CencelButton}
                onClick={() => setAddDocument(false)}
                type="button"
              >
                Cancel
              </button>
              <button
                className={style.SubmitButton}
                // onClick={() => addSuperAdminSurrogate()}
                type="submit"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </Dialog>

    </div>
  );
};

export default AddDocument;
