import React, { useEffect, useRef, useState } from 'react'
import style from './LawyerMyProfile.module.css'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { getDashboardUser, updateDashboardUser, uploadDocument } from "../../../Api/CommonApis"
import { CiImageOn } from 'react-icons/ci'
import Loader from '../../common/Loader'
import { Country, State, City } from "country-state-city";
import { changeHeaderData } from '../../../Store/Slices/headerDataSlice'
import { useDispatch } from 'react-redux'
import PhoneInputComponent from '../../commonComponents/PhoneInput/PhoneInput'
import Toaster from "../../common/Toaster";


const LawyerMyProfile = () => {
    let { t } = useTranslation()
    const [loader, setLoader] = useState(true)
    const [editable, setEditable] = useState(true)
    const countries = Country.getAllCountries()
    const [country, setCountry] = useState('')
    const [state, setState] = useState('')
    const [city, setCity] = useState('')
    const [statesListing, setStatesListing] = useState([])
    const [citiesListing, setCitiesListing] = useState([])
    const [img, setImg] = useState(null)
    const [defaultImg, setDefaultimg] = useState('/images/lawyerDefaultPic.png')
    const dispatch = useDispatch()
    const [toaster, setToaster] = useState({
        show: false,
        message: "",
    });

    const [photoIndex, setPhotoIndex] = useState(0);

    const [upload, setUpload] = useState(false);

    let userId = sessionStorage.getItem("userId");


    const [userData, setUserData] = useState({
        firm_name:"",
        mobile_No: "",
        mobile_No_alter: "",
        email_alter: "",
        address: "",
        city: "",
        state: "",
        imageUrl: '',
        imageFileName: '',
        country: '',
        zip_code: "",
        textSMS_mobile: false,
        textSMS_mobile_alter: false,
        auctionUpdates: false,
        latestNews: false,
        importantUpdate: false,
        promotionalNotifications: false,
        user_blocked: false,
    })


    async function getUserData() {
        try {
            console.log("userid", userId)
            let res = await getDashboardUser(userId)
            setUserData(pre => { return { ...pre, ...res.data.data } })
            console.log(res)

            if (res.data.data.country !== undefined) {
                let cRes = countries?.find(res2 => res2?.name == res.data.data.country)
                setCountry(`${cRes.name}&&${cRes.isoCode}`)
                getStates(`${cRes.name}&&${cRes.isoCode}`)
            }
            if (res.data.data.city !== undefined) {
                setCity(res.data.data.city)
            }
            setLoader(false)
            return res;
        } catch (err) {
            console.log(err)
            setLoader(false)
        }
    }

    useEffect(() => {
        if (statesListing.length !== 0) {
            let sRes = statesListing?.find(res2 => res2?.name == userData.state)
            if (sRes !== undefined) {
                setState(`${sRes.name}&&${sRes.isoCode}&&${sRes.countryCode}`)
                getCities(`${sRes.name}&&${sRes.isoCode}&&${sRes.countryCode}`)
            }
        }

    }, [statesListing])


    function getImgPreview(file) {
        let url = URL.createObjectURL(file)
        return url
    }

    async function editProfileData(e) {
        e.preventDefault()

        if (userData.imageUrl == '' && img == null) {
            setToaster({
                show: true,
                message: "Please select profile image",
            });
            return
        }
        let payload = {
            ...userData,
            textSMS_mobile: JSON.parse(userData.textSMS_mobile),
            textSMS_mobile_alter: JSON.parse(userData.textSMS_mobile_alter),
            city: city.split('&&')[0],
            state: state.split('&&')[0],
            country: country.split('&&')[0],
        }
        let formdata = new FormData()
        if (img !== null) {
            formdata.append('uploadDoc', img)
        }

        setLoader(true)
        try {
            if (img !== null) {
                let imageData = await uploadDocument(formdata)
                payload.imageFileName = imageData.data.fileName
                payload.imageUrl = imageData.data.fileLocation
            }
            let res = await updateDashboardUser(userId, payload)
            setImg(null)

            let responseForData = await getUserData()
            console.log("response forn data", responseForData)
            setEditable(true)
            setLoader(false)

            dispatch(
                changeHeaderData({
                    lawyerName: responseForData?.data?.data?.firm_name,
                    lawyerPhoto: responseForData?.data?.data?.imageUrl,
                })
            );
            // navigate("/superadmin/manageaccounts/intendedparents")
        } catch (err) {
            setLoader(false)
            console.log(err)
        }

    }


    function getStates(res) {
        let isoCode = res.split('&&')[1]
        setCountry(res)
        let states = State.getStatesOfCountry(isoCode)
        setStatesListing(states)
    }

    function getCities(res) {
        let isoCode = res.split('&&')[1]
        let cCode = res.split('&&')[2]
        setState(res)
        let cities = City.getCitiesOfState(cCode, isoCode)
        setCitiesListing(cities)
    }

    function onInputChange(e) {
        setUserData(pre => {
            return { ...pre, [e.target.name]: e.target.value }
        })
    }


    useEffect(() => {
        getUserData();
    }, []);


    return (

        <>  <Loader open={loader} />

            <Toaster
                event={toaster.message}
                show={toaster.show}
                severity={"warn"}
                setToaster={setToaster}
            />

            <div className={style.MyProfile}>
                <form onSubmit={editProfileData}>


                    <div className={`mb-5 ${style.bannersize} px-3`}>

                        <div className="d-flex gap-3 align-items-center px-3">
                            {editable ? (
                                <>
                                    <div className={`${style.ProfileImg1} `}>
                                        <img src={userData.imageUrl !== '' ? userData.imageUrl : defaultImg} className='w-100 h-100' alt="lawyer" />
                                    </div>
                                </>
                            ) : (
                                <>

                                    <label className={`${style.ProfileImg1} pointer border`} >
                                        <div className={`${style.inputIcon}`}>
                                            <CiImageOn />
                                        </div>
                                        <img src={userData.imageUrl !== '' && img == null ? userData.imageUrl : defaultImg == '/images/lawyerDefaultPic.png' ? defaultImg : getImgPreview(defaultImg)} htmlFor='imgInp' className='w-100 h-100' alt="" />
                                        <input type='file' hidden id='imgInp' onChange={(e) => {
                                            setDefaultimg(e.target.files[0])
                                            setImg(e.target.files[0])
                                        }} />
                                    </label>

                                </>
                            )}
                            <div className="d-flex flex-column align-item-center justify-content-center gap-3">
                                <div className={style.BnnerFirstText}>{userData.memberId} : Lawyer</div>
                                <div className={style.BnnersecondtText}>{userData.fullName}</div>

                            </div>

                        </div>

                        <div className={`p-relative ${style.ProfileImg} d-flex align-items-center`}>
                            <img src="/images/lawyer_profile_right_image.png" className={`w-100`} alt="" />
                            {editable ? <div className={`d-flex justify-content-end ${style.ChlidButton}`}>
                                <button className={`text-center d-flex align-items-center ${style.VerfiedButton}`} onClick={() => setEditable(false)}><i class="bi bi-pencil-square me-1 d-flex align-items-center"></i>
                                    <span className={style.verfiedText} >Edit Details</span>
                                </button>
                            </div> : ''}
                        </div>

                    </div>

                    <div className="row">
                        <div className="col">

                            <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`}>{t("Firm Name")}<span className='text-danger'>*</span></label>
                            <input required type="text" className={`form-control`} placeholder="" disabled={editable} value={userData?.firm_name} name='firm_name' onChange={onInputChange} />

                        </div>


                    </div>
                    <div className="row mt-3">
                        <div className="col-md-6">

                            <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`}>{t("Communication Email Address")}<span className='text-danger'>*</span></label>
                            <input type="email" className={`form-control`} disabled={true} value={userData?.email} placeholder="" />

                        </div>
                        <div className="col-md-6">

                            <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`}>{t("Password")}</label>
                            <input type="password" className={`form-control`} disabled={true} value={userData?.password} placeholder="" />

                        </div>
                    </div>

                    <div className="row mt-3">

                        <div className="col-md-6">

                            <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`}>{t("Contact Number")}<span className='text-danger'>*</span></label>
                            <input type="text" name="mobile_No" className={`form-control`} disabled={true} value={userData?.mobile_No} placeholder="" />

                        </div>
                        <div className="col-md-6">

                            <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`}>{t("Alternative Contact Number")}</label>


                            <PhoneInputComponent
                                value={userData.mobile_No_alter}
                                onChange={onInputChange}
                                placeholder="Enter Mobile Number"
                                required
                                disabled={editable}
                                name="mobile_number"
                            />

                        </div>

                    </div>


                    <div className="row mt-3">
                        <div className="col-md-12">

                            <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`}>{t("Current Address")}<span className='text-danger'>*</span></label>
                            <input type="text" className={`form-control`} placeholder="" required disabled={editable} value={userData?.address} name='address' onChange={onInputChange} />

                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-md-3">

                            <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`}>{t("Country")}<span className='text-danger'>*</span> </label>
                            <select className={`form-select`} value={country} required disabled={editable} onChange={(e) => {
                                getStates(e.target.value)
                                setState('')
                                setCity('')
                            }}>
                                <option selected disabled value={''}>{t("select")}</option>
                                {countries.map((res, index) => {
                                    return <option key={index} value={`${res.name}&&${res.isoCode}`}>{res.name}</option>
                                })}
                            </select>

                        </div>
                        <div className="col-md-3">

                            <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`} >{t("State of Residence")}</label>
                            <select className={`form-select`} value={state} disabled={editable} onChange={(e) => {
                                getCities(e.target.value)
                                setCity('')
                            }} >
                                <option selected disabled value={''}>{t("select")}</option>
                                {statesListing?.map((res, index) => {
                                    return <option key={index} value={`${res.name}&&${res.isoCode}&&${res.countryCode}`}>{res.name}</option>
                                })}

                            </select>



                        </div>
                        <div className="col-md-3">

                            <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`}>{t("City")}</label>
                            <select className={`form-select`} value={city} disabled={editable} onChange={(e) => setCity(e.target.value)} >
                                <option selected disabled value={''}>{t("select")}</option>
                                {citiesListing?.map((res, index) => {
                                    return <option key={index} value={`${res.name}`}>{res.name}</option>
                                })}

                            </select>


                        </div>

                        <div className="col-md-3">

                            <label htmlFor="exampleInputEmail1" className={`form-label ${style.LabelHeading}`} >{t("Zip Code")}<span className='text-danger'>*</span></label>
                            <input type="number" className={`form-control`} required placeholder="" disabled={editable} value={userData?.zip_code} name='zip_code' onChange={onInputChange} />


                        </div>
                    </div>

                    <div className='col-md-12'>

                        {editable ? '' : <div className='d-flex justify-content-end gap-4 mt-5'>
                            <button type='button' onClick={() => {
                                setEditable(true);
                                getUserData();
                            }
                            } className={style.CancelButton}>Cancel</button>
                            <button type='submit' className={style.SaveButton}>Save Changes</button>
                        </div>}
                    </div>


                </form>
            </div>
        </>
    )
}

export default LawyerMyProfile
