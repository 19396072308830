import React from "react";

export default function NoNhpPost() {
  return (
    <>
      <div className="d-flex justify-content-center  w-100">
        <div className="w-25">
          <img className="w-100" src="/images/undercons.png" />
        </div>
      </div>
      {/* <div className="d-flex justify-content-center mt-4"> */}
        {/* <div className="d-flex flex-column gap-2"> */}
          <div className={`d-flex justify-content-center lufga_noaucton mt-4`}>No NHP Auction Post Added</div>
          <div className={`d-flex justify-content-center lufga_24px mt-2`}>
          Please check back later for new listings.
          </div>
        {/* </div> */}
      {/* </div> */}
    </>
  );
}
