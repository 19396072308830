import React, { useEffect, useState } from 'react'
import style from '../../../IntendedParentsComponent/MyProfile/MyProfile.module.css'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { getDashboardUser, updateDashboardUser, uploadDocument } from '../../../../Api/CommonApis'
import { CiImageOn } from "react-icons/ci";
import Loader from '../../../common/Loader'
import { Country, State, City } from 'country-state-city';
import ActiveDelete from '../../../commonComponents/ActiveDelete/ActiveDelete'
import { FaArrowLeft } from 'react-icons/fa'


const IntendedParentsActiveClient = () => {
    let { t } = useTranslation()
    let userId = useParams().id
    const navigate = useNavigate()
    const [loader, setLoader] = useState(true)
    const [editable, setEditable] = useState(true)
    const countries = Country.getAllCountries()
    const [country, setCountry] = useState('')
    const [state, setState] = useState('')
    const [city, setCity] = useState('')
    const [statesListing, setStatesListing] = useState([])
    const [citiesListing, setCitiesListing] = useState([])
    const [img, setImg] = useState(null)
    const [defaultImg, setDefaultimg] = useState('/images/blankProfile.png')
    //let userId = sessionStorage.getItem('userId')
    const [userData, setUserData] = useState({
        mobile_No: "",
        mobile_No_alter: "",
        email_alter: "",
        address: "",
        city: "",
        state: "",
        imageUrl: '',
        imageFileName: '',
        country: '',
        zip_code: "",
        textSMS_mobile: false,
        textSMS_mobile_alter: false,
        auctionUpdates: false,
        latestNews: false,
        importantUpdate: false,
        promotionalNotifications: false,
        user_blocked: false,
    })

    // const [ipData, setIpData] = useState([])

    // const fetchIpData= async()=>{
    //     const result = await getDashboardUser(params.id)
    //     setIpData(result.data.data) 
    // }

    // useEffect(()=>{
    //     fetchIpData()
    // },[])

    async function getUserData() {
        try {
            await console.log(userId)
            let res = await getDashboardUser(userId)
            setUserData(pre => { return { ...pre, ...res.data.data } })
            console.log(res)

            if (res.data.data.country !== undefined) {
                let cRes = countries?.find(res2 => res2?.name == res.data.data.country)
                setCountry(`${cRes.name}&&${cRes.isoCode}`)
                getStates(`${cRes.name}&&${cRes.isoCode}`)
            }
            if (res.data.data.city !== undefined) {
                setCity(res.data.data.city)
            }
            setLoader(false)
        } catch (err) {
            console.log(err)
            setLoader(false)
        }
    }
    useEffect(() => {
        if (statesListing.length !== 0) {
            let sRes = statesListing?.find(res2 => res2?.name == userData.state)
            if (sRes !== undefined) {
                setState(`${sRes.name}&&${sRes.isoCode}&&${sRes.countryCode}`)
                getCities(`${sRes.name}&&${sRes.isoCode}&&${sRes.countryCode}`)
            }
        }

    }, [statesListing])


    function getImgPreview(file) {
        let url = URL.createObjectURL(file)
        return url
    }


    async function editProfileData(e) {
        e.preventDefault()

        if (userData.imageUrl == '' && img == null) {
            alert('Please select profile image')
            return
        }
        let payload = {
            ...userData,
            textSMS_mobile: JSON.parse(userData.textSMS_mobile),
            textSMS_mobile_alter: JSON.parse(userData.textSMS_mobile_alter),
            city: city.split('&&')[0],
            state: state.split('&&')[0],
            country: country.split('&&')[0],
        }
        let formdata = new FormData()
        if (img !== null) {
            formdata.append('uploadDoc', img)
        }

        setLoader(true)
        try {
            if (img !== null) {
                let imageData = await uploadDocument(formdata)
                payload.imageFileName = imageData.data.fileName
                payload.imageUrl = imageData.data.fileLocation
            }
            let res = await updateDashboardUser(userId, payload)
            setImg(null)
            await getUserData()
            setEditable(true)
            setLoader(false)
            navigate("/superadmin/manageaccounts/intendedparents")
        } catch (err) {
            setLoader(false)
            console.log(err)
        }

    }


    function getStates(res) {
        let isoCode = res.split('&&')[1]
        setCountry(res)
        let states = State.getStatesOfCountry(isoCode)
        setStatesListing(states)
    }


    function getCities(res) {
        let isoCode = res.split('&&')[1]
        let cCode = res.split('&&')[2]
        setState(res)
        let cities = City.getCitiesOfState(cCode, isoCode)
        setCitiesListing(cities)
    }


    function onInputChange(e) {
        setUserData(pre => {
            return { ...pre, [e.target.name]: e.target.value }
        })
    }



    useEffect(() => {
        getUserData()
    }, [])
    return (
        <>

            <div className="mt-4">
                <div
                    className="d-flex align-items-center gap-2 btn-Secondary p-0 pointer"
                    onClick={() => navigate('/superadmin/manageaccounts/intendedparents')}
                >
                    <FaArrowLeft /> Back
                </div>
            </div>


            <Loader open={loader} />
            <div className={style.MyProfile}>
                <form onSubmit={editProfileData} >
                    <div className={` ${style.bannersize} px-3`}>
                        <div className='d-flex  gap-3'>
                            {editable ? <>
                                <div className={`${style.ProfileImg1} mt-3`} >
                                    <img src={userData.imageUrl !== '' ? userData.imageUrl : defaultImg} className='w-100 h-100' alt="ip" />
                                </div>
                            </> : <><label className={`${style.ProfileImg1} pointer border`} >
                                <div className={`${style.inputIcon}`}>
                                    <CiImageOn />
                                </div>
                                <img src={userData.imageUrl !== '' && img == null ? userData.imageUrl : defaultImg == '/images/blankProfile.png' ? defaultImg : getImgPreview(defaultImg)} htmlFor='imgInp' className='w-100 h-100' alt="" />
                                <input type='file' hidden id='imgInp' onChange={(e) => {
                                    setDefaultimg(e.target.files[0])
                                    setImg(e.target.files[0])
                                }} />
                            </label></>}

                            <div className='d-flex flex-column align-item-center justify-content-center gap-3'>
                                <div className={style.BnnerFirstText}>{userData.memberId} : Intended Parents</div>
                                <div className={style.BnnersecondtText}>{userData.fullName}</div>
                                <div><ActiveDelete id={userId} user_blocked={userData?.user_blocked} target={"intendedparents"} /></div>
                            </div>
                        </div>
                        <div className={`p-3 p-relative ${style.ProfileImg}`}>
                            <img src="/images/child5 (1).png" className={`w-100`} alt="" />
                            {/* {editable ? <div className={`d-flex justify-content-end ${style.ChlidButton}`}>
                                <button className={`text-center d-flex align-items-center ${style.VerfiedButton}`} onClick={() => setEditable(false)}><i class="bi bi-pencil-square me-1 d-flex align-items-center"></i>
                                    <span className={style.verfiedText} >Edit Details</span>
                                </button>
                            </div> : ''} */}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 pb-3">
                            <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`}>{t("Full Name")}<span className='text-danger'>*</span></label>
                            <input type="text" className={`form-control`} placeholder="" disabled={editable} value={userData?.fullName} name='fullName' onChange={onInputChange} />
                        </div>

                        <div className="col-md-6 pb-3">
                            <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`}>{t("Email Address")}<span className='text-danger'>*</span></label>
                            <input type="email" className={`form-control`} disabled={true} value={userData?.email} placeholder="" />
                        </div>

                        <div className="col-md-6 pb-3">
                            <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`}>{t("Alternative Email Address")}</label>
                            <input type="email" className={`form-control`} disabled={editable} value={userData?.email_alter} placeholder="" name='email_alter' onChange={onInputChange} />
                        </div>


                        <div className="col-md-6 pb-3">
                            <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`}>{t("Contact Number")}<span className='text-danger'>*</span></label>
                            <input type="text" name="mobile_No" className={`form-control`} disabled={true} value={userData?.mobile_No} placeholder="" />
                        </div>

                        <div className="col-md-6 pb-3">
                            <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`} >{t("Allow Text Message ?")}<span className='text-danger'>*</span> </label>

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-check">
                                        <input className={`form-check-input`} type="radio" name='textSMS_mobile' id='first' disabled={editable} value={true} checked={JSON.parse(userData?.textSMS_mobile) ? true : false} onChange={(e) => setUserData(pre => {
                                            return { ...pre, [e.target.name]: e.target.value }
                                        })} />
                                        <label className={`form-check-label ${style.SubLAbelHedaing}`} htmlFor="flexRadioDefault1">
                                            {t("Yes")}
                                        </label>
                                    </div>
                                </div>

                                <div className="col-md-6">

                                    <div className="form-check">
                                        <input className={`form-check-input`} type="radio" name='textSMS_mobile' id='first' disabled={editable} value={false} checked={!JSON.parse(userData?.textSMS_mobile) ? true : false} onChange={(e) => setUserData(pre => {
                                            return { ...pre, [e.target.name]: e.target.value }
                                        })} />
                                        <label className={`form-check-label ${style.SubLAbelHedaing}`} htmlFor="flexRadioDefault1">
                                            {t("No")}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div className="col-md-6 pb-3">

                            <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`}>{t("Alternative Contact Number")}</label>

                            <input type="number" className={`form-control`} placeholder="" disabled={editable} value={userData?.mobile_No_alter} name='mobile_No_alter' onChange={onInputChange} />

                        </div>
                        <div className="col-md-6 pb-3">
                            <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`} >{t("Allow Text Message ?")}<span className='text-danger'>*</span> </label>

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-check">
                                        <input className={`form-check-input`} type="radio" id='second' disabled={editable} checked={JSON.parse(userData?.textSMS_mobile_alter) ? true : false} value={true} name='textSMS_mobile_alter' onChange={(e) => setUserData(pre => {
                                            return { ...pre, [e.target.name]: e.target.value }
                                        })} />
                                        <label className={`form-check-label ${style.SubLAbelHedaing}`} htmlFor="flexRadioDefault1">
                                            {t("Yes")}
                                        </label>
                                    </div>
                                </div>

                                <div className="col-md-6">

                                    <div className="form-check">
                                        <input className={`form-check-input`} type="radio" id='second' disabled={editable} checked={!JSON.parse(userData?.textSMS_mobile_alter) ? true : false} value={false} name='textSMS_mobile_alter' onChange={(e) => setUserData(pre => {
                                            return { ...pre, [e.target.name]: e.target.value }
                                        })} />
                                        <label className={`form-check-label ${style.SubLAbelHedaing}`} htmlFor="flexRadioDefault1">
                                            {t("No")}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div className="col-md-12 mb-3">
                            <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`}>{t("Current Address")}<span className='text-danger'>*</span></label>
                            <input type="text" className={`form-control`} placeholder="" required disabled={editable} value={userData?.address} name='address' onChange={onInputChange} />
                        </div>



                        <div className="col-md-6 mb-3">
                            <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`}>{t("Country")}<span className='text-danger'>*</span> </label>
                            <select className={`form-select`} value={country} required disabled={editable} onChange={(e) => {
                                getStates(e.target.value)
                                setState('')
                                setCity('')
                            }}>
                                <option selected disabled value={''}>{t("select")}</option>
                                {countries.map((res, index) => {
                                    return <option key={index} value={`${res.name}&&${res.isoCode}`}>{res.name}</option>
                                })}
                            </select>
                        </div>
                        <div className="col-md-6 mb-3">
                            <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`} >{t("State of Residence")}<span className='text-danger'>*</span> </label>
                            <select className={`form-select`} value={state} required disabled={editable} onChange={(e) => {
                                getCities(e.target.value)
                                setCity('')
                            }} >
                                <option selected disabled value={''}>{t("select")}</option>
                                {statesListing?.map((res, index) => {
                                    return <option key={index} value={`${res.name}&&${res.isoCode}&&${res.countryCode}`}>{res.name}</option>
                                })}

                            </select>
                        </div>
                        <div className="col-md-6 mb-3">
                            <label htmlFor="exampleInputEmail1" className={`form-label  ${style.LabelHeading}`}>{t("City")}<span className='text-danger'>*</span> </label>
                            <select className={`form-select`} value={city} required disabled={editable} onChange={(e) => setCity(e.target.value)} >
                                <option selected disabled value={''}>{t("select")}</option>
                                {citiesListing?.map((res, index) => {
                                    return <option key={index} value={`${res.name}`}>{res.name}</option>
                                })}

                            </select>
                        </div>

                        <div className="col-md-6 mb-3">
                            <label htmlFor="exampleInputEmail1" className={`form-label ${style.LabelHeading}`} >{t("Zip Code")}<span className='text-danger'>*</span></label>
                            <input type="number" className={`form-control`} required placeholder="" disabled={editable} value={userData?.zip_code} name='zip_code' onChange={onInputChange} />
                        </div>
                        <div className='col-md-12'>

                            {editable ? '' : <div className='d-flex justify-content-end gap-4 mt-5'>
                                <button type='button' onClick={() => setEditable(true)} className={style.CancelButton}>Cancel</button>
                                <button type='submit' className={style.SaveButton}>Save Changes</button>
                            </div>}
                        </div>





                    </div>
                </form>
            </div>
        </>
    )
}

export default IntendedParentsActiveClient