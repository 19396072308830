import React, { useEffect, useState } from 'react';
import style from './LawyerAddPoc.module.css';
import { Dialog } from 'primereact/dialog';
import { editAppointment, getlawyerAppointmentById, deleteAppointmentById, addAndUpdatePoc, deletePocApi } from '../../../../Api/Lawyer';
import Loader from '../../../common/Loader';
import moment from 'moment';
import { ConfirmDelete } from '../../../common/ConfirmDelete';
import PhoneInputComponent from '../../../commonComponents/PhoneInput/PhoneInput';

const LawyerEditPoc = ({ apoint, setApoint, appointId, reload, clientId }) => {


    // console.log("this is appointment id for edit poc", appointId)
    const [editable, setEditable] = useState(true)

    let [data, setData] = useState({
        "clientId": `${clientId}`,
        "name": "",
        "contact_number": "",
        email_address: "",
        relation_with_client: ""
    });

    const [loader, setLoader] = useState(false);

    const deletePocFunction = async (id) => {
        setLoader(true);
        console.log("this is id", id)
        try {
            await deletePocApi({ clientId: id });
            await reload();
            setApoint(false);
        } catch (error) {
            console.log(error);
            setApoint(false);
        }
        setLoader(false);
    };

    const addPocdataFunction = async (e) => {
        e.preventDefault();
        data = { ...data, clientId: clientId }
        try {
            setLoader(true);
            await addAndUpdatePoc(data);
            await reload();
            setEditable(true)
            setApoint(false);

            setLoader(false);
        } catch (err) {
            console.log(err);

            setEditable(true)
            setApoint(false);
            setLoader(false);
        }
    };

    const getPocData = async () => {
        setLoader(true);
        try {
            let res = await getlawyerAppointmentById(appointId);
            console.log("this is appointment data", res);
            const appointmentData = res?.data?.data[0]?.poc;
            setData(appointmentData);
            setLoader(false);
        } catch (err) {
            console.log(err);
            setLoader(false);
        }
    };

    const onInputChange = (e) => {
        const { name, value } = e.target;

        setData(prev => ({ ...prev, [name]: value }));
    };

    useEffect(() => {
        if (appointId && apoint) {
            getPocData();
        }
    }, [apoint]);

    return (
        <div>
            <Loader open={loader} />
            <Dialog
                closable={false}
                visible={apoint}
                className='p-0 m-0'
                onHide={() => setApoint(false)}
                style={{ width: "65vw", padding: "0px", margin: "0px" }}
                breakpoints={{ "1400px": "90vw", "641px": "100vw" }}
            >
                <div className='Verfied_con'>
                    <div className='d-flex justify-content-between align-items-center'>
                        {/* Dont remove this first span its here to maintain the layout */}
                        <span></span>
                        <span className={style.verfideText}>{data?.name}</span>
                        <span onClick={() => { setApoint(false); setEditable(true) }} style={{ cursor: "pointer" }}><i className="pi pi-times" style={{ fontSize: '1.5rem' }}></i></span>
                    </div>
                    <form onSubmit={addPocdataFunction}>

                        <div className='row'>
                            <div className='col-12 mt-3'>
                                <label className={`form-check-label mb-1 ${style.LabelText}`} htmlFor='report_name'>Name</label>
                                <input
                                    name='name'
                                    required
                                    disabled={editable}
                                    type='text'
                                    className='form-control'
                                    placeholder='Enter Name'
                                    onChange={onInputChange}
                                    value={data?.name}

                                />
                            </div>
                        </div>

                        <div className='row mb-4'>

                            <div className='col-md-6 mt-3'>
                                <label className={`form-check-label mb-1 ${style.LabelText}`} htmlFor='contact_number'>Contact Number</label>
                                <PhoneInputComponent
                                    required={true}
                                    disabled={editable}
                                    placeholder={'Enter Contact Number'}
                                    name={"contact_number"}
                                    value={data?.contact_number}
                                    onChange={onInputChange}
                                />
                            </div>


                            <div className='col-md-6 mt-3'>
                                <label className={`form-check-label mb-1 ${style.LabelText}`} htmlFor='email_address'>Email Address</label>
                                <input
                                    required
                                    disabled={editable}
                                    type='email'
                                    className='form-control'
                                    placeholder='Enter Email Address'
                                    name='email_address'
                                    onChange={onInputChange}
                                    value={data?.email_address}
                                />
                            </div>



                            <div className='col-12 mt-3'>
                                <label className={`form-check-label mb-1 ${style.LabelText}`} htmlFor='relation_with_client'>Relation With Client</label>
                                <input
                                    required
                                    type='text'
                                    disabled={editable}
                                    className='form-control'
                                    placeholder='Relation With Client'
                                    name='relation_with_client'
                                    onChange={onInputChange}
                                    value={data?.relation_with_client}
                                />
                            </div>


                        </div>

                        {editable &&
                            <div className='d-flex justify-content-end align-items-center gap-4'>
                                <ConfirmDelete onDelete={deletePocFunction} data={clientId} header={"Delete POC"} deleteMsg={"Are you sure you want to delete Poc"}>
                                    <button className={style.delete_button} type="button">Delete POC</button>
                                </ConfirmDelete>

                                <button onClick={() => { setEditable(!editable) }} className={style.Edit_button} type="button">
                                    <span className='d-flex align-items-center justify-content-center gap-1'>
                                        <i className="pi pi-user-edit" style={{ color: '#fff', fontSize: '1.3rem' }}></i>
                                        Edit POC
                                    </span>
                                </button>
                            </div>

                        }

                        {!editable &&
                            <div div className='d-flex justify-content-end gap-3'>
                                <button className={style.CencelButton} type="button" onClick={() => { setEditable(!editable); getPocData() }}>Cancel</button>
                                <button className={style.SubmitButton}
                                    //  onClick={(e) => addAppointmentdata(e)} 
                                    type="submit">Submit</button>
                            </div>

                        }


                    </form>
                </div>
            </Dialog >
        </div >
    );
};

export default LawyerEditPoc;
