import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import style from "./LatestNews.module.css";
import { Link } from "react-router-dom";
import { useState } from "react";
import PopOver from "../../../commonComponents/popover/popover";
import { PopoverItem } from "../../../commonComponents/popovericon/popovericon";
import { useNavigate } from "react-router-dom";
import { LiaEdit } from "react-icons/lia";
import { getAllNews } from "../../../../Api/news";
import DeleteNews from "../DeleteNews/DeleteNews";
import Loader from "../../../common/Loader";
const LatestNews = ({ role,call }) => {
  const navigate = useNavigate();
 

  const [cards, setCards] = useState([]);
  const { t } = useTranslation();
  const [resId, setResId] = useState();
  const [resume, setResume] = useState(false);
  const [loader, setLoader] = useState(false);

  const [allNewsData, setAllNewsData] = useState([]);

  const getAllNewsData = async () => {
    setLoader(true);
    await getAllNews()
      .then((res) => {
        console.log(res.data.data);
        setAllNewsData(res.data.data);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  function refreshData() {
    getAllNewsData();
  }
  useEffect(() => {
    getAllNewsData();
  }, []);

  const changedate = (dt) => {
    const inputDate = new Date(dt);

    const formattedDate = inputDate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    return formattedDate;
  };

  const Linkforward = (link) => {
    window.open(link, "_blank");
  };


  const sortedNewsData = allNewsData.sort((a, b) => {
    return new Date(b.news_date) - new Date(a.news_date);
  });



  return (
    <div>
      <Loader open={loader} />
      <div className={`container-fluid`}>
        <div className="row mt-2 justify-content-start">
          {allNewsData?.map((card, index) => (
            <div
              key={index}
              className={`col-11 col-sm-5 col-md-4 p-lg-3 p-md-1 p-sm-2 p-2  `}
            >
              <div className={`${style.card_wrapper} h-100`}>
                <div className={`${style.upper_v_1}`}>
                  <div
                    className={`${style.upper_v_1_1}`}
                    style={{
                      display: role === false ? "none" : "block",
                    }}
                  >
                    <PopOver
                      onClick={(e) => {
                        e.stopPropagation();
                        setResId(card?._id);
                      }}
                    >
                      <PopoverItem
                        text="Edit News"
                        icon={<LiaEdit style={{ fontSize: "1.5rem" }} />}
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(
                            `/superadmin/latestnews/EditNews/${card._id}`
                          );
                        }}
                      />
                      <PopoverItem
                        style={{ color: "red" }}
                        text={"Delete News"}
                        icon={<i className="pi pi-trash" />}

                        onClick={(e) => {
                          e.stopPropagation();
                          setResume(true);
                          setResId(card?._id);
                        }}
                      />
                    </PopOver>
                    {/* </div> */}
                  </div>
                </div>
                <Link
                  onClick={() => Linkforward(card.news_link)}
                  style={{ height: "100%" }}
                  className="d-flex align-items-end"
                >
                  <div className={`h-100 card ${style.card}`}>
                    <img
                      className={style.top_img}
                      src={`${card.news_photo_url}`}
                      alt={"Card image cap"}
                    />

                    <div className="card-body d-flex flex-column justify-content-between">
                      <div className="lufga_20px fw_400 px-2">
                        {card.news_headline}
                      </div>
                      <div className="lufga_18px d-flex align-items-center justify-content-between mt-3">
                        <div className="d-flex align-items-end">
                          <span className="custom_gray lufga_12px d-flex align-items-center">
                            {card.news_source_name}
                          </span>
                        </div>
                        <div className="lufga_12px custom_gray d-flex align-items-end">
                          {changedate(card.news_date)}
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
      <DeleteNews
        resumeone={resume}
        setResumeone={setResume}
        id={resId}
        refreshData={refreshData}
      />
    </div>
  );
};

export default LatestNews;
